import { useQuery } from "@apollo/client";
import { useGetContactLinkQBAndHandleCurrency } from "hooks/useGetContactLinkQBAndHandleCurrency";
import { useNavHelper } from "hooks/useNavHelper";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import GET_CONTACT_LINK from "../queries/QnB/getContactLink";
import { hasValidRefreshToken } from "../utils/auth";

const useContactLink = () => {
  const intl = useIntl();
  const location = useLocation();
  const contactLinkQB = useGetContactLinkQBAndHandleCurrency();
  const { isQuoteRoute } = useNavHelper(location?.pathname);
  const countryCode = useSelector(
    (state) =>
      state.quoteAndBook.selectedCountry?.value || state.app?.userCountry
  );

  const hasRefreshToken = hasValidRefreshToken();
  const { data } = useQuery(GET_CONTACT_LINK, {
    variables: {
      locale: intl.locale,
      countryCode: countryCode,
    },
    skip: !intl.locale || !countryCode || hasRefreshToken,
  });
  const contactLink = hasRefreshToken
    ? "https://www.mydhli.com/global-en/home/contact-us/registered-mydhli-customers.html"
    : data?.getContactLink?.contactLink ||
      "https://www.dhl.com/global-en/home/our-divisions/global-forwarding/contact-us/contact-our-experts.html";

  return isQuoteRoute ? contactLinkQB || contactLink : contactLink;
};

export default useContactLink;
