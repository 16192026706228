import SideBar from "components/organisms/SideBar/SideBar";
import TopBarWithAuth from "components/organisms/TopBarWithAuth/TopBarWithAuth";
import { useGetDataTestId } from "containers/TestingContext/TestingContext";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { setAppState } from "redux/app/actions";
import { clearMapSearch, setMapSearchText } from "redux/search/actions";
import { getRem } from "utils/css";
import { messages } from "./messages";

const Nav = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const getDataTestId = useGetDataTestId();

  return (
    <>
      <TopBarWithAuth
        data-testid={getDataTestId("TopBarWithAuth")}
        onMenuClick={() => {
          dispatch(setAppState("navCollapsed", false));
        }}
        hideTopBar={props.hideTopBar}
        belowLg={props.belowLg}
      />
      <SideBar
        hideNav={props.hideNav}
        paddingTop={props.belowLg ? getRem(40) : getRem(80)}
        belowLg={props.belowLg}
        collapseMenuText={intl.formatMessage(messages.collapseMenu)}
        shouldScrollMenu={props.shouldScrollNavMenu}
      />
    </>
  );
};

Nav.defaultProps = {
  navCollapsed: true,
  hideTopBar: true,
  inMapView: false,
};
Nav.propTypes = {
  hideTopBar: PropTypes.bool,
  inMapView: PropTypes.bool,
  shouldScrollNavMenu: PropTypes.bool,
  belowLg: PropTypes.bool,
};

export default connect((state) => ({ search: state.search }), {
  setMapSearchText,
  clearMapSearch,
})(Nav);
