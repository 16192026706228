import { PartyType } from "constants/bookWorkflow";
import { MapProviderDataType } from "constants/googleSuggestions";
import * as MC from "constants/masterDataManagement";
import { FormApi } from "final-form";
import { ReactNode } from "react";
import { SectionActionType } from "./AddressBook/AddOrEditAddressModal/AddOrEditAddressForm/AddOrEditAddressForm.constants";

export interface AddressFormProps {
  fieldPrefix: string;
  isForAddressBook?: boolean;
  onCountryChangeDecorator?: (onCountryChange: () => void) => void;
  shouldForceFieldsErrorVisibility?: boolean;
  primaryFormLabel: string;
  secondaryFormLabel: string;
  referredType?: PartyType;
  formVisibleAfterCountrySelection?: boolean;
  className?: string;
  partyType: PartyType;
}

export const iso2code = 2;
export const internationalDialCode = 3;
export const addressSearchMinLength = 3;
export const AddressesCategoryId = "Addresses";
export const AddressBookCategoryId = "AddressBook";
export const CategoryOptionsLimit = 3;
export const AddressBookCategoryOptionsLimit = 6;

export interface FormUpdateOnCountryChangeType {
  form: FormApi;
  fieldPrefix: string;
  newCountry: MC.Country;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectSearchValue: React.Dispatch<React.SetStateAction<{}>>;
  setAddressInputTypeField: () => void;
  isForAddressBook: boolean;
  isLocalityBasedAddress: boolean;
}

export interface FormResetType {
  form: FormApi;
  fieldPrefix: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectSearchValue: React.Dispatch<React.SetStateAction<{}>>;
  isLocalityBasedAddress?: boolean;
  omitCountryReset?: boolean;
}

export interface SearchOptionType {
  label: string;
  value: string | number;
  lookup?: () => Promise<MapProviderDataType>;
  categoryId?: typeof AddressesCategoryId | typeof AddressBookCategoryId;
  icon?: ReactNode;
  categoryType?: CategoryType;
  source?: SourceType;
}

export interface AddressBookAddressSearchCustomerContact {
  contact_name: string;
  email: string;
  phone: string;
  is_shipper: boolean;
  is_consignee: boolean;
  is_pickup: boolean;
  is_delivery: boolean;
  is_billing: boolean;
  is_notify: boolean;
}

export interface AddressBookAddressSearchCustomer {
  id: number;
  corporate_partner_id: number;
  company_name: string;
  address: string;
  address_line1: string;
  address_line2: string;
  postal_code: string;
  city: string;
  state_province: string;
  country_code: string;
  ref_customer: string;
  account_number: string;
  created_at: string;
  created_by: string;
  modified_at: string | null;
  modified_by: string | null;
  contacts: [AddressBookAddressSearchCustomerContact];
  account_numbers: [string];
}

export interface AddressBookAddressesResponse {
  getAddressBookAddresses: {
    customer: AddressBookAddressSearchCustomer[];
    company: AddressBookAddressSearchCustomer[];
  } | null;
}

export interface AddressBookCustomerAddress {
  address_line1: string;
  address_line2: string;
  city: string;
  company_name: string;
  corporate_partner_uuid: string;
  country_code: string;
  google_place_id: string;
  id: number;
  latitude: number;
  longitude: number;
  postal_code: string;
  ref_customer: string;
  state_province: string;
  created_at: string;
  modified_at: string;
  is_customer_visible: boolean;
  is_validated: boolean;
}

export interface AddressBookCustomerClient {
  address_id: number;
  contact_id: number;
  contact: AddressBookAddressSearchCustomerContact;
  audit: {
    created_at: string;
    created_by_email: string;
    modified_at: string;
    modified_by_email: string;
  };
}

export interface AddressBookCustomerAddressesResponse {
  getAddressBookCustomerAddress: {
    address: AddressBookCustomerAddress;
    address_id: number;
    contacts: [AddressBookCustomerClient];
    audit: {
      created_at: string | null;
      created_by_email: string | null;
      modified_at: string | null;
      modified_by_email: string | null;
    };
  } | null;
}

export enum CategoryType {
  CUSTOMER = "customer",
  COMPANY = "company",
}

export enum SourceType {
  RECENTLY_USED = "recentlyUsedOptions",
  ADDRESS_BOOK_ADDRESSES = "addressBookAddresses",
}

export interface AddressValues {
  addressLine: string;
  city: string;
  zipCode: string;
  stateProvince: string;
  companyName?: string;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  addressId?: number;
  referenceId?: string;
  isCustomerVisible?: boolean;
  modifiedAt?: string;
  contactId?: string;
  isValidated?: boolean;
}

export interface AddressFormContact {
  companyName?: string;
  contactName?: string;
  email?: string;
  phoneNumber?: string;
  contactType?: PartyType[];
  actionType?: SectionActionType;
  modifiedAt?: string | null;
  createdAt?: string | null;
  contactId?: number;
}

export interface AddressFormValues {
  addressBook: {
    companyName?: string;
    reference?: string;
    address: {
      country: {
        code?: string;
        countryCode?: string;
        label?: string;
        value?: string;
      };
      streetAddress?: string;
      street?: string;
      zip?: string;
      city?: string;
      states?: string;
      preferredAddressInputType?: string;
      addressId?: string;
      isCustomerVisible?: boolean;
      createdAt?: string | null;
      modifiedAt?: string | null;
      isValidated?: boolean;
    };
    contacts: AddressFormContact[];
  };
}

export const contactsObjInitialValue = {
  contactName: "",
  email: "",
  phoneNumber: "",
  contactType: [],
  actionType: SectionActionType.ADD,
};
