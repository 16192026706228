import { defineMessages } from "react-intl";

export const messages = defineMessages({
  getAQuote: {
    id: "widgets.getAQuote",
    defaultMessage: "Get a Quote",
  },
  goToAnalytics: {
    id: "widgets.goToAnalytics",
    defaultMessage: "Go to Analytics",
  },
});
