import {
  appDefaultZoom,
  initialCenter,
} from "../../components/organisms/DashboardMap/constants";
import actionTypes from "./action-types";
import { WidgetUpdateTypes, MapSelectAdditionalOptions } from "./constants";

const initialState = {
  map: {
    clickedMarker: null,
    sidebarOpen: true,
    mapTransportMode: MapSelectAdditionalOptions.ALL,
  },
  singleShipmentContainerDetails: {
    routePoints: [],
    markers: [],
  },
  widgetsUpdate: {
    [WidgetUpdateTypes.QUOTE]: {
      count: 0,
      id: undefined,
      isFavorite: undefined, // TODO use this field to see if apollo cache can be updated,
      calledFrom: "",
    },
    [WidgetUpdateTypes.LAST_VIEWED_SHIPMENTS]: {
      count: 0,
      id: undefined,
      calledFrom: "",
    },
  },
  widgetsLoaded: [],
  dashboardZoom: appDefaultZoom,
  dashboardCenter: initialCenter,
  numberLinesList: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DASHBOARD_ZOOM:
      return {
        ...state,
        dashboardZoom: action.payload,
      };
    case actionTypes.SET_DASHBOARD_CENTER:
      return {
        ...state,
        dashboardCenter: action.payload,
      };
    case actionTypes.ADD_WIDGET_LOADED:
      return {
        ...state,
        widgetsLoaded: [...state.widgetsLoaded, { type: action.payload }],
      };
    case actionTypes.SET_WIDGETS_UPDATE:
      return {
        ...state,
        widgetsUpdate: {
          ...state.widgetsUpdate,
          [action.payload.type]: {
            count: state.widgetsUpdate[action.payload.type].count + 1,
            id: action.payload.id,
            isFavorite: action.payload.isFavorite,
            calledFrom: action.payload.calledFrom,
          },
        },
      };
    case actionTypes.SET_SINGLE_SHIPMENT_CONTAINER_DETAILS:
      return {
        ...state,
        singleShipmentContainerDetails: {
          ...state.singleShipmentContainerDetails,
          ...action.payload,
        },
      };
    case actionTypes.RESET_SINGLE_SHIPMENT_CONTAINER_DETAILS:
      return {
        ...state,
        singleShipmentContainerDetails: {
          ...initialState.singleShipmentContainerDetails,
        },
      };
    case actionTypes.SET_CLICKED_MARKER:
      return {
        ...state,
        map: {
          ...state.map,
          clickedMarker: action.payload,
        },
      };
    case actionTypes.SET_SIDEBAR_OPEN:
      return {
        ...state,
        map: {
          ...state.map,
          sidebarOpen: action.payload,
        },
      };
    case actionTypes.SET_NUMBER_ELEMENTS_LIST:
      return {
        ...state,
        numberLinesList: action.payload,
      };
    case actionTypes.SET_ACTIVE_MARKER:
      return {
        ...state,
        map: {
          ...state.map,
          activeMarker: action.payload,
        },
      };
    case actionTypes.SET_MAP_TRANSPORT_MODE:
      return {
        ...state,
        map: {
          ...state.map,
          mapTransportMode: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
