import { Color, media } from "react-lib";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const LinkAsButton = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  height: 1.5rem;
  align-self: center;

  &,
  &:hover,
  &:visited,
  &:focus {
    text-decoration: none;
  }
`;

export const Label = styled.p`
  margin: 0 0.25rem 0 0;
  color: ${Color.webBlack};
  line-height: 1.5;
  font-weight: bold;
  display: none;

  ${media.from("md")} {
    display: block;
  }
`;
