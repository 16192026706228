import { defineMessages } from "react-intl";

export const messages = defineMessages({
  redirecting: {
    id: "login.redirecting",
    defaultMessage: "Redirecting",
  },
  logoutMessage: {
    id: "logout.message",
    defaultMessage: "Logging you out...",
  },
});
