import { apiDateFormat } from "constants/variables";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {
  setQuotesFilter,
  setShipmentFilter,
  setBookingsListFilter,
} from "redux/userSettings/actions";
import DateRangePickerController from "../DateRangePicker/DateRangePickerController";
import { messages } from "./messages";

const getDatePickerTexts = (intl, messages) => {
  return {
    clear: intl.formatMessage(messages.clear),
    apply: intl.formatMessage(messages.apply),
    today: intl.formatMessage(messages.today),
    yesterday: intl.formatMessage(messages.yesterday),
    tomorrow: intl.formatMessage(messages.tomorrow),
    nextWeek: intl.formatMessage(messages.nextWeek),
    lastWeek: intl.formatMessage(messages.lastWeek),
  };
};

const DateRange = (props) => {
  let filterFunc = props.setShipmentFilter;

  if (props.module === "quotes") {
    filterFunc = props.setQuotesFilter;
  } else if (props.module === "bookings") {
    filterFunc = props.setBookingsListFilter;
  }

  const intl = useIntl();

  return (
    <DateRangePickerController
      past={props.past}
      text={getDatePickerTexts(intl, messages)}
      onDatesApply={(obj) => {
        if (Array.isArray(props.filter)) {
          filterFunc(props.filter[0], props.transformQuote(obj).from);
          filterFunc(props.filter[1], props.transformQuote(obj).to);
        } else if (props.filter && (obj.startDate || obj.endDate)) {
          filterFunc(props.filter, props.transform(obj));
        }
        props.onOutsideClick();
      }}
      onOutsideClick={props.onOutsideClick}
      numberOfMonths={props.numberOfMonths}
    />
  );
};
DateRange.defaultProps = {
  transform: (obj) => {
    if (obj.startDate && obj.endDate)
      return {
        from: obj.startDate.format(apiDateFormat) + "T00:00",
        to: obj.endDate.format(apiDateFormat) + "T23:59",
      };
    if (obj.startDate)
      return {
        from: obj.startDate.format(apiDateFormat) + "T00:00",
        to: obj.startDate.format(apiDateFormat) + "T23:59",
      };
    if (obj.endDate)
      return {
        from: obj.endDate.format(apiDateFormat) + "T00:00",
        to: obj.endDate.format(apiDateFormat) + "T23:59",
      };
  },
  transformQuote: (obj) => {
    if (obj.startDate && obj.endDate)
      return {
        from: obj.startDate.startOf("day").format(apiDateFormat),
        to: obj.endDate.endOf("day").format(apiDateFormat),
      };
    if (obj.startDate)
      return {
        from: obj.startDate.startOf("day").format(apiDateFormat),
        to: obj.startDate.startOf("day").format(apiDateFormat),
      };
    if (obj.endDate)
      return {
        from: obj.endDate.startOf("day").format(apiDateFormat),
        to: obj.endDate.startOf("day").format(apiDateFormat),
      };
  },
  module: "shipments",
  dates: {},
};
DateRange.propTypes = {
  transform: PropTypes.func,
  onOutsideClick: PropTypes.func,
  module: PropTypes.string,
  setQuotesFilter: PropTypes.func,
  setShipmentFilter: PropTypes.func,
  dates: PropTypes.object,
  past: PropTypes.bool,
};

export default connect(
  (state) => ({
    userSettings: state.userSettings,
  }),
  { setShipmentFilter, setQuotesFilter, setBookingsListFilter }
)(DateRange);
