import { IncoTermsPaymentTerms } from "components/templates/BookingService/ServiceIncoTerms/constants";
import { handoverTypes } from "components/templates/QuoteRouting/constants";
import qs from "query-string";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  incrementDataChangedCount,
  setCPResolved,
} from "redux/quoteAndBookNonSync/actions";
import { resetData } from "redux/quoteData/actions";
import { saveVisibilityScope } from "redux/userSettings/actions";
import { hasValidRefreshToken } from "utils/auth";
import { usePrevious } from "utils/hooks";
import useActiveService from "./useActiveService";
import useCorporatePartnerUUID from "./useCorporatePartnerUUID";

export { default as useReportEditor } from "./useReportEditor";
export { default as useReportingFilter } from "./useReportingFilter";
export { default as useReportingPreview } from "./useReportingPreview";
export { default as useContactLink } from "./useContactLink";

export const useIsUserLoggedInToQuoteAndBookPortal = () => {
  const isUserLoggedIn =
    useSelector((state) => state.auth.isUserLoggedIn) && hasValidRefreshToken();
  const doesCurrentUserHaveQuoteRole = useSelector(
    (state) => state.auth?.doesCurrentUserHaveQuoteRole
  );
  const isCheckingDemoProfile = useSelector(
    (state) => state.app.isCheckingDemoProfile
  );
  const corporatePartner = useSelector(
    (state) => state?.userSettings?.visibilityScope?.key
  );
  const corporatePartnerUUID = useCorporatePartnerUUID().toUpperCase();
  const isDemoAccount = useSelector(
    (state) => state?.userSettings?.visibilityScope?.demo
  );
  return {
    isUserLoggedInToQuoteAndBookPortal:
      isUserLoggedIn &&
      !isCheckingDemoProfile &&
      corporatePartner &&
      doesCurrentUserHaveQuoteRole,
    isUserLoggedIn,
    doesCurrentUserHaveQuoteRole,
    corporatePartner: hasValidRefreshToken() ? corporatePartner : null,
    isCheckingDemoProfile,
    isDemoAccount,
    corporatePartnerUUID: hasValidRefreshToken() ? corporatePartnerUUID : null,
  };
};

export const useOnAccountTypeSwitch = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isQuoteRoute = useActiveService() === "OQT";
  const { isUserLoggedInToQuoteAndBookPortal, isDemoAccount } =
    useIsUserLoggedInToQuoteAndBookPortal();
  const isUserLoggedInToQuoteAndBookPortalPrevious = usePrevious(
    isUserLoggedInToQuoteAndBookPortal
  );
  useEffect(() => {
    if (
      isQuoteRoute &&
      isUserLoggedInToQuoteAndBookPortalPrevious &&
      isUserLoggedInToQuoteAndBookPortal
    ) {
      dispatch(resetData());
      dispatch(incrementDataChangedCount());
      history.push("/get-a-quote");
    }
    // eslint-disable-next-line
  }, [isDemoAccount]);
};

export const usePaymentTerm = ({ data, isGetAQuotePage }) => {
  const originCountryCode = data?.route?.origin?.country?.value;
  const destinationCountryCode = data?.route?.destination?.country?.value;
  const billingCountryCodeFromConsignment =
    data?.billing?.companyDetails?.address?.countryCode;
  const billingCountryCodeFromStore = useSelector(
    (state) => state.quoteAndBook.selectedCountry?.value
  );
  const billingCountryCode = isGetAQuotePage
    ? billingCountryCodeFromStore
    : billingCountryCodeFromConsignment;
  return useMemo(
    () =>
      originCountryCode === billingCountryCode
        ? IncoTermsPaymentTerms.PREPAID
        : destinationCountryCode === billingCountryCode
        ? IncoTermsPaymentTerms.COLLECT
        : IncoTermsPaymentTerms.CROSS_TRADE,
    [originCountryCode, destinationCountryCode, billingCountryCode]
  );
};

export const useShippingTerm = ({ product, originType, destinationType }) => {
  return useMemo(() => {
    if (!product) {
      return "";
    }
    let startingCode = "";
    let endingCode = "";
    if (originType === handoverTypes.COMPANY) {
      startingCode = "D";
    }
    if (destinationType === handoverTypes.COMPANY) {
      endingCode = "D";
    }
    const getTerminalCode = () =>
      product === "OFR"
        ? "P"
        : product === "AFR"
        ? "A"
        : product === "RFR"
        ? "R"
        : "";
    if (originType === handoverTypes.TERMINAL) {
      startingCode = getTerminalCode();
    }
    if (destinationType === handoverTypes.TERMINAL) {
      endingCode = getTerminalCode();
    }
    return `${startingCode}2${endingCode}`;
  }, [originType, destinationType, product]);
};

export const useSwitchCurrentCPBasedOnQueryParamCP = (isQueryStringified) => {
  const { corporatePartnerUUID } = useIsUserLoggedInToQuoteAndBookPortal();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = location?.search;
  const queryObject = qs.parse(queryString);
  const corporatePartners = useSelector(
    (state) => state.userSettings.corporatePartners
  );
  const queryCorporatePartner = isQueryStringified
    ? JSON.parse(queryObject?.data || "{}")?.corporatePartner
    : queryObject.corporatePartner;
  useEffect(() => {
    if (
      queryCorporatePartner &&
      corporatePartnerUUID &&
      corporatePartners.length
    ) {
      if (queryCorporatePartner !== corporatePartnerUUID) {
        const queryObjectCorporatePartnerObject = corporatePartners.find(
          (i) => i.uuid === queryCorporatePartner
        );
        if (queryObjectCorporatePartnerObject) {
          dispatch(
            saveVisibilityScope(
              queryObjectCorporatePartnerObject,
              queryObjectCorporatePartnerObject.demo
            )
          );
          dispatch(setCPResolved(true));
        } else {
          dispatch(setCPResolved(true));
        }
      } else {
        dispatch(setCPResolved(true));
      }
    }
  }, [
    queryCorporatePartner,
    corporatePartners,
    corporatePartnerUUID,
    dispatch,
  ]);
  return {
    corporatePartnerUUID,
    queryObject,
    location,
    queryCorporatePartner,
  };
};
