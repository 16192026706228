import React, { useEffect } from "react";
import { SnackBar } from "react-lib";
import { TYPES } from "react-lib/es/atoms/SnackBar/constants";
import { useDispatch, useSelector } from "react-redux";
import { hideNotification } from "redux/notification/actions";
import { RootState } from "redux/reducers";

const TOAST_TIMEOUT_MS = 3000;

const ToastNotification: React.FC = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state: RootState) => state.notification);
  const isVisible = !!notification.message?.length;

  useEffect(() => {
    if (isVisible && notification.shouldAutoHide) {
      setTimeout(() => {
        dispatch(hideNotification());
      }, TOAST_TIMEOUT_MS);
    }
  }, [
    dispatch,
    isVisible,
    notification.isClosable,
    notification.shouldAutoHide,
  ]);

  return (
    <SnackBar
      text={notification.message}
      type={notification.type || TYPES.NOTIFICATION}
      show={isVisible}
      showCloseIcon={!!notification.isClosable}
      icon={notification.icon}
      showTypeIcon={!!notification.icon}
      onCloseClick={() => dispatch(hideNotification())}
      shouldAutoHide={false}
    />
  );
};

export default ToastNotification;
