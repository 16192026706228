import { ReactComponent as CloseIcon } from "assets/icons/myDHLi/cancel.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { getRem } from "../../../utils/css";

const Wrapper = styled.div`
  height: ${getRem(64)};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.span`
  cursor: pointer;
`;

const closeSVGStyle = {
  width: getRem(12),
  height: getRem(12),
};

const Footer = ({ onClose, text }) => (
  <Wrapper>
    <CloseButton onClick={onClose}>
      <SVGWrapper
        width={closeSVGStyle.width}
        height={closeSVGStyle.height}
        fill="rgba(0, 0, 0, 0.6)"
      >
        <CloseIcon />
        {text}
      </SVGWrapper>
    </CloseButton>
  </Wrapper>
);
Footer.propTypes = {
  onClose: PropTypes.func,
  text: PropTypes.string,
};
Footer.defaultProps = {
  onClose: (f) => f,
  text: "Close",
};

export default Footer;
