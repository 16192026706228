import axios from "axios";
import { call, put as putEffect } from "redux-saga/effects";
import {
  deleteError,
  deleteSuccess,
  getError,
  getSuccess,
  pageError,
  pageSuccess,
  postActionError,
  postActionSuccess,
  putError,
  putSuccess,
} from "./actions";

export function* put({ payload }) {
  try {
    const response = yield call(axios.put, payload.endPoint, payload.data, {});
    yield putEffect(putSuccess(response.data, payload.key));
    if (payload.next) yield putEffect(payload.next);
  } catch (error) {
    yield putEffect(putError(payload.data, payload.key));
  }
}

export function* get({ payload }) {
  try {
    const response = yield call(axios.get, payload.endPoint, {
      params: payload.data,
    });
    yield putEffect(getSuccess(response.data, payload.key));
    if (payload.next) yield putEffect(payload.next);
  } catch (error) {
    yield putEffect(getError(payload.data, payload.key));
  }
}

export function* page({ payload, merger }) {
  try {
    const response = yield call(axios.get, payload.endPoint, {
      params: payload.data,
    });
    yield putEffect(pageSuccess(response.data, payload.key, merger));
    if (payload.next) yield putEffect(payload.next);
  } catch (error) {
    yield putEffect(pageError(payload.data, payload.key));
  }
}

export function* post({ payload }) {
  try {
    const response = yield call(axios.post, payload.endPoint, payload.data, {});
    yield putEffect(
      postActionSuccess({ data: response.data, key: payload.key })
    );
    if (payload.next) yield putEffect(payload.next);
    if (payload.cb) payload.cb({});
  } catch (e) {
    yield putEffect(postActionError({ error: { ...e }, key: payload.key }));
    if (payload.cb) payload.cb({ error: e });
  }
}

// Special naming cuz JS has "delete" as a reserved keyword and don't wanna mess with that!
export function* deleteFunc({ payload }) {
  try {
    yield call(axios.delete, payload.endPoint, {
      params: payload.params,
    });
    yield putEffect(deleteSuccess({ data: {}, key: payload.key }));
    if (payload.next) yield putEffect(payload.next);
    if (payload.cb) payload.cb({});
  } catch (e) {
    yield putEffect(deleteError({ error: { ...e }, key: payload.key }));
    if (payload.cb) payload.cb({ error: { ...e } });
  }
}
