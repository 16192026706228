import styled, { css } from "styled-components";
import { getRem, getRemMedia } from "utils/css";

const Container = styled.div`
  margin: 0 auto;
  padding-left: ${getRem(16)};
  padding-right: ${getRem(16)};
  @media (min-width: ${getRemMedia(768.2)}) {
    padding-left: ${getRem(20)};
    padding-right: ${getRem(20)};
  }
  ${(p) =>
    p.fluid
      ? css`
          width: 100%;
        `
      : null}
`;

export default Container;
