import CustomMinWidthButton from "components/atoms/Buttons/CustomMinWidthButton/CustomMinWidthButton";
import SVGWrapper from "components/atoms/SVGWrapper";
import { Color, Menu, ModalButtons } from "react-lib";
import styled from "styled-components";
import { SCREEN_SIZES } from "utils/css";
import { ModalContainerWidth } from "./AddressBook.constants";

export const HeaderWrapper = styled.div`
  background: ${(props) => props.theme.colors.postYellow};
  width: 100%;
  height: 4rem;
  padding: 1rem 1.25rem;
  font-size: 1.25rem;
  font-weight: 900;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${Color.black};
  display: flex;
`;

export const StyledSVGWrapper = styled(SVGWrapper)`
  align-self: center;
  width: 2rem;
  flex: 0 1;
`;

export const NewAddressWrapper = styled.div`
  align-self: center;
  padding: 0.25rem;
  font-weight: bold;
  width: 6rem;
  flex: 1;
`;

export const StyledModalTitle = styled.div`
  align-self: center;
  margin: 0.25rem;
  font-size: 1.25rem;
  font-weight: 900;
  font-stretch: condensed;
  flex: 1;
`;

export const StyledModalHeaderButtons = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: center;

  @media (max-width: ${SCREEN_SIZES.until.md}) {
    flex: 0 1 2rem;
  }
`;

export const StyledModalNewAddressButton = styled.div`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  flex: 1;
  display: flex;
  cursor: pointer;
`;

export const Content = styled.div`
  max-width: ${ModalContainerWidth};
  line-height: 1.5;
  color: ${Color.black};

  &&& {
    border-bottom: 0.1rem solid ${Color.gray08};
  }
`;

export const Th = styled.th`
  &&& {
    padding: 0.75rem;
  }
`;

export const Td = styled.td<{ isClicked: boolean }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 3rem;

  &&& {
    padding: 0.875rem 0.75rem;
    background-color: ${({ isClicked }) =>
      isClicked ? Color.gray08 : Color.transparent};

    @media (max-width: ${SCREEN_SIZES.until.md}) {
      max-width: 9rem;
    }
  }
`;

export const SmallTd = styled(Td)`
  &&& {
    width: 10rem;

    @media (max-width: ${SCREEN_SIZES.until.md}) {
      width: 8rem;
    }
  }
`;

export const StyledModalButtons = styled(ModalButtons)`
  gap: 1.25rem;
  height: 5.125rem;
  padding: 1rem;

  @media (max-width: ${SCREEN_SIZES.until.md}) {
    align-self: center;
  }
`;

export const StyledCustomMinWidthButton = styled(CustomMinWidthButton)`
  width: 8.75rem;
  height: 3rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0;
`;

export const AdvancedTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 35rem;
  overflow-y: scroll;
`;

export const StyledMenu = styled(Menu)`
  max-width: 1rem;
`;

export const StyledMenuWrapper = styled(StyledMenu)`
  max-width: 1rem;
`;

export const OptionsWrapper = styled.div`
  width: 1rem;
  fill: ${Color.black60};
`;

export const FiltersListContainer = styled.div<{ areFiltersHidden: boolean }>`
  margin-top: 1.3125rem;
  margin-bottom: 1.3125rem;
  margin-left: 1.3125rem;
  visibility: ${({ areFiltersHidden }) =>
    areFiltersHidden ? "hidden" : "visible"};
`;

export const StyledHeader = styled.div`
  svg {
    width: 1rem;
    margin-left: 0.5rem;
  }
`;
