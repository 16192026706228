import { useMutation } from "@apollo/client";
import CustomMinWidthButton from "components/atoms/Buttons/CustomMinWidthButton/CustomMinWidthButton";
import { AnimatePresence } from "framer-motion";
import { deleteAddressFromUsersAddressBookMutation } from "queries/MakeABooking/userAddressBookQueries";
import React from "react";
import { useIntl } from "react-intl";
import { ButtonVariants } from "react-lib/es/atoms/buttons2/Button.constants";
import * as C from "./DeleteModal.constans";
import messages from "./DeleteModal.messages";
import * as S from "./DeleteModal.styles";

const DeleteModal = ({
  id,
  isOpen,
  hideModal,
  onDeleteSuccess,
}: C.DeleteModalProps) => {
  const intl = useIntl();

  const [deleteAddressFromUsersAddressBook, { loading: isDeleting }] =
    useMutation(deleteAddressFromUsersAddressBookMutation);

  const onDeleteAddress = (): void => {
    deleteAddressFromUsersAddressBook({
      variables: {
        addressId: id,
      },
    }).then(() => {
      onDeleteSuccess();
    });
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <S.StyledBackdrop>
          <S.StyledModalContainer width={C.defaultModalWidth}>
            <S.ModalContentWrapper>
              <S.HeadlineWrapper>
                <S.Headline>
                  {intl.formatMessage(messages.deleteThisAddress)}
                </S.Headline>
              </S.HeadlineWrapper>
              <S.ButtonsSection>
                <S.CancelButton disabled={isDeleting} onClick={hideModal}>
                  {intl.formatMessage(messages.cancel)}
                </S.CancelButton>
                <CustomMinWidthButton
                  disabled={isDeleting}
                  onClick={onDeleteAddress}
                  variant={ButtonVariants.SECONDARY}
                >
                  {intl.formatMessage(messages.delete)}
                </CustomMinWidthButton>
              </S.ButtonsSection>
            </S.ModalContentWrapper>
          </S.StyledModalContainer>
        </S.StyledBackdrop>
      )}
    </AnimatePresence>
  );
};

export default DeleteModal;
