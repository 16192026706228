import CustomMinWidthButton from "components/atoms/Buttons/CustomMinWidthButton/CustomMinWidthButton";
import { Color, Backdrop, ModalContainer } from "react-lib";
import styled from "styled-components";
import { SCREEN_SIZES } from "utils/css";

export const StyledBackdrop = styled(Backdrop)`
  background-color: ${Color.black10};
`;

export const StyledModalContainer = styled(ModalContainer)`
  height: auto;
  margin: 0 1.25rem;
`;

export const ModalContentWrapper = styled.div`
  padding: 1.5rem 2.5rem;
`;

export const ButtonsSection = styled.div`
  border-top: 1px solid ${Color.gray20};
  padding: 1.5rem 0 0 0;
  display: flex;
  justify-content: flex-end;
`;

export const CancelButton = styled(CustomMinWidthButton)`
  margin-right: 1.5rem;
`;

export const HeadlineWrapper = styled.div`
  padding: 2.25rem 0;
`;

export const Headline = styled.h2`
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 1.2;
  color: ${Color.webBlack};
  margin: 0;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    font-size: 1.5rem;
    line-height: 1.17;
  }
`;
