import gql from "graphql-tag";

const getPreferenceItemGQL = gql`
  query GetPreferenceItemQuery($userObjectsFilter: [UserObject]) {
    getPreferenceItem(userObjectsFilter: $userObjectsFilter) {
      userObjects {
        key
        userObjectPreferences {
          key
          value
        }
      }
    }
  }
`;
export default getPreferenceItemGQL;
