import {
  AddressBookRequestSchema,
  AddressBookFormSchema,
} from "../components/AddressBookModalContent/AddressBookForm/AddressBookFormFields/AddressBookFormFields.constants";

export const parseFormValuesToRequestSchema = (
  formValues: AddressBookFormSchema & { id?: number }
): AddressBookRequestSchema => {
  const {
    addressBook: { addressType, address, contactDetails },
    id,
  } = formValues;

  return {
    addressBook: {
      address: {
        accountNumber: "", // TODO BE would need this property in future
        addressLine1: address.streetAddress || "",
        addressLine2: address.street || "",
        city: address.city || "",
        companyName: contactDetails.companyName || "",
        countryCode: address?.country?.countryCode || "",
        postalCode: address.zip || "",
        reference: contactDetails.reference,
        stateProvince: address.states || "",
      },
      contact: {
        contactName: contactDetails.contactName || "",
        email: contactDetails.email || "",
        phone: contactDetails.phoneNumber || "",
      },
      addressFlags: {
        isBilling: addressType.isBilling,
        isConsignee: addressType.isConsignee,
        isDelivery: addressType.isDelivery,
        isPickup: addressType.isPickup,
        isShipper: addressType.isShipper,
        isNotify: addressType.isNotify,
      },
    },
    id,
  };
};
