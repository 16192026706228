import getConfigClient from "constants/getConfigClient";
import { useQuery } from "@apollo/client";
import { SideBarItemsObject } from "data/nav";
import useMyDHLiFeatureFlags from "hooks/useMyDHLiFeatureFlags";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import startsWith from "lodash/startsWith";
import PropTypes from "prop-types";
import { loadMenuItems } from "queries/AAA/profile";
import React from "react";
import { useIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setAppState } from "redux/app/actions";
import { useCheckHasReportingRole } from "utils";
import get from "utils/get";
import { includesAnyOf } from "utils/string";
import SideBarActiveIndicator from "./SideBarActiveIndicator";
import SideBarItem from "./SideBarItem";
import SideBarItemsWrapper from "./SideBarItemsWrapper";

const useMenuItems = (backOfficeItems, hasReportingRole) => {
  const featureFlags = useMyDHLiFeatureFlags();

  if (!backOfficeItems?.length || !featureFlags) {
    return [SideBarItemsObject.DASHBOARD];
  }

  const filteredItems = backOfficeItems.filter(
    (item) => featureFlags[item.type] ?? getConfigClient(item.type) ?? true
  );
  const menuItems = filteredItems.map((item) => ({
    ...item,
    ...(SideBarItemsObject[item.type?.toUpperCase?.()] || {}),
  }));

  // todo: remove after defined in backoffice
  if (
    hasReportingRole &&
    !find(menuItems, { type: "REPORTS" }) &&
    includesAnyOf(getConfigClient("BUILD_ENVIRONMENT"), [
      "development",
      "test",
      "local",
    ])
  ) {
    menuItems.push({ ...SideBarItemsObject.REPORTS, order: 10 });
  }
  if (!find(menuItems, { type: "DASHBOARD" })) {
    menuItems.unshift({ ...SideBarItemsObject.DASHBOARD, order: 0.5 });
  }

  return menuItems.sort((a, b) => a.order - b.order);
};

const getTrackId = (key) => {
  switch (key.toLowerCase()) {
    case "dashboard":
      return 2;
    case "shipments":
      return 3;
    case "quotes":
      return 4;
    case "analytics":
      return 5;
    case "reports":
      return 3001;
    default:
      return 0;
  }
};

const SideBarItems = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const hasReportingRole = useCheckHasReportingRole();
  const isLandscape = window.innerHeight < window.innerWidth;

  const corporatePartnerId = useSelector(
    (state) => state.userSettings.visibilityScope?.id
  );

  const { data } = useQuery(loadMenuItems, {
    variables: {
      corporatePartnerId,
    },
    skip: !corporatePartnerId,
  });

  let menuItemsBackOffice = get(data, "loadMenuItems", []);

  if (!Array.isArray(menuItemsBackOffice)) menuItemsBackOffice = [];

  const menuItems = useMenuItems(menuItemsBackOffice, hasReportingRole);

  let currentItemIndex = findIndex(menuItems, (o) => {
    return location.pathname === o.route;
  });

  // this logic should be changed and use separate key from SideBarItemsObject like "routes" to handle multiple urls for one item (for example bookings-drafts, order-lines etc)
  if (startsWith(location.pathname, "/analytics")) {
    currentItemIndex = findIndex(menuItems, (o) => {
      return o.route === "/analytics";
    });
  }

  if (startsWith(location.pathname, "/reporting")) {
    currentItemIndex = findIndex(menuItems, (o) => {
      return o.route === "/reporting";
    });
  }

  if (startsWith(location.pathname, "/order")) {
    currentItemIndex = findIndex(menuItems, (o) => {
      return o.route === "/orders";
    });
  }

  return (
    <SideBarItemsWrapper {...props} isLandscape={isLandscape}>
      <SideBarActiveIndicator currentItemIndex={currentItemIndex} />
      {menuItems.map((item) => (
        <SideBarItem
          data-track={getTrackId(item.type)}
          {...item}
          key={item.type}
          text={
            intl.messages[item.translationKey]
              ? intl.formatMessage({ id: item.translationKey })
              : item.label || item.text
          }
          isListItem
          onClick={() => {
            if (props.belowLg) {
              dispatch(setAppState("navCollapsed", true));
            }
            dispatch(setAppState("navHovered", false));
          }}
          showBadge={false}
        />
      ))}
    </SideBarItemsWrapper>
  );
};
SideBarItems.defaultProps = {};
SideBarItems.propTypes = {
  belowLg: PropTypes.bool,
};

export default connect((state) => ({ rest: state.rest }), null)(SideBarItems);
