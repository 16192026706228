import actionTypes from "./action-types";

const initialState = {
  type: null,
  data: {},
  props: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW:
      return {
        type: action.payload.type,
        data: action.payload.data,
        props: action.payload.props || {},
      };
    case actionTypes.HIDE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
