import { CountriesList } from "constants/bookWorkflow";
import * as MDM from "constants/masterDataManagement";

import { transformCountryCode } from "./transformCountryCode";

export const transformCountriesList = (
  countries: MDM.Country[]
): MDM.Country[] =>
  countries.map((country: MDM.Country) => {
    const countryCode = transformCountryCode(country.code);

    return {
      ...country,
      label: `${country.label} (${countryCode})`,
    };
  });

export const createCountriesList = (
  countries: MDM.Country[]
): CountriesList => {
  const result: CountriesList = {};

  countries.forEach((country: MDM.Country) => {
    result[country.code] = {
      name: country.label,
      code: country.code,
    };
  });

  return result;
};
