import { ReactComponent as ArrowDownIcon } from "assets/icons/myDHLi/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/myDHLi/arrow-up.svg";
import { ReactComponent as SortIcon } from "assets/icons/myDHLi/sort.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { SORT_DIRECTIONS, sortTypes } from "redux/sort/constants";
import styled from "styled-components";
import { getRem } from "utils/css";

const SORT_ICON_DIMENSIONS = {
  width: getRem(20),
  height: getRem(20),
};

export const SortIconWrapper = styled(SVGWrapper).attrs({
  width: SORT_ICON_DIMENSIONS.width,
  height: SORT_ICON_DIMENSIONS.height,
})`
  visibility: ${(p) => (p.current && p.type ? "visible" : "hidden")};
  & svg:hover {
    fill: ${(props) => props.theme.colors.dhlRed};
  }
`;

const getSortDirectionOrder = (sortDirectionOnFirstClick) =>
  sortDirectionOnFirstClick === SORT_DIRECTIONS.UP
    ? [SORT_DIRECTIONS.UP, SORT_DIRECTIONS.DOWN, undefined]
    : [SORT_DIRECTIONS.DOWN, SORT_DIRECTIONS.UP, undefined];

const IconForDirection = {
  [SORT_DIRECTIONS.UP]: <ArrowUpIcon />,
  [SORT_DIRECTIONS.DOWN]: <ArrowDownIcon />,
  [undefined]: <SortIcon />,
};

const getNextIcon = ({ sortDirectionOrder, currentSortDirection }) => {
  const currentSortDirectionIndex =
    sortDirectionOrder.indexOf(currentSortDirection);
  const newIconIndex =
    currentSortDirectionIndex === sortDirectionOrder.length - 1
      ? 0
      : currentSortDirectionIndex + 1;
  return IconForDirection[sortDirectionOrder[newIconIndex]];
};

const Sort = ({
  type,
  onClick,
  sortKey,
  current,
  sortDirectionOnFirstClick,
}) => {
  const [hoverIng, setHoverIng] = useState(false);
  const sortDirectionOrder = useMemo(() => {
    return getSortDirectionOrder(sortDirectionOnFirstClick);
  }, [sortDirectionOnFirstClick]);
  let icon;

  if (type === sortTypes.LOWER_CASE_ASC || type === sortTypes.CAPITAL_ASC) {
    if (hoverIng) {
      icon = getNextIcon({
        sortDirectionOrder,
        currentSortDirection: SORT_DIRECTIONS.DOWN,
      });
    } else {
      icon = <ArrowDownIcon />;
    }
  } else if (
    type === sortTypes.LOWER_CASE_DESC ||
    type === sortTypes.CAPITAL_DESC
  ) {
    if (hoverIng) {
      icon = getNextIcon({
        sortDirectionOrder,
        currentSortDirection: SORT_DIRECTIONS.UP,
      });
    } else {
      icon = <ArrowUpIcon />;
    }
  } else {
    if (hoverIng) {
      icon = getNextIcon({
        sortDirectionOrder,
        currentSortDirection: undefined,
      });
    } else {
      icon = <SortIcon />;
    }
  }
  return (
    <SortIconWrapper
      onClick={() => {
        onClick({
          key: sortKey,
          sortDirectionOnFirstClick,
        });
      }}
      showPointer
      current={current}
      type={type}
      onMouseEnter={() => setHoverIng(true)}
      onMouseLeave={() => setHoverIng(false)}
    >
      {icon}
    </SortIconWrapper>
  );
};
Sort.propTypes = {
  type: PropTypes.oneOf(Object.values(sortTypes)),
  sortDirectionOnFirstClick: PropTypes.string,
};
Sort.defaultProps = {
  type: undefined,
  sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
};

export default Sort;
