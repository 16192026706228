import { Booking } from "constants/bookWorkflow";
import { useParams } from "react-router-dom";
import { useBookingDataById } from "./useBookingDataById";

interface UseBookWorkflowDataOptions {
  onCompleted?: (data: { booking: Booking }) => void;
}

export const useBookWorkflowData = ({
  onCompleted,
}: UseBookWorkflowDataOptions = {}): Partial<OmitGraphQLTypename<Booking>> => {
  const { bookingId } = useParams<{ bookingId: string }>();

  return useBookingDataById({ onCompleted, bookingId });
};
