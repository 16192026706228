import { Color } from "react-lib";
import { ElementHeading } from "routes/MakeABooking/MakeABooking.styles";
import styled from "styled-components";

export const StyledHeadline = styled(ElementHeading)`
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.17;
  letter-spacing: normal;
  color: ${Color.webBlack};
  margin-bottom: 1.25rem;
`;

export const AddressSectionContainer = styled.div`
  padding-bottom: 1.25rem;
`;
