import isEqual from "lodash/isEqual";
import { useMemo, useReducer } from "react";

const undoInitialState = ({
  compareStates = isEqual,
  mergeStates = (newState, oldState) =>
    typeof newState === "function" ? newState(oldState) : newState,
}) => ({
  compareStates,
  mergeStates,
  history: [],
  pointer: -1,
  current: {},
  canUndo: false,
  canRedo: false,
});
const undoReducer = (state, action) => {
  let { current, history, pointer, compareStates, mergeStates } = state;
  switch (action.type) {
    case "push":
      {
        const newState = mergeStates(action.payload, current);
        if (!compareStates(current, newState)) {
          history = [...history.slice(0, pointer + 1), newState];
          pointer += 1;
        }
      }
      break;

    case "undo":
      pointer -= 1;
      break;

    case "redo":
      pointer += 1;
      break;

    case "init":
      pointer = 0;
      history = [
        compareStates(current, action.payload) ? current : action.payload,
      ];
      break;

    case "commit":
      pointer = 0;
      history = history.slice(-1);
      break;
    default:
      break;
  }
  return {
    history,
    pointer,
    current: pointer >= history.length ? {} : history[pointer],
    canUndo: pointer > 0,
    canRedo: pointer < history.length - 1,
    compareStates,
    mergeStates,
  };
};

const useStateHistory = (initArgs = {}) => {
  const [state, dispatch] = useReducer(undoReducer, initArgs, undoInitialState);
  const actions = useMemo(
    () => ({
      push: (payload) => dispatch({ type: "push", payload }),
      undo: () => dispatch({ type: "undo" }),
      redo: () => dispatch({ type: "redo" }),
      init: (payload) => dispatch({ type: "init", payload }),
      commit: () => dispatch({ type: "commit" }),
    }),
    []
  );

  return {
    ...state,
    ...actions,
  };
};

export default useStateHistory;
