import gql from "graphql-tag";

export const USER_ADDRESS_BOOK_QUERY = gql`
  query UserAddressBookQuery(
    $orderBy: String
    $orderDirection: String
    $paging: InputPaging
    $searchText: String
    $filters: [UserAddressBookFilter]
    $corporatePartnerUUID: String
  ) {
    getUserAddressBook(
      orderBy: $orderBy
      orderDirection: $orderDirection
      paging: $paging
      searchText: $searchText
      filters: $filters
      corporatePartnerUUID: $corporatePartnerUUID
    ) {
      addresses {
        id
        address {
          companyName
          addressLine1
          addressLine2
          city
          postalCode
          stateProvince
          reference
          countryCode
        }
        addressFlags {
          isBilling
          isConsignee
          isDelivery
          isPickup
          isShipper
          isNotify
        }
        contact {
          contactName
          email
          phone
        }
      }
      page {
        totalCount
      }
    }
  }
`;
