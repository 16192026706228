import * as MDM from "constants/masterDataManagement";
import { KeyOption, matchSorter } from "match-sorter";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { transformCountryCode } from "../helpers/transformCountryCode";

const defaultMatchSorterKeys: KeyOption<MDM.Country>[] = [
  "countryCode",
  "label",
];

const useSortedCountries = (
  countryList: MDM.Country[],
  matchSorterKeys: KeyOption<MDM.Country>[] = defaultMatchSorterKeys
) => {
  const [countryInputValue, setCountryInputValue] = useState("");
  const intl = useIntl();

  const sortedOptions = useMemo(() => {
    const list = countryList
      ?.map((listEl) => ({
        ...listEl,
        label: listEl.name_weblate_key
          ? `${intl.formatMessage({
              id: listEl.name_weblate_key,
              defaultMessage: listEl.label,
            })} (${transformCountryCode(listEl.code)})`
          : listEl.label,
      }))
      .sort((firstCountry, secondCountry) => {
        return firstCountry.label.localeCompare(secondCountry.label);
      });

    return countryInputValue
      ? matchSorter(list, countryInputValue, {
          keys: matchSorterKeys,
          threshold: matchSorter.rankings.CONTAINS,
        })
      : list;
  }, [countryList, countryInputValue, matchSorterKeys, intl]);

  return {
    setCountryInputValue,
    sortedOptions,
  };
};

export default useSortedCountries;
