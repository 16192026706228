import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "BillingInteractions";

export const BillingInteractions = {
  COMPANY_DETAILS: { id: `${prefix}_Company Details`, name: "Company Details" },
  ENTER_A_NEW_ADDRESS: {
    id: `${prefix}_Enter a new address`,
    name: "Enter a new address",
  },
  SHARE: { id: `${prefix}_Share`, name: "Share" },
  GO_TO_SUMMARY: { id: `${prefix}_Go to Summary`, name: "Go to Summary" },
};

enum BillingEventPosition {
  COMPANY_DETAILS = "Company Details",
  SHARE = "Share",
  FOOTER = "Footer",
}

const billingContext = "Billing";

export const billingEventNameToBillingEventContentMapper: C.EventNameToContentMapperType<
  typeof BillingInteractions
> = {
  [BillingInteractions.COMPANY_DETAILS.id]: {
    name: BillingInteractions.COMPANY_DETAILS.name,
    ...eventContentButtonInterObj,
    position: BillingEventPosition.COMPANY_DETAILS,
    context: billingContext,
  },
  [BillingInteractions.ENTER_A_NEW_ADDRESS.id]: {
    name: BillingInteractions.ENTER_A_NEW_ADDRESS.name,
    ...eventContentButtonInterObj,
    position: BillingEventPosition.COMPANY_DETAILS,
    context: billingContext,
  },
  [BillingInteractions.SHARE.id]: {
    name: BillingInteractions.SHARE.name,
    ...eventContentButtonInterObj,
    position: BillingEventPosition.SHARE,
    context: billingContext,
  },
  [BillingInteractions.GO_TO_SUMMARY.id]: {
    name: BillingInteractions.GO_TO_SUMMARY.name,
    ...eventContentButtonInterObj,
    position: BillingEventPosition.FOOTER,
    context: billingContext,
  },
};
