import { Color } from "react-lib";
import styled from "styled-components";

const BookingElementHeadingHint = styled.p`
  margin: 0;
  padding: 0.325rem 0 0 0;
  font-size: 0.75rem;
  line-height: 1.33;
  color: ${Color.webBlack};
  min-height: 1.325rem;
`;

export default BookingElementHeadingHint;
