import { defineMessages } from "react-intl";

export default defineMessages({
  headline: {
    id: "npsRating.modal.headline",
    defaultMessage:
      "How likely are you to recommend myDHLi to  colleagues, business partners or customers?",
  },
  textAreaPlaceholder: {
    id: "npsRating.modal.textArea.placeholder",
    defaultMessage: "Your Feedback (Optional)",
  },
  checkboxLabel: {
    id: "npsRating.modal.checkbox.label",
    defaultMessage:
      "I would like to receive a call-back to discuss my experience further.",
  },
  phoneNumberLabel: {
    id: "npsRating.modal.phoneNumber.label",
    defaultMessage: "Phone Number",
  },
  submit: {
    id: "npsRating.modal.button.label",
    defaultMessage: "Submit",
  },
  phoneValidationTooShort: {
    id: "npsRating.modal.phoneNumber.error.tooShort",
    defaultMessage: "Phone Number is too short",
  },
  phoneValidationTooLong: {
    id: "npsRating.modal.phoneNumber.error.tooLong",
    defaultMessage: "Phone Number is too long",
  },
});
