import { AddressInputType, PartyType } from "constants/bookWorkflow";
import * as MC from "constants/masterDataManagement";
import { FormApi } from "final-form";
import { validateEmail } from "helpers/joiValidation";
import joi from "joi";
import { ReactElement } from "react";
import { PartyFormValues } from "routes/MakeABooking/BookingRoute/BookingRoute.constants";

export const ADDRESS_SEARCH_MIN_LENGTH = 3;

export interface StreetAddressProps {
  shouldRenderAddressBook?: boolean;
  areFieldsDisabled?: boolean;
  isCountryHintHidden?: boolean;
  shouldRenderSearch: boolean;
  shouldForceFieldsErrorVisibility?: boolean;
  fieldPrefix: string;
  onCountryChangeDecorator?: (onCountryChange: () => void) => void;
  isForAddressBook?: boolean;
  extraFields?: ReactElement[];
  referredType?: PartyType;
}

export interface SearchOptionType {
  label: string;
  value: string;
  lookup: () => Promise<MapProviderDataType>;
}

export interface MapProviderDataType {
  place_id: string;
  zipCode: string;
  addressLine: string;
  label: string;
  label_native: string;
  city: string;
  stateProvince: string;
}

export interface FormUpdateOnCountryChangeType {
  form: FormApi;
  fieldPrefix: string;
  newCountry: MC.Country;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setSelectSearchValue: React.Dispatch<React.SetStateAction<{}>>;
  setAddressInputTypeField: () => void;
  isForAddressBook: boolean;
  isLocalityBasedAddress: boolean;
}

export const iso2code = 2;
export const internationalDialCode = 3;

export const routeCollapsedFormInitialData: PartyFormValues = {
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    countryCode: "",
    postalCode: "",
    stateProvince: "",
  },
  contactDetails: {
    email: undefined,
    name: undefined,
    phone: "",
  },
  companyName: "",
  terminalCode: undefined,
  useTerminal: "false",
};

export type PartyPrefixType =
  | "pickup"
  | "delivery"
  | "shipper"
  | "consignee"
  | "notify";

export const postalCodeValidationSchema = joi.when(
  "preferredAddressInputType",
  {
    is: joi
      .valid(
        AddressInputType.LOCALITY_LOCREG_LIST,
        AddressInputType.LOCALITY_MANUAL
      )
      .required(),
    then: joi.string().optional().allow(null, ""),
    otherwise: joi.string().max(10).required(),
  }
);

export const addressValidationSchema = joi.object({
  countryCode: joi.string().required(),
  addressLine1: joi.string().max(100).required(),
  addressLine2: joi.string().allow(null, ""),
  postalCode: postalCodeValidationSchema,
  city: joi.string().max(50).required(),
  stateProvince: joi.string().max(25).optional().allow(null, ""),
});

export const contactDetailsValidationSchema = joi.object({
  name: joi.string().max(256).required(),
  email: joi.string().custom(validateEmail).max(254).required(),
  phone: joi.string().min(7).max(20).required(),
});

export const streetAddressValidationSchema = joi.object({
  companyName: joi.string().max(100).required(),
  address: addressValidationSchema.required(),
  contactDetails: contactDetailsValidationSchema.required(),
});
