import { PartyType } from "constants/bookWorkflow";
import { IntlShape } from "react-intl";
import messages from "../AddOrEditAddressForm.messages";

export const generatePartyTypeOptions = (intl: IntlShape) => [
  { label: intl.formatMessage(messages.pickUp), value: PartyType.PICKUP },
  { label: intl.formatMessage(messages.shipper), value: PartyType.SHIPPER },
  { label: intl.formatMessage(messages.delivery), value: PartyType.DELIVERY },
  { label: intl.formatMessage(messages.consignee), value: PartyType.CONSIGNEE },
  { label: intl.formatMessage(messages.billing), value: PartyType.BILLING },
  { label: intl.formatMessage(messages.notify), value: PartyType.NOTIFY },
];
