import getConfigClient from "constants/getConfigClient";
import React from "react";
import { getRem } from "utils/css";

const RedirectToTrackMessage = (props) => {
  return (
    <div style={{ padding: getRem(20) }}>
      {"Redirecting to: " + getConfigClient("TRACK_PORTAL_URL")}
    </div>
  );
};

export default RedirectToTrackMessage;
