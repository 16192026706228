import { all, fork, takeEvery } from "redux-saga/effects";
import types from "./action-types";
import { deleteFunc, get, page, post, put } from "./rest-calls";

function* watchUpdate() {
  yield takeEvery(types.put, put);
}

function* watchGet() {
  yield takeEvery(types.get, get);
}

function* watchPage() {
  yield takeEvery(types.page, page);
}

function* watchPost() {
  yield takeEvery(types.post, post);
}

function* watchDelete() {
  yield takeEvery(types.deleteType, deleteFunc);
}

export default function* rootSaga() {
  yield all([
    fork(watchUpdate),
    fork(watchGet),
    fork(watchPage),
    fork(watchPost),
    fork(watchDelete),
  ]);
}
