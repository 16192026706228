import getConfigClient from "constants/getConfigClient";
import { useQuery } from "@apollo/client";
import { useLanguage } from "containers/Translations/Translations.context";
import { GET_USER_DETAILS } from "queries/AAA/userQueries";
import GET_AEM_BY_LANGUAGE_AND_COUNTRY from "queries/Reporting/getAemByLanguageAndCountry";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { hasValidRefreshToken, transformRequest } from "utils/auth";
import { quoteAndBookPaths } from "../constants/paths";
import useIsRouteActive from "./useIsRouteActive";

const norwegianLanguageCode = "nb";

export const useFeedbackLink = () => {
  const intl = useIntl();
  const langCode = useLanguage();
  const isQnB = useIsRouteActive(quoteAndBookPaths);
  const countryCodeFromProfile = useSelector((state) => state.app?.userCountry);
  const { data } = useQuery(GET_AEM_BY_LANGUAGE_AND_COUNTRY, {
    variables: {
      params: {
        languageCode: intl.locale.replaceAll("-", "_") ?? langCode,
        countryCode: countryCodeFromProfile,
      },
    },
  });

  const linkAEMCode =
    data?.getAemByLanguageAndCountry?.code_aem?.replaceAll("_", "-") ?? "en";

  const { data: userData } = useQuery(GET_USER_DETAILS, {
    skip: !hasValidRefreshToken(),
  });

  const isDemo = useSelector(
    (state) => state.userSettings.visibilityScope
  )?.demo;

  const params = {
    urlvar01: userData?.user?.address?.country?.code || "",
    urlvar02: userData?.user?.firstName || "",
    urlvar03: userData?.user?.lastName || "",
    urlvar04: userData?.user?.companyName || "",
    urlvar05: userData?.user?.phoneNumber || "",
    urlvar06: userData?.user?.email || "",
    urlvar07: isQnB ? "QB" : "myDHLi",
  };
  let feedbackUrl = getConfigClient("FEEDBACK_URL");

  if (getConfigClient("BUILD_ENVIRONMENT") === "production") {
    feedbackUrl = feedbackUrl + "-" + linkAEMCode;

    if (linkAEMCode === norwegianLanguageCode) {
      feedbackUrl = feedbackUrl.replaceAll("-", "_");
    }
  } else {
    params.l = linkAEMCode;
  }

  if (isDemo) {
    const demoParams = `urlvar01=DK&urlvar02=Martin&urlvar03=Hansen&urlvar04=WindTurbineSolutions&urlvar05=451234567890&urlvar06=martin.hansen@demo-account.com&urlvar07=${
      isQnB ? "QB" : "myDHLi"
    }`;
    return getConfigClient("BUILD_ENVIRONMENT") === "production"
      ? `${feedbackUrl}?${demoParams}`
      : `${feedbackUrl}?l=${linkAEMCode}&${demoParams}`;
  }
  if (!userData?.user?.email) {
    return feedbackUrl;
  }
  return `${feedbackUrl}?${transformRequest(params)}`;
};
