import * as MDM from "constants/masterDataManagement";
import { NetworkStatus, useQuery } from "@apollo/client";
import useCorporatePartnerUUID from "hooks/useCorporatePartnerUUID";
import { GET_CONFIGS, GET_COUNTRIES } from "queries/AAA/masterDataManagement";
import { USER_ADDRESS_BOOK_QUERY } from "queries/MakeABooking/getUserAddressBook";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { AdvancedTable } from "react-lib";
import { useMediaQuery } from "react-responsive";
import { Waypoint } from "react-waypoint";
import { SCREEN_SIZES } from "utils/css";
import { TableAction } from "../StreetAddress/AddressBook/AddressBook.constants";
import { AddressModalContext } from "../StreetAddress/AddressBook/AddressBookContext";
import * as C from "./AddressBook.constants";
import { RowMenuOptions } from "./AddressBook.constants";
import * as H from "./AddressBook.helpers";
import * as S from "./AddressBook.styles";
import AddressBookEmpty from "./AddressBookEmptyList/AddressBookEmptyList";

const AddressBookTable = ({
  setSelectedAddress,
  openAddAddressModal,
  openEditAddressModal,
  openDeleteAddressModal,
  activeFilters,
  setSelectedFilterId,
  hideFilters,
  showFilters,
}: C.AddressBookTableProps): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.until.md });
  const intl = useIntl();
  const translateAddressFlag = H.useTranslateAddressFlag();
  const [addresses, setAddresses] = useState<C.UserAddressBook[]>([]);
  const [hoveredRowId, setHoveredRowId] = useState(-1);
  const [clickedRowId, setClickedRowId] = useState(-1);
  const advancedTableWrapperRef = useRef(null);
  const corporatePartner = useCorporatePartnerUUID() || "";

  const { tableAction, setTableAction, countryList } =
    useContext(AddressModalContext);

  const { data: configsData, networkStatus: configsDataNetworkStatus } =
    useQuery<{ getConfigs: C.Config[] }>(GET_CONFIGS);

  const { data: countriesData } =
    useQuery<{ getCountries: MDM.Country[] }>(GET_COUNTRIES);

  const orderByParamFromConfig = configsData?.getConfigs?.find(
    (el) => el.KEY === C.AddressBookDefaultSorting
  )?.VALUE;

  const enrichData = useCallback(
    (
      addressesFromResponse: C.UserAddressBook[] | undefined
    ): C.UserAddressBook[] => {
      return (
        addressesFromResponse?.map((entry: C.UserAddressBook) => ({
          ...entry,
          country: countryList?.find(
            (country) => country?.code === entry?.address?.countryCode
          )?.label,
          type: translateAddressFlag(entry.addressFlags),
          concatAddress: H.concatAddress(entry),
        })) || []
      );
    },
    [countryList, translateAddressFlag]
  );

  const {
    data,
    refetch,
    networkStatus: userAddressNetworkStatus,
  } = useQuery<C.UserAddressBookResponse>(USER_ADDRESS_BOOK_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      paging: {
        page: 0,
        size: C.UserAddressBookQueryPageSize,
      },
      filters: H.normalizeActiveFilters(
        activeFilters,
        countriesData?.getCountries
      ),
      orderBy: orderByParamFromConfig || C.defaultOrderingParameter,
      orderDirection: C.defaultOrderingDirection,
      // searchText: "Komats", // For future filtering feature,
      corporatePartnerUUID: corporatePartner,
    },
  });

  const addressBookFetchPartParams = useMemo(
    () => ({
      paging: {
        page: Math.ceil(addresses?.length / C.UserAddressBookQueryPageSize),
        size: C.UserAddressBookQueryPageSize,
      },
      filters: H.normalizeActiveFilters(
        activeFilters,
        countriesData?.getCountries
      ),
      orderBy: orderByParamFromConfig || C.defaultOrderingParameter,
      orderDirection: C.defaultOrderingDirection,
    }),
    [
      activeFilters,
      addresses?.length,
      countriesData?.getCountries,
      orderByParamFromConfig,
    ]
  );

  const addressBookFetchAllParams = useMemo(
    () => ({
      paging: {
        page: 0,
        size: addresses?.length + C.UserAddressBookQueryPageSize,
      },
      filters: H.normalizeActiveFilters(
        activeFilters,
        countriesData?.getCountries
      ),
      orderBy: orderByParamFromConfig || C.defaultOrderingParameter,
      orderDirection: C.defaultOrderingDirection,
    }),
    [
      activeFilters,
      addresses?.length,
      countriesData?.getCountries,
      orderByParamFromConfig,
    ]
  );

  const isDataLoading =
    configsDataNetworkStatus !== NetworkStatus.ready ||
    userAddressNetworkStatus !== NetworkStatus.ready ||
    !countryList ||
    countryList?.length === 0;

  const columns = useMemo(
    () =>
      isMobile
        ? [
            H.getAddressBookOptionsColumn(),
            ...H.getAddressBookColumns(
              intl,
              activeFilters,
              setSelectedFilterId
            ).slice(0, 2),
          ]
        : [
            ...H.getAddressBookColumns(
              intl,
              activeFilters,
              setSelectedFilterId
            ),
            H.getAddressBookOptionsColumn(),
          ],
    [activeFilters, intl, isMobile, setSelectedFilterId]
  );

  const isAddressBookEmpty = !isDataLoading && addresses?.length === 0;

  useEffect(() => {
    if (isAddressBookEmpty) {
      hideFilters();
    } else {
      showFilters();
    }
  });

  useEffect(() => {
    if (!isDataLoading && data) {
      if (tableAction === TableAction.FetchPart) {
        // Partial fetching of addresses, especially for waypoint
        refetch(addressBookFetchPartParams).then((response) => {
          setAddresses([
            ...addresses,
            ...enrichData(response.data?.getUserAddressBook?.addresses),
          ]);
        });
        setTableAction(null);
      } else if (tableAction === TableAction.FetchAll) {
        // Fetch all addresses, especially for add/edit/delete
        refetch(addressBookFetchAllParams).then((response) => {
          setAddresses(
            enrichData(response.data?.getUserAddressBook?.addresses)
          );
        });
        setTableAction(null);
      } else if (
        tableAction === null &&
        addresses?.length === 0 &&
        data?.getUserAddressBook?.addresses.length
      ) {
        // Initial setting
        setAddresses(enrichData(data?.getUserAddressBook?.addresses));
      }
    }
  }, [
    tableAction,
    data,
    isDataLoading,
    addressBookFetchAllParams,
    addressBookFetchPartParams,
    addresses,
    refetch,
    setTableAction,
    enrichData,
  ]);

  const selectMenuOptionForListItem = useCallback(
    (addressId: number, menuOption: RowMenuOptions) => {
      const selectedAddress = addresses.find(
        (address) => address.id === addressId
      );
      if (selectedAddress) {
        if (menuOption === RowMenuOptions.EDIT) {
          openEditAddressModal(selectedAddress);
        } else {
          openDeleteAddressModal(selectedAddress);
        }
      }
    },
    [openEditAddressModal, openDeleteAddressModal, addresses]
  );

  const rowRendererCallback = useCallback(
    (args: C.RowContentRendererProps) => {
      return H.selectableRowRenderer(
        {
          ...args,
          hoveredRowId,
          setHoveredRowId,
          clickedRowId,
          setClickedRowId,
          intl,
          selectMenuOptionForListItem,
        },
        setSelectedAddress
      );
    },
    [
      clickedRowId,
      hoveredRowId,
      intl,
      setSelectedAddress,
      selectMenuOptionForListItem,
    ]
  );

  const canFetchMore =
    (data?.getUserAddressBook?.page?.totalCount || 0) > addresses?.length &&
    addresses?.length > 0;

  const handleWaypoint = useCallback(() => {
    setTableAction(TableAction.FetchPart);
  }, [setTableAction]);

  return (
    <S.AdvancedTableWrapper ref={advancedTableWrapperRef}>
      {isAddressBookEmpty ? (
        <AddressBookEmpty openAddAddressModal={openAddAddressModal} />
      ) : (
        <AdvancedTable
          columns={columns}
          rows={addresses}
          isLoading={isDataLoading}
          headerRowRenderer={() =>
            addresses?.length ? H.customHeaderRowRenderer({ columns }) : null
          }
          rowRenderer={rowRendererCallback}
        />
      )}
      {canFetchMore && (
        <Waypoint
          scrollableAncestor={advancedTableWrapperRef?.current}
          onEnter={handleWaypoint}
          bottomOffset="-24px"
        />
      )}
    </S.AdvancedTableWrapper>
  );
};

export default AddressBookTable;
