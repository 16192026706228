import { useQuery } from "@apollo/client";
import { loadAnalyticsItems } from "queries/AAA/profile";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AnalyticsIframe from "routes/Analytics/AnalyticsIframe";
import { getTabs } from "routes/Analytics/helper";
import { messages as analyticsMessages } from "routes/Analytics/messages";
import { getArray } from "utils/get";

const AnalyticsRenderer = () => {
  const corporatePartnerId = useSelector(
    (state) => state.userSettings.visibilityScope.id
  );

  const { data, loading } = useQuery(loadAnalyticsItems, {
    variables: {
      corporatePartnerId: corporatePartnerId,
    },
  });

  const intl = useIntl();
  const tabs = useMemo(() => {
    const analyticsItems = getArray(data, "loadAnalyticsItems");
    return getTabs(analyticsItems).map((i) => ({
      ...i,
      text: intl.messages[i.translationKey]
        ? intl.formatMessage({ id: i.translationKey })
        : i.text || intl.formatMessage(analyticsMessages.tabTitle_undefined),
    }));
  }, [data, intl]);

  return <AnalyticsIframe tabs={tabs} loading={loading} />;
};

export default AnalyticsRenderer;
