import SimpleBar from "simplebar-react";
import styled from "styled-components";
import CustomOption from "../../Filters/ListBucketSearch/Partials/CustomOption";

export const FilterAutoCompleteWrapper = styled.div`
  width: 17.5rem;
  max-height: 28.875rem;
  height: fit-content;
`;

export const StyledCustomOption = styled(CustomOption)``;
export const StyledSimpleBar = styled(SimpleBar)`
  max-height: 26rem;
`;

export const NoMatch = styled.div`
  display: flex;
  padding: 0.875rem 0 0.875rem 0.875rem;
  background-color: ${(p) => p.theme.colors.gray08};
  margin-bottom: 0.625rem;
`;
