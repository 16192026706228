import countries from "data/countries";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import {
  setQuotesFilter,
  setShipmentFilter,
  setBookingsListFilter,
} from "redux/userSettings/actions";
import styled from "styled-components";
import { getRem } from "../../../utils/css";
import customStyles from "./customStyles";
import CustomOption from "./ListBucketSearch/Partials/CustomOption";

const countriesList = countries.map((item) => ({
  label: item.name,
  value: item.code,
}));

const FilterItemWrapper = styled.div`
  width: ${getRem(250)};
  min-height: ${getRem(300)};
`;

const Countries = (props) => {
  const applyFilter = (val) => {
    if (props.module === "bookings") {
      props.setBookingsListFilter(props.filter, val);
    } else if (props.module === "quotes") {
      props.setQuotesFilter(props.filter, val);
    } else {
      props.setShipmentFilter(props.filter, val);
    }
    props.onOutsideClick();
  };

  return (
    <FilterItemWrapper>
      <ReactSelect
        inputId="countrySearch"
        name="countrySearch"
        options={countriesList}
        onChange={(item) => {
          if (item) {
            applyFilter(item.value);
          }
        }}
        components={{
          Option: CustomOption,
        }}
        placeholder="Type to see suggestion"
        menuIsOpen
        isClearable
        styles={customStyles}
        autoFocus
        autoComplete="off"
      />
    </FilterItemWrapper>
  );
};
Countries.defaultProps = {
  module: "shipments",
};
Countries.propTypes = {
  module: PropTypes.string,
};

export default connect(
  (state) => ({
    userSettings: state.userSettings,
  }),
  { setShipmentFilter, setQuotesFilter, setBookingsListFilter }
)(Countries);
