import { addressBookFormFields } from "./AddressBook.constants";

export const generateFormFields = () => {
  return {
    countryFieldName: addressBookFormFields.countryCode,
    country: addressBookFormFields.country,
    addressLine2: addressBookFormFields.street,
    addressLine1: addressBookFormFields.streetAddress,
    postalCode: addressBookFormFields.zip,
    city: addressBookFormFields.city,
    stateProvince: addressBookFormFields.states,
    preferredAddressInputType: addressBookFormFields.preferredAddressInputType,
    companyName: addressBookFormFields.companyName,
    contactDetailsName: addressBookFormFields.contactName,
    email: addressBookFormFields.email,
    phone: addressBookFormFields.phoneNumber,
    reference: addressBookFormFields.reference,
    contacts: addressBookFormFields.contacts,
    contactType: addressBookFormFields.contactType,
  };
};
