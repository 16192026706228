import * as C from "./constants";

/** Call this when
 * 1. anything gets changed in the form, inside formSpy
 * 2. on floating Save button click
 */
export const incrementSaveConsignmentAPICount = (): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.INCREMENT_SAVE_CONSIGNMENT_API_COUNT
> => ({
  type: C.ActionsType.INCREMENT_SAVE_CONSIGNMENT_API_COUNT,
});

/** Call this when fields related to price get changed
 * Along with this, also call incrementSaveConsignmentAPICount
 */
export const incrementRecalculateQuoteAPICount = (
  payload: string
): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.INCREMENT_RECALCULATE_QUOTE_API_COUNT
> => ({
  type: C.ActionsType.INCREMENT_RECALCULATE_QUOTE_API_COUNT,
  payload,
});

export const incrementGetConsignmentCountAPI = (): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.INCREMENT_GET_CONSIGNMENT_API_COUNT
> => ({
  type: C.ActionsType.INCREMENT_GET_CONSIGNMENT_API_COUNT,
});

export const incrementCalculateQuoteAPICount = (): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.INCREMENT_CALCULATE_QUOTE_API_COUNT
> => ({
  type: C.ActionsType.INCREMENT_CALCULATE_QUOTE_API_COUNT,
});

export const setRecalculatingQuote = (
  payload: boolean
): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.SET_RECALCULATING_QUOTE
> => ({
  type: C.ActionsType.SET_RECALCULATING_QUOTE,
  payload,
});

export const incrementDataChangedCount = (): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.INCREMENT_DATA_CHANGED_COUNT
> => ({
  type: C.ActionsType.INCREMENT_DATA_CHANGED_COUNT,
});

export const setRecalculationError = (payload: {
  code: string;
  value: boolean;
}): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.SET_RECALCULATION_ERROR
> => ({
  type: C.ActionsType.SET_RECALCULATION_ERROR,
  payload,
});

export const resetRecalculationError = (): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.RESET_RECALCULATION_ERROR
> => ({
  type: C.ActionsType.RESET_RECALCULATION_ERROR,
});

export const setContractIdInStore = (payload: {
  contractId?: string;
  fromPath?: string;
  locale?: string;
}): GetAction<C.QuoteAndBookNonSyncActions, C.ActionsType.SET_CONTRACT_ID> => ({
  type: C.ActionsType.SET_CONTRACT_ID,
  payload,
});

export const setUserCountryViaGeoLocation = (
  payload: object
): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.SET_USER_COUNTRY_VIA_GEO_LOCATION
> => ({
  type: C.ActionsType.SET_USER_COUNTRY_VIA_GEO_LOCATION,
  payload,
});

export const setIsR2R = (
  payload: boolean
): GetAction<C.QuoteAndBookNonSyncActions, C.ActionsType.SET_IS_R2R> => ({
  type: C.ActionsType.SET_IS_R2R,
  payload,
});

export const setCPResolved = (
  payload: boolean
): GetAction<C.QuoteAndBookNonSyncActions, C.ActionsType.SET_CP_RESOLVED> => ({
  type: C.ActionsType.SET_CP_RESOLVED,
  payload,
});

export const resetConsignmentData = (): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.RESET_CONSIGNMENT_DATA
> => ({
  type: C.ActionsType.RESET_CONSIGNMENT_DATA,
});

export const saveConsignmentForm = (
  payload: object
): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.SAVE_CONSIGNMENT_FORM
> => ({
  type: C.ActionsType.SAVE_CONSIGNMENT_FORM,
  payload,
  meta: {
    debounce: {
      time: 500,
    },
  },
});

export const setGetOffersLoading = (
  payload: boolean
): GetAction<
  C.QuoteAndBookNonSyncActions,
  C.ActionsType.SET_GET_OFFERS_LOADING
> => ({
  type: C.ActionsType.SET_GET_OFFERS_LOADING,
  payload,
});
