export const getPostalCode = (address) => {
  return getShortName(address?.address_components, "postal_code");
};

export const getLongName = (addressComponents, type) => {
  return (
    addressComponents &&
    addressComponents.find((x) => x.types.includes(type))?.long_name
  );
};
export const getShortName = (addressComponents, type) => {
  return (
    addressComponents &&
    addressComponents.find((x) => x.types.includes(type))?.short_name
  );
};

export const getLongAndShortName = (addressComponents, type) => {
  return [
    getLongName(addressComponents, type),
    getShortName(addressComponents, type),
  ];
};
