import getConfigClient from "constants/getConfigClient";

export const buckets = [
  "TRANSPORT_MODE",
  "ORIGIN",
  "DESTINATION",
  "TRANSIT_STATUS",
  "DELAY_STATUS",
  "LAST_EVENT",
  "SHIPPER",
  "CONSIGNEE",
  "CUSTOMER_NAME",
  "ORIGIN_PORT",
  "DESTINATION_PORT",
  "ORIGIN_COUNTRY_NAME",
  "DESTINATION_COUNTRY_NAME",
  "INCOTERMS",
  "INCOTERMS_NAME",
];

export const limitExportShipments = parseInt(
  getConfigClient("LIMIT_EXPORT_SHIPMENTS"),
  10
);

export const shipmentListType = {
  SHIPMENTS_LIST: "shipmentsList",
  MAP_SHIPMENTS_LIST: "mapShipmentsList",
  MAP_TRACKING_LIST: "mapTrackingList",
};
