import {
  eventContentButtonInterObj,
  eventContentCardInterObj,
  eventContentCheckboxInterObj,
  eventContentChipInterObj,
  eventContentLinkInterObj,
  eventContentListHeaderInterObj,
  eventContentMenuInterObj,
  eventContentMenuItemInterObj,
  eventContentSearchInterObj,
  eventContentSelectFieldInterObj,
  eventContentSortIconInterObj,
} from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "ShipmentsInteractions";

export const ShipmentsInteractions = {
  TABLE_SORTING: { id: `${prefix}_Sort by`, name: "Sort by" },
  REFRESH_TABLE: { id: `${prefix}_Refresh Table`, name: "Refresh Table" },
  TABLE_OPTIONS: { id: `${prefix}_Table options`, name: "Table options" },
  RESET_FILTERS: { id: `${prefix}_Reset Filter`, name: "Reset Filter" },
  FOLLOWING_SHIPMENT: {
    id: `${prefix}_Following Shipment`,
    name: "Following Shipment",
  },
  UNFOLLOWING_SHIPMENT: {
    id: `${prefix}_Unfollowing Shipment`,
    name: "Unfollowing Shipment",
  },
  SHARE_SHIPMENT: { id: `${prefix}_Share Shipment`, name: "Share Shipment" },
  INDIVIDUAL_SHIPMENT: {
    id: `${prefix}_Individual Shipment`,
    name: "Individual Shipment",
  },
  GET_A_QUOTE: { id: `${prefix}_Get a Quote`, name: "Get a Quote" },

  SEARCH_SHIPMENTS: {
    id: `${prefix}_Search shipments`,
    name: "Search shipments",
  },
  OPEN_FILTER_MODAL: {
    id: `${prefix}_filter modal`,
    name: "filter modal",
  },
  RELOAD_CLICK: {
    id: `${prefix}_reload`,
    name: "reload",
  },
  EXPORT_CLICK: {
    id: `${prefix}_export`,
    name: "export",
  },
  ROLE_CLICK: {
    id: `${prefix}_role`,
    name: "role",
  },
  SELECT_SHIPPER: {
    id: `${prefix}_select shipper`,
    name: "select shipper",
  },
  SELECT_CONSIGNEE: {
    id: `${prefix}_select consignee`,
    name: "select consignee",
  },
  SELECT_CARRIER: {
    id: `${prefix}_select carrier`,
    name: "select carrier",
  },
  SELECT_CUSTOMER: {
    id: `${prefix}_select customer`,
    name: "select customer",
  },
  SORT_BY_PICKUP: {
    id: `${prefix}_sort by pick-up`,
    name: "sort by pick-up",
  },
  SORT_BY_DEPARTURE: {
    id: `${prefix}_sort by departure`,
    name: "sort by departure",
  },
  SORT_BY_ARRIVAL: {
    id: `${prefix}_sort by arrival`,
    name: "sort by arrival",
  },
  SORT_BY_DELIVERY: {
    id: `${prefix}_sort by delivery`,
    name: "sort by delivery",
  },
  LIST_SETTINGS_CLICK: {
    id: `${prefix}_settings`,
    name: "settings",
  },
  OPEN_SHIPMENT: {
    id: `${prefix}_open shipment`,
    name: "open shipment",
  },
  ZERO_SEARCH_RESULTS: {
    id: `${prefix}_zero search results`,
    name: "zero search results",
  },
  FILTER_MODAL_FROM_COUNTRY_SEARCH: {
    id: `${prefix}_from-country`,
    name: "from-country",
  },
  FILTER_MODAL_TO_COUNTRY_SEARCH: {
    id: `${prefix}_to-country`,
    name: "to-country",
  },
  FILTER_MODAL_SHIPPER_SEARCH: {
    id: `${prefix}_shipper-name`,
    name: "shipper-name",
  },
  FILTER_MODAL_CONSIGNEE_SEARCH: {
    id: `${prefix}_consignee-name`,
    name: "consignee-name",
  },
  FILTER_MODAL_CUSTOMER_SEARCH: {
    id: `${prefix}_customer-name`,
    name: "customer-name",
  },
  DATA_MILESTONE_CLICK: {
    id: `${prefix}_data-milestone`,
    name: "data-milestone",
  },
  DATA_MILESTONE_PICKUP_SELECT: {
    id: `${prefix}_milestone-pick-up`,
    name: "milestone-pick-up",
  },
  DATA_MILESTONE_DEPARTURE_SELECT: {
    id: `${prefix}_milestone-departure`,
    name: "milestone-departure",
  },
  DATA_MILESTONE_ARRIVAL_SELECT: {
    id: `${prefix}_milestone-arrival`,
    name: "milestone-arrival",
  },
  DATA_MILESTONE_DELIVERY_SELECT: {
    id: `${prefix}_milestone-delivery`,
    name: "milestone-delivery",
  },
  DATA_MILESTONE_EXPORT_CUSTOMS_CLEARED_SELECT: {
    id: `${prefix}_milestone-export-customs-cleared`,
    name: "milestone-export-customs-cleared",
  },
  MILESTONE_SELECT: {
    id: `${prefix}_Milestone`,
    name: "Milestone",
  },
  DATE_ANY_TIME_SELECT: {
    id: `${prefix}_date-any-time`,
    name: "date-any-time",
  },
  DATE_TODAY_SELECT: {
    id: `${prefix}_date-today`,
    name: "date-today",
  },
  DATE_TOMORROW_SELECT: {
    id: `${prefix}_date-tomorrow`,
    name: "date-tomorrow",
  },
  DATE_YESTERDAY_SELECT: {
    id: `${prefix}_date-yesterday`,
    name: "date-yesterday",
  },
  DATE_NEXT_7_DAYS_SELECT: {
    id: `${prefix}_date-next-7-days`,
    name: "date-next-7-days",
  },
  CUSTOMS_DECLARATION_CHECKED: {
    id: `${prefix}_customs-declaration-checked`,
    name: "customs-declaration-checked",
  },
  EXCEPTIONS_CHECKED: {
    id: `${prefix}_exceptions-checked`,
    name: "exceptions-checked",
  },
  FOLLOWED_CHECKED: {
    id: `${prefix}_followed-checked`,
    name: "followed-checked",
  },
  STATUS_ONTIME_CHECKED: {
    id: `${prefix}_status-ontime-checked`,
    name: "status-ontime-checked",
  },
  STATUS_DELAYED_CHECKED: {
    id: `${prefix}_status-delayed-checked`,
    name: "status-delayed-checked",
  },
  PHASE_PLANNED_CHECKED: {
    id: `${prefix}_phase-planned-checked`,
    name: "phase-planned-checked",
  },
  PHASE_COMPLETED_CHECKED: {
    id: `${prefix}_phase-completed-checked`,
    name: "phase-completed-checked",
  },
  PHASE_INTRANSIT_CHECKED: {
    id: `${prefix}_phase-intransit-checked`,
    name: "phase-intransit-checked",
  },
  MODE_AIR_FREIGHT_SELECTED: {
    id: `${prefix}_mode-air-freight`,
    name: "mode-air-freight",
  },
  MODE_OCEAN_FREIGHT_SELECTED: {
    id: `${prefix}_mode-ocean-freight`,
    name: "mode-ocean-freight",
  },
  MODE_RAIL_FREIGHT_SELECTED: {
    id: `${prefix}_mode-rail-freight`,
    name: "mode-rail-freight",
  },
  MODE_ROAD_FREIGHT_SELECTED: {
    id: `${prefix}_mode-road-freight`,
    name: "mode-road-freight",
  },
  PRODUCT_AFR_SAMEDAY_CHECKED: {
    id: `${prefix}_product-afr-sameday`,
    name: "product-afr-sameday",
  },
  PRODUCT_AFR_ECONOMY_CHECKED: {
    id: `${prefix}_product-afr-economy`,
    name: "product-afr-economy",
  },
  PRODUCT_AFR_TEMPERATURE_CONTROLLED_CHECKED: {
    id: `${prefix}_product-afr-temperature-controlled`,
    name: "product-afr-temperature-controlled",
  },
  PRODUCT_OFR_LCL_CHECKED: {
    id: `${prefix}_product-ofr-lcl`,
    name: "product-ofr-lcl",
  },
  PRODUCT_OFR_NON_CONTAINERISED_LOAD_CHECKED: {
    id: `${prefix}_product-ofr-non-containerised-load`,
    name: "product-ofr-non-containerised-load",
  },
  PRODUCT_OFR_TEMPERATURE_CONTROLLED_CHECKED: {
    id: `${prefix}_product-ofr-temperature-controlled`,
    name: "product-ofr-temperature-controlled",
  },
  PRODUCT_RAIL_LCL_CHECKED: {
    id: `${prefix}_product-rail-lcl`,
    name: "product-rail-lcl",
  },
  PRODUCT_RAIL_FTL_AND_PTL_CHECKED: {
    id: `${prefix}_product-rail-ftl-and-ptl`,
    name: "product-rail-ftl-and-ptl",
  },
  PRODUCT_AFR_PRIORITY_CHECKED: {
    id: `${prefix}_product-afr-priority`,
    name: "product-afr-priority",
  },
  PRODUCT_AFR_TAILORED_LIFT_CHECKED: {
    id: `${prefix}_product-afr-tailored-lift`,
    name: "product-afr-tailored-lift",
  },
  PRODUCT_OFR_FCL_CHECKED: {
    id: `${prefix}_product-ofr-fcl`,
    name: "product-ofr-fcl",
  },
  PRODUCT_BUYERS_SHIPPERS_CONSOLIDATION_CHECKED: {
    id: `${prefix}_product-buyers-shippers-consolidation`,
    name: "product-buyers-shippers-consolidation",
  },
  PRODUCT_OFR_BULK_CHECKED: {
    id: `${prefix}_product-ofr-bulk`,
    name: "product-ofr-bulk",
  },
  PRODUCT_RAIL_FTL_CHECKED: {
    id: `${prefix}_product-rail-ftl`,
    name: "product-rail-ftl",
  },
  PRODUCT_ROAD_LTL_CHECKED: {
    id: `${prefix}_product-road-ltl`,
    name: "product-road-ltl",
  },
  PRODUCT_CUSTOMS_SERVICE_CHECKED: {
    id: `${prefix}_product-customs-service`,
    name: "product-customs-service",
  },
  CLEAR_ALL_FILTERS_CLICK: {
    id: `${prefix}_clear-all-filters`,
    name: "clear-all-filters",
  },
  CANCEL_FILTERS_CLICK: {
    id: `${prefix}_cancel-filters`,
    name: "cancel-filters",
  },
  SHOW_RESULTS: {
    id: `${prefix}_show-results`,
    name: "show-results",
  },
};

enum ShipmentsPosition {
  TABLE_HEADER = "Table Header",
  PAGE_HEADER = "Page Header",
  TOOL_BAR = "Tool bar",
  ROLE_MENU = "Role Menu",
  SHIPMENT_ROW = "Shipment Row",
  SHIPMENT_LIST = "Shipment List",
  ROUTE = "Route",
  DATE = "Date",
  CUSTOMS = "customs",
  IMPORTANCE = "importance",
  STATUS = "status",
  PHASE = "phase",
  TRANSPORT_MODE = "transport mode",
  PRODUCT = "product",
  BUTTON_BAR = "Button Bar",
}

const shipmentsListContext = "Shipment List";
const pageOverlayContext = "Page Overlay";
const shipmentFiltersModalContext = "Shipment Filters Modal";

export const shipmentsEventNameToShipmentsEventContentMapper: C.EventNameToContentMapperType<
  typeof ShipmentsInteractions
> = {
  [ShipmentsInteractions.TABLE_SORTING.id]: {
    name: ShipmentsInteractions.TABLE_SORTING.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.REFRESH_TABLE.id]: {
    name: ShipmentsInteractions.REFRESH_TABLE.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.TABLE_OPTIONS.id]: {
    name: ShipmentsInteractions.TABLE_OPTIONS.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.RESET_FILTERS.id]: {
    name: ShipmentsInteractions.RESET_FILTERS.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.FOLLOWING_SHIPMENT.id]: {
    name: ShipmentsInteractions.FOLLOWING_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: "0",
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.UNFOLLOWING_SHIPMENT.id]: {
    name: ShipmentsInteractions.UNFOLLOWING_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: "0",
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SHARE_SHIPMENT.id]: {
    name: ShipmentsInteractions.SHARE_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: "0",
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.INDIVIDUAL_SHIPMENT.id]: {
    name: ShipmentsInteractions.INDIVIDUAL_SHIPMENT.name,
    ...eventContentLinkInterObj,
    position: "0",
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.GET_A_QUOTE.id]: {
    name: ShipmentsInteractions.GET_A_QUOTE.name,
    ...eventContentLinkInterObj,
    position: ShipmentsPosition.PAGE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.EXPORT_CLICK.id]: {
    name: ShipmentsInteractions.EXPORT_CLICK.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TOOL_BAR,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.OPEN_FILTER_MODAL.id]: {
    name: ShipmentsInteractions.OPEN_FILTER_MODAL.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TOOL_BAR,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.RELOAD_CLICK.id]: {
    name: ShipmentsInteractions.RELOAD_CLICK.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.TOOL_BAR,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.ROLE_CLICK.id]: {
    name: ShipmentsInteractions.ROLE_CLICK.name,
    ...eventContentListHeaderInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SELECT_SHIPPER.id]: {
    name: ShipmentsInteractions.SELECT_SHIPPER.name,
    ...eventContentMenuItemInterObj,
    position: ShipmentsPosition.ROLE_MENU,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SELECT_CONSIGNEE.id]: {
    name: ShipmentsInteractions.SELECT_CONSIGNEE.name,
    ...eventContentMenuItemInterObj,
    position: ShipmentsPosition.ROLE_MENU,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SELECT_CARRIER.id]: {
    name: ShipmentsInteractions.SELECT_CARRIER.name,
    ...eventContentMenuItemInterObj,
    position: ShipmentsPosition.ROLE_MENU,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SELECT_CUSTOMER.id]: {
    name: ShipmentsInteractions.SELECT_CUSTOMER.name,
    ...eventContentMenuItemInterObj,
    position: ShipmentsPosition.ROLE_MENU,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SORT_BY_PICKUP.id]: {
    name: ShipmentsInteractions.SORT_BY_PICKUP.name,
    ...eventContentSortIconInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SORT_BY_DEPARTURE.id]: {
    name: ShipmentsInteractions.SORT_BY_DEPARTURE.name,
    ...eventContentSortIconInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SORT_BY_ARRIVAL.id]: {
    name: ShipmentsInteractions.SORT_BY_ARRIVAL.name,
    ...eventContentSortIconInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SORT_BY_DELIVERY.id]: {
    name: ShipmentsInteractions.SORT_BY_DELIVERY.name,
    ...eventContentSortIconInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.LIST_SETTINGS_CLICK.id]: {
    name: ShipmentsInteractions.LIST_SETTINGS_CLICK.name,
    ...eventContentSortIconInterObj,
    position: ShipmentsPosition.TABLE_HEADER,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.OPEN_SHIPMENT.id]: {
    name: ShipmentsInteractions.OPEN_SHIPMENT.name,
    ...eventContentCardInterObj,
    position: ShipmentsPosition.SHIPMENT_ROW,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.ZERO_SEARCH_RESULTS.id]: {
    name: ShipmentsInteractions.ZERO_SEARCH_RESULTS.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.SHIPMENT_LIST,
    context: shipmentsListContext,
  },
  [ShipmentsInteractions.SEARCH_SHIPMENTS.id]: {
    name: ShipmentsInteractions.SEARCH_SHIPMENTS.name,
    ...eventContentSearchInterObj,
    position: ShipmentsPosition.TOOL_BAR,
    context: pageOverlayContext,
  },

  [ShipmentsInteractions.FILTER_MODAL_FROM_COUNTRY_SEARCH.id]: {
    name: ShipmentsInteractions.FILTER_MODAL_FROM_COUNTRY_SEARCH.name,
    ...eventContentSearchInterObj,
    position: ShipmentsPosition.ROUTE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.FILTER_MODAL_TO_COUNTRY_SEARCH.id]: {
    name: ShipmentsInteractions.FILTER_MODAL_TO_COUNTRY_SEARCH.name,
    ...eventContentSearchInterObj,
    position: ShipmentsPosition.ROUTE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.FILTER_MODAL_SHIPPER_SEARCH.id]: {
    name: ShipmentsInteractions.FILTER_MODAL_SHIPPER_SEARCH.name,
    ...eventContentSearchInterObj,
    position: ShipmentsPosition.ROUTE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.FILTER_MODAL_CONSIGNEE_SEARCH.id]: {
    name: ShipmentsInteractions.FILTER_MODAL_CONSIGNEE_SEARCH.name,
    ...eventContentSearchInterObj,
    position: ShipmentsPosition.ROUTE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.FILTER_MODAL_CUSTOMER_SEARCH.id]: {
    name: ShipmentsInteractions.FILTER_MODAL_CUSTOMER_SEARCH.name,
    ...eventContentSearchInterObj,
    position: ShipmentsPosition.ROUTE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATA_MILESTONE_CLICK.id]: {
    name: ShipmentsInteractions.DATA_MILESTONE_CLICK.name,
    ...eventContentSelectFieldInterObj,
    position: ShipmentsPosition.ROUTE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATA_MILESTONE_PICKUP_SELECT.id]: {
    name: ShipmentsInteractions.DATA_MILESTONE_PICKUP_SELECT.name,
    ...eventContentMenuInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATA_MILESTONE_DEPARTURE_SELECT.id]: {
    name: ShipmentsInteractions.DATA_MILESTONE_DEPARTURE_SELECT.name,
    ...eventContentMenuInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATA_MILESTONE_ARRIVAL_SELECT.id]: {
    name: ShipmentsInteractions.DATA_MILESTONE_ARRIVAL_SELECT.name,
    ...eventContentMenuInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATA_MILESTONE_DELIVERY_SELECT.id]: {
    name: ShipmentsInteractions.DATA_MILESTONE_DELIVERY_SELECT.name,
    ...eventContentMenuInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATA_MILESTONE_EXPORT_CUSTOMS_CLEARED_SELECT.id]: {
    name: ShipmentsInteractions.DATA_MILESTONE_EXPORT_CUSTOMS_CLEARED_SELECT
      .name,
    ...eventContentMenuInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.MILESTONE_SELECT.id]: {
    name: ShipmentsInteractions.MILESTONE_SELECT.name,
    ...eventContentSelectFieldInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATE_ANY_TIME_SELECT.id]: {
    name: ShipmentsInteractions.DATE_ANY_TIME_SELECT.name,
    ...eventContentMenuInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATE_TODAY_SELECT.id]: {
    name: ShipmentsInteractions.DATE_TODAY_SELECT.name,
    ...eventContentMenuInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATE_TOMORROW_SELECT.id]: {
    name: ShipmentsInteractions.DATE_TOMORROW_SELECT.name,
    ...eventContentMenuInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATE_YESTERDAY_SELECT.id]: {
    name: ShipmentsInteractions.DATE_YESTERDAY_SELECT.name,
    ...eventContentMenuInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.DATE_NEXT_7_DAYS_SELECT.id]: {
    name: ShipmentsInteractions.DATE_NEXT_7_DAYS_SELECT.name,
    ...eventContentMenuInterObj,
    position: ShipmentsPosition.DATE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.CUSTOMS_DECLARATION_CHECKED.id]: {
    name: ShipmentsInteractions.CUSTOMS_DECLARATION_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.CUSTOMS,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.EXCEPTIONS_CHECKED.id]: {
    name: ShipmentsInteractions.EXCEPTIONS_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.CUSTOMS,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.FOLLOWED_CHECKED.id]: {
    name: ShipmentsInteractions.FOLLOWED_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.IMPORTANCE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.STATUS_ONTIME_CHECKED.id]: {
    name: ShipmentsInteractions.STATUS_ONTIME_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.STATUS,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.STATUS_DELAYED_CHECKED.id]: {
    name: ShipmentsInteractions.STATUS_DELAYED_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.STATUS,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PHASE_PLANNED_CHECKED.id]: {
    name: ShipmentsInteractions.PHASE_PLANNED_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PHASE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PHASE_COMPLETED_CHECKED.id]: {
    name: ShipmentsInteractions.PHASE_COMPLETED_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PHASE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PHASE_INTRANSIT_CHECKED.id]: {
    name: ShipmentsInteractions.PHASE_INTRANSIT_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PHASE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.MODE_AIR_FREIGHT_SELECTED.id]: {
    name: ShipmentsInteractions.MODE_AIR_FREIGHT_SELECTED.name,
    ...eventContentChipInterObj,
    position: ShipmentsPosition.TRANSPORT_MODE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.MODE_OCEAN_FREIGHT_SELECTED.id]: {
    name: ShipmentsInteractions.MODE_OCEAN_FREIGHT_SELECTED.name,
    ...eventContentChipInterObj,
    position: ShipmentsPosition.TRANSPORT_MODE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.MODE_RAIL_FREIGHT_SELECTED.id]: {
    name: ShipmentsInteractions.MODE_RAIL_FREIGHT_SELECTED.name,
    ...eventContentChipInterObj,
    position: ShipmentsPosition.TRANSPORT_MODE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.MODE_ROAD_FREIGHT_SELECTED.id]: {
    name: ShipmentsInteractions.MODE_ROAD_FREIGHT_SELECTED.name,
    ...eventContentChipInterObj,
    position: ShipmentsPosition.TRANSPORT_MODE,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_AFR_SAMEDAY_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_AFR_SAMEDAY_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_AFR_ECONOMY_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_AFR_ECONOMY_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_AFR_TEMPERATURE_CONTROLLED_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_AFR_TEMPERATURE_CONTROLLED_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_OFR_LCL_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_OFR_LCL_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_OFR_NON_CONTAINERISED_LOAD_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_OFR_NON_CONTAINERISED_LOAD_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_OFR_TEMPERATURE_CONTROLLED_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_OFR_TEMPERATURE_CONTROLLED_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_RAIL_LCL_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_RAIL_LCL_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_RAIL_FTL_AND_PTL_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_RAIL_FTL_AND_PTL_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_AFR_PRIORITY_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_AFR_PRIORITY_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_AFR_TAILORED_LIFT_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_AFR_TAILORED_LIFT_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_OFR_FCL_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_OFR_FCL_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_BUYERS_SHIPPERS_CONSOLIDATION_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_BUYERS_SHIPPERS_CONSOLIDATION_CHECKED
      .name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_OFR_BULK_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_OFR_BULK_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_RAIL_FTL_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_RAIL_FTL_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_ROAD_LTL_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_ROAD_LTL_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.PRODUCT_CUSTOMS_SERVICE_CHECKED.id]: {
    name: ShipmentsInteractions.PRODUCT_CUSTOMS_SERVICE_CHECKED.name,
    ...eventContentCheckboxInterObj,
    position: ShipmentsPosition.PRODUCT,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.CLEAR_ALL_FILTERS_CLICK.id]: {
    name: ShipmentsInteractions.CLEAR_ALL_FILTERS_CLICK.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.BUTTON_BAR,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.CANCEL_FILTERS_CLICK.id]: {
    name: ShipmentsInteractions.CANCEL_FILTERS_CLICK.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.BUTTON_BAR,
    context: shipmentFiltersModalContext,
  },
  [ShipmentsInteractions.SHOW_RESULTS.id]: {
    name: ShipmentsInteractions.SHOW_RESULTS.name,
    ...eventContentButtonInterObj,
    position: ShipmentsPosition.BUTTON_BAR,
    context: shipmentFiltersModalContext,
  },
};
