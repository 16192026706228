import { maxTitleWidth as maxTitleWidthBucket } from "components/organisms/Filters/ListBucketSearch/Partials/CustomOption";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { TipWrapper, Wrapper } from "./styles";

const Tip1 = ({
  content,
  children,
  topOffset,
  rightOffset,
  TipFontSize,
  fromDropdown,
  fromBucket,
  timeout,
  disableClickEvent,
  TipOnSide,
  multiline,
}) => {
  const [hovered, setHovered] = useState(false);
  const [pos, setPos] = useState(false);

  const targetRef = useRef();
  const targetRefTip = useRef();
  const paddingTip = 8;

  const isToShowTip = () => {
    if (targetRef.current && targetRefTip.current) {
      return fromBucket
        ? targetRef.current.getBoundingClientRect().width >= maxTitleWidthBucket
        : targetRef.current.getBoundingClientRect().width + paddingTip * 2 <
            targetRefTip.current.getBoundingClientRect().width - 1; // We have to subtract 1px for ensuring the tooltip is shown in IE (IE calculates width different way than Chrome or Firefox)
    } else {
      return true;
    }
  };

  const setPosition = (e) => {
    if (targetRef.current && targetRefTip.current) {
      const wrapperPosition = targetRef.current.getBoundingClientRect();
      const wrapperTip = targetRefTip.current.getBoundingClientRect();
      let offsetX, offsetY;
      if (TipOnSide || fromBucket) {
        offsetX = -wrapperTip.width + rightOffset;
        offsetY = topOffset;
      } else {
        offsetX =
          wrapperPosition.width / 2 - wrapperTip.width / 2 + rightOffset;
        offsetY = topOffset;
      }
      setPos({
        x: wrapperPosition.left + offsetX,
        y: wrapperPosition.top + offsetY,
      });
    } else {
      setPos({ x: e.clientX, y: e.clientY });
    }
  };

  let timeoutID;
  return !content ? (
    children
  ) : (
    <>
      <Wrapper
        fromDropdown={fromDropdown}
        ref={targetRef}
        onMouseOver={(e) => {
          e.persist();
          timeoutID = window.setTimeout(() => {
            setPosition(e);
            if (isToShowTip()) {
              setHovered(true);
            }
          }, timeout);
        }}
        onMouseOut={() => {
          clearTimeout(timeoutID);
          setHovered(false);
        }}
        onClick={() => {
          if (!disableClickEvent) {
            clearTimeout(timeoutID);
            setHovered(false);
          }
        }}
      >
        {children}
      </Wrapper>
      <TipWrapper
        ref={targetRefTip}
        show={hovered}
        pos={pos}
        px={paddingTip}
        TipFontSize={TipFontSize}
        multiline={multiline}
      >
        {content}
      </TipWrapper>
    </>
  );
};

Tip1.defaultProps = {
  content: "",
  topOffset: 40,
  TipFontSize: 14,
  rightOffset: 0,
  breakLine: false,
  timeout: 500,
  disableClickEvent: false,
};

Tip1.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  topOffset: PropTypes.number,
  TipFontSize: PropTypes.number,
  rightOffset: PropTypes.number,
  breakLine: PropTypes.bool,
  timeout: PropTypes.number,
  disableClickEvent: PropTypes.bool,
};

export default Tip1;
