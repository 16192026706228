import { defineMessages } from "react-intl";

const messages = defineMessages({
  switchAccount: {
    id: "flyOut.switchAccount",
    defaultMessage: "Switch account to",
  },
  privacyNotice: {
    id: "flyOut.links.privacyNotice",
    defaultMessage: "Privacy Notice",
  },

  legalNotice: {
    id: "flyOut.links.legalNotice",
    defaultMessage: "Legal Notice",
  },
});

export default messages;
