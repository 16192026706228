import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "ErrorsInteractions";

export const ErrorsInteractions = {
  CLOSE_ERROR: { id: `${prefix}_Close Error`, name: "Close Error" },
  BACK_TO_SHIPMENT_LIST: {
    id: `${prefix}_Back to Shipment List`,
    name: "Back to Shipment List",
  },
  BACK_TO_DASHBOARD: {
    id: `${prefix}_Back to Dashboard`,
    name: "Back to Dashboard",
  },
  RETRY: { id: `${prefix}_Retry`, name: "Retry" },
};

enum ErrorsPosition {
  ERROR = "Error",
  PRIVILEGES_ERROR = "Privileges Error",
}

const errorsContext = "Error Popup";

export const errorsEventNameToErrorsEventContentMapper: C.EventNameToContentMapperType<
  typeof ErrorsInteractions
> = {
  [ErrorsInteractions.CLOSE_ERROR.id]: {
    name: ErrorsInteractions.CLOSE_ERROR.name,
    ...eventContentButtonInterObj,
    position: ErrorsPosition.ERROR,
    context: errorsContext,
  },
  [ErrorsInteractions.BACK_TO_SHIPMENT_LIST.id]: {
    name: ErrorsInteractions.BACK_TO_SHIPMENT_LIST.name,
    ...eventContentButtonInterObj,
    position: ErrorsPosition.PRIVILEGES_ERROR,
    context: errorsContext,
  },
  [ErrorsInteractions.BACK_TO_DASHBOARD.id]: {
    name: ErrorsInteractions.BACK_TO_DASHBOARD.name,
    ...eventContentButtonInterObj,
    position: ErrorsPosition.PRIVILEGES_ERROR,
    context: errorsContext,
  },
  [ErrorsInteractions.RETRY.id]: {
    name: ErrorsInteractions.RETRY.name,
    ...eventContentButtonInterObj,
    position: ErrorsPosition.ERROR,
    context: errorsContext,
  },
};
