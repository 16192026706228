import { demoUserCountryDetails } from "constants/demoUser";
import { useQuery } from "@apollo/client";
import { AccountInteractions } from "adobe-analytics/content-interaction-events/account/AccountEvents.constants";
import { dispatchInteractionEvent } from "adobe-analytics/content-interaction-events/ContentInteractionEvents.helpers";
import FlyOut from "components/molecules/FlyOut/FlyOut";
import AddressBook from "components/organisms/StreetAddress/AddressBook/AddressBook";
import ChangePasswordModal from "components/TopBar/UserAvatarSection/ChangePasswordModal/ChangePasswordModal";
import { useMenuItems } from "components/TopBar/UserAvatarSection/UserAvatarSection.helpers";
import { useGetDataTestId } from "containers/TestingContext/TestingContext";
import countryCoordinates from "data/countryCoordinates";
import useIsInternalUser from "hooks/useIsInternalUser";
import { GET_USER_DETAILS } from "queries/AAA/userQueries";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setAppState } from "redux/app/actions";
import { setDashboardCenter } from "redux/dashboard/actions";
import { getCorporatePartners } from "redux/userSettings/actions";
import { useFilteredCountryList } from "routes/MakeABooking/MakeABooking.helpers";
import { SCREEN_SIZE_VALUES } from "../../../utils/css";
import ProfileImage from "./partials/ProfileImage";
import ProfileImageWrapper from "./partials/ProfileImageWrapper";
import TopBarUserWrapper from "./partials/TopBarUserWrapper";

const UserTopBar = () => {
  const getDataTestId = useGetDataTestId();
  const [isFlyOutVisible, setIsFlyOutVisible] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isAddressBookModalOpen, setIsAddressBookModalOpen] = useState(false);

  const dispatch = useDispatch();

  const isDemoMode = useSelector(
    (state) => state.userSettings.visibilityScope?.demo
  );

  const userCountry = useSelector((state) => state?.app?.userCountry);
  const { unfilteredCountries } = useFilteredCountryList();

  const { data: userData } = useQuery(GET_USER_DETAILS);
  const nameInitials = userData?.user?.nameInitials || "";

  sessionStorage.setItem(
    "track_location",
    userData?.user?.address?.country?.code
  );

  useEffect(() => {
    if (userData?.user?.address?.country?.code !== userCountry) {
      dispatch(
        setAppState("userCountry", userData?.user?.address?.country?.code)
      );
    }
  }, [userData?.user?.address?.country?.code, dispatch, userCountry]);

  const corporatePartners = useSelector(
    (state) => state.userSettings.corporatePartners
  );

  const { hasDemoCPN, hasRealCPN } = useMemo(
    () => ({
      hasDemoCPN: corporatePartners.some((i) => i.demo),
      hasRealCPN: corporatePartners.some((i) => !i.demo),
    }),
    [corporatePartners]
  );

  const isCheckingDemoProfile = useSelector(
    (state) => state.app.isCheckingDemoProfile
  );

  // use ref so the map is not re-centered when the country code changes.
  const countryCodeRef = useRef();
  countryCodeRef.current = userData?.user?.address?.country?.code;
  useEffect(() => {
    let countryCenter;
    if (isDemoMode) {
      countryCenter = countryCoordinates[demoUserCountryDetails.country.code];
    } else {
      countryCenter = countryCoordinates[countryCodeRef.current];
    }
    if (countryCenter?.lat && countryCenter?.lng) {
      dispatch(
        setDashboardCenter({ lat: countryCenter.lat, lng: countryCenter.lng })
      );
    }
  }, [dispatch, isDemoMode]);

  const isUserInternal = useIsInternalUser();
  const menuItems = useMenuItems(
    isUserInternal,
    setIsChangePasswordModalOpen,
    setIsAddressBookModalOpen
  );

  const onUserModeChanged = () => {
    if (!isCheckingDemoProfile) {
      dispatch(setAppState("isCheckingDemoProfile", true));
      dispatch(
        getCorporatePartners({
          isDemoMode: !isDemoMode,
        })
      );
      setIsFlyOutVisible(false);
    }
  };

  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZE_VALUES.until.md });

  return isCheckingDemoProfile ? null : (
    <>
      <TopBarUserWrapper isMobile={isMobile}>
        <ProfileImageWrapper>
          <ProfileImage
            data-testid={getDataTestId("TopBarAvatar")}
            data-track={10}
            tabIndex={0}
            role="user-initials"
            key={isFlyOutVisible ? "profile-open" : "profile-closed"}
            onClick={() => {
              dispatchInteractionEvent(AccountInteractions.PROFILE_PICTURE);
              setIsFlyOutVisible((prevState) => !prevState);
            }}
            isDemoMode={isDemoMode}
            size={isDemoMode ? 12 : 16}
          >
            {isDemoMode ? "DEMO" : nameInitials}
          </ProfileImage>
        </ProfileImageWrapper>
        <FlyOut
          itemGroups={menuItems}
          onOutsideClick={() => setIsFlyOutVisible(false)}
          onVisiblityChange={setIsFlyOutVisible}
          visible={isFlyOutVisible}
          isTopBar
          hasDemoCPN={hasDemoCPN}
          hasRealCPN={hasRealCPN}
          onUserModeChanged={onUserModeChanged}
          email={userData?.user?.email}
          nameInitials={nameInitials}
        />
      </TopBarUserWrapper>
      {!isUserInternal && (
        <ChangePasswordModal
          isModalOpen={isChangePasswordModalOpen}
          onModalClose={() => setIsChangePasswordModalOpen(false)}
        />
      )}

      <AddressBook
        isAddressBookModalOpen={isAddressBookModalOpen}
        countryList={unfilteredCountries}
        closeAddressBookModal={() => setIsAddressBookModalOpen(false)}
        shouldDisplayOpenAddressBookModalButton={false}
      />
    </>
  );
};

export default UserTopBar;
