import { contactsObjInitialValue } from "../AddressForm.constants";

export interface AdjustAddressFormProps {
  handleSubmit: () => void;
  onCancel: () => void;
  onDelete?: () => void;
  isDisabled: boolean;
  isDeletable: boolean;
  formHeader: string;
}

export type AddressBookRequestSchema = {
  addressBook: {
    address: {
      accountNumber: string;
      addressLine1: string;
      addressLine2: string;
      city: string;
      companyName: string;
      countryCode: string;
      postalCode: string;
      reference?: string;
      stateProvince: string;
    };
    addressFlags: {
      isBilling: boolean;
      isConsignee: boolean;
      isDelivery: boolean;
      isPickup: boolean;
      isShipper: boolean;
      isNotify: boolean;
    };
    contact: {
      contactName: string;
      email: string;
      phone: string;
    };
  };
  id?: number;
};

export type CountrySelectData = {
  code: string;
  countryCode: string;
  label: string;
  value: string;
};

export type ContactDetailsData = {
  companyName?: string;
  contactName?: string;
  email?: string;
  phoneNumber?: string;
  reference?: string;
};

export type AddressBookFormSchema = {
  addressBook: {
    address: {
      country?: CountrySelectData;
      streetAddress?: string;
      street?: string;
      zip?: string;
      city?: string;
      states?: string;
      preferredAddressInputType?: string;
    };
    contactDetails: ContactDetailsData;
    addressType: {
      isBilling: boolean;
      isConsignee: boolean;
      isDelivery: boolean;
      isPickup: boolean;
      isShipper: boolean;
      isNotify: boolean;
    };
  };
};

export const initialFormValues = {
  addressBook: {
    companyName: "",
    reference: "",
    address: {
      country: {
        code: "",
        countryCode: "",
        label: "",
        value: "",
      },
      streetAddress: "",
      street: "",
      zip: "",
      city: "",
      states: "",
      preferredAddressInputType: "",
    },

    contacts: {
      ...contactsObjInitialValue,
    },

    addressType: {
      isBilling: false,
      isConsignee: false,
      isDelivery: false,
      isPickup: false,
      isShipper: false,
      isNotify: false,
    },
  },
};

export const addressBookFormFields = {
  country: "addressBook.address.country",
  countryCode: "addressBook.address.country.code",
  streetAddress: "addressBook.address.streetAddress",
  street: "addressBook.address.street",
  zip: "addressBook.address.zip",
  city: "addressBook.address.city",
  states: "addressBook.address.states",
  preferredAddressInputType: "addressBook.address.preferredAddressInputType",

  companyName: "addressBook.companyName",
  contactName: "contactName",
  email: "email",
  phoneNumber: "phoneNumber",
  reference: "addressBook.reference",
  contacts: "addressBook.contacts",
  contactType: "contactType",

  isBilling: "addressBook.addressType.isBilling",
  isConsignee: "addressBook.addressType.isConsignee",
  isDelivery: "addressBook.addressType.isDelivery",
  isPickup: "addressBook.addressType.isPickup",
  isShipper: "addressBook.addressType.isShipper",
  isNotify: "addressBook.addressType.isNotify",

  addressId: "addressBook.address.addressId",
  modifiedAt: "addressBook.address.modifiedAt",
};
