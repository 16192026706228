import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoggedInUserEmail,
  setLoggedInUserRoles,
  setUserId,
  setUserLoggedIn,
} from "../../redux/auth/actions";
import { getPropertyFromToken, hasValidRefreshToken } from "../../utils/auth";

const Tracker = () => {
  const loggedInUserRoles = useSelector(
    (state) => state.auth.loggedInUserRoles
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (loggedInUserRoles && loggedInUserRoles.length === 0) {
      const token = localStorage.getItem("access_token");
      if (token) {
        dispatch(
          setLoggedInUserRoles(
            getPropertyFromToken(token, "realm_access.roles")
          )
        );
      }
    }
  }, [dispatch, loggedInUserRoles]);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      dispatch(setLoggedInUserEmail(getPropertyFromToken(token, "email")));
      dispatch(setUserId(getPropertyFromToken(token, "AAA_ID")));
    }
  }, [dispatch]);

  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);
  const doesHaveValidRefreshToken = hasValidRefreshToken();
  useEffect(() => {
    if (doesHaveValidRefreshToken && !isUserLoggedIn) {
      dispatch(setUserLoggedIn(true));
    } else if (!doesHaveValidRefreshToken && isUserLoggedIn) {
      dispatch(setUserLoggedIn(false));
    }
    // eslint-disable-next-line
  }, [doesHaveValidRefreshToken, isUserLoggedIn]);

  return <div />;
};

export default Tracker;
