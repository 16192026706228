import React from "react";
import { Field } from "react-final-form";
import { useIntl } from "react-intl";
import { formFields } from "../AddressBookFormFields.constants";
import messages from "../AddressBookFormFields.messages";
import * as S from "./AddressTypePicker.styles";

const parties = [
  {
    name: formFields.isPickup,
    message: messages.pickUp,
  },
  {
    name: formFields.isShipper,
    message: messages.shipper,
  },
  {
    name: formFields.isDelivery,
    message: messages.delivery,
  },
  {
    name: formFields.isConsignee,
    message: messages.consignee,
  },

  {
    name: formFields.isBilling,
    message: messages.billing,
  },
  {
    name: formFields.isNotify,
    message: messages.notify,
  },
] as const;

const AddressTypePicker = () => {
  const intl = useIntl();
  return (
    <S.AddressTypePicker>
      <S.SectionHeader>
        {intl.formatMessage(messages.addressType)}
      </S.SectionHeader>
      <S.AddressTypeCheckboxesWrapper>
        {parties.map((party) => (
          <Field type="checkbox" name={party.name} key={party.name}>
            {({ input }) => (
              <S.StyledCheckbox
                value={input.value}
                inputProps={{
                  checked: input.checked,
                  onChange: input.onChange,
                }}
              >
                {intl.formatMessage(party.message)}
              </S.StyledCheckbox>
            )}
          </Field>
        ))}
      </S.AddressTypeCheckboxesWrapper>
    </S.AddressTypePicker>
  );
};

export default AddressTypePicker;
