import SVGWrapper from "components/atoms/SVGWrapper";
import { Color, Divider, ModalHeader } from "react-lib";
import styled, { css } from "styled-components";

export const Container = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`;

export const ModalContent = styled.div`
  height: calc(100vh - 16rem);
  overflow-y: scroll;
`;

export const StyledModalHeader = styled(ModalHeader)`
  background-color: ${Color.postYellow};
  min-height: 4rem;
  padding: 0 1.375rem 0 2.75rem;
  display: flex;
  align-items: center;

  h2 {
    font-size: 1.25rem;
  }
`;

export const StyledSVGWrapper = styled(SVGWrapper)`
  align-self: center;
  height: 1.125rem;
  width: 1rem;
  margin-right: 0.75rem;
`;

export const AddressIdRow = styled.div<{ areChildrenVisible: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 0 0;
  align-items: end;
  min-height: 5rem;

  ${({ areChildrenVisible }) =>
    !areChildrenVisible &&
    css`
      & > * {
        display: none;
      }
    `}
`;

export const AddressId = styled.label`
  font-size: 0.875rem;
  line-height: 1.29;
  color: ${Color.webBlack};
`;

export const FormContainer = styled.div`
  padding: 0 2.5rem;
`;

export const FullWidthDivider = styled(Divider)`
  margin: 0 -2.5rem;
`;

export const SectionDivider = styled(FullWidthDivider)`
  margin-bottom: 2.5rem;
`;

export const FormField = styled.div`
  margin-bottom: 1.25rem;
  flex: 1 1 0px;
  max-height: 4.5rem;
`;

export const FieldRow = styled.div`
  display: flex;
  gap: 1.25rem;
`;
