/* eslint-disable no-console */
import axios, { AxiosInstance } from "axios";
import * as C from "./ClientLogger.constants";

class ClientLogger {
  private constructor() {
    ClientLogger.instance = this;

    this.logger = axios;
    this.logs = [];
    this.isServerAvailable = true;
    this.loggerIntervalId = undefined;
  }

  static instance: null | ClientLogger = null;

  private logs: C.BrowserLogBody[];
  private logger: AxiosInstance;
  private isServerAvailable: boolean;
  private loggerIntervalId: number | undefined;

  static getInstance = () => {
    if (ClientLogger.instance === null) {
      ClientLogger.instance = new ClientLogger();
    }

    return ClientLogger.instance;
  };

  private clearLoggerInterval = () => {
    window.clearInterval(this.loggerIntervalId);
    this.loggerIntervalId = undefined;
  };

  public addRecord = (record: C.BrowserLogBody) => {
    if (this.isServerAvailable) {
      this.logs.push(record);

      if (this.loggerIntervalId === undefined) {
        this.loggerIntervalId = this.sendLogsToServer();
      }
    }
  };

  private getRequestOptions = () => {
    const token = localStorage.getItem("access_token");
    const options = {
      headers: {
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
    };

    return options;
  };

  private sendLogsToServer = () =>
    window.setInterval(() => {
      const logsToSend = [...this.logs];
      const options = this.getRequestOptions();
      this.logs = [];
      this.logger
        .post(C.errorsLoggingEndpoint, logsToSend, options)
        .then(this.onRequestSuccess)
        .catch(this.onRequestError);
    }, C.loggingIntervalTimeInMs);

  private onRequestSuccess = () => {
    if (!this.logs.length) {
      this.clearLoggerInterval();
    }
  };

  private onRequestError = () => {
    this.clearLoggerInterval();
    this.isServerAvailable = false;

    if (C.isDebugEnabled) {
      console.error(
        "Unable to send browser logs to server. Server is unavailable."
      );
    }
  };
}

export default ClientLogger.getInstance();
