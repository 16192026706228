import types from "./action-types";

export const setWidgetsUpdate = (payload) => ({
  type: types.SET_WIDGETS_UPDATE,
  payload,
});

export const setSingleShipmentContainerDetails = (payload) => ({
  type: types.SET_SINGLE_SHIPMENT_CONTAINER_DETAILS,
  payload,
});

export const resetSingleShipmentContainerDetails = () => ({
  type: types.RESET_SINGLE_SHIPMENT_CONTAINER_DETAILS,
  payload: null,
});

export const setClickedMarker = (payload) => ({
  type: types.SET_CLICKED_MARKER,
  payload,
});

export const addWidgetLoaded = (payload) => ({
  type: types.ADD_WIDGET_LOADED,
  payload,
});

export const setSidebarOpen = (payload) => ({
  type: types.SET_SIDEBAR_OPEN,
  payload,
});

export const setDashboardZoom = (payload) => ({
  type: types.SET_DASHBOARD_ZOOM,
  payload,
});

export const setDashboardCenter = (payload) => {
  return {
    type: types.SET_DASHBOARD_CENTER,
    payload,
  };
};

export const setNumberElementsList = (payload) => ({
  type: types.SET_NUMBER_ELEMENTS_LIST,
  payload,
});

export const setActiveMarker = (payload) => ({
  type: types.SET_ACTIVE_MARKER,
  payload,
});

export const setMapTransportMode = (payload) => ({
  type: types.SET_MAP_TRANSPORT_MODE,
  payload,
});
