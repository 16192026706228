import { ReactComponent as DHLLogo } from "assets/icons/DHLGlobalForwardingRed.svg";
import React from "react";
import { Link } from "react-router-dom";
import * as S from "./SiteLogo.styles";

interface SiteLogoProps {
  onClick?: () => void;
  link?: string;
  dataTrack?: number;
  className?: string;
}

const SiteLogo: React.FC<SiteLogoProps> = ({
  onClick,
  link,
  dataTrack,
  className,
}) => (
  <S.LogoWrapper
    className={className}
    data-track={dataTrack ?? 1}
    as={Link}
    to={link || "/"}
    aria-label="Logo - Index"
  >
    <DHLLogo onClick={() => onClick?.()} />
  </S.LogoWrapper>
);

export default SiteLogo;
