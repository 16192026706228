import { dispatchInteractionEvent } from "adobe-analytics/content-interaction-events/ContentInteractionEvents.helpers";
import { ErrorsInteractions } from "adobe-analytics/content-interaction-events/errors/ErrorsEvents.constants";
import { ReactComponent as ServerErrorIcon } from "assets/icons/myDHLi/server-error.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getRem } from "utils/css";
import IconButton from "../../components/atoms/Buttons/IconButton";
import { messages } from "./messages";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  @media (max-width: 600px) {
    min-height: 60vh;
  }
`;

const CenterBlock = styled.div`
  display: flex;
  max-width: ${getRem(600)};
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const IconCol = styled.div`
  margin-right: ${getRem(40)};
  @media (max-width: 600px) {
    margin-bottom: ${getRem(20)};
  }
`;

const ErrorH1 = styled.h1`
  font-size: 39px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #d40511;
  @media (max-width: 600px) {
    font-size: 27px;
  }
`;

const ErrorH3 = styled.h3`
  font-size: 39px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: ${getRem(20)};
  @media (max-width: 600px) {
    font-size: 27px;
  }
`;

const ErrorP = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: ${getRem(20)};
`;

const Error = ({ title, description, helpText }) => {
  const history = useHistory();
  const intl = useIntl();
  return (
    <Wrapper>
      <CenterBlock>
        <IconCol>
          <SVGWrapper
            width={getRem(72)}
            height={getRem(72)}
            fill="#d40511"
            hoverFill="#eb131e"
          >
            <ServerErrorIcon />
          </SVGWrapper>
        </IconCol>
        <div>
          <ErrorH1>{title}</ErrorH1>
          <ErrorH3>{description}</ErrorH3>
          <ErrorP>{helpText}</ErrorP>
          <div>
            <IconButton
              bgColor="#d40511"
              hoverBgColor="#eb131e"
              color="#FFFFFF"
              hoverColor="#FFFFFF"
              onClick={() => {
                dispatchInteractionEvent(ErrorsInteractions.BACK_TO_DASHBOARD);
                history.push("/");
              }}
            >
              {intl.formatMessage(messages.backToDashboard)}
            </IconButton>
          </div>
        </div>
      </CenterBlock>
    </Wrapper>
  );
};

Error.defaultProps = {
  title: "Sorry!",
  description: "Something went wrong and we couldn't load your analytics items",
  helpText: "Contact us if the problem persists.",
};
Error.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  helpText: PropTypes.string,
};

export default Error;
