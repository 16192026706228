import includes from "lodash/includes";
import startsWith from "lodash/startsWith";

export const getQueryParams = (qs) => {
  qs = qs.split("+").join(" ");

  const params = {};
  let tokens;
  const re = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const startsWithAnyOf = (stringToSearch, needles) => {
  let result = false;
  needles.forEach((item) => {
    const resultForItem = startsWith(stringToSearch, item);
    if (resultForItem) result = true;
  });
  return result;
};

export const includesAnyOf = (stringToSearch, needles = []) =>
  needles.some((item) => includes(stringToSearch, item));

export const stringOrUndefined = (string) => {
  return string?.trim() || undefined;
};

export const wildcardEscape = (str) =>
  str.replaceAll("!", "!!").replaceAll("_", "!_").replaceAll("%", "!%");

export const wildcardUnescape = (str) =>
  str.replaceAll(/(!!|!_|!%|%|)/g, (match) => {
    if (match === "!!") return "!";
    if (match === "!_") return "_";
    if (match === "!%") return "%";
    if (match === "%") {
      // eslint-disable-next-line no-console
      console.warn("unescaped wildcard encountered");
    }
    return match;
  });
