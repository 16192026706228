import SideBarMobileOnlyItems from "components/organisms/SideBar/SideBarMobileOnlyItems";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppState } from "redux/app/actions";
import { getRem } from "utils/css";
import SideBarBackDrop from "./Partials/SideBarBackDrop";
import SideBarCollapseControl from "./Partials/SideBarCollapseControl";
import SideBarItems from "./Partials/SideBarItems";
import SideBarWrapper from "./Partials/SideBarWrapper";

const SideBar = (props) => {
  const dispatch = useDispatch();
  let navCollapsed = useSelector((state) => state.app.navCollapsed);
  const navHovered = useSelector((state) => state.app.navHovered);
  if (!props.belowLg) {
    navCollapsed = true;
  }
  return (
    <SideBarWrapper
      hideNav={props.hideNav}
      belowLg={props.belowLg}
      collapsed={navCollapsed}
      hovered={navHovered}
      paddingTop={props.paddingTop}
      onMouseLeave={() => {
        if (navCollapsed) {
          props.onLeaveSideBar(false);
          dispatch(setAppState("navHovered", false));
        }
      }}
      shouldScrollMenu={props.shouldScrollMenu}
    >
      <SideBarItems
        onMouseEnter={() => {
          if (navCollapsed) {
            props.onEnterMenuArea();
            dispatch(setAppState("navHovered", true));
          }
        }}
        onMouseLeave={() => {
          if (navCollapsed) {
            props.onLeaveMenuArea();
          }
        }}
        belowLg={props.belowLg}
      />
      {props.belowLg && <SideBarMobileOnlyItems />}
      {props.belowLg && (
        <SideBarCollapseControl
          belowLg={props.belowLg}
          collapseMenuText={props.belowLg ? "" : props.collapseMenuText}
          onClick={() => {
            dispatch(setAppState("navCollapsed", !navCollapsed));
            dispatch(setAppState("navHovered", false));
          }}
        />
      )}
      {navCollapsed ? null : props.belowLg ? (
        <SideBarBackDrop
          onClick={() => {
            dispatch(setAppState("navCollapsed", true));
          }}
        />
      ) : null}
    </SideBarWrapper>
  );
};
SideBar.defaultProps = {
  onEnterMenuArea: () => {},
  onLeaveMenuArea: () => {},
  onLeaveSideBar: () => {},
  onClickCollapse: () => {},
  paddingTop: getRem(80),
  collapseMenuText: "Collapse Menu",
};
SideBar.propTypes = {
  onEnterMenuArea: PropTypes.func,
  onLeaveMenuArea: PropTypes.func,
  onLeaveSideBar: PropTypes.func,
  onClickCollapse: PropTypes.func,
  paddingTop: PropTypes.string,
  collapseMenuText: PropTypes.string,
  belowLg: PropTypes.bool,
  shouldScrollMenu: PropTypes.bool,
};

export default SideBar;
