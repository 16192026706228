import { ReactComponent as CancelIcon } from "assets/icons/myDHLi/cancel.svg";
import { ReactComponent as SearchIcon } from "assets/icons/myDHLi/search.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import { getRem } from "utils/css";

const SearchWrapper = styled.div`
  width: 100%;
`;

const SearchInput = styled.input`
  height: ${getRem(46)};
  line-height: ${getRem(30)};
  border: none;
  padding: ${getRem(6)} ${getRem(12)} ${getRem(6)} ${getRem(46)};
  outline: none;
  width: 100%;
  display: inline-block;
  border-bottom: rgba(0, 0, 0, 0.45) solid 1px;
  &::-ms-clear {
    display: none;
  }
`;

const SearchInputGroup = styled.div`
  position: relative;
  width: 100%;
  outline: none;
  display: inline-block;
  label {
    cursor: pointer;
    position: absolute;
    top: ${getRem(6)};
    left: ${getRem(10)};
    line-height: ${getRem(30)};
    svg {
      width: ${getRem(24)};
      height: ${getRem(24)};
    }
  }
  button {
    cursor: pointer;
    position: absolute;
    top: ${getRem(6)};
    right: ${getRem(12)};
    line-height: ${getRem(30)};
    svg {
      width: ${getRem(24)};
      height: ${getRem(24)};
    }
  }
`;

const Search = (props) => {
  const [searchText, setSearchText] = useState("");
  function keyDownHandler(e) {
    if (e.key === "Enter") {
      props.onEnter(e);
    }
  }
  return (
    <SearchWrapper>
      <SearchInputGroup>
        <SearchInput
          type="text"
          name="search"
          id="search"
          placeholder={props.placeholder}
          aria-label="search"
          onKeyDown={keyDownHandler}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            props.onChange(e.target.value);
          }}
          autoComplete="off"
        />
        <SVGWrapper as="label" htmlFor="search">
          <SearchIcon />
        </SVGWrapper>
        {searchText ? (
          <SVGWrapper
            as="button"
            onClick={() => {
              setSearchText("");
              props.onReset();
            }}
          >
            <CancelIcon />
          </SVGWrapper>
        ) : null}
      </SearchInputGroup>
    </SearchWrapper>
  );
};

Search.defaultProps = {
  placeholder: "Search",
  onChange: () => {},
  onEnter: () => {},
  onReset: () => {},
};

Search.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onReset: PropTypes.func,
};

export default Search;
