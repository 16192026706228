import FlyOut from "components/molecules/FlyOut/FlyOut";
import styled, { StyledComponent } from "styled-components";

export const UserAvatarWrapper = styled.div`
  position: relative;
`;

export const StyledFlyOut: StyledComponent<any, any> = styled(FlyOut)`
  top: ${({ visible }) => (visible ? "3.375rem" : "1.875rem")};
  right: ${({ visible }) => (visible ? "-0.5rem" : "1.25rem")};
`;
