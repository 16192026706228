const cdzColumns = {
  HouseBill: {
    sortable: false,
    customSize: 7,
    removable: false,
    isDropdown: true,
  },
  Shipper: {
    sortable: false,
    customSize: 8,
    removable: false,
    isDropdown: false,
  },
  Consignee: {
    sortable: false,
    customSize: 6,
    removable: false,
    isDropdown: false,
  },
  OriginCountry: {
    sortable: false,
    customSize: 8,
    removable: true,
    isDropdown: false,
  },
  Origin: {
    sortable: false,
    customSize: 3,
    removable: true,
    isDropdown: false,
  },
  DestinationCountry: {
    sortable: false,
    customSize: 8,
    removable: true,
    isDropdown: false,
  },
  Destination: {
    sortable: false,
    customSize: 3,
    removable: true,
    isDropdown: false,
  },
  PortOfUnloading: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  Status: {
    sortable: false,
    customSize: 3,
    removable: false,
    isDropdown: true,
  },
  StatusDateTime: {
    sortable: false,
    customSize: 2.6,
    removable: false,
    isDropdown: true,
  },
  MasterBill: {
    sortable: false,
    customSize: 2.6,
    removable: true,
    isDropdown: false,
  },
  Reference: {
    sortable: false,
    customSize: 2.6,
    removable: false,
    isDropdown: true,
  },
  TotalPackages: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  TotalWeight: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  TotalChargeableWeight: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  TotalVolume: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  ProductType: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  TransportMode: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  GoodsDescription: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  CreationDate: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  PortOfLoading: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  PlaceOfLoading: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  CountryOfLoading: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  PlaceOfUnloading: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  CountryOfUnloading: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  // jobReference
  CustomsClearanceNumber: {
    sortable: false,
    customSize: 5,
    removable: false,
    isDropdown: true,
  },
  CustomsEntry: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  DeclarationType: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  ResponsibleCustomsOffice: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  ClearanceDate: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
  Forwarder: {
    sortable: false,
    customSize: 5,
    removable: true,
    isDropdown: false,
  },
};

export default cdzColumns;
