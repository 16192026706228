import { defineMessages } from "react-intl";

export default defineMessages({
  addressAddedToAddressBookSuccess: {
    id: "bookingStandalone.route.addressBook.successToast.addAddressSuccess",
    defaultMessage: "The Address has been saved to your Address book",
  },
  editAddressInAddressBookSuccess: {
    id: "bookingStandalone.route.addressBook.successToast.editAddressSuccess",
    defaultMessage: "The Address has been edited in your Address book",
  },
  deleteAddressFromAddressBookSuccess: {
    id: "bookingStandalone.route.addressBook.successToast.deleteAddressSuccess",
    defaultMessage: "The Address has been removed from your Address book",
  },
});
