import gql from "graphql-tag";

const getCorporatePartnersGQL = gql`
  query GetCorporatePartnersQuery {
    getCorporatePartners {
      data {
        __typename
        id
        code
        name
        uuid
        demo
      }
    }
  }
`;
export default getCorporatePartnersGQL;
