import { defineMessages } from "react-intl";

export const messages = defineMessages({
  changePassword: {
    id: "changePasswordModal.title.changePassword",
    defaultMessage: "Change Password",
  },
  enterCurrentPassowrd: {
    id: "changePasswordModal.input.subtitle.enterCurrentPassowrd",
    defaultMessage: "Enter your current password",
  },
  enterNewPasswordAndConfirmIt: {
    id: "changePasswordModal.input.subtitle.enterNewPasswordAndConfirmIt",
    defaultMessage:
      "Enter a new password and confirm it. Your password must be at least 12 characters and cannot contain common words.",
  },
  currentPassword: {
    id: "changePasswordModal.input.placeholder.currentPassword",
    defaultMessage: "Current Password",
  },
  newPassword: {
    id: "changePasswordModal.input.placeholder.newPassword",
    defaultMessage: "New Password",
  },
  confirmPassword: {
    id: "changePasswordModal.input.placeholder.confirmPassword",
    defaultMessage: "Confirm Password",
  },
  required: {
    id: "changePasswordModal.input.validation.required",
    defaultMessage: "Required",
  },
  ivalidCurrentPassowrd: {
    id: "changePasswordModal.input.validation.ivalidCurrentPassowrd",
    defaultMessage: "Provided current password is invalid, please check",
  },
  passwordTooShort: {
    id: "changePasswordModal.input.validation.passwordTooShort",
    defaultMessage: "Please try again, the selected password is too short",
  },
  passwordsDoNotMatch: {
    id: "changePasswordModal.input.validation.passwordsDoNotMatch",
    defaultMessage: "Passwords do not match, please check",
  },
  passwordHaveCommonWord: {
    id: "changePasswordModal.input.validation.passwordHaveCommonWord",
    defaultMessage:
      "Please try again, the selected password contains a common word",
  },
  close: {
    id: "changePasswordModal.button.label.close",
    defaultMessage: "Close",
  },
  confirm: {
    id: "changePasswordModal.button.label.confirm",
    defaultMessage: "Confirm",
  },
});
