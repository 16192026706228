import * as C from "./constants";

export const saveOfferOptions = (
  payload: object[]
): GetAction<C.QuoteDataActions, C.ActionsType.SAVE_OFFER_OPTIONS> => ({
  type: C.ActionsType.SAVE_OFFER_OPTIONS,
  payload,
});

export const setSelectedOffer = (
  payload: object
): GetAction<C.QuoteDataActions, C.ActionsType.SET_SELECTED_OFFER> => ({
  type: C.ActionsType.SET_SELECTED_OFFER,
  payload,
});

export const saveOffer = (payload: {
  calculationDetailsKey: string;
}): GetAction<C.QuoteDataActions, C.ActionsType.SAVE_OFFER> => ({
  type: C.ActionsType.SAVE_OFFER,
  payload,
});

export const recalculateQuote = (
  payload: object
): GetAction<C.QuoteDataActions, C.ActionsType.RECALCULATE_QUOTE> => ({
  type: C.ActionsType.RECALCULATE_QUOTE,
  payload,
});

export const calculateConsignment = (
  payload: object
): GetAction<C.QuoteDataActions, C.ActionsType.CALCULATE_CONSIGNMENT> => ({
  type: C.ActionsType.CALCULATE_CONSIGNMENT,
  payload,
});

export const saveQuoteForm = (
  payload: object
): GetAction<C.QuoteDataActions, C.ActionsType.SAVE_QUOTE_FORM> => ({
  type: C.ActionsType.SAVE_QUOTE_FORM,
  payload,
  meta: {
    debounce: {
      time: 500,
    },
  },
});

export const saveConsignmentAPI = (payload: {
  value?: boolean;
  code: string;
}): GetAction<C.QuoteDataActions, C.ActionsType.SAVE_CONSIGNMENT_API> => ({
  type: C.ActionsType.SAVE_CONSIGNMENT_API,
  payload,
});

export const saveConsignmentAPICompleted = (): GetAction<
  C.QuoteDataActions,
  C.ActionsType.SAVE_CONSIGNMENT_API_COMPLETED
> => ({
  type: C.ActionsType.SAVE_CONSIGNMENT_API_COMPLETED,
});

export const saveConsignmentAPISucceeded = (
  payload: boolean
): GetAction<
  C.QuoteDataActions,
  C.ActionsType.SAVE_CONSIGNMENT_API_SUCCEEDED
> => ({
  type: C.ActionsType.SAVE_CONSIGNMENT_API_SUCCEEDED,
  payload,
});

export const submitConsignment = (
  payload: object
): GetAction<C.QuoteDataActions, C.ActionsType.SUBMIT_CONSIGNMENT> => ({
  type: C.ActionsType.SUBMIT_CONSIGNMENT,
  payload,
});

export const submitConsignmentReset = (
  payload: boolean
): GetAction<C.QuoteDataActions, C.ActionsType.SUBMIT_CONSIGNMENT_RESET> => ({
  type: C.ActionsType.SUBMIT_CONSIGNMENT_RESET,
  payload,
});

export const submitConsignmentCompleted = (): GetAction<
  C.QuoteDataActions,
  C.ActionsType.SUBMIT_CONSIGNMENT_COMPLETED
> => ({
  type: C.ActionsType.SUBMIT_CONSIGNMENT_COMPLETED,
});

export const submitConsignmentSucceeded = (
  payload: object
): GetAction<
  C.QuoteDataActions,
  C.ActionsType.SUBMIT_CONSIGNMENT_SUCCEEDED
> => ({
  type: C.ActionsType.SUBMIT_CONSIGNMENT_SUCCEEDED,
  payload,
});

export const submitConsignmentFailed = (): GetAction<
  C.QuoteDataActions,
  C.ActionsType.SUBMIT_CONSIGNMENT_FAILED
> => ({
  type: C.ActionsType.SUBMIT_CONSIGNMENT_FAILED,
});

export const resetData = (): GetAction<
  C.QuoteDataActions,
  C.ActionsType.RESET_DATA
> => ({
  type: C.ActionsType.RESET_DATA,
});

export const setPickupDate = (
  pickupDate: string
): GetAction<C.QuoteDataActions, C.ActionsType.SET_PICKUP_DATE> => ({
  type: C.ActionsType.SET_PICKUP_DATE,
  payload: {
    pickupDate,
  },
});

export const updateOffers = (
  fromPath: string,
  locale: string
): GetAction<C.QuoteDataActions, C.ActionsType.UPDATE_OFFERS> => ({
  type: C.ActionsType.UPDATE_OFFERS,
  payload: {
    fromPath,
    locale,
  },
});

export const resetPickupDate = (): GetAction<
  C.QuoteDataActions,
  C.ActionsType.RESET_PICKUP_DATE
> => ({
  type: C.ActionsType.RESET_PICKUP_DATE,
});
