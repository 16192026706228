import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { getRem } from "../../../utils/css";

const CustomNavLink = ({
  isItemActive,
  paddingRight,
  tabRef,
  ...otherProps
}) => <NavLink {...otherProps} ref={tabRef} />;

const NavLinkTab = styled(CustomNavLink)`
  position: relative;
  display: inline-block;
  padding: ${getRem(10)} ${(p) => p.paddingRight} ${getRem(10)} 0;
  color: ${(props) =>
    props.isItemActive ? props.theme.colors.dhlRed : "black"};
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.colors.hoverRed};
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
`;

const Tab = (props) => {
  return (
    <NavLinkTab
      data-track={props.trackCode}
      to={{ pathname: props.route, state: props.state }}
      onClick={props.onClick}
      isItemActive={props.active}
      className={props.active ? "activeLink" : ""}
      paddingRight={props.paddingRight}
      tabRef={props.tabRef}
    >
      {props.text}
    </NavLinkTab>
  );
};

Tab.defaultProps = {
  route: "/",
  text: "Tab",
  onClick: () => {},
  active: false,
  paddingRight: getRem(40),
};
Tab.propTypes = {
  route: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  paddingRight: PropTypes.string,
};

export default Tab;
