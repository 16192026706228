import { AnalyticsInteractions } from "adobe-analytics/content-interaction-events/analytics-spent/AnalyticsSpentEvents.constants";
import { dispatchInteractionEvent } from "adobe-analytics/content-interaction-events/ContentInteractionEvents.helpers";
import { ReactComponent as AnalyticsIcon } from "assets/icons/myDHLi/menu/analytics.svg";
import IconButton from "components/atoms/Buttons/IconButton";
import PageTitle from "components/atoms/PageTitle";
import SVGWrapper from "components/atoms/SVGWrapper";
import { messages as widgetMessages } from "components/organisms/Widget/messages";
import Auth from "containers/Auth";
import { useGetDataTestId } from "containers/TestingContext/TestingContext";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import AnalyticsIframeTab from "routes/Analytics/AnalyticsIframeTab";
import LoadingOrError from "routes/Analytics/LoadingOrError";
import { getRem } from "utils/css";
import { hasAnalyticsError } from "utils/error";
import Tabs from "../../components/molecules/Tabs/Tabs";
import { getAnalyticsEventForTab } from "./helper";
import { messages } from "./messages";
import { Wrapper } from "./styles";

const AnalyticsIframe = ({ tabs, loading }) => {
  const errors = useSelector((state) => state.error.errors);
  const [mashUpLink, setMashUpLink] = useState("");
  const [mashUpName, setMashUpName] = useState("");
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const getDataTestId = useGetDataTestId();

  const hasError = hasAnalyticsError(errors);
  if (loading || hasError || !tabs.length) {
    return <LoadingOrError loading={loading} hasError={hasError} tabs={tabs} />;
  }

  if (location.pathname === "/analytics") {
    history.push(`/analytics${tabs[0].route}`);
  }

  const tabsWithAnalyticsEvent = tabs.map((tab) => ({
    ...tab,
    onClick: () => {
      const analyticEvent = getAnalyticsEventForTab(tab.name);
      analyticEvent && dispatchInteractionEvent(analyticEvent);
    },
  }));

  return (
    <Auth mashUpLink={mashUpLink} mashUpName={mashUpName}>
      <Wrapper data-testid={getDataTestId("AnalyticsIframeWrapper")}>
        <PageTitle>
          <SVGWrapper>
            <AnalyticsIcon />
          </SVGWrapper>
          <h1>{intl.formatMessage(messages.pageHeader)}</h1>
          {mashUpLink && (
            <IconButton
              data-track={55}
              size={getRem(14)}
              padding={`${getRem(9)} ${getRem(12)} ${getRem(7)} ${getRem(12)}`}
              color="#ffffff"
              hoverColor="#ffffff"
              bgColor="#d40511"
              hoverBgColor="#eb131e"
              onClick={() => {
                dispatchInteractionEvent(AnalyticsInteractions.GO_TO_ANALYTICS);
                window.open(mashUpLink);
              }}
            >
              {intl.formatMessage(widgetMessages.goToAnalytics)} - {mashUpName}
            </IconButton>
          )}
        </PageTitle>
        <Tabs items={tabsWithAnalyticsEvent} backLink="/analytics" />
        <Switch>
          {tabs.map((tab) => (
            <Route
              key={tab.name}
              path={`/analytics${tab.route}`}
              render={(props) => (
                <AnalyticsIframeTab
                  {...props}
                  data={tab}
                  setMashUpLink={setMashUpLink}
                  setMashUpName={setMashUpName}
                />
              )}
            />
          ))}
        </Switch>
      </Wrapper>
    </Auth>
  );
};

export default AnalyticsIframe;
