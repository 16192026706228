/* eslint-disable no-console */
import { DownloadState } from "constants/DownloadState";
import getConfigClient from "constants/getConfigClient";
import axios from "axios";
import JSZip from "jszip";

function handleBlobResponse(response, fileName) {
  if (response?.status === 200) {
    if (navigator.appVersion.toString().indexOf(".NET") > 0) {
      window.navigator.msSaveBlob(response.data, fileName);
    } else {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    }
  }
}

export async function exportListFile(link, fileName) {
  try {
    const response = await axios.get(
      getConfigClient("NODE_ENDPOINT") + "export-shipments" + link,
      {
        responseType: "blob",
      }
    );

    handleBlobResponse(response, fileName);
  } catch (e) {
    console.error("error", e, { ...e });
    alert("Seems like there was an error. Please try the operation again"); // TODO remove this when BE is fixed
  }
}

// exportListFile adjusted for Bookings usage
export async function exportBookingsListFile(id, corporatePartner) {
  try {
    const response = await axios.get(
      getConfigClient("NODE_ENDPOINT") +
        `book-workflow/documents/${id}?corporatePartner=${corporatePartner}`,
      {
        responseType: "blob",
      }
    );

    handleBlobResponse(response, "BookingList.xlsx");
  } catch (e) {
    console.error("error", e, { ...e });
  }
}

export async function exportQuotesListFile(searchFilters, setLoading, locale) {
  try {
    setLoading(true);
    const response = await axios.get(
      getConfigClient("NODE_ENDPOINT") +
        `export-quotes-quote-and-book/${locale}/${searchFilters}`,
      {
        responseType: "blob",
      }
    );

    if (response?.status === 200) {
      setLoading(false);
      if (navigator.appVersion.toString().indexOf(".NET") > 0) {
        window.navigator.msSaveBlob(response.data, "quotesList.xlsx");
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "quotesList.xlsx");
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (e) {
    setLoading(false);
    // eslint-disable-next-line no-console
    console.error("error", e, { ...e });
    alert("Seems like there was an error. Please try the operation again"); // TODO remove this when BE is fixed
  }
}

export async function downloadFile(
  uuid,
  fileDownloadEndpoint,
  name = "unknown",
  setDownloadState,
  setPercentage,
  fileDownloadAdditionalParams
) {
  try {
    const response = await axios.get(
      `${getConfigClient("NODE_ENDPOINT")}${fileDownloadEndpoint}${uuid}${
        fileDownloadAdditionalParams || ""
      }`,
      {
        responseType: "blob",
        onDownloadProgress: ({ loaded, total }) => {
          const percentage = Math.round((loaded * 100) / total);
          setPercentage?.(percentage);
        },
      }
    );

    if (response?.status === 200) {
      setDownloadState?.(DownloadState.DOWNLOADING);
      if (name === "unknown") {
        const contentDispositionHeader =
          response?.headers?.["content-disposition"] || "";
        const nameFromHeader = contentDispositionHeader.split("=")[1];
        name = nameFromHeader || name;
      }
      if (navigator.appVersion.toString().indexOf(".NET") > 0) {
        window.navigator.msSaveBlob(response.data, name);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      }
    } else if (response?.status === 404) {
      setDownloadState?.(DownloadState.EXPIRED);
    } else {
      setDownloadState?.(DownloadState.FAILED);
    }
  } catch (e) {
    setDownloadState?.(DownloadState.FAILED);
    // eslint-disable-next-line no-console
    console.error("error", e, { ...e });
  }
}

export async function downloadMultipleDocumentsAsZip(
  documents,
  setDownloadInProgress
) {
  if (documents.length < 2) return;
  setDownloadInProgress(true);
  try {
    const zip = new JSZip();
    const usedNames = new Set();
    const downloadPromises = documents.map(async (document) => {
      const response = await axios.get(
        getConfigClient("NODE_ENDPOINT") +
          "download-shipment-documents" +
          document.url,
        {
          responseType: "blob",
        }
      );
      let counter = 0;
      let fileName = document.fileName;
      const dotIndex = fileName.lastIndexOf(".");
      const baseName = fileName.substring(0, dotIndex);
      const ext = fileName.substring(dotIndex + 1);
      while (usedNames.has(fileName)) {
        counter += 1;
        fileName = `${baseName}_${counter}.${ext}`;
      }
      usedNames.add(fileName);
      zip.file(fileName, new Blob([response.data]), {
        binary: true,
      });
    });

    await Promise.all(downloadPromises);

    zip
      .generateAsync({
        type: "blob",
        compression: "STORE",
      })
      .then((content) => {
        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
          window.navigator.msSaveBlob(content, name);
        } else {
          const url = window.URL.createObjectURL(new Blob([content]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            documents[0]?.documentType + ".zip" || "all.zip"
          );
          document.body.appendChild(link);
          link.click();
        }
      });
  } catch (e) {
    alert(
      "Something went wrong. Try again or try downloading files separately."
    );
  }

  setDownloadInProgress(false);
}

export async function downloadDocument(path, name = "unknown") {
  try {
    const response = await axios.get(
      getConfigClient("NODE_ENDPOINT") + "download-shipment-documents" + path,
      {
        responseType: "blob",
      }
    );

    if (response?.status === 200) {
      if (navigator.appVersion.toString().indexOf(".NET") > 0) {
        window.navigator.msSaveBlob(response.data, name);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("error", e, { ...e });
    alert("Seems like there was an error. Please try the operation again"); // TODO remove this when BE is fixed
  }
}

export async function downloadBookingDocument(path, name) {
  try {
    const response = await axios.get(
      getConfigClient("NODE_ENDPOINT") + "download-booking-documents" + path,
      {
        responseType: "blob",
        skipAuth: true,
      }
    );

    if (response?.status === 200) {
      if (navigator.appVersion.toString().indexOf(".NET") > 0) {
        window.navigator.msSaveBlob(response.data, name);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("error", e, { ...e });
    alert("Seems like there was an error. Please try the operation again");
  }
}

export async function uploadBookingDocument(
  documentInfo,
  consignmentId,
  setPercentage,
  cancelToken,
  uploadEndpoint
) {
  const formData = new FormData();

  formData.append(
    "metadata",
    new Blob(
      [
        JSON.stringify(
          {
            consignmentId,
            filename: documentInfo.document.name,
            type: documentInfo.fileType.type,
            deleteAfterEdmUpload: true, // FIXME What that means?
            internal: false, // FIXME What that means?
          },
          null,
          2
        ) + "\n",
      ],
      { type: "application/json" }
    )
  );
  formData.append("file", documentInfo.document);

  try {
    const response = await axios.post(
      getConfigClient("NODE_ENDPOINT") + uploadEndpoint,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        skipAuth: true,
        onUploadProgress: ({ loaded, total }) => {
          const percentage = Math.round((loaded * 100) / total);
          setPercentage(percentage);
        },
        cancelToken: cancelToken,
      }
    );
    return response;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("error", e, { ...e });
  }
}
