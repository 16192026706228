import TextBlockCondensed from "components/atoms/TextBlockCondensed";
import React from "react";
import { useIntl } from "react-intl";
import { Text } from "react-lib";
import styled from "styled-components";
import { getRem } from "utils/css";
import messages from "./messages";

const TextWrapper = styled.div`
  padding-bottom: ${getRem(60)};
`;

const TermsOfUseText = ({ text }) => {
  const intl = useIntl();
  return (
    <TextWrapper>
      <Text fontSize={14} lineHeight={1.43} as="b">
        {intl.formatMessage(messages.termsOfUseHeader)}
      </Text>
      <Text fontSize={14} lineHeight={1.43} as="p">
        {intl.formatMessage(messages.termsOfUseIntroduction)}
      </Text>
      <TextBlockCondensed
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </TextWrapper>
  );
};

export default TermsOfUseText;
