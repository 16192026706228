import ClientLogger from "./ClientLogger/ClientLogger";
import {
  BrowserLogBody,
  BrowserMessages,
  LogLevel,
} from "./ClientLogger/ClientLogger.constants";

const browserErrorsHandler = () => {
  window.addEventListener("error", ({ message, error }) => {
    const browserLog: BrowserLogBody = {
      level: LogLevel.ERROR,
      message: BrowserMessages.WINDOW_ERROR,
      details: {
        original_message: message,
        stack: error?.stack,
      },
    };
    ClientLogger.addRecord(browserLog);
  });
  window.addEventListener("unhandledrejection", (event) => {
    // Prevent saving Apollo errors, those are already handled on server side
    if (!event?.reason?.graphQLErrors) {
      const browserLog: BrowserLogBody = {
        level: LogLevel.ERROR,
        message: BrowserMessages.WINDOW_UNHANDLEDREJECTION,
        details: {
          original_message: event?.reason?.message,
          stack: event?.reason?.stack,
          timestamp: event?.timeStamp,
        },
      };
      ClientLogger.addRecord(browserLog);
    }
  });
};

export default browserErrorsHandler;
