import { BookingsListColumn } from "components/organisms/BookingsList/BookingsListHeader/BookingsListHeader.constants";
import { SORT_DIRECTIONS } from "redux/sort/constants";
import { transformDateForExport } from "../Bookings.helpers";

export const bookingsDraftTabColumns: BookingsListColumn[] = [
  {
    dataName: "product",
    translationKey: "Mode",
    uniqueValueColumnName: "PRODUCT",
    sortable: true,
    customSize: 2.2,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "serviceLevel",
    translationKey: "Service",
    uniqueValueColumnName: "SERVICE_LEVEL",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "reference",
    translationKey: "References",
    uniqueValueColumnName: "REFERENCE",
    isFilteringDisabled: false,
    sortable: false,
    customSize: 8,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "estimatedPickupDate",
    translationKey: "EstimatedPickupDate",
    uniqueValueColumnName: "DATE_ESTIMATED_PICKUP",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    hasDateType: true,
    multiValue: false,
    transformFilterForExport: transformDateForExport("estimatedPickup"),
  },
  {
    dataName: "bookingName",
    translationKey: "BookingName",
    uniqueValueColumnName: "BOOKING_NAME",
    sortable: true,
    customSize: 28,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "userEmail",
    translationKey: "CreatedBy",
    uniqueValueColumnName: "USER_EMAIL",
    sortable: true,
    customSize: 12,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "dateCreated",
    translationKey: "CreationDate",
    uniqueValueColumnName: "DATE_CREATED",
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
    hasDateType: true,
    transformFilterForExport: transformDateForExport("created"),
  },
  {
    dataName: "dateUpdated",
    translationKey: "UpdateDate",
    uniqueValueColumnName: "DATE_UPDATED",
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
    hasDateType: true,
    transformFilterForExport: transformDateForExport("updated"),
  },
  {
    dataName: "originCountry",
    translationKey: "OriginCountry",
    uniqueValueColumnName: "ORIGIN_COUNTRY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "destinationCountry",
    translationKey: "DestinationCountry",
    uniqueValueColumnName: "DESTINATION_COUNTRY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "billingCountry",
    translationKey: "BillingCountry",
    uniqueValueColumnName: "BILLING_COUNTRY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "originCity",
    translationKey: "OriginCity",
    uniqueValueColumnName: "ORIGIN_CITY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "destinationCity",
    translationKey: "DestinationCity",
    uniqueValueColumnName: "DESTINATION_CITY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "shipperCompany",
    translationKey: "ShipperCompany",
    uniqueValueColumnName: "SHIPPER_COMPANY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "consigneeCompany",
    translationKey: "ConsigneeCompany",
    uniqueValueColumnName: "CONSIGNEE_COMPANY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "billingCompany",
    translationKey: "BillingCompany",
    uniqueValueColumnName: "BILLING_COMPANY",
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: true,
  },
  {
    dataName: "totalWeight",
    translationKey: "Weight",
    uniqueValueColumnName: "TOTAL_WEIGHT",
    isFilteringDisabled: true,
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
  {
    dataName: "totalVolume",
    translationKey: "Volume",
    uniqueValueColumnName: "TOTAL_VOLUME",
    isFilteringDisabled: true,
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
    multiValue: false,
  },
];
