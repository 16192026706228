export const ratingScores = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const modalWidth = "660px";

export const phoneInputId = "NPSratingModal";

export interface NPSModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export enum NPSSurveyFields {
  RATING = "rating",
  FEEDBACK = "feedback",
  WAS_CALLBACK_REQUESTED = "wasCallbackRequested",
  PHONE_NUMBER = "phoneNumber",
}

export interface NPSModalFormValues {
  [NPSSurveyFields.RATING]: number | null;
  [NPSSurveyFields.FEEDBACK]: string;
  [NPSSurveyFields.WAS_CALLBACK_REQUESTED]: boolean;
  [NPSSurveyFields.PHONE_NUMBER]: string;
}
