import find from "lodash/find";
import get from "lodash/get";

const requireCtx = require.context("localeData", false, /\.json$/, "lazy");

const MAPPING = {
  NO: "NB_NO",
  ZH: "ZH_HANS",
};

const uniformLanguageCode = (code) => code.toUpperCase().replace("-", "_");

export const allLanguages = requireCtx
  .keys()
  .filter((moduleName) => moduleName.startsWith("./"))
  .map((fileName) => {
    const code = fileName.slice(2, -5); // remove "./" and ".json"
    return {
      code,
      ucCode: uniformLanguageCode(code),
      fileName,
      load: () => requireCtx(fileName),
    };
  });

export const getLanguage = (code) => {
  let uniformCode = uniformLanguageCode(code);
  uniformCode = get(MAPPING, uniformCode, uniformCode);
  const lang = find(allLanguages, { ucCode: uniformCode });
  if (!lang) {
    // eslint-disable-next-line no-console
    console.warn("Try to access unknown language: ", code);
  }
  return lang;
};

export const loadTranslations = async (code) => getLanguage(code)?.load();
