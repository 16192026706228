import { AddressInputType } from "constants/bookWorkflow";
import React, { Dispatch, SetStateAction } from "react";
import { IntlShape } from "react-intl";
import {
  ActiveFilter,
  ListElement,
} from "react-lib/@types/organisms/FiltersList/FiltersList.constants";
import { ListElementTypeEnum } from "react-lib/es/organisms/FiltersList/FiltersList.constants";
import messages from "./AddressBookNew.messages";

export interface AddressBookModalProps {
  closeAddressBookModal: () => void;
  openAddAddressModal: () => void;
  openEditAddressModal: (address: UserNewAddressBook) => void;
  openDeleteAddressModal: (address: UserNewAddressBook) => void;
  setSnackbarError: Dispatch<React.SetStateAction<string | undefined>>;
  fieldPrefix: string;
}

export interface AddressBookNewTableProps {
  openAddAddressModal: () => void;
  openEditAddressModal: (address: UserNewAddressBook) => void;
  openDeleteAddressModal: (address: UserNewAddressBook) => void;
  activeFilters: ActiveFilter[];
  setSelectedFilterId: (id: string) => void;
  hideFilters: () => void;
  showFilters: () => void;
}

export interface UserNewAddressBookListResponse {
  getUserNewAddressBookList: {
    total_count: number;
    addresses: UserNewAddressBook[];
  };
}

export interface UserNewAddressBook {
  address_id: number;
  address: UserNewAddressBookAddress;
  contacts: UserNewAddressBookContact;
  audit: UserNewAddressBookAudit;
  addressFlags?: UserAddressBookAddressFlag;
  concatAddress?: string;
  country?: string;
}

export interface UserNewAddressBookAddress {
  created_at?: string;
  created_by_id?: string;
  modified_at?: string;
  modified_by_id?: string;
  id: number;
  corporate_partner_uuid?: string;
  company_name?: string;
  address_line1?: string;
  address_line2?: string;
  city?: string;
  postal_code?: string;
  state_province?: string;
  country_code?: string;
  ref_customer?: string;
  latitude?: string;
  longitude?: string;
  google_place_id?: string;
  usage?: string;
  is_customer_visible?: string;
  is_validated?: string;
}
export interface UserNewAddressBookContact {
  contact_name: string;
  email: string;
  phone: string;
  is_shipper: boolean;
  is_consignee: boolean;
  is_pickup: boolean;
  is_delivery: boolean;
  is_billing: boolean;
  is_notify: boolean;
}
export interface UserNewAddressBookAudit {
  created_at: string;
  created_by_email: string;
  modified_at?: string;
  modified_by_email?: string;
}

export interface AddressBookFilterCol {
  column: string;
  values: string[];
}

export interface AddressBookFiltersResponse {
  getUserAddressBookNewFilters: AddressBookFilterCol[];
}

export interface UserAddressBook {
  address: UserAddressBookAddress;
  addressFlags: UserAddressBookAddressFlag;
  contact: UserAddressBookContact;
  id: number;
  concatAddress?: string;
  type?: string;
  country?: string;
}

export interface UserAddressBookAddress {
  accountNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  companyName?: string;
  countryCode?: string;
  postalCode?: string;
  reference?: string;
  stateProvince?: string;
  preferredAddressInputType?: AddressInputType;
}

export interface UserAddressBookAddressFlag {
  isBilling: boolean;
  isConsignee: boolean;
  isDelivery: boolean;
  isPickup: boolean;
  isShipper: boolean;
  isNotify: boolean;
  __typename?: string;
}

export interface UserAddressBookContact {
  contactName: string;
  email: string;
  phone: string;
  reference: string;
}

export type UserAddressBookAddressRowData = Omit<
  UserAddressBookAddress,
  "accountNumber"
> & {
  concatAddress: string;
  country: string;
};

export interface ImprovedTableColumn {
  name: string;
  renderer?: (rowData: UserAddressBookAddressRowData) => JSX.Element;
  headerRenderer: (column: ImprovedTableColumn) => JSX.Element;
  displayName: string;
  emptyFallback: React.ReactNode;
}

export interface NewRowContentRendererProps {
  columns: ImprovedTableColumn[];
  row: UserNewAddressBook;
  rowIndex: number;
  hoveredRowId: number;
  setHoveredRowId: Dispatch<SetStateAction<number>>;
  clickedRowId: number;
  setClickedRowId: Dispatch<SetStateAction<number>>;
  intl: IntlShape;
  selectMenuOptionForListItem: (id: number, menuOption: RowMenuOptions) => void;
}

export interface HeaderContentRendererProps {
  columns: ImprovedTableColumn[];
}

export interface MenuOption {
  value: string;
  label: string;
  icon?: React.ReactElement;
}

export interface Config {
  VALUE: string;
  KEY: string;
  __typename: "Config";
}

export const AddressBookDefaultSorting = "addressbook.default.sorting";

export enum SmallColumnsNames {
  reference = "reference",
  type = "type",
  stateProvince = "stateProvince",
}

export enum RowMenuOptions {
  EDIT = "edit",
  DELETE = "delete",
}

export const UserAddressBookQueryPageSize = 10;
export const ModalContainerWidth = "80rem";

export const defaultOrderingParameter = "companyName";
export const defaultNewOrderingParameter = "COMPANY_NAME";

export const defaultOrderingDirection = "ASC";

export const columnNameConfig = {
  COMPANY_NAME: {
    filterRequestKey: "companyName",
    addressListRequestKey: "COMPANY_NAME",
  },
  ADDRESS: { filterRequestKey: "address", addressListRequestKey: "ADDRESS" },
  COUNTRY_CODE: {
    filterRequestKey: "country",
    addressListRequestKey: "COUNTRY_CODE",
  },
  REFERENCE: {
    filterRequestKey: "refCustomer",
    addressListRequestKey: "REF_CUSTOMER",
  },
};

export type ListElementSchema = ListElement & {
  columnName: string;
};

export interface CountriesDataObjForFilters {
  name: string;
  code: string;
}

export const generateFiltersConfig = (
  intl: IntlShape,
  isMobile: boolean
): ListElementSchema[] => [
  {
    columnName: columnNameConfig.COMPANY_NAME.filterRequestKey,
    title: intl.formatMessage(messages.company),
    placeholder: intl.formatMessage(messages.enterCompanyName),
    id: "companyName",
    type: ListElementTypeEnum.FREE_TEXT,
    noValueMessage: intl.formatMessage(messages.noTagsFound),
    applyText: intl.formatMessage(messages.apply),
  },
  {
    columnName: columnNameConfig.ADDRESS.filterRequestKey,
    title: intl.formatMessage(messages.address),
    placeholder: intl.formatMessage(messages.enterAddress),
    id: "address",
    type: ListElementTypeEnum.FREE_TEXT,
    noValueMessage: intl.formatMessage(messages.noTagsFound),
    applyText: intl.formatMessage(messages.apply),
  },
  ...(!isMobile
    ? [
        {
          columnName: columnNameConfig.COUNTRY_CODE.filterRequestKey,
          title: intl.formatMessage(messages.country),
          id: "country",
          type: ListElementTypeEnum.MULTI_SELECT,
          noValueMessage: intl.formatMessage(messages.noTagsFound),
          tagsList: [],
          intlText: {
            applyText: intl.formatMessage(messages.apply),
            clearText: intl.formatMessage(messages.clear),
            noResultText: intl.formatMessage(messages.noResult),
            noFilterSelectedText: intl.formatMessage(messages.noFilterSelected),
            placeholderText: intl.formatMessage(messages.searchFor),
          },
        },
        {
          columnName: columnNameConfig.REFERENCE.filterRequestKey,
          title: intl.formatMessage(messages.referenceId),
          placeholder: intl.formatMessage(messages.enterReference),
          id: "refCustomer",
          type: ListElementTypeEnum.FREE_TEXT,
          noValueMessage: intl.formatMessage(messages.noTagsFound),
          applyText: intl.formatMessage(messages.apply),
        },
      ]
    : []),
];
