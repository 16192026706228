import { ApolloCache, OperationVariables } from "@apollo/client";

export enum FavoriteItemType {
  SHIPMENT = "SHIPMENT",
  QUOTE = "QUOTE",
  BOOKING = "BOOKING",
  ORDER = "ORDER",
}

export interface FavoriteMutationResult {
  favorite?: boolean;
  unFavorite?: boolean;
}

export type FavoriteUpdateFunctionType = (
  cache: ApolloCache<unknown>,
  data: FavoriteMutationResult,
  variables: OperationVariables
) => void;
