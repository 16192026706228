import { ConsignmentStatusTypes } from "constants/quoteAndBook";
import client from "apolloClient";
import cloneDeep from "lodash/cloneDeep";
import startsWith from "lodash/startsWith";
import omitDeep from "omit-deep-lodash";
import { CALCULATE_CONSIGNMENT } from "queries/QnB/calculateConsignment";
import { getRecalculatedQuoteOption } from "queries/QnB/getRecalculatedQuoteOption";
import SUBMIT_CONSIGNMENT from "queries/QnB/submitConsignment";
import { UPDATE_CONSIGNMENT } from "queries/QnB/updateConsignment";
import qs from "query-string";
import { all, call, put, select } from "redux-saga/effects";
import { addError } from "redux/error/actions";
import {
  resetRecalculationError,
  setRecalculatingQuote,
  setRecalculationError,
} from "redux/quoteAndBookNonSync/actions";
import {
  calculateConsignment as calculateConsignmentAction,
  saveConsignmentAPICompleted,
  saveConsignmentAPISucceeded,
  saveOffer,
  submitConsignmentCompleted,
  submitConsignmentFailed,
  submitConsignmentSucceeded,
} from "redux/quoteData/actions";
import { getQuoteFromStorage } from "utils";
import { prepareFormDataForSendingToBackend } from "./utils";

function* fetchRecalculateQuote({
  quote: quoteTemp,
  isOffersPage,
  cb,
  cbForCalculate,
  locale,
}) {
  const corporatePartner = yield select(
    (state) => state?.userSettings?.visibilityScope?.uuid || ""
  );
  const billingCountryCode = yield select(
    (state) => state.quoteAndBook.selectedCountry?.value
  );
  const currency = yield select((state) => state.quoteAndBook.selectedCurrency);
  const contractIdFromStore = yield select(
    (state) => state.quoteAndBookNonSync.contractId
  );
  const quote = cloneDeep(quoteTemp);
  yield put(setRecalculatingQuote(true));
  const inputQuote = omitDeep(
    {
      ...quote,
      currency: typeof currency === "string" ? currency : quote?.currency,
      billingCountry: undefined,
      billing: {
        ...quote.billing,
        country: billingCountryCode,
      },
      locale,
    },
    ["stationAddress", "currentItem", "isPhoneNumberEmpty"]
  );
  inputQuote.originalResponse = "";
  if (!inputQuote.contractId && contractIdFromStore) {
    inputQuote.contractId = contractIdFromStore;
  }
  try {
    const { data, errors } = yield client.query({
      query: getRecalculatedQuoteOption,
      variables: {
        inputQuote,
        corporatePartner,
        routeSwitched: false, // TODO understand how to define this
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
    const recalculationErrorAlready = yield select(
      (state) => state.quoteAndBookNonSync.recalculationErrorAlready
    );
    if (errors) {
      const errorObj = errors.find((e) =>
        ["404", "500"].includes(e.message.split(":")[0])
      );
      const errorCode = errorObj?.message?.split(":")[0];
      if (errorCode && !recalculationErrorAlready?.[errorCode]) {
        if (recalculateQuote) {
          yield put(
            setRecalculationError({
              code: errorCode,
              value: true,
            })
          );
          yield put(
            calculateConsignmentAction({
              data: inputQuote,
              cb: cbForCalculate,
              locale,
            })
          );
        } else {
          // TODO if we do not pass recalculateQuote??
        }
      } else {
        // TODO if we have different error tha 404??
      }
    } else if (data && data.getRecalculatedQuoteOption) {
      if (isOffersPage) {
        yield put(
          saveOffer(omitDeep(data.getRecalculatedQuoteOption, ["__typename"]))
        );
      } else {
        // eslint-disable-next-line
        cb({
          currency: data.getRecalculatedQuoteOption?.currency,
          charges: omitDeep(data.getRecalculatedQuoteOption?.charges || {}, [
            "__typename",
          ]),
          serviceSettings: data.getRecalculatedQuoteOption?.serviceSettings,
          quoteType: data.getRecalculatedQuoteOption.quoteType,
          originStationAddress:
            data.getRecalculatedQuoteOption.routeDetails?.originStation,
          destinationStationAddress:
            data.getRecalculatedQuoteOption.routeDetails?.destinationStation,
          ratesType: data.getRecalculatedQuoteOption.ratesType,
          ratesSource: data.getRecalculatedQuoteOption.ratesSource,
          estimatedDeliveryDate:
            data.getRecalculatedQuoteOption.estimatedDeliveryDate,
        });
      }
    }
  } catch (e) {
    // eslint-disable-next-line
    console.error("error:: ", e);
  } finally {
    yield put(setRecalculatingQuote(false));
  }
}

export function* recalculateQuote({
  payload: { quote, cb, cbForCalculate, locale },
}) {
  yield call(fetchRecalculateQuote, { quote, cb, cbForCalculate, locale });
}

export function* updateOffers({ payload }) {
  if (startsWith(payload?.fromPath, "/offers")) {
    // TODO if we're on booking page fetch only the selected quote/consignment
    const currency = yield select(
      (state) => state.quoteAndBook.selectedCurrency
    );
    const existingOffers = yield select(
      (state) => state.quoteData.offerOptions
    );
    const estimatedPickupDate = yield select(
      (state) => state.quoteData.pickupDate || ""
    );
    if (existingOffers.length > 0) {
      const quote = getQuoteFromStorage();

      yield all(
        existingOffers
          .filter(
            (offer) =>
              offer?.selectedQuoteOption?.quoteType === "INSTANT" &&
              offer?.selectedQuoteOption?.product
          )
          .map(
            ({
              selectedQuoteOption: {
                calculationDetailsKey,
                serviceType,
                ratesType,
                ratesSource,
                quoteType,
                product,
                serviceSettings,
              },
            }) =>
              call(fetchRecalculateQuote, {
                quote: {
                  ...quote,
                  currency,
                  offerInfo: {
                    calculationDetailsKey: calculationDetailsKey,
                    product: product,
                    serviceType: serviceType,
                    ratesType: ratesType,
                    ratesSource: ratesSource,
                    quoteType: quoteType,
                  },
                  service: { ...serviceSettings, ...quote.service }, // todo: map proper tsa type
                  estimatedPickupDate,
                },
                isOffersPage: true,
                locale: payload.locale,
              })
          )
      );
    }
  }
}

export function* calculateConsignment({ payload: { data, cb, locale } }) {
  const billingCountryCode = yield select(
    (state) => state.quoteAndBook.selectedCountry?.value
  );
  const corporatePartnerUUID = yield select(
    (state) => state.userSettings.visibilityScope?.uuid
  );
  const quote = cloneDeep(data);
  yield put(setRecalculatingQuote(true));
  try {
    const { errors, data } = yield client.query({
      query: CALCULATE_CONSIGNMENT,
      variables: {
        corporatePartner: corporatePartnerUUID,
        inputQuote: omitDeep(
          {
            ...quote,
            billing: {
              ...quote.billing,
              country: billingCountryCode,
            },
            locale,
          },
          [
            "stationAddress",
            "currentItem",
            "dataVersion",
            "isPhoneNumberEmpty",
            // todo: remove more fields
          ]
        ),
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
    yield put(saveConsignmentAPICompleted());
    if (!errors) {
      yield put(resetRecalculationError());
      yield put(saveConsignmentAPISucceeded());
    }
    cb(data);
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
    yield put(saveConsignmentAPICompleted());
  } finally {
    yield put(setRecalculatingQuote(false));
  }
}

export function* saveConsignmentSuccess(action) {
  const queryObject = qs.parse(window?.location?.search);
  const cpString = queryObject?.corporatePartner
    ? `&corporatePartner=${queryObject.corporatePartner}`
    : "";
  if (action?.payload?.id) {
    window.location.href =
      window.location.origin +
      "/booking/service?id=" +
      action?.payload?.uuid +
      cpString;
  }
  yield "";
}

export function* saveConsignment(action) {
  const { cb, blockedCb, finalContactLink, formRef, uuid } = action.payload;

  const previousCargoItems = yield select(
    (state) => state.quoteData?.selectedOffer?.cargoDetails?.cargoItems
  );

  try {
    const { data, errors } = yield client.query({
      query: UPDATE_CONSIGNMENT,
      variables: prepareFormDataForSendingToBackend({
        ...action.payload,
        previousCargoItems,
      }),
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
    yield put(saveConsignmentAPICompleted());
    if (!errors) {
      if (data?.updateConsignment?.status === ConsignmentStatusTypes.BLOCKED) {
        blockedCb?.();
        yield put(
          addError({
            blockedCustomer: "Blocked customer",
            contactLink: finalContactLink,
          })
        );
        return;
      }
      const form = formRef?.current;
      if (form) {
        form.change("status", data?.updateConsignment?.status);
      }
      yield put(
        saveConsignmentAPISucceeded(
          uuid === "NEW" ? data?.updateConsignment : undefined
        )
      );
    }
    if (cb) {
      cb();
    }
  } catch (e) {
    yield put(saveConsignmentAPICompleted());
  }
}

export function* submitConsignment(action) {
  try {
    const isUserLoggedIn = yield select((state) => state.auth.isUserLoggedIn);
    const corporatePartnerUUID = yield select((state) =>
      (state.userSettings?.visibilityScope?.uuid || "").toUpperCase()
    );
    const doesCurrentUserHaveQuoteRole = yield select(
      (state) => state.auth?.doesCurrentUserHaveQuoteRole
    );
    const { data, errors } = yield client.query({
      query: SUBMIT_CONSIGNMENT,
      variables: {
        uuid: action.payload.uuid,
        corporatePartner:
          isUserLoggedIn && doesCurrentUserHaveQuoteRole
            ? corporatePartnerUUID
            : undefined,
      },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
    yield put(submitConsignmentCompleted());
    if (!errors) {
      yield put(submitConsignmentSucceeded(data));
    } else {
      yield put(submitConsignmentFailed());
    }
  } catch (e) {
    yield put(submitConsignmentCompleted());
  }
}
