// values are not lower case, to match BE data
export enum ConsignmentStatusTypes {
  BLOCKED = "BLOCKED",
}

export enum ADDRESS_TYPE {
  TERMINAL = "TERMINAL",
  LOCALITY_BASED = "LOCALITY_BASED",
  LOCALITY_MANUAL = "LOCALITY_MANUAL",
  LOCALITY_MAPS = "LOCALITY_MAPS",
  STREET_ADDRESS = "STREET_ADDRESS",
}

export enum FreightType {
  AIR = "AFR",
  OCEAN = "OFR",
}

export enum AirFreightService {
  COMMON = "COMMON",
  ECONOMY = "ECONOMY",
  CONNECT = "CONNECT",
  PRIORITY = "PRIORITY",
  GEN = "GEN",
  AFP_PRIORITY = "AFP_PRIORITY",
  AFP_ECONOMY = "AFP_ECONOMY",
  AEB = "AEB",
  AEC = "AEC",
  AEM = "AEM",
  ACB = "ACB",
  ACC = "ACC",
  ACM = "ACM",
  APB = "APB",
  APC = "APC",
  APD = "APD",
  APM = "APM",
  PLE = "PLE",
  PLP = "PLP",
}

export const airFreightPlusServiceTypes = [
  AirFreightService.AFP_PRIORITY,
  AirFreightService.AFP_ECONOMY,
];

export const ADDRESS_TYPES_WITH_STREET = [
  ADDRESS_TYPE.LOCALITY_MANUAL,
  ADDRESS_TYPE.LOCALITY_MAPS,
  ADDRESS_TYPE.STREET_ADDRESS,
];
