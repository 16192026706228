import SVGWrapper from "components/atoms/SVGWrapper";
import { colors, Divider } from "react-lib";
import styled, { css } from "styled-components";
import { SCREEN_SIZES } from "utils/css";
import FlyOutItem from "./parts/FlyOutItem";

export const SearchIconWrapper = styled(SVGWrapper).attrs((p) => ({
  fill: p.isInputFocused ? p.theme.colors.black : p.theme.colors.black45,
  padding: `0.6875rem 0 0.6875rem 0.6875rem`,
}))`
  position: absolute;
  bottom: 0;
  left: 0.6875rem;
`;

export const flyOutListSearchWrapperStyles = css`
  label {
    padding: 0.6875rem 0.6875rem 0 0.6875rem;
  }

  input {
    border: none;
    border-bottom: solid 1px ${(p) => p.theme.colors.gray20};
    border-radius: 0;
    height: 2.875rem;
    padding-left: 3rem;
    padding-top: 0.6875rem;
    padding-bottom: 0.6875rem;
  }

  input::placeholder {
    opacity: 0.3;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${(p) => p.theme.colors.black90};
  }

  input:focus::placeholder {
    opacity: 1;
  }

  input:focus {
    border: none;
    border-bottom: solid 1px ${(p) => p.theme.colors.gray20};
  }

  .simplebar-scrollbar::before {
    opacity: 0.23;
    border-radius: 0.4375rem;
    background-color: #000000;
  }
`;

export const FlyOutWrapper = styled.div`
  position: absolute;
  line-height: 1em;
  background: ${colors.white};
  box-shadow: 0 0.25rem 2rem 0 rgba(0, 0, 0, 0.07),
    0 0 0.125rem 0 rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.05);
  width: 20.9375rem;

  ${(props) =>
    props.visible
      ? css`
          transform: scale(1);
          transform-origin: top right;
          top: 4.375rem;
          right: 0;
          opacity: 1;
          z-index: 20;
          transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
        `
      : css`
          transform: scale(0.01);
          transform-origin: top right;
          top: 1.875rem;
          right: 1.25rem;
          z-index: -2;
          opacity: 0.1;
          transition: all 75ms cubic-bezier(0.4, 0, 1, 1);
        `};

  &::after {
    content: "";
    width: 1.125rem;
    height: 1.125rem;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    transform: rotate(-45deg);
    position: absolute;
    top: -0.5rem;
    right: 1.1875rem;
    z-index: -1;
    background: ${colors.white};
  }

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    width: 23.75rem;
  }
`;

const sectionPadding = 1.25;

export const BoldText = styled.div`
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.43;
  color: ${colors.black};
`;

export const Email = styled.div`
  font-size: 0.75rem;
  line-height: 1.33;
  color: ${colors.webBlack};
`;

export const SwitchInfoWrapper = styled.div`
  margin-left: 0.75rem;
`;

export const UserWrapper = styled.div`
  display: flex;
  padding: 1rem ${sectionPadding}rem 0.875rem ${sectionPadding}rem;
  font-size: 0.875rem;
  cursor: pointer;

  &:hover {
    background-color: ${colors.gray05};
  }

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    font-size: 1rem;
  }
`;

export const FlyoutDivider = styled(Divider)`
  width: calc(100% - ${2 * sectionPadding}rem);
  margin: auto;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
`;

export const FlyOutItemWrapper = styled.a`
  padding: 0.75rem ${sectionPadding}rem;
  display: block;
  font-size: 1rem;
  line-height: 1.25;
  text-decoration: none;
  color: ${colors.black90};

  &:hover {
    text-decoration: none;
    background-color: ${colors.gray05};
    cursor: pointer;
  }
`;

export const FlyOutBottomWrapper = styled.div`
  text-align: center;
  margin: 1rem 0 1rem 0;

  & a:first-of-type {
    margin-right: 1rem;
  }
`;

export const FlyOutItemWithHighlighter = styled(FlyOutItem)`
  padding: 1.0625rem 1.5rem 1.0625rem 1.5rem;
  position: relative;
  line-height: 1rem;

  &:hover {
    color: ${colors.black};
    text-decoration: none;
    background-color: ${colors.white};

    &:after {
      content: "";
      position: absolute;
      height: 0.125rem;
      width: 0.9375rem;
      background-color: ${colors.dhlRed};
      z-index: 10;
      top: 1.375rem;
      right: 0;
    }
  }
`;
