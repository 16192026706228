export const autoHideInterval = 3000;

export enum AddressBookSuccessfulAction {
  ADD_ADDRESS_SUCCESS = "add_address_success",
  EDIT_ADDRESS_SUCCESS = "edit_address_success",
  DELETE_ADDRESS_SUCCESS = "delete_address_success",
}

export interface AddressBookSuccessToastMessageProps {
  hideToastMessage: () => void;
  successfulAction: AddressBookSuccessfulAction;
  message: string;
}
