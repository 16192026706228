import { numberOfSearchHistoryItem } from "constants/common";
import union from "lodash/union";
import actionTypes from "./action-types";

const initialState = {
  searchHistory: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_SHIPMENT_SEARCH_HISTORY:
      return {
        ...state,
        searchHistory: union(action.payload, state.searchHistory).slice(
          0,
          numberOfSearchHistoryItem
        ),
      };
    default:
      return state;
  }
};

export default reducer;
