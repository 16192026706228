const baseType = "AUTH";

const types = {
  GET_TOKEN: `${baseType}/GET_TOKEN`,
  GET_TOKEN_SUCCESS: `${baseType}/GET_TOKEN_SUCCESS`,
  GET_TOKEN_ERROR: `${baseType}/GET_TOKEN_ERROR`,
  LOG_OUT: `${baseType}/LOG_OUT`,
  GET_CURRENT_USER_ROLES: `${baseType}/GET_CURRENT_USER_ROLES`,
  SET_CURRENT_USER_ROLES: `${baseType}/SET_CURRENT_USER_ROLES`,
  SET_LOGGED_IN_USER_ROLES: `${baseType}/SET_LOGGED_IN_USER_ROLES`,
  SET_LOGGED_IN_USER_EMAIL: `${baseType}/SET_LOGGED_IN_USER_EMAIL`,
  SET_DEMO_USER_ROLES: `${baseType}/SET_DEMO_USER_ROLES`,
  SET_USER_LOGGED_IN: `${baseType}/SET_USER_LOGGED_IN`,
  SET_USER_ID: `${baseType}/SET_USER_ID`,
  SET_USER_CONNECTION_COUNTRY: `${baseType}/SET_USER_CONNECTION_COUNTRY`,
};

export default types;
