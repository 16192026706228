import { defineMessages } from "react-intl";

export default defineMessages({
  address: {
    id: "bookingStandalone.route.addressBook.modal.form.header.address",
    defaultMessage: "Address",
  },
  addressType: {
    id: "bookingStandalone.route.addressBook.modal.form.header.addressType",
    defaultMessage: "Address type (optional)",
  },
  contactDetails: {
    id: "bookingStandalone.route.addressBook.modal.form.header.country",
    defaultMessage: "Contact Details",
  },
  country: {
    id: "bookingStandalone.route.addressBook.modal.form.field.country",
    defaultMessage: "Country",
  },
  searchFor: {
    id: "bookingStandalone.route.addressBook.modal.form.field.searchFor",
    defaultMessage: "Search for …",
  },
  street: {
    id: "bookingStandalone.route.addressBook.modal.form.field.street",
    defaultMessage: "Street",
  },
  zip: {
    id: "bookingStandalone.route.addressBook.modal.form.field.zip",
    defaultMessage: "ZIP",
  },
  city: {
    id: "bookingStandalone.route.addressBook.modal.form.field.city",
    defaultMessage: "City",
  },
  states: {
    id: "bookingStandalone.route.addressBook.modal.form.field.states",
    defaultMessage: "States/ Provinces/ Counties (optional)",
  },
  companyName: {
    id: "bookingStandalone.route.addressBook.modal.form.field.companyName",
    defaultMessage: "Company name",
  },
  name: {
    id: "bookingStandalone.route.addressBook.modal.form.field.name",
    defaultMessage: "Name",
  },
  email: {
    id: "bookingStandalone.route.addressBook.modal.form.field.email",
    defaultMessage: "Email",
  },
  phoneNumber: {
    id: "bookingStandalone.route.addressBook.modal.form.field.phoneNumber",
    defaultMessage: "Phone Number",
  },
  referenceID: {
    id: "bookingStandalone.route.addressBook.modal.form.field.referenceID",
    defaultMessage: "Reference ID",
  },
  pickUp: {
    id: "bookingStandalone.route.addressBook.modal.form.field.pickUp",
    defaultMessage: "Pick-Up",
  },
  shipper: {
    id: "bookingStandalone.route.addressBook.modal.form.field.shipper",
    defaultMessage: "Shipper",
  },
  delivery: {
    id: "bookingStandalone.route.addressBook.modal.form.field.delivery",
    defaultMessage: "Delivery",
  },
  consignee: {
    id: "bookingStandalone.route.addressBook.modal.form.field.consignee",
    defaultMessage: "Consignee",
  },
  billing: {
    id: "bookingStandalone.route.addressBook.modal.form.field.billing",
    defaultMessage: "Billing",
  },
  notify: {
    id: "bookingStandalone.route.addressBook.modal.form.field.notify",
    defaultMessage: "Notify party",
  },
  cancel: {
    id: "bookingStandalone.route.addressBook.modal.form.field.cancel",
    defaultMessage: "Cancel",
  },
  saveAddress: {
    id: "bookingStandalone.route.addressBook.modal.form.field.saveAddress",
    defaultMessage: "Save Address",
  },
  pleaseFillOutCountry: {
    id: "bookingStandalone.route.addressBook.modal.form.hint.pleaseFillOutCountry",
    defaultMessage: "Please fill out Country first to update the other fields",
  },
});
