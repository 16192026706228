import { Checkbox, media } from "react-lib";
import styled from "styled-components";

export const AddressTypePicker = styled.div`
  padding: 3.125rem 0 2.25rem 0;
`;

export const AddressTypeCheckboxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  min-height: 1.5rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.15;

  ${media.from("md")} {
    align-items: center;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-right: 2rem;
  margin-bottom: 0.5rem;
  min-width: 7.5rem;

  ${media.from("md")} {
    min-width: 6.25rem;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  height: 1.5rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.15;
  margin-bottom: 0.5rem;
`;
