import StarIcon from "components/atoms/Icons/StarIcon";
import SVGWrapper from "components/atoms/SVGWrapper";
import useOutsideClick from "hooks/useOutsideClick";
import React, { useRef, FC } from "react";
import { useIntl } from "react-intl";
import { Color } from "react-lib";
import { useDispatch } from "react-redux";
import { setBookingsListFilter } from "redux/userSettings/actions";
import * as C from "./FollowedBooking.constants";
import messages from "./FollowedBooking.messages";
import * as S from "./FollowedBooking.styles";

interface FollowedBookingProps {
  onOutsideClick: () => void;
}

const FollowedBooking: FC<FollowedBookingProps> = ({ onOutsideClick }) => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const intl = useIntl();

  useOutsideClick(wrapperRef, onOutsideClick);

  return (
    <S.FollowedBooking
      ref={wrapperRef}
      onClick={() =>
        dispatch(
          setBookingsListFilter(
            C.followedBookingKey,
            intl.formatMessage(messages.follow)
          )
        )
      }
    >
      <SVGWrapper fill={Color.yellow95} hoverFill={Color.yellow95} showPointer>
        <StarIcon filled />
      </SVGWrapper>
    </S.FollowedBooking>
  );
};

export default FollowedBooking;
