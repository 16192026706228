import { defineMessages } from "react-intl";

export default defineMessages({
  backToList: {
    id: "bookingStandalone.route.addressBook.modal.backToList",
    defaultMessage: "Back to List",
  },
  saveAddress: {
    id: "bookingStandalone.route.addressBook.modal.saveAddress",
    defaultMessage: "Save Address",
  },
  cancel: {
    id: "bookingStandalone.route.addressBook.modal.cancel",
    defaultMessage: "Cancel",
  },
});
