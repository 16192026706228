import { Color, Hint } from "react-lib";
import PhoneInputNew from "react-lib/es/molecules/PhoneInput/PhoneInput";
import styled from "styled-components";

export const StyledPhoneInputNew = styled(PhoneInputNew)`
  background-color: ${Color.white};
`;

export const StyledHint = styled(Hint)`
  margin-top: 0.25rem;
`;
