import {
  Product,
  CargoCodes,
  ServiceLevel,
  WeightUnit,
  VolumeUnit,
  DimensionsUnit,
  CargoItem,
  WeightSpecifier,
  roundUpDecimals,
  Party,
  DirectionType,
} from "constants/bookWorkflow";
import * as MDM from "constants/masterDataManagement";
import { ValidationErrors } from "final-form";
import { ObjectSchema } from "joi";
import BigDecimal from "js-big-decimal";
import set from "lodash/set";
import moment from "moment";
import { IntlShape } from "react-intl";
import summaryMessages from "../components/organisms/BookingStandalone/BookingSummary/BookingSummary.messages";

export const formatServiceLevel = (
  intl: IntlShape,
  serviceLevel?: string | null,
  product?: Product,
  service?: MDM.Service,
  fallbackMessage = ""
): string => {
  if (product === Product.OFR && serviceLevel) {
    return serviceLevel === ServiceLevel.FCL
      ? intl.formatMessage(summaryMessages.serviceLevelFCL)
      : intl.formatMessage(summaryMessages.serviceLevelLCL);
  }

  if (service) {
    return intl.formatMessage({
      id: service.labelWeblateKey,
      defaultMessage: service.label,
    });
  }

  return fallbackMessage;
};

export const createFormValidator =
  <T>(formValidationSchema: ObjectSchema<T>) =>
  (values: T): ValidationErrors => {
    const { error } = formValidationSchema.validate(values);

    if (!error?.details) {
      return undefined;
    }

    const invalidFields: ValidationErrors = {};

    for (const { path, message } of error?.details) {
      set(invalidFields, path, message);
    }

    return invalidFields;
  };

export const preparePdfFilename = (
  prefix: "Label" | "Manifest",
  housebill: string,
  product: string,
  pickupHandlingStation?: string,
  deliveryHandlingStation?: string
): string => {
  const pickup = pickupHandlingStation;
  const delivery = deliveryHandlingStation;

  return `${prefix}_${housebill}_${product}_${pickup ?? "NA"}-${
    delivery ?? "NA"
  }_${moment().format("DDMMMYY")}.pdf`;
};

const isEuropeCountry = (
  countries: MDM.Country[] | undefined,
  countryCode: string | undefined
): boolean | undefined => {
  if (countries && countryCode) {
    const countryWithTargetCode = countries.find(
      ({ code }) => code === countryCode
    );

    return !!Number(countryWithTargetCode?.is_european_union);
  }
};

export const isCustomClearanceBlocked = (
  countries: MDM.Country[] | undefined,
  originCountryCode: string | undefined,
  destinationCountryCode: string | undefined
): boolean => {
  if (originCountryCode === destinationCountryCode) {
    return true;
  }

  const isOriginInEurope = isEuropeCountry(countries, originCountryCode);
  const isDestinationInEurope = isEuropeCountry(
    countries,
    destinationCountryCode
  );

  return (
    (isOriginInEurope && isDestinationInEurope) ||
    isOriginInEurope === undefined ||
    isDestinationInEurope === undefined
  );
};

export const getAvailablePackageTypes = (
  allPackageTypes: MDM.PackageType[],
  cargoTypeSelected?: CargoCodes,
  pickupCountry?: MDM.Country
) => {
  const allPackageTypesForCargoType =
    allPackageTypes.filter((item) => {
      if (cargoTypeSelected === CargoCodes.CONTAINER) {
        return item.SERVICE === ServiceLevel.FCL;
      }

      if (cargoTypeSelected === CargoCodes.LOOSE_CARGO) {
        return item.SERVICE === ServiceLevel.LCL;
      }

      return false;
    }) || [];

  const globalPackageTypes =
    allPackageTypesForCargoType.filter((item) => item.REGION === null) || [];

  const regionalPackageTypes =
    allPackageTypesForCargoType.filter(
      (item) =>
        item.REGION === pickupCountry?.region && item.COUNTRY_CODE === null
    ) || [];

  const nationalPackageTypes =
    allPackageTypesForCargoType.filter(
      (item) =>
        item.COUNTRY_CODE === pickupCountry?.code &&
        !regionalPackageTypes.some((value) => value.CODE === item.CODE)
    ) || [];

  const uniqueAvailablePackageTypes = [
    ...globalPackageTypes,
    ...regionalPackageTypes,
    ...nationalPackageTypes,
  ].filter(
    (packageType, index, array) =>
      array.findIndex(
        (testedPackageType) => testedPackageType.CODE === packageType.CODE
      ) === index
  );

  return uniqueAvailablePackageTypes;
};

export const getUnitSystem = (
  cargoItem: Partial<OmitGraphQLTypename<CargoItem>> = {}
): MDM.UnitSystem =>
  cargoItem?.weightUnit === WeightUnit.LBR ||
  cargoItem?.volumeUnit === VolumeUnit.FTQ ||
  cargoItem?.dimensionsUnit === DimensionsUnit.INH
    ? MDM.UnitSystem.IMPERIAL
    : MDM.UnitSystem.METRIC;

export const findContainer = (
  container: MDM.ContainerDescription,
  cargoItem: Partial<OmitGraphQLTypename<CargoItem>>,
  unitType: MDM.UnitSystem
) =>
  container.CODE === cargoItem.packageType &&
  container.SYSTEM_OF_MEASUREMENT === unitType;

export function formatBigDecimal(value: BigDecimal, precision: number = 2) {
  const roundedValue = value
    .round(precision, BigDecimal.RoundingModes.UP)
    .getValue();
  return parseFloat(roundedValue);
}

export const getTotalContainersNumber = (
  cargoItems: Partial<OmitGraphQLTypename<CargoItem>>[]
) =>
  cargoItems.reduce((accumulator, current) => {
    const quantity = current.quantity || current.packageNumber || 0;
    return accumulator + quantity;
  }, 0);

export const getTotalNetWeight = (
  cargoItems: Partial<OmitGraphQLTypename<CargoItem>>[],
  containersDescription: MDM.ContainerDescription[] = [],
  unitType: MDM.UnitSystem
) => {
  const totalNetWeight = cargoItems.reduce((accumulator, current) => {
    if (!current.weight) {
      return accumulator;
    }

    const foundContainer = containersDescription.find((container) =>
      findContainer(container, current, unitType)
    );

    const quantity = current?.quantity || current.packageNumber;

    const totalCurrentWeight = new BigDecimal(quantity).multiply(
      new BigDecimal(current.weight)
    );
    const tareWeight = new BigDecimal(quantity).multiply(
      new BigDecimal(foundContainer?.TARE_WEIGHT ?? 0)
    );

    if (Number(totalCurrentWeight.subtract(tareWeight).getValue()) < 0) {
      return accumulator;
    }

    return accumulator.add(totalCurrentWeight).subtract(tareWeight);
  }, new BigDecimal(0));

  return formatBigDecimal(totalNetWeight);
};

export const getTotalGrossWeight = (
  cargoItems: Partial<OmitGraphQLTypename<CargoItem>>[],
  containersDescription: MDM.ContainerDescription[] = [],
  unitType: MDM.UnitSystem
) => {
  const totalGrossWeight = cargoItems.reduce((accumulator, current) => {
    const foundContainer = containersDescription?.find((container) =>
      findContainer(container, current, unitType)
    );
    const quantity = current?.quantity || current.packageNumber;
    if (!current.weight && current.weightSpecifier === WeightSpecifier.GROSS) {
      return accumulator;
    }
    if (!current.weight && current.weightSpecifier === WeightSpecifier.NET) {
      const totalCurrentWeight = foundContainer
        ? new BigDecimal(foundContainer.TARE_WEIGHT).multiply(
            new BigDecimal(quantity)
          )
        : new BigDecimal(0);
      return accumulator.add(totalCurrentWeight);
    }
    if (current.weight && current.weight < (foundContainer?.TARE_WEIGHT || 0)) {
      return accumulator;
    }

    const totalCurrentWeight = new BigDecimal(current.weight).multiply(
      new BigDecimal(quantity)
    );

    return accumulator.add(totalCurrentWeight);
  }, new BigDecimal(0));

  return formatBigDecimal(totalGrossWeight);
};

export const getTotalVolume = (
  cargoItems: Partial<OmitGraphQLTypename<CargoItem>>[],
  digits = roundUpDecimals.volume
) => {
  const totalVolume = cargoItems.reduce((accumulator, current) => {
    if (!current.volume) return accumulator;

    const quantity = current?.quantity || current.packageNumber;
    const totalCurrentVolume = new BigDecimal(current.volume).multiply(
      new BigDecimal(quantity)
    );

    return accumulator.add(totalCurrentVolume);
  }, new BigDecimal(0));

  return formatBigDecimal(totalVolume, digits);
};

export const isSingleCargoTypeAvailable = (
  type: MDM.TerminalServiceType.LCL | MDM.TerminalServiceType.FCL,
  pickup?: MDM.Terminal,
  delivery?: MDM.Terminal
): boolean => {
  const terminals = [pickup, delivery];
  const indexOfTerminalWithProvidedType = terminals.findIndex(
    (terminal) => terminal?.serviceType === type
  );

  if (indexOfTerminalWithProvidedType === -1) {
    return false;
  }

  const terminalsCopy = [...terminals];
  terminalsCopy.splice(indexOfTerminalWithProvidedType, 1);
  const secondTerminal = terminalsCopy[0];

  if (
    secondTerminal &&
    secondTerminal.serviceType !== type &&
    secondTerminal.serviceType !== MDM.TerminalServiceType.LCL_FCL
  ) {
    return false;
  }

  return true;
};

export const getOfrCargoType = (
  serviceLevel: string | undefined | null
): CargoCodes | undefined => {
  switch (serviceLevel) {
    case ServiceLevel.FCL:
      return CargoCodes.CONTAINER;

    case ServiceLevel.LCL:
      return CargoCodes.LOOSE_CARGO;

    default:
      return undefined;
  }
};

export const getCargoType = (
  product: Product | undefined,
  serviceLevel: string | undefined | null
): CargoCodes | undefined => {
  switch (product) {
    case undefined:
      return undefined;

    case Product.OFR:
      return getOfrCargoType(serviceLevel);

    default:
      return CargoCodes.LOOSE_CARGO;
  }
};

export const getOfrCargoCodes = (
  pickup?: OmitGraphQLTypename<Party>,
  delivery?: OmitGraphQLTypename<Party>,
  pickupTerminals?: MDM.Terminal[],
  deliveryTerminals?: MDM.Terminal[]
) => {
  const pickupTerminal = pickup?.useTerminal
    ? pickupTerminals?.find(
        ({ unlocode, direction }) =>
          unlocode === pickup?.terminalCode &&
          direction === DirectionType.EXPORT
      )
    : undefined;
  const deliveryTerminal = delivery?.useTerminal
    ? deliveryTerminals?.find(
        ({ unlocode, direction }) =>
          unlocode === delivery?.terminalCode &&
          direction === DirectionType.IMPORT
      )
    : undefined;

  // Hide cargo types on page refresh until terminals are fetched
  if (
    (pickup?.useTerminal &&
      pickup.address?.countryCode &&
      pickup.terminalCode &&
      !pickupTerminal) ||
    (delivery?.useTerminal &&
      delivery.address?.countryCode &&
      delivery.terminalCode &&
      !deliveryTerminal)
  ) {
    return undefined;
  }

  if (
    isSingleCargoTypeAvailable(
      MDM.TerminalServiceType.LCL,
      pickupTerminal,
      deliveryTerminal
    )
  ) {
    return [CargoCodes.LOOSE_CARGO];
  }

  if (
    isSingleCargoTypeAvailable(
      MDM.TerminalServiceType.FCL,
      pickupTerminal,
      deliveryTerminal
    )
  ) {
    return [CargoCodes.CONTAINER];
  }

  return [CargoCodes.CONTAINER, CargoCodes.LOOSE_CARGO];
};
