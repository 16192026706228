import { QuotePackageTypeCodes } from "components/templates/GetAQuote/constants";
import { CargoTypes } from "components/templates/QuoteCargo/constants";
import cloneDeep from "lodash/cloneDeep";
import omitDeep from "omit-deep-lodash";

const hasValidDimensions = (dimensions = []) =>
  dimensions.length > 0 && dimensions.every((dimension) => dimension > 0);

const isValidCargo = ({ dimensions, weight, count, packageType }) =>
  hasValidDimensions(dimensions) && weight > 0 && count > 0 && !!packageType;

export const removeInvalidCargo = (cargoItems = []) =>
  cargoItems.filter((item) => isValidCargo(item));

export const enhanceToQuickEstimationSchema = (cargoItems = []) =>
  cargoItems.map((item) => ({
    ...item,
    packageType: QuotePackageTypeCodes.ESTIMATE_ONLY.key,
  }));

function checkCargoValidityForQuickEstimation(data, previousCargoItems) {
  const quote = cloneDeep(data);
  const currentlySelectedCargoType = quote.cargo.cargoType;

  // We perform validation here to remove items that were added by user but not fully set. API does not allow such cargo.
  const shouldCheckCargoValidity =
    currentlySelectedCargoType === CargoTypes.LOOSE_CARGO;

  if (shouldCheckCargoValidity) {
    let validatedCargoItems;
    let adjustedCargoType;
    const validCargo = removeInvalidCargo(quote.cargo.cargoItems);

    if (validCargo.length) {
      validatedCargoItems = validCargo;
    } else {
      validatedCargoItems = enhanceToQuickEstimationSchema(previousCargoItems);
      adjustedCargoType = CargoTypes.QUICK_ESTIMATION;
    }

    return {
      ...quote,
      cargo: {
        ...quote.cargo,
        cargoItems: validatedCargoItems,
        cargoType: adjustedCargoType || quote.cargo.cargoType,
      },
    };
  }

  return quote;
}

export const prepareFormDataForSendingToBackend = ({
  uuid,
  data,
  shouldSendNotification,
  locale,
  previousCargoItems,
}) => {
  const quote = checkCargoValidityForQuickEstimation(data, previousCargoItems);

  let billingData = quote.billing;
  let billigAddressSource = "billing";
  let countryCodeSource;

  if (billingData?.companyDetailsAddressType === "fill") {
    if (
      billingData?.companyDetails?.address?.countryCode ===
      quote.route?.origin?.country?.value
    ) {
      countryCodeSource = "origin";
      billigAddressSource = quote.route?.fillshipperAddress
        ? "origin"
        : "shipper";
    } else if (
      billingData.companyDetails.address.countryCode ===
      quote.route?.destination?.country?.value
    ) {
      countryCodeSource = "destination";
      billigAddressSource = quote.route?.fillconsigneeAddress
        ? "destination"
        : "consignee";
    }
  }

  if (billigAddressSource !== "billing") {
    billingData = {
      ...billingData,
      companyDetails: {
        ...billingData?.companyDetails,
        address: {
          ...billingData?.companyDetails?.address,
          addressLine: quote.route?.[billigAddressSource]?.address?.addressLine,
          zipCode: quote.route?.[billigAddressSource]?.address?.zipCode,
          city: quote.route?.[billigAddressSource]?.address?.city,
          countryCode: quote.route?.[countryCodeSource]?.country?.value,
        },
      },
    };
  }

  return {
    uuid: uuid,
    shouldSendNotification: shouldSendNotification,
    inputQuote: omitDeep(
      {
        ...quote,
        locale,
        billing: billingData,
        campaignId: sessionStorage?.getItem("acoustics_campaign_id"),
        route: {
          ...quote.route,
          ...(quote.route.fillshipperAddress
            ? {
                shipper: {
                  ...quote.route.shipper,
                  address: quote.route.origin.address,
                  contactDetails: quote.route.origin.contactDetails,
                  country: quote.route.origin.country,
                },
              }
            : {}),
          ...(quote.route.fillconsigneeAddress
            ? {
                consignee: {
                  ...quote.route.consignee,
                  address: quote.route.destination.address,
                  contactDetails: quote.route.destination.contactDetails,
                  country: quote.route.destination.country,
                },
              }
            : {}),
        },
      },
      [
        "__typename",
        "currentItem",
        "dataVersion",
        "isPhoneNumberEmpty",
        uuid === "NEW" ? "estimatedPickup" : "",
        // todo: remove more fields
      ].filter((item) => !!item)
    ),
  };
};
