import { HeaderAndFooterHeightTotal } from "constants/variables";
import React from "react";
import styled from "styled-components";
import { hasValidRefreshToken } from "utils/auth";
import TestContext from "utils/TestContext";
import { getRem } from "../utils/css";
import { clearOut } from "../utils/interceptor";

const Wrapper = styled.div`
  min-height: calc(100vh - ${getRem(HeaderAndFooterHeightTotal)});
`;

const Auth = (props) => {
  return (
    <TestContext.Consumer>
      {(inTesting) => {
        if (inTesting) return props.children;
        else {
          if (hasValidRefreshToken()) {
            return props.children;
          } else {
            if (!props.inline) {
              if (
                !window.location.pathname.includes("logout") &&
                !window.location.pathname.includes("login")
              ) {
                localStorage.setItem("returnUri", window.location.pathname);
              }
              setTimeout(() => {
                clearOut();
              }, 100);
              return <Wrapper />;
            } else {
              return <span />;
            }
          }
        }
      }}
    </TestContext.Consumer>
  );
};

export default Auth;
