import {
  FavoriteItemType,
  FavoriteUpdateFunctionType,
} from "constants/favorites";
import { InternalRefetchQueriesInclude } from "@apollo/client";
import React, { useEffect, useState } from "react";
import * as H from "./FavoriteButton.helpers";

export interface FavoriteButtonProps {
  id: string | undefined;
  itemType: FavoriteItemType;
  isFollowed?: boolean | null;
  className?: string;
  onAdditionComplete?: () => void;
  onRemovalComplete?: () => void;
  onUpdate?: FavoriteUpdateFunctionType;
  refetchQueries?: InternalRefetchQueriesInclude;
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  id,
  itemType,
  isFollowed,
  className,
  onAdditionComplete,
  onRemovalComplete,
  onUpdate,
  refetchQueries,
}) => {
  const [isFollowedState, setIsFollowedState] = useState(!!isFollowed);
  const {
    addToFavorite,
    removeFromFavorite,
    isDuringAddition,
    isDuringRemoval,
  } = H.useFavorite(
    id,
    itemType,
    onAdditionComplete,
    onRemovalComplete,
    setIsFollowedState,
    onUpdate,
    refetchQueries
  );

  useEffect(() => {
    setIsFollowedState(!!isFollowed);
  }, [isFollowed]);

  const onIconClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsFollowedState((prevState) => !prevState);
    if (!isDuringAddition && !isDuringRemoval && id) {
      if (!isFollowedState) {
        await addToFavorite();
      } else {
        await removeFromFavorite();
      }
    }
  };

  const Icon = H.getFavoriteIcon(isFollowedState);

  return <Icon className={className} onClick={onIconClick} />;
};

export default FavoriteButton;
