import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { Avatar } from "react-lib";
import { useSelector } from "react-redux";
import messages from "../messages";
import { BoldText, Email, SwitchInfoWrapper, UserWrapper } from "../styles";

const FlyOutUser = ({ email, initials, onClick }) => {
  const intl = useIntl();
  const isDemoMode = useSelector(
    (state) => state.userSettings.visibilityScope?.demo
  );

  return (
    <UserWrapper onClick={onClick}>
      <Avatar outlined nameInitials={initials} isDemo={!isDemoMode} />
      <SwitchInfoWrapper>
        <BoldText>{intl.formatMessage(messages.switchAccount)}</BoldText>
        <Email>{isDemoMode ? email : "DEMO"}</Email>
      </SwitchInfoWrapper>
    </UserWrapper>
  );
};

FlyOutUser.propTypes = {
  email: PropTypes.string,
  initials: PropTypes.string,
  onClick: PropTypes.func,
};

export default FlyOutUser;
