import { Color, NewButton } from "react-lib";
import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1.375rem;
  justify-content: end;
`;

export const StyledButton = styled(NewButton)`
  min-width: 7.5rem;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.p`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${Color.webBlack};
`;
