import gql from "graphql-tag";

export const getTermsOfUse = gql`
  query GetTermsOfUseQuery {
    getTermsOfUse {
      id
      publishedAt
      text
      translationKey: weblate_key
    }
  }
`;

export const acceptTermsOfUse = gql`
  query AcceptTermsOfUseQuery($id: Int!) {
    acceptTermsOfUse(id: $id) {
      accepted
    }
  }
`;
