export const columnToFilter = {
  Mode: "transportMode",
  Origin: "origin",
  Destination: "destination",
  Phase: "transitStatus",
  Status: "delayStatus",
  LastEvent: "lastEvent",
  Shipper: "shipper",
  Consignee: "consignee",
  Customer: "customerName",
  OriginCountry: "originCountryName",
  OriginPort: "originPort",
  DestinationCountry: "destinationCountryName",
  DestinationPort: "destinationPort",
  Follow: "isFavorite",
  Departure: "departureDate",
  Arrival: "arrivalDate",
  HouseBill: "housebill",
  BookingName: "bookingName",
  Carrier: "carrier",
  Reference: "references",
  CustomsEntryNumber: "customsEntryNumber",
  EstimatedPickup: "estimatedPickup",
  ActualPickup: "actualPickup",
  EstimatedDeparturePort: "estimatedDeparture",
  ActualDeparturePort: "actualDeparture",
  EstimatedArrivalPort: "estimatedArrival",
  ActualArrivalPort: "actualArrival",
  EstimatedCustomerDelivery: "estimatedCustomerDelivery",
  DocumentHandover: "documentHandover",
  JourneyDuration: undefined,
  DelayInShipmentArrival: undefined,
  MasterBill: "masterbill",
  FlightNumber: "flightNumber",
  VesselName: "vessel",
  ContainerNumber: "containerNumber",
  ContainerSize: "containerSize",
  GoodsDescription: "goodsDescription",
  Volume: undefined,
  TotalWeight: undefined,
  ChargeableWeight: undefined,
  Pieces: undefined,
  IncotermsDescription: "incoTermsName",
  IncotermsCode: "incoTerms",
  FeatureNames: "featureName",
  ProductName: "productName",
  Irregularities: "irregularities",
};
export const columnToSort = {
  Mode: {
    key: "TRANSPORT_MODE",
  },
  Departure: {
    key: "DEPARTURE",
  },
  Origin: {
    key: "ORIGIN",
  },
  Arrival: {
    key: "ARRIVAL",
  },
  Destination: {
    key: "DESTINATION",
  },
  HouseBill: {
    key: "HOUSEBILL",
  },
  BookingName: {
    key: "BOOKING_NAME",
  },
  Phase: {
    key: "TRANSIT_STATUS",
  },
  Status: {
    key: "DELAY_STATUS",
  },
  LastEvent: {
    key: "LAST_EVENT",
  },
  Carrier: {
    key: "CARRIER",
  },
  Shipper: {
    key: "SHIPPER",
  },
  Consignee: {
    key: "CONSIGNEE",
  },
  Customer: {
    key: "CUSTOMER_NAME",
  },
  Reference: {
    key: "REFERENCES",
  },
  OriginCountry: {
    key: "ORIGIN_COUNTRY_NAME",
  },
  CustomsEntryNumber: {
    key: "CUSTOMS_ENTRY_NUMBER",
  },
  OriginPort: {
    key: "ORIGIN_PORT",
  },
  DestinationCountry: {
    key: "DESTINATION_COUNTRY_NAME",
  },
  DestinationPort: {
    key: "DESTINATION_PORT",
  },
  JourneyDuration: {
    key: "JOURNEY_DURATION",
  },
  EstimatedPickup: {
    key: "ESTIMATED_PICKUP",
  },
  ActualPickup: {
    key: "ACTUAL_PICKUP",
  },
  EstimatedDeparturePort: {
    key: "ESTIMATED_DEPARTURE",
  },
  ActualDeparturePort: {
    key: "ACTUAL_DEPARTURE",
  },
  EstimatedArrivalPort: {
    key: "ESTIMATED_ARRIVAL",
  },
  ActualArrivalPort: {
    key: "ACTUAL_ARRIVAL",
  },
  EstimatedCustomerDelivery: {
    key: "ESTIMATED_CUSTOMER_DELIVERY",
  },
  DocumentHandover: {
    key: "DOCUMENT_HANDOVER",
  },
  MasterBill: {
    key: "MASTERBILL",
  },
  FlightNumber: {
    key: "FLIGHT_NUMBER",
  },
  VesselName: {
    key: "VESSEL",
  },
  ContainerNumber: {
    key: "CONTAINER_NUMBER",
  },
  ContainerSize: {
    key: "CONTAINER_SIZE",
  },
  GoodsDescription: {
    key: "GOODS_DESCRIPTION",
  },
  Volume: {
    key: "VOLUME",
  },
  TotalWeight: {
    key: "ACTUAL_WEIGHT",
  },
  ChargeableWeight: {
    key: "CHARGEABLE_WEIGHT",
  },
  Pieces: {
    key: "PIECES",
  },
  CHANGED: {
    key: "CHANGED",
  },
  IncotermsDescription: {
    key: "INCOTERMS_NAME",
  },
  IncotermsCode: {
    key: "INCOTERMS",
  },
  ShipmentDelay: { key: "DELAY_DAYS_FLOAT" },
  ProductName: { key: "PRODUCT_NAME" },
  LoadingMeters: { key: "LOADING_METERS" },
  CO2WtWFootprint: {
    key: "CO2E_TOTAL",
  },
  CO2WtWEfficiency: {
    key: "MAIN_LEG_EFFICIENCY",
  },
};

export const columnToExport = {
  Follow: { name: "FAVORITE", trackCode: 97 },
  Mode: { name: "TRANSPORT_MODE", trackCode: 104 },
  Departure: { name: "DEPARTURE", trackCode: 105 },
  Origin: { name: "ORIGIN", trackCode: 106 },
  Arrival: { name: "ARRIVAL", trackCode: 107 },
  Destination: { name: "DESTINATION", trackCode: 108 },
  HouseBill: { name: "HOUSEBILL", trackCode: 109 },
  Phase: { name: "TRANSIT_STATUS", trackCode: 110 },
  Status: { name: "DELAY_STATUS", trackCode: 111 },
  LastEvent: { name: "LAST_EVENT", trackCode: 112 },
  Carrier: { name: "CARRIER", trackCode: 112 },
  Shipper: { name: "SHIPPER", trackCode: 113 },
  Consignee: { name: "CONSIGNEE", trackCode: 114 },
  Customer: { name: "CUSTOMER_NAME", trackCode: 115 },
  Reference: { name: "REFERENCES", trackCode: 116 },
  OriginCountry: { name: "ORIGIN_COUNTRY", trackCode: 118 },
  OriginPort: { name: "ORIGIN_PORT", trackCode: 119 },
  DestinationCountry: { name: "DESTINATION_COUNTRY", trackCode: 120 },
  DestinationPort: { name: "DESTINATION_PORT", trackCode: 121 },
  JourneyDuration: { name: "JOURNEY_DURATION", trackCode: 131 },
  EstimatedPickup: { name: "ESTIMATED_PICKUP", trackCode: 123 },
  ActualPickup: { name: "ACTUAL_PICKUP", trackCode: 124 },
  EstimatedDeparturePort: { name: "ESTIMATED_DEPARTURE", trackCode: 125 },
  ActualDeparturePort: { name: "ACTUAL_DEPARTURE", trackCode: 126 },
  EstimatedArrivalPort: { name: "ESTIMATED_ARRIVAL", trackCode: 127 },
  ActualArrivalPort: { name: "ACTUAL_ARRIVAL", trackCode: 128 },
  EstimatedCustomerDelivery: {
    name: "ESTIMATED_CUSTOMER_DELIVERY",
    trackCode: 129,
  },
  DocumentHandover: { name: "DOCUMENT_HANDOVER", trackCode: 130 },
  DelayInShipmentArrival: { name: "DELAY_DAYS_FLOAT", trackCode: 132 },
  MasterBill: { name: "MASTERBILL", trackCode: 134 },
  FlightNumber: { name: "FLIGHT_NUMBER", trackCode: 135 },
  VesselName: { name: "VESSEL", trackCode: 136 },
  ContainerNumber: { name: "CONTAINER_NUMBER", trackCode: 137 },
  ContainerSize: { name: "CONTAINER_SIZE", trackCode: 138 },
  GoodsDescription: { name: "GOODS_DESCRIPTION", trackCode: 139 },
  Volume: { name: "VOLUME", trackCode: 140 },
  VolumeUnit: { name: "VOLUME_UNIT" },
  WeightUnit: { name: "WEIGHT_UNIT" },
  TotalWeight: { name: "ACTUAL_WEIGHT", trackCode: 141 },
  ChargeableWeight: { name: "CHARGEABLE_WEIGHT", trackCode: 142 },
  Pieces: { name: "PIECES", trackCode: 143 },
  CustomsEntryNumber: { name: "CUSTOMS_ENTRY_NUMBER", trackCode: 117 },
  IncotermsDescription: { name: "INCOTERMS_NAME", trackCode: 144 },
  IncotermsCode: { name: "INCOTERMS", trackCode: 145 },
  LoadingMeters: { name: "LOADING_METER" },
  ProductName: { name: "PRODUCT_NAME" },
  FeatureNames: { name: "FEATURE_NAMES" },
  ShipmentType: { name: "SHIPMENT_TYPE" },
  ShipperReference: { name: "SHIPPER_REFERENCE" },
  ConsigneeReference: { name: "CONSIGNEE_REFERENCE" },
  CO2WtWFootprint: { name: "CO2E_TOTAL" },
  CO2WtWEfficiency: { name: "MAIN_LEG_EFFICIENCY" },
  Irregularities: { name: "IRREGULARITIES" },
};

export const shipmentColumns = [
  "Follow",
  "Mode",
  "Departure",
  "Origin",
  "Arrival",
  "Destination",
  "HouseBill",
  "Phase",
  "Status",
  "LastEvent",
  "Carrier",
  "Shipper",
  "Consignee",
  "Customer",
  "Reference",
  "CustomsEntryNumber",
  "OriginCountry",
  "OriginPort",
  "DestinationCountry",
  "DestinationPort",
  "EstimatedPickup",
  "ActualPickup",
  "EstimatedDeparturePort",
  "ActualDeparturePort",
  "EstimatedArrivalPort",
  "ActualArrivalPort",
  "EstimatedCustomerDelivery",
  "DocumentHandover",
  "JourneyDuration",
  "DelayInShipmentArrival",
  "MasterBill",
  "FlightNumber",
  "VesselName",
  "ContainerNumber",
  "ContainerSize",
  "GoodsDescription",
  "Volume",
  "TotalWeight",
  "ChargeableWeight",
  "Pieces",
  "IncotermsDescription",
  "IncotermsCode",
  "LoadingMeters",
  "FeatureNames",
  "ProductName",
  "CO2WtWFootprint",
  "CO2WtWEfficiency",
  "Irregularities",
];

export const exportOnlyColumns = [
  "VolumeUnit",
  "WeightUnit",
  "ShipmentType",
  "ShipperReference",
  "ConsigneeReference",
];

export const dateTableColumnSize = 6;
