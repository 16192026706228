import { UserRoles } from "constants/userRoles";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserRoles } from "redux/auth/selectors";
import { updateReadOnly } from "redux/userSettings/actions";

const ReadOnlyWrapper = ({ children }: { children: FC }) => {
  const dispatch = useDispatch();
  const currentUserRoles = useSelector(getCurrentUserRoles);
  useEffect(() => {
    if (currentUserRoles) {
      if (currentUserRoles.includes(UserRoles.READ_ONLY)) {
        dispatch(updateReadOnly(true));
      } else {
        dispatch(updateReadOnly(false));
      }
    }
  }, [dispatch, currentUserRoles]);

  return <>{children}</>;
};

export default ReadOnlyWrapper;
