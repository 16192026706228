import { BookingSummary, roundUpDecimals } from "constants/bookWorkflow";
import { FavoriteItemType } from "constants/favorites";
import * as MDM from "constants/masterDataManagement";
import FreightIcon from "components/atoms/Icons/FreightIcon";
import SVGWrapper from "components/atoms/SVGWrapper";
import FavoriteButton from "components/FavoriteButton/FavoriteButton";
import ServiceLevelLabel from "components/molecules/ServiceLevelLabel/ServiceLevelLabel";
import { freightTypes } from "data/shipmentDetailTypes";
import { transformCountryCode } from "helpers/transformCountryCode";
import moment from "moment";
import React from "react";
import { IntlShape } from "react-intl";
import bookingsMessages from "routes/Bookings/Bookings.messages";
import { getFormattedDate } from "../../ShipmentRow/helper/getCellContentFunctions";
import { BookingsListColumn } from "../BookingsListHeader/BookingsListHeader.constants";
import { translate } from "../BookingsListHeader/BookingsListHeader.helpers";
import { statusUserMessages } from "../BookingsListHeader/BookingsListHeader.messages";
import * as BookingsListHeaderStyles from "../BookingsListHeader/BookingsListHeader.styles";
import { followedBookingKey } from "../FollowedBooking/FollowedBooking.constants";
import * as C from "./BookingsListRow.constants";
import messages, { ratesTypeMessages } from "./BookingsListRow.messages";
import * as S from "./BookingsListRow.styles";

const isValidAt = (
  date: moment.Moment | null,
  item: { validFrom: string; validTo: string | null }
): boolean => {
  if (!date) {
    return true;
  }

  if (date.isBefore(moment.utc(item.validFrom), "day")) {
    return false;
  }

  if (item.validTo && date.isAfter(moment.utc(item.validTo), "day")) {
    return false;
  }

  return true;
};

const getCountryNameAtCreationTime = (
  item: BookingSummary,
  contentName: keyof BookingSummary,
  countryList: MDM.Country[]
) => {
  const sentAt = item.dateSent && moment.utc(item.dateSent);
  const countryCode = item[contentName];
  const country = countryList.find(
    (country) => country.code === countryCode && isValidAt(sentAt, country)
  );
  return country ? country.label : countryCode;
};

const getWeightOrVolumeUom = (intl: IntlShape, apiUom?: string) => {
  switch (apiUom) {
    case C.WeightAndVolumeApiUoms.KILOGRAMS:
    case C.WeightAndVolumeApiUoms.POUNDS:
    case C.WeightAndVolumeApiUoms.CUBIC_METERS:
    case C.WeightAndVolumeApiUoms.CUBIC_FEET:
      return intl.formatMessage(messages[apiUom]);
    default:
      return "";
  }
};

const formatCity = (city: string, countryCode: string) =>
  [city, transformCountryCode(countryCode)].filter(Boolean).join(", ");

export const getCellContent = ({
  item,
  column,
  userSettings: { searchKeywords, searchPhrases },
  intl,
  countriesList,
  refetchBookings,
}: C.GetCellContentProps) => {
  const getFormattedDateWithIntl = getFormattedDate(intl);

  let searchWords: string[] = [];

  if (searchKeywords) {
    searchWords = [...searchWords, ...searchKeywords];
  }
  if (searchPhrases) {
    searchWords = [...searchWords, ...searchPhrases];
  }

  switch (column.dataName) {
    case "product":
      return (
        <SVGWrapper>
          <FreightIcon
            type={freightTypes[item.product]}
            className={undefined}
          />
        </SVGWrapper>
      );
    case "followed":
      return (
        <FavoriteButton
          id={item.id}
          isFollowed={item.followed}
          itemType={FavoriteItemType.BOOKING}
          onAdditionComplete={refetchBookings}
          onRemovalComplete={refetchBookings}
        />
      );
    case "serviceLevel": {
      return (
        <ServiceLevelLabel booking={item}>
          {(content) => (
            <BookingsListHeaderStyles.StyledTip
              content={content}
              fromDropdown={null}
              fromBucket={null}
              TipOnSide={null}
              multiline={null}
            >
              <BookingsListHeaderStyles.StyledColText size={column.customSize}>
                <S.StyledHighlighter
                  searchWords={searchWords}
                  textToHighlight={content}
                />
              </BookingsListHeaderStyles.StyledColText>
            </BookingsListHeaderStyles.StyledTip>
          )}
        </ServiceLevelLabel>
      );
    }
    case "housebill": {
      return (
        <BookingsListHeaderStyles.StyledTip
          content={item.housebill}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={item?.housebill || ""}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "bookingName": {
      return (
        <BookingsListHeaderStyles.StyledTip
          content={item?.bookingName}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={item?.bookingName || ""}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "quoteId": {
      return (
        <BookingsListHeaderStyles.StyledTip
          content={item.quoteId}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={item?.quoteId || ""}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "statusUser": {
      const content = translate({
        intl,
        messages: statusUserMessages,
        key: item.statusUser,
      });

      return (
        <BookingsListHeaderStyles.StyledTip
          content={item.statusUser}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={content}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "originCountry":
    case "destinationCountry":
    case "billingCountry": {
      const content = getCountryNameAtCreationTime(
        item,
        column.dataName,
        countriesList
      );

      return (
        <BookingsListHeaderStyles.StyledTip
          content={content}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={content}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "originCity": {
      const content = formatCity(item.originCity, item.originCountry);
      return (
        <BookingsListHeaderStyles.StyledTip
          content={content}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={content}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "destinationCity": {
      const content = formatCity(item.destinationCity, item.destinationCountry);
      return (
        <BookingsListHeaderStyles.StyledTip
          content={content}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={content}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "shipperCompany":
    case "consigneeCompany":
    case "billingCompany": {
      return (
        <BookingsListHeaderStyles.StyledTip
          content={item[column.dataName]}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={item[column.dataName] || ""}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "dateCreated": {
      const content = getFormattedDateWithIntl(item.dateCreated);

      return (
        <BookingsListHeaderStyles.StyledColText size={column.customSize}>
          <S.StyledHighlighter
            searchWords={searchWords}
            textToHighlight={content}
          />
        </BookingsListHeaderStyles.StyledColText>
      );
    }
    case "dateUpdated": {
      const content = getFormattedDateWithIntl(item.dateUpdated);

      return (
        <BookingsListHeaderStyles.StyledColText size={column.customSize}>
          <S.StyledHighlighter
            searchWords={searchWords}
            textToHighlight={content}
          />
        </BookingsListHeaderStyles.StyledColText>
      );
    }
    case "userEmail": {
      return (
        <BookingsListHeaderStyles.StyledTip
          content={item.userEmail}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={item?.userEmail || ""}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "reference": {
      const references =
        item && item.references && Array.isArray(item.references)
          ? item.references.join(", ")
          : item.references;

      return (
        <BookingsListHeaderStyles.StyledTip
          content={references}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={references || ""}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "totalWeight": {
      return (
        <BookingsListHeaderStyles.StyledTip
          content={item.totalWeight}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            {item?.totalWeight
              ? `${item.totalWeight} ${getWeightOrVolumeUom(
                  intl,
                  item.weightUom
                )}`
              : ""}
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "totalVolume": {
      return (
        <BookingsListHeaderStyles.StyledTip
          content={item?.totalVolume?.toFixed(roundUpDecimals.volume)}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            {item?.totalVolume
              ? `${item.totalVolume?.toFixed(
                  roundUpDecimals.volume
                )} ${getWeightOrVolumeUom(intl, item.volumeUom)}`
              : ""}
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "ratesType": {
      const data = item[column.dataName];
      const message = data && ratesTypeMessages[data];
      const translated = message ? intl.formatMessage(message) : data;
      return (
        <BookingsListHeaderStyles.StyledTip
          content={translated}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={translated ?? ""}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    case "dateSent": {
      const content = getFormattedDateWithIntl(item.dateSent);

      return (
        <BookingsListHeaderStyles.StyledColText size={column.customSize}>
          <S.StyledHighlighter
            searchWords={searchWords}
            textToHighlight={content}
          />
        </BookingsListHeaderStyles.StyledColText>
      );
    }
    case "estimatedPickupDate": {
      const content = getFormattedDateWithIntl(item.dateEstimatedPickup);

      return (
        <BookingsListHeaderStyles.StyledColText size={column.customSize}>
          <S.StyledHighlighter
            searchWords={searchWords}
            textToHighlight={content}
          />
        </BookingsListHeaderStyles.StyledColText>
      );
    }
    case "dateSubmitted": {
      const content = getFormattedDateWithIntl(item.dateSubmitted);

      return (
        <BookingsListHeaderStyles.StyledColText size={column.customSize}>
          <S.StyledHighlighter
            searchWords={searchWords}
            textToHighlight={content}
          />
        </BookingsListHeaderStyles.StyledColText>
      );
    }
    case "quoteTotal":
    case "serviceTotal":
    case "exportCustomsClearanceValue":
    case "importCustomsClearanceValue":
    case "sustainabilityOfferingValue":
    case "insuranceValue": {
      const amount = String(item[column.dataName] || "");
      const content = amount ? `${amount} ${item.currency}` : "";

      return (
        <BookingsListHeaderStyles.StyledTip
          content={content}
          fromDropdown={null}
          fromBucket={null}
          TipOnSide={null}
          multiline={null}
        >
          <BookingsListHeaderStyles.StyledColText size={column.customSize}>
            <S.StyledHighlighter
              searchWords={searchWords}
              textToHighlight={content}
            />
          </BookingsListHeaderStyles.StyledColText>
        </BookingsListHeaderStyles.StyledTip>
      );
    }
    default:
      return "...";
  }
};

export const getBookingsListColumnsTranslationKeys = (
  bookingListColumns: BookingsListColumn[]
) => bookingListColumns.map((column) => column.translationKey);

export const getBookingsListColumnsUniqueValueColumnNames = (
  bookingsListColumns: BookingsListColumn[]
) => {
  const columnsExcludedFromBookingsSearching = [
    "TOTAL_WEIGHT",
    "TOTAL_VOLUME",
    "REFERENCE",
    "BOOKING_NAME",
  ];

  return bookingsListColumns
    ?.filter((column) => !column.isFilteringDisabled)
    .map((column) => {
      if (
        column &&
        (column.hasDateType ||
          columnsExcludedFromBookingsSearching.some(
            (uniqueValueColumnName) =>
              uniqueValueColumnName === column.uniqueValueColumnName
          ))
      ) {
        return "";
      }
      return column.uniqueValueColumnName;
    })
    .filter((value) => value && value !== followedBookingKey);
};

export const getBookingsListColumnsForSortableList = (
  bookingListColumns: BookingsListColumn[]
) =>
  bookingListColumns.reduce(
    (result, column) => ({ ...result, [column.translationKey]: column }),
    {}
  );

export const generateDescription = (
  details: Pick<
    BookingSummary,
    "housebill" | "totalWeight" | "weightUom" | "totalVolume" | "volumeUom"
  >,
  intl: IntlShape
) => {
  if (!details.housebill && !details.totalWeight && !details.totalVolume)
    return intl.formatMessage(bookingsMessages.undefinedInformation);

  const weight =
    details.totalWeight !== null
      ? `${details.totalWeight} ${getWeightOrVolumeUom(
          intl,
          details.weightUom
        )}`
      : null;
  const volume =
    details.totalVolume !== null
      ? `${details.totalVolume} ${getWeightOrVolumeUom(
          intl,
          details.volumeUom
        )}`
      : null;

  return [details.housebill, weight, volume]
    .filter((i) => i !== null)
    .join(", ");
};
