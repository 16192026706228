import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clear: {
    id: "datePicker.clear",
    defaultMessage: "Clear",
  },
  apply: {
    id: "datePicker.apply",
    defaultMessage: "Apply",
  },
  today: {
    id: "datePicker.today",
    defaultMessage: "Today",
  },
  yesterday: {
    id: "datePicker.yesterday",
    defaultMessage: "Yesterday",
  },
  tomorrow: {
    id: "datePicker.tomorrow",
    defaultMessage: "Tomorrow",
  },
  nextWeek: {
    id: "datePicker.nextWeek",
    defaultMessage: "Next Week",
  },
  lastWeek: {
    id: "datePicker.lastWeek",
    defaultMessage: "Last Week",
  },
});
