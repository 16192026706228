import { HeaderAndFooterHeightTotal } from "constants/variables";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Text } from "react-lib";
import Button from "react-lib/es/atoms/buttons2/Button";
import { ButtonVariants } from "react-lib/es/atoms/buttons2/Button.constants";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getToken } from "redux/auth/actions";
import styled from "styled-components";
import {
  getKeycloakLoginUrl,
  getKeycloakLogoutUrl,
  hasValidAuthToken,
} from "utils/auth";
import { getRem } from "utils/css";
import get from "utils/get";
import { clearOut } from "utils/interceptor";
import { getQueryParams, includesAnyOf } from "utils/string";
import Loading from "../components/molecules/Loader/Loading";
import { messages } from "./messages";

const Wrapper = styled.div`
  padding: ${getRem(60)} ${getRem(30)};
  min-height: calc(100vh - ${getRem(HeaderAndFooterHeightTotal)});
`;

const Login = (props) => {
  const history = useHistory();
  const queryParams = getQueryParams(window.location.search);
  const code = queryParams.code;
  const failed = queryParams.failed;
  const intl = useIntl();

  useEffect(() => {
    if (code && !get(props, "auth.loginInProgress")) {
      props.getToken(code);
    }
    // eslint-disable-next-line
  }, [code]);

  if (
    hasValidAuthToken() &&
    !get(props, "auth.loginInProgress") &&
    !get(props, "auth.tokenRefreshInProgress")
  ) {
    const returnUri = localStorage.getItem("returnUri");
    localStorage.removeItem("returnUri");
    if (includesAnyOf(returnUri, ["login", "logout"])) {
      history.replace("/");
    } else {
      history.replace(returnUri || "/");
    }
    return <Wrapper>{intl.formatMessage(messages.redirecting)}</Wrapper>;
  } else if (!code && !failed) {
    clearOut(true);
    return null;
  }
  return (
    <Wrapper>
      {code && (
        <Wrapper>
          <Loading />
        </Wrapper>
      )}
      {failed && (
        <Wrapper>
          <Text center fontSize={32}>
            Failed to get {failed}.{" "}
          </Text>
          <Text center className="mt-6">
            <Button
              variant={ButtonVariants.SECONDARY}
              onClick={() => {
                window.location.href = getKeycloakLoginUrl();
              }}
            >
              Retry token
            </Button>
            <Button
              variant={ButtonVariants.SECONDARY}
              onClick={() => {
                window.location.href = getKeycloakLogoutUrl(
                  localStorage.getItem("id_token")
                );
              }}
            >
              Login again
            </Button>
          </Text>
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default connect((state) => ({ auth: state.auth }), { getToken })(Login);
