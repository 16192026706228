export const errorCodesNumber = [
  500, 501, 502, 503, 504, 505, 400, 403, 404, 405, 406, 408, 409, 410, 413,
  422, 308,
];
export const errorCodesString = [
  "500",
  "501",
  "502",
  "503",
  "504",
  "505",
  "400",
  "403",
  "404",
  "405",
  "406",
  "408",
  "409",
  "410",
  "413",
  "422",
  "308",
  "INTERNAL_SERVER_ERROR",
  "BAD_USER_INPUT",
];
