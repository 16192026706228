import types from "./action-types";

export const setCountry = (country, selectedBy) => ({
  type: types.SET_COUNTRY,
  payload: {
    country,
    selectedBy,
  },
});

export const setCurrency = (currency, selectedBy, fromPath, locale) => ({
  type: types.SET_CURRENCY,
  payload: {
    currency,
    selectedBy,
    fromPath,
    locale,
  },
});

export const setQuoteType = (quoteType) => ({
  type: types.SET_QUOTE_TYPE,
  payload: {
    quoteType,
  },
});

export const savePackageTypes = (packageTypes) => ({
  type: types.SAVE_PACKAGE_TYPES,
  payload: {
    packageTypes,
  },
});

export const setRatesType = (ratesType) => ({
  type: types.SET_RATES_TYPE,
  payload: {
    ratesType,
  },
});
