export interface QuoteDataState {
  offerOptions: Array<{
    routes: object;
    selectedQuoteOption: { calculationDetailsKey: string };
  }>;
  selectedOffer: {
    calculationDetailsKey?: string;
    serviceType?: string;
    price?: {
      currency?: string;
      value?: string;
    };
    serviceSettings?: {
      shippingTerms?: string;
    };
    cargoDetails?: {
      cargoType?: string;
    };
  };
  quote:
    | {
        billingCountry?: string;
        route?: {
          origin?: {
            country?: { value: string };
          };
          destination?: {
            country?: { value: string };
          };
        };
      }
    | object;
  updatingConsignment: boolean;
  updateConsignmentSucceeded: boolean;
  updateConsignmentCompleted: boolean;
  submittingConsignment: boolean;
  submitConsignmentSucceeded: boolean;
  submitConsignmentCompleted: boolean;
  submitConsignmentFailed: boolean;
  successData: object;
  pickupDate: string | undefined;
}

export enum ActionsType {
  SAVE_OFFER_OPTIONS = `QUOTE_DATA/SAVE_OFFER_OPTIONS`,
  SAVE_OFFER = `QUOTE_DATA/SAVE_OFFER`,
  RECALCULATE_QUOTE = `QUOTE_DATA/RECALCULATE_QUOTE`,
  CALCULATE_CONSIGNMENT = `QUOTE_DATA/CALCULATE_CONSIGNMENT`,
  SET_SELECTED_OFFER = `QUOTE_DATA/SET_SELECTED_OFFER`,
  SAVE_QUOTE_FORM = `QUOTE_DATA/SAVE_QUOTE_FORM`,
  SAVE_CONSIGNMENT_API = `QUOTE_DATA/SAVE_CONSIGNMENT_API`,
  SAVE_CONSIGNMENT_API_COMPLETED = `QUOTE_DATA/SAVE_CONSIGNMENT_API_COMPLETED`,
  SAVE_CONSIGNMENT_API_SUCCEEDED = `QUOTE_DATA/SAVE_CONSIGNMENT_API_SUCCEEDED`,
  SUBMIT_CONSIGNMENT = `QUOTE_DATA/SUBMIT_CONSIGNMENT`,
  SUBMIT_CONSIGNMENT_RESET = `QUOTE_DATA/SUBMIT_CONSIGNMENT_RESET`,
  SUBMIT_CONSIGNMENT_COMPLETED = `QUOTE_DATA/SUBMIT_CONSIGNMENT_COMPLETED`,
  SUBMIT_CONSIGNMENT_SUCCEEDED = `QUOTE_DATA/SUBMIT_CONSIGNMENT_SUCCEEDED`,
  SUBMIT_CONSIGNMENT_FAILED = `QUOTE_DATA/SUBMIT_CONSIGNMENT_FAILED`,
  RESET_DATA = `QUOTE_DATA/RESET_DATA`,
  SET_PICKUP_DATE = `QUOTE_DATA/SET_PICKUP_DATE`,
  UPDATE_OFFERS = `QUOTE_DATA/UPDATE_OFFERS`,
  RESET_PICKUP_DATE = `QUOTE_DATA/RESET_PICKUP_DATE`,
}

export type QuoteDataActions =
  | {
      type: ActionsType.SAVE_OFFER_OPTIONS;
      payload: any[];
    }
  | {
      type: ActionsType.SAVE_OFFER;
      payload: {
        calculationDetailsKey: string;
      };
    }
  | {
      type: ActionsType.RECALCULATE_QUOTE;
      payload: object;
    }
  | {
      type: ActionsType.CALCULATE_CONSIGNMENT;
      payload: object;
    }
  | {
      type: ActionsType.SET_PICKUP_DATE;
      payload: {
        pickupDate: string;
      };
    }
  | {
      type: ActionsType.SET_SELECTED_OFFER;
      payload: object;
    }
  | {
      type: ActionsType.SAVE_CONSIGNMENT_API;
      payload: {
        value?: boolean;
        code: string;
      };
    }
  | {
      type: ActionsType.SAVE_QUOTE_FORM;
      payload: {
        contractId?: string;
        fromPath?: string;
        locale?: string;
      };
      meta: {
        debounce: {
          time: number;
        };
      };
    }
  | {
      type: ActionsType.SAVE_CONSIGNMENT_API_COMPLETED;
    }
  | {
      type: ActionsType.SUBMIT_CONSIGNMENT;
      payload: object;
    }
  | {
      type: ActionsType.SUBMIT_CONSIGNMENT_COMPLETED;
    }
  | {
      type: ActionsType.SUBMIT_CONSIGNMENT_FAILED;
    }
  | {
      type: ActionsType.SAVE_CONSIGNMENT_API_SUCCEEDED;
      payload: boolean;
    }
  | {
      type: ActionsType.SUBMIT_CONSIGNMENT_SUCCEEDED;
      payload: object;
    }
  | {
      type: ActionsType.RESET_PICKUP_DATE;
    }
  | {
      type: ActionsType.RESET_DATA;
    }
  | {
      type: ActionsType.SUBMIT_CONSIGNMENT_RESET;
      payload: boolean;
    }
  | {
      type: ActionsType.UPDATE_OFFERS;
      payload: {
        fromPath: string;
        locale: string;
      };
    };
