import types from "./action-types";

export const getToken = (code, returnUrl = null) => ({
  type: types.GET_TOKEN,
  payload: { code: code, returnUrl: returnUrl },
});

export const getTokenSuccess = (payload) => ({
  type: types.GET_TOKEN_SUCCESS,
  payload: payload,
});

export const getTokenError = (payload) => ({
  type: types.GET_TOKEN_ERROR,
  payload: payload,
});

export const logOut = (payload, shouldSkipRedirect = false) => ({
  type: types.LOG_OUT,
  payload: payload,
  shouldSkipRedirect: shouldSkipRedirect,
});

export const getCurrentUserRoles = (payload) => ({
  type: types.GET_CURRENT_USER_ROLES,
  payload: payload,
});

export const setCurrentUserRoles = (payload) => ({
  type: types.SET_CURRENT_USER_ROLES,
  payload: payload,
});

export const setLoggedInUserRoles = (payload) => ({
  type: types.SET_LOGGED_IN_USER_ROLES,
  payload: payload,
});

export const setLoggedInUserEmail = (payload) => ({
  type: types.SET_LOGGED_IN_USER_EMAIL,
  payload,
});

export const setUserId = (payload) => ({
  type: types.SET_USER_ID,
  payload,
});

export const setDemoUserRoles = (payload) => ({
  type: types.SET_DEMO_USER_ROLES,
  payload: payload,
});

export const setUserLoggedIn = (payload) => ({
  type: types.SET_USER_LOGGED_IN,
  payload: payload,
});

export const setUserConnectionCountry = (payload) => ({
  type: types.SET_USER_CONNECTION_COUNTRY,
  payload,
});
