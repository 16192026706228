import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sorry: {
    id: "analytics.sorry",
    defaultMessage: "Sorry!",
  },
  backToDashboard: {
    id: "analytics.backToDashboard",
    defaultMessage: "Back to Dashboard",
  },
  serverError: {
    id: "analytics.serverError",
    defaultMessage:
      "Something went wrong and we couldn't load your analytics items",
  },
  contactUs: {
    id: "analytics.contactUs",
    defaultMessage: "Contact us if the problem persists.",
  },
  noAnalytics: {
    id: "analytics.noAnalytics",
    defaultMessage:
      "There is no available dashboard for currently selected Corporate Partner.",
  },
  tabTitle_undefined: {
    id: "analytics.tab.title.noTitle",
    defaultMessage: "No Title",
  },
  pageHeader: {
    id: "analytics.page.header",
    defaultMessage: "Analytics",
  },
});
