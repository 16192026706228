import styled from "styled-components";
import { getRem } from "../../utils/css";

const TextBlockCondensed = styled.p`
  font-size: ${getRem(14)};
  line-height: 1.43;
  margin-bottom: ${getRem(20)};
`;

export default TextBlockCondensed;
