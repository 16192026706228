import getConfigClient from "constants/getConfigClient";
import find from "lodash/find";
import some from "lodash/some";
import { allLanguages, getLanguage } from "utils/languages";
import { languageStorageKey, defaultLanguage } from "./Translations.constants";

const uniformLanguage = (language) => language?.toUpperCase().replace("-", "_");

export const getNavigatorLanguage = (languages) => {
  const fullNames = [...(navigator.languages || []), navigator.language];
  const firstParts = fullNames.map((lang) => lang?.split(/[_-]+/)[0]);
  return [...fullNames, ...firstParts]
    .filter(Boolean)
    .map(uniformLanguage)
    .find((ucCode) => some(languages, { ucCode }));
};

export const getCodeSecure = (languages, code) =>
  find(languages, { ucCode: uniformLanguage(code) })?.code;

export const isTestMode = getConfigClient("LANGUAGE_TEST_MODE");

export const uniformLanguageList = (languages) =>
  isTestMode
    ? allLanguages
    : languages
        ?.map((lang) => {
          // Set the code to the filename (excluding extension)
          const langFile = getLanguage(lang.code);
          return langFile && { ...lang, ...langFile };
        })
        .filter(Boolean); // only use those with matching translation file

export const getActualLanguage = () =>
  localStorage.getItem(languageStorageKey) || defaultLanguage;

export const getLanguageForIntl = (activeLanguage) =>
  activeLanguage?.replace("_", "-") || defaultLanguage;
