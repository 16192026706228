import { defineMessages } from "react-intl";

export default defineMessages({
  addressHeadline: {
    id: "bookingStandalone.streetAddress.address.headline",
    defaultMessage: "Address",
  },

  contactDetailsHeadline: {
    id: "bookingStandalone.streetAddress.contactDetails.headline",
    defaultMessage: "Contact Details",
  },

  selectCountryFirstHint: {
    id: "bookingStandalone.streetAddress.selectCountry.hint",
    defaultMessage:
      "Please fill out Country/Region first to update the other fields",
  },

  partySearchPlaceholder: {
    id: "bookingStandalone.streetAddress.searchDisabled.placeholder",
    defaultMessage: "Start typing street address…",
  },

  countryLabel: {
    id: "bookingStandalone.streetAddress.country",
    defaultMessage: "Country/Region",
  },

  countryPlaceholder: {
    id: "bookingStandalone.streetAddress.country.placeholder",
    defaultMessage: "Select",
  },

  streetLabel: {
    id: "bookingStandalone.streetAddress.street",
    defaultMessage: "Street",
  },

  firstLineOfAddressLabel: {
    id: "bookingStandalone.streetAddress.firstLineOfAddress",
    defaultMessage: "First line of address",
  },

  zipLabel: {
    id: "bookingStandalone.streetAddress.zip",
    defaultMessage: "ZIP",
  },

  cityLabel: {
    id: "bookingStandalone.streetAddress.city",
    defaultMessage: "City",
  },

  statesLabel: {
    id: "bookingStandalone.streetAddress.states",
    defaultMessage: "States/ Provinces/ Counties (optional)",
  },

  companyNameLabel: {
    id: "bookingStandalone.streetAddress.companyName",
    defaultMessage: "Company name",
  },

  nameLabel: {
    id: "bookingStandalone.streetAddress.name",
    defaultMessage: "Name",
  },

  emailLabel: {
    id: "bookingStandalone.streetAddress.email",
    defaultMessage: "Email",
  },

  phoneNumber: {
    id: "bookingStandalone.streetAddress.phone",
    defaultMessage: "Phone Number",
  },
});
