import SVGWrapper from "components/atoms/SVGWrapper";
import { Color } from "react-lib";
import styled from "styled-components";

export const ModalHeaderYellow = styled.div`
  background-color: ${Color.postYellow};
  width: 100%;
  height: 4rem;
  padding: 1rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.2;
  color: ${Color.webBlack};
  display: flex;
`;

export const StyledSVGWrapper = styled(SVGWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 0.25rem;
`;

export const StyledHeaderText = styled.div`
  margin: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
`;
