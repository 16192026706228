import useGeolocationHelper from "./hooks/useGeolocationHelper";
import useQueryStringResolver from "./hooks/useQueryStringResolver";

const InitializeQnB = ({ pathname }) => {
  useQueryStringResolver();
  useGeolocationHelper(pathname);
  return null;
};

export default InitializeQnB;
