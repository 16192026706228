import { ReactComponent as StarFilledSvg } from "assets/icons/myDHLi/star-filled.svg";
import { ReactComponent as StarOutlineSvg } from "assets/icons/myDHLi/star.svg";
import { Color } from "react-lib";
import styled, { css } from "styled-components";

const sharedStyles = css<{ isClickable?: boolean }>`
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;

  ${({ isClickable = true }) =>
    isClickable &&
    css`
      &:hover {
        fill: ${Color.postYellow};
      }
    `}
`;

export const StarFilled = styled(StarFilledSvg)<{ isClickable?: boolean }>`
  fill: ${Color.postYellow};
  ${sharedStyles};
`;

export const StarOutlined = styled(StarOutlineSvg)<{ isClickable?: boolean }>`
  fill: ${Color.black};
  ${sharedStyles};
`;
