import gql from "graphql-tag";

export const CALCULATE_CONSIGNMENT = gql`
  query CalculateConsignment(
    $corporatePartner: String
    $inputQuote: InputQuote
  ) {
    calculateConsignment(
      inputQuote: $inputQuote
      corporatePartner: $corporatePartner
    ) {
      calculationDetailsKey
      selectedQuoteOption {
        availablePickupDates
        calculationDetailsKey
        quoteType
        ratesType
        ratesSource
        product
        serviceType
        freeTimeDetails {
          origin {
            demurrage
            detention
            combined
            unit
          }
          destination {
            demurrage
            detention
            combined
            unit
          }
        }
        transitTimeDetails {
          pickup
          mainCarriage
          delivery
        }
        paymentMethods
        pickupOptions
        deliveryOptions
        validity
        commodityCode
        routeDetails {
          id
          originStation {
            code
            name
            gateway {
              code
              name
            }
            countryCode
            zone
            locality
          }
          destinationStation {
            code
            name
            gateway {
              code
              name
            }
            countryCode
            zone
            locality
          }
        }
        totalTransitTime
        currency
        includedCharges
        emissions {
          pickup {
            tankToWheel
            wellToWheel
          }
          mainCarriage {
            tankToWheel
            wellToWheel
          }
          delivery {
            tankToWheel
            wellToWheel
          }
        }
        charges {
          pickup
          origin
          mainCarriage
          destination
          delivery
          importCustomsClearance
          exportCustomsClearance
          cargoInsurance
          climateNeutral
          total
        }
        promoValue
        serviceSettings {
          pickup
          pickupOption
          delivery
          deliveryOption
          importCustomsClearance
          exportCustomsClearance
          customsClearanceLines
          insurance
          climateNeutral
          insuranceNotice
          goodsValue
          goodsValueMax
          goodsValueCurrency
          shippingTerms
          incoterms
          freeLinesNumber
        }
        reasons {
          code
          type
          category
          message
        }
        excludedRates {
          code
          description
          chargeGroupCurrency
          unit
          unitSecond
          unitMultiple
          excluded
          zone
          min
          max
          basePrice
          unitPrice
          partOf
          pricePer
          percentage
          type
          generics {
            tag
            value
          }
          currency
          originZone
          destinationZone
          perContainerPerDay
          reference
          weightBreak
          weightBreakUnit
          containerType
          numberOfFreeDays
          demurrageAndDetentionCombined
          perWeightMeasurePerDay
        }
        availableShippingTerms
        expirationDate
      }
      allOffers
      originalRequest
    }
  }
`;
