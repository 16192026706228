export const SORT_DIRECTIONS = {
  UP: "UP",
  DOWN: "DOWN",
};

export const sortTypes = {
  CAPITAL_ASC: "ASC",
  CAPITAL_DESC: "DESC",
  LOWER_CASE_ASC: "asc",
  LOWER_CASE_DESC: "desc",
};
