import styled from "styled-components";
import { getRem } from "../../utils/css";

const H3 = styled.h3`
  font-size: ${getRem(27)};
  font-weight: 900;
  font-stretch: condensed;
  line-height: 1.19;
  margin-bottom: ${getRem(20)};
`;

export default H3;
