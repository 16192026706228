import { colors } from "react-lib";
import styled, { css, keyframes } from "styled-components";

const FADE_OUT = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const FADE_IN = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const TipWrapperShown = css`
  opacity: 1;
  visibility: visible;
  animation: ${FADE_IN} 0.15s cubic-bezier(0, 0, 0.2, 1);
`;

const TipWrapperHidden = css`
  visibility: hidden;
  opacity: 0;
  animation: ${FADE_OUT} 0.075s cubic-bezier(0, 0, 0.2, 1);
`;

export const TipWrapper = styled.span`
  position: fixed;
  top: ${(p) => p.pos.y}px;
  left: ${(p) => p.pos.x}px;
  background-color: ${colors.gray80};
  color: ${colors.white};
  font-size: 14px;
  padding: 0.25rem 0.5rem;
  ${(p) => (p.show ? TipWrapperShown : TipWrapperHidden)};
  transition: visibility 0.5s, opacity 0.5s;
  line-height: 1.25rem;
  white-space: ${(p) => (p.multiline ? "wrap" : "nowrap")};
  max-width: 450px;
  z-index: 2;
  margin: 0 20px;
`;

export const Wrapper = styled.span`
  line-height: ${(p) => (p.fromDropdown ? 1.6 : 0)};
`;
