import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const Wrapper = styled(motion.div)`
  ${(p) =>
    p.isMobile
      ? css`
          width: calc(100vw - 32px);
        `
      : css`
          width: 566px;
        `};
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.07), 0 0 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #323232;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10001;
  display: flex;
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.white90};
  display: flex;
  align-items: center;
  padding-right: 20px;
`;
