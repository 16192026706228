import styled from "styled-components";
import { getRem } from "../../../../utils/css";

const SideBarUserItemsWrapper = styled.div`
  width: inherit;
  bottom: 0;
  padding-bottom: ${getRem(10)};
  background: #ffffff;
  z-index: 3;
  a {
    cursor: pointer;
  }
  line-height: ${getRem(16)};
  overflow: hidden;
`;

export default SideBarUserItemsWrapper;
