export const NotificationRouteTypesEnum = {
  TRADE_LANE: "TRADE_LANE",
  INBOUND: "INBOUND",
  OUTBOUND: "OUTBOUND",
};

export const NotificationSettingEnum = {
  General: "GENERAL",
  Custom: "CUSTOM",
};

export const NotificationFollowTypeEnum = {
  All: "ALL",
  Favorites: "FAVORITES",
};

export const LocationTypeEnum = {
  COUNTRY: "COUNTRY",
  PLACE: "PLACE",
};
