import * as MDM from "constants/masterDataManagement";
import { NetworkStatus, useQuery } from "@apollo/client";
import useCorporatePartnerUUID from "hooks/useCorporatePartnerUUID";
import { GET_CONFIGS, GET_COUNTRIES } from "queries/AAA/masterDataManagement";
import { USER_NEW_ADDRESS_BOOK_LIST_QUERY } from "queries/MakeABooking/getUserNewAddressBookList";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { AdvancedTable } from "react-lib";
import { useMediaQuery } from "react-responsive";
import { Waypoint } from "react-waypoint";
import { SCREEN_SIZES } from "utils/css";
import { TableAction } from "../StreetAddress/AddressBook/AddressBook.constants";
import { AddressModalContext } from "../StreetAddress/AddressBook/AddressBookContext";
import * as C from "./AddressBookNew.constants";
import * as H from "./AddressBookNew.helpers";
import * as S from "./AddressBookNew.styles";
import AddressBookEmpty from "./AddressBookNewEmptyList/AddressBookNewEmptyList";

const NewAddressBookTable = ({
  openEditAddressModal,
  openDeleteAddressModal,
  openAddAddressModal,
  activeFilters,
  setSelectedFilterId,
  hideFilters,
  showFilters,
}: C.AddressBookNewTableProps): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.until.md });
  const intl = useIntl();
  const [addresses, setAddresses] = useState<C.UserNewAddressBook[]>([]);
  const [hoveredRowId, setHoveredRowId] = useState(-1);
  const [clickedRowId, setClickedRowId] = useState(-1);
  const [overlayPosition, setOverlayPosition] = useState<DOMRect | null>(null);
  const advancedTableWrapperRef = useRef<HTMLDivElement>(null);
  const corporatePartner = useCorporatePartnerUUID() || "";
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const { tableAction, setTableAction, countryList } =
    useContext(AddressModalContext);

  const { data: configsData, networkStatus: configsDataNetworkStatus } =
    useQuery<{ getConfigs: C.Config[] }>(GET_CONFIGS);

  const { data: countriesData } =
    useQuery<{ getCountries: MDM.Country[] }>(GET_COUNTRIES);

  const orderByParamFromConfig = configsData?.getConfigs?.find(
    (el) => el.KEY === C.AddressBookDefaultSorting
  )?.VALUE;

  const enrichData = useCallback(
    (
      addressesFromResponse: C.UserNewAddressBook[] | undefined
    ): C.UserNewAddressBook[] => {
      return (
        addressesFromResponse?.map((entry: C.UserNewAddressBook) => ({
          ...entry,
          country: countryList?.find(
            (country) => country?.code === entry?.address?.country_code
          )?.label,
          concatAddress: H.concatNewAddress(entry),
        })) || []
      );
    },
    [countryList]
  );

  const {
    data,
    refetch,
    networkStatus: userAddressNetworkStatus,
  } = useQuery<C.UserNewAddressBookListResponse>(
    USER_NEW_ADDRESS_BOOK_LIST_QUERY,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        order: {
          order_by: C.defaultNewOrderingParameter,
          order_direction: C.defaultOrderingDirection,
        },
        paging: {
          page_num: 0,
          page_size: C.UserAddressBookQueryPageSize,
        },
        filters: H.normalizeActiveFilters(
          activeFilters,
          countriesData?.getCountries
        ),
        corporate_partner_uuid: corporatePartner,
      },
    }
  );

  const addressBookFetchPartParams = useMemo(
    () => ({
      paging: {
        page_num: Math.ceil(addresses?.length / C.UserAddressBookQueryPageSize),
        page_size: C.UserAddressBookQueryPageSize,
      },
      filters: H.normalizeActiveFilters(
        activeFilters,
        countriesData?.getCountries
      ),
      orderBy: orderByParamFromConfig || C.defaultOrderingParameter,
      orderDirection: C.defaultOrderingDirection,
    }),
    [
      activeFilters,
      addresses?.length,
      countriesData?.getCountries,
      orderByParamFromConfig,
    ]
  );

  const addressBookFetchAllParams = useMemo(
    () => ({
      paging: {
        page_num: 0,
        page_size: C.UserAddressBookQueryPageSize,
      },
      filters: H.normalizeActiveFilters(
        activeFilters,
        countriesData?.getCountries
      ),
      orderBy: orderByParamFromConfig || C.defaultOrderingParameter,
      orderDirection: C.defaultOrderingDirection,
    }),
    [activeFilters, countriesData?.getCountries, orderByParamFromConfig]
  );

  const isDataLoading =
    configsDataNetworkStatus !== NetworkStatus.ready ||
    userAddressNetworkStatus !== NetworkStatus.ready ||
    !countryList ||
    countryList?.length === 0;

  const columns = useMemo(
    () =>
      isMobile
        ? [
            H.getAddressBookOptionsColumn(),
            ...H.getAddressBookColumns(
              intl,
              activeFilters,
              setSelectedFilterId
            ).slice(0, 2),
          ]
        : [
            ...H.getAddressBookColumns(
              intl,
              activeFilters,
              setSelectedFilterId
            ),
            H.getAddressBookOptionsColumn(),
          ],
    [activeFilters, intl, isMobile, setSelectedFilterId]
  );

  const isAddressBookEmpty =
    !isDataLoading && addresses?.length === 0 && !activeFilters.length;

  useEffect(() => {
    if (isAddressBookEmpty) {
      hideFilters();
    } else {
      showFilters();
    }
  });

  useEffect(() => {
    if (!isDataLoading && data) {
      if (tableAction === TableAction.Clear) {
        setTableAction(null);
        setAddresses([]);
        setTotalCount(0);
      } else if (tableAction === TableAction.FetchPart) {
        // Partial fetching of addresses, especially for waypoint
        refetch(addressBookFetchPartParams).then((response) => {
          setAddresses([
            ...addresses,
            ...enrichData(response.data?.getUserNewAddressBookList?.addresses),
          ]);
          setTotalCount(data?.getUserNewAddressBookList?.total_count);
        });
        setTableAction(null);
      } else if (tableAction === TableAction.FetchAll) {
        // Fetch all addresses, especially for add/edit/delete
        refetch(addressBookFetchAllParams).then((response) => {
          setAddresses(
            enrichData(response.data?.getUserNewAddressBookList?.addresses)
          );
          setTotalCount(data?.getUserNewAddressBookList?.total_count);
        });
        setTableAction(null);
      } else if (
        tableAction === null &&
        addresses?.length === 0 &&
        data?.getUserNewAddressBookList?.addresses?.length
      ) {
        // Initial setting
        setAddresses(enrichData(data?.getUserNewAddressBookList?.addresses));
        setTotalCount(data?.getUserNewAddressBookList?.total_count);
      }
    }
  }, [
    tableAction,
    data,
    isDataLoading,
    addressBookFetchAllParams,
    addressBookFetchPartParams,
    addresses,
    refetch,
    setTableAction,
    enrichData,
  ]);

  const selectMenuOptionForListItem = useCallback(
    (addressId: number, menuOption: C.RowMenuOptions) => {
      const selectedAddress = addresses.find(
        (address) => address.address_id === addressId
      );

      if (selectedAddress) {
        if (menuOption === C.RowMenuOptions.EDIT) {
          openEditAddressModal(selectedAddress);
        } else {
          openDeleteAddressModal(selectedAddress);
        }
      }
    },
    [openEditAddressModal, openDeleteAddressModal, addresses]
  );

  const rowRendererCallback = useCallback(
    (args: C.NewRowContentRendererProps) => {
      return H.selectableNewRowRenderer({
        ...args,
        hoveredRowId,
        setHoveredRowId,
        clickedRowId,
        setClickedRowId,
        intl,
        selectMenuOptionForListItem,
      });
    },
    [clickedRowId, hoveredRowId, intl, selectMenuOptionForListItem]
  );

  const canFetchMore = useMemo(
    () =>
      addresses?.length > 0 &&
      (data?.getUserNewAddressBookList?.total_count || totalCount || 0) >
        addresses?.length &&
      !tableAction &&
      !isDataLoading,
    [
      addresses?.length,
      data?.getUserNewAddressBookList?.total_count,
      isDataLoading,
      tableAction,
      totalCount,
    ]
  );

  const handleWaypoint = useCallback(() => {
    if (!isDataLoading && data) {
      setTableAction(TableAction.FetchPart);
    }
  }, [data, isDataLoading, setTableAction]);

  useEffect(() => {
    if (advancedTableWrapperRef.current) {
      const rect = advancedTableWrapperRef.current.getBoundingClientRect();
      setOverlayPosition(rect);
    }
  }, []);

  return (
    <S.AdvancedTableWrapper ref={advancedTableWrapperRef}>
      {isAddressBookEmpty ? (
        <AddressBookEmpty openAddAddressModal={openAddAddressModal} />
      ) : (
        <AdvancedTable
          overlayPosition={overlayPosition}
          columns={columns}
          rows={addresses}
          isLoading={isDataLoading}
          headerRowRenderer={() =>
            addresses?.length ? H.customHeaderRowRenderer({ columns }) : null
          }
          rowRenderer={rowRendererCallback}
        />
      )}
      {canFetchMore && (
        <Waypoint
          scrollableAncestor={advancedTableWrapperRef?.current}
          onEnter={handleWaypoint}
          bottomOffset="-24px"
        />
      )}
    </S.AdvancedTableWrapper>
  );
};

export default NewAddressBookTable;
