import { defineMessages } from "react-intl";

export default defineMessages({
  termsOfUseHeader: {
    id: "termsOfUse.header",
    defaultMessage: "Terms of Use",
  },
  termsOfUseIntroduction: {
    id: "termsOfUse.introduction",
    defaultMessage:
      "To start using myDHLi Portal, you must agree to the terms and conditions.",
  },
});
