const apiKeys = {
  environment:
    process.env.REACT_APP_CURRENT_ENV || window.ENV.REACT_APP_CURRENT_ENV,
  googleMaps:
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    window.ENV.REACT_APP_GOOGLE_MAPS_API_KEY,
  hereMaps:
    process.env.REACT_APP_HERE_MAPS_API_KEY ||
    window.ENV.REACT_APP_HERE_MAPS_API_KEY,
  recaptcha:
    process.env.REACT_APP_RECAPTCHA_FE_KEY ||
    window.ENV.REACT_APP_RECAPTCHA_FE_KEY,
  googleTimezone:
    process.env.REACT_APP_GOOGLE_TIMEZONE_API_KEY ||
    window.ENV.REACT_APP_GOOGLE_TIMEZONE_API_KEY,
  adobeTagManager:
    process.env.REACT_APP_ADOBE_TAG_MANAGER_KEY ||
    window.ENV.REACT_APP_ADOBE_TAG_MANAGER_KEY,
  mapsKeyPlaces:
    process.env.REACT_APP_MAPS_KEY_PLACES ||
    window.ENV.REACT_APP_MAPS_KEY_PLACES,
  oneTrust:
    process.env.REACT_APP_ONE_TRUST_DATA_DOMAIN_KEY ||
    window.ENV.REACT_APP_ONE_TRUST_DATA_DOMAIN_KEY,
  virtualAssistant:
    process.env.REACT_APP_VIRTUAL_ASSISTANT_WIDGET ||
    window.ENV.REACT_APP_VIRTUAL_ASSISTANT_WIDGET,
};

export default apiKeys;
