const startSession = () => {
  if (localStorage.getItem("MyDHLiSession") !== "open") {
    localStorage.setItem("MyDHLiSession", "open");
    localStorage.removeItem("id_token");
    localStorage.removeItem("id_token_expiry");
    localStorage.removeItem("access_token");
    localStorage.removeItem("access_token_expiry");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token_expiry");
    localStorage.removeItem("user_roles");
    localStorage.removeItem("quote");
    localStorage.removeItem("MyDHLiSettingsV21");
  }
};

export default startSession;
