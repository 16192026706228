import FreightIcon from "components/atoms/Icons/FreightIcon";
import SVGWrapper from "components/atoms/SVGWrapper";
import useOutsideClick from "hooks/useOutsideClick";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { connect } from "react-redux";
import {
  setQuotesFilter,
  setShipmentFilter,
  setBookingsListFilter,
} from "redux/userSettings/actions";
import styled, { css } from "styled-components";
import { getRem } from "utils/css";
import get from "utils/get";

const FilterItemWrapper = styled.div``;

const FilterItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${getRem(16)} ${getRem(20)};
  &:hover {
    background-color: ${(p) => (p.isDisabled ? "none" : p.theme.colors.black5)};
  }
  ${(p) =>
    p.isDisabled
      ? css`
          opacity: 0.4;
        `
      : null};
`;

export const Count = styled.div`
  margin-left: ${getRem(10)};
  padding-right: ${getRem(2)};
  flex: 1 1 ${getRem(120)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const transformTransportMode = (str) => {
  switch (str) {
    case "AIR":
      return "AIR";
    case "OCEAN":
      return "OCEAN";
    case "ROAD":
      return "ROAD";
    case "RAIL":
      return "RAIL";
    case "IW":
      return "INLAND_WATERWAY";
    default:
      return null;
  }
};

const ListBucket = (props) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, props.onOutsideClick);
  const items = get(props, "bucket.bucketItems", []);

  const handleItemClick = (key, count) => {
    if (count > 0 && props.filter) {
      if (props.module === "quotes") {
        props.setQuotesFilter(props.filter, props.transform(key));
      } else if (props.module === "bookings") {
        props.setBookingsListFilter(props.filter, props.transform(key));
      } else {
        props.setShipmentFilter(props.filter, props.transform(key));
      }
    }
    props.onOutsideClick();
  };

  return (
    <FilterItemWrapper ref={wrapperRef}>
      {items.length === 0 ? (
        <span style={{ display: "inline-block", padding: getRem(10) }}>
          No Buckets Found
        </span>
      ) : null}
      {items.map((item) => (
        <FilterItem
          key={item.key}
          onClick={() => handleItemClick(item.key, item.count)}
          isDisabled={item.count === 0}
        >
          <SVGWrapper>
            <FreightIcon type={transformTransportMode(item.key)} />
          </SVGWrapper>
          <Count> ({item.count})</Count>
        </FilterItem>
      ))}
    </FilterItemWrapper>
  );
};
ListBucket.defaultProps = {
  bucket: {},
  transform: (str) => str,
};
ListBucket.propTypes = {
  bucket: PropTypes.object,
  transform: PropTypes.func,
};

export default connect(
  (state) => ({
    userSettings: state.userSettings,
  }),
  { setShipmentFilter, setQuotesFilter, setBookingsListFilter }
)(ListBucket);
