const baseType = "QUOTE_AND_BOOK";

const types = {
  SET_COUNTRY: `${baseType}/SET_COUNTRY`,
  SET_CURRENCY: `${baseType}/SET_CURRENCY`,
  SET_QUOTE_TYPE: `${baseType}/SET_QUOTE_TYPE`,
  SAVE_PACKAGE_TYPES: `${baseType}/SAVE_PACKAGE_TYPES`,
  SET_RATES_TYPE: `${baseType}/SET_RATES_TYPE`,
};

export default types;
