import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "bookingStandalone.addressBook.modalTitle",
    defaultMessage: "Address Book",
  },
  addressId: {
    id: "bookingStandalone.addressBook.addressId",
    defaultMessage: "Address-ID: {id}",
  },
  pickUp: {
    id: "bookingStandalone.route.addressBook.modal.form.field.pickUp",
    defaultMessage: "Pick-Up",
  },
  shipper: {
    id: "bookingStandalone.route.addressBook.modal.form.field.shipper",
    defaultMessage: "Shipper",
  },
  delivery: {
    id: "bookingStandalone.route.addressBook.modal.form.field.delivery",
    defaultMessage: "Delivery",
  },
  consignee: {
    id: "bookingStandalone.route.addressBook.modal.form.field.consignee",
    defaultMessage: "Consignee",
  },
  billing: {
    id: "bookingStandalone.route.addressBook.modal.form.field.billing",
    defaultMessage: "Billing",
  },
  notify: {
    id: "bookingStandalone.route.addressBook.modal.form.field.notify",
    defaultMessage: "Notify party",
  },
});
