import getConfigClient, { FeatureFlagName } from "constants/getConfigClient";
import { UserAddressBook } from "components/organisms/AddressBook/AddressBook.constants";
import { AddressBookModal } from "components/organisms/AddressBook/AddressBookModal";
import { UserNewAddressBook } from "components/organisms/AddressBookNew/AddressBookNew.constants";
import { AddressBookNewModal } from "components/organisms/AddressBookNew/AddressBookNewModal";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { SnackBar } from "react-lib";
import { TYPES } from "react-lib/es/atoms/SnackBar/constants";
import SvgIconSearch from "react-lib/es/icons/SvgSearch";
import { AddOrEditAddressForm } from "../AddressForm/AddressBook/AddOrEditAddressModal/AddOrEditAddressForm/AddOrEditAddressForm";
import { AddressBookProps, TableAction } from "./AddressBook.constants";
import messages from "./AddressBook.messages";
import * as S from "./AddressBook.styles";
import { AddressModalContext } from "./AddressBookContext";
import AddAddressModal from "./AddressModal/AddAddressModal/AddAddressModal";
import AddressModal from "./AddressModal/components/AddressBookModalComponent/AddressModal";
import { AddressBookModalContent } from "./AddressModal/components/AddressBookModalComponent/AddressModal.constants";
import AddressBookSuccessToast from "./AddressModal/components/AddressBookSuccessToast/AddressBookSuccessToast";
import { AddressBookSuccessfulAction } from "./AddressModal/components/AddressBookSuccessToast/AddressBookSuccessToast.constants";
import { getMessage } from "./AddressModal/components/AddressBookSuccessToast/AddressBookSuccessToast.helpers";
import DeleteModal from "./AddressModal/components/DeleteModal/DeleteModal";
import DeleteNewModal from "./AddressModal/DeleteModal/DeleteModal";
import EditAddressModal from "./AddressModal/EditAddressModal/EditAddressModal";

const AddressBook = ({
  countryList,
  isAddressBookModalOpen,
  openAddressBookModal = () => {},
  closeAddressBookModal,
  onAddressConfirm = () => {},
  countriesPossibleToSelect = [],
  fieldPrefix = "",
  shouldDisplayOpenAddressBookModalButton = true,
}: AddressBookProps): JSX.Element => {
  const intl = useIntl();
  const shouldRenderAddressBookNew = getConfigClient(
    FeatureFlagName.ADDRESS_BOOK
  );

  const [modalContent, setModalContent] = useState<AddressBookModalContent>(
    AddressBookModalContent.ADDRESS_BOOK_LIST_MODAL
  );

  const [successfulAction, openSuccessToastOpen] =
    useState<AddressBookSuccessfulAction | null>(null);

  const [selectedAddress, selectAddressFromList] = useState<
    UserAddressBook | undefined
  >();

  const [selectedNewAddress, selectNewAddressFromList] = useState<
    UserNewAddressBook | undefined
  >();

  const [snackbarError, setSnackbarError] = useState<string>();

  const [isDeleteAddressModalOpen, openDeleteAddressModal] = useState(false);

  const [tableAction, setTableAction] = useState<TableAction | null>(null);

  const selectedAddressId = shouldRenderAddressBookNew
    ? selectedNewAddress?.address_id
    : selectedAddress?.id;

  return (
    <S.AddressBookWrapper>
      {shouldDisplayOpenAddressBookModalButton && (
        <S.StyledElementHeading onClick={openAddressBookModal}>
          <SvgIconSearch />
          <span>{intl.formatMessage(messages.addressBookLink)}</span>
        </S.StyledElementHeading>
      )}

      <AddressModalContext.Provider
        value={{ tableAction, setTableAction, countryList }}
      >
        <AddressModal isModalOpen={isAddressBookModalOpen}>
          {(() => {
            switch (modalContent) {
              case AddressBookModalContent.ADD_ADDRESS_MODAL:
                return shouldRenderAddressBookNew ? (
                  <AddOrEditAddressForm
                    setIsModalOpen={() =>
                      setModalContent(
                        AddressBookModalContent.ADDRESS_BOOK_LIST_MODAL
                      )
                    }
                    addressData={null}
                    scrollToLastContactOnModalOpen={false}
                    onAddressBookAddressDelete={() => {}}
                    setSelectedAddressId={() => null}
                    selectedCountry=""
                    onFormWithWrongCountrySubmit={() => {}}
                    openSuccessToast={openSuccessToastOpen}
                    openSuccessToastMessage={
                      AddressBookSuccessfulAction.ADD_ADDRESS_SUCCESS
                    }
                  />
                ) : (
                  <AddAddressModal
                    goToAddressBook={() => {
                      setModalContent(
                        AddressBookModalContent.ADDRESS_BOOK_LIST_MODAL
                      );
                      setTableAction(TableAction.FetchAll);
                    }}
                    showSuccessToastMessage={() =>
                      openSuccessToastOpen(
                        AddressBookSuccessfulAction.ADD_ADDRESS_SUCCESS
                      )
                    }
                  />
                );
              case AddressBookModalContent.EDIT_ADDRESS_MODAL:
                return shouldRenderAddressBookNew ? (
                  <>
                    <AddOrEditAddressForm
                      setIsModalOpen={() =>
                        setModalContent(
                          AddressBookModalContent.ADDRESS_BOOK_LIST_MODAL
                        )
                      }
                      addressData={null}
                      addressDataId={String(selectedAddressId)}
                      scrollToLastContactOnModalOpen={false}
                      onAddressBookAddressDelete={() => {}}
                      setSelectedAddressId={() => null}
                      selectedCountry=""
                      onFormWithWrongCountrySubmit={() => {}}
                      openSuccessToast={openSuccessToastOpen}
                      openSuccessToastMessage={
                        AddressBookSuccessfulAction.EDIT_ADDRESS_SUCCESS
                      }
                    />
                  </>
                ) : (
                  <>
                    {selectedAddress ? (
                      <EditAddressModal
                        countryList={countryList}
                        goToAddressBook={() => {
                          setModalContent(
                            AddressBookModalContent.ADDRESS_BOOK_LIST_MODAL
                          );
                          setTableAction(TableAction.FetchAll);
                        }}
                        showSuccessToastMessage={() =>
                          openSuccessToastOpen(
                            AddressBookSuccessfulAction.EDIT_ADDRESS_SUCCESS
                          )
                        }
                        initialValues={selectedAddress}
                        showDeleteAddressModal={() =>
                          openDeleteAddressModal(true)
                        }
                      />
                    ) : null}
                  </>
                );
              case AddressBookModalContent.ADDRESS_BOOK_LIST_MODAL:
                return shouldRenderAddressBookNew ? (
                  <AddressBookNewModal
                    closeAddressBookModal={closeAddressBookModal}
                    openAddAddressModal={() =>
                      setModalContent(AddressBookModalContent.ADD_ADDRESS_MODAL)
                    }
                    openEditAddressModal={(address: UserNewAddressBook) => {
                      selectNewAddressFromList(address);
                      setModalContent(
                        AddressBookModalContent.EDIT_ADDRESS_MODAL
                      );
                    }}
                    openDeleteAddressModal={(address: UserNewAddressBook) => {
                      selectNewAddressFromList(address);
                      openDeleteAddressModal(true);
                    }}
                    setSnackbarError={setSnackbarError}
                    fieldPrefix={fieldPrefix}
                  />
                ) : (
                  <AddressBookModal
                    onAddressConfirm={onAddressConfirm}
                    closeAddressBookModal={closeAddressBookModal}
                    openAddAddressModal={() =>
                      setModalContent(AddressBookModalContent.ADD_ADDRESS_MODAL)
                    }
                    openEditAddressModal={(address: UserAddressBook) => {
                      selectAddressFromList(address);
                      setModalContent(
                        AddressBookModalContent.EDIT_ADDRESS_MODAL
                      );
                    }}
                    openDeleteAddressModal={(address: UserAddressBook) => {
                      selectAddressFromList(address);
                      openDeleteAddressModal(true);
                    }}
                    countriesPossibleToSelect={countriesPossibleToSelect}
                    setSnackbarError={setSnackbarError}
                    fieldPrefix={fieldPrefix}
                  />
                );
            }
          })()}
          {!!successfulAction && (
            <AddressBookSuccessToast
              message={intl.formatMessage(getMessage(successfulAction))}
              hideToastMessage={() => openSuccessToastOpen(null)}
              successfulAction={successfulAction}
            />
          )}
          <SnackBar
            text={snackbarError}
            type={TYPES.ERROR}
            show={!!snackbarError}
            showCloseIcon={false}
          />
        </AddressModal>

        {selectedAddressId &&
          (shouldRenderAddressBookNew ? (
            <DeleteNewModal
              addressId={selectedAddressId}
              isOpen={isDeleteAddressModalOpen}
              hideModal={() => openDeleteAddressModal(false)}
              onDeleteSuccess={() => {
                openDeleteAddressModal(false);
                setModalContent(
                  AddressBookModalContent.ADDRESS_BOOK_LIST_MODAL
                );
                openSuccessToastOpen(
                  AddressBookSuccessfulAction.DELETE_ADDRESS_SUCCESS
                );
                setTableAction(TableAction.FetchAll);
              }}
            />
          ) : (
            <DeleteModal
              id={selectedAddressId}
              isOpen={isDeleteAddressModalOpen}
              hideModal={() => openDeleteAddressModal(false)}
              onDeleteSuccess={() => {
                openDeleteAddressModal(false);
                setModalContent(
                  AddressBookModalContent.ADDRESS_BOOK_LIST_MODAL
                );
                openSuccessToastOpen(
                  AddressBookSuccessfulAction.DELETE_ADDRESS_SUCCESS
                );
                setTableAction(TableAction.FetchAll);
              }}
            />
          ))}
      </AddressModalContext.Provider>
    </S.AddressBookWrapper>
  );
};

export default AddressBook;
