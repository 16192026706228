import { useMutation } from "@apollo/client";
import useCorporatePartnerUUID from "hooks/useCorporatePartnerUUID";
import { addNewAddressToUsersAddressBookMutation } from "queries/MakeABooking/userAddressBookQueries";
import React from "react";
import { useIntl } from "react-intl";
import AddressBookForm from "../components/AddressBookModalContent/AddressBookForm/AddressBookForm";
import { AddressBookFormSchema } from "../components/AddressBookModalContent/AddressBookForm/AddressBookFormFields/AddressBookFormFields.constants";
import * as C from "./AddAddressModal.constants";
import * as H from "./AddAddressModal.helpers";
import messages from "./AddAddressModal.messages";

const AddAddressModal = ({
  goToAddressBook,
  showSuccessToastMessage,
}: C.AddAddressModalProps) => {
  const intl = useIntl();

  const [addNewAddressToUsersAddressBook, { loading: isAdding }] = useMutation(
    addNewAddressToUsersAddressBookMutation
  );
  const corporatePartner = useCorporatePartnerUUID() || "";

  const handleAddNewAddress = (formValues: AddressBookFormSchema): void => {
    const valuesInRequestSchema = H.parseFormValuesToRequestSchema(formValues);
    addNewAddressToUsersAddressBook({
      variables: {
        newAddress: {
          ...valuesInRequestSchema.addressBook,
          corporatePartnerUUID: corporatePartner,
        },
      },
    }).then(() => {
      showSuccessToastMessage();
      goToAddressBook();
    });
  };

  return (
    <AddressBookForm
      formHeader={intl.formatMessage(messages.addAddress)}
      onFormSubmit={handleAddNewAddress}
      onCancel={goToAddressBook}
      onBackArrowClick={goToAddressBook}
      isDisabled={isAdding}
    />
  );
};

export default AddAddressModal;
