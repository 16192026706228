import gql from "graphql-tag";

export const GET_MYDHLI_FEATURE_FLAGS = gql`
  query GetMyDHLiFeatureFlags {
    getMyDHLiFeatureFlags {
      name
      isEnabled
      exceptions {
        forCP
        forEmail
        multiConditional {
          CP_UUID
          USER_EMAIL
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProductsQuery {
    getProducts {
      ORDER_NUM
      IS_ACTIVE_BOOKING
      CODE
      LABEL_WEBLATE_KEY
      LABEL
      ICON
    }
  }
`;

export const GET_PACKAGE_TYPES = gql`
  query GetPackagesTypesQuery(
    $product: String
    $exportType: ExportType
    $validOn: String
  ) {
    getPackageTypes(
      product: $product
      exportType: $exportType
      validOn: $validOn
    ) {
      LABEL
      CODE
      LABEL_WEBLATE_KEY
      PRODUCT
      SERVICE
      REGION
      COUNTRY_CODE
      ICON
    }
  }
`;

export const GET_DANGEROUS_GOODS_DIVISIONS = gql`
  query GetDangerousGoodsDivisions {
    getDangerousGoodsDivision {
      DESCRIPTION
      CODE
      DESCRIPTION_WEBLATE_KEY
      CLASS_CODE
      ORDER
    }
  }
`;

export const GET_DANGEROUS_GOODS_CLASSES = gql`
  query GetDangerousGoodsClasses {
    getDangerousGoodsClasses {
      DESCRIPTION
      CODE
      DESCRIPTION_WEBLATE_KEY
      ORDER
    }
  }
`;

export const GET_DANGEROUS_GOODS_PACKAGING_GROUPS = gql`
  query GetDangerousGoodsPackagingGroups {
    getDangerousGoodsPackagingGroups {
      DESCRIPTION
      CODE
      DESCRIPTION_WEBLATE_KEY
      ORDER
    }
  }
`;

export const GET_REFERENCE_TYPES = gql`
  query GetReferenceTypesQuery {
    getReferenceTypes {
      LABEL
      CODE
      LABEL_WEBLATE_KEY
    }
  }
`;

export const GET_DOCUMENT_TYPES = gql`
  query GetDocumentTypesQuery(
    $product: String
    $exportType: ExportType
    $validOn: String
  ) {
    getDocumentType(
      product: $product
      exportType: $exportType
      validOn: $validOn
    ) {
      CODE
      EIP_TYPE
      PRODUCT_CODE
      LABEL
      LABEL_WEBLATE_KEY
      ORDER
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries($exportType: ExportType, $validOn: String) {
    getCountries(exportType: $exportType, validOn: $validOn) {
      validFrom: VALID_FROM
      validTo: VALID_TO
      label: NAME
      value: CODE
      code: CODE
      countryCode: CODE
      is_european_union: IS_EUROPEAN_UNION
      phone: PHONE
      region: REGION
      addressType: PREFERRED_ADDRESS_INPUT_TYPE
      name_weblate_key: NAME_WEBLATE_KEY
      systemOfMeasurement: SYSTEM_OF_MEASUREMENT
    }
  }
`;

export const GET_COUNTRIES_PRODUCTS = gql`
  query GetCountriesProducts {
    getCountriesProducts {
      isOrigin: IS_ORIGIN
      isBilling: IS_BILLING
      isDestination: IS_DESTINATION
      mailAvailability: MAIL_AVAILABILITY
      productCode: PRODUCT_CODE
      countryCode: COUNTRY_CODE
    }
  }
`;

export const GET_TERMINALS = gql`
  query GetTerminals(
    $product: String
    $country: String
    $unlocode: String
    $exportType: ExportType
    $validOn: String
  ) {
    getTerminals(
      product: $product
      country: $country
      unlocode: $unlocode
      exportType: $exportType
      validOn: $validOn
    ) {
      pricingLocation: PRICING_LOCATION
      stationCode: STATION_CODE
      serviceType: SERVICE_TYPE
      name: NAME
      cityName: CITY_NAME
      value: UNLOCODE
      code: STATION_CODE
      countryCode: COUNTRY_CODE
      productCode: PRODUCT_CODE
      direction: DIRECTION
      unlocode: UNLOCODE
    }
  }
`;

export const GET_CONFIGS = gql`
  query GetConfigs {
    getConfigs {
      VALUE
      KEY
    }
  }
`;

export const GET_SERVICES = gql`
  query GetServices {
    getServices {
      code: CODE
      isEuOnly: IS_EU_ONLY
      product: PRODUCT
      label: LABEL
      labelWeblateKey: LABEL_WEBLATE_KEY
      iconName: ICON
      isSelectable: IS_SELECTABLE
    }
  }
`;

export const GET_SERVICES_BY_PRODUCT_AND_COUNTRIES = gql`
  query GetServicesByProductAndCountries(
    $product: String
    $pickupCountry: String
    $destinationCountry: String
    $exportType: ExportType
    $validOn: String
  ) {
    getServicesByProductAndCountries(
      product: $product
      pickupCountry: $pickupCountry
      destinationCountry: $destinationCountry
      exportType: $exportType
      validOn: $validOn
    ) {
      code: CODE
      isEuOnly: IS_EU_ONLY
      product: PRODUCT
      label: LABEL
      labelWeblateKey: LABEL_WEBLATE_KEY
      iconName: ICON
      isSelectable: IS_SELECTABLE
    }
  }
`;

export const GET_INCOTERMS_BY_PRODUCT_AND_TYPE = gql`
  query GetIncotermsByProductAndType(
    $product: String!
    $isFromTerminal: Boolean
    $isToTerminal: Boolean
    $billingReferredType: String
  ) {
    getIncotermsByProductAndType(
      product: $product
      isFromTerminal: $isFromTerminal
      isToTerminal: $isToTerminal
      billingReferredType: $billingReferredType
    ) {
      product: PRODUCT
      code: CODE
      label: LABEL
      labelWeblateKey: LABEL_WEBLATE_KEY
      isDefault: IS_DEFAULT
      value: INCO_TERMS
      description: DESCRIPTION
      descriptionWeblateKey: DESCRIPTION_WEBLATE_KEY
    }
  }
`;

export const GET_COMMODITY_GROUPS = gql`
  query GetCommodityGroups(
    $product: String
    $exportType: ExportType
    $validOn: String
  ) {
    getCommodityGroups(
      product: $product
      exportType: $exportType
      validOn: $validOn
    ) {
      CODE
      DESCRIPTION
      DESCRIPTION_WEBLATE_KEY
      ICON
      INFORMATION
      INFORMATION_HEADER
      INFORMATION_HEADER_WEBLATE_KEY
      LABEL
      LABEL_WEBLATE_KEY
      ORDER
      PARENT
      SUB_LEVEL_LABEL
      SUB_LEVEL_LABEL_WEBLATE_KEY
      TEMP_CONTROL
      TEMP_MAX
      TEMP_MIN
      TEMP_UOM
      INFORMATION_WEBLATE_KEY
      PRODUCT_CODE
      SHOW_DETAILS
    }
  }
`;

export const GET_CONTAINERS_DESCRIPTIONS = gql`
  query GetContainersDescriptions($containerCode: String) {
    getContainersDescriptions(containerCode: $containerCode) {
      CODE
      NET_WEIGHT
      GROSS_WEIGHT
      TARE_WEIGHT
      SYSTEM_OF_MEASUREMENT
    }
  }
`;
