import InputFieldWithValidation from "components/organisms/fieldsWithValidation/InputFieldWithValidation/InputFieldWithValidation";
import PhoneInputWithValidation from "components/organisms/fieldsWithValidation/PhoneInputWithValidation/PhoneInputWithValidation";
import SelectFieldWithValidation from "components/organisms/fieldsWithValidation/SelectFieldWithValidation/SelectFieldWithValidation";
import { generateFormFields } from "components/organisms/StreetAddress/AddressForm/AddressBook/AddressBook.helpers";
import {
  contactsObjInitialValue,
  AddressFormContact,
} from "components/organisms/StreetAddress/AddressForm/AddressForm.constants";
import { getPhoneCountryPrefix } from "components/organisms/StreetAddress/AddressForm/AddressForm.helpers";
import { transformCountryCode } from "helpers/transformCountryCode";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useField, useForm } from "react-final-form";
import { FieldArray, FieldArrayProps } from "react-final-form-arrays";
import { useIntl } from "react-intl";
import SvgPlus from "react-lib/es/icons/SvgPlus";
import SvgTrashBinDelete from "react-lib/es/icons/SvgTrashBinDelete";
import { SectionActionType } from "../AddOrEditAddressForm.constants";
import { FormField } from "../AddOrEditAddressForm.styles";
import * as H from "./ContactSection.helpers";
import messages from "./ContactSection.messages";
import * as S from "./ContactSection.styles";

interface ContactSectionProps {
  scrollToLastContactOnModalOpen?: boolean;
  areFieldsDisabled: boolean;
}

export const ContactSection: FC<ContactSectionProps> = ({
  scrollToLastContactOnModalOpen = false,
  areFieldsDisabled,
}) => {
  const intl = useIntl();
  const {
    countryFieldName,
    contactDetailsName,
    email,
    phone: phoneFieldName,
    contacts,
    contactType,
  } = generateFormFields();
  const form = useForm();
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const { input: { value: selectedCountry } = {} } = useField(countryFieldName);
  const [shouldScrollToLastContact, setShouldScrollToLastContact] = useState(
    scrollToLastContactOnModalOpen
  );

  const contactTypeOptions = H.generatePartyTypeOptions(intl);

  useEffect(() => {
    if (shouldScrollToLastContact) {
      addButtonRef.current?.scrollIntoView({ behavior: "smooth" });
      setShouldScrollToLastContact(false);
    }
  }, [shouldScrollToLastContact]);

  const addContact = () => {
    setShouldScrollToLastContact(true);
    form.mutators.push?.(contacts, {
      ...contactsObjInitialValue,
      phoneNumber: getPhoneCountryPrefix(transformCountryCode(selectedCountry)),
    });
  };

  const onChangeCallback = useCallback(
    (name: string, actionType?: SectionActionType) => {
      if (actionType === SectionActionType.NONE) {
        form.change(`${name}.actionType`, SectionActionType.EDIT);
      }
    },
    [form]
  );

  const removeOrEmptyField = (
    e: React.MouseEvent<HTMLElement>,
    fields: FieldArrayProps<AddressFormContact, HTMLElement>,
    idx: number,
    name: string,
    actionType?: SectionActionType
  ) => {
    e.preventDefault();
    if (actionType === SectionActionType.ADD) {
      fields.remove(idx);
    } else {
      form.change(`${name}.actionType`, SectionActionType.REMOVE);
    }
  };

  const renderContact = (
    name: string,
    idx: number,
    fields: FieldArrayProps<AddressFormContact, HTMLElement>
  ) => {
    const actionType = (
      fields?.value?.[idx] as { actionType?: SectionActionType }
    )?.actionType;

    if (actionType === SectionActionType.REMOVE) {
      return null;
    }

    const numberOFNotRemovedContacts = fields?.value?.filter((field) => {
      return field?.actionType !== SectionActionType.REMOVE;
    }).length;

    return (
      <S.AddressSectionContainer>
        <FormField>
          <InputFieldWithValidation
            name={`${name}.${contactDetailsName}`}
            label={intl.formatMessage(messages.nameLabel)}
            onChangeCallback={() => onChangeCallback(name, actionType)}
            isDisabled={areFieldsDisabled}
          />
        </FormField>

        <FormField>
          <InputFieldWithValidation
            name={`${name}.${email}`}
            label={intl.formatMessage(messages.emailLabel)}
            onChangeCallback={() => onChangeCallback(name, actionType)}
            isDisabled={areFieldsDisabled}
          />
        </FormField>

        <S.FieldRow>
          <S.PhoneFormField>
            <PhoneInputWithValidation
              key={`${name}.phone_${selectedCountry}`}
              name={`${name}.${phoneFieldName}`}
              id={`${name}.phone`}
              isDisabled={areFieldsDisabled}
              label={intl.formatMessage(messages.phoneLabel)}
              country={transformCountryCode(selectedCountry)}
              onChangeCallback={() => onChangeCallback(name, actionType)}
            />
          </S.PhoneFormField>

          <FormField>
            <SelectFieldWithValidation
              name={`${name}.${contactType}`}
              label={intl.formatMessage(messages.contactTypeLabel)}
              options={contactTypeOptions}
              onChange={(val) => {
                form.change(`${name}.${contactType}`, val);
                onChangeCallback(name, actionType);
              }}
              shouldShowErrorMessage={false}
              isDefaultSortingDisabled
              isMultiValueSelect
              isDisabled={areFieldsDisabled}
            />
          </FormField>
        </S.FieldRow>
        {numberOFNotRemovedContacts && numberOFNotRemovedContacts > 1 && (
          <S.StyledSVGWrapper>
            <SvgTrashBinDelete
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                removeOrEmptyField(e, fields, idx, name, actionType);
              }}
            />
          </S.StyledSVGWrapper>
        )}
        <S.ContactDivider />
      </S.AddressSectionContainer>
    );
  };

  return (
    <S.AddressSectionContainer data-testid="ab_AddOrEditAddressForm_ContactsContainer">
      <S.StyledHeadline>
        {intl.formatMessage(messages.contactSectionTitle)}
      </S.StyledHeadline>
      <FieldArray name={contacts}>
        {({ fields }) =>
          fields.map((name, idx) => (
            <div key={`${idx}_${fields.length}_${selectedCountry || ""}`}>
              {renderContact(name, idx, fields)}
            </div>
          ))
        }
      </FieldArray>
      <S.StyledTextButton
        ref={addButtonRef}
        color="webBlack"
        leftIcon={<SvgPlus />}
        onClick={addContact}
      >
        {intl.formatMessage(messages.addContact)}
      </S.StyledTextButton>
    </S.AddressSectionContainer>
  );
};
