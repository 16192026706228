import { cdzColumnToFilter } from "constants/cdzColumns";
import { columnToFilter, columnToSort } from "constants/columns";
import { LIST_TYPE } from "constants/common";
import {
  quotesColumnToFilter,
  quotesColumnToSort,
} from "constants/quotesColumns";
import { dispatchInteractionEvent } from "adobe-analytics/content-interaction-events/ContentInteractionEvents.helpers";
import { QuotesInteractions } from "adobe-analytics/content-interaction-events/quotes/QuotesEvents.constants";
import { ShipmentsInteractions } from "adobe-analytics/content-interaction-events/shipments/ShipmentsEvents.constants";
import { ReactComponent as CloseIcon } from "assets/icons/myDHLi/cancel.svg";
import { ReactComponent as DownIcon } from "assets/icons/myDHLi/chevron-down.svg";
import { ReactComponent as UpIcon } from "assets/icons/myDHLi/chevron-up.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import Sort, { SortIconWrapper } from "components/molecules/Sort/Sort";
import Tip from "components/molecules/Tip/Tip1";
import {
  ShipmentColContainer,
  ShipmentColText,
} from "components/organisms/ShipmentRow/Partials/ShipmentRowAndCol";
import cdzColumns from "data/CDZColumns";
import quotesColumns from "data/QuotesColumns";
import ShipmentColumns from "data/ShipmentColumns";
import useOutsideClick from "hooks/useOutsideClick";
import includes from "lodash/includes";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {
  unsetCDZFilter,
  unsetQuotesFilter,
  unsetShipmentFilter,
} from "redux/userSettings/actions";
import getDropdownContentCdz from "routes/CDZ/helper/getDropdownContent";
import { messages as CDZMessages } from "routes/CDZ/messages";
import getDropdownContentQuotes from "routes/Quotes/helper/getDropdownContent";
import { messages as QuotesMessages } from "routes/Quotes/messages";
import getDropdownContentShipments from "routes/Shipments/helper/getDropdownContent";
import { messages as ShipmentsMessages } from "routes/Shipments/messages";
import styled, { css } from "styled-components";
import { getRem } from "utils/css";
import get from "utils/get";
import { getAppliedFilterText, getIsDate } from "../functions";

export const HighLightHover = styled.div`
  display: flex;

  :hover ${SortIconWrapper} {
    visibility: visible;
  }

  :hover {
    ${(p) =>
      !p.noHover
        ? css`
            background-color: ${(p) => p.theme.colors.black5};
          `
        : null}
  }

  border: ${getRem(1)} solid ${(p) => (p.isActive ? "#333" : "transparent")};
  background-color: ${(p) =>
    p.isActive ? p.theme.colors.black5 : "transparent"};
  border-radius: ${getRem(4)};
  padding: ${getRem(3)};
  align-items: center;
  position: relative;
  padding-right: ${(p) => (p.isActive ? getRem(26) : getRem(3))};
`;
export const HeaderCol = styled(ShipmentColContainer)`
  justify-content: flex-start;
  position: relative;
`;

export const ActionButtonsCol = styled(HeaderCol)`
  justify-content: flex-end;
  padding-right: ${getRem(30)};
`;

export const ColText = styled(ShipmentColText)`
  cursor: pointer;
  font-weight: normal;
  vertical-align: middle;
  line-height: ${getRem(28)};
`;

export const DownIconWrapper = styled(SVGWrapper).attrs((props) => ({
  fill: props.theme.colors.black,
  width: getRem(20),
  height: getRem(20),
}))`
  position: relative;
  top: ${getRem(-1)};
  left: ${getRem(1)};
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: ${getRem(30)};
  z-index: 2;
  ${(p) =>
    p.isRight
      ? `right: ${p.isDate ? getRem(-60) : getRem(-2)};`
      : `left: ${p.isDate ? getRem(-60) : getRem(-2)};`};
  background: white;
  box-shadow: 0 ${getRem(2)} ${getRem(4)} 0 rgba(0, 0, 0, 0.1),
    0 ${getRem(2)} ${getRem(4)} 0 rgba(0, 0, 0, 0.1);
  width: ${(p) => (p.isDate ? "auto" : "fit-content")};
  border: ${getRem(2)} solid black;
  border-radius: ${getRem(4)};
  overflow: hidden;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: ${getRem(4)};
  top: ${getRem(2)};
`;

export const BlockOutsideWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
`;

const Header = ({
  item,
  index,
  sort,
  onSortClick,
  bucket,
  userSettings,
  unsetShipmentFilter,
  unsetQuotesFilter,
  unsetCDZFilter,
  listType,
}) => {
  const intl = useIntl();
  const isShipmentList = listType === LIST_TYPE.SHIPMENTS;
  const isCdzList = listType === LIST_TYPE.CDZ;
  const isQuotesList = listType === LIST_TYPE.QUOTES;

  const columns = isShipmentList
    ? ShipmentColumns
    : isCdzList
    ? cdzColumns
    : quotesColumns;

  const filterPath = isShipmentList
    ? "shipmentFilter"
    : isCdzList
    ? "cdzFilter"
    : "quotesFilter";
  const columnsFilter = isShipmentList
    ? columnToFilter
    : isCdzList
    ? cdzColumnToFilter
    : quotesColumnToFilter;

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const message = isShipmentList
    ? ShipmentsMessages["shipment" + item]
    : isCdzList
    ? CDZMessages[item]
    : QuotesMessages[item];
  let text = message ? intl.formatMessage(message) : item;
  let tooltipText = text;
  const customSize = get(columns, item + ".customSize", 4);
  const sortable = get(columns, item + ".sortable", false);
  const isDropdown = get(columns, item + ".isDropdown", false);
  const appliedFilters = Object.keys(get(userSettings, filterPath, {}));

  const isFilterApplied = isShipmentList
    ? includes(appliedFilters, columnsFilter[item])
    : Array.isArray(columnsFilter[item])
    ? includes(appliedFilters, columnsFilter[item][0])
    : includes(appliedFilters, columnsFilter[item]);
  let modeServiceFilters;
  if (isFilterApplied) {
    text = isCdzList
      ? item
      : getAppliedFilterText(item, userSettings, listType, intl);
    const message = isShipmentList
      ? ShipmentsMessages["shipment" + item]
      : isCdzList
      ? CDZMessages[item]
      : QuotesMessages[item];
    tooltipText = (message ? intl.formatMessage(message) : item) + ": " + text;
  }

  if (
    appliedFilters.some(
      (filter) => filter === "product" || filter === "serviceType"
    )
  ) {
    modeServiceFilters = {
      mode: getAppliedFilterText("Mode", userSettings, listType, intl),
      service: userSettings.quotesFilter.serviceType,
    };
  }

  const sortObj = {
    type: isShipmentList ? sort.shipmentSortType : sort.quoteSortType,
    current: isShipmentList ? sort.shipmentSort : sort.quoteSort,
    sortKey: isShipmentList ? columnToSort : quotesColumnToSort,
  };
  const getDropdownContent = isShipmentList
    ? getDropdownContentShipments
    : isCdzList
    ? getDropdownContentCdz
    : getDropdownContentQuotes;
  const isCurrentSort =
    sortable && sortObj.current === sortObj.sortKey[item].key;

  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setDropDownOpen(false));

  const handleSortClick = (sortData) => {
    if (isShipmentList) {
      dispatchInteractionEvent({
        eventName: ShipmentsInteractions.TABLE_SORTING,
        eventNameDetails: message.defaultMessage,
      });
    } else if (isQuotesList) {
      dispatchInteractionEvent({
        eventName: QuotesInteractions.SORT_BY,
        eventNameDetails: message.defaultMessage,
      });
    }
    onSortClick(sortData);
  };

  return (
    <HeaderCol
      data-track={23}
      data-filter={item}
      data-page={
        isShipmentList
          ? LIST_TYPE.SHIPMENTS
          : isCdzList
          ? LIST_TYPE.CDZ
          : LIST_TYPE.QUOTES
      }
      ref={dropdownRef}
      customSize={customSize}
    >
      <HighLightHover isActive={isFilterApplied}>
        {sortable && (
          <Sort
            type={isCurrentSort ? sortObj.type : undefined}
            item={item}
            sortKey={sortObj.sortKey[item].key}
            current={isCurrentSort}
            onClick={handleSortClick}
            sortDirectionOnFirstClick={
              sortObj.sortKey[item].sortDirectionOnFirstClick
            }
          />
        )}
        <Tip topOffset={35} content={tooltipText}>
          <ColText
            size={customSize}
            onClick={() => {
              if (isDropdown) setDropDownOpen(!dropDownOpen);
            }}
          >
            {text}
          </ColText>
        </Tip>
        {isDropdown && (
          <DownIconWrapper
            tabIndex={0}
            showPointer
            onClick={() => {
              setDropDownOpen(!dropDownOpen);
            }}
          >
            {isFilterApplied ? null : dropDownOpen ? <UpIcon /> : <DownIcon />}
          </DownIconWrapper>
        )}
        {dropDownOpen ? (
          <>
            <BlockOutsideWrapper onClick={() => setDropDownOpen(false)} />
            <DropdownContent
              isDate={getIsDate(item, listType)}
              isRight={index >= 4}
            >
              {getDropdownContent(
                item,
                isShipmentList ? bucket : undefined,
                () => {
                  setDropDownOpen(false);
                },
                modeServiceFilters
              )}
            </DropdownContent>
          </>
        ) : null}
        {isFilterApplied ? (
          <CloseButtonWrapper
            onClick={() => {
              if (isShipmentList) {
                unsetShipmentFilter(columnsFilter[item]);
              } else if (isCdzList) {
                unsetCDZFilter(columnsFilter[item]);
              } else {
                if (Array.isArray(columnsFilter[item])) {
                  unsetQuotesFilter(columnsFilter[item][0]);
                  unsetQuotesFilter(columnsFilter[item][1]);
                } else {
                  unsetQuotesFilter(columnsFilter[item]);
                }
              }
            }}
          >
            <SVGWrapper
              width={getRem(16)}
              height={getRem(16)}
              showPointer
              hoverFill="#d40511"
            >
              <CloseIcon />
            </SVGWrapper>
          </CloseButtonWrapper>
        ) : null}
      </HighLightHover>
    </HeaderCol>
  );
};
Header.propTypes = {
  onSortClick: PropTypes.func,
  sort: PropTypes.object,
};
Header.defaultProps = {};

export default connect(
  (state) => ({
    userSettings: state.userSettings,
  }),
  { unsetShipmentFilter, unsetQuotesFilter, unsetCDZFilter }
)(Header);
