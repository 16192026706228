import getConfigClient from "constants/getConfigClient";

export const isProd = () => {
  return (
    getConfigClient("BUILD_ENVIRONMENT") === "production" ||
    getConfigClient("BUILD_ENVIRONMENT") === "preprod"
  );
};
export const isLocal = () => {
  return (
    getConfigClient("BUILD_ENVIRONMENT") === "local" ||
    getConfigClient("BUILD_ENVIRONMENT") === "localbuild"
  );
};
