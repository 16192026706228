import React from "react";
import { useIntl } from "react-intl";
import { Loader } from "react-lib";
import Error from "routes/Analytics/Error";
import { messages } from "routes/Analytics/messages";
import { Wrapper } from "routes/Analytics/styles";

const LoadingOrError = ({ loading, hasError, tabs }) => {
  const intl = useIntl();
  if (loading) {
    return (
      <Wrapper>
        <Loader size="medium" style={{ paddingTop: "30vh" }} />
      </Wrapper>
    );
  }
  if (hasError) {
    return (
      <Wrapper>
        <Error
          title={intl.formatMessage(messages.sorry)}
          description={intl.formatMessage(messages.serverError)}
          helpText={intl.formatMessage(messages.contactUs)}
        />
      </Wrapper>
    );
  } else if (!tabs.length) {
    return (
      <Wrapper>
        <Error
          title={intl.formatMessage(messages.sorry)}
          description={intl.formatMessage(messages.noAnalytics)}
          helpText=""
        />
      </Wrapper>
    );
  } else {
    return <div>Shouldn't reach here</div>;
  }
};

export default LoadingOrError;
