import styled from "styled-components";
import { getRem } from "../../../../utils/css";

const SideBarBackDrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: ${getRem(223)};
  right: 0;
  background: transparent;
  z-index: 2;
`;

export default SideBarBackDrop;
