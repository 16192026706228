import { RootState } from "redux/reducers";

export const getCurrentUserRoles = (state: RootState): string[] | undefined =>
  state.auth.currentUserRoles.length ? state.auth.currentUserRoles : undefined;

export const getLoggedInUserEmail = (state: RootState): string | undefined =>
  state.auth.loggedInUserEmail;

export const getUserConnectionCountry = (state: RootState) =>
  state.auth.userConnectionCountry;
