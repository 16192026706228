import React, { useState, useContext } from "react";

const NotificationsSettingsContext = React.createContext();

const NotificationsSettingsContextProvider = ({ children }) => {
  const [snackBarMessage, setSnackBarMessage] = useState();

  return (
    <NotificationsSettingsContext.Provider
      value={{
        snackBarMessage,
        setSnackBarMessage,
        clearSnackBarMessage: () => setSnackBarMessage(""),
      }}
    >
      {children}
    </NotificationsSettingsContext.Provider>
  );
};

export const useNotificationsSettingsContext = () =>
  useContext(NotificationsSettingsContext);

export default NotificationsSettingsContextProvider;
