import { ReactComponent as RightIcon } from "assets/icons/myDHLi/chevron-forward-larger.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import React from "react";
import styled from "styled-components";

const Position = styled.span`
  position: absolute;
  right: 22px;
  top: 22px;
  width: 20px;
  height: 20px;
  span {
    width: 20px;
    height: 20px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export default () => (
  <Position>
    <SVGWrapper>
      <RightIcon />
    </SVGWrapper>
  </Position>
);
