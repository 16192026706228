import { BookingSummary } from "constants/bookWorkflow";
import * as MDM from "constants/masterDataManagement";
import { ReactComponent as SvgPrintLabel } from "assets/icons/print-label.svg";
import { ReactComponent as SvgPrintManifest } from "assets/icons/print-manifest.svg";
import React from "react";
import { IntlShape } from "react-intl";
import { Divider } from "react-lib";
import SvgCopy from "react-lib/es/icons/SvgCopy";
import SvgEdit from "react-lib/es/icons/SvgEdit";
import SvgOpenInNewWindow from "react-lib/es/icons/SvgOpenInNewWindow";
import SvgShareWebOn from "react-lib/es/icons/SvgShareWebOn";
import SvgStarOn from "react-lib/es/icons/SvgStarOn";
import SvgTrashBinDelete from "react-lib/es/icons/SvgTrashBinDelete";
import { initialState as userSettingsInitialState } from "redux/userSettings/reducer";
import { BookingsListColumn } from "../BookingsListHeader/BookingsListHeader.constants";

export interface MenuOption {
  value: RowMenuOptions;
  label: string;
  isDisabled?: boolean;
  icon?: React.ReactElement;
  onClick?: () => void;
}

export interface BookingsListRowProps {
  tableName: string;
  item: BookingSummary;
  userSettings: typeof userSettingsInitialState;
  onRowClick: (id: string) => void;
  shouldHighlightRow: boolean;
  rowMenuOptions: MenuOption[];
  countriesList: MDM.Country[];
  isDisabled?: boolean | undefined;
  onShareBookingClick?: () => void;
  refetchBookings?: () => void;
  onPrintLabelClick?: () => void;
  onPrintManifestClick?: () => void;
  onRenameBookingClick?: () => void;
}

export const menuIcons: Record<string, React.ReactElement> = {
  edit: <SvgOpenInNewWindow />,
  share: <SvgShareWebOn />,
  rename: <SvgEdit />,
  copy: <SvgCopy />,
  delete: <SvgTrashBinDelete />,
  follow: <SvgStarOn />,
  printLabel: <SvgPrintLabel />,
  printManifest: <SvgPrintManifest />,
  separator: <Divider length="100%" thickness="1px" />,
};

export enum RowMenuOptions {
  EDIT = "edit",
  SHARE = "share",
  COPY = "copy",
  DELETE = "delete",
  FOLLOW = "follow",
  PRINT_LABEL = "printLabel",
  PRINT_MANIFEST = "printManifest",
  SEPARATOR = "separator",
  RENAME = "rename",
}

export interface GetCellContentProps {
  item: BookingSummary;
  column: BookingsListColumn;
  userSettings: typeof userSettingsInitialState;
  intl: IntlShape;
  countriesList: MDM.Country[];
  refetchBookings?: () => void;
}

export enum WeightAndVolumeApiUoms {
  KILOGRAMS = "KGM",
  CUBIC_METERS = "MTQ",
  POUNDS = "LBR",
  CUBIC_FEET = "FTQ",
}
