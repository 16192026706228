import * as MDM from "constants/masterDataManagement";
import { useQuery } from "@apollo/client";
import { useBookWorkflowData } from "hooks/useBookWorkflowData";
import useCorporatePartnerUUID from "hooks/useCorporatePartnerUUID";
import {
  GET_COUNTRIES,
  GET_COUNTRIES_PRODUCTS,
} from "queries/AAA/masterDataManagement";
import { USER_ADDRESS_BOOK_FILTERS_QUERY } from "queries/MakeABooking/getUserAddressBookFilters";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { FiltersList } from "react-lib";
import { ActiveFilter } from "react-lib/@types/organisms/FiltersList/FiltersList.constants";
import { ButtonVariants } from "react-lib/es/atoms/buttons2/Button.constants";
import SvgPleaseContact from "react-lib/es/icons/SvgPleaseContact";
import SvgPlus from "react-lib/es/icons/SvgPlus";
import { useMediaQuery } from "react-responsive";
import { SCREEN_SIZES } from "utils/css";
import { TableAction } from "../StreetAddress/AddressBook/AddressBook.constants";
import { AddressModalContext } from "../StreetAddress/AddressBook/AddressBookContext";
import * as C from "./AddressBook.constants";
import * as H from "./AddressBook.helpers";
import messages from "./AddressBook.messages";
import * as S from "./AddressBook.styles";
import AddressBookTable from "./AddressBookTable";

export const AddressBookModal = ({
  onAddressConfirm,
  openAddAddressModal,
  closeAddressBookModal,
  openEditAddressModal,
  openDeleteAddressModal,
  countriesPossibleToSelect,
  setSnackbarError,
  fieldPrefix,
}: C.AddressBookModalProps): JSX.Element => {
  const intl = useIntl();
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.until.md });
  const { setTableAction } = useContext(AddressModalContext);
  const [selectedFilterId, setSelectedFilterId] = useState<
    string | undefined
  >();
  const [areFiltersHidden, setFiltersHidden] = useState(false);
  const corporatePartner = useCorporatePartnerUUID() || "";

  const filtersConfig = useMemo(
    () => C.generateFiltersConfig(intl, isMobile),
    [intl, isMobile]
  );

  const [selectedAddress, setSelectedAddress] =
    useState<C.UserAddressBook | null>();

  const { data: countriesProductData } = useQuery<{
    getCountriesProducts: MDM.CountryProduct[];
  }>(GET_COUNTRIES_PRODUCTS);
  const { product } = useBookWorkflowData();

  const selectAddress = (address: C.UserAddressBook | null) => {
    setSelectedAddress(address);
    setSnackbarError(
      H.generateError(
        fieldPrefix,
        intl,
        address,
        countriesPossibleToSelect,
        countriesProductData?.getCountriesProducts,
        product
      )
    );
  };

  const isButtonDisabled = useMemo(() => {
    return (
      !selectedAddress ||
      !countriesPossibleToSelect.some(
        (code) => code === selectedAddress?.address.countryCode
      )
    );
  }, [countriesPossibleToSelect, selectedAddress]);

  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([]);

  const { data: countriesData } =
    useQuery<{ getCountries: MDM.Country[] }>(GET_COUNTRIES);

  const { data: filtersData } = useQuery<C.AddressBookFiltersResponse>(
    USER_ADDRESS_BOOK_FILTERS_QUERY,
    {
      errorPolicy: "all",
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      variables: {
        filters: H.normalizeActiveFilters(
          activeFilters,
          countriesData?.getCountries
        ),
        corporatePartnerUUID: corporatePartner,
      },
    }
  );

  const filters = useMemo(() => {
    const filterValues = filtersData?.getUserAddressBookFilters;
    return filterValues?.length
      ? H.buildFilters(
          filtersConfig,
          activeFilters,
          countriesData?.getCountries,
          filterValues
        )
      : [];
  }, [
    filtersData?.getUserAddressBookFilters,
    filtersConfig,
    activeFilters,
    countriesData?.getCountries,
  ]);

  const areAllFiltersActive: boolean = useMemo(
    () =>
      filters.every((filter) =>
        activeFilters.some(
          (activeFilter: ActiveFilter) => activeFilter.id === filter.id
        )
      ),
    [activeFilters, filters]
  );

  const handleCancel = useCallback(() => {
    closeAddressBookModal();
    setSnackbarError(undefined);
  }, [closeAddressBookModal, setSnackbarError]);

  const handleConfirmAddressSelection = useCallback(() => {
    if (selectedAddress) {
      onAddressConfirm(selectedAddress);
    }
  }, [onAddressConfirm, selectedAddress]);

  const onFilterSave = useCallback(
    (val: ActiveFilter) => {
      const isNew = !activeFilters.find((el) => el.id === val.id);
      setActiveFilters((prev) => {
        if (isNew) {
          return [...prev, val];
        } else {
          return prev.map((el) => {
            if (el.id === val.id) {
              return {
                id: el.id,
                value: val.value,
              } as ActiveFilter;
            }
            return el;
          });
        }
      });
      setTableAction(TableAction.FetchAll);
    },
    [activeFilters, setTableAction]
  );

  const onFilterRemove = useCallback(
    (id: string) => {
      setActiveFilters((prev) => prev.filter((el) => el.id !== id));
      setTableAction(TableAction.FetchAll);
    },
    [setTableAction]
  );

  return (
    <>
      <S.HeaderWrapper>
        <S.StyledSVGWrapper>
          <SvgPleaseContact />
        </S.StyledSVGWrapper>
        <S.StyledModalTitle>
          {intl.formatMessage(messages.title)}
        </S.StyledModalTitle>
        <S.StyledModalHeaderButtons>
          <S.StyledModalNewAddressButton onClick={openAddAddressModal}>
            <S.StyledSVGWrapper showPointer>
              <SvgPlus />
            </S.StyledSVGWrapper>
            {isMobile ? null : (
              <S.NewAddressWrapper>
                {intl.formatMessage(messages.newAddress)}
              </S.NewAddressWrapper>
            )}
          </S.StyledModalNewAddressButton>
        </S.StyledModalHeaderButtons>
      </S.HeaderWrapper>

      <S.Content>
        <S.FiltersListContainer areFiltersHidden={areFiltersHidden}>
          <FiltersList
            activeFilters={activeFilters}
            onFilterSave={onFilterSave}
            onFilterRemove={onFilterRemove}
            filterSchema={filters}
            filterTranslation={intl.formatMessage(messages.filter)}
            disabled={!filters.length || areAllFiltersActive}
            selectedFilterId={selectedFilterId}
            onSelectedFilterIdChange={setSelectedFilterId}
          />
        </S.FiltersListContainer>
        <AddressBookTable
          openAddAddressModal={openAddAddressModal}
          openEditAddressModal={openEditAddressModal}
          openDeleteAddressModal={openDeleteAddressModal}
          setSelectedAddress={selectAddress}
          activeFilters={activeFilters}
          setSelectedFilterId={setSelectedFilterId}
          hideFilters={() => setFiltersHidden(true)}
          showFilters={() => setFiltersHidden(false)}
        />
      </S.Content>

      <S.StyledModalButtons>
        <S.StyledCustomMinWidthButton
          variant={ButtonVariants.SECONDARY}
          onClick={handleCancel}
        >
          {intl.formatMessage(messages.cancel)}
        </S.StyledCustomMinWidthButton>
        <S.StyledCustomMinWidthButton
          onClick={handleConfirmAddressSelection}
          disabled={isButtonDisabled}
        >
          {intl.formatMessage(messages.selectAddress)}
        </S.StyledCustomMinWidthButton>
      </S.StyledModalButtons>
    </>
  );
};
