export const DHLI_ROLE = "myDHLi_DHLi_DHLi";
export const C2PORTAL_ROLE = "myDHLi_C2Portal_C2Portal";
export const TopBarHeight = "3.5rem";
export const TopBarHeightMd = "4rem";
export const NavBarWidthCollapsed = "4rem";
export const NavBarWidthExpanded = "12.5rem";
export const HeaderAndFooterHeightTotal = 222;
export const ROLE_REPORTING = "myDHLi_Reports_Reports";
export const apiDateFormat = "YYYY-MM-DD";
export const displayDateFormat = "D.M.YYYY";
