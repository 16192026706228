import getConfigClient from "constants/getConfigClient";
import axios from "axios";
import { MapProviders } from "components/organisms/DashboardMap/constants";
import { useEffect, useMemo, useState } from "react";

const countriesForAddressSearchHERE = ["CN", "CHN"];
const countriesForMapHERE = ["CN", "CHN"];

let userCountryCache;

const useResolveMapsProvider = () => {
  const [userCountry, setUserCountry] = useState(userCountryCache);
  useEffect(() => {
    if (userCountryCache) {
      setUserCountry(userCountryCache);
    } else {
      axios
        .get(getConfigClient("NODE_ENDPOINT") + "detect-country", {
          skipAuth: true,
        })
        .then((res) => {
          const code = res?.data?.code;
          if (code) {
            setUserCountry(code);
            userCountryCache = code;
          }
        })
        .catch(() => {});
    }
  }, []);
  return useMemo(
    () => ({
      addressSearchProvider: countriesForAddressSearchHERE.includes(userCountry)
        ? MapProviders.HERE
        : getConfigClient("DEFAULT_ADDRESS_SEARCH_PROVIDER"),
      mapProvider: countriesForMapHERE.includes(userCountry)
        ? MapProviders.HERE
        : getConfigClient("DEFAULT_MAP_PROVIDER"),
    }),
    [userCountry]
  );
};

export default useResolveMapsProvider;
