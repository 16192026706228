import getConfigClient from "constants/getConfigClient";
import { store } from "redux/store";
import { clearAndRedirect } from "../utils/interceptor";
import { includesAnyOf } from "../utils/string";
import getKeycloakUrl from "./getKeycloakUrl";

const trackRedirectHelper = (location) => {
  const path = (location?.pathname || "").toLowerCase();
  if (!includesAnyOf(path, ["/error", "/login", "/logout"])) {
    const roles = store.getState().auth?.currentUserRoles;
    if (
      roles.length > 0 &&
      !roles.filter((role) => role.startsWith("myDHLi_") || role === "admin")
        .length
    ) {
      if (roles.filter((role) => role.startsWith("track_")).length) {
        window.redirectingToTrack = true;
        const redirectUri = new URL(
          "login",
          getConfigClient("TRACK_PORTAL_URL")
        );
        const url = getKeycloakUrl(redirectUri);
        url.searchParams.set("client_id", "nrt");
        clearAndRedirect(url.toString());
      } else {
        window.location = "/error";
      }
    }
  }
  return false;
};

export default trackRedirectHelper;
