import { NewButton, NewTextButton } from "react-lib";
import styled from "styled-components";

export const ButtonsRow = styled.div`
  height: 5rem;
  display: flex;
  width: 100%;
  padding: 1rem 2.5rem;
`;

export const DeleteButton = styled(NewTextButton)`
  height: 100%;
`;

export const RightButtonsContainer = styled.div`
  margin-left: auto;
  height: 100%;
`;

export const SaveButton = styled(NewButton)`
  margin-left: 1rem;
  padding-left: 2.625rem;
  padding-right: 2.625rem;
`;
