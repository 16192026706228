import SVGWrapper from "components/atoms/SVGWrapper";
import { Color, NewTextButton } from "react-lib";
import { ElementHeading } from "routes/MakeABooking/MakeABooking.styles";
import styled from "styled-components";
import { FormField, SectionDivider } from "../AddOrEditAddressForm.styles";

export const StyledHeadline = styled(ElementHeading)`
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.17;
  letter-spacing: normal;
  color: ${Color.webBlack};
  margin-bottom: 1.25rem;
  margin-top: 0;
`;

export const PhoneFormField = styled(FormField)`
  max-width: 50%;
  label {
    margin-bottom: 0;
  }

  input {
    height: 3rem;
  }
`;

export const FieldRow = styled.div`
  display: flex;
  gap: 1.25rem;
  ${FormField} {
    max-width: 50%;
  }
`;

export const AddressSectionContainer = styled.div``;

export const ContactDivider = styled(SectionDivider)`
  margin-top: 1rem;
  margin-bottom: 1.25rem;
`;

export const StyledTextButton = styled(NewTextButton)`
  margin-bottom: 2.5rem;
  svg {
    height: 1.125rem;
    width: 1.125rem;
  }
`;

export const StyledSVGWrapper = styled(SVGWrapper)`
  display: flex;
  width: 100%;
  margin-bottom: 1.625rem;
  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: auto;
    cursor: pointer;
  }
`;
