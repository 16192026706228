/* eslint-disable react-hooks/exhaustive-deps */ // TODO remove this and handle this independently in each hook
import { showHideWithFade, showHideWithScale } from "constants/animations";
import {
  ModalContent,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "components/atoms/Modal";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNoGlobalScroll } from "react-lib";
import { useDispatch } from "react-redux";
import { incAppScreenChange } from "../../../redux/app/actions";

/**
 * <Modal />
 * Modal wrapper with t
 */
const Modal = ({
  children,
  onClose,
  showFooter,
  showCloseButtonInHeader,
  closeOnOverlayClick,
  closeOnKBDEscape,
  hideHeader,
  title,
  onScroll,
  closeTrackCode,
  marginTop,
}) => {
  const dispatch = useDispatch();
  const onKeydown = (event) => {
    if (event.key === "Escape" || event.keyCode === 27) {
      onClose();
    }
  };

  useEffect(() => {
    dispatch(incAppScreenChange());
    return () => {
      dispatch(incAppScreenChange());
    };
  }, []);

  const [noGlobalScroll, setNoGlobalScroll] = useState(false);
  useNoGlobalScroll(noGlobalScroll);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setNoGlobalScroll(true);
      if (closeOnKBDEscape) {
        window.addEventListener("keydown", onKeydown, true);
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
      setNoGlobalScroll(false);
      if (closeOnKBDEscape) {
        window.removeEventListener("keydown", onKeydown, true);
      }
    };
  }, []);

  const onOverlayClick = () => {
    if (closeOnOverlayClick) {
      onClose();
    }
  };

  const onDialogClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <ModalOverlay
        onClick={onOverlayClick}
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={showHideWithFade}
      />
      <ModalContent onClick={onOverlayClick} onScroll={onScroll}>
        <ModalDialog
          marginTop={marginTop}
          onClick={onDialogClick}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={showHideWithScale}
        >
          {hideHeader ? null : (
            <ModalHeader
              showCloseButtonInHeader={showCloseButtonInHeader}
              onClose={onClose}
              title={title}
              closeTrackCode={closeTrackCode}
            />
          )}
          <div>{children}</div>
          {showFooter && <ModalFooter onClose={onClose} />}
        </ModalDialog>
      </ModalContent>
    </>
  );
};
Modal.propTypes = {
  onClose: PropTypes.func,
  showFooter: PropTypes.bool,
  showCloseButtonInHeader: PropTypes.bool,
  closeOnOverlayClick: PropTypes.bool,
  hideHeader: PropTypes.bool,
  closeOnKBDEscape: PropTypes.bool,
  title: PropTypes.string,
};
Modal.defaultProps = {
  onClose: (f) => f,
  showFooter: false,
  showCloseButtonInHeader: true,
  closeOnOverlayClick: true,
  closeOnKBDEscape: true,
  title: "",
};

export default Modal;
