import { useCallback, useState } from "react";
import { updateFilterInList } from "utils/reporting";
import useObjectMemo from "./useObjectMemo";

const useReportingFilter = () => {
  const [filters, setFilters] = useState([]);
  const addFilter = useCallback(
    (filter) => setFilters((oldFilters) => [...oldFilters, filter]),
    []
  );
  const removeFilter = useCallback(
    (filter) =>
      setFilters((oldFilters) =>
        oldFilters.filter(({ field }) => field !== filter.field)
      ),
    []
  );
  const updateFilter = useCallback(
    (filter, field = filter.field) =>
      setFilters((old) => updateFilterInList(old, filter, field)),
    []
  );

  return useObjectMemo({
    filters,
    setFilters,
    addFilter,
    removeFilter,
    updateFilter,
  });
};

export default useReportingFilter;
