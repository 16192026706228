import { useMutation, useQuery } from "@apollo/client";
import { submitNPSSurvey } from "queries/AAA/backoffice";
import { markNPSSurveyShown } from "queries/AAA/profile";
import { GET_USER_DETAILS } from "queries/AAA/userQueries";
import React, { useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useIntl } from "react-intl";
import { NewButton, ValidationVariant, Validation, Modal } from "react-lib";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { minPhoneNumberLength } from "routes/BookingPage/constants";
import * as C from "./NPSModal.constants";
import { phoneNumberLengthValidator } from "./NPSModal.helpers";
import messages from "./NPSModal.messages";
import * as S from "./NPSModal.styles";
import Rating from "./Rating/Rating";

const NPSModal: React.FC<C.NPSModalProps> = ({ isOpen, onClose }) => {
  const intl = useIntl();

  const { data } = useQuery(GET_USER_DETAILS);

  const userData = data?.user;

  const userID = useSelector((state: RootState) => state.auth.userId);

  const [submitSurvey] = useMutation(submitNPSSurvey);

  const [markThatUserAlreadyVoted] = useMutation(markNPSSurveyShown);

  const form = useRef<HTMLFormElement>(null);
  const [isRatingSelected, setIsRatingSelected] = useState<boolean>();
  const [isPhoneNumberIncorrect, setIsPhoneNumberIncorrect] =
    useState<boolean>();

  const onModalClose = () => {
    markThatUserAlreadyVoted();
    onClose();
  };

  const onSubmitClick = () => {
    form.current?.dispatchEvent(new Event("submit"));
    onModalClose();
  };

  const onFormSubmit = (values: C.NPSModalFormValues) => () => {
    const survey = {
      Contact_Name: userData?.firstName,
      ContactName: userData?.firstName,
      Company_Name: userData?.companyName,
      CompanyName: userData?.companyName,
      ...(values[C.NPSSurveyFields.WAS_CALLBACK_REQUESTED]
        ? {
            Callback: 1,
            Contact_Telephone: values[C.NPSSurveyFields.PHONE_NUMBER],
            ContactTelephone: values[C.NPSSurveyFields.PHONE_NUMBER],
          }
        : { Callback: 2 }),
      Contact_Email: userData?.email,
      ContactEmail: userData?.email,
      Additional_Comments: values.feedback,
      OnlineSource: "PU",
      Country: userData?.address?.country?.code,
      NPS_1: values.rating,
    };

    submitSurvey({
      variables: {
        uuid: String(userID),
        survey: JSON.stringify(survey),
      },
    });
  };

  const submitButton = (
    <NewButton
      key="submitButton"
      onClick={onSubmitClick}
      disabled={!isRatingSelected || isPhoneNumberIncorrect}
    >
      {intl.formatMessage(messages.submit)}
    </NewButton>
  );

  return (
    <Modal
      show={isOpen}
      headline={intl.formatMessage(messages.headline)}
      buttons={[submitButton]}
      onHide={onModalClose}
      width={C.modalWidth}
      fixedMobileButtons
      isMobileButtonsDividerVisible
    >
      <Form
        onSubmit={() => {}}
        initialValues={{
          [C.NPSSurveyFields.PHONE_NUMBER]: userData?.phoneNumber || "",
          [C.NPSSurveyFields.WAS_CALLBACK_REQUESTED]: false,
        }}
      >
        {({ values }: { values: C.NPSModalFormValues }) => (
          <form onSubmit={onFormSubmit(values)} ref={form}>
            <Field name={C.NPSSurveyFields.RATING} component="select">
              {({ input: { value, onChange } }) => (
                <Rating
                  ratingScores={C.ratingScores}
                  selectedRating={value}
                  onRatingSelect={(value) => {
                    setIsRatingSelected(true);
                    onChange(value);
                  }}
                />
              )}
            </Field>
            <Field name={C.NPSSurveyFields.FEEDBACK} component="textarea">
              {({ input: { value, onChange } }) => (
                <S.TextArea
                  value={value}
                  onChange={onChange}
                  scrollable
                  placeholder={intl.formatMessage(messages.textAreaPlaceholder)}
                />
              )}
            </Field>
            <Field name={C.NPSSurveyFields.WAS_CALLBACK_REQUESTED}>
              {({ input }) => (
                <S.Checkbox
                  hasMarginOffset={!input.value}
                  inputProps={{
                    ...input,
                    onChange: (e: MouseEvent) => {
                      if (input.value) {
                        setIsPhoneNumberIncorrect(false);
                      } else {
                        setIsPhoneNumberIncorrect(
                          phoneNumberLengthValidator(
                            values[C.NPSSurveyFields.PHONE_NUMBER]
                          )
                        );
                      }
                      input.onChange(e);
                    },
                  }}
                >
                  {intl.formatMessage(messages.checkboxLabel)}
                </S.Checkbox>
              )}
            </Field>
            {values.wasCallbackRequested && (
              <Field name={C.NPSSurveyFields.PHONE_NUMBER}>
                {({ input: { value, onChange } }) => (
                  <>
                    <S.PhoneInput
                      id={C.phoneInputId}
                      label={intl.formatMessage(messages.phoneNumberLabel)}
                      value={value}
                      onChange={(val: string) => {
                        setIsPhoneNumberIncorrect(
                          phoneNumberLengthValidator(val)
                        );
                        onChange(val);
                      }}
                      validationVariant={
                        phoneNumberLengthValidator(value)
                          ? ValidationVariant.INVALID
                          : ValidationVariant.DEFAULT
                      }
                    />
                    {phoneNumberLengthValidator(value) && (
                      <S.ValidationWrapper>
                        <Validation
                          validation={{
                            message: intl.formatMessage(
                              value.length < minPhoneNumberLength
                                ? messages.phoneValidationTooShort
                                : messages.phoneValidationTooLong
                            ),
                          }}
                        />
                      </S.ValidationWrapper>
                    )}
                  </>
                )}
              </Field>
            )}
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default NPSModal;
