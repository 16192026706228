import { LIST_TYPE } from "constants/common";
import React from "react";

import ErrorModal from "../components/organisms/ErrorModal";

const Error = () => {
  return <ErrorModal view={LIST_TYPE.NO_USER_ROLES_ASSIGNED} />;
};

export default Error;
