import { defineMessages } from "react-intl";

export default defineMessages({
  deleteThisAddress: {
    id: "bookingStandalone.route.addressBook.deleteModal.deleteThisAddress",
    defaultMessage: "Delete this Address?",
  },
  cancel: {
    id: "bookingStandalone.route.addressBook.deleteModal.cancel",
    defaultMessage: "Cancel",
  },
  delete: {
    id: "bookingStandalone.route.addressBook.deleteModal.delete",
    defaultMessage: "Delete",
  },
});
