import * as C from "./constants";

export const makeABookingReducer = (
  state: C.MakeABookingState = C.initialState,
  action: C.MakeABookingAction
): C.MakeABookingState => {
  switch (action.type) {
    case C.MakeABookingActionType.SET_STAGE_VALIDATION_STATUS: {
      return {
        ...state,
        stagesValidationStatus: {
          ...state.stagesValidationStatus,
          [action.pageName]: action.isValid,
        },
      };
    }
    case C.MakeABookingActionType.SET_STAGE_INVALID_SECTIONS: {
      if (!action.invalidSections.length) {
        const { [action.pageName]: _pageToRemoved, ...restStagesSections } =
          state.invalidStagesSections;

        return {
          ...state,
          invalidStagesSections: restStagesSections,
        };
      }

      return {
        ...state,
        invalidStagesSections: {
          ...state.invalidStagesSections,
          [action.pageName]: action.invalidSections,
        },
      };
    }
    case C.MakeABookingActionType.ENABLE_FORMS_ERRORS_VISIBILITY: {
      return {
        ...state,
        areFormsValidationErrorsVisible: true,
      };
    }
    case C.MakeABookingActionType.REINITIALIZE_MAKE_A_BOOKING_STATE:
      return {
        ...C.initialState,
        ...action.newState,
        invalidStagesSections: {
          ...action.newState.invalidStagesSections,
          ...state.invalidStagesSections,
        },
      };
    case C.MakeABookingActionType.CLEAR_STATE:
      return C.initialState;
    case C.MakeABookingActionType.SET_CURRENT_STEP:
      return action.currentStep === state.currentStep
        ? state
        : { ...state, currentStep: action.currentStep };
    case C.MakeABookingActionType.SET_STAGE_VISITED_STATUS:
      return {
        ...state,
        stagesVisitedStatus: {
          ...state.stagesVisitedStatus,
          [action.pageName]: action.isVisited,
        },
      };
    case C.MakeABookingActionType.SET_TERMS_AND_CONDITIONS_STATE:
      return {
        ...state,
        isTermsAndConditionsAccepted: action.isAccepted,
      };
    default:
      return state;
  }
};
