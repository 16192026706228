import PropTypes from "prop-types";
import styled from "styled-components";
import { getRem } from "../../utils/css";

const Gap = styled.span`
  display: inline-block;
  padding-left: ${(p) => p.gap};
`;

Gap.defaultProps = {
  gap: getRem(10),
};

Gap.propTypes = {
  gap: PropTypes.string,
};

export default Gap;
