import PropTypes from "prop-types";
import React from "react";
import { Color } from "react-lib";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getRem } from "../../../../utils/css";

const SideBarItemWrapper = styled(({ userMenu, isListItem, ...rest }) => (
  <Link {...rest} />
))`
  display: block;
  width: inherit;
  line-height: ${getRem(24)};
  overflow: hidden;
  bottom: 0;
  cursor: pointer;
  padding: ${(p) => (p.isListItem ? getRem(20) : getRem(15))} ${getRem(4)}
    ${(p) => (p.isListItem ? getRem(20) : getRem(15))} ${getRem(24)};
  label {
    display: inline-block;
    padding-left: ${getRem(16)};
    font-size: ${getRem(16)};
    font-weight: ${(props) => (props.userMenu ? "normal" : "bold")};
    cursor: pointer;
    line-height: ${getRem(24)};
    margin: 0;
    color: ${(props) => props.theme.colors.black90};
    vertical-align: bottom;
  }
  &:hover {
    label {
      color: ${(props) => props.theme.colors.hoverRed};
    }

    svg {
      fill: ${Color.dhlRedHover};
    }
  }
  img {
    display: inline-block;
    width: ${getRem(24)};
    height: ${getRem(24)};
    border-radius: 50%;
  }
`;

SideBarItemWrapper.defaultProps = {
  userMenu: false,
};

SideBarItemWrapper.propTypes = {
  userMenu: PropTypes.any,
};
export default SideBarItemWrapper;
