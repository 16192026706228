import { SORT_DIRECTIONS } from "../redux/sort/constants";

export const quotesColumnToFilter = {
  Mode: "product",
  Service: "serviceType",
  CreationDate: ["createdFrom", "createdTo"],
  CustomerReference: "userReference",
  OriginCityCountry: "originLocationName",
  DestinationCityCountry: "destinationLocationName",
  QuoteTotal: undefined,
  QuoteStatus: "status",
  BookingStatus: "booked",
  Housebills: undefined,
  QuoteID: "id",
  Weight: undefined,
  Volume: undefined,
  ExpirationDate: ["expireFrom", "expireTo"],
  OriginCountry: "originCountry",
  DestinationCountry: "destinationCountry",
  Origin: undefined,
  Destination: undefined,
  BillingCountry: "contactCountry",
  Company: "companyName",
  RateType: "ratesType",
  ServiceCharge: undefined,
  ExportCustomsClearance: undefined,
  ImportCustomsClearance: undefined,
  DHLCargoInsuranceDHLShipmentValueProtection: undefined,
  DHLClimateNeutral: undefined,
  Follow: "favorite",
  CreatedBy: "contactEmail",
  EstimatedPickupDate: ["estimatedPickupFrom", "estimatedPickupTo"],
};

export const quotesColumnToSort = {
  Mode: {
    key: "product",
  },
  Service: {
    key: "serviceType",
  },
  CreationDate: {
    key: "creationDate",
  },
  CustomerReference: {
    key: "userReference",
  },
  OriginCityCountry: {
    key: "originCountry",
  },
  DestinationCityCountry: {
    key: "destinationCountry",
  },
  QuoteTotal: {},
  QuoteStatus: {
    key: "status",
    sortDirectionOnFirstClick: SORT_DIRECTIONS.DOWN,
  },
  BookingStatus: {
    key: "booked",
  },
  Housebills: {},
  QuoteID: {
    key: "id",
  },
  Weight: {
    key: "weight",
  },
  Volume: {
    key: "volume",
  },
  ExpirationDate: {
    key: "expirationDate",
  },
  OriginCountry: {},
  DestinationCountry: {},
  Origin: {},
  Destination: {},
  BillingCountry: {
    key: "contactCountry",
  },
  Company: {
    key: "companyName",
  },
  CreatedBy: {
    key: "contactEmail",
  },
  RateType: {
    key: "ratesType",
  },
  ServiceCharge: {},
  ExportCustomsClearance: {},
  ImportCustomsClearance: {},
  DHLCargoInsuranceDHLShipmentValueProtection: {},
  DHLClimateNeutral: {},
  EstimatedPickupDate: {
    key: "estimatedPickupDate",
  },
};

export const quotesColumns = [
  "Follow",
  "Mode",
  "Service",
  "OriginCityCountry",
  "DestinationCityCountry",
  "CreationDate",
  "ExpirationDate",
  "EstimatedPickupDate",
  "QuoteStatus",
  "BookingStatus",
  "QuoteTotal",
  "CustomerReference",
  "QuoteID",
  "Weight",
  "Volume",
  "OriginCountry",
  "DestinationCountry",
  "Origin",
  "Destination",
  "BillingCountry",
  "Company",
  "CreatedBy",
  "RateType",
  "ServiceCharge",
  "ExportCustomsClearance",
  "ImportCustomsClearance",
  "DHLCargoInsuranceDHLShipmentValueProtection",
  "DHLClimateNeutral",
];

export const trackCodesQuotesColumns = {
  Follow: 147,
  Mode: 148,
  Service: 149,
  OriginCityCountry: 150,
  DestinationCityCountry: 151,
  CreationDate: 152,
  EstimatedPickupDate: 152,
  ExpirationDate: 153,
  QuoteStatus: 154,
  BookingStatus: 155,
  QuoteTotal: 156,
  CustomerReference: 157,
  QuoteID: 158,
  Weight: 159,
  Volume: 160,
  OriginCountry: 161,
  DestinationCountry: 162,
  Origin: 163,
  Destination: 164,
  BillingCountry: 165,
  Company: 166,
  RateType: 167,
  ServiceCharge: 168,
  ExportCustomsClearance: 170,
  ImportCustomsClearance: 171,
  DHLCargoInsuranceDHLShipmentValueProtection: 172,
  DHLClimateNeutral: 173,
  CreatedBy: 174,
};

export const getExportColumnName = (columnName) => {
  switch (columnName) {
    case "Follow":
      return "FAVORITE";
    case "Mode":
      return "PRODUCT";
    case "Service":
      return "PRODUCT_NAME";
    case "OriginCityCountry":
      return "ORIGIN";
    case "DestinationCityCountry":
      return "DESTINATION";
    case "CreationDate":
      return "CREATION_DATE";
    case "ExpirationDate":
      return "EXPIRATION_DATE";
    case "QuoteStatus":
      return "STATUS";
    case "BookingStatus":
      return "BOOKED";
    case "QuoteTotal":
      return "QUOTE_TOTAL";
    case "CustomerReference":
      return "REFERENCE";
    case "QuoteID":
      return "QUOTE_ID";
    case "Weight":
      return "WEIGHT";
    case "Volume":
      return "VOLUME";
    case "OriginCountry":
      return "ORIGIN_COUNTRY";
    case "DestinationCountry":
      return "DESTINATION_COUNTRY";
    case "Origin":
      return "ORIGIN_STATION";
    case "Destination":
      return "DESTINATION_STATION";
    case "BillingCountry":
      return "BILLING_COUNTRY";
    case "Company":
      return "COMPANY_NAME";
    case "CreatedBy":
      return "CONTACT_EMAIL";
    case "RateType":
      return "RATES_TYPE";
    case "ServiceCharge":
      return "SERVICE_TOTAL";
    case "ExportCustomsClearance":
      return "EXPORT_CUSTOMS_CLEARANCE";
    case "ImportCustomsClearance":
      return "IMPORT_CUSTOMS_CLEARANCE";
    case "EstimatedPickupDate":
      return "ESTIMATED_PICKUP_DATE";
    case "DHLCargoInsuranceDHLShipmentValueProtection":
      return "CARGO_INSURANCE";
    case "DHLClimateNeutral":
      return "CLIMATE_NEUTRAL";
    default:
      return "";
  }
};

export const filterValues = {
  Service: {
    ECONOMY: "DHL Air Economy",
    CONNECT: "DHL Air Connect",
    PRIORITY: "DHL Air Priority",
    LCL: "DHL Ocean LCL",
    FCL: "DHL Ocean FCL",
  },
};

export const serviceFilter = [
  { key: "ECONOMY", label: "DHL Air Economy", mode: "AFR" },
  { key: "CONNECT", label: "DHL Air Connect", mode: "AFR" },
  { key: "LCL", label: "DHL Ocean LCL", mode: "OFR" },
  { key: "FCL", label: "DHL Ocean FCL", mode: "OFR" },
  { key: "PRIORITY", label: "DHL Air Priority", mode: "AFR" },
  { key: "AFP_PRIORITY", label: "DHL Airfreight Plus Priority", mode: "AFR" },
  { key: "AFP_ECONOMY", label: "DHL Airfreight Plus Economy", mode: "AFR" },
];
