import * as MDM from "constants/masterDataManagement";
import { useMemo } from "react";
import { AddressInputType } from "../constants/bookWorkflow";

export const useIsLocalityBasedCountryCheck = (
  countryCode?: string,
  countryList?: MDM.Country[]
) => {
  return useMemo(() => {
    if (!countryCode || !countryList) return undefined;

    const country = countryList?.find(
      (el: MDM.Country) => el.code === countryCode
    );

    return (
      country?.addressType === AddressInputType.LOCALITY_LOCREG_LIST ||
      country?.addressType === AddressInputType.LOCALITY_MANUAL
    );
  }, [countryCode, countryList]);
};
