import ElementHeading from "components/atoms/BookingElementHeading";
import ElementHeadingHint from "components/atoms/BookingElementHeadingHint";
import FormField from "components/atoms/BookingFormFieldWrapper";
import HalfContainer from "components/atoms/BookingHalfContainer";
import { UserAddressBook } from "components/organisms/AddressBook/AddressBook.constants";
import { useGetDataTestId } from "containers/TestingContext/TestingContext";
import useBreakpoint from "hooks/useBreakpoint";
import { useIsLocalityBasedCountryCheck } from "hooks/useIsLocalityBasedCountryCheck";
import useSortedCountries from "hooks/useSortedCountries";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Field, useField, useForm } from "react-final-form";
import { useIntl } from "react-intl";
import { Size } from "react-lib";
import { Sizes } from "react-lib/es/atoms/input/constants";
import { PartyFormValues } from "routes/MakeABooking/BookingRoute/BookingRoute.constants";
import { useFilteredCountryList } from "routes/MakeABooking/MakeABooking.helpers";
import InputFieldWithValidation from "../fieldsWithValidation/InputFieldWithValidation/InputFieldWithValidation";
import PhoneInputWithValidation from "../fieldsWithValidation/PhoneInputWithValidation/PhoneInputWithValidation";
import SelectFieldWithValidation from "../fieldsWithValidation/SelectFieldWithValidation/SelectFieldWithValidation";
import AddressBook from "./AddressBook/AddressBook";
import * as C from "./StreetAddress.constants";
import * as H from "./StreetAddress.helpers";
import messages from "./StreetAddress.messages";
import * as S from "./StreetAddress.styles";

const StreetAddress: React.FC<C.StreetAddressProps> = ({
  shouldRenderAddressBook,
  areFieldsDisabled = false,
  isCountryHintHidden,
  shouldRenderSearch,
  shouldForceFieldsErrorVisibility = false,
  fieldPrefix,
  onCountryChangeDecorator,
  isForAddressBook = false,
  extraFields,
  referredType,
}) => {
  const { isMobile } = useBreakpoint();
  const form = useForm();
  const referredTypeValues: PartyFormValues | null = referredType
    ? form.getState().values[referredType.toLowerCase()]
    : null;

  const {
    countryFieldName,
    addressLine2,
    addressLine1,
    postalCode,
    city,
    stateProvince,
    companyName,
    contactDetailsName,
    email,
    phone: phoneFieldName,
    preferredAddressInputType,
  } = H.generateFormFields(isForAddressBook, fieldPrefix);
  const intl = useIntl();
  const { input: { value: selectedCountry } = {} } = useField(countryFieldName);
  const { input: { value: selectedPhone } = {} } = useField(phoneFieldName);
  const { input: preferredAddressInputTypeField } = useField(
    preferredAddressInputType
  );

  const {
    unfilteredCountries,
    originCountries,
    destinationCountries,
    billingCountries,
  } = useFilteredCountryList();

  const countryList = H.restrictCountryList(
    originCountries,
    destinationCountries,
    billingCountries,
    unfilteredCountries,
    form,
    fieldPrefix
  );

  const [selectSearchValue, setSelectSearchValue] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchOptions, setSearchOptions] = useState<C.SearchOptionType[]>([]);
  const [isAddressBookModalOpen, setIsAddressBookModalOpen] = useState(false);
  const [
    shouldForceAddressLine1ErrorVisibility,
    setShouldForceAddressLine1ErrorVisibility,
  ] = useState(false);

  const isLocalityBasedAddress = useIsLocalityBasedCountryCheck(
    selectedCountry,
    countryList
  );

  const handleCountryChange = useCallback(
    (val: string) => {
      const country = countryList.find((country) => country.value === val);
      if (country) {
        if (onCountryChangeDecorator) {
          onCountryChangeDecorator(() => {
            setSearchOptions([]);
            H.formUpdateOnCountryChange({
              form,
              fieldPrefix,
              newCountry: country,
              setInputValue,
              setSelectSearchValue,
              setAddressInputTypeField: () =>
                preferredAddressInputTypeField.onChange(country.addressType),
              isForAddressBook,
              isLocalityBasedAddress: !!isLocalityBasedAddress,
            });
          });
        } else {
          setSearchOptions([]);
          H.formUpdateOnCountryChange({
            form,
            fieldPrefix,
            newCountry: country,
            setInputValue,
            setSelectSearchValue,
            setAddressInputTypeField: () =>
              preferredAddressInputTypeField.onChange(country.addressType),
            isForAddressBook,
            isLocalityBasedAddress: !!isLocalityBasedAddress,
          });
        }
      }
    },
    [
      countryList,
      onCountryChangeDecorator,
      form,
      fieldPrefix,
      isForAddressBook,
      preferredAddressInputTypeField,
      isLocalityBasedAddress,
    ]
  );

  const setAddressFromAddressBook = useCallback(
    ({ address, contact }: UserAddressBook) => {
      const companyNameFieldName = `${fieldPrefix}.companyName`;
      const streetFieldName = `${fieldPrefix}.address.addressLine1`;
      const zipFieldName = `${fieldPrefix}.address.postalCode`;
      const cityFieldName = `${fieldPrefix}.address.city`;
      const stateProvinceFieldName = `${fieldPrefix}.address.stateProvince`;
      const emailFieldName = `${fieldPrefix}.contactDetails.email`;
      const phoneFieldName = `${fieldPrefix}.contactDetails.phone`;
      const contactNameFieldName = `${fieldPrefix}.contactDetails.name`;
      const preferredAddressInputType = `${fieldPrefix}.address.preferredAddressInputType`;

      const setField = (fieldName: string, value: string | undefined) => {
        form.change(fieldName, value || "");
      };

      form.batch(() => {
        if (address) {
          const country = countryList.find(
            (el) => el.code === address?.countryCode
          );
          const concatAddress = [address.addressLine1, address.addressLine2]
            .filter((addressPart) => addressPart)
            .join(" ");

          setField(countryFieldName, country?.countryCode);
          setField(preferredAddressInputType, country?.addressType);
          setField(companyNameFieldName, address?.companyName);
          setField(zipFieldName, address?.postalCode);
          setField(cityFieldName, address?.city);
          setField(stateProvinceFieldName, address?.stateProvince);
          setField(streetFieldName, concatAddress);
        }
        if (contact) {
          setField(emailFieldName, contact?.email);
          setField(phoneFieldName, contact?.phone);
          setField(contactNameFieldName, contact?.contactName);
        }
      });
    },
    [fieldPrefix, form, countryFieldName, countryList]
  );

  const handleSelectedAddress = useCallback(
    (addressBookData: UserAddressBook) => {
      const newCountry = countryList.find(
        (el) => el.code === addressBookData.address?.countryCode
      );

      setIsAddressBookModalOpen(false);

      if (
        onCountryChangeDecorator &&
        newCountry?.countryCode !== selectedCountry
      ) {
        onCountryChangeDecorator(() => {
          setAddressFromAddressBook(addressBookData);
        });
      } else {
        setAddressFromAddressBook(addressBookData);
      }
    },
    [
      countryList,
      selectedCountry,
      setIsAddressBookModalOpen,
      setAddressFromAddressBook,
      onCountryChangeDecorator,
    ]
  );

  const handleSearchSelectChange = async (
    value: Partial<C.SearchOptionType>
  ) => {
    setInputValue(value.label || "");
    setSelectSearchValue(value);

    if (value.lookup) {
      const data = await value.lookup();
      H.setAddressValues(data, form, fieldPrefix, isForAddressBook);
      setShouldForceAddressLine1ErrorVisibility(true);
    }
  };

  const searchId = useRef(-1);
  useEffect(
    () => () => {
      searchId.current = -1;
    },
    []
  );

  const handleSearchInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const keyword = e.target.value;

    setInputValue(keyword);

    if (keyword?.length >= C.ADDRESS_SEARCH_MIN_LENGTH) {
      searchId.current += 1;
      const trackingSearchId = searchId.current;
      try {
        setIsSearching(true);
        const results = await H.getGoogleOptions(keyword, selectedCountry);
        if (searchId.current === trackingSearchId) {
          setSearchOptions(results);
          setIsSearching(false);
        }
      } catch (error) {
        if (searchId.current === trackingSearchId) {
          setIsSearching(false);
          // TODO: To be implemented with validations and errors part.
        }
      }
    }
  };

  const { sortedOptions, setCountryInputValue } =
    useSortedCountries(countryList);

  useEffect(() => {
    if (!shouldRenderSearch) {
      setSelectSearchValue({});
      setInputValue("");
    }
  }, [shouldRenderSearch]);

  useEffect(() => {
    if (referredTypeValues !== null) {
      form.change(countryFieldName, referredTypeValues.address?.countryCode);
    }
  }, [countryFieldName, form, referredTypeValues]);

  const getDataTestId = useGetDataTestId();

  return (
    <S.Wrapper data-testid={getDataTestId("StreetAddressWrapper", fieldPrefix)}>
      {shouldRenderAddressBook && (
        <AddressBook
          isAddressBookModalOpen={isAddressBookModalOpen}
          countryList={unfilteredCountries}
          openAddressBookModal={() => setIsAddressBookModalOpen(true)}
          closeAddressBookModal={() => setIsAddressBookModalOpen(false)}
          onAddressConfirm={handleSelectedAddress}
          countriesPossibleToSelect={H.getCountriesCountryCodes(countryList)}
          fieldPrefix={fieldPrefix}
        />
      )}
      <S.StreetAddressWrapper>
        <HalfContainer>
          <ElementHeading>
            {intl.formatMessage(messages.addressHeadline)}
          </ElementHeading>
          <FormField>
            <SelectFieldWithValidation
              name={countryFieldName}
              label={intl.formatMessage(messages.countryLabel)}
              options={sortedOptions}
              size={isMobile ? Size.MEDIUM : Size.LARGE}
              isDisabled={areFieldsDisabled}
              onChange={handleCountryChange}
              onInputChange={setCountryInputValue}
              isValidationVisible={
                shouldForceFieldsErrorVisibility || undefined
              }
              shouldShowErrorMessage={false}
              placeholder={intl.formatMessage(messages.countryPlaceholder)}
              isDefaultSortingDisabled
              isSearchable
            />
            {!selectedCountry && !isCountryHintHidden && (
              <ElementHeadingHint>
                {intl.formatMessage(messages.selectCountryFirstHint)}
              </ElementHeadingHint>
            )}
          </FormField>

          {shouldRenderSearch &&
            H.isGoogleAPILoaded() &&
            !isLocalityBasedAddress && (
              <FormField>
                <Field name={addressLine2}>
                  {({ input }) => (
                    <S.StyledSearchInput
                      isDisabled={areFieldsDisabled || !selectedCountry}
                      name={input.name}
                      menuIsOpen={false}
                      size={isMobile ? "md" : "lg"}
                      width="100%"
                      placeholder={intl.formatMessage(
                        messages.partySearchPlaceholder
                      )}
                      onChange={handleSearchSelectChange}
                      value={
                        referredTypeValues !== null
                          ? referredTypeValues.address?.addressLine2 || ""
                          : selectSearchValue
                      }
                      onInputChange={handleSearchInputChange}
                      inputValue={
                        referredTypeValues !== null
                          ? referredTypeValues.address?.addressLine2 || ""
                          : inputValue
                      }
                      options={searchOptions}
                      optionCategories={false}
                      closeMenuOnSelect
                      isClearable
                      isLoading={isSearching}
                    />
                  )}
                </Field>
              </FormField>
            )}

          <FormField>
            <InputFieldWithValidation
              name={addressLine1}
              label={
                isLocalityBasedAddress
                  ? intl.formatMessage(messages.firstLineOfAddressLabel)
                  : intl.formatMessage(messages.streetLabel)
              }
              size={isMobile ? Sizes.MEDIUM : Sizes.LARGE}
              isDisabled={areFieldsDisabled || !selectedCountry}
              isErrorVisibilityEnforced={
                shouldForceFieldsErrorVisibility ||
                shouldForceAddressLine1ErrorVisibility
              }
              inputProps={
                referredTypeValues !== null
                  ? {
                      value: referredTypeValues.address?.addressLine1 || "",
                    }
                  : undefined
              }
            />
          </FormField>
          <S.InlineFieldsWrapper>
            {!isLocalityBasedAddress && (
              <FormField>
                <S.ZipCodeFieldWithValidation
                  name={postalCode}
                  label={intl.formatMessage(messages.zipLabel)}
                  size={isMobile ? Sizes.MEDIUM : Sizes.LARGE}
                  isDisabled={areFieldsDisabled || !selectedCountry}
                  isErrorVisibilityEnforced={shouldForceFieldsErrorVisibility}
                  inputProps={{
                    ...(referredTypeValues !== null
                      ? {
                          value: referredTypeValues.address?.postalCode || "",
                        }
                      : undefined),
                  }}
                />
              </FormField>
            )}
            <FormField>
              <S.CityFieldWithValidation
                name={city}
                label={intl.formatMessage(messages.cityLabel)}
                size={isMobile ? Sizes.MEDIUM : Sizes.LARGE}
                isDisabled={areFieldsDisabled || !selectedCountry}
                isErrorVisibilityEnforced={shouldForceFieldsErrorVisibility}
                inputProps={{
                  ...(referredTypeValues !== null
                    ? {
                        value: referredTypeValues.address?.city || "",
                      }
                    : undefined),
                }}
              />
            </FormField>
          </S.InlineFieldsWrapper>
          <FormField>
            <InputFieldWithValidation
              name={stateProvince}
              label={intl.formatMessage(messages.statesLabel)}
              size={isMobile ? Sizes.MEDIUM : Sizes.LARGE}
              isDisabled={areFieldsDisabled || !selectedCountry}
              isErrorVisibilityEnforced={shouldForceFieldsErrorVisibility}
              inputProps={{
                ...(referredTypeValues !== null
                  ? {
                      value: referredTypeValues.address?.stateProvince || "",
                    }
                  : undefined),
              }}
            />
          </FormField>
        </HalfContainer>

        <HalfContainer>
          <ElementHeading>
            {intl.formatMessage(messages.contactDetailsHeadline)}
          </ElementHeading>
          <FormField>
            <InputFieldWithValidation
              name={companyName}
              label={intl.formatMessage(messages.companyNameLabel)}
              size={isMobile ? Sizes.MEDIUM : Sizes.LARGE}
              isDisabled={areFieldsDisabled || !selectedCountry}
              isErrorVisibilityEnforced={shouldForceFieldsErrorVisibility}
              inputProps={{
                ...(referredTypeValues !== null
                  ? {
                      value: referredTypeValues.companyName || "",
                    }
                  : undefined),
              }}
            />
          </FormField>

          <FormField>
            <InputFieldWithValidation
              name={contactDetailsName}
              label={intl.formatMessage(messages.nameLabel)}
              size={isMobile ? Sizes.MEDIUM : Sizes.LARGE}
              isDisabled={areFieldsDisabled || !selectedCountry}
              isErrorVisibilityEnforced={shouldForceFieldsErrorVisibility}
              inputProps={{
                ...(referredTypeValues !== null
                  ? {
                      value: referredTypeValues.contactDetails?.name || "",
                    }
                  : undefined),
              }}
            />
          </FormField>

          <FormField>
            <InputFieldWithValidation
              name={email}
              label={intl.formatMessage(messages.emailLabel)}
              size={isMobile ? Sizes.MEDIUM : Sizes.LARGE}
              isDisabled={areFieldsDisabled || !selectedCountry}
              isErrorVisibilityEnforced={shouldForceFieldsErrorVisibility}
              inputProps={{
                ...(referredTypeValues !== null
                  ? {
                      value: referredTypeValues.contactDetails?.email || "",
                    }
                  : undefined),
              }}
            />
          </FormField>

          <FormField>
            <PhoneInputWithValidation
              key={selectedCountry}
              name={phoneFieldName}
              id={`${fieldPrefix}.phone`}
              isDisabled={areFieldsDisabled || !selectedCountry}
              label={intl.formatMessage(messages.phoneNumber)}
              size={isMobile ? Sizes.MEDIUM : Sizes.LARGE}
              isErrorVisibilityEnforced={shouldForceFieldsErrorVisibility}
              country={
                selectedCountry &&
                selectedPhone === H.getPhoneCountryPrefix(selectedCountry)
                  ? selectedCountry
                  : undefined
              }
              value={
                referredTypeValues !== null
                  ? referredTypeValues.contactDetails?.phone || ""
                  : undefined
              }
            />
          </FormField>

          {extraFields &&
            extraFields.map((extraField) => (
              <FormField key={extraField.key}>{extraField}</FormField>
            ))}
        </HalfContainer>
      </S.StreetAddressWrapper>
    </S.Wrapper>
  );
};

export default StreetAddress;
