import {
  BookingStatusUser,
  BookingSummary,
  QueryType,
} from "constants/bookWorkflow";
import { apiDateFormat } from "constants/variables";
import { useQuery } from "@apollo/client";
import { FetchMoreOptions, FetchMoreQueryOptions } from "@apollo/client/core";
import { BookingsListColumn } from "components/organisms/BookingsList/BookingsListHeader/BookingsListHeader.constants";
import { getBookingsListColumnsUniqueValueColumnNames } from "components/organisms/BookingsList/BookingsListRow/BookingsListRow.helpers";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import moment from "moment/moment";
import { GET_BOOKING_LIST } from "queries/AAA/bookWorkflow";
import getPreferenceItemGQL from "queries/AAA/getPreferenceItemGQL";
import { useDispatch, useSelector } from "react-redux";
import { initialState as sortSettingsInitialState } from "redux/sort/reducer";
import { initialState as userSettingsInitialState } from "redux/userSettings/reducer";
import {
  getCorporatePartner,
  getCorporatePartners,
} from "redux/userSettings/selectors";
import get from "utils/get";
import * as C from "./Bookings.constants";

export const convertDate = (date: string) => {
  return moment(new Date(date)).format(apiDateFormat);
};

const getSelectedFilters = (
  userSettings: typeof userSettingsInitialState | undefined,
  bookingsColumns: BookingsListColumn[]
) => {
  return Object.entries(userSettings?.bookingsListFilter || {})?.reduce(
    (filters, column: [string, unknown]) => {
      const columnVal = column[1];
      const bookingListColumn = bookingsColumns.find(
        (bookingsListColumn) =>
          bookingsListColumn.uniqueValueColumnName === column[0]
      );
      const isColumnDateObject = (columnVal: any) =>
        typeof columnVal === "object" &&
        "from" in columnVal &&
        "to" in columnVal;

      if (bookingListColumn) {
        if (bookingListColumn.isFilteringDisabled) {
          return filters;
        }
        if (bookingListColumn?.dataName === "dateCreated") {
          // the application state is in plain js, therefore I check type and enforce type
          if (isColumnDateObject(columnVal)) {
            const columnDate = columnVal as { from: string; to: string };
            return {
              ...filters,
              createdFrom: convertDate(columnDate.from),
              createdTo: convertDate(columnDate.to),
            };
          }
        } else if (bookingListColumn?.dataName === "estimatedPickupDate") {
          if (isColumnDateObject(columnVal)) {
            const columnDate = columnVal as { from: string; to: string };
            return {
              ...filters,
              estimatedPickupFrom: convertDate(columnDate.from),
              estimatedPickupTo: convertDate(columnDate.to),
            };
          }
        } else if (bookingListColumn?.dataName === "dateUpdated") {
          if (isColumnDateObject(columnVal)) {
            const columnDate = columnVal as { from: string; to: string };
            return {
              ...filters,
              updatedFrom: convertDate(columnDate.from),
              updatedTo: convertDate(columnDate.to),
            };
          }
        }
        if (bookingListColumn?.dataName === "dateSent") {
          if (isColumnDateObject(columnVal)) {
            const columnDate = columnVal as { from: string; to: string };
            return {
              ...filters,
              sentFrom: convertDate(columnDate.from),
              sentTo: convertDate(columnDate.to),
            };
          }
        } else if (bookingListColumn?.dataName === "dateSubmitted") {
          if (isColumnDateObject(columnVal)) {
            const columnDate = columnVal as { from: string; to: string };
            return {
              ...filters,
              submittedFrom: convertDate(columnDate.from),
              submittedTo: convertDate(columnDate.to),
            };
          }
        } else if (bookingListColumn?.dataName === "followed") {
          return {
            ...filters,
            followed: true,
          };
        } else if (bookingListColumn?.dataName === "serviceLevel") {
          return {
            ...filters,
            [bookingListColumn.dataName]: columnVal,
          };
        } else {
          return {
            ...filters,
            [bookingListColumn.dataName]: bookingListColumn.multiValue
              ? [columnVal]
              : columnVal,
          };
        }
      }
      return filters;
    },
    {}
  );
};

export const convertToBuckets = (dataValues: { [key: string]: string[] }) => {
  return dataValues
    ? Object.keys(dataValues).map((key) => ({
        name: key,
        bucketItems: dataValues[key].map((itemKey) => ({
          count: 1, // A value is just a singular, always will be one per bucket
          key: itemKey,
          title: itemKey,
          __typename: "BucketItem",
        })),
      }))
    : [];
};

export const getFilterVariables = (
  bookingsStatuses: BookingStatusUser[],
  bookingsColumns: BookingsListColumn[],
  userSettings: typeof userSettingsInitialState,
  sortSettings: typeof sortSettingsInitialState,
  corporatePartner: string,
  offset?: number,
  queryType: QueryType = QueryType.CUSTOMER
) => {
  const selectedFilters = getSelectedFilters(userSettings, bookingsColumns);
  const statusesFromFilters = get(selectedFilters, "statusUser");
  const isSelectedStatusValidForStatusesFromCurrentTab =
    bookingsStatuses?.includes(statusesFromFilters?.[0]);
  const isAnyStatusAlreadySelectedInFilters =
    statusesFromFilters &&
    Array.isArray(statusesFromFilters) &&
    [...statusesFromFilters].length;

  let filterVariables: C.BookingsRequestParams = {
    ...selectedFilters,
    statusUser:
      isAnyStatusAlreadySelectedInFilters &&
      isSelectedStatusValidForStatusesFromCurrentTab
        ? statusesFromFilters
        : bookingsStatuses,
    uniqueValueColumns:
      getBookingsListColumnsUniqueValueColumnNames(bookingsColumns),
    corporatePartner,
    queryType,
  };

  if (sortSettings?.bookingsListSort) {
    filterVariables = {
      ...filterVariables,
      orderBy: sortSettings.bookingsListSort,
      direction: sortSettings.bookingsListSortType,
    };
  }

  if (offset) {
    filterVariables = {
      ...filterVariables,
      offset,
    };
  }

  return filterVariables;
};

export const handleEnterWayPoint = async (
  bookingsStatuses: BookingStatusUser[],
  userSettings: typeof userSettingsInitialState,
  sortSettings: typeof sortSettingsInitialState,
  bookingsLength: number,
  corporatePartner: string,
  bookingsColumns: BookingsListColumn[],
  bookingsFetchMore: (
    fetchMoreOptions: FetchMoreQueryOptions<any> & FetchMoreOptions<any, any>
  ) => void
) => {
  const filterVariables: C.BookingsRequestParams = getFilterVariables(
    bookingsStatuses,
    bookingsColumns,
    userSettings,
    sortSettings,
    corporatePartner,
    bookingsLength
  );

  await bookingsFetchMore({
    variables: { filter: filterVariables },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) return prev;
      const combined = cloneDeep(prev);
      set(combined, "bookingList.bookings", [
        ...get(prev, "bookingList.bookings", []),
        ...get(fetchMoreResult, "bookingList.bookings", []),
      ]);
      return combined;
    },
  });
};

export const useBookings = (
  bookingsStatuses: BookingStatusUser[],
  bookingsColumns: BookingsListColumn[],
  userSettings: typeof userSettingsInitialState,
  sortSettings: typeof sortSettingsInitialState
) => {
  const corporatePartner = useSelector(getCorporatePartner) ?? "";
  const userCorporatePartners = useSelector(getCorporatePartners);
  const filterVariables: C.BookingsRequestParams = getFilterVariables(
    bookingsStatuses,
    bookingsColumns,
    userSettings,
    sortSettings,
    corporatePartner
  );

  const followFilterSelected =
    (
      userSettings?.bookingsListFilter as
        | { [key: string]: string | undefined }
        | undefined
    )?.FOLLOWED === "Follow";

  return useQuery<{
    bookingList?: {
      bookings: BookingSummary[];
      totalResultsNumber: number;
      values: Record<string, string[]>;
    };
  }>(GET_BOOKING_LIST, {
    context: {
      headers: {
        CorporatePartner: corporatePartner,
      },
    },
    errorPolicy: "all",
    fetchPolicy: followFilterSelected ? "cache-and-network" : "cache-first",
    variables: { filter: filterVariables },
    skip: userCorporatePartners?.length === 0,
  });
};

export function useInitializeColumnsOrder(
  tableName: string,
  defaultColumnsList: BookingsListColumn[],
  initializeColumnsAction: (list: BookingsListColumn[]) => {
    payload: BookingsListColumn[];
    type: string;
  }
) {
  const dispatch = useDispatch();

  useQuery(getPreferenceItemGQL, {
    variables: {
      userObjectsFilter: [
        {
          key: `${tableName}Order`,
        },
      ],
    },
    onCompleted: (data) => {
      const userObjectPreference: C.UserObjectPreferenceResponse[] =
        data.getPreferenceItem.userObjects?.[0]?.userObjectPreferences;

      if (userObjectPreference) {
        const orderLookup = new Map(
          userObjectPreference.map(({ key, value }) => [key, Number(value)])
        );
        const getOrder = (column: BookingsListColumn) =>
          orderLookup.get(column.translationKey) ?? 0;
        const sortedColumnsList = defaultColumnsList
          .slice()
          .sort((column1, column2) => getOrder(column1) - getOrder(column2));
        dispatch(initializeColumnsAction(sortedColumnsList));
      }
    },
    fetchPolicy: "no-cache",
  });
}

export const transformDateForExport =
  (baseName: string) =>
  (value: { from: string; to: string }): Array<[string, string]> =>
    [
      [`${baseName}From`, moment(value.from).format(apiDateFormat)],
      [`${baseName}To`, moment(value.to).format(apiDateFormat)],
    ];
