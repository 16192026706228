import gql from "graphql-tag";

const QUOTE_COUNTRIES_LIST = gql`
  query QuoteCountriesListQuery($locale: String!) {
    getQuoteCountries(locale: $locale) {
      label: name
      value: code
      preferredAddressInputType
      isOrigin
      isDestination
      isBilling
      __typename
      _id: code
    }
  }
`;

export const QuoteCountryPreferredAddressInputTypeFragment = gql`
  fragment QuoteCountryPreferredAddressInputType on QuoteCountry {
    preferredAddressInputType
  }
`;

export default QUOTE_COUNTRIES_LIST;
