import Tip from "components/molecules/Tip/Tip1";
import trim from "lodash/trim";
import PropTypes from "prop-types";
import React from "react";
import Highlighter from "react-highlight-words";
import styled from "styled-components";
import { getRem } from "utils/css";

const Wrapper = styled.div`
  background-color: ${(p) => (p.isFocused ? p.theme.colors.black5 : "white")};
  cursor: pointer;
  height: fit-content;
  span {
    font-weight: bold;
  }
  mark {
    font-weight: normal;
    background-color: transparent;
  }
  &:hover {
    background-color: ${(p) => p.theme.colors.black5};
  }
`;
const FilterItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${getRem(14)} 0 ${getRem(14)} ${getRem(14)};
`;

export const maxTitleWidth = 195;

const Title = styled.div`
  max-width: ${maxTitleWidth}px;
  overflow: hidden;
  flex: 0 1 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const Count = styled.div`
  padding-left: ${getRem(5)};
  flex: 1 1 ${getRem(120)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomOption = ({
  innerRef,
  innerProps,
  label,
  data,
  isFocused,
  selectProps,
  ...otherProps
}) => {
  let searchWords = selectProps ? trim(selectProps.inputValue) : "";
  if (searchWords && searchWords.length > 1) {
    searchWords = searchWords.split(" ");
  } else {
    searchWords = [searchWords];
  }
  searchWords = searchWords.filter((item) => !!item);
  return (
    <Wrapper
      isFocused={isFocused}
      ref={innerRef}
      {...innerProps}
      {...otherProps}
    >
      <FilterItem key={label}>
        <Tip
          key={label}
          TipFontSize={16}
          content={<div>{label}</div>}
          fromBucket
          rightOffset={-28}
          topOffset={0}
          fromDropdown
        >
          <Title>
            {selectProps && selectProps.inputValue ? (
              <Highlighter searchWords={searchWords} textToHighlight={label} />
            ) : (
              label
            )}
          </Title>
        </Tip>
        {data.count && <Count> ({data.count})</Count>}
      </FilterItem>
    </Wrapper>
  );
};
CustomOption.defaultProps = {
  selectProps: {},
  data: {},
};
CustomOption.propTypes = {
  selectProps: PropTypes.object,
  data: PropTypes.object,
};

export default CustomOption;
