import React from "react";
import { Field } from "react-final-form";
import { Sizes } from "react-lib/es/atoms/input/constants";
import ValidationVariant from "react-lib/es/constants/ValidationVariant";
import * as S from "./PhoneInputWithValidation.styles";

interface PhoneInputWithValidationProps {
  name: string;
  id: string;
  key: string;
  isDisabled: boolean;
  label: string;
  size?: Sizes;
  isErrorVisibilityEnforced?: boolean;
  country?: string;
  value?: string;
  onChangeCallback?: () => void;
}

const PhoneInputWithValidation = ({
  name,
  id,
  label,
  isDisabled,
  size = Sizes.LARGE,
  isErrorVisibilityEnforced = false,
  country,
  value,
  onChangeCallback,
  key,
}: PhoneInputWithValidationProps): JSX.Element => (
  <Field name={name}>
    {({ input, meta }) => (
      <>
        <S.StyledPhoneInputNew
          {...input}
          inputSize={size}
          isDisabled={isDisabled}
          id={id}
          label={label}
          validationVariant={
            !meta.active &&
            (meta.visited || isErrorVisibilityEnforced) &&
            meta.invalid
              ? ValidationVariant.INVALID
              : ValidationVariant.DEFAULT
          }
          enableSearch
          country={country}
          onChange={(val: string) => {
            input.onChange(val);
            onChangeCallback && onChangeCallback();
          }}
          value={value ?? input.value}
          key={key}
        />
        <S.StyledHint>
          {!meta.active &&
            (meta.visited || isErrorVisibilityEnforced) &&
            meta.error}
        </S.StyledHint>
      </>
    )}
  </Field>
);

export default PhoneInputWithValidation;
