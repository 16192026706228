import styled, { css } from "styled-components";
import { getRem } from "../../../../utils/css";

const ProfileImage = styled.div`
  font-size: ${(p) => getRem(p.size)};
  display: inline-block;
  ${(p) =>
    !p.noMargin
      ? css`
          margin: ${getRem(8)} ${getRem(8)} 0 0;
        `
      : null}
  line-height: ${getRem(40)};
  height: ${getRem(40)};
  width: ${getRem(40)};
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  background: ${(p) =>
    p.isDemoMode
      ? p.isDemoMode && p.isDropdownItem
        ? p.theme.colors.postYellow
        : "black"
      : !p.isDemoMode && p.isDropdownItem
      ? "black"
      : "white"};
  color: ${(p) =>
    p.invert || p.isDemoMode
      ? p.theme.colors.white
      : p.theme.colors.postYellow};
  font-weight: bold;
  :focus {
    outline: none;
  }
  text-transform: uppercase;
`;

export default ProfileImage;
