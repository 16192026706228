import { deviceBreakpointStartPoint } from "react-lib";
import { useMediaQuery } from "react-responsive";

export interface Breakpoint {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

const useBreakpoint = (): Breakpoint => {
  const isMobile = useMediaQuery({
    maxWidth: deviceBreakpointStartPoint.tablet - 1,
  });

  const isTablet = useMediaQuery({
    minWidth: deviceBreakpointStartPoint.tablet,
    maxWidth: deviceBreakpointStartPoint.desktop - 1,
  });

  const isDesktop = useMediaQuery({
    minWidth: deviceBreakpointStartPoint.desktop,
  });

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useBreakpoint;
