import { useSelector } from "react-redux";
import { getLoggedInUserEmail } from "redux/auth/selectors";

const dhlInternalEmailRegex = /(@dhl|@dpdhl)\.com$/;

const useIsInternalUser = (): boolean => {
  const userEmail = useSelector(getLoggedInUserEmail);

  return !!userEmail && dhlInternalEmailRegex.test(userEmail);
};

export default useIsInternalUser;
