import type { Location } from "history";

export const pageLoadEvent = "dhl_utf_pageload";
export const dataLayerObjectKey = "dhl_utf_dataLayer";
export const pageNamePrefix = "path:";

export const internalEmailDomains = ["dhl.com", "dpdhl.com"];
export const sensitiveDataSearchParams = ["session_state", "code"];

export enum UserStatus {
  LOGGED_IN = "logged-in",
  LOGGED_OUT = "logged-out",
}

export enum UserType {
  ANONYMOUS = "anonymous",
  INTERNAL = "internal",
  EXTERNAL = "external",
}

export enum FindingMethod {
  MENU = "Menu",
  NONE = "None",
}

export enum RootPath {
  DASHBOARD = "/",
  SHIPMENTS = "shipments",
  QUOTES = "quotes",
  ANALYTICS = "analytics",
  REPORTING = "reporting",
  SETTINGS = "settings",
  BSA = "make-a-booking",
}

type RootPathToPageNameMapperType = { [key in RootPath]: string };

export const RootPathToPageNameMapper: RootPathToPageNameMapperType = {
  [RootPath.DASHBOARD]: "Dashboard",
  [RootPath.SHIPMENTS]: "Shipments",
  [RootPath.QUOTES]: "Quote",
  [RootPath.ANALYTICS]: "Analytics",
  [RootPath.REPORTING]: "Reports",
  [RootPath.SETTINGS]: "Settings",
  [RootPath.BSA]: "BookingStandalone",
};

export const BsaRoutes = [
  "mode",
  "route",
  "cargo",
  "service",
  "billing",
  "finalize",
];

export type NestedPathsType =
  | RootPath.ANALYTICS
  | RootPath.REPORTING
  | RootPath.SETTINGS;

type NestedPathsForRootPathsType = {
  [key in NestedPathsType]: string[];
};

export const NestedPathsForRootPaths: NestedPathsForRootPathsType = {
  [RootPath.ANALYTICS]: [
    "spend",
    "volume",
    "store",
    "customs-activity",
    "service-quality",
    "detailed-reports",
    "cost-per-unit",
    "transit-time",
  ],
  [RootPath.REPORTING]: ["your-reports", "archive"],
  [RootPath.SETTINGS]: ["notifications", "account", "portal"],
};

export type LocationState = {
  analyticsFindingMethodMenu?: FindingMethod.MENU;
};

export type LocationObject = Location<LocationState>;
