import SERVICES from "constants/services";
import { startsWithAnyOf } from "./string";

const getActiveService = (path) => {
  const isQuoteRoute = startsWithAnyOf(path?.toLowerCase(), [
    "/get-a-quote",
    "/offers",
    "/booking/",
    "/terms-and-conditions",
    "/continue-with-quote",
  ]);
  return isQuoteRoute ? SERVICES.OQT : SERVICES.PLATFORM;
};

export default getActiveService;
