import { defineMessages } from "react-intl";

export default defineMessages({
  newAddress: {
    id: "bookingStandalone.addressBook.modal.newAddress",
    defaultMessage: "New Address",
  },
  addYourAddresses: {
    id: "bookingStandalone.addressBook.addYourAddresses",
    defaultMessage: "Add your addresses",
  },
  addYourAddressesExplanation: {
    id: "bookingStandalone.addressBook.addYourAddressesExplanation",
    defaultMessage:
      "Use the address book to keep all your addresses accessible for future bookings.",
  },
});
