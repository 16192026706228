import getConfigClient from "constants/getConfigClient";
import { AnalyticsInteractions } from "adobe-analytics/content-interaction-events/analytics-spent/AnalyticsSpentEvents.constants";
import get from "lodash/get";

export const getTrackCode = (label) => {
  switch (label.toLowerCase()) {
    case "spend":
      return 56;
    case "volume":
      return 57;
    case "service quality":
      return 58;
    case "customs activity":
      return 59;
    case "cost per unit":
      return 1000;
    case "detailed reports":
      return 1001;
    default:
      return 0;
  }
};

export const getTabs = (items) => {
  return items.map((item) => ({
    text: get(item, "header"),
    name: get(item, "mainReference.name") || "undefined",
    route: "/" + get(item, "mainReference.name", "undefined"),
    widgetUrl: get(item, "widgetReference.url", "undefined"),
    mashUpUrl: get(item, "mainReference.url"),
    appID: get(item, "widgetReference.applicationUuid", "undefined"),
    sheetID: get(item, "widgetReference.sheetUuid", "undefined"),
    trackCode: { outMapView: getTrackCode(item.header) },
    translationKey: item.translationKey,
  }));
};

export const getMashupLink = (data, uid, langcode) => {
  const paramMashUp = {
    uid,
    langcode,
    mun: data.name,
  };
  return (
    data.mashUpUrl &&
    `${getConfigClient(
      "KEYCLOAK_URL"
    )}auth?scope=openid+web-origins&response_type=code&redirect_uri=${
      data.mashUpUrl
    }?param=${btoa(JSON.stringify(paramMashUp))}&client_id=myDHLi`
  );
};

export const getIframeSrcAndMashupLink = (data, uid, langcode) => {
  const paramsIframeSrc = {
    uid,
    langcode,
    aid: data.appID,
    sid: data.sheetID,
  };

  const src = `${getConfigClient(
    "KEYCLOAK_URL"
  )}auth?scope=openid+web-origins&response_type=code&redirect_uri=${
    data.widgetUrl
  }?param=${btoa(JSON.stringify(paramsIframeSrc))}&client_id=myDHLi`;

  const link = getMashupLink(data, uid, langcode);

  return { src, link };
};

const tabNamesWithEventTracking = {
  SPEND: "spend",
  VOLUME: "volume",
  SERVICE_QUALITY: "service-quality",
  CUSTOMS_ACTIVITY: "customs-activity",
};

export const getAnalyticsEventForTab = (tabName) => {
  switch (tabName) {
    case tabNamesWithEventTracking.SPEND:
      return AnalyticsInteractions.SPEND;
    case tabNamesWithEventTracking.VOLUME:
      return AnalyticsInteractions.VOLUME;
    case tabNamesWithEventTracking.SERVICE_QUALITY:
      return AnalyticsInteractions.SERVICE_QUALITY;
    case tabNamesWithEventTracking.CUSTOMS_ACTIVITY:
      return AnalyticsInteractions.CUSTOMS_ACTIVITY;
    default:
      return null;
  }
};
