import { defineMessages } from "react-intl";

export default defineMessages({
  delete: {
    id: "bookingStandalone.addressBook.modal.delete",
    defaultMessage: "Delete",
  },
  cancel: {
    id: "bookingStandalone.addressBook.modal.cancel",
    defaultMessage: "Cancel",
  },
  save: {
    id: "bookingStandalone.addressBook.modal.save",
    defaultMessage: "Save",
  },
});
