import {
  FavoriteItemType,
  FavoriteUpdateFunctionType,
} from "constants/favorites";
import { InternalRefetchQueriesInclude, useMutation } from "@apollo/client";
import { FAVORITE_ITEM, UNFAVORITE_ITEM } from "queries/AAA/favoriteQueries";
import { StarFilled, StarOutlined } from "./FavoriteButton.styles";

export const getFavoriteIcon = (isIconFilled?: boolean | null) =>
  isIconFilled ? StarFilled : StarOutlined;

export const useFavorite = (
  id: string | undefined,
  itemType: FavoriteItemType,
  onAdditionComplete?: () => void,
  onRemovalComplete?: () => void,
  setIsFollowedState?: (value: boolean) => void,
  onUpdate?: FavoriteUpdateFunctionType,
  refetchQueries?: InternalRefetchQueriesInclude
) => {
  const [addToFavorite, { loading: isDuringAddition }] = useMutation(
    FAVORITE_ITEM,
    {
      variables: {
        item: {
          key: id,
          type: itemType,
        },
      },
      onCompleted: (data) => {
        onAdditionComplete?.();
        setIsFollowedState?.(data.favorite);
      },
      update: (cache, { data }, { variables }) => {
        if (variables) {
          onUpdate?.(cache, data, variables);
        }
      },
      refetchQueries,
    }
  );

  const [removeFromFavorite, { loading: isDuringRemoval }] = useMutation(
    UNFAVORITE_ITEM,
    {
      variables: {
        item: {
          key: id,
          type: itemType,
        },
      },
      onCompleted: (data) => {
        onRemovalComplete?.();
        setIsFollowedState?.(!data.unFavorite);
      },
      update: (cache, { data }, { variables }) => {
        if (variables) {
          onUpdate?.(cache, data, variables);
        }
      },
      refetchQueries,
    }
  );

  return {
    addToFavorite,
    removeFromFavorite,
    isDuringAddition,
    isDuringRemoval,
  };
};
