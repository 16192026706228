import isInputEmail from "isemail";
import {
  CustomValidator,
  ErrorReport,
  ValidationError,
  ValidationErrorItem,
} from "joi";
import { IntlShape } from "react-intl";
import { messages } from "routes/BookingPage/validatorsMessages";

enum CustomErrorCode {
  EMAIL = "custom.email",
  ALPHA_ONLY = "custom.alphaOnly",
  NUMBER_LENGTH_MAX = "number.lengthMax",
  NUMBER_PRECISION = "number.precision",
}

export enum ValidationErrorCode {
  ANY_REQUIRED = "any.required",
  STRING_BASE = "string.base",
  STRING_EMPTY = "string.empty",
  NUMBER_BASE = "number.base",
  STRING_MIN = "string.min",
  STRING_MAX = "string.max",
  NUMBER_MIN = "number.min",
  NUMBER_MAX = "number.max",
  NUMBER_GREATER = "number.greater",
  NUMBER_POSITIVE = "number.positive",
  NUMBER_INTEGER = "number.integer",
  NUMBER_INFINITY = "number.infinity",
}

export const validateEmail: CustomValidator<string> = (value, helpers) => {
  const isValid = !isInputEmail.validate(value || "", {
    errorLevel: true,
    minDomainAtoms: 2,
  });

  return isValid ? value : helpers.error(CustomErrorCode.EMAIL);
};

export const validateNumberLengthMax =
  (limit: number): CustomValidator<number> =>
  (value, helpers) => {
    const isValid = String(value).length <= limit;

    return isValid
      ? value
      : helpers.error(CustomErrorCode.NUMBER_LENGTH_MAX, { limit });
  };

export const validateNumberPrecision =
  (limit: number): CustomValidator<number> =>
  (value, helpers) => {
    const decimalsCount = String(value)?.split(".")?.[1]?.length;
    const isValid = !decimalsCount || decimalsCount <= limit;

    return isValid
      ? value
      : helpers.error(CustomErrorCode.NUMBER_PRECISION, { limit });
  };

const getErrorMessage = (intl: IntlShape, error: ErrorReport): string => {
  switch (error.code) {
    case ValidationErrorCode.ANY_REQUIRED:
    case ValidationErrorCode.STRING_BASE:
    case ValidationErrorCode.STRING_EMPTY:
    case ValidationErrorCode.NUMBER_BASE:
      return intl.formatMessage(messages.required);

    case ValidationErrorCode.STRING_MIN:
      return intl.formatMessage(messages.validationMinCharacters, {
        char: error.local.limit,
      });

    case ValidationErrorCode.STRING_MAX:
      return intl.formatMessage(messages.validationCharacters, {
        char: error.local.limit,
      });

    case ValidationErrorCode.NUMBER_MIN:
      return intl.formatMessage(messages.numberMin, {
        limit: error.local.limit,
      });

    case ValidationErrorCode.NUMBER_MAX:
      return intl.formatMessage(messages.numberMax, {
        limit: error.local.limit,
      });

    case ValidationErrorCode.NUMBER_GREATER:
      return intl.formatMessage(messages.numberGreater, {
        limit: error.local.limit,
      });

    case ValidationErrorCode.NUMBER_POSITIVE:
      return intl.formatMessage(messages.positiveValuesOnly);

    case ValidationErrorCode.NUMBER_INTEGER:
      return intl.formatMessage(messages.onlyIntegerAllowed);

    case ValidationErrorCode.NUMBER_INFINITY:
      return intl.formatMessage(messages.infinityReached);

    case CustomErrorCode.EMAIL:
      return intl.formatMessage(messages.validateEmail);

    case CustomErrorCode.ALPHA_ONLY:
      return intl.formatMessage(messages.alphaCharactersOnly);

    case CustomErrorCode.NUMBER_LENGTH_MAX:
      return intl.formatMessage(messages.numberTooLong, {
        limit: error.local.limit,
      });

    case CustomErrorCode.NUMBER_PRECISION:
      return intl.formatMessage(messages.numberPrecision, {
        limit: error.local.limit,
      });

    default:
      return "";
  }
};

export const getDefaultJoiErrorFunction =
  (
    intl: IntlShape,
    getCustomMessages?: (intl: IntlShape, error: ErrorReport) => string
  ) =>
  (errors: ErrorReport[]) => {
    const message = getErrorMessage(intl, errors[0]);

    const details = errors.map(
      (error): ValidationErrorItem => ({
        message:
          getErrorMessage(intl, error) ||
          getCustomMessages?.(intl, error) ||
          error.toString(),
        type: error.code,
        context: error.local,
        path: error.path,
      })
    );

    return new ValidationError(message, details, errors);
  };
