// Adding a route here will skip redirection to keycloak after auth token expires.
const unprotectedRoutes: string[] = [
  "/get-a-quote",
  "/offers",
  "/booking/",
  "/subscriptions/unsubscribe",
  "/continue-with-quote",
];

const isCurrentPathUnprotected = (path: string) =>
  unprotectedRoutes.some((route) => path.includes(route));

export default isCurrentPathUnprotected;
