import { DownloadState } from "constants/DownloadState";
import TopBar from "components/TopBar/TopBar";
import React, { useEffect, useState } from "react";
import { downloadFile } from "redux/download/saga";
import DownloadStatus from "./DownloadStatus/DownloadStatus";

interface FileDownloadViewProps {
  fileId: string;
  fileDownloadEndpoint: string;
  fileDownloadAdditionalParams?: string;
  className?: string;
}

const FileDownloadView: React.FC<FileDownloadViewProps> = ({
  className,
  fileId,
  fileDownloadEndpoint,
  fileDownloadAdditionalParams,
}) => {
  const [downloadState, setDownloadState] = useState<DownloadState>(
    DownloadState.GETTING
  );
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (fileId) {
      downloadFile(
        fileId,
        fileDownloadEndpoint,
        undefined,
        setDownloadState,
        setPercentage,
        fileDownloadAdditionalParams
      );
    }
  }, [fileId, fileDownloadEndpoint, fileDownloadAdditionalParams]);

  return (
    <div className={className}>
      <TopBar isAvatarVisible={false} />
      <DownloadStatus
        downloadState={downloadState}
        barFillPercentage={percentage}
      />
    </div>
  );
};

export default FileDownloadView;
