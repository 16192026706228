import { dateTableColumnSize } from "constants/columns";

const quotesColumns = {
  Follow: {
    sortable: false,
    customSize: 2.6,
    removable: false,
    visible: true,
    isDropdown: true,
  },
  Mode: {
    sortable: true,
    customSize: 2.2,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  Service: {
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  CreationDate: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  CustomerReference: {
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  OriginCityCountry: {
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  DestinationCityCountry: {
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  QuoteTotal: {
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  QuoteStatus: {
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  BookingStatus: {
    sortable: true,
    customSize: 3,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  Housebills: {
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  QuoteID: {
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  Weight: {
    sortable: true,
    customSize: 8,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  Volume: {
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  ExpirationDate: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  OriginCountry: {
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  DestinationCountry: {
    sortable: true,
    customSize: 10,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  Origin: {
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  Destination: {
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  BillingCountry: {
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  Company: {
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  CreatedBy: {
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  RateType: {
    sortable: true,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: true,
  },
  ServiceCharge: {
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  ExportCustomsClearance: {
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  ImportCustomsClearance: {
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  DHLCargoInsuranceDHLShipmentValueProtection: {
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  DHLClimateNeutral: {
    sortable: false,
    customSize: 6,
    removable: true,
    visible: true,
    isDropdown: false,
  },
  EstimatedPickupDate: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: true,
    visible: true,
    isDropdown: true,
  },
};

export default quotesColumns;
