import PropTypes from "prop-types";
import React from "react";
import { chooseElementBasedOnItem } from "../FlyOut.helpers";
import { FlyOutItemWrapper } from "../styles";

const FlyOutItem = ({ className, item, onClick }) => {
  const elementBasedOnItem = chooseElementBasedOnItem(item);

  return (
    <FlyOutItemWrapper
      className={className}
      as={elementBasedOnItem}
      target={item.shouldOpenInNewTab ? "_blank" : undefined}
      href={item.shouldOpenInNewTab ? item.route : undefined}
      to={item.shouldOpenInNewTab ? undefined : item.route}
      onClick={() => {
        item.onClick?.();
        onClick?.();
      }}
      data-track={item.trackCode}
    >
      {item.text}
    </FlyOutItemWrapper>
  );
};

FlyOutItem.defaultProps = {
  item: {
    text: "My Account",
    onClick: () => {},
  },
};

FlyOutItem.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    shouldOpenInNewTab: PropTypes.bool,
    route: PropTypes.string,
    trackCode: PropTypes.number,
  }),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default FlyOutItem;
