import { defineMessages } from "react-intl";

export default defineMessages({
  addressBookText: {
    id: "bookingStandalone.drafts.addressBookText",
    defaultMessage: "Address book",
  },
  exportListText: {
    id: "bookingStandalone.drafts.exportListText",
    defaultMessage: "Export List",
  },
  startNewBookingText: {
    id: "bookingStandalone.drafts.startNewBookingText",
    defaultMessage: "Start new booking",
  },
  loadMoreButton: {
    id: "portal.bookings.loadMoreButton",
    defaultMessage: "Load More",
  },
  paginationInfo: {
    id: "portal.bookings.pagination.info",
    defaultMessage: "{bookings} of {total} Bookings",
  },
  allLoaded: {
    id: "portal.bookings.allLoaded",
    defaultMessage:
      "All {total, plural, one {# booking} other {# bookings}} loaded.",
  },
  undefinedInformation: {
    id: "portal.bookings.undefinedInformation",
    defaultMessage: "Undefined",
  },
});
