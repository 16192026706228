const linkLocaleLookup: Record<string, string> = {
  fr: "fr",
  es: "es",
  es_419: "es",
  zh: "zh",
  zh_Hant: "hk-zh",
  de: "de",
  ko: "ko",
  pl: "pl",
  pt: "pt",
  cs: "cs",
  nl_BE: "nl-be",
  et: "et",
  el: "el",
  it: "it",
  ja: "ja",
  lv: "lv",
  lt: "lt",
  no: "no",
  ro: "ro",
  sr: "sr",
  sl: "sl",
  tr: "tr",
  uk: "uk",
  vi: "vi",
  hr: "hr",
};

export default linkLocaleLookup;
