const baseType = "DASHBOARD";

const types = {
  ADD_WIDGET_LOADED: `${baseType}/ADD_WIDGET_LOADED`,
  SET_WIDGETS_UPDATE: `${baseType}/SET_WIDGETS_UPDATE`,
  SET_SINGLE_SHIPMENT_CONTAINER_DETAILS: `${baseType}/SET_SINGLE_SHIPMENT_CONTAINER_DETAILS`,
  RESET_SINGLE_SHIPMENT_CONTAINER_DETAILS: `${baseType}/RESET_SINGLE_SHIPMENT_CONTAINER_DETAILS`,
  SET_CLICKED_MARKER: `${baseType}/SET_CLICKED_MARKER`,
  SET_FULLSCREEN_MODE: `${baseType}/SET_FULLSCREEN_MODE`,
  SET_SIDEBAR_OPEN: `${baseType}/SET_SIDEBAR_OPEN`,
  SET_DASHBOARD_ZOOM: `${baseType}/SET_DASHBOARD_ZOOM`,
  SET_DASHBOARD_CENTER: `${baseType}/SET_DASHBOARD_CENTER`,
  SET_NUMBER_ELEMENTS_LIST: `${baseType}/SET_NUMBER_ELEMENTS_LIST`,
  SET_ACTIVE_MARKER: `${baseType}/SET_ACTIVE_MARKER`,
  SET_MAP_TRANSPORT_MODE: `${baseType}/SET_MAP_TRANSPORT_MODE`,
};

export default types;
