/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";

const useObjectMemo = (object) => {
  return useMemo(
    () => object,
    [...Object.keys(object), ...Object.values(object)]
  );
};

export default useObjectMemo;
