import styled from "styled-components";
import { getRem } from "../../utils/css";

const PageTitle = styled.div`
  display: flex;
  margin: ${getRem(20)} 0;
  h1 {
    padding-top: ${getRem(2)};
    margin-left: ${getRem(10)};
    font-size: ${getRem(20)};
    font-weight: 900;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-transform: uppercase;
  }
  button {
    margin-top: ${getRem(-4)};
    margin-left: ${getRem(30)};
  }
`;

export default PageTitle;
