import types from "./action-types";

export const setMapSearchText = (text) => ({
  type: types.SET_MAP_SEARCH,
  payload: { text },
});

export const clearMapSearch = () => ({
  type: types.SET_MAP_SEARCH,
  payload: { text: "" },
});

export const showMapSearchResult = () => ({
  type: types.SHOW_HIDE_MAP_SEARCH_RESULT,
  payload: { state: true },
});

export const hideMapSearchResult = (text) => ({
  type: types.SHOW_HIDE_MAP_SEARCH_RESULT,
  payload: { state: false },
});

export const expandMapSearchResult = (text) => ({
  type: types.EXPAND_COLLAPSE_MAP_SEARCH_RESULT,
  payload: { state: true },
});

export const collapseMapSearchResult = (text) => ({
  type: types.EXPAND_COLLAPSE_MAP_SEARCH_RESULT,
  payload: { state: false },
});
