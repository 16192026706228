import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "SaveYourSelectionInteractions";

export const SaveYourSelectionInteractions = {
  PRIVATE_CUSTOMER: {
    id: `${prefix}_Private Customer`,
    name: "Private Customer",
  },
  BUSINESS_CUSTOMER: {
    id: `${prefix}_Business Customer`,
    name: "Business Customer",
  },
  MARKETING: { id: `${prefix}_Marketing`, name: "Marketing" },
  CONTINUE: { id: `${prefix}_Continue`, name: "Continue" },
};

const saveYourSelectionPosition = "Header";

const saveYourSelectionContext = "Save your Selection";

export const saveYourSelectionEventNameToEventContentMapper: C.EventNameToContentMapperType<
  typeof SaveYourSelectionInteractions
> = {
  [SaveYourSelectionInteractions.PRIVATE_CUSTOMER.id]: {
    name: SaveYourSelectionInteractions.PRIVATE_CUSTOMER.name,
    ...eventContentButtonInterObj,
    position: saveYourSelectionPosition,
    context: saveYourSelectionContext,
  },
  [SaveYourSelectionInteractions.BUSINESS_CUSTOMER.id]: {
    name: SaveYourSelectionInteractions.BUSINESS_CUSTOMER.name,
    ...eventContentButtonInterObj,
    position: saveYourSelectionPosition,
    context: saveYourSelectionContext,
  },
  [SaveYourSelectionInteractions.MARKETING.id]: {
    name: SaveYourSelectionInteractions.MARKETING.name,
    ...eventContentButtonInterObj,
    position: saveYourSelectionPosition,
    context: saveYourSelectionContext,
  },
  [SaveYourSelectionInteractions.CONTINUE.id]: {
    name: SaveYourSelectionInteractions.CONTINUE.name,
    ...eventContentButtonInterObj,
    position: saveYourSelectionPosition,
    context: saveYourSelectionContext,
  },
};
