import gql from "graphql-tag";

export const getRecalculatedQuoteOption = gql`
  query GetRecalculatedQuoteOption(
    $inputQuote: InputQuote
    $routeSwitched: Boolean
    $corporatePartner: String!
  ) {
    getRecalculatedQuoteOption(
      routeSwitched: $routeSwitched
      inputQuote: $inputQuote
      corporatePartner: $corporatePartner
    ) {
      calculationDetailsKey
      carrier {
        description
      }
      quoteType
      ratesType
      ratesSource
      product
      serviceType
      freeTimeDetails {
        origin {
          demurrage
          detention
          combined
          unit
        }
        destination {
          demurrage
          detention
          combined
          unit
        }
      }
      transitTimeDetails {
        pickup
        mainCarriage
        delivery
      }
      paymentMethods
      pickupOptions
      deliveryOptions
      validity
      commodityCode
      routeDetails {
        id
        originStation {
          code
          name
          gateway {
            code
            name
          }
          countryCode
          zone
          locality
        }
        destinationStation {
          code
          name
          gateway {
            code
            name
          }
          countryCode
          zone
          locality
        }
      }
      totalTransitTime
      currency
      includedCharges
      emissions {
        pickup {
          tankToWheel
          wellToWheel
        }
        mainCarriage {
          tankToWheel
          wellToWheel
        }
        delivery {
          tankToWheel
          wellToWheel
        }
      }
      charges {
        pickup
        origin
        mainCarriage
        destination
        delivery
        importCustomsClearance
        exportCustomsClearance
        cargoInsurance
        climateNeutral
        total
      }
      promoValue
      serviceSettings {
        pickup
        pickupOption
        delivery
        deliveryOption
        importCustomsClearance
        exportCustomsClearance
        customsClearanceLines
        insurance
        climateNeutral
        insuranceNotice
        goodsValue
        goodsValueMax
        goodsValueCurrency
        shippingTerms
        incoterms
        freeLinesNumber
      }
      availableShippingTerms
      availablePickupDates
      estimatedPickupDate
      estimatedDeliveryDate
      originalRequest
    }
  }
`;
