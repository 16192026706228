import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "DocumentsInteractions";

export const DocumentsInteractions = {
  DOWNLOAD: { id: `${prefix}_Download`, name: "Download" },
  UPLOAD: { id: `${prefix}_Upload`, name: "Upload" },
  GO_TO_BILLING: { id: `${prefix}_Go to Billing`, name: "Go to Billing" },
};

const documentsEventPosition = "Download";

const documentsContext = "Documents";
export const documentsEventNameToDocumentsEventContentMapper: C.EventNameToContentMapperType<
  typeof DocumentsInteractions
> = {
  [DocumentsInteractions.DOWNLOAD.id]: {
    name: DocumentsInteractions.DOWNLOAD.name,
    ...eventContentButtonInterObj,
    position: documentsEventPosition,
    context: documentsContext,
  },
  [DocumentsInteractions.UPLOAD.id]: {
    name: DocumentsInteractions.UPLOAD.name,
    ...eventContentButtonInterObj,
    position: documentsEventPosition,
    context: documentsContext,
  },
  [DocumentsInteractions.GO_TO_BILLING.id]: {
    name: DocumentsInteractions.GO_TO_BILLING.name,
    ...eventContentButtonInterObj,
    position: documentsEventPosition,
    context: documentsContext,
  },
};
