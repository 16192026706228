import { useMutation } from "@apollo/client";
import { AddressBookSuccessfulAction } from "components/organisms/StreetAddress/AddressBook/AddressModal/components/AddressBookSuccessToast/AddressBookSuccessToast.constants";
import { generateFormFields } from "components/organisms/StreetAddress/AddressForm/AddressForm.helpers";
import { deleteAddressBookCustomerAddressMutation } from "queries/MakeABooking/userAddressBookQueries";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useForm } from "react-final-form";
import { useIntl } from "react-intl";
import { NewButton } from "react-lib";
import {
  ButtonVariants,
  ButtonSize,
} from "react-lib/es/atoms/buttons2/Button.constants";
import messages from "./BottomButtons.messages";
import * as S from "./BottomButtons.styles";

interface BottomButtonsProps {
  handleSubmit: () => void;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  onAddressBookAddressDelete: () => void;
  areButtonsBlocked: boolean;
  isDeleteButtonVisible: boolean;
  openSuccessToast: Dispatch<
    SetStateAction<AddressBookSuccessfulAction | null>
  >;
  openSuccessToastMessage: SetStateAction<AddressBookSuccessfulAction | null>;
}

export const BottomButtons: FC<BottomButtonsProps> = ({
  handleSubmit,
  setIsModalOpen,
  onAddressBookAddressDelete,
  areButtonsBlocked,
  isDeleteButtonVisible,
  openSuccessToast,
  openSuccessToastMessage,
}) => {
  const form = useForm();
  const isAddressFormValid = !Object.keys(form?.getState().errors || {}).length;
  const intl = useIntl();
  const { addressId: addressIdField } = generateFormFields(true, "");
  const addressId = form.getFieldState(addressIdField)?.value;

  const [deleteAddressBookCustomerAddress] = useMutation(
    deleteAddressBookCustomerAddressMutation
  );

  const deleteAddress = () => {
    if (!addressId) {
      setIsModalOpen(false);
      return;
    }
    deleteAddressBookCustomerAddress({
      variables: {
        addressId,
      },
    }).then(() => {
      form.reset({});
      onAddressBookAddressDelete();
      openSuccessToast(openSuccessToastMessage);
      setIsModalOpen(false);
    });
  };
  const submit = () => {
    handleSubmit();
  };

  return (
    <S.ButtonsRow>
      {isDeleteButtonVisible && (
        <S.DeleteButton onClick={deleteAddress} disabled={areButtonsBlocked}>
          {intl.formatMessage(messages.delete)}
        </S.DeleteButton>
      )}
      <S.RightButtonsContainer>
        <NewButton
          variant={ButtonVariants.SECONDARY}
          size={ButtonSize.LARGE}
          onClick={() => setIsModalOpen(false)}
          disabled={areButtonsBlocked}
        >
          {intl.formatMessage(messages.cancel)}
        </NewButton>
        <S.SaveButton
          disabled={!isAddressFormValid || areButtonsBlocked}
          variant={ButtonVariants.PRIMARY}
          size={ButtonSize.LARGE}
          onClick={submit}
        >
          {intl.formatMessage(messages.save)}
        </S.SaveButton>
      </S.RightButtonsContainer>
    </S.ButtonsRow>
  );
};
