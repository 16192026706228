import types from "./action-types.js";

const initialState = {
  updating: [],
  updatingValue: {},
  getting: [],
  paging: [],
  data: {},
  posting: [],
  postingValue: {},
  error: {},
  deleting: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.put:
      return {
        ...state,
        updating: [...state.updating, action.payload.key],
        updatingValue: {
          ...state.updatingValue,
          [action.payload.key]: action.payload.data,
        },
      };

    case types.putSuccess:
    case types.putError:
      return {
        ...state,
        updating: state.updating.filter((item) => item !== action.payload.key),
        updatingValue: {
          ...state.updatingValue,
          [action.payload.key]: undefined,
        },
      };

    case types.get:
      return {
        ...state,
        getting: [...state.getting, action.payload.key],
      };

    case types.getSuccess:
      return {
        ...state,
        getting: state.getting.filter((item) => item !== action.payload.key),
        data: { ...state.data, [action.payload.key]: action.payload.data },
      };

    case types.getError:
      return {
        ...state,
        getting: state.getting.filter((item) => item !== action.payload.key),
        data: { ...state.data, [action.payload.key]: undefined },
      };

    case types.page:
      return {
        ...state,
        paging: [...state.paging, action.payload.key],
      };

    case types.pageSuccess:
      return {
        ...state,
        paging: state.paging.filter((item) => item !== action.payload.key),
        data: {
          ...state.data,
          [action.payload.key]: action.merger(
            state.data[action.payload.key],
            action.payload.data
          ),
        },
      };

    case types.pageError:
      return {
        ...state,
        paging: state.paging.filter((item) => item !== action.payload.key),
        data: { ...state.data },
      };

    case types.post:
      return {
        ...state,
        posting: [...state.posting, action.payload.key],
        postingValue: {
          ...state.postingValue,
          [action.payload.key]: action.payload.data,
        },
        error: {
          ...state.error,
          [action.payload.key]: undefined,
        },
        data: {
          ...state.data,
          [action.payload.key]: undefined,
        },
      };
    case types.postSuccess:
      return {
        ...state,
        posting: state.posting.filter((item) => item !== action.payload.key),
        postingValue: {
          ...state.postingValue,
          [action.payload.key]: undefined,
        },
        error: {
          ...state.error,
          [action.payload.key]: undefined,
        },
        data: {
          ...state.data,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.postError:
      return {
        ...state,
        posting: state.posting.filter((item) => item !== action.payload.key),
        postingValue: {
          ...state.postingValue,
          [action.payload.key]: undefined,
        },
        error: {
          ...state.error,
          [action.payload.key]: action.payload.error,
        },
        data: {
          ...state.data,
          [action.payload.key]: undefined,
        },
      };

    case types.deleteType:
      return {
        ...state,
        deleting: [...state.deleting, action.payload.key],
        error: {
          ...state.error,
          [action.payload.key]: undefined,
        },
        data: {
          ...state.data,
          [action.payload.key]: undefined,
        },
      };
    case types.deleteSuccess:
      return {
        ...state,
        deleting: state.deleting.filter((item) => item !== action.payload.key),
        error: {
          ...state.error,
          [action.payload.key]: undefined,
        },
        data: {
          ...state.data,
          [action.payload.key]: action.payload.data,
        },
      };
    case types.deleteError:
      return {
        ...state,
        deleting: state.deleting.filter((item) => item !== action.payload.key),
        error: {
          ...state.error,
          [action.payload.key]: action.payload.error,
        },
        data: {
          ...state.data,
          [action.payload.key]: undefined,
        },
      };
    case types.clearData:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: undefined,
        },
      };

    default:
      return { ...state };
  }
};
