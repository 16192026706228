import { useLazyQuery, useQuery } from "@apollo/client";
import Loading from "components/molecules/Loader/Loading";
import Modal from "components/molecules/Modal";
import PropTypes from "prop-types";
import { GET_USER_DETAILS } from "queries/AAA/userQueries";
import { acceptTermsOfUse, getTermsOfUse } from "queries/QnB/getTermsOfUse";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Waypoint } from "react-waypoint";
import { logOut } from "redux/auth/actions";
import { updateLatestTCAccepted } from "redux/userSettings/actions";
import styled from "styled-components";
import { getRem } from "utils/css";
import TermsOfUseButtons from "./termsOfUseButtons";
import TermsOfUseText from "./termsOfUseText";

const Wrapper = styled.div`
  padding: ${getRem(40)} ${(p) => (p.belowLg ? getRem(24) : getRem(60))};
`;

const TermsAndConditions = ({ areTermsAccepted, belowLg, ...props }) => {
  const intl = useIntl();
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [termsOfUse, setTermsOfUse] = useState(null);
  const termsOfUseRef = useRef();

  const { data: user } = useQuery(GET_USER_DETAILS);
  useEffect(() => {
    if (user) {
      props.updateLatestTCAccepted(user?.user?.latestTCAccepted);
    }
    // eslint-disable-next-line
  }, [user]);
  useQuery(getTermsOfUse, {
    onCompleted(data) {
      setTermsOfUse(data.getTermsOfUse);
    },
  });
  const [acceptTerms] = useLazyQuery(acceptTermsOfUse, {
    onCompleted({ acceptTermsOfUse: { accepted } }) {
      props.updateLatestTCAccepted(accepted);
    },
  });

  const handleDecline = () => {
    props.logOut();
  };

  const handleAccept = () => {
    acceptTerms({
      variables: { id: termsOfUse?.id },
    });
  };

  const handleEnterWayPoint = () => {
    setIsButtonDisable(false);
  };

  if (areTermsAccepted) return null;

  return (
    <Modal
      key="terms-and-conditions-modal"
      onClose={handleDecline}
      showCloseButtonInHeader={false}
      title="myDHLi"
    >
      <Wrapper belowLg={belowLg}>
        <div ref={termsOfUseRef}>
          {!termsOfUse ? (
            <Loading />
          ) : (
            <>
              <TermsOfUseText
                text={
                  intl.messages[termsOfUse.translationKey]
                    ? intl.formatMessage({ id: termsOfUse.translationKey })
                    : termsOfUse?.text
                }
              />
              <Waypoint onEnter={() => handleEnterWayPoint()} />
            </>
          )}
        </div>
      </Wrapper>
      {termsOfUse && (
        <TermsOfUseButtons
          belowLg={belowLg}
          onAccept={handleAccept}
          onDecline={handleDecline}
          isButtonDisable={isButtonDisable}
        />
      )}
    </Modal>
  );
};

TermsAndConditions.propTypes = {
  belowLg: PropTypes.bool,
};
TermsAndConditions.defaultProps = {
  belowLg: false,
};

export default connect(
  (state) => ({ areTermsAccepted: state.userSettings.latestTCAccepted }),
  {
    logOut,
    updateLatestTCAccepted,
  }
)(TermsAndConditions);
