import React from "react";

const StarIcon = ({ filled }) => {
  return (
    <svg viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        d={
          filled
            ? "M12 18l-4.672 2.456a1 1 0 0 1-1.451-1.054l.892-5.202-3.78-3.685a1 1 0 0 1 .555-1.706l5.223-.759 2.336-4.733a1 1 0 0 1 1.794 0l2.336 4.733 5.223.76a1 1 0 0 1 .555 1.705L17.23 14.2l.892 5.202a1 1 0 0 1-1.45 1.054L12 18z"
            : "M15.62 13.676l3.618-3.528-5.001-.727L12 4.89 9.763 9.421l-5.001.727 3.619 3.528-.854 4.981L12 16.305l4.473 2.352-.854-4.981zM12 18l-4.672 2.456a1 1 0 0 1-1.451-1.054l.892-5.202-3.78-3.685a1 1 0 0 1 .555-1.706l5.223-.759 2.336-4.733a1 1 0 0 1 1.794 0l2.336 4.733 5.223.76a1 1 0 0 1 .555 1.705L17.23 14.2l.892 5.202a1 1 0 0 1-1.45 1.054L12 18z"
        }
      />
    </svg>
  );
};

export default StarIcon;
