import React from "react";

const Customs = () => {
  return (
    <svg viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        d="M12.286 17.04v1.012l-.037.195-.055.175-.083.157-.125.14-.157.12-.157.087-.194.056-.208.018H6.477l-.213-.018-.19-.056-.157-.088-.157-.12-.125-.139-.087-.157-.051-.175-.037-.195V17.04H4.448l-.195-.037-.175-.05-.157-.088-.14-.125-.124-.157-.088-.157-.05-.195-.019-.208V11.23l.018-.194.051-.194.088-.17.125-.144.139-.12.157-.088.175-.07.195-.018H5.46v6.808h6.826zm.592-6.808h4.761l-4.303 5.459v-3.693L9.73 16.532l-.124.139-.139.087-.157.051-.139.019-.157-.019-.143-.05-.139-.088-.125-.088-.101-.139-.088-.139-.051-.143-.037-.176v-.157l.018-.175.07-.171.106-.157 3.795-4.798.125-.139.138-.088.157-.05.139-.019zm4.95-2.431l-.014.176-.018.175-.051.171-.055.176-.088.157-.088.143-.102.139-.125.139-.12.106-.143.106-.157.088-.157.088-.158.05-.175.052-.176.018-.19.018-.193-.018-.176-.018-.176-.051-.157-.051-.157-.088-.157-.088-.139-.106-.125-.106-.12-.14-.106-.138-.088-.143-.07-.157-.069-.176-.037-.17-.032-.176-.018-.176v-.194h-.855l.855-.855L13.317 5h4.511v2.8zM20.158 19h-6.651v-1.96l2.662-3.17 2.87-3.638H19.215l.19.019.175.069.157.088.139.12.124.143.088.171.051.194.019.194V19z"
      />
    </svg>
  );
};

export default Customs;
