const types = {
  put: "put",
  putSuccess: "putSuccess",
  putError: "putError",

  page: "page",
  pageSuccess: "pageSuccess",
  pageError: "pageError",

  get: "get",
  getSuccess: "getSuccess",
  getError: "getError",

  post: "post",
  postSuccess: "postSuccess",
  postError: "postError",

  deleteType: "deleteType", // Special naming cuz JS has "delete" as a reserved keyword and don't wanna mess with that!
  deleteSuccess: "deleteSuccess",
  deleteError: "deleteError",

  clearData: "clearData",
};

export default types;
