import React from "react";

export enum AddressBookModalContent {
  ADDRESS_BOOK_LIST_MODAL = "address_book_list_modal",
  ADD_ADDRESS_MODAL = "add_address_modal",
  EDIT_ADDRESS_MODAL = "edit_address_modal",
}

export interface AddressModalProps {
  isModalOpen: boolean;
  className?: string;
  children: React.ReactNode;
}

export const addressBookModalWidth = "80rem";
