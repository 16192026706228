import { ShipmentMode, ShipmentsByCurrentPosition } from "constants/shipments";
import { Marker } from "components/MapProvider/MapProvider.constants";

export const WidgetUpdateTypes = {
  SHIPMENT: "SHIPMENT",
  QUOTE: "QUOTE",
  LAST_VIEWED_SHIPMENTS: "LAST_VIEWED_SHIPMENTS",
};

export type DashboardMapMarker = ShipmentsByCurrentPosition & Marker;

export enum MapSelectAdditionalOptions {
  ALL = "ALL",
}

export type ShipmentsByModeMapSelectOptions =
  | MapSelectAdditionalOptions
  | ShipmentMode;
