import {
  Button,
  SIZES as BUTTON_SIZES,
  VARIANTS as BUTTON_VARIANTS,
} from "components/atoms/Buttons/ButtonDHL";
import Col from "components/atoms/Col";
import React from "react";
import styled, { css } from "styled-components";
import { getRem, getRemMedia } from "utils/css";

const ButtonsRow = styled.div`
  ${(p) =>
    p.isButtonDisable
      ? css`
          box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.07),
            0 0 2px 0 rgba(0, 0, 0, 0.1);
        `
      : null};
  background: white;
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.07), 0 0 2px 0 rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0;
  padding: ${getRem(24)} ${(p) => (p.belowLg ? getRem(24) : getRem(60))};
  height: 88px;
  width: 100%;
  left: 0;

  @media (min-width: ${getRemMedia(768.2)}) {
    width: ${getRem(880)};
    left: 50%;
    margin-left: ${getRem(-440)};
    justify-content: flex-end;
  }

  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`;

const AcceptButtonCol = styled(Col)`
  @media (min-width: ${getRemMedia(1024.2)}) {
    display: flex;
    justify-content: flex-end;
  }
  & {
    padding-right: 0;
  }
`;

const DeclineButtonCol = styled(Col)`
  margin-bottom: ${getRem(24)};

  @media (min-width: ${getRemMedia(1024.2)}) {
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
  & {
    padding-left: 0;
  }
`;

const DeclineButton = styled(Button)`
  border: 0;
  color: black;
`;

const TermsOfUseButtons = ({
  belowLg,
  onDecline,
  onAccept,
  isButtonDisable,
}) => (
  <ButtonsRow isButtonDisable={isButtonDisable} belowLg={belowLg}>
    <DeclineButtonCol col={belowLg ? undefined : 3}>
      <DeclineButton
        variant={BUTTON_VARIANTS.SECONDARY}
        onClick={onDecline}
        size={BUTTON_SIZES.SMALL}
        isBlock
      >
        Decline
      </DeclineButton>
    </DeclineButtonCol>
    <AcceptButtonCol col={belowLg ? undefined : 4}>
      <Button
        type={BUTTON_VARIANTS.SUBMIT}
        onClick={onAccept}
        disabled={isButtonDisable}
        size={BUTTON_SIZES.SMALL}
        isBlock
      >
        Accept Terms
      </Button>
    </AcceptButtonCol>
  </ButtonsRow>
);

export default TermsOfUseButtons;
