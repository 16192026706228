import { useQuery } from "@apollo/client";
import SideBarUserItems from "components/organisms/SideBar/Partials/SideBarUserItems";
import { GET_USER_DETAILS } from "queries/AAA/userQueries";
import React from "react";
import { useSelector } from "react-redux";

const SideBarMobileOnlyItems = (props) => {
  const isDemo = useSelector(
    (state) => state.userSettings.visibilityScope?.demo
  );
  const { data: userDetails } = useQuery(GET_USER_DETAILS);
  const nameInitials = userDetails?.user?.nameInitials || "";
  const nameInitialsOrDemo = isDemo ? "DEMO" : nameInitials;
  return (
    <div>
      <SideBarUserItems
        isDemo={isDemo}
        nameInitials={nameInitialsOrDemo}
        currentItemIndex={props.currentItemIndexUser}
      />
    </div>
  );
};

export default SideBarMobileOnlyItems;
