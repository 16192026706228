import { Color } from "react-lib";
import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled from "styled-components";

const BookingElementHeading = styled.h4`
  margin: 0;
  padding: 0 0 0.3125rem 0;
  font-weight: bold;
  line-height: 1.5;
  color: ${Color.webBlack};

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    margin-top: 0.5rem;
  }
`;

export default BookingElementHeading;
