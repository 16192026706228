import gql from "graphql-tag";

export const USER_ADDRESS_BOOK_FILTERS_QUERY = gql`
  query UserAddressBookFiltersQuery(
    $filters: [UserAddressBookFilter]
    $corporatePartnerUUID: String
  ) {
    getUserAddressBookFilters(
      filters: $filters
      corporatePartnerUUID: $corporatePartnerUUID
    ) {
      column
      values
    }
  }
`;

export const USER_ADDRESS_BOOK_NEW_FILTERS_QUERY = gql`
  query UserAddressBookNewFiltersQuery(
    $filters: [UserNewAddressBookFilter]
    $corporatePartnerUUID: String
  ) {
    getUserAddressBookNewFilters(
      filters: $filters
      corporate_partner_uuid: $corporatePartnerUUID
    ) {
      column
      values
    }
  }
`;
