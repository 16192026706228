import { AddressInputType } from "constants/bookWorkflow";
import {
  getDefaultJoiErrorFunction,
  validateEmail,
} from "helpers/joiValidation";
import joi from "joi";
import { IntlShape } from "react-intl";
import { AddressBookFormSchema } from "./AddressBookFormFields/AddressBookFormFields.constants";

export interface AddressBookFormProps {
  onBackArrowClick: () => void;
  onFormSubmit: (formValues: AddressBookFormSchema) => void;
  onCancel: () => void;
  onDelete?: () => void;
  isDisabled: boolean;
  isDeletable?: boolean;
  formInitialValues?: AddressBookFormSchema;
  formHeader: string;
}

const addressTypeSchema = joi.object({
  isBilling: joi.boolean().required(),
  isConsignee: joi.boolean().required(),
  isDelivery: joi.boolean().required(),
  isPickup: joi.boolean().required(),
  isShipper: joi.boolean().required(),
  isNotify: joi.boolean().required(),
});

const zipValidationSchema = joi.when("preferredAddressInputType", {
  is: joi.valid(
    AddressInputType.LOCALITY_LOCREG_LIST,
    AddressInputType.LOCALITY_MANUAL
  ),
  then: joi.string().optional().allow(null, ""),
  otherwise: joi.string().max(10).required(),
});

const addressValidationSchema = joi.object({
  preferredAddressInputType: joi.any(),
  country: joi.object().required(),
  street: joi.string().allow(null, ""),
  streetAddress: joi.string().max(100).required(),
  zip: zipValidationSchema,
  city: joi.string().max(50).required(),
  states: joi.string().max(25).optional().allow(null, ""),
});

const contactDetailsValidationSchema = joi.object({
  companyName: joi.string().max(100).required(),
  contactName: joi.string().max(256).required(),
  email: joi.string().max(254).custom(validateEmail).required(),
  phoneNumber: joi.string().min(7).required(),
});

const addressBookValidationSchema = joi.object({
  addressType: addressTypeSchema.required(),
  address: addressValidationSchema.required(),
  contactDetails: contactDetailsValidationSchema.required(),
});

export const getAddressBookFormSchema = (intl: IntlShape) =>
  joi
    .object({
      addressBook: addressBookValidationSchema.required(),
    })
    .error(getDefaultJoiErrorFunction(intl))
    .options({ abortEarly: false, allowUnknown: true });
