import * as C from "./constants";

const initialState: C.QuoteDataState = {
  offerOptions: [],
  selectedOffer: {},
  quote: {},
  updatingConsignment: false,
  updateConsignmentSucceeded: false,
  updateConsignmentCompleted: false,
  submittingConsignment: false,
  submitConsignmentSucceeded: false,
  submitConsignmentCompleted: false,
  submitConsignmentFailed: false,
  successData: {},
  pickupDate: undefined,
};

const reducer = (
  state = initialState,
  action: C.QuoteDataActions
): C.QuoteDataState => {
  switch (action.type) {
    case C.ActionsType.SAVE_OFFER_OPTIONS:
      return {
        ...state,
        offerOptions: [...action.payload],
      };

    case C.ActionsType.SAVE_OFFER: {
      const offerOptions = [...state.offerOptions];
      const indexOffer = state.offerOptions.findIndex(
        (x) =>
          action.payload.calculationDetailsKey ===
          x.selectedQuoteOption.calculationDetailsKey
      );
      offerOptions[indexOffer] = {
        routes: { ...state?.offerOptions?.[indexOffer]?.routes },
        selectedQuoteOption: { ...action.payload },
      };
      return {
        ...state,
        offerOptions: [...offerOptions],
      };
    }
    case C.ActionsType.SET_SELECTED_OFFER:
      return {
        ...state,
        selectedOffer: { ...action.payload },
      };
    case C.ActionsType.SAVE_QUOTE_FORM:
      return {
        ...state,
        quote: action.payload,
      };
    case C.ActionsType.SAVE_CONSIGNMENT_API:
      return {
        ...state,
        updatingConsignment: true,
        updateConsignmentCompleted: false,
        updateConsignmentSucceeded: false,
      };

    case C.ActionsType.SAVE_CONSIGNMENT_API_SUCCEEDED:
      return {
        ...state,
        updateConsignmentSucceeded: true,
      };
    case C.ActionsType.SAVE_CONSIGNMENT_API_COMPLETED:
      return {
        ...state,
        updateConsignmentCompleted: true,
        updatingConsignment: false,
      };
    case C.ActionsType.SUBMIT_CONSIGNMENT:
      return {
        ...state,
        submittingConsignment: true,
        submitConsignmentCompleted: false,
        submitConsignmentSucceeded: false,
      };
    case C.ActionsType.SUBMIT_CONSIGNMENT_RESET:
      return {
        ...state,
        submittingConsignment: false,
        submitConsignmentSucceeded: false,
        submitConsignmentCompleted: false,
        submitConsignmentFailed: false,
      };
    case C.ActionsType.SUBMIT_CONSIGNMENT_SUCCEEDED:
      return {
        ...state,
        successData: action.payload,
        submitConsignmentSucceeded: true,
        submitConsignmentFailed: false,
      };
    case C.ActionsType.SUBMIT_CONSIGNMENT_COMPLETED:
      return {
        ...state,
        submitConsignmentCompleted: true,
        submittingConsignment: false,
      };
    case C.ActionsType.SUBMIT_CONSIGNMENT_FAILED:
      return {
        ...state,
        submitConsignmentSucceeded: false,
        submitConsignmentFailed: true,
      };
    case C.ActionsType.RESET_DATA:
      return initialState;
    case C.ActionsType.SET_PICKUP_DATE:
      return {
        ...state,
        pickupDate: action.payload.pickupDate,
      };
    case C.ActionsType.RESET_PICKUP_DATE:
      return {
        ...state,
        pickupDate: undefined,
      };
    default:
      return state;
  }
};

export default reducer;
