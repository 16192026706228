import * as MDM from "constants/masterDataManagement";
import { useMutation, useQuery } from "@apollo/client";
import { AddressBookSuccessfulAction } from "components/organisms/StreetAddress/AddressBook/AddressModal/components/AddressBookSuccessToast/AddressBookSuccessToast.constants";
import {
  AddressBookCustomerAddressesResponse,
  AddressFormValues,
} from "components/organisms/StreetAddress/AddressForm/AddressForm.constants";
import { generateFormFields } from "components/organisms/StreetAddress/AddressForm/AddressForm.helpers";
import arrayMutators from "final-form-arrays";
import { createFormValidator } from "helpers/bookWorkflow";
import useCorporatePartnerUUID from "hooks/useCorporatePartnerUUID";
import moment from "moment";
import { GET_COUNTRIES } from "queries/AAA/masterDataManagement";
import {
  GetAddressBookCustomerAddressQuery,
  addNewAddressContactMutation,
  createNewAddressBookCustomerAddressMutation,
  deleteAddressContactMutation,
  editAddressBookCustomerAddressMutation,
  editAddressBookCustomerAddressVisibilityMutation,
  editAddressContactMutation,
} from "queries/MakeABooking/userAddressBookQueries";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Field, Form } from "react-final-form";
import { useIntl } from "react-intl";
import SvgPleaseContact from "react-lib/es/icons/SvgPleaseContact";
import * as C from "./AddOrEditAddressForm.constants";
import * as H from "./AddOrEditAddressForm.helpers";
import messages from "./AddOrEditAddressForm.messages";
import * as S from "./AddOrEditAddressForm.styles";
import { getAddressBookFormSchema } from "./AddOrEditAddressForm.validations";
import { AddressSection } from "./AddressSection/AddressSection";
import { BottomButtons } from "./BottomButtons/BottomButtons";
import { ContactSection } from "./ContactSection/ContactSection";

interface AddOrEditAddressFormProps {
  addressData: AddressFormValues | null;
  addressDataId?: string;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  scrollToLastContactOnModalOpen?: boolean;
  onAddressBookAddressDelete: () => void;
  setSelectedAddressId: Dispatch<SetStateAction<string | null>>;
  selectedCountry?: string;
  onFormWithWrongCountrySubmit: () => void;
  openSuccessToast: Dispatch<
    SetStateAction<AddressBookSuccessfulAction | null>
  >;
  openSuccessToastMessage: SetStateAction<AddressBookSuccessfulAction | null>;
}

export const AddOrEditAddressForm: FC<AddOrEditAddressFormProps> = ({
  addressData,
  addressDataId,
  setIsModalOpen,
  scrollToLastContactOnModalOpen,
  onAddressBookAddressDelete,
  setSelectedAddressId,
  selectedCountry,
  onFormWithWrongCountrySubmit,
  openSuccessToast,
  openSuccessToastMessage,
}) => {
  const intl = useIntl();
  const { addressId: addressIdField } = generateFormFields(true, "");
  const corporatePartner = useCorporatePartnerUUID() || "";
  const addressId = addressDataId || addressData?.addressBook.address.addressId;
  const [isAddressChanged, setIsAddressChanged] = useState(false);
  const {
    data: currentAddressData,
    loading: currentAddressLoading,
    refetch: refetchAddressBookCustomerAddress,
  } = useQuery<AddressBookCustomerAddressesResponse>(
    GetAddressBookCustomerAddressQuery,
    {
      errorPolicy: "all",
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      variables: {
        addressId: Number(addressId),
      },
      skip: !addressId,
    }
  );
  const isAddressVerified =
    !!currentAddressData?.getAddressBookCustomerAddress?.address.is_validated;

  const { data: countriesData } =
    useQuery<{ getCountries: MDM.Country[] }>(GET_COUNTRIES);

  useEffect(() => {
    if (addressId) {
      refetchAddressBookCustomerAddress();
    }
  }, [addressId, refetchAddressBookCustomerAddress]);

  const formInitialData = useMemo(
    () =>
      H.formatInitialData(
        addressData,
        currentAddressData,
        countriesData?.getCountries
      ) ?? C.initialFormValues,
    [addressData, currentAddressData, countriesData]
  );

  const [
    createNewAddressBookCustomerAddress,
    { loading: createAddressLoading },
  ] = useMutation<C.AddAddressResponse>(
    createNewAddressBookCustomerAddressMutation
  );
  const [editAddressBookCustomerAddress, { loading: editAddressLoading }] =
    useMutation(editAddressBookCustomerAddressMutation);

  const editAddress = (formValues: AddressFormValues) => {
    const val = formValues.addressBook.address;
    const shouldEditAddress = isAddressChanged || !val.isCustomerVisible;
    return (
      shouldEditAddress &&
      editAddressBookCustomerAddress({
        variables: {
          address: {
            address_id: val.addressId,
            address: H.prepareAddressData(formValues, corporatePartner),
            modified_at: moment
              .utc(val.modifiedAt || val.createdAt)
              .format("YYYY-MM-DD HH:mm:ss"),
          },
        },
      })
    );
  };

  const [addNewAddressContact, { loading: addNewContactLoading }] = useMutation(
    addNewAddressContactMutation
  );
  const [editAddressContact, { loading: editContactLoading }] = useMutation(
    editAddressContactMutation
  );
  const [deleteAddressContact, { loading: deleteContactLoading }] = useMutation(
    deleteAddressContactMutation
  );
  const [
    editAddressBookCustomerAddressVisibility,
    { loading: editVisibilityLoading },
  ] = useMutation(editAddressBookCustomerAddressVisibilityMutation);

  const isLoading =
    createAddressLoading ||
    editAddressLoading ||
    addNewContactLoading ||
    editContactLoading ||
    deleteContactLoading ||
    currentAddressLoading ||
    editVisibilityLoading;

  const getContactRequests = (values: AddressFormValues) =>
    values.addressBook.contacts
      .filter(
        (contactSection) =>
          contactSection?.actionType !== C.SectionActionType.NONE
      )
      .map((contactDetails) => {
        switch (contactDetails.actionType) {
          case C.SectionActionType.ADD:
            return addNewAddressContact({
              variables: {
                newContact: {
                  address_id: Number(values.addressBook.address.addressId),
                  contact: H.prepareContactData(contactDetails),
                },
              },
            });
          case C.SectionActionType.EDIT:
            return editAddressContact({
              variables: {
                contact: {
                  contact_id: Number(contactDetails.contactId),
                  contact: H.prepareContactData(contactDetails),
                  modified_at: moment
                    .utc(contactDetails.modifiedAt || contactDetails.createdAt)
                    .format("YYYY-MM-DD HH:mm:ss"),
                },
              },
            });
          case C.SectionActionType.REMOVE:
            return deleteAddressContact({
              variables: {
                contactId: contactDetails.contactId,
              },
            });
          default:
            return null;
        }
      });

  const editIsCustomerVisible = async (formValues: AddressFormValues) => {
    if (!formValues.addressBook.address.isCustomerVisible) {
      await editAddressBookCustomerAddressVisibility({
        variables: { addressBookId: addressDataId, visibility: true },
      });
    }
  };

  const onFormSubmit = async (formValues: AddressFormValues) => {
    const val = formValues.addressBook.address;
    if (val.createdAt && addressDataId) {
      const response = await Promise.allSettled([
        editAddress(formValues),
        ...getContactRequests(formValues),
      ]);
      await editIsCustomerVisible(formValues);

      if (response.some((res) => res.status === "rejected")) {
        refetchAddressBookCustomerAddress();
      } else {
        if (val.country.code !== selectedCountry) {
          setSelectedAddressId(null);
          onFormWithWrongCountrySubmit();
        }
        openSuccessToast(openSuccessToastMessage);
        setIsModalOpen(false);
      }
    } else {
      createNewAddressBookCustomerAddress({
        variables: {
          newAddress: {
            address: H.prepareAddressData(formValues, corporatePartner),
            contacts: H.filterContactData(formValues.addressBook.contacts)?.map(
              (contact) => H.prepareContactData(contact)
            ),
          },
        },
      }).then((val) => {
        if (formValues.addressBook.address.country.code === selectedCountry) {
          setSelectedAddressId(
            val.data?.createNewAddressBookCustomerAddress.address_id.toString() ||
              null
          );
        }
        openSuccessToast(openSuccessToastMessage);
        setIsModalOpen(false);
      });
    }
  };

  const validate = useMemo(
    () => createFormValidator(getAddressBookFormSchema(intl)),
    [intl]
  );

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={formInitialData}
      mutators={{ ...arrayMutators }}
      validate={validate}
    >
      {({ handleSubmit }) => (
        <S.Container>
          <S.StyledModalHeader onClose={() => setIsModalOpen(false)}>
            <S.StyledSVGWrapper>
              <SvgPleaseContact />
            </S.StyledSVGWrapper>
            {intl.formatMessage(messages.title)}
          </S.StyledModalHeader>
          <S.ModalContent>
            <S.AddressIdRow areChildrenVisible={!!addressId}>
              <Field name={addressIdField}>
                {({ input: { value: addressId } }) => (
                  <S.AddressId>
                    {intl.formatMessage(messages.addressId, {
                      id: addressId ?? "",
                    })}
                  </S.AddressId>
                )}
              </Field>
            </S.AddressIdRow>

            <S.FormContainer>
              <AddressSection
                isAddressVerified={isAddressVerified}
                isDataLoading={isLoading}
                onAnyAddressChange={() => setIsAddressChanged(true)}
              />
              <S.SectionDivider />
              <ContactSection
                scrollToLastContactOnModalOpen={scrollToLastContactOnModalOpen}
                areFieldsDisabled={isLoading}
              />
            </S.FormContainer>
          </S.ModalContent>
          <S.FullWidthDivider />
          <BottomButtons
            handleSubmit={handleSubmit}
            setIsModalOpen={setIsModalOpen}
            onAddressBookAddressDelete={onAddressBookAddressDelete}
            areButtonsBlocked={isLoading}
            isDeleteButtonVisible={!!addressId}
            openSuccessToast={openSuccessToast}
            openSuccessToastMessage={
              AddressBookSuccessfulAction.DELETE_ADDRESS_SUCCESS
            }
          />
        </S.Container>
      )}
    </Form>
  );
};
