import {
  NavBarWidthCollapsed,
  NavBarWidthExpanded,
  TopBarHeight,
  TopBarHeightMd,
} from "constants/variables";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import { SCREEN_SIZES } from "utils/css";

const topBarRelatedCss = css`
  padding-top: ${TopBarHeight};

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    padding-top: ${TopBarHeightMd};
  }
`;

const navBarRelatedCss = css`
  padding-left: 0;

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    padding-left: ${({ isNavBarCollapsed }) =>
      isNavBarCollapsed ? NavBarWidthCollapsed : NavBarWidthExpanded};
  }
`;

const ContentWrapper = styled.div`
  width: 100vw;
  max-width: 100%;
  height: ${({ location }) => location.pathname === "/" && "auto"};
  overflow: ${({ location }) => location.pathname === "/" && "hidden"};
  transition: padding 200ms cubic-bezier(0.4, 0, 0.2, 1);

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    position: ${({ isMapView }) => isMapView && "fixed"};
  }

  ${({ isTopBarVisible }) => isTopBarVisible && topBarRelatedCss};
  ${({ isNavBarVisible }) => isNavBarVisible && navBarRelatedCss};
`;

ContentWrapper.propTypes = {
  isNavBarCollapsed: PropTypes.bool,
  isTopBarVisible: PropTypes.bool,
  isNavBarVisible: PropTypes.bool,
  isMapView: PropTypes.bool,
};
export default withRouter(ContentWrapper);
