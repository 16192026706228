export const demoUserCountryDetails = {
  country: {
    code: "DK",
    name: "Denmark",
    __typename: "UserAddressCountry",
  },
};

export const demoUserDetails = {
  firstName: "Martin",
  lastName: "Hansen",
  companyName: "Wind Turbine Solutions",
  department: "Global Headquarters Denmark",
  roleInCompany: "Chief Executive Officer",
  phoneNumber: "45 1234 567890",
  email: "martin.hansen@demo-account.com",
  address: {
    addressLine1: "First Street 1",
    postalCode: "2300",
    city: "Copenhagen",
    state: "Frederiksberg",
    countryCode: "DK",
    country: demoUserCountryDetails.country,
  },
};
