import { defineMessages } from "react-intl";

export const messages = defineMessages({
  okButton: {
    id: "quoteAndBook.alerts.ok",
    defaultMessage: "Ok",
  },
  learnMoreLink: {
    id: "quoteAndBook.alerts.learnMore",
    defaultMessage: "Learn More",
  },
});
