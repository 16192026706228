import getConfigClient from "constants/getConfigClient";

const getKeycloakUrl = (redirectUri) => {
  const keycloakUrl = new URL("auth", getConfigClient("KEYCLOAK_URL"));
  keycloakUrl.searchParams.set("redirect_uri", String(redirectUri));
  keycloakUrl.searchParams.set("scope", "openid web-origins");
  keycloakUrl.searchParams.set("response_type", "code");
  keycloakUrl.searchParams.set(
    "client_id",
    getConfigClient("KEYCLOAK_CLIENT_ID")
  );
  return keycloakUrl;
};

export default getKeycloakUrl;
