import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { getRem } from "utils/css";

const FlyOutWrapper = styled.div`
  text-align: left;
  position: absolute;
  padding: ${(p) => p.padding};
  line-height: 1em;
  background: #fff;
  box-shadow: 0 ${getRem(4)} ${getRem(32)} 0 rgba(0, 0, 0, 0.07),
    0 0 ${getRem(2)} 0 rgba(0, 0, 0, 0.1);
  border: solid ${getRem(1)} rgba(0, 0, 0, 0.05);
  width: ${(props) => getRem(props.containerWidth * 10)};
  ${(props) =>
    props.visible
      ? css`
          transform: scale(1);
          transform-origin: top right;
          top: ${(props) =>
            props.footer ? null : getRem(props.containerTop * 10)};
          bottom: ${(props) =>
            props.footer ? getRem(props.containerTop * 10) : null};
          right: ${(props) => getRem(props.containerRight * 10)};
          opacity: 1;
          z-index: 20;
          transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
        `
      : css`
          transform: scale(0.01);
          transform-origin: ${(p) => (p.footer ? "bottom right" : "top right")};
          top: ${(p) => (p.footer ? null : getRem(30))};
          bottom: ${(p) => (p.footer ? getRem(0) : null)};
          right: ${getRem(20)};
          z-index: -2;
          opacity: 0.1;
          transition: all 75ms cubic-bezier(0.4, 0, 1, 1);
        `};

  &::after {
    content: "";
    width: ${getRem(18)};
    height: ${getRem(18)};
    border: none;
    border-top: ${getRem(1)} solid rgba(0, 0, 0, 0.05);
    border-right: ${getRem(1)} solid rgba(0, 0, 0, 0.05);
    transform: rotate(-${(p) => (p.footer ? 225 : 45)}deg);
    position: absolute;
    top: ${(p) => (p.footer ? null : getRem(-10))};
    bottom: ${(p) => (p.footer ? getRem(-10) : null)};
    right: ${(props) => getRem(props.arrowRight * 10)};
    z-index: 25;
    background: white;
  }

  ${(p) => p.$styles};
`;

FlyOutWrapper.defaultProps = {
  containerWidth: 24.6,
  containerTop: 7.0,
  containerRight: 0,
  arrowRight: 1.9,
  padding: getRem(15),
};
FlyOutWrapper.propTypes = {
  containerWidth: PropTypes.number,
  containerTop: PropTypes.number,
  containerRight: PropTypes.number,
  arrowRight: PropTypes.number,
  padding: PropTypes.string,
  maxHeight: PropTypes.string,
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default FlyOutWrapper;
