import { useQuery } from "@apollo/client";
import { QuotePackageTypeCodes } from "components/templates/GetAQuote/constants";
import GET_CONTACT_LINK_QB from "queries/QnB/getContactLinkQB";
import { LegalEntityRatesSources } from "queries/QnB/getLegalEntity";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import { setCurrency } from "redux/quoteAndBook/actions";
import { checkIsFreightQuote, FreightCurrency } from "routes/Quotes/helper";

export const useGetContactLinkQBAndHandleCurrency = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const locale = intl.locale;
  const locationPathname = location.pathname;
  const isGetAQuotePage = !!useRouteMatch("/get-a-quote");
  const isOffersPageExact = useRouteMatch("/offers")?.isExact;
  const isIndividualOfferPageExact = useRouteMatch("/offers/:id")?.isExact;
  const isBookingPage = !!useRouteMatch("/booking");
  const isContinueWithQuotePage = !!useRouteMatch("/continue-with-quote");
  const isTermsAndConditionsPage = !!useRouteMatch("/terms-and-conditions");
  const billingCountryCode = useSelector(
    (state) =>
      state.quoteAndBook.selectedCountry?.value || state.app?.userCountry
  );
  const hasSkeletonBox = useSelector((state) =>
    state.quoteData.quote?.cargo?.cargoItems?.some(
      (i) => i.packageType === QuotePackageTypeCodes.SKELETON_BOX.key
    )
  );
  const hasLoadingMeters = useSelector(
    (state) => state.quoteData.quote?.cargo?.cargoItems?.[0]?.loadingMeters
  );
  const selectedOfferProduct = useSelector(
    (state) => state.quoteData.selectedOffer?.product
  );
  const consignmentProduct = useSelector(
    (state) => state.quoteAndBookNonSync.consignment?.offerInfo?.product
  );
  const currency = useSelector((state) => state.quoteAndBook.selectedCurrency);
  const isFreightQuoteBasedOnOfferProduct =
    checkIsFreightQuote(selectedOfferProduct);
  useEffect(() => {
    if (
      isFreightQuoteBasedOnOfferProduct &&
      currency !== FreightCurrency &&
      (isIndividualOfferPageExact || isContinueWithQuotePage || isBookingPage)
    ) {
      dispatch(setCurrency(FreightCurrency, "USER", locationPathname, locale));
    }
    // eslint-disable-next-line
  }, [
    isFreightQuoteBasedOnOfferProduct,
    isIndividualOfferPageExact,
    isContinueWithQuotePage,
    isBookingPage,
    currency,
  ]);
  const isFreight = useMemo(() => {
    if (isGetAQuotePage || isOffersPageExact) {
      return hasSkeletonBox || hasLoadingMeters;
    }
    if (isIndividualOfferPageExact) {
      return checkIsFreightQuote(selectedOfferProduct);
    }
    if (isBookingPage || isContinueWithQuotePage || isTermsAndConditionsPage) {
      return checkIsFreightQuote(consignmentProduct);
    }
    return false;
  }, [
    isGetAQuotePage,
    isOffersPageExact,
    hasSkeletonBox,
    hasLoadingMeters,
    isIndividualOfferPageExact,
    selectedOfferProduct,
    isBookingPage,
    isContinueWithQuotePage,
    isTermsAndConditionsPage,
    consignmentProduct,
  ]);
  const { data } = useQuery(GET_CONTACT_LINK_QB, {
    variables: {
      locale: intl.locale,
      countryCode: billingCountryCode,
      ratesSource: isFreight
        ? LegalEntityRatesSources.FREIGHT
        : LegalEntityRatesSources.DGF,
    },
    skip: !intl.locale || !billingCountryCode,
  });
  return data?.getContactLinkQB?.contactLink;
};
