import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { getRem } from "../../../../utils/css";

const SideBarCollapseControlWrapper = styled.div`
  position: fixed;
  width: inherit;
  display: inline-block;
  line-height: ${getRem(16)};
  overflow: hidden;
  ${(props) =>
    props.belowLg
      ? css`
          top: 0;
        `
      : css`
          bottom: 0;
        `};
  cursor: pointer;
  ${(props) =>
    props.belowLg && props.collapsed
      ? css`
          padding: 0;
          display: none;
        `
      : css`
          padding: ${getRem(20)} ${getRem(4)} ${getRem(15)} ${getRem(24)};
        `};
  background: #ffffff;
  z-index: 20;
  label {
    display: inline-block;
    padding-left: ${getRem(16)};
    font-size: ${getRem(14)};
    cursor: pointer;
    line-height: ${getRem(16)};
    margin: 0;
    opacity: ${(props) => (props.collapsed ? 0 : 1)};
    transition: opacity 200ms;
  }
`;

SideBarCollapseControlWrapper.propTypes = {
  belowLg: PropTypes.bool,
  collapsed: PropTypes.bool,
};

export default SideBarCollapseControlWrapper;
