import { MapProviderDataType } from "constants/googleSuggestions";
import { FormApi } from "final-form";
import { transformCountryCode } from "helpers/transformCountryCode";
import { prepareAddressFromGoogleSuggestions } from "hooks/useAddressSuggestions";
import { useState } from "react";
import * as C from "./AddressSection.constants";

const Google = window?.google;
const autocompleteService =
  Google && new Google.maps.places.AutocompleteService();
export const isGoogleAPILoaded = () => !!window.google;
const geocoderService = Google && new Google.maps.Geocoder();

const googleLookup = async (
  countryCode: string,
  item: google.maps.places.AutocompletePrediction
): Promise<MapProviderDataType> => {
  if (!geocoderService) {
    throw new Error("No results");
  }
  const { results } = await geocoderService.geocode({ placeId: item.place_id });

  if (!results?.length) {
    throw new Error("No results");
  }

  const geoVal = results[0];

  const constGBCountryCode = "GB";

  const isGBGoogleSearch = countryCode === constGBCountryCode;
  const { zipCode, addressLine, city, areaLevel1, areaLevel2 } =
    prepareAddressFromGoogleSuggestions(geoVal, item, countryCode);

  return {
    place_id: geoVal.place_id,
    zipCode,
    addressLine,
    label: geoVal?.formatted_address,
    label_native: item.description,
    city,
    stateProvince: isGBGoogleSearch ? areaLevel2 : areaLevel1,
  };
};

export const getGoogleOptions = async (
  keyword: string,
  countryCode: string
): Promise<C.SearchOptionType[]> => {
  const code = transformCountryCode(countryCode);
  const { predictions } = await autocompleteService.getPlacePredictions({
    input: keyword,
    componentRestrictions: { country: code },
  });

  return predictions.map((item) => ({
    label: item.description,
    value: item.place_id,
    lookup: () => googleLookup(code, item),
  }));
};

export const useGoogleSuggestionsInput = (
  selectedCountry: string,
  addressLine1: string,
  postalCode: string,
  city: string,
  state: string,
  form: FormApi
) => {
  const [selectSearchValue, setSelectSearchValue] = useState({});
  const [googleSearchInputValue, setGoogleSearchInputValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchOptions, setSearchOptions] = useState<C.SearchOptionType[]>([]);

  const handleSearchInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const keyword = e.target.value;

    setGoogleSearchInputValue(keyword);

    if (keyword?.length >= 3) {
      try {
        setIsSearching(true);
        const results = await getGoogleOptions(keyword, selectedCountry);
        setSearchOptions(results);
        setIsSearching(false);
      } catch (error) {
        setIsSearching(false);
      }
    }
  };

  const handleSearchSelectChange = async (
    value: Partial<C.SearchOptionType>
  ) => {
    setGoogleSearchInputValue(value.label || "");
    setSelectSearchValue(value);
    if (value.lookup) {
      const {
        addressLine,
        zipCode,
        city: cityName,
        stateProvince,
      } = await value.lookup();
      if (addressLine) {
        form.change(addressLine1, addressLine);
        form.blur(addressLine1);
      }
      if (zipCode) {
        form.change(postalCode, zipCode);
        form.blur(postalCode);
      }
      if (cityName) {
        form.change(city, cityName);
        form.blur(city);
      }
      if (stateProvince) {
        form.change(state, stateProvince);
        form.blur(state);
      }
    }
  };

  const clearSearchInput = () => {
    setGoogleSearchInputValue("");
    setSelectSearchValue("");
    setSearchOptions([]);
  };

  return {
    handleSearchInputChange,
    handleSearchSelectChange,
    selectSearchValue,
    googleSearchInputValue,
    searchOptions,
    isSearching,
    clearSearchInput,
  };
};
