import { Color, media } from "react-lib";
import styled, { css } from "styled-components";

const twoRowsOfOptionsBreakpoint = "620px";

interface SingleRatingProps {
  isSelected?: boolean;
  isAnySelected?: boolean;
}

export const RatingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${twoRowsOfOptionsBreakpoint}) {
    flex-direction: row;
    margin-top: 0.75rem;
  }
`;

export const Likelihood = styled.p<{ isRightAligned?: boolean }>`
  color: ${Color.webBlack};
  font-size: 0.875rem;
  line-height: 1.43;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 300;

  ${({ isRightAligned }) =>
    isRightAligned &&
    `
      align-self: end;
      order: 3;
    `}

  @media (min-width: ${twoRowsOfOptionsBreakpoint}) {
    order: unset;
  }
`;

export const RatingScore = styled.span`
  font-weight: bold;
`;

const handleSelection = ({ isSelected, isAnySelected }: SingleRatingProps) => {
  if (isAnySelected) {
    if (isSelected) {
      return css`
        border-color: ${Color.green};
        background-color: ${Color.green};

        ${RatingScore} {
          color: ${Color.white};
        }
      `;
    }

    return css`
      border-color: ${Color.gray10};

      ${RatingScore} {
        color: ${Color.black45};
      }
    `;
  }
};

export const SingleRating = styled.div<SingleRatingProps>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 666px;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${media.until("xs")} {
    width: 2rem;
    height: 2rem;
  }

  ${handleSelection}
`;

export const RatingScoresWrapper = styled.div`
  display: flex;
  gap: 1rem 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 0.625rem;
  width: 100%;

  ${media.from("xs")} {
    gap: 1.25rem 1rem;
  }

  @media (min-width: ${twoRowsOfOptionsBreakpoint}) {
    margin-bottom: 2.125rem;
    gap: 1.25rem 0.875rem;
    justify-content: space-evenly;
  }
`;
