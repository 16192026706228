import SVGWrapper from "components/atoms/SVGWrapper";
import { Chip, Color } from "react-lib";
import * as ChipStyles from "react-lib/es/molecules/Chip/Chip.styles";
import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled from "styled-components";

export const SectionHeading = styled.h3`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25;
  margin-bottom: 1rem;

  @media (min-width: ${SCREEN_SIZES.from.sm}) {
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem 0 1rem 0;
    line-height: 1.33;
  }
`;

export const ElementHeading = styled.h4`
  margin: 0;
  padding: 0 0 0.3125rem 0;
  font-weight: bold;
  line-height: 1.5;
  color: ${Color.black};

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    margin-top: 0.5rem;
  }
`;

export const SVGWrapperForHeaderTooltip = styled(SVGWrapper)`
  margin-left: 0.75rem;
  position: relative;
  top: -1px;
`;

export const BookingStandaloneChip = styled(Chip)`
  width: 19.875rem;
  ${ChipStyles.ChipContentWrapper} {
    padding: 0.75rem;
  }
`;
