import { getRem } from "../../../utils/css";

const customStyles = {
  singleValue: (provided, state) => {
    return { ...provided };
  },
  container: (provided, state) => {
    return { ...provided };
  },
  control: (provided, state) => {
    return {
      ...provided,
      padding: state.isFocused ? `${getRem(5)} 0` : `${getRem(5)} 0`,
      fontSize: getRem(16),
      border: "none",
      boxShadow: "none",
      lineHeight: 1,
    };
  },
  dropdownIndicator: (provided, state) => {
    return { display: "none" };
  },
  indicatorSeparator: (provided, state) => {
    return { display: "none" };
  },
  option: (provided, state) => {
    return {
      ...provided,
      padding: getRem(12),
      backgroundColor: state.isFocused ? "rgba(0, 0, 0, 0.05)" : "white",
      cursor: "pointer",
      borderLeft: 0,
      borderRight: 0,
      "&:first-child": {
        borderTop: 0,
      },
      "&:last-child": {
        borderBottom: 0,
      },
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
      },
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "white",
      marginTop: 0,
      boxShadow: "none",
      border: "none",
      borderTop: 0,
    };
  },
  menuList: (provided, state) => {
    return {
      ...provided,
      paddingTop: 0,
      paddingBottom: getRem(2),
    };
  },
  multiValue: (provided, state) => {
    return {
      ...provided,
      padding: `${getRem(3)} ${getRem(5)}`,
      border: `${getRem(1)} solid black`,
      marginRight: getRem(5),
      borderRadius: getRem(4),
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
    };
  },
  multiValueLabel: (provided, state) => {
    return {
      ...provided,
      fontSize: getRem(14),
    };
  },
  input: (provided) => ({ ...provided }),
  placeholder: (provided) => ({ ...provided }),
};

export default customStyles;
