import { ReactComponent as CloseIcon } from "assets/icons/myDHLi/cancel.svg";
import H3 from "components/atoms/H3";
import SVGWrapper from "components/atoms/SVGWrapper";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { getRem } from "utils/css";

const Wrapper = styled.div`
  height: ${getRem(64)};
  background: ${(props) => props.theme.colors.postYellow};
  display: flex;
`;

const CloseButton = styled.span`
  margin-left: ${getRem(20)};
  margin-top: ${getRem(20)};
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  margin-left: ${getRem(60)};
  margin-top: ${getRem(15)};
`;

const closeSVGStyle = {
  width: getRem(24),
  height: getRem(24),
};

const Header = ({
  showCloseButtonInHeader,
  onClose,
  title,
  closeTrackCode,
}) => (
  <Wrapper>
    {showCloseButtonInHeader && (
      <CloseButton onClick={onClose} data-track={closeTrackCode}>
        <SVGWrapper
          width={closeSVGStyle.width}
          height={closeSVGStyle.height}
          fill="black"
        >
          <CloseIcon />
        </SVGWrapper>
      </CloseButton>
    )}
    {title && (
      <HeaderWrapper>
        <H3>{title}</H3>
      </HeaderWrapper>
    )}
  </Wrapper>
);
Header.propTypes = {
  showCloseButtonInHeader: PropTypes.bool,
  onClose: PropTypes.func,
};
Header.defaultProps = {
  showCloseButtonInHeader: true,
  onClose: (f) => f,
};

export default Header;
