import { HeaderAndFooterHeightTotal } from "constants/variables";
import styled from "styled-components";
import { getRem } from "utils/css";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  min-height: calc(100vh - ${getRem(HeaderAndFooterHeightTotal)});
`;
