import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "SummaryInteractions";

export const SummaryInteractions = {
  ADDITIONAL_CHARGES: {
    id: `${prefix}_Additional Charges`,
    name: "Additional Charges",
  },
  CANCEL: { id: `${prefix}_Cancel`, name: "Cancel" },
  COMPLETE_BOOKING: {
    id: `${prefix}_Complete Booking`,
    name: "Complete Booking",
  },
};

enum SummaryEventPosition {
  TOTAL = "Total",
  FOOTER = "Footer",
}

const summaryContext = "Summary";

export const summaryEventNameToSummaryEventContentMapper: C.EventNameToContentMapperType<
  typeof SummaryInteractions
> = {
  [SummaryInteractions.ADDITIONAL_CHARGES.id]: {
    name: SummaryInteractions.ADDITIONAL_CHARGES.name,
    ...eventContentButtonInterObj,
    position: SummaryEventPosition.TOTAL,
    context: summaryContext,
  },
  [SummaryInteractions.CANCEL.id]: {
    name: SummaryInteractions.CANCEL.name,
    ...eventContentButtonInterObj,
    position: SummaryEventPosition.FOOTER,
    context: summaryContext,
  },
  [SummaryInteractions.COMPLETE_BOOKING.id]: {
    name: SummaryInteractions.COMPLETE_BOOKING.name,
    ...eventContentButtonInterObj,
    position: SummaryEventPosition.FOOTER,
    context: summaryContext,
  },
};
