import { defineMessages } from "react-intl";
import { MakeABookingStage } from "./MakeABooking.constants";

export default defineMessages({
  [MakeABookingStage.MODE]: {
    id: "bookingStandalone.progressSteps.mode",
    defaultMessage: "Mode",
  },
  [MakeABookingStage.ROUTE]: {
    id: "bookingStandalone.progressSteps.route",
    defaultMessage: "Route",
  },
  [MakeABookingStage.CARGO]: {
    id: "bookingStandalone.progressSteps.cargo",
    defaultMessage: "Cargo",
  },
  [MakeABookingStage.SERVICE]: {
    id: "bookingStandalone.progressSteps.service",
    defaultMessage: "Service",
  },
  [MakeABookingStage.BILLING]: {
    id: "bookingStandalone.progressSteps.billing",
    defaultMessage: "Billing",
  },
  [MakeABookingStage.FINALIZE]: {
    id: "bookingStandalone.progressSteps.finalize",
    defaultMessage: "Finalize",
  },
});
