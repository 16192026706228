import React, { FC } from "react";
import { Redirect, Route } from "react-router-dom";

interface ProtectedRouteProps extends Route {
  children: FC;
  condition: boolean;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => (
  <Route {...props}>
    {props.condition ? props.children : <Redirect to="/" />}
  </Route>
);
