import React from "react";
import { ReactComponent as CurrentLocationPulse } from "../assets/icons/myDHLi/current-location-pulse.svg";
import { ReactComponent as AirportCircledIcon } from "../assets/icons/myDHLi/location-airport-circled.svg";
import { ReactComponent as AirportIcon } from "../assets/icons/myDHLi/location-airport.svg";
import { ReactComponent as CityCircledIcon } from "../assets/icons/myDHLi/location-city-circled.svg";
import { ReactComponent as CityIcon } from "../assets/icons/myDHLi/location-city.svg";
import { ReactComponent as PortCircledIcon } from "../assets/icons/myDHLi/location-port-circled.svg";
import { ReactComponent as PortIcon } from "../assets/icons/myDHLi/location-port.svg";
import { ReactComponent as RailStationCircledIcon } from "../assets/icons/myDHLi/location-rail-station-circled.svg";
import { ReactComponent as RailStationIcon } from "../assets/icons/myDHLi/location-rail-station.svg";
import { ReactComponent as RoadTerminalCircledIcon } from "../assets/icons/myDHLi/location-road-terminal-circled.svg";
import { ReactComponent as RoadTerminalIcon } from "../assets/icons/myDHLi/location-road-terminal.svg";
import { ReactComponent as WarehouseCircledIcon } from "../assets/icons/myDHLi/location-warehouse-circled.svg";
import { ReactComponent as WarehouseIcon } from "../assets/icons/myDHLi/location-warehouse.svg";
import { ReactComponent as AirplaneCircledIcon } from "../assets/icons/myDHLi/mode-air-circled.svg";
import { ReactComponent as AirplaneIcon } from "../assets/icons/myDHLi/mode-air.svg";
import { ReactComponent as ShipCircledIcon } from "../assets/icons/myDHLi/mode-ocean-circled.svg";
import { ReactComponent as ShipIcon } from "../assets/icons/myDHLi/mode-ocean.svg";
import { ReactComponent as RailCircledIcon } from "../assets/icons/myDHLi/mode-rail-circled.svg";
import { ReactComponent as RailIcon } from "../assets/icons/myDHLi/mode-rail.svg";
import { ReactComponent as TruckCircledIcon } from "../assets/icons/myDHLi/mode-road-circled.svg";
import { ReactComponent as TruckIcon } from "../assets/icons/myDHLi/mode-road.svg";

export const freightTypes = {
  RAIL: "RAIL",
  AIR: "AIR",
  AFR: "AIR",
  OCEAN: "OCEAN",
  OFR: "OCEAN",
  ROAD: "ROAD",
  RFR: "ROAD",
  RDE: "ROAD",
  INLAND_WATERWAY: "INLAND_WATERWAY",
  IW: "INLAND_WATERWAY",
  CUSTOMS: "CUSTOMS",
};

export const shipmentPhases = {
  PLANNED: "PLANNED",
  IN_TRANSIT: "IN_TRANSIT",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  BOOKING_CREATED: "BOOKING_CREATED",
};

export const shipmentPhasesLabels = {
  [shipmentPhases.PLANNED]: "Planned",
  [shipmentPhases.IN_TRANSIT]: "In Transit",
  [shipmentPhases.COMPLETED]: "Completed",
  [shipmentPhases.CANCELED]: "Canceled",
  [shipmentPhases.BOOKING_CREATED]: "Booking Created",
};

export const shipmentDelayStatuses = {
  DELAYED: "DELAYED",
  ON_TIME: "ON_TIME",
};

export const locationTypes = {
  CITY: "CITY",
  PORT: "PORT",
  TRANSSHIPMENT_PORT: "TRANSSHIPMENT_PORT",
  AIRPORT: "AIRPORT",
  RAILSTATION: "RAILSTATION",
  ROADTERMINAL: "ROADTERMINAL",
  STATION: "STATION",
  VESSEL: "VESSEL",
  AIRCRAFT: "AIRCRAFT",
  TRAIN: "TRAIN",
  TRUCK: "TRUCK",
};

export const shipmentEventFlags = {
  CONFIRMED_ESTIMATE: "CONFIRMED_ESTIMATE",
  INITIAL_ESTIMATE: "INITIAL_ESTIMATE",
  SMART_ESTIMATE: "SMART_ESTIMATE",
};

export const circledIconText = "_CIRCLED";
export const pulsatingIconText = "CURRENT_LOCATION";

export const Icon = {
  [locationTypes.CITY]: <CityIcon />,
  [locationTypes.CITY + circledIconText]: <CityCircledIcon />,
  [locationTypes.PORT]: <PortIcon />,
  [locationTypes.PORT + circledIconText]: <PortCircledIcon />,
  [locationTypes.TRANSSHIPMENT_PORT]: <PortIcon />, // TODO replace when new icon is ready
  [locationTypes.TRANSSHIPMENT_PORT + "AIR"]: <AirportIcon />, // TODO replace when new icon is ready
  [locationTypes.TRANSSHIPMENT_PORT + circledIconText]: <PortCircledIcon />, // TODO replace when new icon is ready
  [locationTypes.TRANSSHIPMENT_PORT + circledIconText + "AIR"]: (
    <AirportCircledIcon />
  ), // TODO replace when new icon is ready
  [locationTypes.AIRPORT]: <AirportIcon />,
  [locationTypes.AIRPORT + circledIconText]: <AirportCircledIcon />,
  [locationTypes.RAILSTATION]: <RailStationIcon />,
  [locationTypes.RAILSTATION + circledIconText]: <RailStationCircledIcon />,
  [locationTypes.ROADTERMINAL]: <RoadTerminalIcon />,
  [locationTypes.ROADTERMINAL + circledIconText]: <RoadTerminalCircledIcon />,
  [locationTypes.STATION]: <WarehouseIcon />,
  [locationTypes.STATION + circledIconText]: <WarehouseCircledIcon />,
  VESSEL: <ShipIcon />,
  [freightTypes.OCEAN]: <ShipIcon />,
  [freightTypes.OCEAN + circledIconText]: <ShipCircledIcon />,
  [freightTypes.INLAND_WATERWAY]: <ShipIcon />, // TODO replace when new icon is ready
  [freightTypes.INLAND_WATERWAY + circledIconText]: <ShipCircledIcon />, // TODO replace when new icon is ready
  AIRCRAFT: <AirplaneIcon />,
  [freightTypes.AIR]: <AirplaneIcon />,
  [freightTypes.AIR + circledIconText]: <AirplaneCircledIcon />,
  TRAIN: <RailIcon />,
  [freightTypes.RAIL]: <RailIcon />,
  [freightTypes.RAIL + circledIconText]: <RailCircledIcon />,
  TRUCK: <TruckIcon />,
  [freightTypes.ROAD]: <TruckIcon />,
  [freightTypes.ROAD + circledIconText]: <TruckCircledIcon />,
  [pulsatingIconText]: <CurrentLocationPulse />,
};
