import useBreakpoint from "hooks/useBreakpoint";
import React from "react";
import { useIntl } from "react-intl";
import {
  ButtonVariants,
  ButtonSizes,
} from "react-lib/es/atoms/buttons2/Button.constants";
import SvgPlus from "react-lib/es/icons/SvgPlus";
import messages from "./AddressBookNewEmptyList.messages";
import * as S from "./AddressBookNewEmptyList.styles";

interface AddressBookEmptyProps {
  openAddAddressModal: () => void;
}

const AddressBookEmpty = ({ openAddAddressModal }: AddressBookEmptyProps) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();

  return (
    <S.AddressBookEmptyContent>
      <S.AddressBookEmptyTitle>
        {intl.formatMessage(messages.addYourAddresses)}
      </S.AddressBookEmptyTitle>
      <S.AddressBookEmptyExplanation>
        {intl.formatMessage(messages.addYourAddressesExplanation)}
      </S.AddressBookEmptyExplanation>
      <S.AddressBookEmptyButtons>
        <S.AddressBookEmptyButton
          onClick={openAddAddressModal}
          variant={ButtonVariants.SECONDARY}
          leftIcon={<SvgPlus />}
          size={isMobile ? ButtonSizes.SMALL : ButtonSizes.LARGE}
        >
          {intl.formatMessage(messages.newAddress)}
        </S.AddressBookEmptyButton>
      </S.AddressBookEmptyButtons>
    </S.AddressBookEmptyContent>
  );
};

export default AddressBookEmpty;
