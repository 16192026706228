import { pageLoadEvent } from "../constants";

export const productViewPageLoadEvent = "dhl_utf_productviewpageload";

export const productCheckOutPageLoadEvent = "dhl_utf_productcheckoutpageload";

export const orderConfirmationPageLoadEvent =
  "dhl_utf_orderconfirmationpageload";

export enum QuoteAndBookPaths {
  GET_A_QUOTE = "/get-a-quote",
  OFFERS = "/offers",
  SERVICE = "/booking/service",
  ROUTE = "/booking/route",
  CARGO = "/booking/cargo",
  DOCUMENTS = "/booking/documents",
  BILLING = "/booking/billing",
  SUMMARY = "/booking/summary",
  SUCCESS = "/booking/success",
}

export const quoteAndBookPathsNames = {
  [QuoteAndBookPaths.GET_A_QUOTE]: "Get a Quote",
  [QuoteAndBookPaths.OFFERS]: "Select Quote",
  [QuoteAndBookPaths.SERVICE]: "Service",
  [QuoteAndBookPaths.ROUTE]: "Route",
  [QuoteAndBookPaths.CARGO]: "Cargo",
  [QuoteAndBookPaths.DOCUMENTS]: "Documents",
  [QuoteAndBookPaths.BILLING]: "Billing",
  [QuoteAndBookPaths.SUMMARY]: "Summary",
  [QuoteAndBookPaths.SUCCESS]: "Thank You",
};

export const quoteAndPathsToEventMapping = {
  [QuoteAndBookPaths.GET_A_QUOTE]: pageLoadEvent,
  [QuoteAndBookPaths.OFFERS]: productViewPageLoadEvent,
  [QuoteAndBookPaths.SERVICE]: productCheckOutPageLoadEvent,
  [QuoteAndBookPaths.ROUTE]: pageLoadEvent,
  [QuoteAndBookPaths.CARGO]: pageLoadEvent,
  [QuoteAndBookPaths.DOCUMENTS]: pageLoadEvent,
  [QuoteAndBookPaths.BILLING]: pageLoadEvent,
  [QuoteAndBookPaths.SUMMARY]: pageLoadEvent,
  [QuoteAndBookPaths.SUCCESS]: orderConfirmationPageLoadEvent,
};

export const quoteAndBookPaths = Object.values(QuoteAndBookPaths);

export interface Product {
  id?: string;
  quantity: 1;
  price?: string;
  currency?: string;
  shippingTerms?: string;
  cargoType?: string;
  totalPrice?: string;
  mainCarriage?: string;
}
