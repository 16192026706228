import {
  eventContentButtonInterObj,
  eventContentTabInterObj,
} from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "AnalyticsInteractions";

export const AnalyticsInteractions = {
  GO_TO_ANALYTICS: { id: `${prefix}_Go to Analytics`, name: "Go to Analytics" },
  SPEND: { id: `${prefix}_Spend`, name: "Spend" },
  VOLUME: { id: `${prefix}_Volume`, name: "Volume" },
  SERVICE_QUALITY: { id: `${prefix}_Service Quality`, name: "Service Quality" },
  CUSTOMS_ACTIVITY: {
    id: `${prefix}_Customs Activity`,
    name: "Customs Activity",
  },
};

const analyticsPosition = "Page Header";

const analyticsContext = "Analytics";

export const analyticsEventNameToAnalyticsEventContentMapper: C.EventNameToContentMapperType<
  typeof AnalyticsInteractions
> = {
  [AnalyticsInteractions.GO_TO_ANALYTICS.id]: {
    name: AnalyticsInteractions.GO_TO_ANALYTICS.name,
    ...eventContentButtonInterObj,
    position: analyticsPosition,
    context: analyticsContext,
  },
  [AnalyticsInteractions.SPEND.id]: {
    name: AnalyticsInteractions.SPEND.name,
    ...eventContentTabInterObj,
    position: analyticsPosition,
    context: analyticsContext,
  },
  [AnalyticsInteractions.VOLUME.id]: {
    name: AnalyticsInteractions.VOLUME.name,
    ...eventContentTabInterObj,
    position: analyticsPosition,
    context: analyticsContext,
  },
  [AnalyticsInteractions.SERVICE_QUALITY.id]: {
    name: AnalyticsInteractions.SERVICE_QUALITY.name,
    ...eventContentTabInterObj,
    position: analyticsPosition,
    context: analyticsContext,
  },
  [AnalyticsInteractions.CUSTOMS_ACTIVITY.id]: {
    name: AnalyticsInteractions.CUSTOMS_ACTIVITY.name,
    ...eventContentTabInterObj,
    position: analyticsPosition,
    context: analyticsContext,
  },
};
