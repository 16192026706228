import { useCallback, useEffect, useState } from "react";

const getItem = (storage, key, isJson) => {
  const value = storage.getItem(key);
  if (isJson) {
    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  }
  return value;
};

const useStorageItem = (storage, key, isJson) => {
  const [value, setValue] = useState(() => getItem(storage, key, isJson));
  useEffect(() => {
    const handleUpdate = (e) => {
      if (e.storageArea === storage && (e.key === null || e.key === key)) {
        setValue(e.newValue);
      }
    };
    window.addEventListener("storage", handleUpdate);
    return () => window.removeEventListener("storage", handleUpdate);
  }, [storage, key, isJson]);
  const updateValue = useCallback(
    (newValue) => {
      setValue(newValue);
      storage.setItem(key, isJson ? JSON.stringify(newValue) : newValue);
    },
    [storage, key, isJson]
  );
  return [value, updateValue];
};

export default useStorageItem;
