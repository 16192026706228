import { defineMessages } from "react-intl";

const messages = defineMessages({
  downloadContainerButton: {
    id: "downloadFile.backButton.backToDashboard.label",
    defaultMessage: "Back to dashboard",
  },
  gettingYourFile: {
    id: "downloadFile.gettingYourFile",
    defaultMessage: "Getting your file…",
  },
  downloadBeingPrepared: {
    id: "downloadFile.downloadBeingPrepared",
    defaultMessage: "We're just preparing your download.",
  },
  downloadingFile: {
    id: "downloadFile.downloadingFile",
    defaultMessage: "Downloading your file...",
  },
  downloadedFileWillBeOnYourComputer: {
    id: "downloadFile.downloadedFileWillBeOnYourComputer",
    defaultMessage:
      "Once it's done, the file will be on your computer (often in the download folder).",
  },
  fileExpired: {
    id: "downloadFile.fileExpired",
    defaultMessage: "File expired",
  },
  fileNotAvailableAnyMore: {
    id: "downloadFile.fileNotAvailableAnyMore",
    defaultMessage:
      "Sorry, this file has expired and is not available any more.",
  },
  failed: {
    id: "downloadFile.failed",
    defaultMessage: "Failed",
  },
  theDownloadOfFileFailed: {
    id: "downloadFile.theDownloadOfFileFailed",
    defaultMessage: "Sorry, the download of the file has failed.",
  },
});

export default messages;
