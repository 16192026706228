import { RouteProps } from "react-router-dom";

export enum Feature {
  APP = "app",
  DASHBOARD = "dashboard",
  SHIPMENTS = "shipments",
  QUOTES = "quotes",
  ANALYTICS = "analytics",
  BOOKINGS = "bookings",
  ORDERS = "orders",
  REPORTING = "reporting",
  BOOKING_STANDALONE = "bsa",
  CUSTOMER_SUPPORT_CENTER = "support_center",
}

export interface TestingContextProps {
  feature: Feature;
}

export interface RouteWithTestingContextProps extends RouteProps {
  feature: Feature;
}
