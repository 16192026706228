import codeToCountry from "constants/codeToCountry";
import includes from "lodash/includes";
import trim from "lodash/trim";

const cleanupAddressLineForSuggestions = (
  line,
  zipCode,
  city,
  cityShortName,
  areaLevel1ShortName,
  objectDescriptionAddressComponent,
  mainTextFromGeocodeService,
  route,
  routeShortName,
  streetNumber,
  country,
  countryShortName,
  countryCode
) => {
  // required to change route from long name to short name, if streetNumber and route are included in main_text from suggestions
  let modifiedMainTextFromGeocodeService;
  switch (mainTextFromGeocodeService) {
    case `${streetNumber} ${route}`:
      modifiedMainTextFromGeocodeService = `${streetNumber} ${routeShortName}`;
      break;
    case `${route} ${streetNumber}`:
      modifiedMainTextFromGeocodeService = `${routeShortName} ${streetNumber}`;
      break;
    default:
      modifiedMainTextFromGeocodeService = mainTextFromGeocodeService;
  }
  // if there is address component with the same value, as main_text from suggestions, remove it at the beginning, as it will be added later on in return statement
  const firstPartOfAddressLine = objectDescriptionAddressComponent?.short_name
    ? ""
    : `${modifiedMainTextFromGeocodeService}`;
  let addressLineComponents = (line || "").split(",");

  const isRouteInsideOfMainText =
    includes(mainTextFromGeocodeService, route) ||
    includes(mainTextFromGeocodeService, routeShortName);

  addressLineComponents = addressLineComponents.filter((item) => {
    return !(
      includes(item, zipCode) || // remove zipCode
      (!objectDescriptionAddressComponent?.short_name &&
        trim(item, ", ") === mainTextFromGeocodeService) || // if there is no address component with the same value as main_text from suggestions and item equals main_text, filter out item with same value as main_text, as it will be added earlier on
      (!objectDescriptionAddressComponent?.short_name &&
        isRouteInsideOfMainText &&
        (includes(trim(item, ", "), route) ||
          includes(trim(item, ", "), routeShortName))) || // remove route and route short name if it is already in main_text
      trim(item, ", ") === cityShortName || // remove cityShortName
      trim(item, ", ") === city || // remove city
      trim(item, ", ") === areaLevel1ShortName || // remove areaLevel1
      trim(item, ", ") === country || // remove country
      trim(item, ", ") === countryShortName || // remove countryShortName
      (codeToCountry[countryCode] || []).includes(trim(item, ", "))
    );
  });

  return addressLineComponents.length === 0
    ? modifiedMainTextFromGeocodeService
    : `${firstPartOfAddressLine}${
        addressLineComponents.length > 0 && firstPartOfAddressLine.length > 0
          ? ", "
          : ""
      }${trim(addressLineComponents.join(","), ", ")}`;
};

export default cleanupAddressLineForSuggestions;
