import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled from "styled-components";

const BookingFormFieldWrapper = styled.div`
  min-height: 4.375rem;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    min-height: 5.125rem;
  }
`;

export default BookingFormFieldWrapper;
