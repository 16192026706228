import * as C from "adobe-analytics/page-load-event/constants";
import {
  dispatchEvent,
  prepareDefaultDataLayerObject,
} from "adobe-analytics/page-load-event/helpers";
import { useLanguage } from "containers/Translations/Translations.context";
import useLoginState from "hooks/useLoginState";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import type { RootState } from "redux/reducers";
import {
  QuoteAndBookPaths,
  quoteAndPathsToEventMapping,
} from "../adobe-analytics/page-load-event/quote-and-book-page-load/constants";
import * as QnBHelpers from "../adobe-analytics/page-load-event/quote-and-book-page-load/helpers";
import { useQuoteAndBookDataLayerObject } from "../adobe-analytics/page-load-event/quote-and-book-page-load/helpers";

const useAdobeAnalytics = (): void => {
  const location = useLocation<C.LocationState>();
  const isLoggedIn = useLoginState();
  const language = useLanguage();
  const loggedInUserEmail = useSelector(
    (state: RootState) => state.auth.loggedInUserEmail
  );
  const locationPathWithoutIds = location.pathname?.replace(/\/\d/, "");
  const isQuoteAndBook = QnBHelpers.isQuoteAndBookPath(locationPathWithoutIds);
  const quoteAndBookDataLayerObject = useQuoteAndBookDataLayerObject(
    isLoggedIn,
    loggedInUserEmail,
    language,
    location
  );

  window[C.dataLayerObjectKey] = isQuoteAndBook
    ? quoteAndBookDataLayerObject
    : prepareDefaultDataLayerObject(
        isLoggedIn,
        loggedInUserEmail,
        language,
        location
      );

  const eventKey = isQuoteAndBook
    ? quoteAndPathsToEventMapping[locationPathWithoutIds as QuoteAndBookPaths]
    : C.pageLoadEvent;

  useEffect(() => {
    dispatchEvent(eventKey);
  }, [location, eventKey]);
};

export default useAdobeAnalytics;
