import gql from "graphql-tag";

const GET_CONTACT_LINK = gql`
  query GetContactLink($locale: String!, $countryCode: String!) {
    getContactLink(locale: $locale, countryCode: $countryCode) {
      languageCode
      countryCode
      contactLink
    }
  }
`;

export default GET_CONTACT_LINK;
