/* eslint-disable import/order */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "unorm";
import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
// todo: import other locales
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
import "assets/css/reboot.css";
import "assets/custom-bootstrap/bootstrap-grid.css";
import "assets/css/App.css";
import "react-lib/es/styles/spacing.css";
// import "assets/css/onetrust.css";
// added directly in onetrust admin panel
import "simplebar/dist/simplebar.min.css";
import { Provider as StoreProvider } from "react-redux";
import { store } from "redux/store";
import App from "App";
import * as serviceWorker from "serviceWorker";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "apolloClient";
import { ThemeProvider } from "styled-components";
import styledTheme from "constants/styledTheme";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import {
  requestInterceptor,
  responseErrorInterceptor,
  responseSuccessInterceptor,
} from "utils/interceptor";
import TestContext from "utils/TestContext";
import browserErrorsHandler from "utils/browserErrorsHandler";
import { TranslationsProvider } from "./containers/Translations/TranslationsProvider";
import "@dhl-official/tokens/index.css";
import { defineCustomElements } from "@dhl-official/react-library";

axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);

browserErrorsHandler();

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <ThemeProvider theme={styledTheme}>
      <StoreProvider store={store}>
        <Router>
          <TestContext.Provider value={false}>
            <TranslationsProvider>
              <DndProvider backend={HTML5Backend}>
                <App />
              </DndProvider>
            </TranslationsProvider>
          </TestContext.Provider>
        </Router>
      </StoreProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// DHL official react library: import the DUIL styles and register custom elements
defineCustomElements();
