import ElementHeading from "components/atoms/BookingElementHeading";
import { Color } from "react-lib";
import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled from "styled-components";

export const AddressBookWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 0 auto;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    max-width: 36rem;
  }

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    max-width: none;
  }
`;

export const StyledElementHeading = styled(ElementHeading)`
  display: flex;
  cursor: pointer;
  color: ${Color.webBlack};

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  & > span {
    padding-left: 0.5rem;
    font-weight: bold;
  }
`;
