import { defineMessages } from "react-intl";

export const messages = defineMessages({
  oops: {
    id: "error.modal.oops",
    defaultMessage: "Oops!",
  },
  sorry: {
    id: "error.modal.sorry",
    defaultMessage: "Sorry!",
  },
  shipmentNotFound: {
    id: "error.modal.shipmentNotFound",
    defaultMessage: "We cannot find this shipment.",
  },
  shipmentCouldNotBeLoaded: {
    id: "error.modal.shipmentCouldNotBeLoaded",
    defaultMessage: "Shipments could not be loaded.",
  },
  notAllowedToViewShipment: {
    id: "error.modal.notAllowedToViewShipment",
    defaultMessage: "You are not allowed to view this shipment.",
  },
  shipmentDoesNotExist: {
    id: "error.modal.shipmentDoesNotExist",
    defaultMessage:
      "You may have mistyped the address or the shipment may no longer exist.",
  },
  serverError: {
    id: "error.modal.serverError",
    defaultMessage:
      "The server encountered an error and we could not load your shipments.  If the problem persists, please report your problem.",
  },
  shipmentOtherAccount: {
    id: "error.modal.shipmentOtherAccount",
    defaultMessage:
      "This shipment belongs to a different myDHLi account. Please contact us if this seems wrong.",
  },
  shipmentListButtonText: {
    id: "error.modal.shipmentListButtonText",
    defaultMessage: "Shipment List",
  },
  noAccess: {
    id: "error.modal.noAccess",
    defaultMessage: "You do not have access!",
  },
  notAllowedSection: {
    id: "error.modal.notAllowedSection",
    defaultMessage: "You are not allowed to view this section.",
  },
  contactToEnable: {
    id: "error.modal.contactToEnable",
    defaultMessage: "Please contact us to enable this view.",
  },
  buttonTextDashboard: {
    id: "error.modal.buttonTextDashboard",
    defaultMessage: "Dashboard",
  },
  buttonTextRetry: {
    id: "error.modal.buttonTextRetry",
    defaultMessage: "Retry",
  },
  buttonTextBackToItem: {
    id: "error.modal.buttonTextBackToItem",
    defaultMessage: "Back To {item}",
  },
});
