import { Input } from "react-lib";
import styled from "styled-components";

export const StyledInput = styled(Input)<{
  hasError: boolean;
}>`
  input {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 1.5rem 0.5rem 0.5rem 0.75rem;

    &:not(:placeholder-shown) {
      ${({ hasError }) => hasError && "padding: 1.5rem 2.5rem 0.5rem 0.75rem"}
    }
  }

  textarea {
    ${({ hasError }) => hasError && "padding: 1.5rem 2.5rem 0.5rem 0.75rem;"}

    &:not(:placeholder-shown) {
      ${({ hasError }) => hasError && "padding: 1rem 2.5rem 1rem 0.75rem;"}
    }
  }

  span {
    position: absolute;
    right: 0;
  }
`;
