import {
  getLegalNoticeLink,
  getLinkLocale,
  getPrivacyNoticeLink,
} from "helpers/links";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { FooterLink } from "react-lib";
import messages from "../messages";
import { FlyOutBottomWrapper } from "../styles";

const FlyOutBottomSection = ({ onLinkClick }) => {
  const intl = useIntl();

  const linkLocale = getLinkLocale(intl.locale);
  const privacyNotice = getPrivacyNoticeLink(linkLocale);
  const legalNotice = getLegalNoticeLink(linkLocale);

  return (
    <FlyOutBottomWrapper>
      <FooterLink
        href={privacyNotice.route}
        target="_blank"
        rel="noopener noreferrer"
        data-track={privacyNotice.trackCode}
        label={intl.formatMessage(messages.privacyNotice)}
        onClick={onLinkClick}
      />
      <FooterLink
        href={legalNotice.route}
        target="_blank"
        rel="noopener noreferrer"
        data-track={legalNotice.trackCode}
        label={intl.formatMessage(messages.legalNotice)}
        onClick={onLinkClick}
      />
    </FlyOutBottomWrapper>
  );
};

FlyOutBottomSection.propTypes = {
  onLinkClick: PropTypes.func,
};

export default FlyOutBottomSection;
