import {
  eventContentButtonInterObj,
  eventContentLinkInterObj,
} from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "DashboardInteractions";

export const DashboardInteractions = {
  SHIPMENT_FILTER: { id: `${prefix}_Shipment Filter`, name: "Shipment Filter" },
  MAP_ITEM: { id: `${prefix}_Map Item`, name: "Map Item" },
  FOLLOWING_SHIPMENT: {
    id: `${prefix}_Following Shipment`,
    name: "Following Shipment",
  },
  UNFOLLOWING_SHIPMENT: {
    id: `${prefix}_Unfollowing Shipment`,
    name: "Unfollowing Shipment",
  },
  SETTINGS: { id: `${prefix}_Settings`, name: "Settings" },
  INDIVIDUAL_SHIPMENT: {
    id: `${prefix}_Individual Shipment`,
    name: "Individual Shipment",
  },
  DELAYED_SHIPMENT: {
    id: `${prefix}_Delayed Shipments`,
    name: "Delayed Shipments",
  },
  INDIVIDUAL_QUOTE: {
    id: `${prefix}_Individual Quote`,
    name: "Individual Quote",
  },
  GET_A_QUOTE: { id: `${prefix}_Get a Quote`, name: "Get a Quote" },
  ALL_QUOTES: { id: `${prefix}_All Quotes`, name: "All Quotes" },
};

enum DashboardContext {
  SHIPMENT_MAP = "Shipment Map",
  SHIPMENT_LIST = "Shipment List",
  QUOTE_LIST = "Quote List",
}

enum DashboardPosition {
  SHIPMENT_MAP = "Shipment Map",
  SHIPMENT_LIST = "Shipment List",
  QUOTE_LIST = "Quote List",
}

export const dashboardEventNameToDashboardEventContentMapper: C.EventNameToContentMapperType<
  typeof DashboardInteractions
> = {
  [DashboardInteractions.SHIPMENT_FILTER.id]: {
    name: DashboardInteractions.SHIPMENT_FILTER.name,
    ...eventContentButtonInterObj,
    position: DashboardPosition.SHIPMENT_MAP,
    context: DashboardContext.SHIPMENT_MAP,
  },
  [DashboardInteractions.MAP_ITEM.id]: {
    name: DashboardInteractions.MAP_ITEM.name,
    ...eventContentButtonInterObj,
    position: DashboardPosition.SHIPMENT_MAP,
    context: DashboardContext.SHIPMENT_MAP,
  },
  [DashboardInteractions.FOLLOWING_SHIPMENT.id]: {
    name: DashboardInteractions.FOLLOWING_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: DashboardPosition.SHIPMENT_LIST,
    context: DashboardContext.SHIPMENT_LIST,
  },
  [DashboardInteractions.UNFOLLOWING_SHIPMENT.id]: {
    name: DashboardInteractions.UNFOLLOWING_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: DashboardPosition.SHIPMENT_LIST,
    context: DashboardContext.SHIPMENT_LIST,
  },
  [DashboardInteractions.SETTINGS.id]: {
    name: DashboardInteractions.SETTINGS.name,
    ...eventContentButtonInterObj,
    position: DashboardPosition.SHIPMENT_LIST,
    context: DashboardContext.SHIPMENT_LIST,
  },
  [DashboardInteractions.INDIVIDUAL_SHIPMENT.id]: {
    name: DashboardInteractions.INDIVIDUAL_SHIPMENT.name,
    ...eventContentLinkInterObj,
    position: DashboardPosition.SHIPMENT_LIST,
    context: DashboardContext.SHIPMENT_LIST,
  },
  [DashboardInteractions.DELAYED_SHIPMENT.id]: {
    name: DashboardInteractions.DELAYED_SHIPMENT.name,
    ...eventContentLinkInterObj,
    position: DashboardPosition.SHIPMENT_LIST,
    context: DashboardContext.SHIPMENT_LIST,
  },
  [DashboardInteractions.INDIVIDUAL_QUOTE.id]: {
    name: DashboardInteractions.INDIVIDUAL_QUOTE.name,
    ...eventContentLinkInterObj,
    position: DashboardPosition.QUOTE_LIST,
    context: DashboardContext.QUOTE_LIST,
  },
  [DashboardInteractions.GET_A_QUOTE.id]: {
    name: DashboardInteractions.GET_A_QUOTE.name,
    ...eventContentLinkInterObj,
    position: DashboardPosition.QUOTE_LIST,
    context: DashboardContext.QUOTE_LIST,
  },
  [DashboardInteractions.ALL_QUOTES.id]: {
    name: DashboardInteractions.ALL_QUOTES.name,
    ...eventContentLinkInterObj,
    position: DashboardPosition.QUOTE_LIST,
    context: DashboardContext.QUOTE_LIST,
  },
};
