import { theme } from "constants/DHLPatternLibraryTheme";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { getRem } from "../../../utils/css";

const IconButton = styled.button`
  font-size: ${(props) => props.size};
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
  display: inline-block;
  border-radius: ${getRem(4)};
  font-weight: bold;
  margin-right: ${getRem(20)};
  margin-left: ${(props) => props.marginLeft};
  svg {
    fill: ${(props) => props.color};
    width: calc(${(props) => props.size} * 1.5);
    height: calc(${(props) => props.size} * 1.5);
    margin-left: ${getRem(7.5)};
  }
  line-height: 1;
  &:last-child {
    margin-right: 0;
  }
  span,
  svg {
    display: inline-block;
  }
  span {
    font-weight: bold;
  }

  ${({ isDisabled, secondary }) =>
    isDisabled
      ? css`
          cursor: not-allowed !important;
          border: ${getRem(1)} solid
            ${secondary ? theme.colors.gray45 : theme.colors.gray[400]};
          color: ${(p) =>
            secondary ? p.theme.colors.gray45 : p.theme.colors.white};
          background-color: ${(p) =>
            secondary ? p.theme.colors.white : p.theme.colors.gray20};
          svg {
            fill: ${(p) =>
              secondary ? p.theme.colors.gray45 : p.theme.colors.white};
          }
        `
      : css`
          cursor: pointer;
          border: ${getRem(1)} solid
            ${(props) => (props.borderColor ? props.borderColor : props.color)};
          background-color: ${(props) => props.bgColor};
          &:hover,
          &:active,
          &:focus {
            outline: 0;
            //border: ${getRem(1)} solid transparent;
          }
          &:focus,
          &:active {
            //border-color: ${(props) => props.color};
            //border-width: ${getRem(2)};
            //box-shadow: 0 0 0 ${getRem(4)} white, 0 0 0 ${getRem(6)} ${(
              props
            ) => props.color};
            //padding: ${getRem(9)} ${getRem(29)};
          }
          &:hover {
            background-color: ${(props) =>
              props.hoverBgColor ? props.hoverBgColor : props.bgColor};
            border-color: ${(props) =>
              props.hoverBorderColor
                ? props.hoverBorderColor
                : props.hoverColor};
            color: ${(props) => props.hoverColor};
            box-shadow: unset;
            svg {
              fill: ${(props) => props.hoverColor};
            }
          }
        `}

  ${({ hideBorder }) =>
    hideBorder
      ? css`
          border: 0;
        `
      : null}
`;

IconButton.defaultProps = {
  color: "#d40511",
  hoverColor: "#eb131e",
  bgColor: "#FFFFFF",
  padding: `${getRem(10)} ${getRem(30)}`,
  size: getRem(16),
  type: "button",
};

IconButton.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  bgColor: PropTypes.string,
  hoverBgColor: PropTypes.string,
  borderColor: PropTypes.string,
  hoverBorderColor: PropTypes.string,
  padding: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
};

export default IconButton;
