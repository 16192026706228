import { proxyRoutes } from "constants/serverEndpoints";
import FileDownloadView from "components/FileDownloadView/FileDownloadView";
import Auth from "containers/Auth";
import React from "react";
import { useParams } from "react-router-dom";

export const digestDownloadPath = "/notifications/digest/:fileId";

const DigestDownload: React.FC = () => {
  const { fileId } = useParams<{ fileId: string }>();

  return (
    <Auth>
      <FileDownloadView
        fileId={fileId}
        fileDownloadEndpoint={proxyRoutes.downloadDigest}
      />
    </Auth>
  );
};

export default DigestDownload;
