import Loading from "components/molecules/Loader/Loading";
import React from "react";

const FullpageLoader = () => {
  return (
    <div style={{ minHeight: "80vh" }}>
      <Loading delay={300} />
    </div>
  );
};

export default FullpageLoader;
