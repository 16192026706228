import styled from "styled-components";
import { getRem, getRemMedia } from "utils/css";

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${getRem(-16)};
  margin-right: ${getRem(-16)};
  @media (min-width: ${getRemMedia(768.2)}) {
    margin-left: ${getRem(-20)};
    margin-right: ${getRem(-20)};
  }
`;

export default Row;
