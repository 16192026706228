import styled, { css } from "styled-components";
import { getRem } from "utils/css";
import { LIST_PADDING_LEFT } from "../../TableHeader/partials/constants";
import { TableCol, TableRow } from "./Table";

export const ShipmentColContainer = styled(TableCol)`
  display: flex;
  align-items: center;
  height: ${getRem(48)};
  ${(p) =>
    p.addLeftMargin &&
    css`
      > * {
        margin-left: ${getRem(24)};
      }
    `}
`;

export const ButtonCol = styled(ShipmentColContainer)`
  visibility: hidden;
  justify-content: space-evenly;
  padding-right: ${getRem(30)};
`;

export const ShipmentRowContainer = styled(TableRow)`
  min-height: ${getRem(48)};
  padding-left: ${LIST_PADDING_LEFT};
  box-shadow: inset 0 -1px 0 0 ${(themeProps) => themeProps.theme.colors.black10};
  &:hover {
    background-color: ${(themeProps) => themeProps.theme.colors.black5};
    cursor: pointer;
  }
  &:hover ${ButtonCol}, &:focus ${ButtonCol} {
    visibility: visible;
  }
  ${({ shouldHighlightRow }) =>
    shouldHighlightRow
      ? css`
          background-color: ${(themeProps) => themeProps.theme.colors.black5};
        `
      : null};
`;

export const ShipmentColText = styled.span`
  font-size: ${getRem(14)};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: ${getRem(20)};
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.black90};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(p) => getRem(p.size ? p.size * 16 : 60)};
  hyphens: auto;
  display: inline-block;
  mark {
    font-weight: bold;
    background-color: transparent;
  }
`;
