import { useQuery } from "@apollo/client";
import { getIsUserReadyForVote } from "queries/AAA/profile";
import React, { useEffect, useState } from "react";
import NPSModal from "./NPSModal/NPSModal";

const oneMinuteInMs = 60000;

const NPSSurvey = () => {
  const [shouldDisplayNPSModal, setShouldDisplayNPSModal] = useState(false);
  const { data, refetch } = useQuery(getIsUserReadyForVote);

  const isUserReadyForVote = data?.getIsUserReadyForVote?.readyForVote;

  // This logic executes following steps:
  // if user is ready for vote, start the timer for 1 minute
  // when the timer finishes check if current tab is visible & the conditions are still valid
  // if so, display the modal - otherwise stop the logic.
  useEffect(() => {
    let activeSessionTimer: NodeJS.Timer;

    const displayTheSurvey = () => {
      // Check if tab is visible and the condition is still valid.
      if (document.visibilityState === "visible") {
        refetch();

        if (isUserReadyForVote) {
          setShouldDisplayNPSModal(true);
        }
      }
    };

    if (isUserReadyForVote) {
      activeSessionTimer = setTimeout(displayTheSurvey, oneMinuteInMs);
    }

    return () => {
      clearTimeout(activeSessionTimer);
      window.removeEventListener("blur", displayTheSurvey);
    };
  }, [isUserReadyForVote, refetch]);

  const [isNPSOpen, onNPSClose] = useState(true);

  return shouldDisplayNPSModal ? (
    <NPSModal isOpen={isNPSOpen} onClose={() => onNPSClose(false)} />
  ) : null;
};

export default NPSSurvey;
