import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  setQuotesFilter,
  setShipmentFilter,
  setBookingsListFilter,
  setCDZFilter,
} from "redux/userSettings/actions";
import styled from "styled-components";
import { getRem } from "../../../utils/css";

const FilterItemWrapper = styled.div``;
const FilterItemBG = styled.div`
  width: ${getRem(250)};
  &:hover {
    background-color: ${(p) => p.theme.colors.black5};
  }
`;
const FilterItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${getRem(16)} ${getRem(20)};
`;

const Title = styled.div`
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const List = (props) => {
  return (
    <FilterItemWrapper>
      {props.items.map((item) => (
        <FilterItemBG key={item.key}>
          <FilterItem
            key={item.key}
            onClick={() => {
              if (props.filter) {
                if (props.module === "quotes") {
                  props.setQuotesFilter(
                    props.filter,
                    props.transform(item.key)
                  );
                  props.onOutsideClick();
                } else if (props.module === "cdz") {
                  props.setCDZFilter(props.filter, item.key);
                  props.onOutsideClick();
                } else if (props.module === "bookings") {
                  props.setBookingsListFilter(
                    props.filter,
                    props.transform(item.key)
                  );
                } else {
                  props.setShipmentFilter(
                    props.filter,
                    props.transform(item.key)
                  );
                  props.onOutsideClick();
                }
              }
            }}
          >
            <Title>{item.title || item.key}</Title>
          </FilterItem>
        </FilterItemBG>
      ))}
    </FilterItemWrapper>
  );
};
List.defaultProps = {
  bucket: {},
  module: "shipments",
  transform: (str) => str,
};
List.propTypes = {
  bucket: PropTypes.object,
  module: PropTypes.string,
  transform: PropTypes.func,
};

export default connect(
  (state) => ({
    userSettings: state.userSettings,
  }),
  { setShipmentFilter, setQuotesFilter, setBookingsListFilter, setCDZFilter }
)(List);
