import gql from "graphql-tag";

const SUBMIT_CONSIGNMENT = gql`
  query SubmitConsignment($uuid: String!, $corporatePartner: String) {
    submitConsignment(uuid: $uuid, corporatePartner: $corporatePartner) {
      id
      housebill
      status
      timestamp
    }
  }
`;

export default SUBMIT_CONSIGNMENT;
