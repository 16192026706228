import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Loader, colors } from "react-lib";
import styled from "styled-components";
import { getRem } from "utils/css";

const Wrapper = styled.div`
  padding: ${(p) => (p.$onPopup ? getRem(10) : 0)} 0;
  padding-top: ${(p) => p.marginTop};
`;

const Loading = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!showLoader) {
        setShowLoader(true);
      }
    }, props?.delay);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);
  if (props.additionalCallback) {
    props.additionalCallback();
  }

  return (
    <Wrapper marginTop={props.marginTop} $onPopup={props.onPopup}>
      {showLoader && (
        <Loader size={props.size} color={props.color} inline={props.inline} />
      )}
    </Wrapper>
  );
};
Loading.defaultProps = {
  onPopup: false,
  marginTop: getRem(70),
  color: colors.dhlRed,
  delay: 0,
};
Loading.propTypes = {
  size: PropTypes.string,
  marginTop: PropTypes.string,
  color: PropTypes.string,
  onPopup: PropTypes.bool,
  additionalCallback: PropTypes.func,
  delay: PropTypes.number,
};

export default Loading;
