import { useEffect } from "react";

const useOutsideClick = (
  ref,
  onOutsideClick,
  disable,
  event = "mousedown",
  useCapture = false
) => {
  function handleClickOutside(event) {
    if (ref?.current && !ref.current.contains(event.target)) {
      onOutsideClick();
    }
  }

  useEffect(() => {
    if (!disable)
      document.addEventListener(event, handleClickOutside, useCapture);
    return () => {
      if (!disable)
        document.removeEventListener(event, handleClickOutside, useCapture);
    };
  });
};

export default useOutsideClick;
