export const handoverTypes = {
  TERMINAL: "terminal",
  COMPANY: "company",
};

export const countryPreferredAddressInputTypes = {
  GOOGLE: "POSTALCODE_MAPS_API",
  LOCALITY_BASED: "LOCALITY_LOCREG_LIST",
  MANUAL: "POSTALCODE_MANUAL",
  POLYGON_AREA: "POLYGON_MAPS_API",
  LOCALITY_MAPS_API: "LOCALITY_MAPS_API",
  LOCALITY_MANUAL: "LOCALITY_MANUAL",
};

export const localityTypes = {
  ORIGIN: "origin",
  DESTINATION: "destination",
};

export const trackedUrlsPaths = {
  PICK_UP: "/booking/route/edit/pickUp",
  DELIVERY: "/booking/route/edit/delivery",
};
