import {
  eventContentButtonInterObj,
  eventContentTabInterObj,
} from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "MapTrackingInteractions";

export const MapTrackingInteractions = {
  CLOSE_MAP: { id: `${prefix}_Close Map`, name: "Close Map" },
  MAP_ITEM: { id: `${prefix}_Map Item`, name: "Map Item" },
  BACK_TO_LIST: { id: `${prefix}_Back to list`, name: "Back to list" },
  FOLLOWING_SHIPMENT: {
    id: `${prefix}_Following Shipment`,
    name: "Following Shipment",
  },
  UNFOLLOWING_SHIPMENT: {
    id: `${prefix}_Unfollowing Shipment`,
    name: "Unfollowing Shipment",
  },
  CONTAINER_DROPDOWN: {
    id: `${prefix}_Container Dropdown`,
    name: "Container Dropdown",
  },
  OPEN_SHARE: { id: `${prefix}_Open Share`, name: "Open Share" },
  SHARE: { id: `${prefix}_Share`, name: "Share" },
  PDF: { id: `${prefix}_PDF`, name: "PDF" },
  ROUTE: { id: `${prefix}_Route`, name: "Route" },
  DETAILS: { id: `${prefix}_Details`, name: "Details" },
  DOCUMENTS: { id: `${prefix}_Documents`, name: "Documents" },
};

enum MapTrackingPosition {
  MAP_HEADER = "Map Header",
  SHIPMENT_MAP = "Shipment Map",
  SHIPMENT_LIST = "Shipment List",
}

const mapTrackingContext = "Tracking Map";

export const mapTrackingEventNameToMapTrackingEventContentMapper: C.EventNameToContentMapperType<
  typeof MapTrackingInteractions
> = {
  [MapTrackingInteractions.CLOSE_MAP.id]: {
    name: MapTrackingInteractions.CLOSE_MAP.name,
    ...eventContentButtonInterObj,
    position: MapTrackingPosition.MAP_HEADER,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.MAP_ITEM.id]: {
    name: MapTrackingInteractions.MAP_ITEM.name,
    ...eventContentButtonInterObj,
    position: MapTrackingPosition.SHIPMENT_MAP,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.BACK_TO_LIST.id]: {
    name: MapTrackingInteractions.BACK_TO_LIST.name,
    ...eventContentButtonInterObj,
    position: MapTrackingPosition.SHIPMENT_LIST,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.FOLLOWING_SHIPMENT.id]: {
    name: MapTrackingInteractions.FOLLOWING_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: MapTrackingPosition.SHIPMENT_LIST,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.UNFOLLOWING_SHIPMENT.id]: {
    name: MapTrackingInteractions.UNFOLLOWING_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: MapTrackingPosition.SHIPMENT_LIST,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.CONTAINER_DROPDOWN.id]: {
    name: MapTrackingInteractions.CONTAINER_DROPDOWN.name,
    ...eventContentButtonInterObj,
    position: MapTrackingPosition.SHIPMENT_LIST,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.OPEN_SHARE.id]: {
    name: MapTrackingInteractions.OPEN_SHARE.name,
    ...eventContentButtonInterObj,
    position: MapTrackingPosition.SHIPMENT_LIST,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.SHARE.id]: {
    name: MapTrackingInteractions.SHARE.name,
    ...eventContentButtonInterObj,
    position: MapTrackingPosition.SHIPMENT_LIST,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.PDF.id]: {
    name: MapTrackingInteractions.PDF.name,
    ...eventContentButtonInterObj,
    position: MapTrackingPosition.SHIPMENT_LIST,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.ROUTE.id]: {
    name: MapTrackingInteractions.ROUTE.name,
    ...eventContentTabInterObj,
    position: MapTrackingPosition.SHIPMENT_LIST,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.DETAILS.id]: {
    name: MapTrackingInteractions.DETAILS.name,
    ...eventContentTabInterObj,
    position: MapTrackingPosition.SHIPMENT_LIST,
    context: mapTrackingContext,
  },
  [MapTrackingInteractions.DOCUMENTS.id]: {
    name: MapTrackingInteractions.DOCUMENTS.name,
    ...eventContentTabInterObj,
    position: MapTrackingPosition.SHIPMENT_LIST,
    context: mapTrackingContext,
  },
};
