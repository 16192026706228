import apiKeys from "constants/apiKeys";
import cleanupAddressLine from "components/organisms/AddressAutosuggestion/cleanupAddressLine";
import cleanupAddressLineForSuggestions from "components/organisms/AddressAutosuggestion/cleanupAddressLineForSuggestions";
import {
  getLongAndShortName,
  getPostalCode,
  getShortName,
} from "components/organisms/AddressAutosuggestion/helper";
import useResolveMapsProvider from "components/organisms/AddressAutosuggestion/useResolveMapsProvider";
import { MapProviders } from "components/organisms/DashboardMap/constants";
import countryCoordinates from "data/countryCoordinates";
import countryThreeCharCode from "data/countryThreeCharCode";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const herePlatform =
  window.H &&
  new window.H.service.Platform({
    apikey: apiKeys.hereMaps,
  });
const hereSearchService = herePlatform && herePlatform.getSearchService();

const autocompleteService =
  window.google && new window.google.maps.places.AutocompleteService();
const GeocoderService = window.google && new window.google.maps.Geocoder();

export const prepareAddressFromGoogleSuggestions = (
  geoVal,
  item,
  countryCode
) => {
  const zipCode = getPostalCode(geoVal);

  const addressComponents = geoVal.address_components;

  const streetNumber = getShortName(addressComponents, "street_number");

  const [country, countryShortName] = getLongAndShortName(
    addressComponents,
    "country"
  );

  const [route, routeShortName] = getLongAndShortName(
    addressComponents,
    "route"
  );

  const [postalTown, postalTownShortName] = getLongAndShortName(
    addressComponents,
    "postal_town"
  );

  const [locality, localityShortName] = getLongAndShortName(
    addressComponents,
    "locality"
  );

  const [areaLevel1, areaLevel1ShortName] = getLongAndShortName(
    addressComponents,
    "administrative_area_level_1"
  );

  const [areaLevel2, areaLevel2ShortName] = getLongAndShortName(
    addressComponents,
    "administrative_area_level_2"
  );

  let cityShortName = "";

  let city = "";

  if (countryCode === "CO") {
    city = postalTown || areaLevel1 || locality || areaLevel2;
    cityShortName =
      postalTownShortName ||
      areaLevel1ShortName ||
      localityShortName ||
      areaLevel2ShortName;
  } else {
    city = postalTown || locality || areaLevel1 || areaLevel2;
    cityShortName =
      postalTownShortName ||
      localityShortName ||
      areaLevel1ShortName ||
      areaLevel2ShortName;
  }

  // find address component that matches main_text value from suggestions
  const objectDescriptionAddressComponent = geoVal?.address_components.find(
    (component) => item.structured_formatting.main_text === component.long_name
  );

  const addressLine = cleanupAddressLineForSuggestions(
    geoVal?.formatted_address,
    zipCode,
    city,
    cityShortName,
    areaLevel1ShortName,
    objectDescriptionAddressComponent,
    item.structured_formatting.main_text,
    route,
    routeShortName,
    streetNumber,
    country,
    countryShortName,
    countryCode
  );

  return { zipCode, addressLine, city, areaLevel1, areaLevel2 };
};

const googleLookup = !window.google
  ? null
  : (countryCode, item, onComplete) => {
      const onValuePresent = (geoVal) => {
        const { zipCode, addressLine, city } =
          prepareAddressFromGoogleSuggestions(geoVal, item, countryCode);
        onComplete({
          geometry: {
            location: {
              lat: geoVal.geometry.location.lat(),
              lng: geoVal.geometry.location.lng(),
            },
            location_type: geoVal.geometry.location_type,
          },
          place_id: geoVal.place_id,
          zipCode,
          addressLine,
          label: item.description,
          label_native: item.label,
          city,
        });
      };
      GeocoderService.geocode(
        {
          placeId: item.place_id,
        },
        (result, _status) => onValuePresent(result[0])
      );
    };

const fetchSuggestionGoogleApi = !window.google
  ? null
  : (keyword, countryCode, onComplete) => {
      autocompleteService.getPlacePredictions(
        {
          input: keyword,
          componentRestrictions: { country: countryCode },
        },
        async (predictions, status) => {
          const results = status === "OK" ? predictions : [];
          onComplete(
            results.map((item) => ({
              label: item.description,
              value: item.place_id,
              address: item.address,
              position: item.position,
              resultType: item.resultType,
              lookup: (cb) => googleLookup(countryCode, item, cb),
            }))
          );
        }
      );
    };

const hereLookup = !window.H
  ? null
  : (countryCode, item, onComplete) => {
      hereSearchService.lookup({ id: item?.id, lang: "en" }, (result) => {
        const { label, postalCode, city } = result?.address || {};
        const addressLine = cleanupAddressLine(
          label,
          postalCode,
          city,
          countryCode
        );
        onComplete({
          geometry: {
            location: result?.position,
            location_type: result?.resultType,
          },
          place_id: item?.id,
          zipCode: postalCode,
          addressLine,
          label: [addressLine, postalCode, city].filter(Boolean).join(", "),
          label_native: label,
          city,
        });
      });
    };

const fetchSuggestionHereApi = !window.H
  ? null
  : async (keyword, countryCode, onComplete) => {
      if (hereSearchService) {
        const searchOptions = {
          q: keyword,
          in: "countryCode:" + countryThreeCharCode[countryCode],
          at:
            countryCoordinates[countryCode]?.lat +
            "," +
            countryCoordinates[countryCode]?.lng,
          lang: "en",
        };
        const handleSuccess = (result) => {
          const filtered = result.items.map((item) => ({
            label: item?.address?.label || item?.title,
            value: item?.id,
            address: item?.address,
            position: item?.position,
            resultType: item?.resultType,
            lookup: (cb) => hereLookup(countryCode, item, cb),
          }));
          onComplete(filtered);
        };
        const handleError = () => onComplete([]);
        hereSearchService.autosuggest(
          searchOptions,
          handleSuccess,
          handleError
        );
      } else {
        // console.log("here service not present");
        onComplete([]);
      }
    };

const useAddressSuggestions = (countryCode, keyword) => {
  const provider = useResolveMapsProvider()?.addressSearchProvider;
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const mapsSuggestionProvider =
    provider === MapProviders.HERE
      ? fetchSuggestionHereApi
      : fetchSuggestionGoogleApi;

  const callId = useRef(0);

  const search = useCallback(
    async ({ keyword, countryCode }) => {
      if (!mapsSuggestionProvider) {
        setError(new Error("Map provider not available."));
        return;
      }
      setLoading(true);
      // Make sure that only the most recent search is updating the results
      callId.current += 1;
      const thisCallId = callId.current;
      const updateResults = (results) => {
        if (callId.current === thisCallId) {
          setResults(results);
          setLoading(false);
        }
      };
      try {
        await mapsSuggestionProvider(keyword, countryCode, updateResults);
      } catch (error) {
        if (callId.current === thisCallId) {
          setResults([]);
          setLoading(false);
          setError(error);
        }
      }
    },
    [mapsSuggestionProvider]
  );

  const delayedSearch = useMemo(() => debounce(search, 500), [search]);

  const countryCodeRef = useRef(countryCode);
  const keywordRef = useRef(keyword);

  // handle country changes with instant search (ignoring keyword changes)
  useEffect(() => {
    countryCodeRef.current = countryCode;
    if (countryCode && keywordRef.current) {
      search({ keyword: keywordRef.current, countryCode });
    }
  }, [countryCode, search]);

  // handle keyword changes with delayed search (ignoring country changes)
  useEffect(() => {
    keywordRef.current = keyword;
    if (keyword && countryCodeRef.current) {
      setLoading(true);
      delayedSearch({ keyword, countryCode: countryCodeRef.current });
    } else {
      // Make sure it will not be overridden by an earlier search
      callId.current += 1;
      setResults([]);
    }
  }, [delayedSearch, keyword]);

  return {
    available: !!mapsSuggestionProvider,
    data: results,
    loading,
    error,
  };
};

export default useAddressSuggestions;
