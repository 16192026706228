import gql from "graphql-tag";

export const USER_NEW_ADDRESS_BOOK_LIST_QUERY = gql`
  query UserNewAddressBookListQuery(
    $order: UserNewAddressBookOrder
    $paging: NewInputPaging
    $filters: [UserNewAddressBookFilter]
    $corporate_partner_uuid: String
  ) {
    getUserNewAddressBookList(
      order: $order
      paging: $paging
      filters: $filters
      corporate_partner_uuid: $corporate_partner_uuid
    ) {
      addresses {
        address_id
        address {
          created_at
          created_by_id
          modified_at
          modified_by_id
          id
          corporate_partner_uuid
          company_name
          address_line1
          address_line2
          city
          postal_code
          state_province
          country_code
          ref_customer
          latitude
          longitude
          google_place_id
          usage
        }
        contacts {
          contact_id
          address_id
          contact {
            contact_name
            email
            phone
            is_shipper
            is_consignee
            is_pickup
            is_delivery
            is_billing
            is_notify
          }
          audit {
            created_at
            created_by_email
            modified_at
            modified_by_email
          }
        }
      }
      total_count
    }
  }
`;
