import { AddressInputType } from "constants/bookWorkflow";
import {
  getDefaultJoiErrorFunction,
  validateEmail,
} from "helpers/joiValidation";
import joi from "joi";
import { IntlShape } from "react-intl";

const zipValidationSchema = joi.when("preferredAddressInputType", {
  is: joi.valid(
    AddressInputType.LOCALITY_LOCREG_LIST, // to check
    AddressInputType.LOCALITY_MANUAL
  ),
  then: joi.string().optional().allow(null, ""),
  otherwise: joi.string().max(10).required(),
});

const countryValidationSchema = joi.object({
  code: joi.string().required(),
  countryCode: joi.string().required(),
  label: joi.string(),
  value: joi.string(),
});

const addressValidationSchema = joi.object({
  preferredAddressInputType: joi.any(),
  country: countryValidationSchema,
  street: joi.string().allow(null, ""),
  streetAddress: joi.string().max(100).required(),
  zip: zipValidationSchema,
  city: joi.string().max(50).required(),
  states: joi.string().max(25).optional().allow(null, ""),
});

const getContactsValidationSchema = () =>
  joi.object({
    contactName: joi.string().max(256).required(),
    contactType: joi.array().items(joi.string()).allow(null),
    email: joi.string().max(254).custom(validateEmail).required(),
    phoneNumber: joi.string().min(7).required(),
  });

const addressBookValidationSchema = joi.object({
  companyName: joi.string().max(100).required(),
  address: addressValidationSchema.required(),
  contacts: joi.array().items(getContactsValidationSchema()),
  reference: joi.string().max(100).allow(null, ""),
});

export const getAddressBookFormSchema: any = (intl: IntlShape) =>
  joi
    .object({
      addressBook: addressBookValidationSchema.required(),
    })
    .error(getDefaultJoiErrorFunction(intl))
    .options({ abortEarly: false, allowUnknown: true });
