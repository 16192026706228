import PropTypes from "prop-types";
import styled from "styled-components";
import { getRem } from "../../../../utils/css";

const SideBarActiveIndicatorUser = styled.div`
  position: absolute;
  display: ${(props) => (props.currentItemIndex < 0 ? "none" : "block")};
  width: ${getRem(2)};
  height: ${getRem(34)};
  left: ${getRem(6)};
  top: ${(props) => getRem((props.currentItemIndex * 6.4 + 1.4) * 10)};
  background: ${(props) => props.theme.colors.dhlRed};
  transition: top 200ms;
`;

SideBarActiveIndicatorUser.defaultProps = {
  currentItemIndex: 0,
};

SideBarActiveIndicatorUser.propTypes = {
  currentItemIndex: PropTypes.number,
  offsetTop: PropTypes.number,
};

export default SideBarActiveIndicatorUser;
