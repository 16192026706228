import * as C from "./AddressBookSuccessToast.constants";
import messages from "./AddressBookSuccessToast.messages";

export const getMessage = (action: C.AddressBookSuccessfulAction) => {
  switch (action) {
    case C.AddressBookSuccessfulAction.ADD_ADDRESS_SUCCESS:
      return messages.addressAddedToAddressBookSuccess;
    case C.AddressBookSuccessfulAction.EDIT_ADDRESS_SUCCESS:
      return messages.editAddressInAddressBookSuccess;
    case C.AddressBookSuccessfulAction.DELETE_ADDRESS_SUCCESS:
      return messages.deleteAddressFromAddressBookSuccess;
  }
};
