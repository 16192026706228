import globalDateMessages from "constants/dateMessages";
import { dispatchInteractionEvent } from "adobe-analytics/content-interaction-events/ContentInteractionEvents.helpers";
import { DashboardInteractions } from "adobe-analytics/content-interaction-events/dashboard/DashboardEvents.constants";
import { MapTrackingInteractions } from "adobe-analytics/content-interaction-events/map-tracking/MapTrackingEvents.constants";
import { ShipmentsInteractions } from "adobe-analytics/content-interaction-events/shipments/ShipmentsEvents.constants";
import { WidgetType } from "components/templates/WidgetContent/WidgetContent.constants";
import moment from "moment-timezone";
import { shipmentListType } from "routes/Shipments/constants";

export const getFormattedDate = (intl) => (date, withTime, longYear) => {
  if (date) {
    const convertedDate = date.replace("Z[UTC]", "+00:00");
    if (withTime) {
      return moment
        .parseZone(convertedDate)
        .format(intl.formatMessage(globalDateMessages.longDatetime));
    }
    return longYear
      ? moment
          .parseZone(convertedDate)
          .format(intl.formatMessage(globalDateMessages.longDate))
      : moment
          .parseZone(convertedDate)
          .format(intl.formatMessage(globalDateMessages.shortDate));
  } else {
    return "--";
  }
};

export const dispatchFollowUnfollowEventForWidgetType = (
  type,
  isFavorite,
  row
) => {
  if (Object.values(WidgetType).includes(type)) {
    dispatchInteractionEvent({
      eventName: isFavorite
        ? DashboardInteractions.UNFOLLOWING_SHIPMENT
        : DashboardInteractions.FOLLOWING_SHIPMENT,
      position: row,
    });
  } else if (type === shipmentListType.SHIPMENTS_LIST) {
    dispatchInteractionEvent({
      eventName: isFavorite
        ? ShipmentsInteractions.UNFOLLOWING_SHIPMENT
        : ShipmentsInteractions.FOLLOWING_SHIPMENT,
      position: row,
    });
  } else if (type === shipmentListType.MAP_TRACKING_LIST) {
    dispatchInteractionEvent({
      eventName: isFavorite
        ? MapTrackingInteractions.UNFOLLOWING_SHIPMENT
        : MapTrackingInteractions.FOLLOWING_SHIPMENT,
    });
  } else if (type === shipmentListType.MAP_SHIPMENTS_LIST) {
    dispatchInteractionEvent({
      eventName: isFavorite
        ? MapTrackingInteractions.UNFOLLOWING_SHIPMENT
        : MapTrackingInteractions.FOLLOWING_SHIPMENT,
      position: row,
    });
  }
};
