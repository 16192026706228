import React from "react";
import { colors } from "react-lib";
import SvgChevronBack from "react-lib/es/icons/SvgChevronBack";
import * as C from "./ModalHeaderYellow.constants";
import * as S from "./ModalHeaderYellow.styles";

const ModalHeaderYellow = ({
  onBackArrowClick,
  headerText,
}: C.ModalHeaderYellowProps) => (
  <S.ModalHeaderYellow>
    <S.StyledSVGWrapper
      showPointer
      fill={colors.webBlack}
      onClick={onBackArrowClick}
    >
      <SvgChevronBack />
    </S.StyledSVGWrapper>
    <S.StyledHeader>
      <S.StyledHeaderText>{headerText}</S.StyledHeaderText>
    </S.StyledHeader>
  </S.ModalHeaderYellow>
);

export default ModalHeaderYellow;
