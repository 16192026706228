import { FeatureFlagName } from "constants/getConfigClient";
import { ReactComponent as Analytics } from "assets/icons/myDHLi/menu/analytics.svg";
import { ReactComponent as Orders } from "assets/icons/myDHLi/menu/barcode.svg";
import { ReactComponent as PaperRegistration } from "assets/icons/myDHLi/menu/paper-registration.svg";
import { ReactComponent as Quotes } from "assets/icons/myDHLi/menu/quotes.svg";
import React from "react";
import SvgInterfaceReporting from "react-lib/es/icons/SvgIconUserInterfaceReporting";
import SvgLayout from "react-lib/es/icons/SvgLayout";
import SvgParcel from "react-lib/es/icons/SvgParcel";
import Customs from "../components/atoms/Icons/Customs";

export const SideBarItemsObject = {
  DASHBOARD: {
    icon: <SvgLayout />,
    route: "/",
    text: "Dashboard",
    type: "DASHBOARD",
  },
  REPORTS: {
    icon: <SvgInterfaceReporting />,
    route: "/reporting",
    text: "Reports",
    type: "REPORTS",
  },
  TRACK: {
    icon: <SvgParcel />,
    route: "/shipments",
    type: "SHIPMENTS",
  },
  OQT: {
    icon: <Quotes />,
    route: "/quotes",
    type: "QUOTES",
  },
  ANALYTICS: {
    icon: <Analytics />,
    route: "/analytics",
    type: "ANALYTICS",
  },
  BOOK: {
    icon: <PaperRegistration />,
    route: "/bookings",
    type: "BOOK",
  },
  CDZ: {
    icon: <Customs />,
    route: "/cdz",
    type: "CDZ",
  },
  [FeatureFlagName.PO_SKU]: {
    icon: <Orders />,
    route: "/orders",
    type: FeatureFlagName.PO_SKU,
  },
};
