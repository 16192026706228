import { cdzModule } from "constants/cdz";
import { cdzColumnToFilter } from "constants/cdzColumns";
import FreeText from "components/organisms/Filters/FreeText";
import List from "components/organisms/Filters/List";
import React from "react";
import get from "utils/get";

const getDropdownContent = (item, bucket, onOutsideClick) => {
  switch (item) {
    case "CustomsClearanceNumber":
    case "Reference":
    case "HouseBill": {
      return (
        <FreeText
          module={cdzModule}
          filter={cdzColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "Status": {
      return (
        <List
          filter={get(cdzColumnToFilter, "Status")}
          items={[{ title: "Import Customs Cleared", key: "CLC" }]}
          onOutsideClick={onOutsideClick}
          module={cdzModule}
        />
      );
    }
    case "StatusDateTime": {
      return (
        <List
          filter={get(cdzColumnToFilter, "StatusDateTime")}
          items={[
            { title: "Last 24 hours", key: "24/HOURS" },
            { title: "Last 48 hours", key: "48/HOURS" },
            { title: "Last 72 hours", key: "72/HOURS" },
            { title: "Last 7 days", key: "7/DAYS" },
            { title: "Last 10 days", key: "10/DAYS" },
            { title: "Last 14 days", key: "14/DAYS" },
            { title: "Last 31 days", key: "31/DAYS" },
            { title: "Last 62 days", key: "62/DAYS" },
            { title: "Last 93 days", key: "93/DAYS" },
          ]}
          onOutsideClick={onOutsideClick}
          module={cdzModule}
        />
      );
    }
    default: {
      return "";
    }
  }
};

export default getDropdownContent;
