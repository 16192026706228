import * as MDM from "constants/masterDataManagement";
import { FormApi } from "final-form";
import { prepareAddressFromGoogleSuggestions } from "hooks/useAddressSuggestions";
import rawCountries from "react-lib/es/molecules/PhoneInput/countryData/rawCountires";
import { FormValues } from "routes/MakeABooking/BookingRoute/BookingRoute.constants";
import { formFields as addressBookFormFields } from "./AddressBook/AddressModal/components/AddressBookModalContent/AddressBookForm/AddressBookFormFields/AddressBookFormFields.constants";
import * as C from "./StreetAddress.constants";

const Google = window?.google;
const autocompleteService =
  Google && new Google.maps.places.AutocompleteService();
const GeocoderService = Google && new Google.maps.Geocoder();
export const isGoogleAPILoaded = () => !!window.google;

const googleLookup = async (
  countryCode: string,
  item: google.maps.places.AutocompletePrediction
): Promise<C.MapProviderDataType> => {
  if (!GeocoderService) {
    throw new Error("No results");
  }
  const { results } = await GeocoderService.geocode({ placeId: item.place_id });

  if (!results?.length) {
    throw new Error("No results");
  }

  const geoVal = results[0];

  const isGBGoogleSearch = countryCode === "GB";
  const { zipCode, addressLine, city, areaLevel1, areaLevel2 } =
    prepareAddressFromGoogleSuggestions(geoVal, item, countryCode);

  return {
    place_id: geoVal.place_id,
    zipCode,
    addressLine,
    label: geoVal?.formatted_address,
    label_native: item.description,
    city,
    stateProvince: isGBGoogleSearch ? areaLevel2 : areaLevel1,
  };
};

export const getGoogleOptions = async (
  keyword: string,
  countryCode: string
): Promise<C.SearchOptionType[]> => {
  const { predictions } = await autocompleteService.getPlacePredictions({
    input: keyword,
    componentRestrictions: { country: countryCode },
  });

  return predictions.map((item) => ({
    label: item.description,
    value: item.place_id,
    lookup: () => googleLookup(countryCode, item),
  }));
};

export const getPhoneCountryPrefix = (country?: string): undefined | string => {
  if (country === undefined) {
    return undefined;
  }

  const lcCountry = country.toLowerCase();
  const data = rawCountries.find((data) => data[C.iso2code] === lcCountry);

  return data?.[C.internationalDialCode];
};

const getBaseFormFields = (fieldPrefix: string) => ({
  countryFieldName: `${fieldPrefix}.address.countryCode`,
  addressLine2: `${fieldPrefix}.address.addressLine2`,
  addressLine1: `${fieldPrefix}.address.addressLine2`,
  postalCode: `${fieldPrefix}.address.postalCode`,
  city: `${fieldPrefix}.address.city`,
  stateProvince: `${fieldPrefix}.address.stateProvince`,
  companyName: `${fieldPrefix}.companyName`,
  contactDetailsName: `${fieldPrefix}.contactDetails.name`,
  email: `${fieldPrefix}.contactDetails.email`,
  phone: `${fieldPrefix}.contactDetails.phone`,
});

export const generateFormFields = (
  isForAddressBook: boolean,
  fieldPrefix: string
) => {
  const { countryFieldName } = getBaseFormFields(fieldPrefix);
  return {
    countryFieldName: isForAddressBook
      ? addressBookFormFields.countryCode
      : countryFieldName,
    country: isForAddressBook
      ? addressBookFormFields.country
      : `${fieldPrefix}.address.country`,
    addressLine2: isForAddressBook
      ? addressBookFormFields.street
      : `${fieldPrefix}.address.addressLine2`,
    addressLine1: isForAddressBook
      ? addressBookFormFields.streetAddress
      : `${fieldPrefix}.address.addressLine1`,
    postalCode: isForAddressBook
      ? addressBookFormFields.zip
      : `${fieldPrefix}.address.postalCode`,
    city: isForAddressBook
      ? addressBookFormFields.city
      : `${fieldPrefix}.address.city`,
    stateProvince: isForAddressBook
      ? addressBookFormFields.states
      : `${fieldPrefix}.address.stateProvince`,
    preferredAddressInputType: isForAddressBook
      ? addressBookFormFields.preferredAddressInputType
      : `${fieldPrefix}.address.preferredAddressInputType`,
    companyName: isForAddressBook
      ? addressBookFormFields.companyName
      : `${fieldPrefix}.companyName`,
    contactDetailsName: isForAddressBook
      ? addressBookFormFields.contactName
      : `${fieldPrefix}.contactDetails.name`,
    email: `${fieldPrefix}.contactDetails.email`,
    phone: isForAddressBook
      ? addressBookFormFields.phoneNumber
      : `${fieldPrefix}.contactDetails.phone`,
  };
};

export const setAddressValues = (
  { addressLine, city, zipCode, stateProvince }: C.MapProviderDataType,
  form: FormApi,
  fieldPrefix: string,
  isForAddressBook: boolean
) => {
  const {
    addressLine1,
    city: cityField,
    postalCode,
    stateProvince: stateProvinceField,
  } = generateFormFields(isForAddressBook, fieldPrefix);
  form.batch(() => {
    form.change(addressLine1, addressLine);
    form.change(cityField, city);
    form.change(postalCode, zipCode);
    form.change(stateProvinceField, stateProvince);
    form.resetFieldState(addressLine1);
    form.resetFieldState(cityField);
    form.resetFieldState(postalCode);
    form.resetFieldState(stateProvinceField);
  });
};

export const formUpdateOnCountryChange = ({
  form,
  fieldPrefix,
  newCountry,
  setInputValue,
  setSelectSearchValue,
  setAddressInputTypeField,
  isForAddressBook,
  isLocalityBasedAddress,
}: C.FormUpdateOnCountryChangeType) => {
  const {
    addressLine1,
    city,
    postalCode,
    stateProvince,
    countryFieldName,
    addressLine2,
    country,
    phone,
    companyName,
    contactDetailsName,
    email,
  } = generateFormFields(isForAddressBook, fieldPrefix);
  setInputValue("");
  setSelectSearchValue({});
  const { code, countryCode, label, value } = newCountry;
  const newPhonePrefix = getPhoneCountryPrefix(code === "DX" ? "AE" : code);

  form.batch(() => {
    if (isForAddressBook) {
      form.change(country, { code, countryCode, label, value });
    } else {
      form.change(countryFieldName, value);
    }

    form.change(addressLine1, "");
    form.change(city, "");
    form.change(stateProvince, "");
    form.change(companyName, "");
    form.change(contactDetailsName, "");
    form.change(email, "");
    form.change(phone, newPhonePrefix ?? "");
    form.change(fieldPrefix + ".terminalCode", "");

    form.resetFieldState(countryFieldName);
    form.resetFieldState(addressLine1);
    form.resetFieldState(city);
    form.resetFieldState(stateProvince);
    form.resetFieldState(companyName);
    form.resetFieldState(contactDetailsName);
    form.resetFieldState(email);
    form.resetFieldState(phone);

    if (!isLocalityBasedAddress) {
      form.change(postalCode, "");
      form.resetFieldState(postalCode);
      if (isGoogleAPILoaded()) {
        form.change(addressLine2, "");
        form.resetFieldState(addressLine2);
      }
    }

    setAddressInputTypeField();
  });
};

const getPreservedPartyData = (
  form: FormApi<FormValues, Partial<FormValues>>,
  prefix: C.PartyPrefixType
) => {
  const countryCode = form.getState().values?.[prefix]?.address?.countryCode;
  const phone = form.getState().values?.[prefix]?.contactDetails?.phone;
  const useTerminal = form.getState().values?.[prefix]?.useTerminal;
  const terminalCode = form.getState().values?.[prefix]?.terminalCode;
  return {
    address: {
      ...C.routeCollapsedFormInitialData.address,
      countryCode,
    },
    contactDetails: {
      phone,
    },
    useTerminal,
    terminalCode,
  };
};

export const resetRouteFormData = (
  form: FormApi<FormValues>,
  prefix: C.PartyPrefixType,
  keepCountryAndUseTerminal?: boolean
) => {
  const preserveData = keepCountryAndUseTerminal
    ? getPreservedPartyData(form, prefix)
    : {};

  form.change(prefix, {
    ...C.routeCollapsedFormInitialData,
    ...preserveData,
  });
};

export const restrictCountryList = (
  originCountries: MDM.Country[],
  destinationCountries: MDM.Country[],
  billingCountries: MDM.Country[],
  unfilteredCountries: MDM.Country[],
  form: FormApi,
  prefix: string
) => {
  switch (prefix) {
    case "shipper":
    case "pickup":
      return originCountries;

    case "delivery":
    case "consignee":
      return destinationCountries;

    case "party":
      return billingCountries;

    case "addressBook":
    case "notify":
      return unfilteredCountries;

    default:
      return [];
  }
};

export const getCountriesCountryCodes = (countries: MDM.Country[]) =>
  countries.map((country) => country.countryCode);
