import { media } from "react-lib";
import styled from "styled-components";

export const LogoWrapper = styled.span`
  border: none;
  background: none;
  outline: none;

  &:focus {
    outline: none;
  }

  ${media.from("lg")} {
    padding-left: 1.25rem;
  }
`;
