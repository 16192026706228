import { StepStateEnum } from "react-lib/es/atoms/ProgressStep/ProgressStep.constants";

export const billingForEmailQuoteStepLabel = "BillingForEmailQuote";

export const billingPath = "billing";
export const bookingPath = "booking";

export const BookingPathsNames = {
  SERVICE: `/${bookingPath}/service`,
  ROUTE: `/${bookingPath}/route`,
  CARGO: `/${bookingPath}/cargo`,
  DOCUMENTS: `/${bookingPath}/documents`,
  BILLING: `/${bookingPath}/billing`,
  SUMMARY: `/${bookingPath}/summary`,
};

export const minPhoneNumberLength = 7;

export const progressItems = [
  {
    key: 1,
    stepLabel: "Service",
    stepState: StepStateEnum.CURRENT,
    path: "/service",
  },
  {
    key: 2,
    stepLabel: "Route",
    stepState: StepStateEnum.PARTIAL,
    path: "/route",
  },
  {
    key: 3,
    stepLabel: "Cargo",
    stepState: StepStateEnum.PARTIAL,
    path: "/cargo",
  },
  {
    key: 4,
    stepLabel: "Documents",
    stepState: StepStateEnum.FINISHED,
    path: "/documents",
  },
  {
    key: 5,
    stepLabel: "Billing",
    stepState: StepStateEnum.UPCOMING,
    path: `/${billingPath}`,
  },
  {
    key: 6,
    stepLabel: billingForEmailQuoteStepLabel,
    stepState: StepStateEnum.UPCOMING,
    path: `/${billingPath}`,
  },
];

export const pathToProgressStepLabels = progressItems
  .slice(0, -1)
  .reduce((p, n) => {
    p[n.path.slice(1)] = n.stepLabel;
    return p;
  }, {});
