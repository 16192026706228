/* eslint-disable import/order */
import "react-dates/initialize";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { DayPickerRangeController } from "react-dates";
import { getRem } from "../../../utils/css";
import DateRangeWrapper from "./Partials/DateRangeWrapper";
import NavNext from "./Partials/NavNext";
import NavPrev from "./Partials/NavPrev";
import styled from "styled-components";
import IconButton from "components/atoms/Buttons/IconButton";
import LinkButton from "components/atoms/Buttons/LinkButton";
import moment from "moment-timezone";
import useOutsideClick from "hooks/useOutsideClick";

const RenderInfoWrapper = styled.div`
  padding: 0 ${getRem(20)} ${getRem(20)} ${getRem(20)};
  display: flex;
  justify-content: space-between;
`;

const ButtonArea = styled.div`
  flex: 0 0 auto;
`;
const LinkArea = styled.div`
  flex: 1 1 auto;
  text-align: center;
`;

const DateRangePickerController = (props) => {
  const [startDate, setStartDate] = useState(props.startDate || null);
  const [endDate, setEndDate] = useState(props.endDate || null);
  const [focusedInput, setFocusedInput] = useState(props.focusedInput || null);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, props.onOutsideClick);
  const numberOfMonths =
    props.numberOfMonths ||
    (window.matchMedia("(max-width: 1024px)").matches ? 1 : 2);

  const daySize = window.matchMedia("(max-width: 400px)").matches ? 36 : 44;
  const renderInfo = () => {
    return (
      <RenderInfoWrapper>
        <ButtonArea>
          <IconButton
            size={getRem(14)}
            padding={
              getRem(9) +
              " " +
              getRem(10) +
              " " +
              getRem(7) +
              " " +
              getRem(10) +
              " "
            }
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
              setFocusedInput("startDate");
            }}
          >
            {props.text.clear}
          </IconButton>
        </ButtonArea>
        {numberOfMonths > 1 && (
          <LinkArea>
            <LinkButton
              onClick={() => {
                setStartDate(moment(new Date()));
                setEndDate(null);
              }}
            >
              {props.text.today}
            </LinkButton>
            {props.past ? (
              <LinkButton
                onClick={() => {
                  setStartDate(moment(new Date()).add(-1, "days"));
                  setEndDate(null);
                }}
              >
                {props.text.yesterday}
              </LinkButton>
            ) : (
              <LinkButton
                onClick={() => {
                  setStartDate(moment(new Date()).add(1, "days"));
                  setEndDate(null);
                }}
              >
                {props.text.tomorrow}
              </LinkButton>
            )}
            {props.past ? (
              <LinkButton
                onClick={() => {
                  setStartDate(moment(new Date()).add(-7, "days"));
                  setEndDate(moment(new Date()));
                }}
              >
                {props.text.lastWeek}
              </LinkButton>
            ) : (
              <LinkButton
                onClick={() => {
                  setStartDate(moment(new Date()));
                  setEndDate(moment(new Date()).add(7, "days"));
                }}
              >
                {props.text.nextWeek}
              </LinkButton>
            )}
          </LinkArea>
        )}
        <ButtonArea>
          <IconButton
            size={getRem(14)}
            padding={
              getRem(9) +
              " " +
              getRem(10) +
              " " +
              getRem(7) +
              " " +
              getRem(10) +
              " "
            }
            onClick={() => {
              props.onDatesApply({ startDate: startDate, endDate: endDate });
            }}
          >
            {props.text.apply}
          </IconButton>
        </ButtonArea>
      </RenderInfoWrapper>
    );
  };
  return (
    <DateRangeWrapper ref={wrapperRef}>
      <DayPickerRangeController
        initialVisibleMonth={() =>
          props.past && numberOfMonths > 1
            ? moment().subtract(1, "months")
            : moment()
        }
        numberOfMonths={numberOfMonths}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={(obj) => {
          setStartDate(obj.startDate);
          setEndDate(obj.endDate);
        }}
        focusedInput={focusedInput}
        onFocusChange={() => {
          focusedInput === "endDate"
            ? setFocusedInput("startDate")
            : setFocusedInput("endDate");
        }}
        hideKeyboardShortcutsPanel
        // screenReaderInputMessage=""
        daySize={daySize}
        isOutsideRange={() => false}
        navPrev={<NavPrev />}
        navNext={<NavNext />}
        minimumNights={0}
        // displayFormat={() => "YYYY/MM/DD"}
        renderCalendarInfo={renderInfo}
        isDayBlocked={(day) =>
          props.past ? moment(day).isAfter(moment(new Date()), "day") : false
        }
      />
    </DateRangeWrapper>
  );
};

DateRangePickerController.defaultProps = {
  focusedInput: "startDate",
  text: {
    clear: "Clear",
    apply: "Apply",
    today: "Today",
    yesterday: "Yesterday",
    tomorrow: "Tomorrow",
    nextWeek: "Next Week",
    lastWeek: "Last Week",
  },
};

DateRangePickerController.propTypes = {
  /**
   * Also any propType available for DateRangePicker component of react-dates library
   * */
  onDatesApply: PropTypes.func.isRequired,
  onOutsideClick: PropTypes.func,
  focusedInput: PropTypes.oneOf([null, "startDate", "endDate"]),
  text: PropTypes.object,
};
export default DateRangePickerController;
