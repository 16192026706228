import { IntlShape } from "react-intl";
import {
  ChangePasswordFormFieldName,
  ChangePasswordFormValues,
  PasswordUpdateErrorEnum,
  minPasswordLength,
} from "./ChangePasswordModal.constants";
import { messages } from "./ChangePasswordModal.messages";

const validateNewPassword = (
  intl: IntlShape,
  value?: string,
  reEnteredPassword?: string,
  currentPassword?: string,
  apiError?: PasswordUpdateErrorEnum
) => {
  if (!value) {
    return intl.formatMessage(messages.required);
  }

  if (value.length < minPasswordLength) {
    return intl.formatMessage(messages.passwordTooShort);
  }

  if (value !== reEnteredPassword) {
    return intl.formatMessage(messages.passwordsDoNotMatch);
  }

  if (apiError === PasswordUpdateErrorEnum.COMMON_WORD) {
    return intl.formatMessage(messages.passwordHaveCommonWord);
  }
};

const validateCurrentPassword = (
  intl: IntlShape,
  value?: string,
  apiError?: PasswordUpdateErrorEnum
) => {
  if (!value) {
    return intl.formatMessage(messages.required);
  }

  if (apiError === PasswordUpdateErrorEnum.INVALID_CURRENT_PASSWORD) {
    return intl.formatMessage(messages.ivalidCurrentPassowrd);
  }
};

export const createFormValidator =
  (intl: IntlShape, apiError?: PasswordUpdateErrorEnum) =>
  (values: ChangePasswordFormValues) => ({
    [ChangePasswordFormFieldName.CURRENT_PASSWORD]: validateCurrentPassword(
      intl,
      values.currentPassword,
      apiError
    ),
    [ChangePasswordFormFieldName.NEW_PASSWORD]: validateNewPassword(
      intl,
      values.newPassword,
      values.confirmPassword,
      values.currentPassword,
      apiError
    ),
    [ChangePasswordFormFieldName.CONFIRM_PASSWORD]: validateNewPassword(
      intl,
      values.confirmPassword,
      values.newPassword,
      values.currentPassword,
      apiError
    ),
  });
