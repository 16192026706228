/*
    TODO: Backend Alert Service will support separate ids for each service (NOQT, BOOK, DASHBOARD, SHIPMENTS, REPORTS),
    but in initial configuration there will be only NOQT for Q+B specific alerts and PORTAL for the rest of the portal.
*/
export enum Service {
  NOQT = "NOQT",
  BOOK = "PORTAL",
  DASHBOARD = "PORTAL",
  SHIPMENTS = "PORTAL",
  REPORTS = "PORTAL",
  PORTAL = "PORTAL",
}

export interface Alert {
  id: string;
  text: string;
  title: string;
  url: string;
}

export interface AlertsProps {
  service: Service;
}
