import SiteLogo from "components/SiteLogo/SiteLogo";
import React from "react";
import * as S from "./TopBar.styles";
import UserAvatarSection from "./UserAvatarSection/UserAvatarSection";

interface TopBarProps {
  isAvatarVisible?: boolean;
}

const TopBar: React.FC<TopBarProps> = ({
  children,
  isAvatarVisible = true,
}) => (
  <S.TopBarWrapper>
    <S.LogoWrapper>
      <SiteLogo />
    </S.LogoWrapper>
    {children}
    {isAvatarVisible && <UserAvatarSection />}
  </S.TopBarWrapper>
);

export default TopBar;
