import noop from "lodash/noop";
import { createContext, useContext } from "react";
import { LanguageObject } from "./Translations.constants";

export const languagesContext = createContext<Array<LanguageObject>>([]);
export const loadingLanguagesContext = createContext(false);
export const languageContext = createContext("");
export const setContext = createContext(noop);

export const useLanguages = () => useContext(languagesContext);
export const useLanguage = () => useContext(languageContext);
export const useSetLanguage = () => useContext(setContext);
