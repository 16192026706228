import types from "./action-types";

export const setAppState = (key, value) => ({
  type: types.SET_STATE,
  payload: { key, value },
});
export const incAppScreenChange = () => ({
  type: types.INC_SCREEN_CHANGE,
  payload: {},
});
