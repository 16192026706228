import enMessages from "localeData/en.json";
import { useEffect, useState } from "react";
import { loadTranslations } from "utils/languages";

const useLanguageMessages = (language) => {
  const [messages, setMessages] = useState(enMessages);

  useEffect(() => {
    if (language) {
      let canceled = false;
      loadTranslations(language).then((messages) => {
        if (!canceled) {
          setMessages(messages || enMessages);
        }
      });
      return () => {
        canceled = true;
      };
    }
  }, [language]);

  return messages;
};

export default useLanguageMessages;
