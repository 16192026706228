import { useQuery } from "@apollo/client";
import Loading from "components/molecules/Loader/Loading";
import PropTypes from "prop-types";
import getColumnSuggestion from "queries/Track/getColumnSuggestion";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  setBookingsListFilter,
  setQuotesFilter,
  setShipmentFilter,
} from "redux/userSettings/actions";
import SimpleBar from "simplebar-react";
import styled from "styled-components";
import { getRem } from "utils/css";
import get from "utils/get";
import CustomOption from "./Partials/CustomOption";
import Search from "./Partials/SearchBuckets";

const FilterAutoCompleteWrapper = styled.div`
  width: ${getRem(280)};
  max-height: ${getRem(462)};
  height: fit-content;
`;

const WrapperLoading = styled.div`
  margin-bottom: ${getRem(70)};
`;

const NoMatch = styled.div`
  display: flex;
  padding: ${getRem(14)} 0 ${getRem(14)} ${getRem(14)};
  background-color: ${(p) => p.theme.colors.gray08};
`;

const FilterAutoComplete = (props) => {
  const [inputValue, setInputValue] = useState("");

  const filter = {
    ...props.shipmentFilter,
    searchKeywords: props.searchKeywords,
    searchPhrases: props.searchPhrases,
  };

  const items = get(props, "bucket.bucketItems", []);

  const defaultOptions = items.map((item) => ({
    value: item.key || item.title,
    title: item.title || item.key,
    count: item.count,
  }));

  const { data, loading } = useQuery(getColumnSuggestion, {
    variables: {
      filter: filter,
      search: inputValue,
      column: props.columnKey,
      corporatePartner: get(props, "visibilityScope.key"),
    },
    skip: !inputValue,
  });

  let options = get(data, "getColumnSuggestions.data", []).map((value) => ({
    value: value.key,
    title: value.title,
    count: value.count,
  }));

  if (!inputValue) {
    options = defaultOptions;
  }

  return (
    <FilterAutoCompleteWrapper>
      <Search
        onChange={(value) => {
          setInputValue(value);
        }}
        onReset={() => setInputValue(null)}
        placeholder={props.placeholder}
      />

      {loading ? (
        <WrapperLoading>
          <Loading />
        </WrapperLoading>
      ) : options && options.length ? (
        <SimpleBar style={{ maxHeight: 416 }}>
          {options.map((option) => {
            return (
              <CustomOption
                key={option.value}
                label={option.title}
                data={option}
                selectProps={{
                  inputValue,
                }}
                onClick={() => {
                  if (props.module === "quotes") {
                    props.setQuotesFilter(props.filter, option.value);
                  } else if (props.module === "bookings") {
                    props.setBookingsListFilter(props.filter, option.value);
                  } else {
                    props.setShipmentFilter(props.filter, option.value);
                  }
                  props.onOutsideClick();
                }}
              />
            );
          })}
        </SimpleBar>
      ) : (
        inputValue && (
          <NoMatch style={{ marginBottom: "10px" }}>No Match</NoMatch>
        )
      )}
    </FilterAutoCompleteWrapper>
  );
};

FilterAutoComplete.defaultProps = {
  defaultOptions: [],
  onChange: (item) => {},
  label: null,
  placeholder: "Search",
  isClearable: true,
  openMenuOnFocus: true,
  defaultValue: null,
  columnKey: "FLIGHT_NUMBER",
  value: undefined,
};

FilterAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  openMenuOnFocus: PropTypes.bool,
  openMenuOnClick: PropTypes.bool,
  escapeClearsValue: PropTypes.bool,
  columnKey: PropTypes.string,
  bucket: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default connect(
  (state) => ({
    visibilityScope: get(state, "userSettings.visibilityScope", {}),
    shipmentFilter: get(state, "userSettings.shipmentFilter", {}),
    searchKeywords: get(state, "userSettings.searchKeywords", []),
    searchPhrases: get(state, "userSettings.searchPhrases"),
  }),
  { setShipmentFilter, setQuotesFilter, setBookingsListFilter }
)(FilterAutoComplete);
