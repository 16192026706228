import * as C from "./constants";

const notificationReducer = (
  state: C.ToastNotificationState = C.initialState,
  action: C.ToastNotificationAction
): C.ToastNotificationState => {
  switch (action.type) {
    case C.ToastNotificationActionType.SHOW_NOTIFICATION:
      return {
        ...action.notificationPayload,
      };
    case C.ToastNotificationActionType.HIDE_NOTIFICATION:
      return C.initialState;
    default:
      return state;
  }
};

export default notificationReducer;
