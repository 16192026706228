import Sort from "components/molecules/Sort/Sort";
import React from "react";
import styled from "styled-components";
import Tip from "../../../molecules/Tip/Tip1";
import Countries from "../../Filters/CountryDropdown/CountryDropdown";
import DateRange from "../../Filters/DateRange";
import FreeText from "../../Filters/FreeText";
import List from "../../Filters/List";
import { ShipmentColContainer } from "../../ShipmentRow/Partials/ShipmentRowAndCol";
import {
  ColText,
  DropdownContent,
  HighLightHover,
} from "../../TableHeader/partials/Header";

export const StyledHighLightHover = styled(HighLightHover)<{
  isActive: boolean;
}>``;

export const StyledSort = styled(Sort)<{ item: any }>``;

export const StyledColText = styled(ColText)<{
  size: number | string;
}>``;

export const StyledDropdownContent = styled(DropdownContent)<{
  isDate: boolean;
  isRight: boolean;
}>``;

export const HeaderCol = styled(ShipmentColContainer)<{ customSize: number }>`
  justify-content: flex-start;
  position: relative;
`;

export const StyledList = styled(List)<{
  filter: string;
  onOutsideClick: () => void;
  items?: { title: string; key: string | boolean }[];
}>``;

export const StyledDateRange = styled(DateRange)<{
  filter: string;
  onOutsideClick: () => void;
}>``;

export const StyledFreeText = styled(FreeText)<{
  filter: string;
  onOutsideClick: () => void;
}>``;

export const StyledCountries = styled(Countries)<{
  filter: string;
  onOutsideClick: () => void;
}>``;

export const StyledTip = styled(Tip)<{
  children?: React.ReactNode;
  content?: string;
  topOffset?: number;
  rightOffset?: number;
  TipFontSize?: number;
  fromDropdown?: boolean;
  fromBucket?: boolean;
  timeout?: number;
  disableClickEvent?: boolean;
  TipOnSide?: boolean;
  multiline?: boolean;
  breakLine?: boolean;
}>``;
