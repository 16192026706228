import gql from "graphql-tag";

export const loadMenuItems = gql`
  query getMenuItems($corporatePartnerId: Int) {
    loadMenuItems(corporatePartnerId: $corporatePartnerId) {
      order
      type
      label
      translationKey: weblate_key
    }
  }
`;

export const loadAvailableWidgets = gql`
  query getAvailableWidgets($corporatePartnerId: Int) {
    loadAvailableWidgets(corporatePartnerId: $corporatePartnerId) {
      widgetId
      order
      type
      header
      size
      isDefault
      isPromoted
      description
      services {
        id
        name
      }
      dashboard {
        dashboardId
        order
        type
        header
        description
        mainReference {
          name
          url
          applicationUuid
          sheetUuid
        }
        widgetReference {
          name
          url
          applicationUuid
          sheetUuid
        }
      }
      translationKey: weblate_key
    }
  }
`;

export const loadAnalyticsItems = gql`
  query getAnalyticsItems($corporatePartnerId: Int) {
    loadAnalyticsItems(corporatePartnerId: $corporatePartnerId) {
      dashboardId
      order
      type
      header
      description
      mainReference {
        name
        url
        applicationUuid
        sheetUuid
      }
      widgetReference {
        name
        url
        applicationUuid
        sheetUuid
      }
      translationKey: weblate_key
    }
  }
`;

export const getIsUserReadyForVote = gql`
  query GetIsUserReadyForVote {
    getIsUserReadyForVote {
      readyForVote
      voteAt
    }
  }
`;

export const markNPSSurveyShown = gql`
  mutation MarkNPSSurveyShown {
    markNPSSurveyShown {
      readyForVote
      voteAt
    }
  }
`;
