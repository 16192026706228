import { FindingMethod } from "adobe-analytics/page-load-event/constants";
import { ReactComponent as DefaultIcon } from "assets/icons/myDHLi/technology.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import { colors } from "react-lib";
import styled from "styled-components";
import { getRem } from "../../../../utils/css";
import SideBarItemWrapper from "./SideBarItemWrapper";

const Initials = styled.div`
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  color: white;
  text-decoration: none;
  background: ${(p) =>
    p.isDemo ? p.theme.colors.black : p.theme.colors.postYellow};
  font-weight: bold;

  :focus {
    outline: none;
  }

  width: ${getRem(24)};
  height: ${getRem(24)};
  font-size: ${(p) => getRem(p.isDemo ? 7 : 14)};
  padding-top: ${getRem(1)};
`;
const SideBadge = styled.span`
  border: 1px solid ${colors.dhlRed};
  display: inline-block;
  padding: 0 4px;
  border-radius: 8px;
  font-size: 0.75rem;
  line-height: 1.34;
  margin-left: 0.75rem;
  font-weight: normal;
  position: relative;
  top: -1px;
`;
const SideBadgeText = styled.span`
  color: ${colors.dhlRed};
  position: relative;
  top: 1px;
`;
const SideBarItem = (props) => (
  <SideBarItemWrapper
    {...omit(props, [
      "icon",
      "iconType",
      "showBadge",
      "badgeText",
      "translationKey",
    ])}
    onClick={props.onClick}
    to={{
      pathname: props.route,
      state: { analyticsFindingMethodMenu: FindingMethod.MENU },
    }}
    isListItem={props.isListItem}
  >
    {props.iconType === "path" ? (
      <img src={props.icon} alt={props.text} />
    ) : props.iconType === "initials" ? (
      <Initials isDemo={props.isDemo}>{props.icon}</Initials>
    ) : (
      <SVGWrapper showPointer fill="#000">
        {props.icon || <DefaultIcon />}
      </SVGWrapper>
    )}
    <label>{props.text}</label>
    {props.showBadge && (
      <SideBadge>
        <SideBadgeText>{props.badgeText}</SideBadgeText>
      </SideBadge>
    )}
  </SideBarItemWrapper>
);

SideBarItem.defaultProps = {
  icon: null,
  text: "Dashboard",
  badgeText: "NEW",
  showBadge: false,
  onClick: () => {},
  route: "/",
  userMenu: false,
};
SideBarItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  text: PropTypes.string,
  badgeText: PropTypes.string,
  showBadge: PropTypes.bool,
  onClick: PropTypes.func,
  route: PropTypes.string,
  userMenu: PropTypes.bool,
};

export default SideBarItem;
