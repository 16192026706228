import {
  getPrimaryColor,
  getSecondaryColor,
  getFontFamily,
  getFontColor,
  getGrayColor,
  getFontSize,
  getRadius,
  getUnit,
} from "constants/DHLPatternLibraryTheme";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

export const SIZES = {
  REGULAR: "regular",
  SMALL: "small",
};

export const VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TRANSPARENT: "transparent",
};

export const TYPES = {
  BUTTON: "button",
  RESET: "reset",
  SUBMIT: "submit",
};

// #region Component Styles
const getPaddingFromSize = (size) => {
  switch (size) {
    case SIZES.SMALL:
      return getUnit("regular");

    case SIZES.REGULAR:
    default:
      return getUnit("large");
  }
};

const getReducedPaddingFromSize = (size) => {
  const padding = getPaddingFromSize(size);

  return css`
    padding: calc(${padding} - 1px);
  `;
};

const PRIMARY_BUTTON = css`
  background-color: ${getPrimaryColor("400")};
  transition: background-color 200ms ease-in-out;

  // &:focus {
  //   box-shadow: 0 0 0 4px white, 0 0 0 6px ${getPrimaryColor("400")};
  // }

  /* Leave this after the focus style to prevent the box-shadow to appear on hover of a focused button */
  &:hover {
    background-color: ${getPrimaryColor("300")};
    box-shadow: unset;
  }

  &:disabled {
    color: ${getFontColor("inverted")};
    border: 1px solid transparent;
    background-color: ${getGrayColor("200")};
  }
`;

const SECONDARY_BUTTON = css`
  ${({ size }) => {
    const padding = getPaddingFromSize(size);

    return css`
      background-color: ${getFontColor("inverted")};
      border-color: ${getPrimaryColor("400")};
      color: ${getPrimaryColor("400")};

      &:focus,
      &:active {
        border-color: ${getPrimaryColor("300")};
        border-width: 2px;
        // box-shadow: 0 0 0 4px white, 0 0 0 6px ${getPrimaryColor("400")};
        // padding: calc(${padding} - 1px);
      }

      /* Leave this after the focus style to prevent the box-shadow to appear on hover of a focused button */
      &:hover {
        border-color: ${getPrimaryColor("300")};
        box-shadow: unset;
        color: ${getPrimaryColor("300")};
      }

      &:disabled {
        background-color: ${getFontColor("inverted")};
        border-color: ${getGrayColor("200")};
        color: ${getGrayColor("400")};
      }
    `;
  }}
`;

const TRANSPARENT_BUTTON = css`
  ${SECONDARY_BUTTON};
  background-color: transparent;
  border-color: ${getPrimaryColor("400")};

  &:focus,
  &:active {
    // box-shadow: 0 0 0 4px ${getSecondaryColor("400")},
    //   0 0 0 6px ${getPrimaryColor("400")};
    border-width: 2px;
    /*
      ${({ size }) => getReducedPaddingFromSize(size)};
     */
  }
`;

const getStyleForVariant = (variant) => {
  switch (variant) {
    case VARIANTS.SECONDARY:
      return SECONDARY_BUTTON;

    case VARIANTS.TRANSPARENT:
      return TRANSPARENT_BUTTON;

    case VARIANTS.PRIMARY:
    default:
      return PRIMARY_BUTTON;
  }
};

const Container = styled.button`
  ${({ size }) => {
    const padding = getPaddingFromSize(size);

    return css`
      background-color: ${getGrayColor("400")};
      border-radius: ${getRadius()};
      border: 1px solid transparent;
      color: ${getFontColor("inverted")};
      font-family: ${getFontFamily()};
      font-size: ${getFontSize("small")};
      font-weight: 700;
      min-height: auto;
      padding: ${padding};
      position: relative;
      text-align: center;
      width: ${({ isBlock }) => (isBlock ? "100%" : undefined)};

      &:hover,
      &:active,
      &:focus {
        outline: 0;
        border: 1px solid transparent;
      }

      ${({ variant }) => getStyleForVariant(variant)};
    `;
  }}
`;
// #endregion

export const Button = ({
  ariaControls,
  autoFocus,
  children,
  dataTracking,
  disabled,
  form,
  isBlock,
  onClick,
  size,
  type,
  variant,
  trackCode,
  style,
}) => (
  <Container
    data-track={trackCode}
    aria-controls={ariaControls}
    autoFocus={autoFocus}
    data-tracking={dataTracking}
    disabled={disabled}
    form={form}
    isBlock={isBlock}
    onClick={onClick}
    size={size}
    type={type}
    variant={variant}
    style={style}
  >
    {children}
  </Container>
);

Button.propTypes = {
  /**
   * The aria-controls attribute is a 'relationship attribute' which denotes which elements in a page an
   * interactive element or set of elements has control over and affects. It's commonly used to describe a
   * relationship between a button and the expandable region revealed by that button.
   *
   * (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Attributes)
   */
  ariaControls: PropTypes.string,
  /** When set the element is automatically focused when mounted. */
  autoFocus: PropTypes.bool,
  children: PropTypes.node.isRequired,
  /** The tracking value attached to the button as a data-tracking attribute. */
  dataTracking: PropTypes.string,
  disabled: PropTypes.bool,
  /**
   * The form element that the button is associated with (its form owner).
   * The value of the attribute must be the id attribute of a `<form>` element in the same document.
   * If this attribute is not specified, the <button> element will be associated to an ancestor
   * `<form>` element, if one exists. This attribute enables you to associate `<button>` elements to
   * `<form>` elements anywhere within a document, not just as descendants of `<form>` elements.
   *
   * (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Attributes)
   */
  form: PropTypes.string,
  /** Should the component expand to the full width of its parent? */
  isBlock: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(SIZES)),
  type: PropTypes.oneOf(Object.values(TYPES)),
  /** What's the button look and feel? Based on the `VARIANTS` constant. */
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

Button.defaultProps = {
  ariaControls: undefined,
  autoFocus: undefined,
  dataTracking: undefined,
  disabled: false,
  form: undefined,
  isBlock: false,
  onClick: Function.prototype,
  size: SIZES.REGULAR,
  type: TYPES.BUTTON,
  variant: VARIANTS.PRIMARY,
};
