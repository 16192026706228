import { proxyRoutes } from "constants/serverEndpoints";
import FileDownloadView from "components/FileDownloadView/FileDownloadView";
import Auth from "containers/Auth";
import useCorporatePartnerUUID from "hooks/useCorporatePartnerUUID";
import React from "react";
import { useParams } from "react-router-dom";

export const reportingDownloadPath =
  "/download-report-archive/report-file/:fileId";

const ReportingDownload: React.FC = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const corporatePartnerUUID = useCorporatePartnerUUID();
  const additionalParams = corporatePartnerUUID
    ? `?corporatePartnerUUID=${corporatePartnerUUID}`
    : "";
  return (
    <Auth>
      <FileDownloadView
        fileId={fileId}
        fileDownloadEndpoint={`${proxyRoutes.downloadReportArchive}`}
        fileDownloadAdditionalParams={additionalParams}
      />
    </Auth>
  );
};

export default ReportingDownload;
