import { defineMessages } from "react-intl";

export const messages = defineMessages({
  selectAll: {
    id: "cdz.export.selectAll",
    defaultMessage: "Select All",
  },
  unselectAll: {
    id: "cdz.export.unselectAll",
    defaultMessage: "Unselect All",
  },
  cancelExport: {
    id: "cdz.export.cancelExport",
    defaultMessage: "Cancel",
  },
  doExport: {
    id: "cdz.export.doExport",
    defaultMessage: "Export",
  },
  Mode: {
    id: "cdz.export.Mode",
    defaultMessage: "Mode",
  },
  Reference: {
    id: "cdz.export.CustomerReference",
    defaultMessage: "Customer Reference",
  },
  OriginCityCountry: {
    id: "cdz.export.OriginCityCountry",
    defaultMessage: "Origin",
  },
  DestinationCityCountry: {
    id: "cdz.export.DestinationCityCountry",
    defaultMessage: "Destination",
  },
  QuoteTotal: {
    id: "cdz.export.QuoteTotal",
    defaultMessage: "cdz Total",
  },
  Status: {
    id: "cdz.export.QuoteStatus",
    defaultMessage: "Status",
  },
  HouseBill: {
    id: "cdz.export.Housebills",
    defaultMessage: "Housebills",
  },
  Weight: {
    id: "cdz.export.Weight",
    defaultMessage: "Weight",
  },
  Volume: {
    id: "cdz.export.Volume",
    defaultMessage: "Volume",
  },
  ExpirationDate: {
    id: "cdz.export.ExpirationDate",
    defaultMessage: "Expiration Date",
  },
  OriginCountry: {
    id: "cdz.export.OriginCountry",
    defaultMessage: "Origin Country/Region",
  },
  DestinationCountry: {
    id: "cdz.export.DestinationCountry",
    defaultMessage: "Destination Country/Region",
  },
  Origin: {
    id: "cdz.export.Origin",
    defaultMessage: "Origin Code",
  },
  Destination: {
    id: "cdz.export.Destination",
    defaultMessage: "Destination Code",
  },
  BillingCountry: {
    id: "cdz.export.BillingCountry",
    defaultMessage: "Billing Country/Region",
  },
  Company: {
    id: "cdz.export.Company",
    defaultMessage: "Company",
  },
  CreatedBy: {
    id: "cdz.export.CreatedBy",
    defaultMessage: "Created By",
  },
  RateType: {
    id: "cdz.export.RateType",
    defaultMessage: "Rate Type",
  },
  ServiceCharge: {
    id: "cdz.export.ServiceCharge",
    defaultMessage: "Service Charge",
  },
  ExportCustomsClearance: {
    id: "cdz.export.ExportCustomsClearance",
    defaultMessage: "Export Customs Charge",
  },
  Shipper: {
    id: "cdz.export.Shipper",
    defaultMessage: "Shipper",
  },
  Consignee: {
    id: "cdz.export.Consignee",
    defaultMessage: "Consignee",
  },
  PortOfUnloading: {
    id: "cdz.export.PortOfUnloading",
    defaultMessage: "Port Of Unloading",
  },
  StatusDateTime: {
    id: "cdz.export.StatusDateTime",
    defaultMessage: "Status Date/Time",
  },
  TotalPackages: {
    id: "cdz.export.TotalPackages",
    defaultMessage: "Total Packages",
  },
  TotalWeight: {
    id: "cdz.export.TotalWeight",
    defaultMessage: "Total Weight",
  },
  TotalChargeableWeight: {
    id: "cdz.export.TotalChargeableWeight",
    defaultMessage: "Total Chargeable Weight",
  },
  TotalVolume: {
    id: "cdz.export.TotalVolume",
    defaultMessage: "Total Volume",
  },
  ProductType: {
    id: "cdz.export.ProductType",
    defaultMessage: "Product Type",
  },
  TransportMode: {
    id: "cdz.export.TransportMode",
    defaultMessage: "Transport Mode",
  },
  GoodsDescription: {
    id: "cdz.export.GoodsDescription",
    defaultMessage: "Goods Description",
  },
  CreationDate: {
    id: "cdz.export.CreationDate",
    defaultMessage: "Creation Date",
  },
  PortOfLoading: {
    id: "cdz.export.PortOfLoading",
    defaultMessage: "Port Of Loading",
  },
  PlaceOfLoading: {
    id: "cdz.export.PlaceOfLoading",
    defaultMessage: "Place Of Loading",
  },
  CountryOfLoading: {
    id: "cdz.export.CountryOfLoading",
    defaultMessage: "Country Of Loading",
  },
  PlaceOfUnloading: {
    id: "cdz.export.PlaceOfUnloading",
    defaultMessage: "Place Of Unloading",
  },
  CountryOfUnloading: {
    id: "cdz.export.CountryOfUnloading",
    defaultMessage: "Country Of Unloading",
  },
  CustomsClearanceNumber: {
    id: "cdz.export.CustomsClearanceNumber",
    defaultMessage: "Customs Clearance",
  },
  CustomsEntry: {
    id: "cdz.export.CustomsEntry",
    defaultMessage: "Customs Entry",
  },
  DeclarationType: {
    id: "cdz.export.DeclarationType",
    defaultMessage: "Declaration Type",
  },
  ResponsibleCustomsOffice: {
    id: "cdz.export.ResponsibleCustomsOffice",
    defaultMessage: "Responsible Customs Office",
  },
  ClearanceDate: {
    id: "cdz.export.ClearanceDate",
    defaultMessage: "Clearance Date",
  },
  Forwarder: {
    id: "cdz.export.Forwarder",
    defaultMessage: "Forwarder",
  },
  MasterBill: {
    id: "cdz.export.MasterBill",
    defaultMessage: "MasterBill",
  },
  paginationInfo: {
    id: "cdz.pagination.info",
    defaultMessage: "{declarations} of {total} declarations",
  },
  loadMoreButton: {
    id: "cdz.button.loadMore",
    defaultMessage: "Load More",
  },
  allLoaded: {
    id: "cdz.allLoaded",
    defaultMessage:
      "{total, plural, one {# Declaration} other {# Declarations}} loaded.",
  },
  cdzExportTitle: {
    id: "cdz.exportTitle",
    defaultMessage: "Export Declarations",
  },
  cdzExportDescription: {
    id: "cdz.exportDescription",
    defaultMessage: "Currently only the {part1}",
  },
  cdzExportDescriptionPart1: {
    id: "cdz.exportDescription.boldPart1",
    defaultMessage: "first 2,000 declarations can be exported.",
  },
});
