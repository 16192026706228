import { DownloadState } from "constants/DownloadState";
import { IntlShape } from "react-intl";
import messages from "./DownloadStatus.messages";

export const getTitle = (
  intl: IntlShape,
  downloadState: DownloadState
): string => {
  switch (downloadState) {
    case DownloadState.GETTING:
      return intl.formatMessage(messages.gettingYourFile);
    case DownloadState.DOWNLOADING:
      return intl.formatMessage(messages.downloadingFile);
    case DownloadState.EXPIRED:
      return intl.formatMessage(messages.fileExpired);
    case DownloadState.FAILED:
      return intl.formatMessage(messages.failed);
    default:
      return "";
  }
};

export const getLabel = (
  intl: IntlShape,
  downloadState: DownloadState
): string => {
  switch (downloadState) {
    case DownloadState.GETTING:
      return intl.formatMessage(messages.downloadBeingPrepared);
    case DownloadState.DOWNLOADING:
      return intl.formatMessage(messages.downloadedFileWillBeOnYourComputer);
    case DownloadState.EXPIRED:
      return intl.formatMessage(messages.fileNotAvailableAnyMore);
    case DownloadState.FAILED:
      return intl.formatMessage(messages.theDownloadOfFileFailed);
    default:
      return "";
  }
};
