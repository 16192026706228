import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { getRem, getRemMedia } from "utils/css";

export const BasicCol = styled.div`
  padding-left: ${getRem(16)};
  padding-right: ${getRem(16)};
  @media (min-width: ${getRemMedia(768.2)}) {
    padding-left: ${getRem(20)};
    padding-right: ${getRem(20)};
  }
`;

export const GrowCol = styled(BasicCol)`
  flex: 1 1 50%;
`;

export const AutoColMd = styled(BasicCol)`
  flex: 0 0 100%;
  line-height: ${(p) => p.lineHeight};
  @media (min-width: ${getRemMedia(768.2)}) {
    flex: 0 0 auto;
  }
`;

const Col = styled.div`
  position: relative;
  ${(p) =>
    p.col >= 1
      ? css`
          flex: 0 0 ${(p) => ((p.col / 12) * 100).toFixed(2)}%;
          max-width: ${(p) => ((p.col / 12) * 100).toFixed(2)}%;
        `
      : p.auto
      ? css`
          flex: 0 0 auto;
        `
      : css`
          flex: 1 0 0;
          max-width: 100%;
        `};
  ${(p) =>
    p.offset
      ? css`
          margin-left: ${(p) => ((p.offset / 12) * 100).toFixed(2)}%;
        `
      : null};
  min-height: ${getRem(10)};
  padding-left: ${getRem(16)};
  padding-right: ${getRem(16)};
  @media (min-width: ${getRemMedia(768.2)}) {
    padding-left: ${getRem(20)};
    padding-right: ${getRem(20)};
    ${(p) =>
      p.md >= 1
        ? css`
            flex: 0 0 ${(p) => ((p.md / 12) * 100).toFixed(2)}%;
            max-width: ${(p) => ((p.md / 12) * 100).toFixed(2)}%;
          `
        : p.autoMd
        ? css`
            flex: 0 0 auto;
          `
        : null};
  }
  @media (max-width: ${getRemMedia(768)}) {
    ${(p) =>
      p.hiddenMdDown
        ? css`
            display: none;
          `
        : null}
  }
`;

Col.defaultProps = {};
Col.propTypes = {
  auto: PropTypes.bool,
  autoMd: PropTypes.bool,
  hiddenMdDown: PropTypes.bool,
  col: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  offset: PropTypes.number,
};
export default Col;
