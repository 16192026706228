import { defineMessages } from "react-intl";

export default defineMessages({
  notLikely: {
    id: "npsRating.modal.rating.notLikely",
    defaultMessage: "Not likely",
  },
  veryLikely: {
    id: "npsRating.modal.rating.veryLikely",
    defaultMessage: "Very likely",
  },
});
