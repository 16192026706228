import {
  TextField,
  Checkbox as CheckboxBase,
  PhoneInputNew,
  media,
} from "react-lib";
import styled from "styled-components";

export const TextArea = styled(TextField)`
  width: 100%;
  height: 6.375rem;
`;

export const Checkbox = styled(CheckboxBase)<{ hasMarginOffset?: boolean }>`
  margin-top: 0.625rem;
  margin-bottom: ${({ hasMarginOffset }) =>
    hasMarginOffset ? "3.875rem" : "0.75rem"};
`;

export const PhoneInput = styled(PhoneInputNew)`
  marigin-bottom: 0.875rem;

  ${media.from("sm")} {
    width: 19.625rem;
  }
`;

export const ValidationWrapper = styled.div`
  position: absolute;
`;
