import { ServiceLevel } from "constants/bookWorkflow";
import {
  mdmServiceToQnService,
  qnbServiceToMdmService,
} from "constants/masterDataManagement";
import { AirFreightService } from "constants/quoteAndBook";
import React from "react";
import { IntlShape } from "react-intl";
import productMessages from "routes/MakeABooking/BookingMode/BookingMode.messages";
import get from "utils/get";
import { ratesTypeMessages } from "../BookingsListRow/BookingsListRow.messages";
import BookingsListSearch from "../BookingsListSearch/BookingsListSearch";
import FollowedBooking from "../FollowedBooking/FollowedBooking";
import * as C from "./BookingsListHeader.constants";
import {
  serviceLevelMessages,
  statusUserMessages,
} from "./BookingsListHeader.messages";
import * as S from "./BookingsListHeader.styles";

export const translate = ({
  intl,
  messages,
  key,
  fallback = key,
}: C.TranslateProps) => {
  return key && messages[key]
    ? intl.formatMessage(messages[key])
    : fallback || "";
};

export const getAppliedFilterText = ({
  item,
  userSettings,
  countriesList,
  intl,
}: C.GetAppliedFilterTextProps) => {
  if (item.hasDateType) {
    const fromDate = get(
      userSettings,
      `${C.filterPath}.${item.uniqueValueColumnName}.from`
    );
    const toDate = get(
      userSettings,
      `${C.filterPath}.${item.uniqueValueColumnName}.to`
    );
    return `${fromDate} - ${toDate}`;
  }

  const serviceFilterItemTitle = get(
    userSettings,
    `${C.filterPath}.${item.uniqueValueColumnName}`
  );

  switch (item.dataName) {
    case "product": {
      return translate({
        intl,
        messages: productMessages,
        key: `MODE_${serviceFilterItemTitle}`,
        fallback: serviceFilterItemTitle,
      });
    }

    case "serviceLevel": {
      const serviceToTranslate =
        serviceFilterItemTitle.find(
          (service: ServiceLevel | AirFreightService) =>
            Object.keys(mdmServiceToQnService).find(
              (mdmService) => mdmService === service
            )
        ) ?? serviceFilterItemTitle[0];

      return translate({
        intl,
        messages: serviceLevelMessages,
        key: serviceToTranslate,
      });
    }

    case "statusUser": {
      return translate({
        intl,
        messages: statusUserMessages,
        key: serviceFilterItemTitle,
      });
    }

    case "originCountry":
    case "destinationCountry":
    case "billingCountry": {
      return serviceFilterItemTitle && countriesList[serviceFilterItemTitle]
        ? countriesList[serviceFilterItemTitle].name
        : serviceFilterItemTitle || "";
    }

    case "ratesType": {
      return translate({
        intl,
        messages: ratesTypeMessages,
        key: serviceFilterItemTitle,
      });
    }

    default:
      return serviceFilterItemTitle;
  }
};

const translateBucketTitle = (
  bucket: C.Bucket | undefined,
  bucketItem: C.BucketItem,
  intl: IntlShape
): string => {
  switch (bucket?.name) {
    case "PRODUCT":
      return translate({
        intl,
        messages: productMessages,
        key: `MODE_${bucketItem.title}`,
        fallback: bucketItem.title,
      });

    case "SERVICE_LEVEL":
      return translate({
        intl,
        messages: serviceLevelMessages,
        key: bucketItem.title,
      });

    case "STATUS_USER":
      return translate({
        intl,
        messages: statusUserMessages,
        key: bucketItem.title,
      });

    case "RATES_TYPE": {
      return translate({
        intl,
        messages: ratesTypeMessages,
        key: bucketItem.title,
      });
    }

    default:
      return bucketItem.title;
  }
};

const bucketToListItem = (bucket: C.Bucket | undefined, intl: IntlShape) =>
  bucket?.bucketItems?.map((bucketItem) => ({
    key: bucketItem.key,
    title: translateBucketTitle(bucket, bucketItem, intl),
  })) || [];

export const getDropdownContent = ({
  item,
  bucket,
  onOutsideClick,
  module,
  intl,
}: C.BookingsListHeaderDropdownContentProps) => {
  switch (item.translationKey) {
    case "Follow":
      return <FollowedBooking onOutsideClick={onOutsideClick} />;
    case "Service": {
      const items = [...bucketToListItem(bucket, intl)];
      const qnbServiceLevels = Object.keys(
        qnbServiceToMdmService
      ) as AirFreightService[];
      const filteredItems = items.filter(
        ({ key }) =>
          !qnbServiceLevels.find((serviceLevel) => serviceLevel === key)
      );

      return (
        <S.StyledList
          filter={item.uniqueValueColumnName || ""}
          items={filteredItems.sort((a, b) => a.title.localeCompare(b.title))}
          onOutsideClick={onOutsideClick}
          module={module}
          transform={(item: ServiceLevel) => [
            ...(mdmServiceToQnService[item] ?? []),
            item,
          ]}
        />
      );
    }
    case "Mode":
    case "RatesType":
    case "BookingStatusUser": {
      return (
        <S.StyledList
          filter={item.uniqueValueColumnName || ""}
          items={bucketToListItem(bucket, intl)}
          onOutsideClick={onOutsideClick}
          module={module}
        />
      );
    }
    case "OriginCountry":
    case "DestinationCountry":
    case "BillingCountry": {
      return (
        <S.StyledCountries
          filter={item.uniqueValueColumnName || ""}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "ShipperCompany":
    case "ConsigneeCompany":
    case "BillingCompany":
    case "OriginCity":
    case "DestinationCity":
    case "Housebill":
    case "QuoteID": {
      return (
        <BookingsListSearch
          filter={item.uniqueValueColumnName || ""}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
        />
      );
    }
    case "CreationDate":
    case "UpdateDate":
    case "SentDate":
    case "EstimatedPickupDate": {
      return (
        <S.StyledDateRange
          filter={item.uniqueValueColumnName || ""}
          onOutsideClick={onOutsideClick}
          module={module}
        />
      );
    }
    case "SubmittedDate": {
      return (
        <S.StyledDateRange
          filter={item.uniqueValueColumnName || ""}
          onOutsideClick={onOutsideClick}
          module={module}
          past
        />
      );
    }
    case "References":
    case "BookingName":
    case "CreatedBy": {
      return (
        <S.StyledFreeText
          filter={item.uniqueValueColumnName || ""}
          onOutsideClick={onOutsideClick}
          module={module}
        />
      );
    }
    case "Weight":
    case "Volume": {
      return null;
    }

    default: {
      return "";
    }
  }
};
