import { CountriesList } from "constants/bookWorkflow";
import { IntlShape, MessageDescriptor } from "react-intl";
import { initialState as sortSettingsInitialState } from "redux/sort/reducer";
import { initialState as userSettingsInitialState } from "redux/userSettings/reducer";

export interface BookingsListHeaderDropdownContentProps {
  item: BookingsListColumn;
  bucket: Bucket | undefined;
  onOutsideClick: () => void;
  module: string;
  intl: IntlShape;
}

export interface BookingsListColumn {
  dataName: string;
  translationKey: string;
  uniqueValueColumnName: string;
  sortable: boolean;
  customSize: number;
  removable: boolean;
  visible: boolean;
  isDropdown: boolean;
  sortDirectionOnFirstClick?: string;
  multiValue: boolean;
  isFilteringDisabled?: boolean;
  hasDateType?: boolean;
  // `value` is typed as any as we need to access `value.from`. That is not possible on a variable
  // of type `unknown`. Having proper types for all columns is nearly impossible.
  transformFilterForExport?: (value: any) => Array<[string, string]>;
}

export interface BucketItem {
  count: number;
  key: string;
  title: string;
  __typename?: string;
}

export interface Bucket {
  name: string;
  bucketItems: BucketItem[];
}

export interface BookingsListHeaderProps {
  item: BookingsListColumn;
  index: number;
  userSettings: typeof userSettingsInitialState;
  sortSettings: typeof sortSettingsInitialState;
  bucket?: Bucket;
  countriesList: CountriesList;
}

export interface TranslateProps {
  intl: IntlShape;
  messages: Record<string, MessageDescriptor>;
  key: undefined | string;
  fallback?: undefined | string;
}

export interface GetAppliedFilterTextProps {
  item: BookingsListColumn;
  userSettings: typeof userSettingsInitialState;
  countriesList: CountriesList;
  intl: IntlShape;
}

export const moduleName = "bookings";
export const filterPath = "bookingsListFilter";
