import gql from "graphql-tag";

const GET_CONTACT_LINK_QB = gql`
  query GetContactLinkQB(
    $locale: String!
    $countryCode: String!
    $ratesSource: LegalEntityPayloadRatesSource!
  ) {
    getContactLinkQB(
      locale: $locale
      countryCode: $countryCode
      ratesSource: $ratesSource
    ) {
      contactLink
    }
  }
`;

export default GET_CONTACT_LINK_QB;
