import trim from "lodash/trim";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useDocumentTitle = (isQuoteRoute) => {
  const location = useLocation();
  const pathName = location?.pathname;
  useEffect(() => {
    if (isQuoteRoute) {
      window.document.title = "myDHLi Quote+Book";
    } else if (pathName?.length) {
      window.document.title = trim(
        "myDHLi" + pathName.replace(/\//g, " - "),
        "- "
      );
    }
  }, [pathName, isQuoteRoute]);
};

export default useDocumentTitle;
