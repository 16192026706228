import { Product } from "constants/bookWorkflow";
import * as MDM from "constants/masterDataManagement";
import React from "react";
import { IntlShape } from "react-intl";
import { zIndices } from "react-lib";
import {
  ActiveFilter,
  ListElement,
} from "react-lib/@types/organisms/FiltersList/FiltersList.constants";
import SvgEdit from "react-lib/es/icons/SvgEdit";
import IconUserInterfaceFilter from "react-lib/es/icons/SvgIconUserInterfaceFilter";
import SvgMoreVertical from "react-lib/es/icons/SvgMoreVertical";
import SvgTrashBinDelete from "react-lib/es/icons/SvgTrashBinDelete";
import { ListElementTypeEnum } from "react-lib/es/organisms/FiltersList/FiltersList.constants";
import {
  HeaderContentRendererProps,
  ImprovedTableColumn,
  MenuOption,
  NewRowContentRendererProps,
  RowMenuOptions,
  SmallColumnsNames,
  UserNewAddressBook,
  UserAddressBookAddressRowData,
  AddressBookFilterCol,
  CountriesDataObjForFilters,
  ListElementSchema,
  columnNameConfig,
} from "./AddressBookNew.constants";
import messages from "./AddressBookNew.messages";
import * as S from "./AddressBookNew.styles";

export const customHeaderRowRenderer = ({
  columns,
}: HeaderContentRendererProps) => (
  <tr>
    {columns.map((column: ImprovedTableColumn) => (
      <S.Th key={column.name}>
        {column.headerRenderer
          ? column.headerRenderer(column)
          : column.displayName}
      </S.Th>
    ))}
  </tr>
);

export const OptionsName = "options";

export const getAddressBookOptionsColumn = (): ImprovedTableColumn => ({
  name: OptionsName,
  headerRenderer: () => <S.StyledMenuWrapper />,
  displayName: "",
  emptyFallback: "",
});

export const getRowMenuOptions = (intl: IntlShape): MenuOption[] => [
  {
    value: RowMenuOptions.EDIT,
    label: intl.formatMessage(messages.edit),
  },
  {
    value: RowMenuOptions.DELETE,
    label: intl.formatMessage(messages.delete),
  },
];

export const MenuIcons: Record<string, React.ReactElement> = {
  edit: <SvgEdit />,
  delete: <SvgTrashBinDelete />,
};

const renderOptions = (
  intl: IntlShape,
  id: number,
  selectMenuOptionForListItem: (id: number, menuOption: RowMenuOptions) => void
) => (
  <S.StyledMenu
    zIndex={zIndices.popover}
    options={getRowMenuOptions(intl)}
    getIcon={(option: MenuOption) => MenuIcons[option.value]}
    onChange={(menuOption: RowMenuOptions) =>
      selectMenuOptionForListItem(id, menuOption)
    }
  >
    <S.OptionsWrapper>
      <SvgMoreVertical />
    </S.OptionsWrapper>
  </S.StyledMenu>
);

const renderRows = (
  column: ImprovedTableColumn,
  row: UserAddressBookAddressRowData,
  intl: IntlShape,
  selectMenuOptionForListItem: (id: number, menuOption: RowMenuOptions) => void,
  selectedElementId?: number
) => {
  if (column.name === OptionsName && selectedElementId) {
    return renderOptions(intl, selectedElementId, selectMenuOptionForListItem);
  } else if (column.renderer) {
    return column.renderer({
      ...row,
    });
  } else {
    return row[column.name as keyof UserAddressBookAddressRowData];
  }
};

export const getDefaultHeaderRenderer = (
  column: ImprovedTableColumn,
  hasActiveFilter: boolean,
  setSelectedFilterId: (id: string) => void,
  id: string
) => (
  <S.StyledHeader key={column.displayName}>
    {column.displayName}
    {hasActiveFilter && (
      <IconUserInterfaceFilter onClick={() => setSelectedFilterId(id)} />
    )}
  </S.StyledHeader>
);

const rowNewContentRenderer = ({
  columns,
  row,
  hoveredRowId,
  clickedRowId,
  intl,
  selectMenuOptionForListItem,
}: NewRowContentRendererProps) => {
  const rowData: UserAddressBookAddressRowData = {
    concatAddress: row.concatAddress || "",
    country: row.country || "",
    addressLine1: row.address.address_line1,
    addressLine2: row.address.address_line2,
    city: row.address.city,
    companyName: row.address.company_name,
    countryCode: row.address.country_code,
    postalCode: row.address.postal_code,
    reference: row.address.ref_customer,
    stateProvince: row.address.state_province,
  };
  return columns.map((column) => {
    if (column.name in SmallColumnsNames) {
      return (
        <S.SmallTd
          key={column.name}
          isClicked={clickedRowId === row.address_id}
        >
          {hoveredRowId === row.address_id
            ? renderRows(
                column,
                rowData,
                intl,
                selectMenuOptionForListItem,
                hoveredRowId
              )
            : renderRows(column, rowData, intl, selectMenuOptionForListItem)}
        </S.SmallTd>
      );
    } else {
      return (
        <S.Td key={column.name} isClicked={clickedRowId === row.address_id}>
          {hoveredRowId === row.address_id
            ? renderRows(
                column,
                rowData,
                intl,
                selectMenuOptionForListItem,
                hoveredRowId
              )
            : renderRows(column, rowData, intl, selectMenuOptionForListItem)}
        </S.Td>
      );
    }
  });
};

export const selectableNewRowRenderer = (args: NewRowContentRendererProps) => {
  return (
    <tr
      key={args.rowIndex}
      id={`${args.row.address_id}`}
      onMouseEnter={() => args.setHoveredRowId(args.row.address_id)}
      onMouseLeave={() => args.setHoveredRowId(-1)}
    >
      {rowNewContentRenderer(args)}
    </tr>
  );
};

export const getAddressBookColumns = (
  intl: IntlShape,
  activeFilters: ActiveFilter[],
  setSelectedFilterId: (id: string) => void
): ImprovedTableColumn[] => [
  {
    name: "companyName",
    headerRenderer: (col) =>
      getDefaultHeaderRenderer(
        col,
        activeFilters.some(
          (filter) =>
            filter.id === columnNameConfig.COMPANY_NAME.filterRequestKey
        ),
        setSelectedFilterId,
        columnNameConfig.COMPANY_NAME.filterRequestKey
      ),
    displayName: intl.formatMessage(messages.company),
    emptyFallback: <div> empty </div>,
  },
  {
    name: "concatAddress",
    headerRenderer: (col) =>
      getDefaultHeaderRenderer(
        col,
        activeFilters.some(
          (filter) => filter.id === columnNameConfig.ADDRESS.filterRequestKey
        ),
        setSelectedFilterId,
        columnNameConfig.ADDRESS.filterRequestKey
      ),
    displayName: intl.formatMessage(messages.address),
    emptyFallback: <div> empty </div>,
  },
  {
    name: "country",
    headerRenderer: (col) =>
      getDefaultHeaderRenderer(
        col,
        activeFilters.some(
          (filter) =>
            filter.id === columnNameConfig.COUNTRY_CODE.filterRequestKey
        ),
        setSelectedFilterId,
        columnNameConfig.COUNTRY_CODE.filterRequestKey
      ),
    displayName: intl.formatMessage(messages.country),
    emptyFallback: <div> empty </div>,
  },
  {
    name: "reference",
    headerRenderer: (col) =>
      getDefaultHeaderRenderer(
        col,
        activeFilters.some(
          (filter) => filter.id === columnNameConfig.REFERENCE.filterRequestKey
        ),
        setSelectedFilterId,
        columnNameConfig.REFERENCE.filterRequestKey
      ),
    displayName: intl.formatMessage(messages.referenceId),
    emptyFallback: <div> empty </div>,
  },
];

export const normalizeActiveFilters = (
  activeFilters: ActiveFilter[],
  countriesData?: MDM.Country[]
) =>
  activeFilters.map((activeFilter) => {
    if (activeFilter.id === columnNameConfig.COUNTRY_CODE.filterRequestKey) {
      return {
        column_name: columnNameConfig.COUNTRY_CODE.addressListRequestKey,
        search_values: Array.isArray(activeFilter.value)
          ? activeFilter.value.reduce<string[]>((acc, countryName) => {
              const activeEl = countriesData?.find(
                (countryDataEl) => countryDataEl.label === countryName
              );
              if (activeEl) {
                acc.push(activeEl.code);
              }
              return acc;
            }, [])
          : [activeFilter.value],
        search_type: "EXACT",
      };
    }

    const addressListColumnName = Object.entries(columnNameConfig).find(
      (entry) => entry[1].filterRequestKey === activeFilter.id
    )?.[1].addressListRequestKey;

    return {
      column_name: addressListColumnName,
      search_values: Array.isArray(activeFilter.value)
        ? activeFilter.value
        : [activeFilter.value],
      search_type: "CONTAINS",
    };
  });

export const concatNewAddress = (entry: UserNewAddressBook): string => {
  const nullToEmpty = (field: string | undefined): string => field ?? "";
  const address = entry?.address;
  if (address) {
    const beforeComma = [
      nullToEmpty(address.address_line1),
      nullToEmpty(address.address_line2),
    ]
      .filter((addressPart) => addressPart)
      .join(" ");
    const afterComma = [
      nullToEmpty(address.postal_code),
      nullToEmpty(address.city),
    ]
      .filter((addressPart) => addressPart)
      .join(" ");
    return [beforeComma, afterComma]
      .filter((addressPart) => addressPart)
      .join(", ");
  }
  return "";
};

const buildCountriesDataForProvidedCodes = (
  codes?: string[],
  countriesData?: MDM.Country[]
) =>
  codes?.reduce<CountriesDataObjForFilters[]>((acc, curr) => {
    const country = countriesData?.find((country) => country.code === curr);

    if (country) {
      acc.push({
        code: curr,
        name: country?.label,
      });
    }
    return acc.sort((prevCountry, nextCountry) =>
      prevCountry.name.localeCompare(nextCountry.name)
    );
  }, []);

export const buildFilters = (
  filtersConfig: ListElementSchema[],
  activeFilters: ActiveFilter[],
  countriesData?: MDM.Country[],
  filterValues?: AddressBookFilterCol[]
) =>
  filtersConfig.reduce<ListElement[]>((results, filterSchemaEl) => {
    const filterValue = filterValues?.find(
      (val) => val.column === filterSchemaEl.columnName
    );
    if (
      filterValue?.values.length ||
      activeFilters.find(
        (activeFilter) => activeFilter.id === filterValue?.column
      )
    ) {
      const { columnName, ...rest } = filterSchemaEl;

      return [
        ...results,
        {
          ...rest,
          ...(filterSchemaEl.type === ListElementTypeEnum.MULTI_SELECT && {
            tagsList:
              columnName === columnNameConfig.COUNTRY_CODE.filterRequestKey
                ? buildCountriesDataForProvidedCodes(
                    filterValue?.values,
                    countriesData
                  )?.map((el) => el.name) || []
                : filterValue?.values,
          }),
        },
      ];
    }
    return results;
  }, []);

export const generateError = (
  prefix: string,
  intl: IntlShape,
  selectedAddress: UserNewAddressBook | null,
  countriesPossibleToSelect: string[],
  countriesProductData?: MDM.CountryProduct[],
  product?: Product
) => {
  const isCountryCanBeSelected = countriesPossibleToSelect.some(
    (code) => code === selectedAddress?.address.country_code
  );
  const countryProduct = countriesProductData?.find(
    (countryProduct) =>
      countryProduct.countryCode === selectedAddress?.address.country_code &&
      countryProduct.productCode === product
  );

  const isCountryProperForOrigin = !!countryProduct?.isOrigin;
  const isCountryProperForDestination = !!countryProduct?.isDestination;
  const isCountryProperForBilling = !!countryProduct?.isBilling;

  switch (prefix) {
    case "shipper":
    case "pickup": {
      if (!isCountryProperForOrigin) {
        return intl.formatMessage(messages.wrongCountryForOrigin);
      }
      if (!isCountryCanBeSelected) {
        return intl.formatMessage(messages.originCantBeTheSameAsDestination);
      }
      break;
    }
    case "delivery":
    case "consignee": {
      if (!isCountryProperForDestination) {
        return intl.formatMessage(messages.wrongCountryForDestination);
      }
      if (!isCountryCanBeSelected) {
        return intl.formatMessage(messages.destinationCantBeTheSameAsOrigin);
      }
      break;
    }
    case "party": {
      if (!isCountryProperForBilling) {
        return intl.formatMessage(messages.wrongCountryForBilling);
      }
      break;
    }

    default:
      return undefined;
  }
};
