import { defineMessages } from "react-intl";
import { MenuItemKey } from "./UserAvatarSection.constants";

export const messages = defineMessages({
  [MenuItemKey.ACCOUNT]: {
    id: "dashboard.nav.account",
    defaultMessage: "Account",
  },
  [MenuItemKey.NOTIFICATIONS]: {
    id: "dashboard.nav.notifications",
    defaultMessage: "Notifications",
  },
  [MenuItemKey.PORTAL_SETTINGS]: {
    id: "dashboard.nav.portalSettings",
    defaultMessage: "Portal Settings",
  },
  [MenuItemKey.LOGOUT]: {
    id: "dashboard.nav.logOut",
    defaultMessage: "Logout",
  },
  [MenuItemKey.CONTACT_US]: {
    id: "dashboard.nav.contactUs",
    defaultMessage: "Contact Us",
  },
  [MenuItemKey.FEEDBACK]: {
    id: "dashboard.nav.feedback",
    defaultMessage: "Give us feedback",
  },
  [MenuItemKey.CHANGE_PASSWORD]: {
    id: "dashboard.nav.changePassword",
    defaultMessage: "Change Password",
  },
  [MenuItemKey.ADDRESS_BOOK]: {
    id: "dashboard.nav.addressBook",
    defaultMessage: "Address Book",
  },
});
