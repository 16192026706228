import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "RoutingInteractions";

export const RoutingInteractions = {
  PICK_UP_FROM_COMPANY_ADDRESS: {
    id: `${prefix}_Pick-Up from Company Address`,
    name: "Pick-Up from Company Address",
  },
  SELFDELIVERY_TO_TERMINAL: {
    id: `${prefix}_Selfdelivery to Terminal`,
    name: "Selfdelivery to Terminal",
  },
  COUNTRY: { id: `${prefix}_Country`, name: "Country" },
  COUNTRY_ORIGIN: { id: `${prefix}_Country Origin`, name: "Country Origin" },
  COUNTRY_DESTINATION: {
    id: `${prefix}_Country Destination`,
    name: "Country Destination",
  },
  SEARCH: { id: `${prefix}_Search`, name: "Search" },
  SEARCH_ORIGIN: { id: `${prefix}_Search Origin`, name: "Search Origin" },
  SEARCH_DESTINATION: {
    id: `${prefix}_Search Destination`,
    name: "Search Destination",
  },
  DROP_OFF_AT_COMPANY_ADDRESS: {
    id: `${prefix}_Drop-Off at Company Address`,
    name: "Drop-Off at Company Address",
  },
  SELF_PICK_UP_FROM_TERMINAL: {
    id: `${prefix}_Self-Pick-Up from Terminal`,
    name: "Self-Pick-Up from Terminal",
  },
  QUICK_ESTIMATION: {
    id: `${prefix}_Quick Estimation`,
    name: "Quick Estimation",
  },
  FULL_CONTAINER_LOAD: {
    id: `${prefix}_Full Container Load`,
    name: "Full Container Load",
  },
  LOOSE_CARGO: { id: `${prefix}_Loose Cargo`, name: "Loose Cargo" },
  TOTAL_WEIGHT: { id: `${prefix}_Total Weight`, name: "Total Weight" },
  TOTAL_VOLUME: { id: `${prefix}_Total Volume`, name: "Total Volume" },
  NEXT: { id: `${prefix}_Next`, name: "Next" },
  STANDARD_DRY_CONTAINER_20: {
    id: `${prefix}_20' Standard Dry Container`,
    name: "20' Standard Dry Container",
  },
  STANDARD_DRY_CONTAINER_40: {
    id: `${prefix}_40' Standard Dry Container`,
    name: "40' Standard Dry Container",
  },
  HIGH_CUBE_DRY_CONTAINER_40: {
    id: `${prefix}_40' High Cube Dry Container`,
    name: "40' High Cube Dry Container",
  },
  HIGH_CUBE_DRY_CONTAINER_45: {
    id: `${prefix}_45' High Cube Dry Container`,
    name: "45' High Cube Dry Container",
  },
  MORE_CONTAINERS: { id: `${prefix}_More Containers`, name: "More Containers" },
  LESS_CONTAINERS: { id: `${prefix}_Less Containers`, name: "Less Containers" },
  NET_WEIGHT: { id: `${prefix}_Net Weight`, name: "Net Weight" },
  GROSS_WEIGHT: { id: `${prefix}_Gross Weight`, name: "Gross Weight" },
  ADD_ADDITIONAL_CONTAINER_TYPE: {
    id: `${prefix}_Add Additional Container Type`,
    name: "Add Additional Container Type",
  },
  BAGS: { id: `${prefix}_BAGS`, name: "BAGS" },
  WOODEN_CASES: { id: `${prefix}_Wooden Cases`, name: "Wooden Cases" },
  CARTONS: { id: `${prefix}_Cartons`, name: "Cartons" },
  CRATES: { id: `${prefix}_Crates`, name: "Crates" },
  PALLET: { id: `${prefix}_Pallet`, name: "Pallets/Skids" },
  COMMON_PALLET_EUR_1: {
    id: `${prefix}_Common_Pallet_EUR_1`,
    name: "Common Pallet EUR 1",
  },
  COMMON_PALLET_EUR_3: {
    id: `${prefix}_Common_Pallet_EUR_3`,
    name: "Common Pallet EUR 3",
  },
  COMMON_PALLET_EUR_6: {
    id: `${prefix}_Common_Pallet_EUR_6`,
    name: "Common Pallet EUR 6",
  },
  MY_PALLET_1: {
    id: `${prefix}_My_Pallet_1`,
    name: "My Pallet 1",
  },
  MY_PALLET_2: {
    id: `${prefix}_My_Pallet_2`,
    name: "My Pallet 2",
  },
  MY_PALLET_3: {
    id: `${prefix}_My_Pallet_3`,
    name: "My Pallet 3",
  },
  IN_PALLET_1: {
    id: `${prefix}_In_Pallet_1`,
    name: "In Pallet 1",
  },
  IN_PALLET_2: {
    id: `${prefix}_In_Pallet_2`,
    name: "In Pallet 2",
  },
  IN_PALLET_6: {
    id: `${prefix}_In_Pallet_6`,
    name: "In Pallet 6",
  },
  T_PALLET_11: {
    id: `${prefix}_T_Pallet_11`,
    name: "T Pallet 11",
  },
  T_PALLET_12: {
    id: `${prefix}_T_Pallet_12`,
    name: "T Pallet 12",
  },
  JP_PALLET_1: {
    id: `${prefix}_JP_Pallet_1`,
    name: "JP Pallet 1",
  },
  JP_PALLET_2: {
    id: `${prefix}_JP_Pallet_2`,
    name: "JP Pallet 2",
  },
  US_PALLET_1: {
    id: `${prefix}_US_Pallet_1`,
    name: "US Pallet 1",
  },
  AU_PALLET_STD: {
    id: `${prefix}_AU_Pallet_STD`,
    name: "AU Pallet STD",
  },
  AU_PALLET_EXP: {
    id: `${prefix}_AU_Pallet_EXP`,
    name: "AU Pallet EXP",
  },
  MX_PALLET_STD: {
    id: `${prefix}_MX_Pallet_STD`,
    name: "MX Pallet STD",
  },
  MX_PALLET_EUR_1: {
    id: `${prefix}_MX_Pallet_EUR_1`,
    name: "MX Pallet EUR 1",
  },
  MX_PALLET_CP_3: {
    id: `${prefix}_MX_Pallet_CP_3`,
    name: "MX Pallet CP 3",
  },
  BR_PALLET_EUR_3: {
    id: `${prefix}_BR_Pallet_EUR_3`,
    name: "BR Pallet EUR 3",
  },
  CA_PALLET_EUR_1: {
    id: `${prefix}_CA_Pallet_EUR_1`,
    name: "CA Pallet EUR 1",
  },
  CA_PALLET_EUR_3: {
    id: `${prefix}_CA_Pallet_EUR_3`,
    name: "CA Pallet EUR 3",
  },
  CA_PALLET_EUR_6: {
    id: `${prefix}_CA_Pallet_EUR_6`,
    name: "CA Pallet EUR 6",
  },
  CN_PALLET_1: {
    id: `${prefix}_CN_Pallet_1`,
    name: "CN Pallet 1",
  },
  CN_PALLET_2: {
    id: `${prefix}_CN_Pallet_2`,
    name: "CN Pallet 2",
  },
  CN_PALLET_3: {
    id: `${prefix}_CN_Pallet_3`,
    name: "CN Pallet 3",
  },
  ZA_PALLET_EUR_1: {
    id: `${prefix}_ZA_Pallet_EUR_1`,
    name: "ZA Pallet EUR 1",
  },
  ZA_PALLET_EUR_3: {
    id: `${prefix}_ZA_Pallet_EUR_3`,
    name: "ZA Pallet EUR 3",
  },
  ZA_PALLET_EUR_6: {
    id: `${prefix}_ZA_Pallet_EUR_6`,
    name: "ZA Pallet EUR 6",
  },
  EUR_1_PALLET: { id: `${prefix}_Eur-1 Pallet`, name: "Eur-1 Pallet" },
  EUR_3_PALLET: { id: `${prefix}_Eur-3 Pallet`, name: "Eur-3 Pallet" },
  EUR_6_PALLET: { id: `${prefix}_Eur-6 Pallet`, name: "Eur-6 Pallet" },
  DRUM: { id: `${prefix}_Drum`, name: "Drum" },
  ROLLS: { id: `${prefix}_Rolls`, name: "Rolls" },
  SKELETON_BOX: { id: `${prefix}_Skeleton box`, name: "Skeleton box" },
  IBC_TANK: { id: `${prefix}_IBC Tank`, name: "IBC Tank" },
  METRIC_UNIT: { id: `${prefix}_Metric Unit`, name: "Metric Unit" },
  IMPERIAL_UNIT: { id: `${prefix}_Imperial Unit`, name: "Imperial Unit" },
  DUPLICATE_CARGO_TYPE: {
    id: `${prefix}_Duplicate Cargo Type`,
    name: "Duplicate Cargo Type",
  },
  DELETE_CARGO_TYPE: {
    id: `${prefix}_Delete Cargo Type`,
    name: "Delete Cargo Type",
  },
  ADD_CARGO_TYPE: { id: `${prefix}_Add Cargo Type`, name: "Add Cargo Type" },
  GENERAL_CARGO: { id: `${prefix}_General Cargo`, name: "General Cargo" },
  SPECIAL_HANDLING: {
    id: `${prefix}_Special Handling`,
    name: "Special Handling",
  },
  DANGEROUS_GOODS: { id: `${prefix}_Dangerous Goods`, name: "Dangerous Goods" },
  TEMPERATURE_CONTROL: {
    id: `${prefix}_Temperature Control`,
    name: "Temperature Control",
  },
  LITHIUM_BATTERIES: {
    id: `${prefix}_Lithium Batteries`,
    name: "Lithium Batteries",
  },
  OTHERS: { id: `${prefix}_Others`, name: "Others" },
  STACKABLE: { id: `${prefix}_Stackable`, name: "Stackable" },
  NOT_STACKABLE: { id: `${prefix}_Not Stackable`, name: "Not Stackable" },
  BILLING_COUNTRY: { id: `${prefix}_Billing Country`, name: "Billing Country" },
  COMPARE_QUOTES: { id: `${prefix}_Compare Quotes`, name: "Compare Quotes" },
  START_NEW_BOOKING: {
    id: `${prefix}_Start new Booking`,
    name: "Start new Booking",
  },
  BOOK_AGAIN: { id: `${prefix}_Book again`, name: "Book again" },
  REFRESH: { id: `${prefix}_Refresh`, name: "Refresh" },
};

enum RoutingPosition {
  ORIGIN = "Origin",
  DESTINATION = "Destination",
  CARGO_DETAILS = "Cargo Details",
  QUICK_ESTIMATION = "Quick Estimation",
  CONTAINER_TYPE = "Container Type",
  LOOSE_CARGO_TYPE = "Loose Cargo Type",
  SPECIAL_HANDLING = "Special Handling",
  BILLING_COUNTRY = "Billing Country",
  QUOTE_ALREADY_BOOKED = "Quote already booked",
  QUOTE_EXPIRED = "Quote expired",
}

const routingContext = "Routing";

export const routingEventNameToRoutingEventContentMapper: () => C.EventNameToContentMapperType<
  typeof RoutingInteractions
> = () => {
  const generateRoutingEntryForPallet = (entry: {
    id: string;
    name: string;
  }) => ({
    [entry.id]: {
      name: entry.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
  });

  return {
    [RoutingInteractions.PICK_UP_FROM_COMPANY_ADDRESS.id]: {
      name: RoutingInteractions.PICK_UP_FROM_COMPANY_ADDRESS.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.ORIGIN,
      context: routingContext,
    },
    [RoutingInteractions.SELFDELIVERY_TO_TERMINAL.id]: {
      name: RoutingInteractions.SELFDELIVERY_TO_TERMINAL.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.ORIGIN,
      context: routingContext,
    },
    [RoutingInteractions.COUNTRY.id]: {
      name: RoutingInteractions.COUNTRY.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.ORIGIN,
      context: routingContext,
    },
    [RoutingInteractions.COUNTRY_ORIGIN.id]: {
      name: RoutingInteractions.COUNTRY.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.ORIGIN,
      context: routingContext,
    },
    [RoutingInteractions.COUNTRY_DESTINATION.id]: {
      name: RoutingInteractions.COUNTRY.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.DESTINATION,
      context: routingContext,
    },
    [RoutingInteractions.SEARCH.id]: {
      name: RoutingInteractions.SEARCH.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.ORIGIN,
      context: routingContext,
    },
    [RoutingInteractions.SEARCH_ORIGIN.id]: {
      name: RoutingInteractions.SEARCH.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.ORIGIN,
      context: routingContext,
    },
    [RoutingInteractions.SEARCH_DESTINATION.id]: {
      name: RoutingInteractions.SEARCH.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.DESTINATION,
      context: routingContext,
    },
    [RoutingInteractions.DROP_OFF_AT_COMPANY_ADDRESS.id]: {
      name: RoutingInteractions.DROP_OFF_AT_COMPANY_ADDRESS.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.DESTINATION,
      context: routingContext,
    },
    [RoutingInteractions.SELF_PICK_UP_FROM_TERMINAL.id]: {
      name: RoutingInteractions.SELF_PICK_UP_FROM_TERMINAL.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.DESTINATION,
      context: routingContext,
    },
    [RoutingInteractions.QUICK_ESTIMATION.id]: {
      name: RoutingInteractions.QUICK_ESTIMATION.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CARGO_DETAILS,
      context: routingContext,
    },
    [RoutingInteractions.FULL_CONTAINER_LOAD.id]: {
      name: RoutingInteractions.FULL_CONTAINER_LOAD.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CARGO_DETAILS,
      context: routingContext,
    },
    [RoutingInteractions.LOOSE_CARGO.id]: {
      name: RoutingInteractions.LOOSE_CARGO.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CARGO_DETAILS,
      context: routingContext,
    },
    [RoutingInteractions.TOTAL_WEIGHT.id]: {
      name: RoutingInteractions.TOTAL_WEIGHT.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.QUICK_ESTIMATION,
      context: routingContext,
    },
    [RoutingInteractions.TOTAL_VOLUME.id]: {
      name: RoutingInteractions.TOTAL_VOLUME.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.QUICK_ESTIMATION,
      context: routingContext,
    },
    [RoutingInteractions.NEXT.id]: {
      name: RoutingInteractions.NEXT.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.QUICK_ESTIMATION,
      context: routingContext,
    },
    [RoutingInteractions.STANDARD_DRY_CONTAINER_20.id]: {
      name: RoutingInteractions.STANDARD_DRY_CONTAINER_20.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CONTAINER_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.STANDARD_DRY_CONTAINER_40.id]: {
      name: RoutingInteractions.STANDARD_DRY_CONTAINER_40.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CONTAINER_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.HIGH_CUBE_DRY_CONTAINER_40.id]: {
      name: RoutingInteractions.HIGH_CUBE_DRY_CONTAINER_40.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CONTAINER_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.HIGH_CUBE_DRY_CONTAINER_45.id]: {
      name: RoutingInteractions.HIGH_CUBE_DRY_CONTAINER_45.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CONTAINER_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.MORE_CONTAINERS.id]: {
      name: RoutingInteractions.MORE_CONTAINERS.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CONTAINER_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.LESS_CONTAINERS.id]: {
      name: RoutingInteractions.LESS_CONTAINERS.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CONTAINER_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.NET_WEIGHT.id]: {
      name: RoutingInteractions.NET_WEIGHT.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CONTAINER_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.GROSS_WEIGHT.id]: {
      name: RoutingInteractions.GROSS_WEIGHT.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CONTAINER_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.TOTAL_WEIGHT.id]: {
      name: RoutingInteractions.TOTAL_WEIGHT.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CONTAINER_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.ADD_ADDITIONAL_CONTAINER_TYPE.id]: {
      name: RoutingInteractions.ADD_ADDITIONAL_CONTAINER_TYPE.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.CONTAINER_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.BAGS.id]: {
      name: RoutingInteractions.BAGS.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.WOODEN_CASES.id]: {
      name: RoutingInteractions.WOODEN_CASES.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.CARTONS.id]: {
      name: RoutingInteractions.CARTONS.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.CRATES.id]: {
      name: RoutingInteractions.CRATES.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    ...generateRoutingEntryForPallet(RoutingInteractions.PALLET),
    ...generateRoutingEntryForPallet(RoutingInteractions.COMMON_PALLET_EUR_1),
    ...generateRoutingEntryForPallet(RoutingInteractions.COMMON_PALLET_EUR_3),
    ...generateRoutingEntryForPallet(RoutingInteractions.COMMON_PALLET_EUR_6),
    ...generateRoutingEntryForPallet(RoutingInteractions.MY_PALLET_1),
    ...generateRoutingEntryForPallet(RoutingInteractions.MY_PALLET_2),
    ...generateRoutingEntryForPallet(RoutingInteractions.MY_PALLET_3),
    ...generateRoutingEntryForPallet(RoutingInteractions.IN_PALLET_1),
    ...generateRoutingEntryForPallet(RoutingInteractions.IN_PALLET_2),
    ...generateRoutingEntryForPallet(RoutingInteractions.IN_PALLET_6),
    ...generateRoutingEntryForPallet(RoutingInteractions.T_PALLET_11),
    ...generateRoutingEntryForPallet(RoutingInteractions.T_PALLET_12),
    ...generateRoutingEntryForPallet(RoutingInteractions.JP_PALLET_1),
    ...generateRoutingEntryForPallet(RoutingInteractions.JP_PALLET_2),
    ...generateRoutingEntryForPallet(RoutingInteractions.US_PALLET_1),
    ...generateRoutingEntryForPallet(RoutingInteractions.AU_PALLET_STD),
    ...generateRoutingEntryForPallet(RoutingInteractions.AU_PALLET_EXP),
    ...generateRoutingEntryForPallet(RoutingInteractions.MX_PALLET_STD),
    ...generateRoutingEntryForPallet(RoutingInteractions.MX_PALLET_EUR_1),
    ...generateRoutingEntryForPallet(RoutingInteractions.MX_PALLET_CP_3),
    ...generateRoutingEntryForPallet(RoutingInteractions.BR_PALLET_EUR_3),
    ...generateRoutingEntryForPallet(RoutingInteractions.CA_PALLET_EUR_1),
    ...generateRoutingEntryForPallet(RoutingInteractions.CA_PALLET_EUR_3),
    ...generateRoutingEntryForPallet(RoutingInteractions.CA_PALLET_EUR_6),
    ...generateRoutingEntryForPallet(RoutingInteractions.CN_PALLET_1),
    ...generateRoutingEntryForPallet(RoutingInteractions.CN_PALLET_2),
    ...generateRoutingEntryForPallet(RoutingInteractions.CN_PALLET_3),
    ...generateRoutingEntryForPallet(RoutingInteractions.ZA_PALLET_EUR_1),
    ...generateRoutingEntryForPallet(RoutingInteractions.ZA_PALLET_EUR_3),
    ...generateRoutingEntryForPallet(RoutingInteractions.ZA_PALLET_EUR_6),
    ...generateRoutingEntryForPallet(RoutingInteractions.EUR_1_PALLET),
    ...generateRoutingEntryForPallet(RoutingInteractions.EUR_3_PALLET),
    ...generateRoutingEntryForPallet(RoutingInteractions.EUR_6_PALLET),
    [RoutingInteractions.DRUM.id]: {
      name: RoutingInteractions.DRUM.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.ROLLS.id]: {
      name: RoutingInteractions.ROLLS.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.SKELETON_BOX.id]: {
      name: RoutingInteractions.SKELETON_BOX.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.IBC_TANK.id]: {
      name: RoutingInteractions.IBC_TANK.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.METRIC_UNIT.id]: {
      name: RoutingInteractions.METRIC_UNIT.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.IMPERIAL_UNIT.id]: {
      name: RoutingInteractions.IMPERIAL_UNIT.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.DUPLICATE_CARGO_TYPE.id]: {
      name: RoutingInteractions.DUPLICATE_CARGO_TYPE.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.DELETE_CARGO_TYPE.id]: {
      name: RoutingInteractions.DELETE_CARGO_TYPE.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.ADD_CARGO_TYPE.id]: {
      name: RoutingInteractions.ADD_CARGO_TYPE.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.LOOSE_CARGO_TYPE,
      context: routingContext,
    },
    [RoutingInteractions.GENERAL_CARGO.id]: {
      name: RoutingInteractions.GENERAL_CARGO.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.SPECIAL_HANDLING,
      context: routingContext,
    },
    [RoutingInteractions.SPECIAL_HANDLING.id]: {
      name: RoutingInteractions.SPECIAL_HANDLING.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.SPECIAL_HANDLING,
      context: routingContext,
    },
    [RoutingInteractions.DANGEROUS_GOODS.id]: {
      name: RoutingInteractions.DANGEROUS_GOODS.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.SPECIAL_HANDLING,
      context: routingContext,
    },
    [RoutingInteractions.DANGEROUS_GOODS.id]: {
      name: RoutingInteractions.DANGEROUS_GOODS.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.SPECIAL_HANDLING,
      context: routingContext,
    },
    [RoutingInteractions.LITHIUM_BATTERIES.id]: {
      name: RoutingInteractions.LITHIUM_BATTERIES.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.SPECIAL_HANDLING,
      context: routingContext,
    },
    [RoutingInteractions.TEMPERATURE_CONTROL.id]: {
      name: RoutingInteractions.TEMPERATURE_CONTROL.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.SPECIAL_HANDLING,
      context: routingContext,
    },
    [RoutingInteractions.OTHERS.id]: {
      name: RoutingInteractions.OTHERS.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.SPECIAL_HANDLING,
      context: routingContext,
    },
    [RoutingInteractions.STACKABLE.id]: {
      name: RoutingInteractions.STACKABLE.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.SPECIAL_HANDLING,
      context: routingContext,
    },
    [RoutingInteractions.NOT_STACKABLE.id]: {
      name: RoutingInteractions.NOT_STACKABLE.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.SPECIAL_HANDLING,
      context: routingContext,
    },
    [RoutingInteractions.BILLING_COUNTRY.id]: {
      name: RoutingInteractions.BILLING_COUNTRY.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.BILLING_COUNTRY,
      context: routingContext,
    },
    [RoutingInteractions.COMPARE_QUOTES.id]: {
      name: RoutingInteractions.COMPARE_QUOTES.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.BILLING_COUNTRY,
      context: routingContext,
    },
    [RoutingInteractions.START_NEW_BOOKING.id]: {
      name: RoutingInteractions.START_NEW_BOOKING.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.QUOTE_ALREADY_BOOKED,
      context: routingContext,
    },
    [RoutingInteractions.BOOK_AGAIN.id]: {
      name: RoutingInteractions.BOOK_AGAIN.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.QUOTE_ALREADY_BOOKED,
      context: routingContext,
    },
    [RoutingInteractions.REFRESH.id]: {
      name: RoutingInteractions.REFRESH.name,
      ...eventContentButtonInterObj,
      position: RoutingPosition.QUOTE_EXPIRED,
      context: routingContext,
    },
  };
};
