import { errorCodesNumber, errorCodesString } from "constants/errorCodes";
import getConfigClient from "constants/getConfigClient";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import {
  errorPopupItems,
  errorPopupItemsQnB,
  errorPopupItemsReporting,
} from "data/errorPopupItems";
import includes from "lodash/includes";
import { addError } from "redux/error/actions";
import { store } from "redux/store";
import {
  hasValidRefreshToken,
  isAccessTokenExpired,
  refreshTokenIfExpiringSoon,
} from "utils/auth";
import { makeErrorObject } from "utils/error";
import get from "utils/get";
import { clearOut, refreshToken } from "utils/interceptor";
import { includesAnyOf } from "utils/string";
import { v4 as uuidv4 } from "uuid";

const httpLink = new HttpLink({
  uri: ({ operationName }) =>
    `${getConfigClient("GRAPHQL_ENDPOINT")}?op=${operationName}`,
});

const authLink = setContext((_, { headers }) => {
  refreshTokenIfExpiringSoon();
  const token = localStorage.getItem("access_token");
  return {
    headers: {
      ...headers,
      x_request_id: uuidv4(),
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    const statusCodeInExt =
      get(graphQLErrors[0], "extensions.exception.statusCode") ||
      get(graphQLErrors[0], "extensions.code") ||
      get(graphQLErrors[0], "extensions.response.status");
    if (
      includes(graphQLErrors[0].message, "401") ||
      statusCodeInExt === 401 ||
      statusCodeInExt === "401" ||
      isAccessTokenExpired()
    ) {
      if (hasValidRefreshToken()) {
        return new Observable((observer) => {
          refreshToken()
            .then((token) => {
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  x_request_id: uuidv4(),
                  authorization: "Bearer " + token,
                },
              });
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              };
              forward(operation).subscribe(subscriber);
            });
        });
      } else {
        clearOut();
      }
    }
    if (
      includesAnyOf(graphQLErrors[0].message, errorCodesString) ||
      includes([...errorCodesNumber, ...errorCodesString], statusCodeInExt)
    ) {
      const shouldAdd = includes(
        [
          ...errorPopupItems,
          ...errorPopupItemsQnB,
          ...errorPopupItemsReporting,
        ],
        operation?.operationName
      );
      if (
        shouldAdd &&
        !(
          operation?.operationName === "GetRecalculatedQuoteOption" &&
          includesAnyOf(
            graphQLErrors[0].message,
            ["500"] || includes([500, "INTERNAL_SERVER_ERROR"], statusCodeInExt)
          )
        )
      ) {
        store.dispatch(
          addError(makeErrorObject(graphQLErrors, operation, statusCodeInExt))
        );
      }
    }
  }
});

const localeLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    x_locale_id: store.getState()?.userSettings?.locale || "en",
  },
}));

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      keyFields: ["email"],
    },
    ShipmentData: {
      keyFields: ["shipmentId"],
    },
    ShipmentDetails: {
      keyFields: ["shipmentId"],
    },
    CorporatePartner: {
      keyFields: ["uuid"],
    },
  },
  possibleTypes: {
    NotificationConfigBase: ["GeneralConfig", "CustomConfig"],
  },
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(localeLink).concat(errorLink).concat(httpLink),
  resolvers: {
    User: {
      nameInitials(parent) {
        return (
          (parent.firstName || "").slice(0, 1) +
          (parent.lastName || "").slice(0, 1)
        );
      },
    },
  },
});

export default client;
