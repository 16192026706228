import styledTheme from "constants/styledTheme";
import { icons } from "@dhl-official/icons";
import { DhlIcon } from "@dhl-official/react-library";
import React from "react";
import * as S from "./styles";

const BurgerMenu = (props) => {
  return (
    <S.StyledSVGWrapper
      fill={styledTheme.colors.dhlRed}
      hoverFill={styledTheme.colors.hoverRed}
      as="button"
      aria-label="Menu"
      onClick={() => props.onClick()}
    >
      {props?.isOpened === true ? (
        <DhlIcon src={icons.Cancel} />
      ) : (
        <DhlIcon src={icons.MenuBurger} />
      )}
    </S.StyledSVGWrapper>
  );
};

export default BurgerMenu;
