import { Shipment } from "constants/shipments";

export enum TableColumn {
  DETAILS = "DETAILS",
  SHIPPER = "SHIPPER",
  CONSIGNEE = "CONSIGNEE",
  CARRIER = "CARRIER",
  CUSTOMER = "CUSTOMER",
  MILESTONES = "MILESTONES",
  SETTINGS = "SETTINGS",
}

export const swappableColumns: [
  TableColumn.SHIPPER,
  TableColumn.CONSIGNEE,
  TableColumn.CARRIER,
  TableColumn.CUSTOMER
] = [
  TableColumn.SHIPPER,
  TableColumn.CONSIGNEE,
  TableColumn.CARRIER,
  TableColumn.CUSTOMER,
];

export interface TableProps {
  rowData: Shipment[];
  isLoading: boolean;
  noContentFallback: React.ReactElement;
  onRowClick: (shipmentId: string) => void;
}
