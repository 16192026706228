import { defineMessages } from "react-intl";

export default defineMessages({
  transportMode: {
    id: "bookingStandalone.bookingMode.transportMode",
    defaultMessage: "Transport Mode",
  },
  transportModeSubtitle: {
    id: "bookingStandalone.bookingMode.transportMode.subtitle",
    defaultMessage: "How would you like your goods to be transported?",
  },
  nextButton: {
    id: "bookingStandalone.bookingMode.nextButton.label",
    defaultMessage: "Next",
  },
  MODE_AFR: {
    id: "bookingStandalone.bookingMode.products.AFR_MODE",
    defaultMessage: "Air",
  },
  MODE_OFR: {
    id: "bookingStandalone.bookingMode.products.OFR_MODE",
    defaultMessage: "Ocean",
  },
});
