import getConfigClient from "constants/getConfigClient";
import getKeycloakUrl from "helpers/getKeycloakUrl";

export const getDHLiUrl = (isDemo) => {
  const redirectUri = new URL(
    "dhli-client/ssologin",
    getConfigClient("DHLI_PORTAL_URL")
  );
  if (isDemo) {
    redirectUri.searchParams.set("inDemoMode", "1");
  }
  return getKeycloakUrl(redirectUri).toString();
};

export const getC2PortalUrl = () =>
  getKeycloakUrl(getConfigClient("C2_PORTAL_URL")).toString();
