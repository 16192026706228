import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled from "styled-components";

const BookingHalfContainer = styled.div`
  flex: 1;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    max-width: 36rem;
    margin: 0 auto;
  }

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    max-width: none;
  }
`;

export default BookingHalfContainer;
