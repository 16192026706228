import useLanguageMessages from "hooks/useLanguageMessages";
import useStorageItem from "hooks/useStorageItem";
import noop from "lodash/noop";
import qs from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setLocale } from "redux/userSettings/actions";
import { defaultLanguage, languageStorageKey } from "./Translations.constants";
import {
  languagesContext,
  loadingLanguagesContext,
  setContext,
  languageContext,
} from "./Translations.context";
import {
  getCodeSecure,
  getLanguageForIntl,
  getNavigatorLanguage,
} from "./Translations.helpers";
import {
  useAvailableLanguages,
  useGetUserLanguage,
  useSynchroniseTimeFormatWithLanguage,
  useUpdateUserLanguage,
} from "./Translations.hooks";

export const TranslationsProvider = ({ children }) => {
  const [activeLanguage, setActiveLanguage] = useState(defaultLanguage);
  const dispatch = useDispatch();
  const loggedInUserEmail = useSelector(
    (state) => state.auth.loggedInUserEmail
  );
  const isLoggedIn = loggedInUserEmail !== "";
  const { languages, isLoadingLanguages } = useAvailableLanguages(isLoggedIn);
  const [updateUserLanguageMutation, isUpdatingUserLanguage] =
    useUpdateUserLanguage(!isLoggedIn);
  const userLanguage = useGetUserLanguage(isLoggedIn);

  const [storedLanguage, storeLanguage] = useStorageItem(
    localStorage,
    languageStorageKey
  );

  const navigatorLanguages = getNavigatorLanguage(languages);
  const navigatorLanguage = getCodeSecure(languages, navigatorLanguages);
  const messages = useLanguageMessages(!isLoadingLanguages && activeLanguage);

  useSynchroniseTimeFormatWithLanguage(isLoadingLanguages, activeLanguage);

  // Store language locally when user is not logged
  useEffect(() => {
    const storageLanguage = getCodeSecure(languages, storedLanguage);
    const queryParams = qs.parse(window.location.search);
    const queryParamsLanguage = getCodeSecure(languages, queryParams.lang);
    if (languages && !isLoggedIn) {
      // 1.  User selection from footer (local storage) || 2.  URL query parameter lang ||  3.Browser Language || 4.Default Language
      const language =
        storageLanguage ||
        queryParamsLanguage ||
        navigatorLanguage ||
        defaultLanguage;
      setActiveLanguage(language);
      storeLanguage(language);
    }
  }, [navigatorLanguage, storedLanguage, storeLanguage, languages, isLoggedIn]);

  // Store language locally when user logs in
  useEffect(() => {
    if (userLanguage && isLoggedIn) {
      // 1.User Language || 2.Browser Language || 3.Default Language
      const language = userLanguage || navigatorLanguage || defaultLanguage;
      setActiveLanguage(language);
      storeLanguage(language);
      dispatch(setLocale(language));
    }
  }, [dispatch, navigatorLanguage, storeLanguage, userLanguage, isLoggedIn]);

  // updates selected language value
  const updateUserLanguage = useCallback(
    (newLanguage) => {
      const code = getCodeSecure(languages, newLanguage);
      if (code && !isUpdatingUserLanguage) {
        storeLanguage(code);
        setActiveLanguage(code);
        if (isLoggedIn) {
          dispatch(setLocale(code));
          updateUserLanguageMutation(code);
        }
      }
    },
    [
      isLoggedIn,
      languages,
      storeLanguage,
      dispatch,
      updateUserLanguageMutation,
      isUpdatingUserLanguage,
    ]
  );

  return (
    <languagesContext.Provider value={languages}>
      <loadingLanguagesContext.Provider value={isLoadingLanguages}>
        <languageContext.Provider value={activeLanguage}>
          <setContext.Provider
            value={isLoadingLanguages ? noop : updateUserLanguage}
          >
            <IntlProvider
              locale={getLanguageForIntl(activeLanguage)}
              messages={messages}
            >
              {children}
            </IntlProvider>
          </setContext.Provider>
        </languageContext.Provider>
      </loadingLanguagesContext.Provider>
    </languagesContext.Provider>
  );
};
