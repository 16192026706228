import types from "./action-types";

export const saveVisibilityScope = (scope, isDemoMode) => ({
  type: types.SAVE_VISIBILITY_SCOPE,
  payload: scope,
  isDemoMode: isDemoMode || false,
});

export const setCDZFilter = (key, value) => ({
  type: types.SET_CDZ_FILTER,
  payload: { key, value },
});

export const unsetCDZFilter = (key) => ({
  type: types.UNSET_CDZ_FILTER,
  payload: { key },
});

export const resetCDZFilter = () => ({
  type: types.RESET_CDZ_FILTER,
  payload: {},
});
export const replaceCDZFilter = (value) => ({
  type: types.REPLACE_CDZ_FILTER,
  payload: value,
});

export const setShipmentFilter = (key, value) => ({
  type: types.SET_SHIPMENT_FILTER,
  payload: { key, value },
});

export const unsetShipmentFilter = (key) => ({
  type: types.UNSET_SHIPMENT_FILTER,
  payload: { key },
});

export const resetShipmentFilter = () => ({
  type: types.RESET_SHIPMENT_FILTER,
  payload: {},
});

export const initBookings = (bookingsColumns) => ({
  type: types.INIT_BOOKINGS,
  payload: bookingsColumns,
});

export const initBookingsDrafts = (bookingsColumns) => ({
  type: types.INIT_BOOKINGS_DRAFTS,
  payload: bookingsColumns,
});

export const setBookingsListFilter = (key, value) => ({
  type: types.SET_BOOKINGS_LIST_FILTER,
  payload: { key, value },
});

export const unsetBookingsListFilter = (key) => ({
  type: types.UNSET_BOOKINGS_LIST_FILTER,
  payload: { key },
});

export const resetBookingsListFilter = () => ({
  type: types.RESET_BOOKINGS_LIST_FILTER,
  payload: {},
});

export const replaceBookingsListFilter = (value) => ({
  type: types.REPLACE_BOOKINGS_LIST_FILTER,
  payload: value,
});

export const replaceShipmentFilter = (value) => ({
  type: types.REPLACE_SHIPMENT_FILTER,
  payload: value,
});

export const setQuotesFilter = (key, value) => ({
  type: types.SET_QUOTES_FILTER,
  payload: { key, value },
});

export const unsetQuotesFilter = (key) => ({
  type: types.UNSET_QUOTES_FILTER,
  payload: { key },
});

export const resetQuotesFilter = () => ({
  type: types.RESET_QUOTES_FILTER,
  payload: {},
});

export const replaceQuotesFilter = (payload) => ({
  type: types.REPLACE_QUOTES_FILTER,
  payload,
});

export const setShipmentSearchKeywords = (payload) => ({
  type: types.SET_SHIPMENT_SEARCH_KEYWORDS,
  payload: payload,
});

export const addShipmentSearchPhrase = (payload) => ({
  type: types.ADD_SHIPMENT_SEARCH_PHRASE,
  payload: payload,
});

export const getWidgetOrder = (payload) => ({
  type: types.GET_WIDGET_ORDER,
  payload: payload,
});

export const saveAvailableWidgets = (payload) => ({
  type: types.SAVE_AVAILABLE_WIDGETS,
  payload: payload,
});

export const loadColumnsPreferences = (payload) => ({
  type: types.LOAD_COLUMN_PREFERENCES,
  payload: payload,
});

export const saveColumnPreferences = (payload) => ({
  type: types.SAVE_COLUMN_PREFERENCES,
  payload: payload,
});

export const getWidgetOrderSuccess = (payload) => ({
  type: types.GET_WIDGET_ORDER_SUCCESS,
  payload: payload,
});

export const getWidgetOrderError = (payload) => ({
  type: types.GET_WIDGET_ORDER_ERROR,
  payload: payload,
});

export const saveWidgetList = (payload) => ({
  type: types.SAVE_WIDGET_LIST,
  payload: payload,
});

export const changeWidgetOrder = (source, destination) => ({
  type: types.SAVE_WIDGET_ORDER,
  payload: {
    source: source,
    destination: destination,
  },
});

export const resetWidgetOrder = () => ({
  type: types.RESET_WIDGET_ORDER,
  payload: null,
});

export const enableDisableWidget = (key, enabled) => ({
  type: types.ENABLE_DISABLE_WIDGET,
  payload: {
    key: key,
    enabled: enabled,
  },
});

export const changeColumnOrder = (source, destination) => ({
  type: types.CHANGE_COLUMN_ORDER,
  payload: {
    source: source,
    destination: destination,
  },
});

export const resetShipmentsColumnOrder = () => ({
  type: types.RESET_SHIPMENTS_COLUMN_ORDER,
});

export const changeQuotesColumnOrder = (source, destination) => ({
  type: types.CHANGE_QUOTES_COLUMN_ORDER,
  payload: {
    source: source,
    destination: destination,
  },
});

export const resetQuotesColumnOrder = () => ({
  type: types.RESET_QUOTES_COLUMN_ORDER,
});

export const changeBookingsColumnOrder = (source, destination) => ({
  type: types.CHANGE_BOOKINGS_COLUMN_ORDER,
  payload: {
    source: source,
    destination: destination,
  },
});

export const resetBookingsColumnOrder = () => ({
  type: types.RESET_BOOKINGS_COLUMN_ORDER,
});

export const changeDraftBookingColumnOrder = (source, destination) => ({
  type: types.CHANGE_DRAFT_BOOKINGS_COLUMN_ORDER,
  payload: {
    source: source,
    destination: destination,
  },
});

export const resetDraftBookingColumnOrder = () => ({
  type: types.RESET_DRAFT_BOOKINGS_COLUMN_ORDER,
});

export const changeCDZColumnOrder = (source, destination) => ({
  type: types.CHANGE_CDZ_COLUMN_ORDER,
  payload: {
    source: source,
    destination: destination,
  },
});

export const resetCDZColumnOrder = () => ({
  type: types.RESET_CDZ_COLUMN_ORDER,
});

export const getCorporatePartners = (payload) => ({
  type: types.GET_CORPORATE_PARTNERS,
  payload: payload || {},
});

export const getCorporatePartnersSuccess = (payload) => ({
  type: types.GET_CORPORATE_PARTNERS_SUCCESS,
  payload,
});

export const markAsDownloaded = (payload) => ({
  type: types.MARK_AS_DOWNLOADED,
  payload,
});

export const getCorporatePartnersError = (payload) => ({
  type: types.GET_CORPORATE_PARTNERS_ERROR,
  payload,
});

export const updateAnalyticsTimestamp = (payload) => ({
  type: types.UPDATE_ANALYTICS_TIMESTAMP,
  payload,
});

export const updateCdzExportColumns = (payload) => ({
  type: types.UPDATE_CDZ_EXPORT_COLUMNS,
  payload,
});

export const updateShipmentExportColumns = (payload) => ({
  type: types.UPDATE_SHIPMENT_EXPORT_COLUMNS,
  payload,
});

export const updateQuoteExportColumns = (payload) => ({
  type: types.UPDATE_QUOTE_EXPORT_COLUMNS,
  payload,
});

export const updateLatestTCAccepted = (payload) => ({
  type: types.UPDATE_LATEST_TC_ACCEPTED,
  payload,
});

export const setMapTransportMode = (payload) => ({
  type: types.SET_MAP_TRANSPORT_MODE,
  payload,
});

export const setLocale = (payload) => ({
  type: types.SET_LOCALE,
  payload,
});

export const resetAllUserSettings = (payload) => ({
  type: types.RESET_ALL,
  payload,
});

export const updateReadOnly = (payload) => ({
  type: types.UPDATE_READ_ONLY,
  payload,
});
