import { quotesColumnToFilter } from "constants/quotesColumns";
import FreightIcon from "components/atoms/Icons/FreightIcon";
import StarIcon from "components/atoms/Icons/StarIcon";
import SVGWrapper from "components/atoms/SVGWrapper";
import Countries from "components/organisms/Filters/Country";
import DateRange from "components/organisms/Filters/DateRange";
import FreeText from "components/organisms/Filters/FreeText";
import List from "components/organisms/Filters/List";
import ListIcon from "components/organisms/Filters/ListIcons";
import React from "react";
import get from "utils/get";
import { serviceFilter } from "../../../constants/quotesColumns";

const freightTypesFilter = [
  { icon: "AIR", key: "AFR" },
  { icon: "OCEAN", key: "OFR" },
  { icon: "RAIL", key: "RFR" },
  { icon: "ROAD", key: "RDE" },
];

const getDropdownContent = (
  item,
  bucket,
  onOutsideClick,
  modeServiceFilters
) => {
  switch (item) {
    case "Follow": {
      return (
        <ListIcon
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
          transform={(str) => Boolean(str)}
        >
          <SVGWrapper
            fill="rgba(255, 204, 0, 0.95)"
            hoverFill="rgba(255, 204, 0, 0.95)"
            key={1}
          >
            <StarIcon filled />
          </SVGWrapper>
        </ListIcon>
      );
    }
    case "Mode": {
      let filterList = freightTypesFilter;
      if (["ECONOMY", "CONNECT"].includes(modeServiceFilters?.service)) {
        filterList = freightTypesFilter.filter(
          (service) => service.key === "AFR"
        );
      } else if (["LCL", "FCL"].includes(modeServiceFilters?.service)) {
        filterList = freightTypesFilter.filter(
          (service) => service.key === "OFR"
        );
      }

      return (
        <ListIcon
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        >
          {filterList.map((type) => (
            <SVGWrapper key={type.key}>
              <FreightIcon type={type.icon} />
            </SVGWrapper>
          ))}
        </ListIcon>
      );
    }
    case "Service": {
      let filterList = serviceFilter;
      if (["OFR", "AFR"].includes(modeServiceFilters?.mode)) {
        filterList = serviceFilter.filter(
          (service) => service.mode === modeServiceFilters.mode
        );
      }

      return (
        <List
          filter={quotesColumnToFilter[item]}
          items={filterList.map((service) => ({
            title: service.label,
            key: service.key,
          }))}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "EstimatedPickupDate": {
      return (
        <DateRange
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
          future
        />
      );
    }
    case "CreationDate": {
      return (
        <DateRange
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
          past
        />
      );
    }
    case "CustomerReference": {
      return (
        <FreeText
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "OriginCityCountry": {
      return (
        <FreeText
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "DestinationCityCountry": {
      return (
        <FreeText
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "OriginCountry": {
      return (
        <Countries
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "DestinationCountry": {
      return (
        <Countries
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "QuoteTotal": {
      return null;
    }
    case "QuoteStatus": {
      return (
        <List
          filter={get(quotesColumnToFilter, "QuoteStatus")}
          items={[
            { title: "Active", key: "Active" },
            { title: "Expired", key: "Expired" },
          ]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "BookingStatus": {
      return (
        <List
          filter={get(quotesColumnToFilter, "BookingStatus")}
          items={[
            { title: "Yes", key: true },
            { title: "No", key: false },
          ]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "Housebills": {
      return (
        <FreeText
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "QuoteID": {
      return (
        <FreeText
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "Weight": {
      return null;
    }
    case "Volume": {
      return null;
    }
    case "ExpirationDate": {
      return (
        <DateRange
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "Origin": {
      return null;
    }
    case "Destination": {
      return null;
    }
    case "BillingCountry": {
      return (
        <Countries
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "Company": {
      return (
        <FreeText
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "CreatedBy": {
      return (
        <FreeText
          filter={quotesColumnToFilter[item]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "RateType": {
      return (
        <List
          filter={get(quotesColumnToFilter, "RateType")}
          items={[
            { title: "MARKET", key: "MARKET" },
            { title: "CONTRACTED", key: "CONTRACTED" },
          ]}
          onOutsideClick={onOutsideClick}
          module="quotes"
        />
      );
    }
    case "ServiceCharge": {
      return null;
    }
    case "Pickup": {
      return null;
    }
    case "Delivery": {
      return null;
    }
    case "ExportCustomsClearance": {
      return null;
    }
    case "ImportCustomsClearance": {
      return null;
    }
    case "DHLCargoInsuranceDHLShipmentValueProtection": {
      return null;
    }
    case "DHLClimateNeutral": {
      return null;
    }
    default: {
      return "";
    }
  }
};

export default getDropdownContent;
