import { DownloadState } from "constants/DownloadState";
import { TopBarHeight, TopBarHeightMd } from "constants/variables";
import { NewTextButton, media } from "react-lib";
import styled from "styled-components";

export const Wrapper = styled.div<{ downloadState: DownloadState }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - ${TopBarHeight});

  ${media.from("sm")} {
    height: calc(100vh - ${TopBarHeightMd});
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 23rem;
  height: 25.75rem;
  padding: 2.5rem 2.5rem;
  text-align: center;
`;

export const Title = styled.h1`
  font-weight: 900;
  font-stretch: condensed;
  font-size: 2.25rem;
  line-height: 1;
  margin: 2.5rem 0 1.25rem;
`;

export const Label = styled.p`
  margin: 0;
  font-size: 1rem;
  line-height: 1.25;
`;

export const StyledTextButton = styled(NewTextButton)`
  align-self: flex-end;
`;
