import Tip from "components/molecules/Tip/Tip1";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import {
  setQuotesFilter,
  setShipmentFilter,
  setBookingsListFilter,
} from "redux/userSettings/actions";
import styled, { css } from "styled-components";
import { getRem } from "utils/css";
import get from "utils/get";

const FilterItemWrapper = styled.div`
  width: ${getRem(250)};
`;

const FilterItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${getRem(16)} ${getRem(20)};
  width: ${getRem(250)};
  ${(p) =>
    !p.isDisabled
      ? css`
          &:hover {
            background-color: ${(p) => p.theme.colors.black5};
          }
        `
      : null};
  ${(p) =>
    p.isDisabled
      ? css`
          opacity: 0.4;
        `
      : null};
`;

const Title = styled.div`
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const Count = styled.div`
  margin-left: ${getRem(10)};
  padding-right: ${getRem(2)};
  // flex: 1 1 ${getRem(120)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ListBucket = (props) => {
  const items = get(props, "bucket.bucketItems", []);
  const handleItemClick = (key, count) => {
    if (count > 0 && props.filter) {
      if (props.module === "quotes") {
        props.setQuotesFilter(props.filter, props.transform(key));
      } else if (props.module === "bookings") {
        props.setBookingsListFilter(props.filter, props.transform(key));
      } else {
        props.setShipmentFilter(props.filter, props.transform(key));
      }
    }
    props.onOutsideClick();
  };
  return (
    <FilterItemWrapper>
      {items.length === 0 ? (
        <span style={{ display: "inline-block", padding: getRem(10) }}>
          No Buckets Found
        </span>
      ) : null}
      {items.map((item) => (
        <FilterItem
          onClick={() => handleItemClick(item.key, item.count)}
          isDisabled={item.count === 0}
          key={item.key}
        >
          <Tip
            content={props?.labels?.[item.key] || item.title || item.key}
            fromDropdown
          >
            <Title>{props?.labels?.[item.key] || item.title || item.key}</Title>
          </Tip>
          <Count> ({item.count})</Count>
        </FilterItem>
      ))}
    </FilterItemWrapper>
  );
};
ListBucket.defaultProps = {
  bucket: {},
  transform: (str) => str,
};
ListBucket.propTypes = {
  bucket: PropTypes.object,
  transform: PropTypes.func,
};

export default connect(
  (state) => ({
    userSettings: state.userSettings,
  }),
  { setShipmentFilter, setQuotesFilter, setBookingsListFilter }
)(ListBucket);
