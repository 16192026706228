import { Booking } from "constants/bookWorkflow";
import { qnbServiceToMdmService } from "constants/masterDataManagement";
import { AirFreightService } from "constants/quoteAndBook";
import { formatServiceLevel } from "helpers/bookWorkflow";
import { useMDMServices } from "hooks/masterDataManagement";
import React, { ReactElement } from "react";
import { useIntl } from "react-intl";

type BookingParts = Pick<Booking, "product" | "cargoItems" | "serviceLevel">;

interface ServiceLevelProps<T extends BookingParts> {
  booking: T;
  children?: (serviceLevelLabel: undefined | string) => null | ReactElement;
}

const ServiceLevelLabel = <T extends BookingParts>({
  booking,
  children,
}: ServiceLevelProps<T>): null | ReactElement => {
  const intl = useIntl();
  const qnbServiceLevel: AirFreightService | undefined = Object.keys(
    qnbServiceToMdmService
  ).find((service) => service === booking.serviceLevel) as AirFreightService;
  const serviceCode = qnbServiceLevel
    ? qnbServiceToMdmService[qnbServiceLevel]
    : booking.serviceLevel;

  const allServiceLevels = useMDMServices();
  const serviceLevel = allServiceLevels?.find(
    (serviceLevel) => serviceLevel.code === serviceCode
  );

  const serviceLevelLabel = formatServiceLevel(
    intl,
    booking.serviceLevel,
    booking.product,
    serviceLevel
  );

  return children ? children(serviceLevelLabel) : <>{serviceLevelLabel}</>;
};

export default ServiceLevelLabel;
