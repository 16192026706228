import PropTypes from "prop-types";
import { WeakValidationMap } from "react";
import { colors } from "react-lib";
import styled from "styled-components";
import type { CSSProp } from "styled-components";
import { SCREEN_SIZES } from "../../utils/css";

interface SVGWrapperProps {
  margin?: string;
  padding?: string;
  focusedStyles?: CSSProp;
  showPointer?: boolean;
  fill?: string;
  fillOpacity?: string;
  height?: string;
  width?: string;
  heightMd?: string;
  widthMd?: string;
  hoverFill?: string;
  fillPath?: string;
  isDisabled?: boolean | undefined;
}

const SVGWrapper = styled.span<SVGWrapperProps>`
  display: inline-block;
  border: none;
  background: none;
  outline: none;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  cursor: ${({ showPointer, isDisabled }) =>
    isDisabled ? "not-allowed" : showPointer ? "pointer" : "default"};

  svg {
    transition: fill 0.2s;
    fill: ${({ fill }) => fill};
    fill-opacity: ${({ fillOpacity }) => fillOpacity};
    height: ${({ height }) => height};
    width: ${({ width }) => width};

    @media (min-width: ${SCREEN_SIZES.from.lg}) {
      height: ${({ heightMd, height }) => heightMd || height};
      width: ${({ widthMd, width }) => widthMd || width};
    }
  }

  path {
    fill: ${({ fillPath }) => fillPath};
  }

  &:focus {
    outline: none;
    ${({ focusedStyles }) => focusedStyles && focusedStyles};
  }

  &:hover {
    transition: fill 0.2s;

    svg {
      fill: ${({ hoverFill }) => hoverFill && hoverFill};
    }
  }
`;

const SVGWrapperPropTypes: WeakValidationMap<SVGWrapperProps> = {
  fill: PropTypes.string,
  hoverFill: PropTypes.string,
  showPointer: PropTypes.bool,
  width: PropTypes.string,
  widthMd: PropTypes.string,
  height: PropTypes.string,
  heightMd: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  fillOpacity: PropTypes.string,
};

SVGWrapper.propTypes = SVGWrapperPropTypes;

SVGWrapper.defaultProps = {
  fill: colors.black,
  hoverFill: colors.black,
  showPointer: false,
  width: "1.5rem",
  height: "1.5rem",
  padding: "0",
  margin: "0",
};

export default SVGWrapper;
