import { columnToFilter } from "constants/columns";
import StarIcon from "components/atoms/Icons/StarIcon";
import SVGWrapper from "components/atoms/SVGWrapper";
import DateRange from "components/organisms/Filters/DateRange";
import FreeText from "components/organisms/Filters/FreeText";
import ListBucket from "components/organisms/Filters/ListBucket";
import ListBucketSearch from "components/organisms/Filters/ListBucketSearch/ListBucketSearch";
import ListIconBucket from "components/organisms/Filters/ListIconBucket";
import ListIcon from "components/organisms/Filters/ListIcons";
import { messages } from "components/organisms/ShipmentDetails/ShipmentDetails.messages";
import React from "react";
import { useIntl } from "react-intl";
import {
  shipmentDelayStatuses,
  shipmentPhasesLabels,
} from "../../../data/shipmentDetailTypes";

const transformTransportMode = (str) => {
  switch (str) {
    case "AIR":
      return "AIR";
    case "OCEAN":
      return "OCEAN";
    case "ROAD":
      return "ROAD";
    case "RAIL":
      return "RAIL";
    case "IW":
      return "INLAND_WATERWAY";
    default:
      return null;
  }
};

const StatusComp = ({ item, bucket, onOutsideClick }) => {
  const intl = useIntl();
  const labels = {
    [shipmentDelayStatuses.DELAYED]: intl.formatMessage(
      messages[`shipmentDelayStatus_${shipmentDelayStatuses.DELAYED}`]
    ),
    [shipmentDelayStatuses.ON_TIME]: intl.formatMessage(
      messages[`shipmentDelayStatus_${shipmentDelayStatuses.ON_TIME}`]
    ),
  };

  return (
    <ListBucket
      filter={columnToFilter[item]}
      bucket={bucket}
      onOutsideClick={onOutsideClick}
      labels={labels}
    />
  );
};

const getDropdownContent = (item, bucket, onOutsideClick) => {
  switch (item) {
    case "Follow": {
      return (
        <ListIcon
          filter="isFavorite"
          onOutsideClick={onOutsideClick}
          transform={(str) => Boolean(str)}
        >
          <SVGWrapper
            fill="rgba(255, 204, 0, 0.95)"
            hoverFill="rgba(255, 204, 0, 0.95)"
            key={1}
          >
            <StarIcon filled />
          </SVGWrapper>
        </ListIcon>
      );
    }
    case "Mode": {
      return (
        <ListIconBucket
          filter="transportMode"
          transform={transformTransportMode}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
        />
      );
    }
    case "Departure": {
      return (
        <DateRange
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "Origin": {
      return (
        <ListBucketSearch
          name="Origin"
          id="Origin"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="ORIGIN"
        />
      );
    }
    case "Arrival": {
      return (
        <DateRange
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "Destination": {
      return (
        <ListBucketSearch
          name="Destination"
          id="Destination"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="DESTINATION"
        />
      );
    }
    case "HouseBill": {
      return (
        <ListBucketSearch
          name="House Bill"
          id="HouseBill"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          columnKey="HOUSEBILL"
          placeholder="Search to filter"
        />
      );
    }
    case "Phase": {
      return (
        <ListBucket
          filter={columnToFilter[item]}
          bucket={bucket}
          onOutsideClick={onOutsideClick}
          labels={shipmentPhasesLabels}
        />
      );
    }
    case "Status":
      return (
        <StatusComp
          item={item}
          bucket={bucket}
          onOutsideClick={onOutsideClick}
        />
      );
    case "LastEvent": {
      return (
        <ListBucketSearch
          name="LAST_EVENT"
          id="LAST_EVENT"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="LAST_EVENT"
        />
      );
    }
    case "Carrier": {
      return (
        <ListBucketSearch
          name="CARRIER"
          id="CARRIER"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="CARRIER"
        />
      );
    }
    case "Shipper": {
      return (
        <ListBucketSearch
          name="SHIPPER"
          id="SHIPPER"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="SHIPPER"
        />
      );
    }
    case "Consignee": {
      return (
        <ListBucketSearch
          name="CONSIGNEE"
          id="CONSIGNEE"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="CONSIGNEE"
        />
      );
    }
    case "Customer": {
      return (
        <ListBucketSearch
          name="CUSTOMER_NAME"
          id="CUSTOMER_NAME"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="CUSTOMER_NAME"
        />
      );
    }
    case "Reference": {
      return (
        <FreeText
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "CustomsEntryNumber": {
      return (
        <FreeText
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "OriginCountry": {
      return (
        <ListBucketSearch
          name="ORIGIN_COUNTRY_NAME"
          id="ORIGIN_COUNTRY_NAME"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          columnKey="ORIGIN_COUNTRY_NAME"
          bucket={bucket}
        />
      );
    }
    case "OriginPort": {
      return (
        <ListBucketSearch
          name="ORIGIN_PORT"
          id="ORIGIN_PORT"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="ORIGIN_PORT"
        />
      );
    }
    case "DestinationCountry": {
      return (
        <ListBucketSearch
          name="DESTINATION_COUNTRY_NAME"
          id="DESTINATION_COUNTRY_NAME"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          columnKey="DESTINATION_COUNTRY_NAME"
          bucket={bucket}
        />
      );
    }
    case "DestinationPort": {
      return (
        <ListBucketSearch
          name="DESTINATION_PORT"
          id="DESTINATION_PORT"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="DESTINATION_PORT"
        />
      );
    }
    case "EstimatedPickup": {
      return (
        <DateRange
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "ActualPickup": {
      return (
        <DateRange
          past
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "EstimatedDeparturePort": {
      return (
        <DateRange
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "ActualDeparturePort": {
      return (
        <DateRange
          past
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "EstimatedArrivalPort": {
      return (
        <DateRange
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "ActualArrivalPort": {
      return (
        <DateRange
          past
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "EstimatedCustomerDelivery": {
      return (
        <DateRange
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "DocumentHandover": {
      return (
        <DateRange
          past
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "JourneyDuration": {
      return "";
    }
    case "DelayInShipmentArrival": {
      return "";
    }
    case "MasterBill": {
      return (
        <ListBucketSearch
          name="Master Bill"
          id="MasterBill"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          columnKey="MASTERBILL"
          placeholder="Search to filter"
        />
      );
    }
    case "FlightNumber": {
      return (
        <ListBucketSearch
          name="Flight Number"
          id="FlightNumber"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          columnKey="FLIGHT_NUMBER"
          placeholder="Search to filter"
        />
      );
    }
    case "VesselName": {
      return (
        <ListBucketSearch
          name="Vessel Name"
          id="VesselName"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          columnKey="VESSEL"
          placeholder="Search to filter"
        />
      );
    }
    case "ContainerNumber": {
      return (
        <ListBucketSearch
          name="Container Number"
          id="ContainerNumber"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          columnKey="CONTAINER_NUMBER"
          placeholder="Search to filter"
        />
      );
    }
    case "ContainerSize": {
      return "";
    }
    case "GoodsDescription": {
      return "";
    }
    case "Volume": {
      return "";
    }
    case "TotalWeight": {
      return "";
    }
    case "ChargeableWeight": {
      return "";
    }
    case "Pieces": {
      return "";
    }
    case "IncotermsDescription": {
      return (
        <ListBucketSearch
          name="IncotermsDescription"
          id="IncotermsDescription"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="INCOTERMS_NAME"
        />
      );
    }
    case "IncotermsCode": {
      return (
        <ListBucketSearch
          name="IncotermsCode"
          id="IncotermsCode"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="INCOTERMS"
        />
      );
    }
    case "FeatureNames": {
      return (
        <FreeText
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "ProductName": {
      return (
        <FreeText
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
        />
      );
    }
    case "Irregularities": {
      return (
        <ListBucketSearch
          name="Irregularities"
          id="Irregularities"
          filter={columnToFilter[item]}
          onOutsideClick={onOutsideClick}
          bucket={bucket}
          columnKey="IRREGULARITIES"
        />
      );
    }
    default: {
      return "";
    }
  }
};

export default getDropdownContent;
