import { defineMessages } from "react-intl";

const messages = defineMessages({
  weekdayShortDate: {
    id: "dates.weekdayShortDate",
    defaultMessage: "ddd, DD MMM",
  },
  weekdayLongDate: {
    id: "dates.weekdayLongDate",
    defaultMessage: "ddd, DD MMM YYYY",
  },

  veryShortDate: {
    id: "dates.veryShortDate",
    defaultMessage: "DD MMM",
  },
  veryShortDateWithTime: {
    id: "dates.veryShortDateWithTime",
    defaultMessage: "DD MMM, HH:mm",
  },
  shortDate: {
    id: "dates.shortDate",
    defaultMessage: "DD MMM YY",
  },
  longDate: {
    id: "dates.longDate",
    defaultMessage: "DD MMM YYYY",
  },
  longDatetime: {
    id: "dates.longDatetime",
    defaultMessage: "DD MMM YYYY, HH:mm",
  },
  utcDateTime: {
    id: "dates.utcDateTimeAndTimezone",
    defaultMessage: "DD MMM YYYY, HH:mm [UTC] Z",
  },
  utcTimeOnly: {
    id: "dates.utcTimeWithTimezone",
    defaultMessage: "HH:mm [UTC] Z",
  },
  timeOnly: {
    id: "dates.timeOnly",
    defaultMessage: "HH:mm",
  },
  zoneOnly: {
    id: "dates.zoneOnly",
    defaultMessage: "Z",
  },
});

export default messages;
