import gql from "graphql-tag";

export const UPDATE_CONSIGNMENT = gql`
  query UpdateConsignment(
    $uuid: String
    $inputQuote: InputQuote
    $shouldSendNotification: Boolean
  ) {
    updateConsignment(
      uuid: $uuid
      inputQuote: $inputQuote
      shouldSendNotification: $shouldSendNotification
    ) {
      id
      uuid
      housebill
      status
      timestamp
      originalRequest
    }
  }
`;
