import useOutsideClick from "hooks/useOutsideClick";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import FlyOutBottomSection from "./parts/FlyOutBottomSection";
import FlyOutItem from "./parts/FlyOutItem";
import FlyOutUser from "./parts/FlyOutUser";
import { FlyoutDivider, FlyOutWrapper } from "./styles";

const FlyOut = ({
  itemGroups,
  visible,
  onOutsideClick,
  isTopBar,
  hasDemoCPN,
  hasRealCPN,
  onUserModeChanged,
  email,
  nameInitials,
  hideBottomSection,
  className,
  onVisiblityChange,
}) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, onOutsideClick);

  return (
    <FlyOutWrapper visible={visible} ref={wrapperRef} className={className}>
      {isTopBar && hasDemoCPN && hasRealCPN && (
        <>
          <FlyOutUser
            onClick={onUserModeChanged}
            email={email}
            initials={nameInitials}
          />
          <FlyoutDivider />
        </>
      )}
      {itemGroups.map((itemGroup, index) => (
        <React.Fragment key={index}>
          {itemGroup.map((item) => (
            <FlyOutItem
              key={item.key}
              item={item}
              onClick={() => onVisiblityChange(false)}
            />
          ))}
          {index !== itemGroups.length - 1 ? <FlyoutDivider /> : null}
        </React.Fragment>
      ))}

      {!hideBottomSection && (
        <>
          <FlyoutDivider />
          <FlyOutBottomSection onLinkClick={() => onVisiblityChange(false)} />
        </>
      )}
    </FlyOutWrapper>
  );
};

FlyOut.defaultProps = {
  itemGroups: [],
  visible: false,
  onOutsideClick: () => {},
  hideBottomSection: false,
};

FlyOut.propTypes = {
  itemGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        key: PropTypes.string,
        route: PropTypes.string,
        icon: PropTypes.element,
      })
    )
  ),
  visible: PropTypes.bool,
  onOutsideClick: PropTypes.func,
  hideBottomSection: PropTypes.bool,
  onVisiblityChange: PropTypes.func,
  className: PropTypes.string,
};

export default FlyOut;
