export enum SectionActionType {
  ADD = "ADD",
  EDIT = "EDIT",
  REMOVE = "REMOVE",
  NONE = "NONE",
}

export const initialFormValues = {
  addressBook: {
    address: {
      country: {
        code: "",
        countryCode: "",
        label: "",
        value: "",
      },
      streetAddress: "",
      street: "",
      zip: "",
      city: "",
      states: "",
      preferredAddressInputType: "",
      companyName: "",
    },

    contacts: [
      {
        contactName: "",
        email: "",
        phoneNumber: "",
        contactType: [],
        actionType: SectionActionType.NONE,
      },
    ],
  },
};

export interface AddAddressResponse {
  createNewAddressBookCustomerAddress: {
    address_id: number;
    modified_at: string;
  };
}
