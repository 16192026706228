import CustomMinWidthButton from "components/atoms/Buttons/CustomMinWidthButton/CustomMinWidthButton";
import { Color, colors, Input, NewTextButton } from "react-lib";
import styled from "styled-components";
import { SCREEN_SIZES } from "utils/css";

export const StyledForm = styled.form`
  height: auto;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    height: 100%;
  }
`;

export const AddressWrapper = styled.div`
  margin: 0 1.25rem;
  height: auto;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    margin: 0 7.25rem;
  }
`;

export const AddressAndContactDetailsForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    flex-direction: row;
  }
`;

export const AddressAndContactDetailsFormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  &:first-child {
    margin-right: 0;
  }

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    &:first-child {
      margin-right: 1.25rem;
    }
  }
`;

export const FormField = styled.div`
  height: auto;
  min-height: 5.125rem;
`;

export const StyledInput = styled(Input)`
  label {
    height: 3.5rem;
  }
`;

export const AddressModalHeader = styled.div`
  width: 100%;
  height: 1.75rem;
  margin: 1.375rem 0;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.17;
  letter-spacing: normal;
  color: ${colors.webBlack};
`;

export const Buttons = styled.div`
  border-top: 1px solid ${Color.gray20};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 100%;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    padding: 1rem 1.875rem 1rem 6.125rem;
  }
`;

export const DeleteButton = styled(NewTextButton)<{ isVisible: boolean }>`
  width: 3rem;
  justify-content: center;
  align-items: center;
  visibility: ${(props) => !props.isVisible && "hidden"};
`;

export const CancelButton = styled(CustomMinWidthButton)`
  margin-right: 1rem;
  min-width: 5.0125rem;
  width: 5.0125rem;
`;

export const SaveAddressButton = styled(CustomMinWidthButton)`
  line-height: 1.5;
`;
