export enum MenuItemKey {
  ACCOUNT = "account",
  NOTIFICATIONS = "notifications",
  PORTAL_SETTINGS = "portalSettings",
  FEEDBACK = "feedback",
  CONTACT_US = "contactUs",
  LOGOUT = "logout",
  CHANGE_PASSWORD = "changePassword",
  ADDRESS_BOOK = "addressBook",
}

export interface MenuItem {
  key: MenuItemKey;
  text: string;
  route?: string;
  trackCode?: number;
  shouldOpenInNewTab?: boolean;
  onClick?: () => void;
}
