import getConfigClient, { FeatureFlagName } from "constants/getConfigClient";
import useMyDHLiFeatureFlags from "./useMyDHLiFeatureFlags";

const useIsFeatureAvailable = (featureFlag: FeatureFlagName) => {
  const featureFlags = useMyDHLiFeatureFlags();
  const isFeatureAvailable =
    featureFlags === undefined
      ? undefined
      : featureFlags[featureFlag] ?? getConfigClient(featureFlag) ?? true;

  return isFeatureAvailable;
};

export default useIsFeatureAvailable;
