import { defineMessages } from "react-intl";

export const messages = defineMessages({
  required: {
    id: "booking.validations.required",
    defaultMessage: "Required",
  },
  validationCharacters: {
    id: "booking.validations.validationMaxCharacters",
    defaultMessage: "The value must not be higher than {char} characters",
  },
  validationMinCharacters: {
    id: "booking.validations.validationMinCharacters",
    defaultMessage:
      "The value must have at least {char,plural,one{# character}other{# characters}}",
  },
  validateEmail: {
    id: "booking.validations.validateEmail",
    defaultMessage: "The value does not have an email format",
  },
  noSpecialCharacters: {
    id: "booking.validations.noSpecialCharacters",
    defaultMessage:
      "The value you entered contains some unsupported special characters",
  },
  noDigits: {
    id: "booking.validations.noDigit",
    defaultMessage: "Digits are not allowed here",
  },
  alphaCharactersOnly: {
    id: "booking.validations.alphaCharactersOnly",
    defaultMessage: "Please input alphabet characters only",
  },
  positiveValuesOnly: {
    id: "booking.validations.positiveValuesOnly",
    defaultMessage: "Please input positive number",
  },
  onlyIntegerAllowed: {
    id: "booking.validations.onlyIntegerAllowed",
    defaultMessage: "Number must be an integer",
  },
  numberMin: {
    id: "booking.validations.number.min",
    defaultMessage: "The value must be at least {limit}",
  },
  numberMax: {
    id: "booking.validations.number.max",
    defaultMessage: "The value must not be higher than {limit}",
  },
  numberGreater: {
    id: "booking.validations.number.greater",
    defaultMessage: "The value must be higher than {limit}",
  },
  numberTooLong: {
    id: "booking.validations.number.tooLong",
    defaultMessage: "The value must not be higher than {limit} digits",
  },
  numberPrecision: {
    id: "booking.validations.number.precision",
    defaultMessage: "The value must not have more than {limit} decimals",
  },
  infinityReached: {
    id: "booking.validations.number.infinityReached",
    defaultMessage: "Provided number has exceeded maximum range",
  },
});
