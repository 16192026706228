import { cdzColumns } from "constants/cdzColumns";
import { shipmentColumns } from "constants/columns";
import { quotesColumns } from "constants/quotesColumns";
import find from "lodash/find";
import includes from "lodash/includes";
import sortBy from "lodash/sortBy";
import getCorporatePartnersGQL from "queries/AAA/getCorporatePartnersGQL";
import getPreferenceItemGQL from "queries/AAA/getPreferenceItemGQL";
import { loadAvailableWidgets } from "queries/AAA/profile";
import setPreferenceItemGQL from "queries/AAA/setPreferenceItemGQL";
import { put, select } from "redux-saga/effects";
import { TableColumnsKey } from "redux/userSettings/constants";
import get, { getArray } from "utils/get";

import client from "../../apolloClient";
import { setAppState } from "../app/actions";
import { getCurrentUserRoles } from "../auth/actions";
import types from "./action-types";
import {
  getCorporatePartnersError,
  getCorporatePartnersSuccess,
  getWidgetOrderError,
  saveAvailableWidgets,
  saveColumnPreferences,
  saveVisibilityScope,
} from "./actions";

export function* getCorporatePartnersEffect(action) {
  try {
    const result = yield client.query({
      query: getCorporatePartnersGQL,
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });
    const partners = getArray(result, "data.getCorporatePartners.data");
    yield put(
      getCorporatePartnersSuccess(
        partners.map((i) => ({
          key: i.code,
          name: i.name,
          id: i.id,
          uuid: i.uuid,
          demo: i.demo,
        }))
      )
    );
    yield put({
      type: types.LOAD_CORPORATE_PARTNER_PREFERENCE,
      payload: {
        isDemoMode: action.payload.isDemoMode,
      },
    });
  } catch (e) {
    yield put(getCorporatePartnersError());
  }
}

export function* getWidgetOrder() {
  try {
    const userSettings = yield select((state) => state.userSettings);
    const corporatePartnerId = get(userSettings, "visibilityScope.id");
    if (!corporatePartnerId) return;

    const filter = {
      userObjectsFilter: [
        {
          key: "widgetListOrdered",
        },
      ],
    };
    const result = yield client.query({
      query: getPreferenceItemGQL,
      variables: filter,
      fetchPolicy: "no-cache",
    });
    const widgetsOrder = getArray(
      result,
      "data.getPreferenceItem.userObjects[0].userObjectPreferences"
    );

    const availableWidgetsResponse = yield client.query({
      query: loadAvailableWidgets,
      variables: {
        corporatePartnerId: corporatePartnerId,
      },
    });
    const availableWidgets = get(
      availableWidgetsResponse,
      "data.loadAvailableWidgets",
      []
    );

    const widgetArray = availableWidgets.map((item) => {
      if (item.type === "ANALYTICS") {
        return {
          ...item,
          type: item.type + "-" + item.widgetId,
        };
      } else {
        return item;
      }
    });

    const widgetList = widgetArray.reduce(
      (acc, { widgetId, type, header, ...rest }) => {
        let value = null;
        if (widgetsOrder) {
          const widgetItem = widgetsOrder.find((widget) => widget.key === type);
          value = widgetItem && JSON.parse(widgetItem.value);
        }
        const enabled = value ? value.enabled !== false : true;
        const widget = {
          key: type,
          header,
          enabled: enabled,
          ...rest,
        };

        // todo: enable when ordering is requested. currently the requirement is changed to show it only as default order
        // if (value) {
        //   acc[value.order] = widget;
        // } else {
        //   acc.push(widget);
        // }

        acc.push(widget);

        return acc;
      },
      []
    );
    yield put(
      saveAvailableWidgets({
        widgetList,
      })
    );
  } catch (e) {
    yield put(getWidgetOrderError());
  }
}

export function* saveWidgetOrder() {
  const widgetOrder = yield select((state) => state.userSettings.widgetList);
  const widget = [];
  widgetOrder.forEach(({ key, enabled }, index) =>
    widget.push({
      key: key,
      value: JSON.stringify({ enabled, order: index }),
    })
  );
  try {
    const item = {
      userObjects: [
        {
          key: "widgetListOrdered",
          userObjectPreferences: [...widget],
        },
      ],
    };
    yield client.mutate({
      mutation: setPreferenceItemGQL,
      variables: { item },
    });
  } catch (e) {}
}

function mapActionTypeToTableName(actionType) {
  switch (actionType) {
    case types.CHANGE_COLUMN_ORDER:
    case types.RESET_SHIPMENTS_COLUMN_ORDER:
      return TableColumnsKey.SHIPMENTS_COLUMNS;
    case types.CHANGE_QUOTES_COLUMN_ORDER:
    case types.RESET_QUOTES_COLUMN_ORDER:
      return TableColumnsKey.QUOTES_COLUMNS;
    case types.CHANGE_BOOKINGS_COLUMN_ORDER:
    case types.RESET_BOOKINGS_COLUMN_ORDER:
      return TableColumnsKey.BOOKINGS;
    case types.CHANGE_DRAFT_BOOKINGS_COLUMN_ORDER:
    case types.RESET_DRAFT_BOOKINGS_COLUMN_ORDER:
      return TableColumnsKey.BOOKINGS_DRAFTS;
  }
}

export function* saveColumnOrder(action) {
  try {
    const tableName = mapActionTypeToTableName(action.type);
    const tableData = yield select((state) => state.userSettings[tableName]);

    let tableDataInBackendSchema = tableData.reduce((table, column) => {
      table.push({
        key: [
          TableColumnsKey.BOOKINGS,
          TableColumnsKey.BOOKINGS_DRAFTS,
        ].includes(tableName)
          ? column.translationKey
          : column,
        value: table.length > 0 ? table[table.length - 1].value + 1 : 0,
      });
      return table;
    }, []);

    // getting rid of columns which are not present
    if (tableName === TableColumnsKey.SHIPMENTS_COLUMNS) {
      tableDataInBackendSchema = tableDataInBackendSchema.filter((item) =>
        includes(shipmentColumns, item.key)
      );
    } else if (tableName === TableColumnsKey.QUOTES_COLUMNS) {
      tableDataInBackendSchema = tableDataInBackendSchema.filter((item) =>
        includes(quotesColumns, item.key)
      );
    }

    yield client.mutate({
      mutation: setPreferenceItemGQL,
      variables: {
        item: {
          userObjects: [
            {
              key: `${tableName}Order`,
              userObjectPreferences: [
                ...tableDataInBackendSchema.map((item) => ({
                  key: String(item.key),
                  value: String(item.value),
                })),
              ],
            },
          ],
        },
      },
    });
  } catch (e) {}
}

export function* loadColumnPreferences(action) {
  try {
    const filter = {
      userObjectsFilter: [
        {
          key: `${action.payload}Order`,
        },
      ],
    };
    const result = yield client.query({
      query: getPreferenceItemGQL,
      variables: filter,
    });

    let defaultColumns =
      action.payload === "columns"
        ? [...shipmentColumns]
        : action.payload === "cdzColumns"
        ? [...cdzColumns]
        : [...quotesColumns];

    defaultColumns = defaultColumns.map((item, i) => ({
      item: item,
      value: i,
    }));

    const columnOrderPref = getArray(
      result,
      "data.getPreferenceItem.userObjects"
    ).filter((item) => item.key === `${action.payload}Order`);
    let columnOrder = [];
    if (columnOrderPref.length) {
      columnOrder = getArray(columnOrderPref, "[0]userObjectPreferences");
    }

    defaultColumns = defaultColumns.map((item) => {
      const itemInPref = find(columnOrder, { key: item.item });
      return {
        item: item.item,
        value: itemInPref ? parseInt(itemInPref.value) : parseInt(item.value),
      };
    });

    const orderedColumns = sortBy(defaultColumns, "value").map(
      (item) => item.item
    );

    yield put(
      saveColumnPreferences({
        column: action.payload,
        columnList: [...orderedColumns],
      })
    );
  } catch (e) {}
}

export function* saveCorporatePartnerPreference(action) {
  try {
    const item = {
      userObjects: [
        {
          key: "userSettings",
          userObjectPreferences: [
            {
              key: `corporatePartner${action.isDemoMode ? "Demo" : "Real"}`,
              value: JSON.stringify(action.payload),
            },
          ],
        },
      ],
    };
    yield client.mutate({
      mutation: setPreferenceItemGQL,
      variables: { item },
    });
    yield put(getCurrentUserRoles());
    if (localStorage.getItem("firstLoadCompleted") !== "true") {
      localStorage.setItem("firstLoadCompleted", "true");
    }
  } catch (e) {}
}

export function* loadCorporatePartnerPreference(action) {
  try {
    const filter = {
      userObjectsFilter: [
        {
          key: "userSettings",
        },
      ],
    };
    const result = yield client.query({
      query: getPreferenceItemGQL,
      variables: filter,
      fetchPolicy: "network-only",
    });
    const userPreferences = getArray(
      result,
      "data.getPreferenceItem.userObjects[0].userObjectPreferences"
    );
    const isFirstLoad = localStorage.getItem("firstLoadCompleted") !== "true";
    const allCorporatePartners = yield select(
      (state) => state.userSettings.corporatePartners
    );
    const hasDemoCPN = allCorporatePartners.some((i) => i.demo);
    yield put(setAppState("hasNoDemoCPs", !hasDemoCPN));
    const filterType =
      (action.payload.isDemoMode || isFirstLoad) && hasDemoCPN
        ? "Demo"
        : "Real";
    const filteredUserPreferencesByType = userPreferences.filter(
      (x) => x.key === `corporatePartner${filterType}`
    );
    if (
      filteredUserPreferencesByType.length &&
      filteredUserPreferencesByType[0].value !== "null"
    ) {
      let corporatePartner = {};
      try {
        const preferenceCorporatePartner = JSON.parse(
          filteredUserPreferencesByType[0].value
        );
        const isPreferenceCorporatePartnerValid =
          filterType === "Demo"
            ? preferenceCorporatePartner.demo
            : !preferenceCorporatePartner.demo;
        if (isPreferenceCorporatePartnerValid) {
          corporatePartner = preferenceCorporatePartner;
        }
      } catch (e) {}
      const corporatePartnerObjectInList = allCorporatePartners.find(
        (cor) => cor.key === corporatePartner.key
      );
      if (corporatePartnerObjectInList) {
        yield put(setAppState("isCheckingDemoProfile", false));
        yield put(
          saveVisibilityScope(
            corporatePartnerObjectInList,
            corporatePartnerObjectInList.demo
          )
        );
        return;
      }
    }
    const firstCorporatePartnerOfType = allCorporatePartners
      .filter((i) => (filterType === "Demo" ? i.demo : !i.demo))
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (a.name === b.name) {
          return 0;
        } else return -1;
      })[0];
    if (firstCorporatePartnerOfType) {
      yield put(setAppState("isCheckingDemoProfile", false));
      yield put(
        saveVisibilityScope(
          firstCorporatePartnerOfType,
          firstCorporatePartnerOfType.demo
        )
      );
    } else {
      yield put(
        setAppState(
          filterType === "Demo" ? "hasNoDemoCPs" : "hasNoRealCPs",
          true
        )
      );
      const { hasNoRealCPs, hasNoDemoCPs } = yield select((state) => ({
        hasNoRealCPs: state.app.hasNoRealCPs,
        hasNoDemoCPs: state.app.hasNoDemoCPs,
      }));
      if (hasNoDemoCPs && hasNoRealCPs) {
        yield put(setAppState("isCheckingDemoProfile", false));
      } else {
        yield put({
          type: types.LOAD_CORPORATE_PARTNER_PREFERENCE,
          payload: {
            isDemoMode: !action.payload.isDemoMode,
          },
        });
      }
    }
  } catch (e) {}
}
