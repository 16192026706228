import gql from "graphql-tag";

export const UserAddressBookQuery = gql`
  query UserAddressBookQuery(
    $orderBy: String
    $orderDirection: String
    $paging: InputPaging
    $searchText: String
  ) {
    getUserAddressBook(
      orderBy: $orderBy
      orderDirection: $orderDirection
      paging: $paging
      searchText: $searchText
    ) {
      addresses {
        id
        address {
          companyName
          addressLine1
          addressLine2
          city
          postalCode
          stateProvince
          reference
        }
        addressFlags {
          isBilling
          isConsignee
          isDelivery
          isPickup
          isShipper
          isNotify
        }
        contact {
          contactName
          email
          phone
        }
      }
      page {
        totalCount
      }
    }
  }
`;

export const GetAddressBookAddressesQuery = gql`
  query GetAddressBookAddressesQuery(
    $terms: String
    $country_code: String
    $limit_customer: Int
    $limit_company: Int
    $party_type: String
    $corporate_partner_uuid: String
  ) {
    getAddressBookAddresses(
      terms: $terms
      country_code: $country_code
      limit_customer: $limit_customer
      limit_company: $limit_company
      party_type: $party_type
      corporate_partner_uuid: $corporate_partner_uuid
    ) {
      customer {
        id
        corporate_partner_id
        company_name
        address
        address_line1
        address_line2
        postal_code
        city
        state_province
        country_code
        ref_customer
        account_number
        created_at
        created_by
        modified_at
        modified_by
        contacts {
          contact_name
          email
          phone
          is_shipper
          is_consignee
          is_pickup
          is_delivery
          is_billing
          is_notify
        }
        account_numbers
      }
      company {
        id
        corporate_partner_id
        company_name
        address
        address_line1
        address_line2
        postal_code
        city
        state_province
        country_code
        ref_customer
        account_number
        created_at
        created_by
        modified_at
        modified_by
        contacts {
          contact_name
          email
          phone
          is_shipper
          is_consignee
          is_pickup
          is_delivery
          is_billing
          is_notify
        }
        account_numbers
      }
    }
  }
`;

export const addNewAddressToUsersAddressBookMutation = gql`
  mutation AddNewAddressToUsersAddressBook($newAddress: UserAddressBookInput) {
    addNewAddressToUsersAddressBook(newAddress: $newAddress)
  }
`;

export const editAddressInUsersAddressBookMutation = gql`
  mutation EditAddressToUsersAddressBookMutation(
    $newAddress: UserAddressBookInput
  ) {
    editAddressInUsersAddressBook(newAddress: $newAddress)
  }
`;

export const deleteAddressFromUsersAddressBookMutation = gql`
  mutation DeleteAddressFromUsersAddressBookMutation($addressId: Int) {
    deleteAddressFromUsersAddressBook(addressId: $addressId)
  }
`;

export const addNewAddressContactMutation = gql`
  mutation AddNewAddressContact(
    $newContact: UserAddressBookAddressContactInput
  ) {
    addNewAddressContact(newContact: $newContact) {
      contact_id
      modified_at
      address_id
    }
  }
`;

export const editAddressContactMutation = gql`
  mutation EditAddressContact(
    $contact: UserAddressBookAddressContactToEditInput
  ) {
    editAddressContact(contact: $contact) {
      contact_id
      modified_at
      address_id
    }
  }
`;

export const deleteAddressContactMutation = gql`
  mutation DeleteAddressContactMutation($contactId: Int) {
    deleteAddressContact(contactId: $contactId)
  }
`;

export const createNewAddressBookCustomerAddressMutation = gql`
  mutation CreateNewAddressBookCustomerAddress(
    $newAddress: AddressBookCustomerAddressInput
  ) {
    createNewAddressBookCustomerAddress(newAddress: $newAddress) {
      address_id
      modified_at
    }
  }
`;

export const editAddressBookCustomerAddressMutation = gql`
  mutation EditAddressBookCustomerAddress(
    $address: AddressBookCustomerAddressToEditInput
  ) {
    editAddressBookCustomerAddress(address: $address) {
      address_id
      modified_at
    }
  }
`;

export const editAddressBookCustomerAddressVisibilityMutation = gql`
  mutation EditAddressBookCustomerAddressVisibility(
    $addressBookId: String
    $visibility: Boolean
  ) {
    editAddressBookCustomerAddressVisibility(
      addressBookId: $addressBookId
      visibility: $visibility
    )
  }
`;

export const deleteAddressBookCustomerAddressMutation = gql`
  mutation DeleteAddressBookCustomerAddress($addressId: Int) {
    deleteAddressBookCustomerAddress(addressId: $addressId)
  }
`;

export const GetAddressBookCustomerAddressQuery = gql`
  query GetAddressBookCustomerAddressQuery($addressId: Int) {
    getAddressBookCustomerAddress(addressId: $addressId) {
      address_id
      address {
        id
        corporate_partner_uuid
        company_name
        address_line1
        address_line2
        city
        postal_code
        state_province
        country_code
        ref_customer
        latitude
        longitude
        google_place_id
        is_customer_visible
        is_validated
      }
      contacts {
        contact_id
        address_id
        contact {
          contact_name
          email
          phone
          is_shipper
          is_consignee
          is_pickup
          is_delivery
          is_billing
          is_notify
        }
        audit {
          created_at
          modified_at
          created_by_email
          modified_by_email
        }
      }
      audit {
        created_at
        modified_at
        created_by_email
        modified_by_email
      }
    }
  }
`;
