import React from "react";
import { Field } from "react-final-form";
import { Sizes, ValidationVariants } from "react-lib/es/atoms/input/constants";
import * as C from "./InputFieldWithValidation.constants";
import * as S from "./InputFieldWithValidation.styles";

const InputFieldWithValidation: React.FC<C.FieldWithValidationProps> = ({
  fieldType = C.InputFieldType.INPUT,
  size = Sizes.LARGE,
  name,
  label,
  className,
  isDisabled = false,
  isErrorVisibilityEnforced = false,
  inputProps = {},
  initialValue,
  onChangeCallback,
}) => {
  return (
    <Field
      name={name}
      // not removing field name from state, when its value become empty
      parse={(value) => value}
      initialValue={initialValue}
    >
      {({ input, meta }) => {
        const isErrorVisible =
          (isErrorVisibilityEnforced || meta.touched) && !!meta.error;

        return (
          <S.StyledInput
            label={label}
            isDisabled={isDisabled}
            size={size}
            hasError={isErrorVisible}
            className={className}
            inputProps={{
              ...input,
              ...(fieldType === C.InputFieldType.TEXTAREA
                ? { as: "textarea", rows: 1 }
                : {}),
              ...inputProps,
              onBlur: () => {
                input.onChange(input.value?.trim?.());
                input.onBlur();
                inputProps.onBlur?.();
              },
              onChange: (value: string) => {
                input.onChange(value);
                onChangeCallback && onChangeCallback();
              },
            }}
            hint={isErrorVisible ? meta.error : undefined}
            validationVariant={
              isErrorVisible ? ValidationVariants.INVALID : undefined
            }
          />
        );
      }}
    </Field>
  );
};

export default InputFieldWithValidation;
