const baseType = "ERROR";

const types = {
  ADD_ERROR: `${baseType}/ADD_ERROR`,
  REMOVE_ERROR: `${baseType}/REMOVE_ERROR`,
  REMOVE_ALL_ERROR: `${baseType}/REMOVE_ALL_ERROR`,
  HIDE_ALL_ERROR: `${baseType}/HIDE_ALL_ERROR`,
};

export default types;
