import { FieldInputProps } from "react-final-form";
import { Sizes } from "react-lib/es/atoms/input/constants";

export enum InputFieldType {
  INPUT = "input",
  TEXTAREA = "textarea",
}

export interface FieldWithValidationProps {
  fieldType?: InputFieldType;
  size?: Sizes;
  name: string;
  label?: string;
  isDisabled?: boolean;
  className?: string;
  inputProps?: Partial<FieldInputProps<any, HTMLElement>>;
  isErrorVisibilityEnforced?: boolean;
  initialValue?: string;
  onChangeCallback?: () => void;
}
