import apiKeys from "constants/apiKeys";
import { MapProviders } from "components/organisms/DashboardMap/constants";

export enum FeatureFlagName {
  BOOK_STANDALONE = "BOOK",
  PO_SKU = "PO_SKU",
  NPS = "NPS_MODAL",
  ADDRESS_BOOK = "ADDRESS_BOOK",
  CUSTOMER_SUPPORT_CENTER = "CUSTOMER_SUPPORT_CENTER",
  CONTRACTED_RATES = "CONTRACTED_RATES",
  NEW_CARGO = "NEW_CARGO",
  SHIPMENTS_CDZ_TAGS = "SHIPMENTS_CDZ_TAGS",
  SHIPMENT_ORDERS = "SHIPMENT_ORDERS",
}

export enum MapsProviders {
  GOOGLE = "GOOGLE",
  HERE = "HERE",
}

interface FrontendConfig extends Partial<Record<FeatureFlagName, boolean>> {
  BUILD_ENVIRONMENT: string;
  C2_PORTAL_URL: string;
  CONTACT_URL: string;
  CONTACT_US_URL_UNREGISTERED: string;
  DEBUG_MESSAGE: boolean;
  DEFAULT_ADDRESS_SEARCH_PROVIDER: string; // Enum
  DEFAULT_MAP_PROVIDER: string; // Enum
  DHLI_PORTAL_URL: string;
  ENABLE_FREIGHT_OQT: boolean;
  ENABLE_I18N: boolean;
  FEATURE_UPLOAD_SHIPMENT_DOCUMENTS: boolean;
  FEATURE_REPORTING_FILTER_DISJUNCTION: boolean;
  FEATURE_CDZ: boolean;
  FEEDBACK_URL: string;
  GRAPHQL_ENDPOINT: string;
  KEYCLOAK_CLIENT_ID: string;
  KEYCLOAK_REDIRECT_URI: string;
  KEYCLOAK_RESET_PW_URL: string;
  KEYCLOAK_URL: string;
  LANGUAGE_TEST_MODE: boolean;
  LIMIT_EXPORT_SHIPMENTS: number;
  NODE_ENDPOINT: string;
  SHIPMENT_SHARE_URL: string;
  TIME_UNTIL_REPORTING_BADGE_MUST_BE_SHOWN: number;
  TRACK_PORTAL_URL: string;
  [FeatureFlagName.BOOK_STANDALONE]: boolean;
  [FeatureFlagName.PO_SKU]: boolean;
  [FeatureFlagName.NPS]: boolean;
  [FeatureFlagName.ADDRESS_BOOK]: boolean;
  [FeatureFlagName.CUSTOMER_SUPPORT_CENTER]: boolean;
  [FeatureFlagName.NEW_CARGO]: boolean;
  [FeatureFlagName.SHIPMENTS_CDZ_TAGS]: boolean;
  [FeatureFlagName.SHIPMENT_ORDERS]: boolean;
}

const TIME_UNTIL_REPORTING_BADGE_MUST_BE_SHOWN = 1000 * 60 * 60 * 24 * 7 * 4; // 4 weeks = 28 days, in milliseconds

const DevValues: FrontendConfig = {
  GRAPHQL_ENDPOINT: "/gql",
  NODE_ENDPOINT: "/api/",
  KEYCLOAK_URL:
    "https://keycloak-dev.dhl.com/auth/realms/DCI-DEV/protocol/openid-connect/",
  KEYCLOAK_RESET_PW_URL:
    "https://keycloak-dev.dhl.com/auth/realms/DCI-DEV/login-actions/reset-credentials/?client_id=myDHLi",
  KEYCLOAK_REDIRECT_URI: `${window.location.origin}/login`,
  KEYCLOAK_CLIENT_ID: "myDHLi",
  BUILD_ENVIRONMENT: "development",
  DHLI_PORTAL_URL: "https://dhlit-qa.dhl.com/",
  C2_PORTAL_URL: "https://uscustomsbrokerageqa.dhl.com",
  LIMIT_EXPORT_SHIPMENTS: 2000,
  TRACK_PORTAL_URL: "https://sview-dev.dhl.com/",
  ENABLE_I18N: true,
  DEBUG_MESSAGE: true,
  SHIPMENT_SHARE_URL:
    "https://www.mydhli.com/global-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=",
  CONTACT_URL:
    "https://www.mydhli.com/global-en/home/contact-us/registered-mydhli-customers.html",
  CONTACT_US_URL_UNREGISTERED:
    "https://www.mydhli.com/global-en/home/contact-us.html",
  LANGUAGE_TEST_MODE: true,
  ENABLE_FREIGHT_OQT: true, // keep in sync with BE config
  DEFAULT_MAP_PROVIDER: MapProviders.GOOGLE,
  DEFAULT_ADDRESS_SEARCH_PROVIDER: MapProviders.GOOGLE,
  TIME_UNTIL_REPORTING_BADGE_MUST_BE_SHOWN,
  FEEDBACK_URL: "https://surveys.dpdhl.com/nkbhsojyca",
  FEATURE_UPLOAD_SHIPMENT_DOCUMENTS: true,
  FEATURE_REPORTING_FILTER_DISJUNCTION: true,
  FEATURE_CDZ: true,
  [FeatureFlagName.BOOK_STANDALONE]: true,
  [FeatureFlagName.PO_SKU]: true,
  [FeatureFlagName.NPS]: true,
  [FeatureFlagName.ADDRESS_BOOK]: true,
  [FeatureFlagName.CUSTOMER_SUPPORT_CENTER]: true,
  [FeatureFlagName.NEW_CARGO]: true,
  [FeatureFlagName.SHIPMENTS_CDZ_TAGS]: true,
  [FeatureFlagName.SHIPMENT_ORDERS]: true,
};

const TestValues: FrontendConfig = {
  GRAPHQL_ENDPOINT: "/gql",
  NODE_ENDPOINT: "/api/",
  KEYCLOAK_URL:
    "https://keycloak-test.dhl.com/auth/realms/DCI-TST/protocol/openid-connect/",
  KEYCLOAK_RESET_PW_URL:
    "https://keycloak-test.dhl.com/auth/realms/DCI-TST/login-actions/reset-credentials/?client_id=myDHLi",
  KEYCLOAK_REDIRECT_URI: `${window.location.origin}/login`,
  KEYCLOAK_CLIENT_ID: "myDHLi",
  BUILD_ENVIRONMENT: "test",
  DHLI_PORTAL_URL: "https://dhlit-qa.dhl.com/",
  C2_PORTAL_URL: "https://uscustomsbrokerageqa.dhl.com",
  LIMIT_EXPORT_SHIPMENTS: 2000,
  TRACK_PORTAL_URL: "https://sview-tst.dhl.com/",
  ENABLE_I18N: true,
  DEBUG_MESSAGE: true,
  SHIPMENT_SHARE_URL:
    "https://www.mydhli.com/global-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=",
  CONTACT_URL:
    "https://www.mydhli.com/global-en/home/contact-us/registered-mydhli-customers.html",
  CONTACT_US_URL_UNREGISTERED:
    "https://www.mydhli.com/global-en/home/contact-us.html",
  LANGUAGE_TEST_MODE: false,
  ENABLE_FREIGHT_OQT: true, // keep in sync with BE config
  DEFAULT_MAP_PROVIDER: MapProviders.HERE,
  DEFAULT_ADDRESS_SEARCH_PROVIDER: MapProviders.GOOGLE,
  TIME_UNTIL_REPORTING_BADGE_MUST_BE_SHOWN,
  FEEDBACK_URL: "https://surveys.dpdhl.com/nkbhsojyca",
  FEATURE_UPLOAD_SHIPMENT_DOCUMENTS: true,
  FEATURE_REPORTING_FILTER_DISJUNCTION: true,
  FEATURE_CDZ: true,
  [FeatureFlagName.BOOK_STANDALONE]: true,
  [FeatureFlagName.PO_SKU]: true,
  [FeatureFlagName.NPS]: true,
  [FeatureFlagName.ADDRESS_BOOK]: true,
  [FeatureFlagName.CUSTOMER_SUPPORT_CENTER]: true,
  [FeatureFlagName.NEW_CARGO]: false,
  [FeatureFlagName.SHIPMENTS_CDZ_TAGS]: true,
  [FeatureFlagName.SHIPMENT_ORDERS]: true,
};

const UatValues: FrontendConfig = {
  GRAPHQL_ENDPOINT: "/gql",
  NODE_ENDPOINT: "/api/",
  KEYCLOAK_URL:
    "https://keycloak-uat.dhl.com/auth/realms/DCI-UAT/protocol/openid-connect/",
  KEYCLOAK_RESET_PW_URL:
    "https://keycloak-uat.dhl.com/auth/realms/DCI-UAT/login-actions/reset-credentials/?client_id=myDHLi",
  KEYCLOAK_REDIRECT_URI: `${window.location.origin}/login`,
  KEYCLOAK_CLIENT_ID: "myDHLi",
  BUILD_ENVIRONMENT: "uat",
  DHLI_PORTAL_URL: "https://dhlit-qa.dhl.com/",
  C2_PORTAL_URL: "https://uscustomsbrokerageqa.dhl.com",
  LIMIT_EXPORT_SHIPMENTS: 2000,
  TRACK_PORTAL_URL: "https://sview-uat.dhl.com/",
  ENABLE_I18N: true,
  DEBUG_MESSAGE: false,
  SHIPMENT_SHARE_URL:
    "https://www.mydhli.com/global-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=",
  CONTACT_URL:
    "https://www.mydhli.com/global-en/home/contact-us/registered-mydhli-customers.html",
  CONTACT_US_URL_UNREGISTERED:
    "https://www.mydhli.com/global-en/home/contact-us.html",
  LANGUAGE_TEST_MODE: false,
  ENABLE_FREIGHT_OQT: true, // keep in sync with BE config
  DEFAULT_MAP_PROVIDER: MapProviders.HERE,
  DEFAULT_ADDRESS_SEARCH_PROVIDER: MapProviders.GOOGLE,
  TIME_UNTIL_REPORTING_BADGE_MUST_BE_SHOWN,
  FEEDBACK_URL: "https://surveys.dpdhl.com/nkbhsojyca",
  FEATURE_UPLOAD_SHIPMENT_DOCUMENTS: true,
  FEATURE_REPORTING_FILTER_DISJUNCTION: true,
  FEATURE_CDZ: true,
  [FeatureFlagName.BOOK_STANDALONE]: false,
  [FeatureFlagName.PO_SKU]: true,
  [FeatureFlagName.NPS]: true,
  [FeatureFlagName.ADDRESS_BOOK]: true,
  [FeatureFlagName.NEW_CARGO]: false,
  [FeatureFlagName.CUSTOMER_SUPPORT_CENTER]: true,
  [FeatureFlagName.SHIPMENTS_CDZ_TAGS]: false,
  [FeatureFlagName.SHIPMENT_ORDERS]: false,
};

const ProdValues: FrontendConfig = {
  GRAPHQL_ENDPOINT: "/gql",
  NODE_ENDPOINT: "/api/",
  KEYCLOAK_URL:
    "https://keycloak.mydhli.com/auth/realms/DCI/protocol/openid-connect/",
  KEYCLOAK_RESET_PW_URL:
    "https://keycloak.mydhli.com/auth/realms/DCI/login-actions/reset-credentials/?client_id=myDHLi",
  KEYCLOAK_REDIRECT_URI: `${window.location.origin}/login`,
  KEYCLOAK_CLIENT_ID: "myDHLi",
  BUILD_ENVIRONMENT: "production",
  DHLI_PORTAL_URL: "https://dhli.dhl.com/",
  C2_PORTAL_URL: "https://uscustomsbrokerage.dhl.com",
  LIMIT_EXPORT_SHIPMENTS: 2000,
  TRACK_PORTAL_URL: "https://track.mydhli.com/",
  ENABLE_I18N: true,
  DEBUG_MESSAGE: false,
  SHIPMENT_SHARE_URL:
    "https://www.mydhli.com/global-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=",
  CONTACT_URL:
    "https://www.mydhli.com/global-en/home/contact-us/registered-mydhli-customers.html",
  CONTACT_US_URL_UNREGISTERED:
    "https://www.mydhli.com/global-en/home/contact-us.html",
  LANGUAGE_TEST_MODE: false,
  ENABLE_FREIGHT_OQT: true, // keep in sync with BE config
  DEFAULT_MAP_PROVIDER: MapProviders.HERE,
  DEFAULT_ADDRESS_SEARCH_PROVIDER: MapProviders.GOOGLE,
  TIME_UNTIL_REPORTING_BADGE_MUST_BE_SHOWN,
  FEEDBACK_URL: "https://surveys.dpdhl.com/DGF-NPA",
  FEATURE_UPLOAD_SHIPMENT_DOCUMENTS: true,
  FEATURE_REPORTING_FILTER_DISJUNCTION: true,
  FEATURE_CDZ: true,
  [FeatureFlagName.BOOK_STANDALONE]: false,
  [FeatureFlagName.PO_SKU]: true,
  [FeatureFlagName.NPS]: true,
  [FeatureFlagName.ADDRESS_BOOK]: true,
  [FeatureFlagName.NEW_CARGO]: false,
  [FeatureFlagName.CUSTOMER_SUPPORT_CENTER]: false,
  [FeatureFlagName.SHIPMENTS_CDZ_TAGS]: false,
  [FeatureFlagName.SHIPMENT_ORDERS]: false,
};

const PreProdValues: FrontendConfig = {
  GRAPHQL_ENDPOINT: "/gql",
  NODE_ENDPOINT: "/api/",
  KEYCLOAK_URL:
    "https://keycloak-ppa.mydhli.com/auth/realms/DCI-PREPROD/protocol/openid-connect/",
  KEYCLOAK_RESET_PW_URL:
    "https://keycloak-ppa.mydhli.com/auth/realms/DCI-PREPROD/login-actions/reset-credentials/?client_id=myDHLi",
  KEYCLOAK_REDIRECT_URI: `${window.location.origin}/login`,
  KEYCLOAK_CLIENT_ID: "myDHLi",
  BUILD_ENVIRONMENT: "preprod",
  DHLI_PORTAL_URL: "https://dhlit-uat.dhl.com/",
  C2_PORTAL_URL: "https://uscustomsbrokerageqa.dhl.com",
  LIMIT_EXPORT_SHIPMENTS: 2000,
  TRACK_PORTAL_URL: "https://track.mydhli.com/",
  ENABLE_I18N: true,
  DEBUG_MESSAGE: false,
  SHIPMENT_SHARE_URL:
    "https://www.mydhli.com/global-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=",
  CONTACT_URL:
    "https://www.mydhli.com/global-en/home/contact-us/registered-mydhli-customers.html",
  CONTACT_US_URL_UNREGISTERED:
    "https://www.mydhli.com/global-en/home/contact-us.html",
  LANGUAGE_TEST_MODE: false,
  ENABLE_FREIGHT_OQT: true, // keep in sync with BE config
  DEFAULT_MAP_PROVIDER: MapProviders.HERE,
  DEFAULT_ADDRESS_SEARCH_PROVIDER: MapProviders.GOOGLE,
  TIME_UNTIL_REPORTING_BADGE_MUST_BE_SHOWN,
  FEEDBACK_URL: "https://surveys.dpdhl.com/nkbhsojyca",
  FEATURE_UPLOAD_SHIPMENT_DOCUMENTS: true,
  FEATURE_REPORTING_FILTER_DISJUNCTION: true,
  FEATURE_CDZ: true,
  [FeatureFlagName.BOOK_STANDALONE]: false,
  [FeatureFlagName.PO_SKU]: true,
  [FeatureFlagName.NPS]: true,
  [FeatureFlagName.ADDRESS_BOOK]: true,
  [FeatureFlagName.NEW_CARGO]: false,
  [FeatureFlagName.CUSTOMER_SUPPORT_CENTER]: false,
  [FeatureFlagName.SHIPMENTS_CDZ_TAGS]: false,
  [FeatureFlagName.SHIPMENT_ORDERS]: false,
};

const localBEEnv: FrontendConfig = TestValues;

const LocalValues: FrontendConfig = {
  GRAPHQL_ENDPOINT: "http://localhost:8080/gql",
  NODE_ENDPOINT: "http://localhost:8080/api/",
  KEYCLOAK_URL: localBEEnv.KEYCLOAK_URL,
  KEYCLOAK_RESET_PW_URL: localBEEnv.KEYCLOAK_RESET_PW_URL,
  KEYCLOAK_REDIRECT_URI: `${window.location.origin}/login`,
  KEYCLOAK_CLIENT_ID: "myDHLi",
  BUILD_ENVIRONMENT: "local",
  DHLI_PORTAL_URL: "https://dhlit-qa.dhl.com/",
  C2_PORTAL_URL: "https://uscustomsbrokerageqa.dhl.com",
  LIMIT_EXPORT_SHIPMENTS: 2000,
  TRACK_PORTAL_URL: localBEEnv.TRACK_PORTAL_URL,
  ENABLE_I18N: true,
  DEBUG_MESSAGE: true,
  SHIPMENT_SHARE_URL:
    "https://www.mydhli.com/global-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=",
  CONTACT_URL:
    "https://www.mydhli.com/global-en/home/contact-us/registered-mydhli-customers.html",
  CONTACT_US_URL_UNREGISTERED:
    "https://www.mydhli.com/global-en/home/contact-us.html",
  LANGUAGE_TEST_MODE: false,
  ENABLE_FREIGHT_OQT: true, // keep in sync with BE config
  DEFAULT_MAP_PROVIDER: MapProviders.HERE,
  DEFAULT_ADDRESS_SEARCH_PROVIDER: MapProviders.GOOGLE,
  TIME_UNTIL_REPORTING_BADGE_MUST_BE_SHOWN,
  FEEDBACK_URL: "https://surveys.dpdhl.com/nkbhsojyca",
  FEATURE_UPLOAD_SHIPMENT_DOCUMENTS: true,
  FEATURE_REPORTING_FILTER_DISJUNCTION: true,
  FEATURE_CDZ: true,
  [FeatureFlagName.BOOK_STANDALONE]: true,
  [FeatureFlagName.PO_SKU]: true,
  [FeatureFlagName.NPS]: true,
  [FeatureFlagName.ADDRESS_BOOK]: true,
  [FeatureFlagName.CUSTOMER_SUPPORT_CENTER]: true,
  [FeatureFlagName.NEW_CARGO]: true,
  [FeatureFlagName.SHIPMENTS_CDZ_TAGS]: true,
  [FeatureFlagName.SHIPMENT_ORDERS]: true,
};

const LocalBuildValues: FrontendConfig = {
  GRAPHQL_ENDPOINT: "/gql",
  NODE_ENDPOINT: "http://localhost:8080/api/",
  KEYCLOAK_URL: localBEEnv.KEYCLOAK_URL,
  KEYCLOAK_RESET_PW_URL: localBEEnv.KEYCLOAK_RESET_PW_URL,
  KEYCLOAK_REDIRECT_URI: `${window.location.origin}/login`,
  KEYCLOAK_CLIENT_ID: "myDHLi",
  BUILD_ENVIRONMENT: "localbuild",
  DHLI_PORTAL_URL: "https://dhlit-qa.dhl.com/",
  C2_PORTAL_URL: "https://uscustomsbrokerageqa.dhl.com",
  LIMIT_EXPORT_SHIPMENTS: 2000,
  TRACK_PORTAL_URL: localBEEnv.TRACK_PORTAL_URL,
  ENABLE_I18N: true,
  DEBUG_MESSAGE: true,
  SHIPMENT_SHARE_URL:
    "https://www.mydhli.com/global-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=",
  CONTACT_URL:
    "https://www.mydhli.com/global-en/home/contact-us/registered-mydhli-customers.html",
  CONTACT_US_URL_UNREGISTERED:
    "https://www.mydhli.com/global-en/home/contact-us.html",
  LANGUAGE_TEST_MODE: false,
  ENABLE_FREIGHT_OQT: true, // keep in sync with BE config
  DEFAULT_MAP_PROVIDER: MapProviders.HERE,
  DEFAULT_ADDRESS_SEARCH_PROVIDER: MapProviders.GOOGLE,
  TIME_UNTIL_REPORTING_BADGE_MUST_BE_SHOWN,
  FEEDBACK_URL: "https://surveys.dpdhl.com/nkbhsojyca",
  FEATURE_UPLOAD_SHIPMENT_DOCUMENTS: true,
  FEATURE_REPORTING_FILTER_DISJUNCTION: true,
  FEATURE_CDZ: true,
  [FeatureFlagName.BOOK_STANDALONE]: true,
  [FeatureFlagName.PO_SKU]: true,
  [FeatureFlagName.NPS]: true,
  [FeatureFlagName.ADDRESS_BOOK]: true,
  [FeatureFlagName.CUSTOMER_SUPPORT_CENTER]: true,
  [FeatureFlagName.NEW_CARGO]: true,
  [FeatureFlagName.SHIPMENTS_CDZ_TAGS]: true,
  [FeatureFlagName.SHIPMENT_ORDERS]: true,
};

const getConfigClient = <K extends keyof FrontendConfig>(
  key: K
): FrontendConfig[K] => {
  switch (apiKeys.environment) {
    case "local": {
      return LocalValues[key];
    }
    case "localBuild": {
      return LocalBuildValues[key];
    }
    case "dev": {
      return DevValues[key];
    }
    case "test": {
      return TestValues[key];
    }
    case "uat": {
      return UatValues[key];
    }
    case "prod": {
      return ProdValues[key];
    }
    case "preprod": {
      return PreProdValues[key];
    }
    default: {
      return DevValues[key];
    }
  }
};

export default getConfigClient;
