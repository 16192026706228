import PropTypes from "prop-types";
import styled from "styled-components";
import { getRem } from "../../../../utils/css";

const LINK_HEIGHT_PX = 65;
const INDICATOR_HEIGHT_PX = 34;

const SideBarActiveIndicator = styled.div`
  position: absolute;
  display: ${(props) => (props.currentItemIndex < 0 ? "none" : "block")};
  width: ${getRem(2)};
  height: ${getRem(INDICATOR_HEIGHT_PX)};
  left: ${getRem(6)};
  transform: translateY(
    ${(props) =>
      getRem(
        LINK_HEIGHT_PX * props.currentItemIndex +
          (LINK_HEIGHT_PX - INDICATOR_HEIGHT_PX) / 2
      )}
  );
  background: ${(props) => props.theme.colors.dhlRed};
  transition: transform 200ms;
`;

SideBarActiveIndicator.defaultProps = {
  currentItemIndex: 0,
};

SideBarActiveIndicator.propTypes = {
  currentItemIndex: PropTypes.number,
};

export default SideBarActiveIndicator;
