import { BillingStageSections } from "routes/MakeABooking/BookingBilling/BookingBilling.constants";
import { CargoStageSections } from "routes/MakeABooking/BookingCargo/BookingCargo.constants";
import { FinalizeStageSections } from "routes/MakeABooking/BookingFinalize/BookingFinalize.constants";
import { RouteStageSections } from "routes/MakeABooking/BookingRoute/BookingRoute.constants";
import { ServiceStageSections } from "routes/MakeABooking/BookingService/BookingService.constants";
import { MakeABookingStage } from "routes/MakeABooking/MakeABooking.constants";

export type MakeABookingSectionsKeys =
  | ServiceStageSections
  | RouteStageSections
  | BillingStageSections
  | CargoStageSections
  | FinalizeStageSections;

export interface StageSectionInfo {
  key: MakeABookingSectionsKeys;
  label: string;
  id?: string;
}

export type StagesSections = Record<MakeABookingStage, StageSectionInfo[]>;

export interface MakeABookingState {
  stagesValidationStatus: Partial<Record<MakeABookingStage, boolean>>;
  invalidStagesSections: Partial<StagesSections>;
  stagesVisitedStatus: Partial<Record<MakeABookingStage, boolean>>;
  areFormsValidationErrorsVisible: boolean;
  currentStep?: MakeABookingStage;
  isTermsAndConditionsAccepted: boolean;
}

export const initialState: MakeABookingState = {
  stagesValidationStatus: {},
  invalidStagesSections: {},
  areFormsValidationErrorsVisible: false,
  stagesVisitedStatus: {},
  isTermsAndConditionsAccepted: false,
};

export enum MakeABookingActionType {
  SET_STAGE_VALIDATION_STATUS = "MAKE_A_BOOKING/SET_STAGE_VALIDATION_STATUS",
  SET_STAGE_VISITED_STATUS = "MAKE_A_BOOKING/SET_STAGE_VISITED_STATUS",
  SET_STAGE_INVALID_SECTIONS = "MAKE_A_BOOKING/SET_STAGE_INVALID_SECTIONS",
  ENABLE_FORMS_ERRORS_VISIBILITY = "MAKE_A_BOOKING/ENABLE_FORMS_ERRORS_VISIBILITY",
  CLEAR_STATE = "MAKE_A_BOOKING/CLEAR_STATE",
  REINITIALIZE_MAKE_A_BOOKING_STATE = "MAKE_A_BOOKING/REINITIALIZE_MAKE_A_BOOKING_STATE",
  SET_CURRENT_STEP = "MAKE_A_BOOKING/SET_CURRENT_STEP",
  SET_TERMS_AND_CONDITIONS_STATE = "MAKE_A_BOOKING/SET_TERMS_AND_CONDITIONS_STATE",
}

export type MakeABookingAction =
  | {
      type: MakeABookingActionType.SET_STAGE_VALIDATION_STATUS;
      pageName: MakeABookingStage;
      isValid: boolean;
    }
  | {
      type: MakeABookingActionType.SET_STAGE_VISITED_STATUS;
      pageName: MakeABookingStage;
      isVisited: boolean;
    }
  | {
      type: MakeABookingActionType.SET_STAGE_INVALID_SECTIONS;
      pageName: MakeABookingStage;
      invalidSections: StageSectionInfo[];
    }
  | {
      type: MakeABookingActionType.ENABLE_FORMS_ERRORS_VISIBILITY;
    }
  | {
      type: MakeABookingActionType.REINITIALIZE_MAKE_A_BOOKING_STATE;
      newState: Partial<MakeABookingState>;
    }
  | {
      type: MakeABookingActionType.CLEAR_STATE;
    }
  | {
      type: MakeABookingActionType.SET_CURRENT_STEP;
      currentStep?: MakeABookingStage;
    }
  | {
      type: MakeABookingActionType.SET_TERMS_AND_CONDITIONS_STATE;
      isAccepted: boolean;
    };
