import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import omitDeep from "omit-deep-lodash";
import getServiceLanguages from "queries/AAA/getServiceLanguages";
import { GET_USER_DETAILS, UPDATE_USER_DETAILS } from "queries/AAA/userQueries";
import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { langCodeToMomentCode } from "utils/intl";
import { allLanguages } from "utils/languages";
import { defaultLanguage } from "./Translations.constants";
import {
  getCodeSecure,
  isTestMode,
  uniformLanguageList,
} from "./Translations.helpers";

export const useSynchroniseTimeFormatWithLanguage = (isLoading, language) => {
  useLayoutEffect(() => {
    if (!isLoading) {
      moment.locale(
        langCodeToMomentCode([language, defaultLanguage.toUpperCase()])
      );
    }
  }, [isLoading, language]);
};

const useGetUserDetails = (skip) => {
  const { data: userData } = useQuery(GET_USER_DETAILS, {
    skip,
    fetchPolicy: "network-only",
  });
  return userData?.user;
};

export const useGetUserLanguage = (isLoggedIn) => {
  const user = useGetUserDetails(!isLoggedIn);

  const userLanguage = useMemo(
    () => getCodeSecure(allLanguages, user?.languageCode),
    [user?.languageCode]
  );

  return isLoggedIn && userLanguage ? userLanguage : null;
};

export const useUpdateUserLanguage = (isLoggedIn) => {
  const [updateUserDetails, { loading: isUpdatingUserLanguage }] =
    useMutation(UPDATE_USER_DETAILS);
  const user = useGetUserDetails(isLoggedIn);

  const updateUserLanguageMutation = useCallback(
    (languageCode) => {
      if (user) {
        const copy = omitDeep(user, "__typename");
        updateUserDetails({
          variables: {
            user: {
              ...copy,
              languageCode,
              nameInitials: undefined,
              latestTCAccepted: undefined,
              companyName: undefined,
              company: copy.companyName,
              roleInCompany: undefined,
              companyRole: copy.roleInCompany,
              phoneNumber: undefined,
              phone: copy.phoneNumber,
              getEmailNotifications: undefined,
              toNotify: copy.getEmailNotifications,
              address: {
                ...copy?.address,
                postalCode: undefined,
                postcode: copy?.postalCode || null,
              },
            },
          },
          optimisticResponse: { ...user, languageCode },
        });
      }
    },
    [user, updateUserDetails]
  );

  return [updateUserLanguageMutation, isUpdatingUserLanguage];
};

const useAppLanguages = (isLoggedIn) => {
  const {
    data,
    loading: isLoadingLanguages,
    refetch,
  } = useQuery(getServiceLanguages);

  useEffect(() => {
    if (!data?.qnb || !data?.platform) {
      refetch();
    }
  }, [data?.platform, data?.qnb, isLoadingLanguages, isLoggedIn, refetch]);

  const platformLanguages = useMemo(
    () => uniformLanguageList(data?.platform),
    [data?.platform]
  );

  const quoteAndBookLanguages = useMemo(
    () => uniformLanguageList(data?.qnb),
    [data?.qnb]
  );

  return {
    platformLanguages,
    quoteAndBookLanguages,
    isLoadingLanguages,
  };
};

export const useAvailableLanguages = (isLoggedIn) => {
  const { platformLanguages, quoteAndBookLanguages, isLoadingLanguages } =
    useAppLanguages(isLoggedIn);

  if (isTestMode) {
    return {
      languages: allLanguages,
      isLoadingLanguages: false,
    };
  }

  return {
    languages: isLoggedIn ? platformLanguages : quoteAndBookLanguages,
    isLoadingLanguages,
  };
};
