import { FeatureFlagName } from "constants/getConfigClient";
import { FeatureFlag } from "constants/masterDataManagement";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useMDMmyDHLiFeatureFlags } from "./masterDataManagement";

const getFlagState = (
  featureFlag: FeatureFlag,
  userEmail?: string,
  cpUuid?: string
): boolean => {
  const { isEnabled, exceptions } = featureFlag;
  const isExceptionForCurrentAppState =
    exceptions.forCP.find((exceptionCp) => exceptionCp === cpUuid) ||
    exceptions.forEmail.find(
      (exceptionEmail) => exceptionEmail === userEmail
    ) ||
    exceptions.multiConditional.find(
      ({ CP_UUID, USER_EMAIL }) =>
        CP_UUID === cpUuid && USER_EMAIL === userEmail
    );

  // If there is an exception for current enable status we need to negate it
  return isExceptionForCurrentAppState !== undefined ? !isEnabled : isEnabled;
};

const useMyDHLiFeatureFlags = ():
  | Record<FeatureFlagName, boolean>
  | undefined => {
  const { data, loading } = useMDMmyDHLiFeatureFlags();
  const cpUuid: string | undefined = useSelector(
    (state: RootState) => state?.userSettings?.visibilityScope?.uuid
  );
  const userEmail: string | undefined = useSelector(
    (state: RootState) => state?.auth?.loggedInUserEmail
  );

  if (loading) {
    return undefined;
  }

  return data?.getMyDHLiFeatureFlags.reduce(
    (flagsState, featureFlag) => ({
      ...flagsState,
      [featureFlag.name]: getFlagState(featureFlag, userEmail, cpUuid),
    }),
    {} as Record<FeatureFlagName, boolean>
  );
};

export default useMyDHLiFeatureFlags;
