import { createFormValidator } from "helpers/bookWorkflow";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import ModalHeaderYellow from "../../ModalHeaderYellow/ModalHeaderYellow";
import * as C from "./AddressBookForm.constants";
import messages from "./AddressBookForm.messages";
import AddressBookFormFields from "./AddressBookFormFields/AddressBookFormFields";
import { initialFormValues } from "./AddressBookFormFields/AddressBookFormFields.constants";

const AddressBookForm = ({
  onBackArrowClick,
  formHeader,
  onFormSubmit,
  onCancel,
  onDelete,
  isDisabled = false,
  isDeletable = false,
  formInitialValues = initialFormValues,
}: C.AddressBookFormProps) => {
  const intl = useIntl();

  const validate = useMemo(
    () => createFormValidator(C.getAddressBookFormSchema(intl)),
    [intl]
  );

  return (
    <>
      <ModalHeaderYellow
        onBackArrowClick={onBackArrowClick}
        headerText={intl.formatMessage(messages.backToList)}
      />

      <Form
        onSubmit={onFormSubmit}
        initialValues={formInitialValues}
        validate={validate}
      >
        {({ handleSubmit }) => (
          <>
            <AddressBookFormFields
              onDelete={onDelete}
              isDeletable={isDeletable}
              onCancel={onCancel}
              isDisabled={isDisabled}
              handleSubmit={handleSubmit}
              formHeader={formHeader}
            />
          </>
        )}
      </Form>
    </>
  );
};

export default AddressBookForm;
