import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { setAppState } from "redux/app/actions";
import SideBarActiveIndicatorUser from "./SideBarActiveIndicatorUser";
import SideBarItem from "./SideBarItem";
import SideBarUserItemsWrapper from "./SideBarUserItemsWrapper";

const SideBarUserItems = (props) => {
  const dispatch = useDispatch();
  return (
    <SideBarUserItemsWrapper {...props}>
      <SideBarActiveIndicatorUser currentItemIndex={props.currentItemIndex} />
      {props.items.map((item) => (
        <SideBarItem
          isListItem={false}
          userMenu
          {...item}
          key={item.key}
          icon={item.icon}
          onClick={() => {
            dispatch(setAppState("navCollapsed", true));
          }}
          isDemo={props.isDemo}
        />
      ))}
    </SideBarUserItemsWrapper>
  );
};
SideBarUserItems.defaultProps = {
  items: [],
};
SideBarUserItems.propTypes = {
  items: PropTypes.array,
  setHoverIndex: PropTypes.func,
  currentItemIndex: PropTypes.number,
};

export default SideBarUserItems;
