import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { getRem } from "../../../../utils/css";

const SideBarWrapper = styled.div`
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: ${(props) =>
    props.hideNav && !props.belowLg
      ? props.collapsed
        ? getRem(-66)
        : getRem(-202)
      : 0};
  bottom: 0;
  overflow-y: ${(props) => (props.shouldScrollMenu ? "scroll" : "hidden")};
  padding-top: ${(props) => props.paddingTop};
  ${(props) => {
    return props.collapsed && props.hovered
      ? css`
          width: ${(props) => (props.belowLg ? 0 : getRem(223))};
          transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
          //transition-delay: 100ms;
        `
      : props.collapsed && !props.hovered
      ? css`
          width: ${(props) => (props.belowLg ? 0 : getRem(64))};
          transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
          //transition-delay: 100ms;
        `
      : css`
          width: ${getRem(223)};
          transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
        `;
  }};
  z-index: ${(props) => (props.belowLg ? 130 : 100)};
  box-shadow: 0 ${getRem(1)} ${getRem(4)} 0 rgba(50, 50, 50, 0.5);
  background: #fff;
  white-space: nowrap;
`;

SideBarWrapper.propTypes = {
  belowLg: PropTypes.bool,
};

export default SideBarWrapper;
