import { defineMessages } from "react-intl";

export const messages = defineMessages({
  privacyNotice: {
    id: "footer.links.privacyNotice",
    defaultMessage: "Privacy Notice",
  },
  termsOfUse: { id: "footer.links.termsOfUse", defaultMessage: "Terms of Use" },
  legalNotice: {
    id: "footer.links.legalNotice",
    defaultMessage: "Legal Notice",
  },
  cookieSettings: {
    id: "footer.links.cookieSettings",
    defaultMessage: "Cookie Settings",
  },
  contactUs: {
    id: "footer.links.contactUs",
    defaultMessage: "Contact Us",
  },
  feedback: {
    id: "footer.links.feedback",
    defaultMessage: "Feedback",
  },
});
