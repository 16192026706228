import styled from "styled-components";

export const TabOuterWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray20};
  position: relative;
`;
export const TabWrapper = styled.div`
  flex: 0 0 auto;
`;
export const Highlighter = styled.div`
  background: ${(props) => props.theme.colors.dhlRed};
  height: 0.125rem;
  position: absolute;
  bottom: 0;
  left: ${(p) => p.dims.left};
  width: ${(p) => p.dims.width};
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.35, 1) 0.025s;
`;
