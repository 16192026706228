import { colors, media } from "react-lib";
import styled from "styled-components";

export const TopBarWrapper = styled.header`
  position: absolute;
  top: 0;
  z-index: 120;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0.125rem 0.625rem 0.125rem 0.375rem;
  width: 100%;

  background-image: linear-gradient(
    to right,
    ${colors.postYellow} 0%,
    ${colors.postYellow} 30%,
    #ffe57f 79%,
    #fff0b2 100%
  );

  ${media.from("md")} {
    padding-right: 1.5rem;
  }
`;

export const LogoWrapper = styled.div`
  flex-grow: 1;
  padding: 0.75rem 0 0.75rem 0.875rem;
`;
