import { DownloadState } from "constants/DownloadState";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import DownloadProgress, {
  DownloadProgressProps,
} from "./DownloadProgress/DownloadProgress";
import * as H from "./DownloadStatus.helpers";
import messages from "./DownloadStatus.messages";
import * as S from "./DownloadStatus.styles";

interface DownloadStatusProps extends DownloadProgressProps {
  className?: string;
}

const DownloadStatus: React.FC<DownloadStatusProps> = ({
  className,
  downloadState,
  barFillPercentage,
}) => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <S.Wrapper className={className} downloadState={downloadState}>
      <S.ContentWrapper>
        <div>
          <div>
            <DownloadProgress
              downloadState={downloadState}
              barFillPercentage={barFillPercentage}
            />
          </div>
          <S.Title>{H.getTitle(intl, downloadState)}</S.Title>
          <S.Label>{H.getLabel(intl, downloadState)}</S.Label>
        </div>
        <S.StyledTextButton
          disabled={downloadState === DownloadState.GETTING}
          onClick={() => history.push("/")}
        >
          {intl.formatMessage(messages.downloadContainerButton)}
        </S.StyledTextButton>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default DownloadStatus;
