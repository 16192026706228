import { ReactComponent as ArrowBack } from "assets/icons/myDHLi/arrow-back.svg";
import { ReactComponent as ArrowForward } from "assets/icons/myDHLi/arrow-forward.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import SideBarCollapseControlWrapper from "./SideBarCollapseControlWrapper";

const SideBarCollapseControl = (props) => {
  const navCollapsed = useSelector((state) => state.app.navCollapsed);
  return (
    <SideBarCollapseControlWrapper
      data-track={navCollapsed ? 6 : 7}
      collapsed={navCollapsed}
      {...props}
    >
      {navCollapsed ? (
        <SVGWrapper>
          <ArrowForward />
        </SVGWrapper>
      ) : (
        <SVGWrapper>
          <ArrowBack />
        </SVGWrapper>
      )}
      <label>{props.collapseMenuText}</label>
    </SideBarCollapseControlWrapper>
  );
};
SideBarCollapseControl.defaultProps = {
  collapseMenuText: "Collapse Menu",
};
SideBarCollapseControl.propTypes = {
  collapseMenuText: PropTypes.string,
  belowLg: PropTypes.bool,
};

export default SideBarCollapseControl;
