import { matchPath, useLocation } from "react-router-dom";

const useIsRouteActive = (routes: string[]) => {
  const location = useLocation();

  return routes.some((path) =>
    matchPath(location.pathname, {
      path,
      exact: true,
      strict: false,
    })
  );
};

export default useIsRouteActive;
