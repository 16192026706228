import {
  eventContentButtonInterObj,
  eventContentDropdownInterObj,
  eventContentTabInterObj,
} from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "ShipmentDetailsInteractions";

export const ShipmentDetailsInteractions = {
  FOLLOWING_SHIPMENT: {
    id: `${prefix}_Following Shipment`,
    name: "Following Shipment",
  },
  UNFOLLOWING_SHIPMENT: {
    id: `${prefix}_Unfollowing Shipment`,
    name: "Unfollowing Shipment",
  },
  SHARE: { id: `${prefix}_Share`, name: "Share" },
  PRINT: { id: `${prefix}_Print`, name: "Print" },
  ROUTE: { id: `${prefix}_Route`, name: "Route" },
  DETAILS: { id: `${prefix}_Details`, name: "Details" },
  DOCUMENTS: { id: `${prefix}_Documents`, name: "Documents" },
  CONTAINER: { id: `${prefix}_Container`, name: "Container" },
  CLOSE: { id: `${prefix}_Close`, name: "Close" },
};

enum ShipmentDetailsEventPosition {
  SHIPMENT_DETAILS = "Shipment Details",
  TOP = "Top",
  BOTTOM = "Bottom",
}

const shipmentsDetailsContext = "Shipment Details";

export const shipmentDetailsEventNameToEventContentMapper: C.EventNameToContentMapperType<
  typeof ShipmentDetailsInteractions
> = {
  [ShipmentDetailsInteractions.FOLLOWING_SHIPMENT.id]: {
    name: ShipmentDetailsInteractions.FOLLOWING_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: ShipmentDetailsEventPosition.SHIPMENT_DETAILS,
    context: shipmentsDetailsContext,
  },
  [ShipmentDetailsInteractions.UNFOLLOWING_SHIPMENT.id]: {
    name: ShipmentDetailsInteractions.UNFOLLOWING_SHIPMENT.name,
    ...eventContentButtonInterObj,
    position: ShipmentDetailsEventPosition.SHIPMENT_DETAILS,
    context: shipmentsDetailsContext,
  },
  [ShipmentDetailsInteractions.SHARE.id]: {
    name: ShipmentDetailsInteractions.SHARE.name,
    ...eventContentButtonInterObj,
    position: ShipmentDetailsEventPosition.SHIPMENT_DETAILS,
    context: shipmentsDetailsContext,
  },
  [ShipmentDetailsInteractions.PRINT.id]: {
    name: ShipmentDetailsInteractions.PRINT.name,
    ...eventContentButtonInterObj,
    position: ShipmentDetailsEventPosition.SHIPMENT_DETAILS,
    context: shipmentsDetailsContext,
  },
  [ShipmentDetailsInteractions.ROUTE.id]: {
    name: ShipmentDetailsInteractions.ROUTE.name,
    ...eventContentTabInterObj,
    position: ShipmentDetailsEventPosition.SHIPMENT_DETAILS,
    context: shipmentsDetailsContext,
  },
  [ShipmentDetailsInteractions.DETAILS.id]: {
    name: ShipmentDetailsInteractions.DETAILS.name,
    ...eventContentTabInterObj,
    position: ShipmentDetailsEventPosition.SHIPMENT_DETAILS,
    context: shipmentsDetailsContext,
  },
  [ShipmentDetailsInteractions.DOCUMENTS.id]: {
    name: ShipmentDetailsInteractions.DOCUMENTS.name,
    ...eventContentTabInterObj,
    position: ShipmentDetailsEventPosition.SHIPMENT_DETAILS,
    context: shipmentsDetailsContext,
  },
  [ShipmentDetailsInteractions.CONTAINER.id]: {
    name: ShipmentDetailsInteractions.CONTAINER.name,
    ...eventContentDropdownInterObj,
    position: ShipmentDetailsEventPosition.SHIPMENT_DETAILS,
    context: shipmentsDetailsContext,
  },
  [ShipmentDetailsInteractions.CLOSE.id]: {
    name: ShipmentDetailsInteractions.CLOSE.name,
    ...eventContentButtonInterObj,
    position: ShipmentDetailsEventPosition.TOP,
    context: shipmentsDetailsContext,
  },
};
