import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "MapInteractionInteractions";

export const MapInteractionInteractions = {
  CLOSE_MAP: { id: `${prefix}_Close Map`, name: "Close Map" },
  MAP_ITEM: { id: `${prefix}_Map Item`, name: "Map Item" },
  SHIPMENT_NUMBER: { id: `${prefix}_Shipment Number`, name: "Shipment Number" },
};

enum MapInteractionPosition {
  MAP_HEADER = "Map Header",
  SHIPMENT_MAP = "Shipment Map",
  SHIPMENT_LIST = "Shipment List",
}

const mapInteractionContext = "Shipment Map";

export const mapInteractionEventNameToMapInteractionEventContentMapper: C.EventNameToContentMapperType<
  typeof MapInteractionInteractions
> = {
  [MapInteractionInteractions.CLOSE_MAP.id]: {
    name: MapInteractionInteractions.CLOSE_MAP.name,
    ...eventContentButtonInterObj,
    position: MapInteractionPosition.MAP_HEADER,
    context: mapInteractionContext,
  },
  [MapInteractionInteractions.MAP_ITEM.id]: {
    name: MapInteractionInteractions.MAP_ITEM.name,
    ...eventContentButtonInterObj,
    position: MapInteractionPosition.SHIPMENT_MAP,
    context: mapInteractionContext,
  },
  [MapInteractionInteractions.SHIPMENT_NUMBER.id]: {
    name: MapInteractionInteractions.SHIPMENT_NUMBER.name,
    ...eventContentButtonInterObj,
    position: MapInteractionPosition.SHIPMENT_LIST,
    context: mapInteractionContext,
  },
};
