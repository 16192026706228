import { defineMessages } from "react-intl";

export const messages = defineMessages({
  exportQuoteTitle: {
    id: "quote.export.Title",
    defaultMessage: "Export Quotes",
  },
  selectAll: {
    id: "quote.export.selectAll",
    defaultMessage: "Select All",
  },
  unselectAll: {
    id: "quote.export.unselectAll",
    defaultMessage: "Unselect All",
  },
  cancelExport: {
    id: "quote.export.cancelExport",
    defaultMessage: "Cancel",
  },
  doExport: {
    id: "quote.export.doExport",
    defaultMessage: "Export",
  },
  Follow: {
    id: "quote.export.Follow",
    defaultMessage: "Follow",
  },
  Mode: {
    id: "quote.export.Mode",
    defaultMessage: "Mode",
  },
  Service: {
    id: "quote.export.Service",
    defaultMessage: "Service",
  },
  CreationDate: {
    id: "quote.export.CreationDate",
    defaultMessage: "Creation Date",
  },
  CustomerReference: {
    id: "quote.export.CustomerReference",
    defaultMessage: "Customer Reference",
  },
  OriginCityCountry: {
    id: "quote.export.OriginCityCountry",
    defaultMessage: "Origin",
  },
  DestinationCityCountry: {
    id: "quote.export.DestinationCityCountry",
    defaultMessage: "Destination",
  },
  QuoteTotal: {
    id: "quote.export.QuoteTotal",
    defaultMessage: "Quote Total",
  },
  QuoteStatus: {
    id: "quote.export.QuoteStatus",
    defaultMessage: "Status",
  },
  BookingStatus: {
    id: "quote.export.BookingStatus",
    defaultMessage: "Booked",
  },
  Housebills: {
    id: "quote.export.Housebills",
    defaultMessage: "Housebills",
  },
  QuoteID: {
    id: "quote.export.QuoteID",
    defaultMessage: "Quote ID",
  },
  Weight: {
    id: "quote.export.Weight",
    defaultMessage: "Weight",
  },
  Volume: {
    id: "quote.export.Volume",
    defaultMessage: "Volume",
  },
  ExpirationDate: {
    id: "quote.export.ExpirationDate",
    defaultMessage: "Expiration Date",
  },
  OriginCountry: {
    id: "quote.export.OriginCountry",
    defaultMessage: "Origin Country/Region",
  },
  DestinationCountry: {
    id: "quote.export.DestinationCountry",
    defaultMessage: "Destination Country/Region",
  },
  Origin: {
    id: "quote.export.Origin",
    defaultMessage: "Origin Code",
  },
  Destination: {
    id: "quote.export.Destination",
    defaultMessage: "Destination Code",
  },
  BillingCountry: {
    id: "quote.export.BillingCountry",
    defaultMessage: "Billing Country/Region",
  },
  Company: {
    id: "quote.export.Company",
    defaultMessage: "Company",
  },
  CreatedBy: {
    id: "quote.export.CreatedBy",
    defaultMessage: "Created By",
  },
  RateType: {
    id: "quote.export.RateType",
    defaultMessage: "Rate Type",
  },
  ServiceCharge: {
    id: "quote.export.ServiceCharge",
    defaultMessage: "Service Charge",
  },
  ExportCustomsClearance: {
    id: "quote.export.ExportCustomsClearance",
    defaultMessage: "Export Customs Charge",
  },
  ImportCustomsClearance: {
    id: "quote.export.ImportCustomsClearance",
    defaultMessage: "Import Customs Charge",
  },
  EstimatedPickupDate: {
    id: "quote.export.EstimatedPickupDate",
    defaultMessage: "Pick-up/Self drop off date",
  },
  DHLCargoInsuranceDHLShipmentValueProtection: {
    id: "quote.export.DHLCargoInsuranceDHLShipmentValueProtection",
    defaultMessage: "DHL Cargo Insurance/DHL Shipment Value Protection",
  },
  DHLClimateNeutral: {
    id: "quote.export.DHLClimateNeutral",
    defaultMessage: "DHL Climate Neutral Charge",
  },
  paginationInfo: {
    id: "quote.pagination.info",
    defaultMessage: "{quotes} of {total} quotes",
  },
  loadMoreButton: {
    id: "quote.button.loadMore",
    defaultMessage: "Load More",
  },
  allLoaded: {
    id: "quote.allLoaded",
    defaultMessage:
      "All {total, plural, one {# quote} other {# quotes}} loaded.",
  },
});
