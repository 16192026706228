import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCountry } from "redux/quoteAndBook/actions";
import { setUserCountryViaGeoLocation } from "redux/quoteAndBookNonSync/actions";
import { getArray } from "utils/get";
import { useQuoteCountryOptions } from "./useQuoteCountryOptions";

const GeocoderService = window.google && new window.google.maps.Geocoder();
const useGeolocationHelper = (pathname) => {
  const [timeToAsk, setTimeToAsk] = useState(false);
  const [asked, setAsked] = useState(false);
  const [resolvedCode, setResolvedCode] = useState();
  const countries = useQuoteCountryOptions();
  const dispatch = useDispatch();
  const selectedCountry = useSelector(
    (state) => state?.quoteAndBook?.selectedCountry
  );

  // intend to ask after a few seconds to make sure other methods has a chance to set the country
  useEffect(() => {
    setTimeout(() => {
      setTimeToAsk(true);
    }, 3000);
  }, []);

  // show browser prompt only if already not shown and already not resolved
  useEffect(() => {
    if (timeToAsk) {
      if (
        !asked &&
        !selectedCountry &&
        pathname === "/get-a-quote" &&
        navigator.geolocation &&
        GeocoderService
      ) {
        setAsked(true);
        navigator.geolocation.getCurrentPosition(
          (location) => {
            const { latitude, longitude } = location.coords;
            GeocoderService.geocode(
              { location: { lat: latitude, lng: longitude } },
              (results) => {
                const countryComponent = getArray(
                  results[0],
                  "address_components"
                ).find((item) => {
                  return getArray(item, "types").includes("country");
                });
                setResolvedCode(countryComponent?.short_name);
              }
            );
          },
          () => {}
        );
      }
    }
  }, [timeToAsk, asked, selectedCountry, setAsked, pathname]);

  // make sure the resolved country exist in QnB database as a billing country and set it to redux store
  useEffect(() => {
    if (resolvedCode && countries.length > 1 && !selectedCountry) {
      const matchedCountry = countries.find(
        (country) =>
          country.value.toLowerCase() === resolvedCode.toLowerCase() &&
          country.isBilling
      );
      const isRestricted = ["ae", "dx"].some(
        (c) => c === resolvedCode.toLowerCase()
      );
      if (matchedCountry && !isRestricted) {
        const obj = {
          label: matchedCountry.label,
          value: matchedCountry.value,
        };
        dispatch(setCountry(obj, "GEOLOCATION"));
        dispatch(setUserCountryViaGeoLocation(obj));
      }
    }
  }, [resolvedCode, countries, dispatch, selectedCountry]);
};
export default useGeolocationHelper;
