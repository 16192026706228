import { DownloadState } from "constants/DownloadState";
import React from "react";
import { Color, Loader, LoaderSize, UploadProgress } from "react-lib";
import SvgCancelCircle from "react-lib/es/icons/SvgCancelCircle";
import SvgCheckmarkCircle from "react-lib/es/icons/SvgCheckmarkCircle";

export interface DownloadProgressProps {
  downloadState: DownloadState;
  barFillPercentage: number;
}

const DownloadProgress: React.FC<DownloadProgressProps> = ({
  downloadState,
  barFillPercentage,
}) => {
  switch (downloadState) {
    case DownloadState.GETTING:
      return barFillPercentage > 0 ? (
        <UploadProgress
          position="relative"
          percentCompleted={barFillPercentage}
          size={96}
          stroke={8}
          color={Color.dhlRed}
        />
      ) : (
        <Loader size={LoaderSize.LARGE} />
      );
    case DownloadState.DOWNLOADING:
      return <SvgCheckmarkCircle fill={Color.green} width="6rem" />;
    case DownloadState.EXPIRED:
      return <SvgCancelCircle fill={Color.dhlRed} width="6rem" />;
    case DownloadState.FAILED:
      return <SvgCancelCircle fill={Color.dhlRed} width="6rem" />;
    default:
      return null;
  }
};

export default DownloadProgress;
