import { HeaderAndFooterHeightTotal } from "constants/variables";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { logOut } from "redux/auth/actions";
import styled from "styled-components";
import { getRem } from "../utils/css";
import { messages } from "./messages";
// import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - ${getRem(HeaderAndFooterHeightTotal)});
`;
const Logout = (props) => {
  const intl = useIntl();

  useEffect(() => {
    props.logOut();
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <div>{intl.formatMessage(messages.logoutMessage)}</div>
    </Wrapper>
  );
};
Logout.defaultProps = {};
Logout.propTypes = {};

export default connect(() => ({}), { logOut })(Logout);
