const baseType = "SHIPMENTS";
const bookingsBaseType = "BOOKINGS";

const types = {
  SET_SHIPMENT_SORT: `${baseType}/SET_SHIPMENT_SORT`,
  SET_QUOTE_SORT: `${baseType}/SET_QUOTE_SORT`,
  SET_BOOKINGS_LIST_SORT: `${bookingsBaseType}/SET_BOOKINGS_LIST_SORT`,
};

export default types;
