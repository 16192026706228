import {
  eventContentButtonInterObj,
  eventContentLinkInterObj,
} from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "QuotesInteractions";

export const QuotesInteractions = {
  SORT_BY: { id: `${prefix}_Sort by`, name: "Sort by" },
  REFRESH_TABLE: { id: `${prefix}_Refresh Table`, name: "Refresh Table" },
  TABLE_OPTIONS: { id: `${prefix}_Table options`, name: "Table options" },
  RESET_FILTERS: { id: `${prefix}_Reset Filter`, name: "Reset Filter" },
  FOLLOWING_QUOTE: { id: `${prefix}_Following Quote`, name: "Following Quote" },
  UNFOLLOWING_QUOTE: {
    id: `${prefix}_Unfollowing Quote`,
    name: "Unfollowing Quote",
  },
  SHARE_QUOTE: { id: `${prefix}_Share Quote`, name: "Share Quote" },
  INDIVIDUAL_QUOTE: {
    id: `${prefix}_Individual Quote`,
    name: "Individual Quote",
  },
  BOOK: { id: `${prefix}_Book`, name: "Book" },
  GET_A_QUOTE: { id: `${prefix}_Get a Quote`, name: "Get a Quote" },
};

enum QuotesPosition {
  PAGE_HEADER = "Page Header",
  TABLE_HEADER = "Table Header",
}

const quotesContext = "Quote List";

export const quotesEventNameToQuotesEventContentMapper: C.EventNameToContentMapperType<
  typeof QuotesInteractions
> = {
  [QuotesInteractions.SORT_BY.id]: {
    name: QuotesInteractions.SORT_BY.name,
    ...eventContentButtonInterObj,
    position: QuotesPosition.TABLE_HEADER,
    context: quotesContext,
  },
  [QuotesInteractions.REFRESH_TABLE.id]: {
    name: QuotesInteractions.REFRESH_TABLE.name,
    ...eventContentButtonInterObj,
    position: QuotesPosition.TABLE_HEADER,
    context: quotesContext,
  },
  [QuotesInteractions.TABLE_OPTIONS.id]: {
    name: QuotesInteractions.TABLE_OPTIONS.name,
    ...eventContentButtonInterObj,
    position: QuotesPosition.TABLE_HEADER,
    context: quotesContext,
  },
  [QuotesInteractions.RESET_FILTERS.id]: {
    name: QuotesInteractions.RESET_FILTERS.name,
    ...eventContentButtonInterObj,
    position: QuotesPosition.TABLE_HEADER,
    context: quotesContext,
  },
  [QuotesInteractions.FOLLOWING_QUOTE.id]: {
    name: QuotesInteractions.FOLLOWING_QUOTE.name,
    ...eventContentButtonInterObj,
    position: "0",
    context: quotesContext,
  },
  [QuotesInteractions.UNFOLLOWING_QUOTE.id]: {
    name: QuotesInteractions.UNFOLLOWING_QUOTE.name,
    ...eventContentButtonInterObj,
    position: "0",
    context: quotesContext,
  },
  [QuotesInteractions.SHARE_QUOTE.id]: {
    name: QuotesInteractions.SHARE_QUOTE.name,
    ...eventContentButtonInterObj,
    position: "0",
    context: quotesContext,
  },
  [QuotesInteractions.INDIVIDUAL_QUOTE.id]: {
    name: QuotesInteractions.INDIVIDUAL_QUOTE.name,
    ...eventContentLinkInterObj,
    position: "0",
    context: quotesContext,
  },
  [QuotesInteractions.BOOK.id]: {
    name: QuotesInteractions.BOOK.name,
    ...eventContentButtonInterObj,
    position: "0",
    context: quotesContext,
  },
  [QuotesInteractions.GET_A_QUOTE.id]: {
    name: QuotesInteractions.GET_A_QUOTE.name,
    ...eventContentLinkInterObj,
    position: QuotesPosition.PAGE_HEADER,
    context: quotesContext,
  },
};
