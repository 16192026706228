import React from "react";
import { useIntl } from "react-intl";
import messages from "./Rating.messages";
import * as S from "./Rating.styles";

interface RatingProps {
  ratingScores: number[];
  selectedRating: number | null;
  onRatingSelect: (rating: number | null) => void;
}

const Rating: React.FC<RatingProps> = ({
  ratingScores,
  selectedRating,
  onRatingSelect,
}) => {
  const intl = useIntl();

  return (
    <S.RatingWrapper>
      <S.Likelihood>{intl.formatMessage(messages.notLikely)}</S.Likelihood>
      <S.Likelihood isRightAligned>
        {intl.formatMessage(messages.veryLikely)}
      </S.Likelihood>
      <S.RatingScoresWrapper>
        {ratingScores.map((ratingScore) => (
          <S.SingleRating
            key={ratingScore}
            onClick={() => onRatingSelect(ratingScore)}
            isSelected={selectedRating === ratingScore}
            isAnySelected={typeof selectedRating === "number"}
          >
            <S.RatingScore>{ratingScore}</S.RatingScore>
          </S.SingleRating>
        ))}
      </S.RatingScoresWrapper>
    </S.RatingWrapper>
  );
};

export default Rating;
