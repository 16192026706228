import styled from "styled-components";

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TableCol = styled.div`
  flex: 1 1 ${(props) => `${props.customSize}rem`};
`;
