import { all, fork, takeLatest } from "redux-saga/effects";
import settingsTypes from "../quoteAndBook/action-types";
import * as C from "./constants";
import {
  calculateConsignment,
  recalculateQuote,
  saveConsignment,
  saveConsignmentSuccess,
  submitConsignment,
  updateOffers,
} from "./effects";

function* watchRecalculateQuote() {
  yield takeLatest(C.ActionsType.RECALCULATE_QUOTE, recalculateQuote);
}

// todo: temporarily added as recalculate not working with currency change
function* watchSetCurrency() {
  yield takeLatest(settingsTypes.SET_CURRENCY, updateOffers);
}

function* watchSetEstimatedPickup() {
  yield takeLatest(C.ActionsType.UPDATE_OFFERS, updateOffers);
}

function* watchSaveConsignment() {
  yield takeLatest(C.ActionsType.SAVE_CONSIGNMENT_API, saveConsignment);
}
function* watchSaveConsignmentSuccess() {
  yield takeLatest(
    C.ActionsType.SAVE_CONSIGNMENT_API_SUCCEEDED,
    saveConsignmentSuccess
  );
}

function* watchCalculateConsignment() {
  yield takeLatest(C.ActionsType.CALCULATE_CONSIGNMENT, calculateConsignment);
}

function* watchSubmitConsignment() {
  yield takeLatest(C.ActionsType.SUBMIT_CONSIGNMENT, submitConsignment);
}

export default function* rootSaga() {
  yield all([
    fork(watchRecalculateQuote),
    fork(watchSetCurrency),
    fork(watchSaveConsignment),
    fork(watchCalculateConsignment),
    fork(watchSubmitConsignment),
    fork(watchSaveConsignmentSuccess),
    fork(watchSetEstimatedPickup),
  ]);
}
