import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useAcousticsCampaign = (): void => {
  const location = useLocation();
  const campaignId = useSelector(
    // @ts-ignore
    (state) => state?.quoteAndBookNonSync?.consignment?.campaignId
  );

  useMemo(() => {
    const cid = new URLSearchParams(location?.search)?.get("cid");
    if (cid) {
      sessionStorage.setItem("acoustics_campaign_id", cid);
      return;
    }

    const campaignIdFromStorage = sessionStorage?.getItem(
      "acoustics_campaign_id"
    );
    if (!campaignIdFromStorage && campaignId) {
      sessionStorage.setItem("acoustics_campaign_id", campaignId);
    }
  }, [location, campaignId]);
};

export default useAcousticsCampaign;
