import { ROLE_REPORTING } from "constants/variables";
import userSettingsActionTypes from "../userSettings/action-types";
import types from "./action-types";

const initialState = {
  loginInProgress: false,
  redirect: false,
  loggedInUserRoles: [],
  loggedInUserEmail: "",
  demoUserRoles: {},
  currentUserRoles: [],
  isUserLoggedIn: false,
  doesCurrentUserHaveQuoteRole: false,
  doesCurrentUserHaveReportingRole: undefined,
  userId: undefined,
  userConnectionCountry: undefined,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TOKEN: {
      return { ...state, loginInProgress: true, redirect: false };
    }
    case types.GET_TOKEN_SUCCESS:
      return {
        ...state,
        loginInProgress: false,
        redirect: true,
      };
    case types.GET_TOKEN_ERROR:
    case types.LOG_OUT:
      return initialState;
    case types.SET_LOGGED_IN_USER_ROLES:
      return {
        ...state,
        loggedInUserRoles: action.payload,
      };
    case types.SET_LOGGED_IN_USER_EMAIL:
      return {
        ...state,
        loggedInUserEmail: action.payload,
      };
    case types.SET_DEMO_USER_ROLES:
      return {
        ...state,
        demoUserRoles: action.payload,
      };
    case types.SET_CURRENT_USER_ROLES:
      return {
        ...state,
        currentUserRoles: action.payload,
        doesCurrentUserHaveQuoteRole: action.payload.some((i) =>
          i.includes("Quote")
        ),
        doesCurrentUserHaveReportingRole:
          action.payload.includes(ROLE_REPORTING),
      };
    case types.SET_USER_LOGGED_IN:
      return {
        ...state,
        isUserLoggedIn: action.payload,
      };
    case types.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case userSettingsActionTypes.SAVE_VISIBILITY_SCOPE:
      return {
        ...state,
        doesCurrentUserHaveReportingRole: undefined,
      };
    case types.SET_USER_CONNECTION_COUNTRY:
      return {
        ...state,
        userConnectionCountry: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
