import { ReactComponent as ArrowForwardUp } from "assets/icons/myDHLi/arrow-forward-up.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import PropTypes from "prop-types";
import React from "react";
import { colors } from "react-lib";
import styled from "styled-components";
import { getRem } from "utils/css";

const Wrapper = styled.a`
  flex: 0 0 auto;
  display: block;
  font-size: ${getRem(14)};
  color: ${colors.black};
  text-decoration: none;
  :hover,
  :focus,
  :visited {
    color: ${colors.black};
    text-decoration: none;
  }
  padding-right: ${getRem(20)};
`;
const ExternalLink = (props) => {
  return (
    <Wrapper
      data-track={props.trackCode}
      role={props.role}
      href={props.href}
      target="_blank"
    >
      <span>{props.text}</span>
      <SVGWrapper height={getRem(24)} width={getRem(24)}>
        <ArrowForwardUp />
      </SVGWrapper>
    </Wrapper>
  );
};
ExternalLink.defaultProps = {
  href: "#",
  text: "",
};
ExternalLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
};

export default ExternalLink;
