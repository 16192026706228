import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "SelectQuoteInteractions";

export const SelectQuoteInteractions = {
  EDIT: { id: `${prefix}_Edit`, name: "Edit" },
  CONTRACT_ID: { id: `${prefix}_Contract ID`, name: "Contract ID" },
  CURRENCY: { id: `${prefix}_Currency`, name: "Currency" },
  GET_EMAIL_QUOTE: { id: `${prefix}_Get Email Quote`, name: "Get Email Quote" },
};

const selectQuotePosition = "Header";

const selectQuoteContext = "Select Quote";
export const selectQuoteEventNameToEventContentMapper: C.EventNameToContentMapperType<
  typeof SelectQuoteInteractions
> = {
  [SelectQuoteInteractions.EDIT.id]: {
    name: SelectQuoteInteractions.EDIT.name,
    ...eventContentButtonInterObj,
    position: selectQuotePosition,
    context: selectQuoteContext,
  },
  [SelectQuoteInteractions.CONTRACT_ID.id]: {
    name: SelectQuoteInteractions.CONTRACT_ID.name,
    ...eventContentButtonInterObj,
    position: selectQuotePosition,
    context: selectQuoteContext,
  },
  [SelectQuoteInteractions.CURRENCY.id]: {
    name: SelectQuoteInteractions.CURRENCY.name,
    ...eventContentButtonInterObj,
    position: selectQuotePosition,
    context: selectQuoteContext,
  },
  [SelectQuoteInteractions.GET_EMAIL_QUOTE.id]: {
    name: SelectQuoteInteractions.GET_EMAIL_QUOTE.name,
    ...eventContentButtonInterObj,
    position: selectQuotePosition,
    context: selectQuoteContext,
  },
};
