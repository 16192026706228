import * as MDM from "constants/masterDataManagement";
import { createContext } from "react";
import { TableAction } from "./AddressBook.constants";

export interface AddressBookContextProps {
  countryList: MDM.Country[];
  tableAction: TableAction | null;
  setTableAction: (tableAction: TableAction | null) => void;
}

export const AddressModalContext = createContext<AddressBookContextProps>({
  countryList: [],
  tableAction: null,
  setTableAction: () => {},
});
