import { ReactComponent as NoConnectionIcon } from "assets/icons/myDHLi/no-connection.svg";
import SVGWrapper from "components/atoms/SVGWrapper";
import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { getRem } from "utils/css";
import { Text, Wrapper } from "./styles";

const messages = defineMessages({
  offline: {
    id: "error.offline",
    defaultMessage:
      "You seem to be offline. You may not be able to use some functions.",
  },
});

const OfflineBanner = ({ isMobile }) => {
  const intl = useIntl();
  const [isUserOnline, setIsUserOnline] = useState(() => {
    return typeof navigator !== "undefined" &&
      typeof navigator.onLine === "boolean"
      ? navigator.onLine
      : true;
  });
  const goOnline = () => {
    setIsUserOnline(true);
  };
  const goOffline = () => {
    setIsUserOnline(false);
  };
  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <AnimatePresence>
      {!isUserOnline && (
        <Wrapper
          isMobile={isMobile}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { duration: 0.5, ease: [0.4, 0.0, 0.2, 1] },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.5, ease: [0.4, 0.0, 0.2, 1] },
          }}
        >
          <SVGWrapper
            width={getRem(48)}
            height={getRem(48)}
            padding={getRem(20)}
          >
            <NoConnectionIcon />
          </SVGWrapper>
          <Text>{intl.formatMessage(messages.offline)}</Text>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default OfflineBanner;
