export interface ChangePasswordModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
}

export enum ChangePasswordFormFieldName {
  CURRENT_PASSWORD = "currentPassword",
  NEW_PASSWORD = "newPassword",
  CONFIRM_PASSWORD = "confirmPassword",
}

export interface ChangePasswordFormValues {
  [ChangePasswordFormFieldName.CURRENT_PASSWORD]: string;
  [ChangePasswordFormFieldName.NEW_PASSWORD]: string;
  [ChangePasswordFormFieldName.CONFIRM_PASSWORD]: string;
}

export const minPasswordLength = 12;

export enum PasswordUpdateErrorEnum {
  COMMON_WORD = "invalidPasswordBlacklistedMessage",
  INVALID_CURRENT_PASSWORD = "invalidCurrentPassword",
}
