import getConfigClient from "constants/getConfigClient";

// Keep in sync with server logger types //
export enum LogLevel {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

export enum BrowserMessages {
  WINDOW_UNHANDLEDREJECTION = "window unhandledrejection",
  WINDOW_ERROR = "window error",
}

export interface UiLogBody {
  message: string;
  details: { [key: string]: any } & {
    stack: string;
    originalError?: any;
  };
}

export interface BrowserLogBody extends UiLogBody {
  level: LogLevel;
}

/*************************************************/

export const loggingIntervalTimeInMs = 2000;
export const isDebugEnabled = getConfigClient("DEBUG_MESSAGE");
export const errorsLoggingEndpoint =
  getConfigClient("NODE_ENDPOINT") + "log-browser-data";
