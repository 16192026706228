import * as C from "./TestingContext.constants";

export const formatDataTestId = ({
  uniqueName,
  iterableIdentifier,
  feature,
}: {
  uniqueName: string;
  iterableIdentifier?: string | number;
  feature: C.Feature;
}) => {
  if (!feature) {
    return "";
  }
  return `${feature}_${uniqueName}${
    iterableIdentifier != null && iterableIdentifier !== ""
      ? `--${iterableIdentifier}`
      : ""
  }`;
};
