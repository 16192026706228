export const languageStorageKey = "language";
export const defaultLanguage = "en";

export interface LanguageObject {
  code: string;
  code_aem: string;
  fileName: string;
  label: string;
  ucCode: string;
  weblate_key: string;
}
