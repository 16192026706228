import { Color } from "react-lib";
import {
  Bar,
  Wrapper,
} from "react-lib/es/atoms/BlackBoxSticky/BlackBoxSticky.styles";
import styled from "styled-components";

export const StyledBar = styled(Bar)`
  z-index: 10001;
  left: 0;
`;

export const StyledWrapper = styled(Wrapper)<{ isDeleteAction: boolean }>`
  display: flex;
  margin: 1.25rem 1rem;
  justify-content: space-between;
  align-items: center;
  max-width: 24rem;
  padding: 0.75rem 1.25rem;
  background-color: ${({ isDeleteAction }) =>
    isDeleteAction ? Color.grey80 : Color.darkGreen};
  box-shadow: 0.125rem 0.25rem 0.75rem 0 ${Color.black},
    0 0 1rem -0.125rem rgba(0, 0, 0, 0.12), 0 0.125rem 0.25rem 0 ${Color.black};
`;

export const StyledBlackBoxStickyContent = styled.div`
  margin: 0 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 300;
  user-select: none;
`;
