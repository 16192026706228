import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "ThankYouInteractions";

export const ThankYouInteractions = {
  GO_TO_MYDHLI: { id: `${prefix}_Go to myDHLi`, name: "Go to myDHLi" },
  GET_A_NEW_QUOTE: { id: `${prefix}_Get a new Quote`, name: "Get a new Quote" },
  ONE_STAR_RATING: { id: `${prefix}_One star rating`, name: "One star rating" },
  TWO_STAR_RATING: { id: `${prefix}_Two star rating`, name: "Two star rating" },
  THREE_STAR_RATING: {
    id: `${prefix}_Three star rating`,
    name: "Three star rating",
  },
  FOUR_STAR_RATING: {
    id: `${prefix}_Four star rating`,
    name: "Four star rating",
  },
  FIVE_STAR_RATING: {
    id: `${prefix}_Five star rating`,
    name: "Five star rating",
  },
  COMMENTS: { id: `${prefix}_Comments`, name: "Comments" },
  SEND_FEEDBACK: { id: `${prefix}_Send Feedback`, name: "Send Feedback" },
};

enum ThankYouEventPosition {
  SUMMARY = "Summary",
  FEEDBACK = "Feedback",
}

const thankYouContext = "Thank You";

export const thankYouEventNameToThankYouEventContentMapper: C.EventNameToContentMapperType<
  typeof ThankYouInteractions
> = {
  [ThankYouInteractions.GO_TO_MYDHLI.id]: {
    name: ThankYouInteractions.GO_TO_MYDHLI.name,
    ...eventContentButtonInterObj,
    position: ThankYouEventPosition.SUMMARY,
    context: thankYouContext,
  },
  [ThankYouInteractions.GET_A_NEW_QUOTE.id]: {
    name: ThankYouInteractions.GET_A_NEW_QUOTE.name,
    ...eventContentButtonInterObj,
    position: ThankYouEventPosition.SUMMARY,
    context: thankYouContext,
  },
  [ThankYouInteractions.ONE_STAR_RATING.id]: {
    name: ThankYouInteractions.ONE_STAR_RATING.name,
    ...eventContentButtonInterObj,
    position: ThankYouEventPosition.FEEDBACK,
    context: thankYouContext,
  },
  [ThankYouInteractions.TWO_STAR_RATING.id]: {
    name: ThankYouInteractions.TWO_STAR_RATING.name,
    ...eventContentButtonInterObj,
    position: ThankYouEventPosition.FEEDBACK,
    context: thankYouContext,
  },
  [ThankYouInteractions.THREE_STAR_RATING.id]: {
    name: ThankYouInteractions.THREE_STAR_RATING.name,
    ...eventContentButtonInterObj,
    position: ThankYouEventPosition.FEEDBACK,
    context: thankYouContext,
  },
  [ThankYouInteractions.FOUR_STAR_RATING.id]: {
    name: ThankYouInteractions.FOUR_STAR_RATING.name,
    ...eventContentButtonInterObj,
    position: ThankYouEventPosition.FEEDBACK,
    context: thankYouContext,
  },
  [ThankYouInteractions.FIVE_STAR_RATING.id]: {
    name: ThankYouInteractions.FIVE_STAR_RATING.name,
    ...eventContentButtonInterObj,
    position: ThankYouEventPosition.FEEDBACK,
    context: thankYouContext,
  },
  [ThankYouInteractions.COMMENTS.id]: {
    name: ThankYouInteractions.COMMENTS.name,
    ...eventContentButtonInterObj,
    position: ThankYouEventPosition.FEEDBACK,
    context: thankYouContext,
  },
  [ThankYouInteractions.SEND_FEEDBACK.id]: {
    name: ThankYouInteractions.SEND_FEEDBACK.name,
    ...eventContentButtonInterObj,
    position: ThankYouEventPosition.FEEDBACK,
    context: thankYouContext,
  },
};
