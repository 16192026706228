import { defineMessages } from "react-intl";

export const messages = defineMessages({
  errorOccurred: {
    id: "error.occurred",
    defaultMessage: "Sorry! The server encountered an error",
  },
  noOffersFoundError: {
    id: "error.noOffersFoundError",
    defaultMessage:
      "Sorry we are not able to show any options. for this request. ",
  },
  noOffersFoundErrorLink: {
    id: "error.noOffersFoundError.link",
    defaultMessage: "Please contact us.",
  },
  reportProblem: {
    id: "error.reportProblem",
    defaultMessage: "If the problem persists, report your problem",
  },
  mentionCode: {
    id: "error.mentionCode",
    defaultMessage: "and mention the error code:",
  },
  navPane: {
    id: "error.navPane",
    defaultMessage: "load your navigation pane",
  },
  loadShipment: {
    id: "error.loadShipment",
    defaultMessage: "load your shipments",
  },
  loadData: {
    id: "error.loadData",
    defaultMessage: "load some data",
  },
  loadWidget: {
    id: "error.loadWidget",
    defaultMessage: "load your widgets",
  },
  errorAnd: {
    id: "error.and",
    defaultMessage: "and",
  },
  joinWeCouldNot: {
    id: "error.joinWeCouldNot",
    defaultMessage: ". We couldn't",
  },
  errorStop: {
    id: "error.stop",
    defaultMessage: ".",
  },
  markFavorite: {
    id: "error.markFavorite",
    defaultMessage: "mark an item as favorite",
  },
  removeFavorite: {
    id: "error.removeFavorite",
    defaultMessage: "remove an item from favorite",
  },
  markLastViewed: {
    id: "error.markLastViewed",
    defaultMessage: "put an item in your last viewed list",
  },
  columnSuggestion: {
    id: "error.columnSuggestion",
    defaultMessage: "load suggestions for this column",
  },
  loadAnalytics: {
    id: "error.loadAnalytics",
    defaultMessage: "load your analytics dashboard",
  },
  loadMap: {
    id: "error.loadMap",
    defaultMessage: "load the map properly",
  },
  exportList: {
    id: "error.exportList",
    defaultMessage: "export your list",
  },
  searchSuggestion: {
    id: "error.searchSuggestion",
    defaultMessage: "load search suggestions",
  },
  loadDocuments: {
    id: "error.loadDocuments",
    defaultMessage: "load documents for this shipment",
  },
  loadProfile: {
    id: "error.loadProfile",
    defaultMessage: "load your profile",
  },
  loadQuotes: {
    id: "error.loadQuotes",
    defaultMessage: "load your quotes",
  },
  loadCurrencies: {
    id: "error.loadCurrencies",
    defaultMessage: "get the list of available currencies",
  },
  savePreference: {
    id: "error.savePreference",
    defaultMessage: "store your preferences",
  },
  reportingSharedViews: {
    id: "error.reportingSharedViews",
    defaultMessage: "get the templates",
  },
  reload: {
    id: "error.reload",
    defaultMessage: "Reload",
  },
  errorMaxAllowedQuotesReached: {
    id: "error.maxAllowedQuotes",
    defaultMessage:
      "You have exceeded the maximum number of quotes allowed per day. Please try again tomorrow, or Contact us for urgent requests.",
  },
  quoteExpired: {
    id: "error.quoteExpired",
    defaultMessage: "Your previous offer has expired, click here to refresh.",
  },
  noOffersFoundErrorR2RPreStartNewQuote: {
    id: "error.noOffersFoundError.R2R.preStartNewQuote",
    defaultMessage:
      "Your selected product is no longer available for your routing selection. Please 'edit' to adjust the routing or",
  },
  noOffersFoundErrorR2RStartNewQuote: {
    id: "error.noOffersFoundError.R2R.startNewQuote",
    defaultMessage: "'start new quote'",
  },
  noOffersFoundErrorR2RPostStartNewQuote: {
    id: "error.noOffersFoundError.R2R.startNewQuote.postStartNewQuote",
    defaultMessage: "to see options available.",
  },
  tryLater: {
    id: "error.quotePackages",
    defaultMessage:
      "We are not able to process your request at present. Please try again later, or {contactUsLink}.",
  },
  contactUsLinkText: {
    id: "error.quotePackagesContactUs",
    defaultMessage: "contact us",
  },
  errorQuotePackageTypes: {
    id: "error.errorQuotePackageTypes",
    defaultMessage: "Sorry",
  },
  blockedCustomer: {
    id: "error.blockedCustomer",
    defaultMessage:
      "Due to security reasons, we can't proceed with the quote. In case of questions or clarifications please {contactUsLink}.",
  },
});
