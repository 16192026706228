import gql from "graphql-tag";

const getColumnSuggestion = gql`
  query ColumnSuggestionQuery(
    $search: String!
    $column: FilterColumnName!
    $filter: ShipmentFilter
    $limit: Int
    $corporatePartner: String!
  ) {
    getColumnSuggestions(
      search: $search
      column: $column
      filter: $filter
      limit: $limit
      corporatePartner: $corporatePartner
    ) {
      statusCode
      errorCode
      errorMessage
      paging {
        count
        limit
        offset
        totalCount
        totalRetrievableCount
      }
      data {
        key
        title
        count
      }
    }
  }
`;
export default getColumnSuggestion;
