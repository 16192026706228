const baseType = "USER_SETTINGS";

const types = {
  LOAD_COLUMN_PREFERENCES: `${baseType}/LOAD_COLUMN_PREFERENCES`,
  SAVE_COLUMN_PREFERENCES: `${baseType}/SAVE_COLUMN_PREFERENCES`,
  LOAD_QUOTES_COLUMN_PREFERENCES: `${baseType}/LOAD_QUOTES_COLUMN_PREFERENCES`,
  SAVE_VISIBILITY_SCOPE: `${baseType}/SAVE_VISIBILITY_SCOPE`,
  LOAD_CORPORATE_PARTNER_PREFERENCE: `${baseType}/LOAD_CORPORATE_PARTNER_PREFERENCE`,
  RESET_VISIBILITY_SCOPE: `${baseType}/RESET_VISIBILITY_SCOPE`,
  GET_WIDGET_ORDER: `${baseType}/GET_WIDGET_ORDER`,
  SAVE_AVAILABLE_WIDGETS: `${baseType}/SAVE_AVAILABLE_WIDGETS`,
  GET_WIDGET_ORDER_SUCCESS: `${baseType}/GET_WIDGET_ORDER_SUCCESS`,
  GET_WIDGET_ORDER_ERROR: `${baseType}/GET_WIDGET_ORDER_ERROR`,
  SAVE_WIDGET_ORDER: `${baseType}/SAVE_WIDGET_ORDER`,
  SAVE_WIDGET_LIST: `${baseType}/SAVE_WIDGET_LIST`,
  RESET_WIDGET_ORDER: `${baseType}/RESET_WIDGET_ORDER`,
  ENABLE_DISABLE_WIDGET: `${baseType}/ENABLE_DISABLE_WIDGET`,
  SAVE_SHIPMENT_COLUMN_ORDER: `${baseType}/SAVE_SHIPMENT_COLUMN_ORDER`,
  RESET_SHIPMENT_COLUMN_ORDER: `${baseType}/RESET_SHIPMENT_COLUMN_ORDER`,
  CHANGE_COLUMN_ORDER: `${baseType}/CHANGE_COLUMN_ORDER`,
  CHANGE_BOOKINGS_COLUMN_ORDER: `${baseType}/CHANGE_BOOKINGS_COLUMN_ORDER`,
  CHANGE_DRAFT_BOOKINGS_COLUMN_ORDER: `${baseType}/CHANGE_DRAFT_BOOKINGS_COLUMN_ORDER`,
  RESET_DRAFT_BOOKINGS_COLUMN_ORDER: `${baseType}/RESET_DRAFT_BOOKINGS_COLUMN_ORDER`,
  RESET_SHIPMENTS_COLUMN_ORDER: `${baseType}/RESET_SHIPMENTS_COLUMN_ORDER`,
  CHANGE_QUOTES_COLUMN_ORDER: `${baseType}/CHANGE_QUOTES_COLUMN_ORDER`,
  RESET_QUOTES_COLUMN_ORDER: `${baseType}/RESET_QUOTES_COLUMN_ORDER`,
  RESET_BOOKINGS_COLUMN_ORDER: `${baseType}/RESET_BOOKINGS_COLUMN_ORDER`,
  CHANGE_CDZ_COLUMN_ORDER: `${baseType}/CHANGE_CDZ_COLUMN_ORDER`,
  RESET_CDZ_COLUMN_ORDER: `${baseType}/RESET_CDZ_COLUMN_ORDER`,
  SET_SHIPMENT_FILTER: `${baseType}/SET_SHIPMENT_FILTER`,
  UNSET_SHIPMENT_FILTER: `${baseType}/UNSET_SHIPMENT_FILTER`,
  RESET_SHIPMENT_FILTER: `${baseType}/RESET_SHIPMENT_FILTER`,
  REPLACE_SHIPMENT_FILTER: `${baseType}/REPLACE_SHIPMENT_FILTER`,
  SET_QUOTES_FILTER: `${baseType}/SET_QUOTES_FILTER`,
  UNSET_QUOTES_FILTER: `${baseType}/UNSET_QUOTES_FILTER`,
  RESET_QUOTES_FILTER: `${baseType}/RESET_QUOTES_FILTER`,
  REPLACE_QUOTES_FILTER: `${baseType}/REPLACE_QUOTES_FILTER`,
  SET_CDZ_FILTER: `${baseType}/SET_CDZ_FILTER`,
  UNSET_CDZ_FILTER: `${baseType}/UNSET_CDZ_FILTER`,
  RESET_CDZ_FILTER: `${baseType}/RESET_CDZ_FILTER`,
  REPLACE_CDZ_FILTER: `${baseType}/REPLACE_CDZ_FILTER`,
  UPDATE_CDZ_EXPORT_COLUMNS: `${baseType}/UPDATE_CDZ_EXPORT_COLUMNS`,
  SET_SHIPMENT_SEARCH_KEYWORDS: `${baseType}/SET_SHIPMENT_SEARCH_KEYWORDS`,
  ADD_SHIPMENT_SEARCH_PHRASE: `${baseType}/ADD_SHIPMENT_SEARCH_PHRASE`,
  MARK_AS_DOWNLOADED: `${baseType}/MARK_AS_DOWNLOADED`,
  GET_CORPORATE_PARTNERS: `${baseType}/GET_CORPORATE_PARTNERS`,
  GET_CORPORATE_PARTNERS_SUCCESS: `${baseType}/GET_CORPORATE_PARTNERS_SUCCESS`,
  GET_CORPORATE_PARTNERS_ERROR: `${baseType}/GET_CORPORATE_PARTNERS_ERROR`,
  UPDATE_ANALYTICS_TIMESTAMP: `${baseType}/UPDATE_ANALYTICS_TIMESTAMP`,
  UPDATE_SHIPMENT_EXPORT_COLUMNS: `${baseType}/UPDATE_SHIPMENT_EXPORT_COLUMNS`,
  UPDATE_QUOTE_EXPORT_COLUMNS: `${baseType}/UPDATE_QUOTE_EXPORT_COLUMNS`,
  UPDATE_LATEST_TC_ACCEPTED: `${baseType}/UPDATE_LATEST_TC_ACCEPTED`,
  SET_MAP_TRANSPORT_MODE: `${baseType}/SET_MAP_TRANSPORT_MODE`,
  SET_LOCALE: `${baseType}/SET_LOCALE`,
  SET_ANALYTICS_METHOD: `${baseType}/SET_ANALYTICS_METHOD`,
  RESET_ALL: `${baseType}/RESET_ALL`,
  RESET_BOOKINGS_LIST_FILTER: `${baseType}/RESET_BOOKINGS_LIST_FILTER`,
  SET_BOOKINGS_LIST_FILTER: `${baseType}/SET_BOOKINGS_LIST_FILTER`,
  UNSET_BOOKINGS_LIST_FILTER: `${baseType}/UNSET_BOOKINGS_LIST_FILTER`,
  REPLACE_BOOKINGS_LIST_FILTER: `${baseType}/REPLACE_BOOKINGS_LIST_FILTER`,
  INIT_BOOKINGS: `${baseType}/INIT_BOOKINGS`,
  INIT_BOOKINGS_DRAFTS: `${baseType}/INIT_BOOKINGS_DRAFTS`,
  UPDATE_READ_ONLY: `${baseType}/UPDATE_READ_ONLY`,
};

export default types;
