import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { getCorporatePartners } from "redux/userSettings/actions";

export const useCorporatePartnersLoad = () => {
  const dispatch = useDispatch();

  const isUserLoggedIn = useSelector<RootState, boolean>(
    (state) => state.auth?.isUserLoggedIn
  );

  const isDemoMode = useSelector<RootState, boolean>(
    (state) => state.userSettings.visibilityScope?.demo
  );

  // By default isCheckingDemoProfile in store is set to true, only after
  // corporate partners are fetched it is changed to correct value
  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(getCorporatePartners({ isDemoMode: isDemoMode ?? true }));
    }
  }, [dispatch, isUserLoggedIn, isDemoMode]);
};
