import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { setBookingsListFilter } from "redux/userSettings/actions";
import Search from "../../Filters/ListBucketSearch/Partials/SearchBuckets";
import * as C from "./BookingsListSearch.constants";
import { messages } from "./BookingsListSearch.messages";
import * as S from "./BookingsListSearch.styles";

const BookingsListSearch = ({
  filter,
  onOutsideClick,
  bucket,
}: C.BookingsListSearchProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [inputValue, setInputValue] = useState("");

  const defaultOptions = useMemo(
    () =>
      bucket?.bucketItems?.map((item) => ({
        value: item.key || item.title,
        title: item.title || item.key,
        count: item.count,
      })),
    [bucket?.bucketItems]
  );

  const currentOptions = useMemo(
    () =>
      defaultOptions?.filter((option) =>
        option.value
          .toLocaleUpperCase()
          .includes(inputValue.toLocaleUpperCase())
      ),
    [defaultOptions, inputValue]
  );

  return (
    <S.FilterAutoCompleteWrapper>
      <Search
        onChange={(value) => {
          setInputValue(value);
        }}
        onReset={() => setInputValue("")}
      />

      {currentOptions && currentOptions.length ? (
        <S.StyledSimpleBar>
          {currentOptions.map((option) => {
            return (
              <S.StyledCustomOption
                key={option.value}
                label={option.title}
                data={option}
                selectProps={{
                  inputValue,
                }}
                onClick={() => {
                  dispatch(setBookingsListFilter(filter, option.value));
                  onOutsideClick();
                }}
              />
            );
          })}
        </S.StyledSimpleBar>
      ) : (
        inputValue && (
          <S.NoMatch> {intl.formatMessage(messages.noMatch)} </S.NoMatch>
        )
      )}
    </S.FilterAutoCompleteWrapper>
  );
};

export default BookingsListSearch;
