import { Country } from "constants/masterDataManagement";
import { UserAddressBook } from "components/organisms/AddressBook/AddressBook.constants";

export interface AddressBookProps {
  countryList: Country[];
  isAddressBookModalOpen: boolean;
  countriesPossibleToSelect?: string[];
  fieldPrefix?: string;
  closeAddressBookModal: () => void;
  openAddressBookModal?: () => void;
  onAddressConfirm?: (confirmedRow: UserAddressBook) => void;
  shouldDisplayOpenAddressBookModalButton?: boolean;
}

export enum TableAction {
  FetchAll = "FetchAll",
  FetchPart = "FetchPart",
  Clear = "Clear",
}
