import gql from "graphql-tag";

const QUOTE_ALERTS = gql`
  query QuoteAlertsListQuery($locale: String!, $service: String!) {
    getQuoteAlerts(locale: $locale, service: $service) {
      id
      text
      title
      url
    }
  }
`;

export default QUOTE_ALERTS;
