export const cdzColumnToFilter = {
  CustomsClearanceNumber: "jobReference",
  HouseBill: "housebill",
  Status: "statusCode",
  StatusDateTime: "statusDateTime",
  Reference: "references",
};

export const filterMappingType = {
  jobReference: "CONTAINS",
  housebill: "CONTAINS",
  statusCode: "EQUAL",
  statusDateTime: "DATERANGE",
  references: "CONTAINS",
};

export const cdzColumns = [
  "HouseBill",
  "CustomsClearanceNumber",
  "Reference",
  "Status",
  "StatusDateTime",
  "Shipper",
  "Consignee",
  "OriginCountry",
  "DestinationCountry",
  "Origin",
  "Destination",
  "PortOfUnloading",
  "MasterBill",
  "TotalPackages",
  "TotalWeight",
  "TotalChargeableWeight",
  "TotalVolume",
  "ProductType",
  "TransportMode",
  "GoodsDescription",
  "CreationDate",
  "PortOfLoading",
  "PlaceOfLoading",
  "CountryOfLoading",
  "PlaceOfUnloading",
  "CountryOfUnloading",
  "CustomsEntry",
  "DeclarationType",
  "ResponsibleCustomsOffice",
  "ClearanceDate",
  "Forwarder",
];

export const columnToExport = {
  HouseBill: { name: "HOUSEBILL" },
  Shipper: { name: "SHIPPER" },
  Consignee: { name: "CONSIGNEE" },
  OriginCountry: { name: "ORIGIN_COUNTRY" },
  Origin: { name: "ORIGIN" },
  DestinationCountry: { name: "DESTINATION_COUNTRY" },
  Destination: { name: "DESTINATION" },
  PortOfUnloading: { name: "PORT_OF_UNLOADING" },
  Status: { name: "STATUS" },
  StatusDateTime: { name: "STATUS_DATE_TIME" },
  MasterBill: { name: "MASTERBILL" },
  Reference: { name: "REFERENCES" },
  TotalPackages: { name: "TOTAL_PACKAGES" },
  TotalWeight: { name: "TOTAL_WEIGHT" },
  TotalChargeableWeight: { name: "TOTAL_CHARGEABLE_WEIGHT" },
  TotalVolume: { name: "TOTAL_VOLUME" },
  ProductType: { name: "PRODUCT_TYPE" },
  TransportMode: { name: "TRANSPORT_MODE" },
  GoodsDescription: { name: "GOODS_DESCRIPTION" },
  CreationDate: { name: "CREATION_DATE" },
  PortOfLoading: { name: "PORT_OF_LOADING" },
  PlaceOfLoading: { name: "PLACE_OF_LOADING" },
  CountryOfLoading: { name: "COUNTRY_OF_LOADING" },
  PlaceOfUnloading: { name: "PLACE_OF_UNLOADING" },
  CountryOfUnloading: { name: "COUNTRY_OF_UNLOADING" },
  CustomsClearanceNumber: { name: "CUSTOMS_CLEARANCE_NUMBER" },
  CustomsEntry: { name: "CUSTOMS_ENTRY" },
  DeclarationType: { name: "DECLARATION_TYPE" },
  ResponsibleCustomsOffice: { name: "RESPONSIBLE_CUSTOMS_OFFICE" },
  ClearanceDate: { name: "CLEARANCE_DATE" },
  Forwarder: { name: "FORWARDER" },
};

export const castFilterToArray = (filter) => {
  return Object.keys(filter).map((key) => ({
    key: key,
    value: filter[key],
    type: filterMappingType[key],
  }));
};
