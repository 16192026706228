import React, { useEffect } from "react";
import { Color } from "react-lib";
import SVGWrapper from "react-lib/es/atoms/SVGWrapper";
import SvgCancel from "react-lib/es/icons/SvgCancel";
import SvgCheckmarkCircle from "react-lib/es/icons/SvgCheckmarkCircle";
import * as C from "./AddressBookSuccessToast.constants";
import * as S from "./AddressBookSuccessToast.styles";

const AddressBookSuccessToast = ({
  message,
  hideToastMessage,
  successfulAction,
}: C.AddressBookSuccessToastMessageProps) => {
  useEffect(() => {
    setTimeout(() => {
      hideToastMessage();
    }, C.autoHideInterval);
  }, [hideToastMessage]);

  return (
    <S.StyledBar>
      <S.StyledWrapper
        isDeleteAction={
          successfulAction ===
          C.AddressBookSuccessfulAction.DELETE_ADDRESS_SUCCESS
        }
        data-testid="ab_SuccessfulAction_Toast"
      >
        <SVGWrapper
          fill={Color.white}
          hoverFill={Color.white}
          height="3rem"
          width="3rem"
        >
          <SvgCheckmarkCircle />
        </SVGWrapper>
        <S.StyledBlackBoxStickyContent>{message}</S.StyledBlackBoxStickyContent>
        <SVGWrapper
          showPointer
          fill={Color.white}
          hoverFill={Color.white}
          onClick={hideToastMessage}
        >
          <SvgCancel />
        </SVGWrapper>
      </S.StyledWrapper>
    </S.StyledBar>
  );
};

export default AddressBookSuccessToast;
