import get from "lodash/get";

const mapping = {
  es_419: "es",
  zh: "zh-cn",
  zh_Hant: "zh-cn",
  nl_BE: "nl-be",
  no: "nb",
};

export const langCodeToMomentCode = (lang) => get(mapping, lang, lang);
