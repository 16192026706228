import { useQuery } from "@apollo/client";
import countryThreeCharCode from "data/countryThreeCharCode";
import cloneDeep from "lodash/cloneDeep";
import QUOTE_COUNTRIES_LIST from "queries/QnB/getQuoteCountriesList";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

export interface QuoteCountry {
  code3: string;
  isBilling: boolean;
  isDestination: boolean;
  isOrigin: boolean;
  label: string;
  originalLabel: string;
  preferredAddressInputType: string;
  value: string;
  _id: string;
  __typename: "QuoteCountry";
}

const emptyList: QuoteCountry[] = [];

export const useQuoteCountryOptions = () => {
  const intl = useIntl();
  const { data } = useQuery(QUOTE_COUNTRIES_LIST, {
    errorPolicy: "all",
    variables: {
      locale: intl.locale,
    },
    fetchPolicy: "cache-and-network",
  });

  const items = data?.getQuoteCountries || emptyList;

  const mapFunc = useCallback(
    (d) => ({
      ...d,
      label: `${d.label} (${d.value === "DX" ? "AE" : d.value})`,
      code3: countryThreeCharCode[d.value as keyof typeof countryThreeCharCode],
      originalLabel: d.label,
    }),
    []
  );

  const sortFunc = useCallback((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    if (a.label === b.label) {
      return 0;
    }
  }, []);

  return useMemo(
    () => cloneDeep(items).sort(sortFunc).map(mapFunc),
    [items, sortFunc, mapFunc]
  );
};
