import { useLanguage } from "containers/Translations/Translations.context";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getIframeSrcAndMashupLink } from "routes/Analytics/helper";

const AnalyticsIframeTab = ({ data, setMashUpLink, setMashUpName }) => {
  const language = useLanguage();
  const uid = useSelector((state) => state.userSettings.visibilityScope.uuid);
  const { src, link } = getIframeSrcAndMashupLink(data, uid, language);

  useEffect(() => {
    setMashUpLink(link);
    setMashUpName(data.text);
  }, [link, data.text, setMashUpLink, setMashUpName]);

  return (
    <div>
      <iframe
        frameBorder={0}
        src={src}
        title={data.text}
        width="100%"
        height={`${Math.max(window.innerHeight - 230, 420)}px`}
        data-testid="analytics-iframe"
      />
    </div>
  );
};

export default AnalyticsIframeTab;
