import { TYPES } from "react-lib/es/atoms/SnackBar/constants";

export interface ToastNotificationState {
  message?: string;
  icon?: React.ReactNode;
  isClosable?: boolean;
  shouldAutoHide?: boolean;
  type?: typeof TYPES;
}

export type ToastNotificationAction =
  | {
      type: ToastNotificationActionType.SHOW_NOTIFICATION;
      notificationPayload: ToastNotificationState;
    }
  | {
      type: ToastNotificationActionType.HIDE_NOTIFICATION;
    };

export const initialState: ToastNotificationState = {
  shouldAutoHide: true,
};

export enum ToastNotificationActionType {
  SHOW_NOTIFICATION = "NOTIFICATION/SHOW_NOTIFICATION",
  HIDE_NOTIFICATION = "NOTIFICATION/HIDE_NOTIFICATION",
}
