import getConfigClient, { FeatureFlagName } from "constants/getConfigClient";
import { AccountInteractions } from "adobe-analytics/content-interaction-events/account/AccountEvents.constants";
import { dispatchInteractionEvent } from "adobe-analytics/content-interaction-events/ContentInteractionEvents.helpers";
import { useFeedbackLink } from "hooks/useFeedbackLink";
import { IntlShape, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getReadOnly } from "redux/userSettings/selectors";
import { MenuItem, MenuItemKey } from "./UserAvatarSection.constants";
import { messages } from "./UserAvatarSection.messages";

export const getMenuItems = (
  intl: IntlShape,
  feedbackLink: string,
  isUserInternal: boolean,
  isReadOnly: boolean,
  setIsChangePasswordModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAddressBookModalOpen: React.Dispatch<React.SetStateAction<boolean>>
): Array<Array<MenuItem>> => {
  const firstSection = [
    {
      key: MenuItemKey.ACCOUNT,
      route: "/settings/account",
      text: intl.formatMessage(messages[MenuItemKey.ACCOUNT]) || "Account",
    },
    ...(!isReadOnly
      ? [
          {
            key: MenuItemKey.NOTIFICATIONS,
            route: "/settings/notifications",
            text:
              intl.formatMessage(messages[MenuItemKey.NOTIFICATIONS]) ||
              "Notifications",
          },
        ]
      : []),
    {
      key: MenuItemKey.PORTAL_SETTINGS,
      route: "/settings/portal",
      text:
        intl.formatMessage(messages[MenuItemKey.PORTAL_SETTINGS]) ||
        "Portal Settings",
    },
  ];

  const secondSection = [
    {
      key: MenuItemKey.ADDRESS_BOOK,
      text:
        intl.formatMessage(messages[MenuItemKey.ADDRESS_BOOK]) ||
        "Address Book",
      onClick: () => setIsAddressBookModalOpen(true),
    },
  ];

  const thirdSection = [
    {
      key: MenuItemKey.FEEDBACK,
      route: feedbackLink,
      text:
        intl.formatMessage(messages[MenuItemKey.FEEDBACK]) ||
        "Give us feedback",
      trackCode: 0,
      shouldOpenInNewTab: true,
    },
    {
      key: MenuItemKey.CONTACT_US,
      route: getConfigClient("CONTACT_URL"),
      text:
        intl.formatMessage(messages[MenuItemKey.CONTACT_US]) || "Contact Us",
      trackCode: 8,
      shouldOpenInNewTab: true,
    },
  ];

  const fourthSection = [
    {
      key: MenuItemKey.LOGOUT,
      route: "/logout",
      text: intl.formatMessage(messages[MenuItemKey.LOGOUT]) || "Logout",
      trackCode: 103,
      onClick: () => {
        dispatchInteractionEvent({ eventName: AccountInteractions.LOG_OUT });
      },
    },
    ...(isUserInternal
      ? []
      : [
          {
            key: MenuItemKey.CHANGE_PASSWORD,
            text:
              intl.formatMessage(messages[MenuItemKey.CHANGE_PASSWORD]) ||
              "Change Password",
            onClick: () => setIsChangePasswordModalOpen(true),
          },
        ]),
  ];

  return isReadOnly
    ? [firstSection, thirdSection, fourthSection]
    : [firstSection, secondSection, thirdSection, fourthSection];
};

const filterOutAddressBookSection = (menuItems: MenuItem[][]) => {
  const filteredMenuItems = menuItems.filter((section) =>
    section.every((item) => item.key !== MenuItemKey.ADDRESS_BOOK)
  );
  return filteredMenuItems;
};

export const useMenuItems = (
  isUserInternal: boolean,
  setIsChangePasswordModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAddressBookModalOpen: React.Dispatch<React.SetStateAction<boolean>>
): MenuItem[][] => {
  const intl = useIntl();
  const feedbackLink = useFeedbackLink() ?? "";
  const isReadOnly = useSelector(getReadOnly);
  const menuItems = getMenuItems(
    intl,
    feedbackLink,
    isUserInternal,
    isReadOnly,
    setIsChangePasswordModalOpen,
    setIsAddressBookModalOpen
  );
  const shouldDisplayAddressBookSection = getConfigClient(
    FeatureFlagName.ADDRESS_BOOK
  );

  return shouldDisplayAddressBookSection
    ? menuItems
    : filterOutAddressBookSection(menuItems);
};
