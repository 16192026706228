import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "bookingStandalone.addressBook.modal.title",
    defaultMessage: "Address Book",
  },
  newAddress: {
    id: "bookingStandalone.addressBook.modal.newAddress",
    defaultMessage: "New Address",
  },
  edit: {
    id: "bookingStandalone.addressBook.modal.edit",
    defaultMessage: "Edit",
  },
  delete: {
    id: "bookingStandalone.addressBook.modal.delete",
    defaultMessage: "Delete",
  },
  importAddresses: {
    id: "bookingStandalone.addressBook.modal.importAddresses",
    defaultMessage: "Import Addresses",
  },
  exportList: {
    id: "bookingStandalone.addressBook.modal.exportList",
    defaultMessage: "Export List",
  },
  company: {
    id: "bookingStandalone.addressBook.table.columns.company",
    defaultMessage: "Company",
  },
  address: {
    id: "bookingStandalone.addressBook.table.columns.address",
    defaultMessage: "Address",
  },
  country: {
    id: "bookingStandalone.addressBook.table.columns.country",
    defaultMessage: "Country/Region",
  },
  referenceId: {
    id: "bookingStandalone.addressBook.table.columns.referenceId",
    defaultMessage: "Reference ID",
  },
  isBilling: {
    id: "bookingStandalone.addressBook.isBilling",
    defaultMessage: "Billing",
  },
  isConsignee: {
    id: "bookingStandalone.addressBook.isConsignee",
    defaultMessage: "Consignee",
  },
  isDelivery: {
    id: "bookingStandalone.addressBook.isDelivery",
    defaultMessage: "Delivery",
  },
  isPickup: {
    id: "bookingStandalone.addressBook.isPickup",
    defaultMessage: "Pickup",
  },
  isShipper: {
    id: "bookingStandalone.addressBook.isShipper",
    defaultMessage: "Shipper",
  },
  isNotify: {
    id: "bookingStandalone.addressBook.isNotify",
    defaultMessage: "Notify party",
  },
  close: {
    id: "bookingStandalone.addressBook.close",
    defaultMessage: "Close",
  },
  filter: {
    id: "bookingStandalone.addressBook.filter",
    defaultMessage: "Filter",
  },
  enterCompanyName: {
    id: "bookingStandalone.addressBook.enterCompanyName",
    defaultMessage: "Enter company name",
  },
  noTagsFound: {
    id: "bookingStandalone.addressBook.noTagsFound",
    defaultMessage: "No tags found for this filter yet, enter one!",
  },
  apply: {
    id: "bookingStandalone.addressBook.apply",
    defaultMessage: "Apply",
  },
  enterAddress: {
    id: "bookingStandalone.addressBook.enterAddress",
    defaultMessage: "Enter address",
  },
  clear: {
    id: "bookingStandalone.addressBook.clear",
    defaultMessage: "Clear",
  },
  noResult: {
    id: "bookingStandalone.addressBook.noResult",
    defaultMessage: "No result",
  },
  noFilterSelected: {
    id: "bookingStandalone.addressBook.noFilterSelected",
    defaultMessage: "No filter selected",
  },
  searchFor: {
    id: "bookingStandalone.addressBook.searchFor",
    defaultMessage: "Search for...",
  },
  enterReference: {
    id: "bookingStandalone.addressBook.enterReference",
    defaultMessage: "Enter reference id",
  },

  wrongCountryForOrigin: {
    id: "bookingStandalone.addressBook.wrongCountryForOrigin",
    defaultMessage: "Based on selected address, country is invalid for origin.",
  },
  wrongCountryForDestination: {
    id: "bookingStandalone.addressBook.wrongCountryForDestination",
    defaultMessage:
      "Based on selected address, country is invalid for destination.",
  },
  wrongCountryForBilling: {
    id: "bookingStandalone.addressBook.wrongCountryForBilling",
    defaultMessage:
      "Based on selected address, country is invalid for billing.",
  },
  originCantBeTheSameAsDestination: {
    id: "bookingStandalone.addressBook.originCantBeTheSameAsDestination",
    defaultMessage: "Origin country cannot be same as destination country",
  },
  destinationCantBeTheSameAsOrigin: {
    id: "bookingStandalone.addressBook.destinationCantBeTheSameAsOrigin",
    defaultMessage: "Destination country cannot be same as origin country",
  },
});
