export const initialCenter = {
  lat: 20.209666742166487,
  lng: 19.304687500000014,
};

export const zoomLevelShipmentList = 5;
export const zoomLevelDetailShipment = 4;

export const appDefaultZoom = window?.matchMedia?.("(min-width: 1600px)")
  ?.matches
  ? 3
  : window?.matchMedia?.("(min-width: 768px)")?.matches
  ? 2
  : 1;
export const appMinZoom = window?.matchMedia?.("(min-width: 1600px)")?.matches
  ? 3
  : window?.matchMedia?.("(min-width: 768px)")?.matches
  ? 2
  : 1;
export const appMaxZoom = 18;

export const MapProviders = {
  GOOGLE: "GOOGLE",
  HERE: "HERE",
};
