import PropTypes from "prop-types";
import styled from "styled-components";
import { getRem } from "utils/css";

const SideBarItemsWrapper = styled.div`
  position: relative;
  margin-top: ${getRem(30)};
  margin-bottom: ${getRem(16)};
  a {
    cursor: pointer;
  }
`;

SideBarItemsWrapper.propTypes = {
  mobile: PropTypes.bool,
  isLandscape: PropTypes.bool,
};

export default SideBarItemsWrapper;
