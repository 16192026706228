import { Booking } from "constants/bookWorkflow";
import getConfigClient, { FeatureFlagName } from "constants/getConfigClient";
import { useQuery } from "@apollo/client";
import removeGraphQLTypename from "helpers/removeGraphQLTypename";
import { GET_BOOKING_BY_ID } from "queries/AAA/bookWorkflow";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getCorporatePartner } from "redux/userSettings/selectors";

interface UseBookWorkflowDataOptionsExtended {
  onCompleted?: (data: { booking: Booking }) => void;
  bookingId?: string;
}

export const useBookingDataById = ({
  onCompleted,
  bookingId,
}: UseBookWorkflowDataOptionsExtended): Partial<
  OmitGraphQLTypename<Booking>
> => {
  const corporatePartner = useSelector(getCorporatePartner);

  const addressBookFeatureFlag = getConfigClient(FeatureFlagName.ADDRESS_BOOK);
  const { data } = useQuery<{ booking: Booking }>(
    GET_BOOKING_BY_ID(addressBookFeatureFlag),
    {
      context: {
        headers: {
          CorporatePartner: corporatePartner,
        },
      },
      variables: {
        id: bookingId,
      },
      skip: !bookingId || !corporatePartner,
      onCompleted,
    }
  );

  return useMemo(
    () => removeGraphQLTypename<Partial<Booking>>(data?.booking),
    [data?.booking]
  );
};
