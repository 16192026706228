import { Location } from "history";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import {
  UserStatus,
  LocationObject,
  FindingMethod,
  LocationState,
} from "../constants";
import { getUserType } from "../helpers";
import * as C from "./constants";

const useProductData = (): C.Product => {
  const selectedOffer = useSelector(
    (state: RootState) => state.quoteData?.selectedOffer
  );

  const consignment = useSelector(
    (state: RootState) => state.quoteAndBookNonSync.consignment
  );

  return {
    price: selectedOffer?.price?.value || consignment?.charges?.total,
    id: selectedOffer.serviceType || consignment?.offerInfo?.serviceType,
    quantity: 1 as const,
    currency: selectedOffer?.price?.currency || consignment?.currency,
    shippingTerms: selectedOffer?.serviceSettings?.shippingTerms,
    cargoType: selectedOffer?.cargoDetails?.cargoType,
    totalPrice: consignment?.charges?.total,
    mainCarriage: consignment?.charges?.mainCarriage,
  };
};

const getOptionIdForOffer = (location: Location<LocationState>) =>
  (location?.pathname?.includes("offers") &&
    location?.pathname?.match(/\d/)?.[0]) ||
  undefined;

export const useQuoteAndBookDataLayerObject = (
  isLoggedIn: boolean,
  loggedInUserEmail: string,
  language: string,
  location: LocationObject
) => {
  const quoteAndBookNonSync = useSelector(
    (state: RootState) => state.quoteAndBookNonSync
  );
  const quoteData = useSelector((state: RootState) => state.quoteData);
  const product = useProductData();

  return {
    user: {
      status: isLoggedIn ? UserStatus.LOGGED_IN : UserStatus.LOGGED_OUT,
      type: getUserType(isLoggedIn, loggedInUserEmail),
    },
    page: {
      title: document.title,
      country: "Global",
      language,
      finding_method:
        location.state?.analyticsFindingMethodMenu || FindingMethod.NONE,
      publisher: "DSC",
      section: "Quote & Book",
      category: "Sales",
      name: C.quoteAndBookPathsNames[location.pathname as C.QuoteAndBookPaths],
      funnel: {
        type: "Sales",
        name: "Quote & Book",
        step: C.quoteAndBookPathsNames[
          location.pathname as C.QuoteAndBookPaths
        ],
        progress: `${
          C.quoteAndBookPaths.indexOf(
            location.pathname as C.QuoteAndBookPaths
          ) + 1
        } of ${C.quoteAndBookPaths.length}`,
      },
      attributes: {
        quote_id: quoteAndBookNonSync?.consignment?.consignmentUuid,
        consignmentId: quoteAndBookNonSync?.consignment?.consignmentId,
        offer_price: product?.mainCarriage || product?.price,
        currency: product?.currency,
        language: language,
        deliveryMode: product?.shippingTerms,
        option_id: getOptionIdForOffer(location),
        cargoType: product?.cargoType,
        total_price: product?.totalPrice,
        from:
          ("route" in quoteData?.quote &&
            quoteData?.quote?.route?.origin?.country?.value) ||
          undefined,
        to:
          ("route" in quoteData?.quote &&
            quoteData?.quote?.route?.destination?.country?.value) ||
          undefined,
        billing_country:
          ("billingCountry" in quoteData?.quote &&
            quoteData?.quote?.billingCountry) ||
          undefined,
      },
      ...(product.id && {
        products: [
          {
            id: product?.id,
            price: product?.price,
            quantity: product?.quantity,
            currency: product?.currency,
          },
        ],
      }),
    },
  };
};

export const isQuoteAndBookPath = (pathname: string) =>
  C.quoteAndBookPaths.includes(pathname as C.QuoteAndBookPaths);
