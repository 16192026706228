import codeToCountry from "constants/codeToCountry";
import includes from "lodash/includes";
import trim from "lodash/trim";

const cleanupAddressLine = (line, zipCode, city, countryCode) => {
  let addressLineComponents = (line || "").split(",");
  addressLineComponents = addressLineComponents.filter((item) => {
    return !(
      includes(item, zipCode) ||
      trim(item, ", ") === city ||
      (codeToCountry[countryCode] || []).includes(trim(item, ", "))
    );
  });
  return trim(addressLineComponents.join(","), ", ");
};

export default cleanupAddressLine;
