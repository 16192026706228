import StreetAddress from "components/organisms/StreetAddress/StreetAddress";
import React from "react";
import { Field, useForm } from "react-final-form";
import { useIntl } from "react-intl";
import {
  ButtonVariants,
  ButtonTypes,
} from "react-lib/es/atoms/buttons2/Button.constants";
import { Sizes } from "react-lib/es/atoms/input/constants";
import SvgTrashBinDelete from "react-lib/es/icons/SvgTrashBinDelete";
import * as C from "./AddressBookFormFields.constants";
import messages from "./AddressBookFormFields.messages";
import * as S from "./AddressBookFormFields.styles";
import AddressTypePicker from "./AddressTypePicker/AddressTypePicker";

const AddressBookFormFields = ({
  formHeader,
  handleSubmit,
  onCancel,
  onDelete,
  isDisabled,
  isDeletable,
}: C.AdjustAddressFormProps) => {
  const intl = useIntl();
  const form = useForm();

  const areRequiredFieldsFilled = () => form.getState().valid;

  return (
    <>
      <S.StyledForm onSubmit={handleSubmit}>
        <S.AddressWrapper>
          <S.AddressModalHeader>{formHeader}</S.AddressModalHeader>
          <S.AddressAndContactDetailsForm>
            <S.AddressAndContactDetailsFormSection>
              <StreetAddress
                shouldRenderSearch
                fieldPrefix="addressBook"
                isForAddressBook
                extraFields={[
                  <Field
                    name={C.formFields.reference}
                    key={C.formFields.reference}
                  >
                    {({ input }) => (
                      <S.StyledInput
                        size={Sizes.MEDIUM}
                        inputProps={{
                          ...input,
                          onBlur: () => {
                            input.onChange(input.value?.trim?.());
                            input.onBlur();
                          },
                        }}
                        label={intl.formatMessage(messages.referenceID)}
                      />
                    )}
                  </Field>,
                ]}
              />
            </S.AddressAndContactDetailsFormSection>
          </S.AddressAndContactDetailsForm>
          <AddressTypePicker />
        </S.AddressWrapper>
      </S.StyledForm>
      <S.Buttons>
        <S.DeleteButton onClick={onDelete} isVisible={isDeletable}>
          <SvgTrashBinDelete />
        </S.DeleteButton>
        <span>
          <S.CancelButton
            disabled={isDisabled}
            variant={ButtonVariants.GHOST_RED}
            onClick={onCancel}
          >
            {intl.formatMessage(messages.cancel)}
          </S.CancelButton>

          <S.SaveAddressButton
            onClick={handleSubmit}
            disabled={isDisabled || !areRequiredFieldsFilled()}
            type={ButtonTypes.SUBMIT}
          >
            {intl.formatMessage(messages.saveAddress)}
          </S.SaveAddressButton>
        </span>
      </S.Buttons>
    </>
  );
};

export default AddressBookFormFields;
