import { UnitSystem } from "components/templates/GetAQuote/constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorporatePartners } from "redux/userSettings/actions";
import get from "utils/get";

const defaultQuoteObject = {
  unitSystem: UnitSystem.METRIC,
  route: {
    origin: { type: "company" },
    destination: { type: "company" },
  },
  cargo: { specialHandling: { special: "GEN" } },
};

export const getQuoteFromStorage = () => {
  try {
    const quoteFromStorage = get(
      JSON.parse(sessionStorage.getItem("quoteData")),
      "quoteData.quote",
      {}
    );
    return { ...defaultQuoteObject, ...quoteFromStorage };
  } catch (error) {
    // console.log("error");
    return defaultQuoteObject;
  }
};

export const useGetConsignmentFromStorage = () =>
  useSelector((state) => state.quoteAndBookNonSync.consignment);

export const useCheckAndGetCorporatePartners = () => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector((state) => state.auth?.isUserLoggedIn);
  const isDemo = useSelector(
    (state) => state.userSettings.visibilityScope?.demo
  );
  const hasCurrentUserRoles = useSelector(
    (state) => state.auth.currentUserRoles?.length > 0
  );
  useEffect(() => {
    if (isUserLoggedIn && !hasCurrentUserRoles) {
      dispatch(
        getCorporatePartners({
          isDemoMode: isDemo === undefined ? true : isDemo,
        })
      );
    }
    // eslint-disable-next-line
  }, [isUserLoggedIn, hasCurrentUserRoles]);
};

export const useCheckHasReportingRole = () =>
  useSelector((state) => state.auth?.doesCurrentUserHaveReportingRole);
