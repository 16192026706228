import * as C from "./ContentInteractionEvents.constants";

export const dispatchInteractionEvent = (
  props: C.DispatchInteractionEventProps | C.EventConfig | undefined
): void => {
  if (!props) return;

  const eventName = "eventName" in props ? props.eventName.id : props.id;
  const position = "position" in props ? props.position : undefined;
  const eventNameDetails =
    "eventNameDetails" in props ? props.eventNameDetails : undefined;
  const eventType =
    "interactionType" in props && props.interactionType
      ? props.interactionType
      : C.contentInteractionEvent;

  const eventContent: C.EventContentLex =
    C.eventNameToEventContentMapper[eventName as keyof C.EventName];

  let eventContentObj = Object.assign(eventContent);

  if (position !== undefined && position >= 0) {
    eventContentObj = {
      ...eventContentObj,
      position: String(position + 1),
    };
  }

  if (eventNameDetails) {
    eventContentObj = {
      ...eventContentObj,
      name: `${eventContentObj.name} ${eventNameDetails}`,
    };
  }

  const event = new CustomEvent(eventType, {
    bubbles: true,
    detail: {
      eventContentObj,
    },
  });

  window.dispatchEvent(event);
};
