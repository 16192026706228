import { Country } from "constants/masterDataManagement";
import { UserAddressBook } from "components/organisms/AddressBook/AddressBook.constants";
import { AddressBookFormSchema } from "../components/AddressBookModalContent/AddressBookForm/AddressBookFormFields/AddressBookFormFields.constants";

export const parseListValueToFormSchema = (
  formValues: UserAddressBook,
  countryList: Country[]
): AddressBookFormSchema => {
  const { address, contact, addressFlags } = formValues;

  const selectedCountry = countryList.find(
    (country) => country.countryCode === address.countryCode
  );

  return {
    addressBook: {
      address: {
        country: {
          code: selectedCountry?.code || "",
          countryCode: selectedCountry?.countryCode || "",
          label: selectedCountry?.label || "",
          value: selectedCountry?.value || "",
        },
        streetAddress: address.addressLine1,
        street: address.addressLine2,
        zip: address.postalCode,
        city: address.city,
        states: address.stateProvince,
        preferredAddressInputType: selectedCountry?.addressType,
      },
      contactDetails: {
        companyName: address.companyName,
        contactName: contact.contactName,
        email: contact.email,
        phoneNumber: contact.phone,
        reference: address.reference,
      },
      addressType: addressFlags,
    },
  };
};
