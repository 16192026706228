import gql from "graphql-tag";

export const FAVORITE_ITEM = gql`
  mutation favoriteItem($item: FavoriteItem!) {
    favorite(item: $item)
  }
`;

export const UNFAVORITE_ITEM = gql`
  mutation unFavoriteItem($item: FavoriteItem!) {
    unFavorite(item: $item)
  }
`;
