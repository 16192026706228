import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "ExportQuotesInteractions";

export const ExportQuotesInteractions = {
  CLOSE: { id: `${prefix}_Close`, name: "Close" },
  SELECT_ALL: { id: `${prefix}_Select All`, name: "Select All" },
  FIELD: { id: `${prefix}_Field`, name: "Field" },
  CANCEL: { id: `${prefix}_Cancel`, name: "Cancel" },
  EXPORT: { id: `${prefix}_Export`, name: "Export" },
};

enum ExportQuotesPosition {
  HEADER = "Header",
  FIELD_LIST = "Field List",
  FOOTER = "Footer",
}

const exportQuotesContext = "Export Quotes";

export const exportQuotesEventNameToExportQuotesEventContentMapper: C.EventNameToContentMapperType<
  typeof ExportQuotesInteractions
> = {
  [ExportQuotesInteractions.CLOSE.id]: {
    name: ExportQuotesInteractions.CLOSE.name,
    ...eventContentButtonInterObj,
    position: ExportQuotesPosition.HEADER,
    context: exportQuotesContext,
  },
  [ExportQuotesInteractions.SELECT_ALL.id]: {
    name: ExportQuotesInteractions.SELECT_ALL.name,
    ...eventContentButtonInterObj,
    position: ExportQuotesPosition.FIELD_LIST,
    context: exportQuotesContext,
  },
  [ExportQuotesInteractions.FIELD.id]: {
    name: ExportQuotesInteractions.FIELD.name,
    ...eventContentButtonInterObj,
    position: ExportQuotesPosition.FIELD_LIST,
    context: exportQuotesContext,
  },
  [ExportQuotesInteractions.CANCEL.id]: {
    name: ExportQuotesInteractions.CANCEL.name,
    ...eventContentButtonInterObj,
    position: ExportQuotesPosition.FOOTER,
    context: exportQuotesContext,
  },
  [ExportQuotesInteractions.EXPORT.id]: {
    name: ExportQuotesInteractions.EXPORT.name,
    ...eventContentButtonInterObj,
    position: ExportQuotesPosition.FOOTER,
    context: exportQuotesContext,
  },
};
