import React from "react";
import styled from "styled-components";
import { COOKIE_SETTINGS_LINK_CLASS } from "./constants/cookieSettings";

const Wrapper = styled.div`
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
  left: -9999px;
  top: 0;
`;

const CookieSettingsLink = () => {
  return (
    <Wrapper>
      {/* eslint-disable-next-line */}
      <a id="ot-sdk-btn" className={COOKIE_SETTINGS_LINK_CLASS}>
        Cookie Settings
      </a>
    </Wrapper>
  );
};

export default CookieSettingsLink;
