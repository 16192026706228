import { combineReducers } from "redux";
import app from "./app/reducer";
import auth from "./auth/reducer";
import dashboard from "./dashboard/reducer";
import error from "./error/reducer";
import { makeABookingReducer } from "./makeABooking/reducers";
import modal from "./modal/reducer";
import notification from "./notification/reducers";
import { purchaseOrdersReducer } from "./purchaseOrders/reducers";
import quoteAndBook from "./quoteAndBook/reducer";
import quoteAndBookNonSync from "./quoteAndBookNonSync/reducer";
import quoteData from "./quoteData/reducer";
import rest from "./rest/reducer";
import search from "./search/reducer";
import searchHistory from "./searchHistory/reducer";
import { shipmentsReducer } from "./shipments/reducers";
import sort from "./sort/reducer";
import userSettings from "./userSettings/reducer";

const reducers = combineReducers({
  app,
  rest,
  modal,
  sort,
  search,
  userSettings,
  searchHistory,
  auth,
  dashboard,
  quoteAndBook,
  quoteData,
  quoteAndBookNonSync,
  error,
  makeABooking: makeABookingReducer,
  purchaseOrders: purchaseOrdersReducer,
  shipments: shipmentsReducer,
  notification,
});

export type RootState = ReturnType<typeof reducers>;

export default reducers;
