import gql from "graphql-tag";

const GET_SERVICE_LANGUAGES = gql`
  query GetServiceLanguages {
    platform: getServiceLanguages(service: "MYDHLI") {
      code
      code_aem
      label
      weblate_key
    }
    qnb: getServiceLanguages(service: "OQT") {
      code
      label
      weblate_key
    }
  }
`;

export default GET_SERVICE_LANGUAGES;
