import { Color, SearchInput } from "react-lib";
import { SCREEN_SIZES } from "react-lib/es/utils/css";
import styled from "styled-components";
import InputFieldWithValidation from "../fieldsWithValidation/InputFieldWithValidation/InputFieldWithValidation";

export const StreetAddressWrapper = styled.div`
  display: block;

  @media (min-width: ${SCREEN_SIZES.from.lg}) {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
`;

export const InlineFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    flex-direction: row;
    gap: 1.25rem;

    & > * {
      min-width: 15.375rem;
    }

    & > *:first-child {
      min-width: 9.375rem;
    }

    & > *:last-child {
      flex-grow: 1;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const StyledSearchInput = styled(SearchInput)`
  background-color: ${Color.white};
`;

export const ZipCodeFieldWithValidation = styled(InputFieldWithValidation)`
  flex: 3;
  max-width: none;

  @media (min-width: ${SCREEN_SIZES.from.md}) {
    max-width: 9.375rem;
  }
`;

export const CityFieldWithValidation = styled(InputFieldWithValidation)`
  flex: 10;
`;
