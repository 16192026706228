import { ReactComponent as PalletEUIcon } from "assets/icons/QnB/icon-presets-pallet-eur-1.svg";
import { ReactComponent as BagsIcon } from "assets/icons/QnB/illustrations-chips-cargo-types-bags.svg";
import { ReactComponent as CartonIcon } from "assets/icons/QnB/illustrations-chips-cargo-types-carton.svg";
import { ReactComponent as CratesIcon } from "assets/icons/QnB/illustrations-chips-cargo-types-crates.svg";
import { ReactComponent as DrumIcon } from "assets/icons/QnB/illustrations-chips-cargo-types-drum.svg";
import { ReactComponent as IBCTankIcon } from "assets/icons/QnB/illustrations-chips-cargo-types-ibc-tank.svg";
import { ReactComponent as RollsIcon } from "assets/icons/QnB/illustrations-chips-cargo-types-rolls.svg";
import { ReactComponent as SkeletonBoxIcon } from "assets/icons/QnB/illustrations-chips-cargo-types-skelleton.svg";
import { ReactComponent as WoodenCasesIcon } from "assets/icons/QnB/illustrations-chips-cargo-types-wooden-cases.svg";
import { ReactComponent as Container20 } from "assets/icons/QnB/illustrations-chips-container-ftl-20.svg";
import { ReactComponent as Container40 } from "assets/icons/QnB/illustrations-chips-container-ftl-40.svg";
import { ReactComponent as ContainerHighCube } from "assets/icons/QnB/illustrations-chips-container-ftl-high-cube.svg";

export const QuotePackageTypes = {
  ESTIMATED: "ESTIMATED",
  STANDARD: "STANDARD",
  CONTAINER: "CONTAINER",
  PALLET: "PALLET",
};

export const QuotePackageTypeCodes = {
  ESTIMATE_ONLY: {
    key: "ESTIMATE_ONLY",
    icon: "",
  },
  // Loose Cargo
  CASE: {
    key: "CASE",
    icon: WoodenCasesIcon,
  },
  BAG: {
    key: "BAG",
    icon: BagsIcon,
  },
  PALLET: {
    key: "PALLET",
    icon: PalletEUIcon,
  },
  CARTON: {
    key: "CARTON",
    icon: CartonIcon,
  },
  CRATE: {
    key: "CRATE",
    icon: CratesIcon,
  },
  DRUM: {
    key: "DRUM",
    icon: DrumIcon,
  },
  TANK: {
    key: "TANK",
    icon: IBCTankIcon,
  },
  ROLL: {
    key: "ROLL",
    icon: RollsIcon,
  },
  SKELETON_BOX: {
    key: "SKELETON_BOX",
    icon: SkeletonBoxIcon,
  },
  // FCL
  CTR_STD_20: {
    key: "CTR_STD_20",
    icon: Container20,
  },
  CTR_STD_40: {
    key: "CTR_STD_40",
    icon: Container40,
  },
  CTR_CUBE_40: {
    key: "CTR_CUBE_40",
    icon: ContainerHighCube,
  },
  CTR_CUBE_45: {
    key: "CTR_CUBE_45",
    icon: ContainerHighCube,
  },
  AU_PALLET_STD: {
    key: "PALLET",
    icon: PalletEUIcon,
  },
  AU_PALLET_EXP: {
    key: "PALLET",
    icon: PalletEUIcon,
  },
  COMMON_PALLET_EUR_1: {
    key: "PALLET",
    icon: PalletEUIcon,
  },
  COMMON_PALLET_EUR_3: {
    key: "PALLET",
    icon: PalletEUIcon,
  },
  COMMON_PALLET_EUR_6: {
    key: "PALLET",
    icon: PalletEUIcon,
  },
};

export const PalletsTypesCodes = {
  COMMON_PALLET_EUR_1: {
    key: "COMMON_PALLET_EUR_1",
    icon: "",
  },
  COMMON_PALLET_EUR_3: {
    key: "COMMON_PALLET_EUR_3",
    icon: "",
  },
  COMMON_PALLET_EUR_6: {
    key: "COMMON_PALLET_EUR_6",
    icon: "",
  },
};

export const UnitSystem = {
  METRIC: "METRIC",
  IMPERIAL: "IMPERIAL",
};
