import * as C from "./constants";

const initialState: C.QuoteAndBookNonSyncState = {
  saveConsignmentAPICount: 0,
  recalculateQuoteAPICount: 0,
  calculateQuoteAPICount: 0,
  saveAndGetConsignmentAPICount: 0,
  recalculatingQuote: false,
  dataChangedCount: 0,
  recalculationErrorAlready: {
    404: false,
    410: false,
  },
  contractId: undefined,
  userCountryViaGeoLocation: undefined,
  isR2R: undefined,
  CPResolved: undefined,
  consignment: {
    offerInfo: {},
  },
  previousRatesType: undefined,
  isGetOffersLoading: false,
};

const reducer = (
  state = initialState,
  action: C.QuoteAndBookNonSyncActions
): C.QuoteAndBookNonSyncState => {
  switch (action.type) {
    case C.ActionsType.INCREMENT_SAVE_CONSIGNMENT_API_COUNT:
      return {
        ...state,
        saveConsignmentAPICount: state.saveConsignmentAPICount + 1,
      };
    case C.ActionsType.INCREMENT_RECALCULATE_QUOTE_API_COUNT:
      return {
        ...state,
        recalculateQuoteAPICount: state.recalculateQuoteAPICount + 1,
      };
    case C.ActionsType.INCREMENT_CALCULATE_QUOTE_API_COUNT:
      return {
        ...state,
        calculateQuoteAPICount: state.calculateQuoteAPICount + 1,
      };
    case C.ActionsType.SET_RECALCULATING_QUOTE:
      return {
        ...state,
        recalculatingQuote: action.payload,
      };
    case C.ActionsType.SET_CONTRACT_ID:
      return {
        ...state,
        contractId: action.payload.contractId,
      };
    case C.ActionsType.INCREMENT_DATA_CHANGED_COUNT:
      return {
        ...state,
        dataChangedCount: state.dataChangedCount + 1,
      };
    case C.ActionsType.INCREMENT_GET_CONSIGNMENT_API_COUNT:
      return {
        ...state,
        saveAndGetConsignmentAPICount: state.saveAndGetConsignmentAPICount + 1,
      };
    case C.ActionsType.SET_RECALCULATION_ERROR:
      return {
        ...state,
        recalculationErrorAlready: {
          ...state.recalculationErrorAlready,
          [action.payload.code]: action.payload.value,
        },
      };
    case C.ActionsType.RESET_RECALCULATION_ERROR: {
      const cleanedRecalculationErrorAlready = Object.keys(
        state.recalculationErrorAlready
      ).reduce((prev, curr) => ({ ...prev, [curr]: false }), {});
      return {
        ...state,
        recalculationErrorAlready: cleanedRecalculationErrorAlready as {
          404: boolean;
          410: boolean;
        },
      };
    }
    case C.ActionsType.SET_USER_COUNTRY_VIA_GEO_LOCATION:
      return {
        ...state,
        userCountryViaGeoLocation: action.payload,
      };
    case C.ActionsType.SET_IS_R2R:
      return {
        ...state,
        isR2R: action.payload,
      };
    case C.ActionsType.SET_CP_RESOLVED:
      return {
        ...state,
        CPResolved: action.payload,
      };
    case C.ActionsType.SAVE_CONSIGNMENT_FORM:
      return {
        ...state,
        consignment: action.payload,
        previousRatesType: state?.consignment?.offerInfo?.ratesType,
      };
    case C.ActionsType.RESET_CONSIGNMENT_DATA:
      return {
        ...state,
        consignment: initialState.consignment,
        previousRatesType: initialState.previousRatesType,
      };
    case C.ActionsType.SET_GET_OFFERS_LOADING:
      return {
        ...state,
        isGetOffersLoading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
