import types from "./action-types";

export const putAction = (endPoint, data, key, next = null) => ({
  type: types.put,
  payload: { endPoint, data, key, next },
});

export const putSuccess = (data, key) => ({
  type: types.putSuccess,
  payload: { data, key },
});

export const putError = (data, key) => ({
  type: types.putError,
  payload: { data, key },
});

export const getAction = (endPoint, data, key, next = null) => ({
  type: types.get,
  payload: { endPoint, data, key, next },
});

export const getSuccess = (data, key) => ({
  type: types.getSuccess,
  payload: { data, key },
});

export const getError = (data, key) => ({
  type: types.getError,
  payload: { data, key },
});

export const pageAction = (endPoint, data, key, merger, next = null) => ({
  type: types.page,
  payload: { endPoint, data, key, next },
  merger: merger,
});

export const pageSuccess = (data, key, merger) => ({
  type: types.pageSuccess,
  payload: { data, key },
  merger: merger,
});

export const pageError = (data, key) => ({
  type: types.pageError,
  payload: { data, key },
});

export const postAction = (payload) => ({
  type: types.post,
  payload,
});

export const postActionSuccess = (payload) => ({
  type: types.postSuccess,
  payload,
});

export const postActionError = (payload) => ({
  type: types.postError,
  payload,
});

// Special naming cuz JS has "delete" as a reserved keyword and don't wanna mess with that!
export const deleteAction = (payload) => ({
  type: types.deleteType,
  payload,
});

export const deleteSuccess = (payload) => ({
  type: types.deleteSuccess,
  payload,
});

export const deleteError = (payload) => ({
  type: types.deleteError,
  payload,
});

export const clearData = (payload) => ({
  type: types.clearData,
  payload,
});
