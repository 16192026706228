import { RatesType } from "constants/bookWorkflow";
import { defineMessages, MessageDescriptor } from "react-intl";
import * as C from "./BookingsListRow.constants";

export default defineMessages({
  edit: {
    id: "bookingStandalone.bookingList.bookings.rowAction.edit",
    defaultMessage: "Edit in a new tab",
  },
  delete: {
    id: "bookingStandalone.bookingList.bookings.rowAction.delete",
    defaultMessage: "Delete",
  },
  share: {
    id: "bookingStandalone.bookingList.bookings.rowAction.share",
    defaultMessage: "Share",
  },
  rename: {
    id: "bookingStandalone.bookingList.bookings.rowAction.rename",
    defaultMessage: "Rename",
  },
  copy: {
    id: "bookingStandalone.bookingList.bookings.rowAction.copy",
    defaultMessage: "Copy",
  },
  follow: {
    id: "bookingStandalone.bookingList.bookings.rowAction.follow",
    defaultMessage: "Follow",
  },
  unfollow: {
    id: "bookingStandalone.bookingList.bookings.rowAction.unfollow",
    defaultMessage: "Unfollow",
  },
  printLabel: {
    id: "bookingStandalone.bookingList.bookings.rowAction.printLabel",
    defaultMessage: "Print Label",
  },
  printManifest: {
    id: "bookingStandalone.bookingList.bookings.rowAction.printManifest",
    defaultMessage: "Print Manifest",
  },
  [C.WeightAndVolumeApiUoms.KILOGRAMS]: {
    id: "bookingStandalone.unitSystem.kilograms",
    defaultMessage: "kg",
  },
  [C.WeightAndVolumeApiUoms.POUNDS]: {
    id: "bookingStandalone.unitSystem.pounds",
    defaultMessage: "lb",
  },
  [C.WeightAndVolumeApiUoms.CUBIC_METERS]: {
    id: "bookingStandalone.unitSystem.cubicMeters",
    defaultMessage: "m³",
  },
  [C.WeightAndVolumeApiUoms.CUBIC_FEET]: {
    id: "bookingStandalone.unitSystem.cubicFeet",
    defaultMessage: "ft³",
  },
});

export const ratesTypeMessages: Record<RatesType, MessageDescriptor> =
  defineMessages({
    [RatesType.MARKET]: {
      id: "bookingStandalone.ratesType.MARKET",
      defaultMessage: "Market",
    },
    [RatesType.CONTRACTED]: {
      id: "bookingStandalone.ratesType.CONTRACTED",
      defaultMessage: "Contracted",
    },
  });
