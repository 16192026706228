import actionTypes from "./action-types";

const initialState = {
  selectedCountry: undefined,
  countrySelectedBy: undefined,
  selectedCurrency: undefined,
  currencySelectedBy: undefined,
  quoteType: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload.country,
        countrySelectedBy: action.payload.selectedBy,
      };
    case actionTypes.SET_CURRENCY:
      if (
        state.currencySelectedBy === "USER" &&
        action.payload.selectedBy !== "USER"
      ) {
        return state;
      }
      sessionStorage.setItem("track_currency", action.payload.currency);
      return {
        ...state,
        selectedCurrency: action.payload.currency,
        currencySelectedBy: action.payload.selectedBy,
      };
    case actionTypes.SET_QUOTE_TYPE:
      return {
        ...state,
        quoteType: action.payload.quoteType,
      };
    case actionTypes.SAVE_PACKAGE_TYPES:
      return {
        ...state,
        packageTypes: action.payload.packageTypes,
      };
    case actionTypes.SET_RATES_TYPE:
      return {
        ...state,
        ratesType: action.payload.ratesType,
      };
    default:
      return state;
  }
};

export default reducer;
