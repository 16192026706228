import { useCallback, useLayoutEffect } from "react";
import { updateFilterInList } from "utils/reporting";
import useStateHistory from "./useStateHistory";

const useReportEditor = (report) => {
  const { init, commit, push, current, undo, redo, canUndo, canRedo } =
    useStateHistory();

  useLayoutEffect(() => {
    init({
      sorts: report?.sorts,
      filters: report?.filters,
      columns: (report?.columns || []).map((column) => ({
        name: column.name,
        displayName: column.displayName,
        description: column.description,
        groupName: column.groupName,
      })),
    });
  }, [init, report]);

  const setColumns = useCallback(
    (columns) =>
      push((old) => {
        let { filters } = old;
        const newFilters = filters.filter((filter) =>
          columns.some((col) => col.name === filter.field)
        );
        if (newFilters.length !== filters.length) {
          filters = newFilters;
        }
        return {
          ...old,
          columns,
          filters,
        };
      }),
    [push]
  );

  const setSorts = useCallback(
    (sorts) => push((old) => ({ ...old, sorts })),
    [push]
  );

  const toggleSort = useCallback(
    (column) =>
      push((old) => ({
        ...old,
        sorts: {
          order_by: column,
          order_direction:
            old.sorts?.order_by === column &&
            old.sorts?.order_direction === "ASC"
              ? "DESC"
              : "ASC",
        },
      })),
    [push]
  );

  const setFilters = useCallback(
    (filters) =>
      push((old) => ({
        ...old,
        filters: typeof filters === "function" ? filters(old.filters) : filters,
      })),
    [push]
  );

  const addFilter = useCallback(
    (filter) =>
      push((old) => ({
        ...old,
        filters: [...old.filters, filter],
      })),
    [push]
  );
  const removeFilter = useCallback(
    (filter) =>
      push((old) => ({
        ...old,
        filters: old.filters.filter(({ field }) => field !== filter.field),
      })),
    [push]
  );
  const updateFilter = useCallback(
    (filter, field = filter.field) =>
      push((old) => ({
        ...old,
        filters: updateFilterInList(old.filters, filter, field),
      })),
    [push]
  );

  return {
    commit,
    undo,
    redo,
    canUndo,
    canRedo,
    columns: current.columns,
    setColumns,
    sorts: current.sorts,
    setSorts,
    toggleSort,
    filters: current.filters,
    setFilters,
    addFilter,
    removeFilter,
    updateFilter,
  };
};

export default useReportEditor;
