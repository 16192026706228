import * as C from "./constants";

export const showNotification = (
  notificationPayload: C.ToastNotificationState
): GetAction<
  C.ToastNotificationAction,
  C.ToastNotificationActionType.SHOW_NOTIFICATION
> => ({
  type: C.ToastNotificationActionType.SHOW_NOTIFICATION,
  notificationPayload: {
    ...C.initialState,
    ...notificationPayload,
  },
});

export const hideNotification = (): GetAction<
  C.ToastNotificationAction,
  C.ToastNotificationActionType.HIDE_NOTIFICATION
> => ({
  type: C.ToastNotificationActionType.HIDE_NOTIFICATION,
});
