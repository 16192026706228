import gql from "graphql-tag";

export const EmailLinkQuery = gql`
  query EmailLink($uuid: String) {
    emailLink(uuid: $uuid) {
      link
    }
  }
`;

export const submitNPSSurvey = gql`
  mutation SubmitNPSSurvey($survey: String, $uuid: String) {
    submitNPSSurvey(survey: $survey, uuid: $uuid) {
      userId
      responseData
    }
  }
`;
