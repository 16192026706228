import { Color } from "react-lib";
import styled from "styled-components";

export const FollowedBooking = styled.div`
  display: flex;
  padding: 1rem 1.25rem;
  cursor: pointer;

  &:hover {
    background-color: ${Color.black5};
  }
`;
