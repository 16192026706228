import React, { useContext, useMemo } from "react";
import { Route } from "react-router-dom";
import * as C from "./TestingContext.constants";
import * as H from "./TestingContext.helpers";

const TestingContext = React.createContext<C.TestingContextProps>({
  feature: C.Feature.APP,
});

export const TestingContextProvider = ({
  children,
  feature,
}: React.PropsWithChildren<{ feature: C.Feature }>) => {
  const contextValue = useMemo(() => ({ feature }), [feature]);

  return (
    <TestingContext.Provider value={contextValue}>
      {children}
    </TestingContext.Provider>
  );
};

export const useTestingContext = () => useContext(TestingContext);

export const useGetDataTestId = () => {
  const { feature } = useTestingContext();

  return (uniqueName: string, iterableIdentifier?: string | number) =>
    H.formatDataTestId({
      uniqueName,
      iterableIdentifier,
      feature,
    });
};

export const RouteWithTestingContext = ({
  feature,
  ...rest
}: C.RouteWithTestingContextProps) => {
  return (
    <TestingContextProvider feature={feature}>
      <Route {...rest} />
    </TestingContextProvider>
  );
};

export default TestingContext;
