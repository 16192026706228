import { UnitSystem } from "components/templates/GetAQuote/constants";

export const CargoTypes = {
  QUICK_ESTIMATION: "QUICK_ESTIMATION",
  FCL: "CONTAINER",
  LOOSE_CARGO: "LOOSE",
};

export const HasSpecialHandling = {
  YES: "G-SP",
  NO: "GEN",
};

export const SpecialHandlingStackableTypes = {
  NON_STACKABLE: "non_stackable",
  STACKABLE: "stackable",
  LOADABLE: "loadable",
  NON_LOADABLE: "non_loadable",
};

export const unitOptionsWeight = [
  { label: "kg", value: "kg", unitSystem: UnitSystem.METRIC },
  { label: "lb", value: "lb", unitSystem: UnitSystem.IMPERIAL },
];

export const unitOptionsVolume = [
  { label: "m³", value: "m³", unitSystem: UnitSystem.METRIC },
  { label: "ft³", value: "ft³", unitSystem: UnitSystem.IMPERIAL },
];

export const unitOptionsDimension = [
  { label: "cm", value: "cm", unitSystem: UnitSystem.METRIC },
  { label: "inch", value: "inch", unitSystem: UnitSystem.IMPERIAL },
];

export const unitsVolume = {
  METRIC: "m³",
  IMPERIAL: "ft³",
};

export const unitsWeight = {
  METRIC: "kg",
  IMPERIAL: "lb",
};
