import { RootState } from "redux/reducers";
import { MakeABookingStage } from "routes/MakeABooking/MakeABooking.constants";
import {
  MakeABookingState,
  StageSectionInfo,
  StagesSections,
} from "./constants";

export const getMakeABookingState = (state: RootState): MakeABookingState =>
  state.makeABooking;

export const getStagesVisitedStatus = (
  state: RootState
): Partial<Record<MakeABookingStage, boolean>> =>
  state.makeABooking.stagesVisitedStatus;

export const getStagesValidationStatus = (
  state: RootState
): Partial<Record<MakeABookingStage, boolean>> =>
  state.makeABooking.stagesValidationStatus;

export const getModeStageValidationStatus = (
  state: RootState
): boolean | undefined =>
  state.makeABooking.stagesValidationStatus[MakeABookingStage.MODE];

export const getStagesInvalidSections = (
  state: RootState
): Partial<StagesSections> | undefined =>
  state.makeABooking.areFormsValidationErrorsVisible
    ? state.makeABooking.invalidStagesSections
    : undefined;

export const getStagesInvalidSectionsDespiteErrosVisibility = (
  state: RootState
): Partial<StagesSections> | undefined =>
  state.makeABooking.invalidStagesSections;

export const getPageInvalidSections = (
  state: RootState,
  stage: MakeABookingStage
): StageSectionInfo[] | undefined =>
  state.makeABooking.areFormsValidationErrorsVisible
    ? state.makeABooking.invalidStagesSections[stage]
    : undefined;

export const getFormsValidationErrorsVisibleState = (
  state: RootState
): boolean => state.makeABooking.areFormsValidationErrorsVisible;

export const areAllStagesValid = (state: RootState): boolean => {
  const stagesValidationStatuses = Object.values(
    state.makeABooking.stagesValidationStatus
  );

  return (
    !!stagesValidationStatuses.length &&
    stagesValidationStatuses.every((isValid) => isValid)
  );
};

export const getCurrentStep = (
  state: RootState
): MakeABookingStage | undefined => state.makeABooking.currentStep;

export const getIsCurrentStepVisited = (state: RootState): boolean => {
  const step = state.makeABooking.currentStep;
  return !!step && !!state.makeABooking.stagesVisitedStatus[step];
};

export const getTermsAndConditionsState = (state: RootState): boolean =>
  state.makeABooking.isTermsAndConditionsAccepted;
