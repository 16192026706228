import { BookingsListColumn } from "../../components/organisms/BookingsList/BookingsListHeader/BookingsListHeader.constants";

export function changeColumnsOrder(
  columns: string | BookingsListColumn[],
  { destination, source }: { destination: number; source: number }
) {
  const sorted = [...columns];
  sorted.splice(source, 1);
  sorted.splice(destination, 0, columns[source]);

  return sorted;
}
