import PropTypes from "prop-types";
import React, { createRef, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getRem, OneRemInPx } from "../../../utils/css";
import Tab from "./Tab";
import { TabOuterWrapper, TabWrapper, Highlighter } from "./Tabs.styles";

const Tabs = ({ items, backLink, paddingRight, inMapView, className = "" }) => {
  const { state, pathname, search } = useLocation();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs(items.map(() => createRef()));
  }, [items]);

  const activeTabIndex = useMemo(
    () => items.findIndex((item) => backLink + item.route === pathname),
    [items, backLink, pathname]
  );

  const activeTabDimensions =
    tabs[activeTabIndex]?.current?.getBoundingClientRect();

  const highlighterDims = useMemo(() => {
    if (activeTabDimensions) {
      return {
        width: getRem(
          activeTabDimensions.width - paddingRight.slice(0, -3) * OneRemInPx
        ),
        left: getRem(
          tabs
            .slice(0, activeTabIndex)
            .reduce((p, n) => p + n.current.getBoundingClientRect().width, 0)
        ),
      };
    } else {
      return { width: 0, left: 0 };
    }
  }, [activeTabDimensions, activeTabIndex, paddingRight, tabs]);

  // force rerender after refs initialized to get highlight right without interaction
  const [, setForceRefresh] = useState();
  useEffect(() => {
    if (tabs.some((ref) => ref.current)) {
      setForceRefresh(+new Date());
    }
  }, [tabs]);

  return (
    <TabOuterWrapper className={className}>
      <TabWrapper>
        {items.map((item, i) => (
          <Tab
            trackCode={item.trackCode[inMapView ? "inMapView" : "outMapView"]}
            key={item.text}
            route={backLink + item.route + search}
            text={item.text}
            active={backLink + item.route === pathname}
            paddingRight={paddingRight}
            tabRef={tabs[i]}
            state={state}
            onClick={item.onClick}
          />
        ))}
      </TabWrapper>
      <Highlighter dims={highlighterDims} />
    </TabOuterWrapper>
  );
};

Tabs.defaultProps = {
  items: [],
  backLink: "/",
  paddingRight: getRem(40),
};
Tabs.propTypes = {
  items: PropTypes.array,
  backLink: PropTypes.string,
  paddingRight: PropTypes.string,
};

export default Tabs;
