import types from "./action-types";

const initialState = {
  errors: [],
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ERROR: {
      return { ...state, errors: [...state.errors, action.payload] };
    }
    case types.REMOVE_ALL_ERROR:
      return initialState;
    case types.HIDE_ALL_ERROR:
      return {
        ...state,
        errors: state.errors.map((error) => {
          return { ...error, hidden: true };
        }),
      };
    case types.REMOVE_ERROR:
      return {
        ...state,
        errors: state.errors.splice(action.payload, 1),
      };
    default:
      return { ...state };
  }
};

export default errorReducer;
