import gql from "graphql-tag";

const GET_USER_ROLES = gql`
  query GetUserRolesQuery($corporatePartnerId: String!) {
    getUserRoles(corporatePartnerId: $corporatePartnerId) {
      code
    }
  }
`;
export default GET_USER_ROLES;
