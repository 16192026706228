export enum ContentType {
  BUTTON = "Button",
  LINK = "Link",
  TAB = "Tab",
  DROPDOWN = "Dropdown",
  PAGE_OVERLAY = "Page Overlay",
  SEARCH_FIELD = "Search Field",
  LIST_HEADER = "List Header",
  MENU_ITEM = "Menu Item",
  SORT_ICON = "Sort Icon",
  CARD = "Card",
  SELECT_FIELD = "select-field",
  MENU = "Menu",
  CHECKBOX = "Checkbox",
  CHIP = "Chip",
}

export const contentInteractionClick = "Click";
export const contentInteractionPageOverlay = "Modal Open";
export const contentInteractionSearchTriggered = "Search triggered";
export const contentInteractionSelect = "Select";
export const contentInteractionChecked = "checked";

export interface EventContentInteraction {
  type: ContentType;
  interaction:
    | typeof contentInteractionClick
    | typeof contentInteractionPageOverlay
    | typeof contentInteractionSearchTriggered
    | typeof contentInteractionSelect
    | typeof contentInteractionChecked;
}

export const eventContentButtonInterObj: EventContentInteraction = {
  type: ContentType.BUTTON,
  interaction: contentInteractionClick,
};

export const eventContentLinkInterObj: EventContentInteraction = {
  type: ContentType.LINK,
  interaction: contentInteractionClick,
};

export const eventContentDropdownInterObj: EventContentInteraction = {
  type: ContentType.DROPDOWN,
  interaction: contentInteractionClick,
};

export const eventContentTabInterObj: EventContentInteraction = {
  type: ContentType.TAB,
  interaction: contentInteractionClick,
};

export const eventContentPageOverlayInterObj: EventContentInteraction = {
  type: ContentType.PAGE_OVERLAY,
  interaction: contentInteractionPageOverlay,
};

export const eventContentSearchInterObj: EventContentInteraction = {
  type: ContentType.SEARCH_FIELD,
  interaction: contentInteractionSearchTriggered,
};

export const eventContentListHeaderInterObj: EventContentInteraction = {
  type: ContentType.LIST_HEADER,
  interaction: contentInteractionClick,
};

export const eventContentMenuItemInterObj: EventContentInteraction = {
  type: ContentType.MENU_ITEM,
  interaction: contentInteractionSelect,
};

export const eventContentSortIconInterObj: EventContentInteraction = {
  type: ContentType.SORT_ICON,
  interaction: contentInteractionClick,
};

export const eventContentCardInterObj: EventContentInteraction = {
  type: ContentType.CARD,
  interaction: contentInteractionClick,
};

export const eventContentSelectFieldInterObj: EventContentInteraction = {
  type: ContentType.SELECT_FIELD,
  interaction: contentInteractionClick,
};

export const eventContentMenuInterObj: EventContentInteraction = {
  type: ContentType.MENU,
  interaction: contentInteractionSelect,
};

export const eventContentCheckboxInterObj: EventContentInteraction = {
  type: ContentType.CHECKBOX,
  interaction: contentInteractionChecked,
};

export const eventContentChipInterObj: EventContentInteraction = {
  type: ContentType.CHIP,
  interaction: contentInteractionSelect,
};
