import { ModalButtons, NewButton } from "react-lib";
import styled from "styled-components";
import { SCREEN_SIZES } from "utils/css";

export const AddressBookEmptyButtons = styled(ModalButtons)`
  display: flex;
  flex-flow: row;
  text-align: center;
  padding: 0;
  align-self: center;

  @media (max-width: ${SCREEN_SIZES.until.md}) {
    flex-flow: column;
  }
`;

export const AddressBookEmptyButton = styled(NewButton)`
  min-width: 13.25rem;
`;

export const AddressBookEmptyContent = styled.div`
  &&& {
    display: flex;
    flex-flow: column;
    height: auto;
  }
`;

export const AddressBookEmptyTitle = styled.div`
  margin-top: 12rem;
  font-size: 1.5rem;
  font-weight: 900;
  align-self: center;
  line-height: 1.17;
`;

export const AddressBookEmptyExplanation = styled.div`
  font-size: 0.875rem;
  align-self: center;
  padding: 0.625rem 1.75rem 2.5rem 1.75rem;
  text-align: center;
  line-height: 1.43;
`;
