import { COOKIE_SETTINGS_LINK_CLASS } from "constants/cookieSettings";
import linkLocaleLookup from "constants/linkLocaleLookup";
import { messages } from "constants/links.messages";
import { useQuery } from "@apollo/client";
import { useFeedbackLink } from "hooks/useFeedbackLink";
import GET_AEM_BY_LANGUAGE_AND_COUNTRY from "queries/Reporting/getAemByLanguageAndCountry";
import { useIntl, MessageDescriptor } from "react-intl";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

export interface LinkParams {
  key: string;
  route: string;
  trackCode?: number;
  message: MessageDescriptor;
  onClick?: (e: React.MouseEvent<HTMLHeadingElement>) => void;
}

export const getLinkLocale = (language: string) => {
  const code = language.replace("-", "_");
  return linkLocaleLookup[code] ? `-${linkLocaleLookup[code]}` : "";
};

const getPrivacyNoticeData = (route: string): LinkParams => ({
  key: "privacyNotice",
  route,
  trackCode: 18,
  message: messages.privacyNotice,
});

export const getPrivacyNoticeLink = (localeCode: string) =>
  getPrivacyNoticeData(
    `https://www.mydhli.com/global-en/home/footer/privacy-notice${localeCode}.html`
  );

const getTermsOfUseData = (route: string) => ({
  key: "termsOfUse",
  route,
  trackCode: 19,
  message: messages.termsOfUse,
});

export const getTermsOfUseLink = (localeCode: string) =>
  getTermsOfUseData(
    `https://www.mydhli.com/global-en/home/footer/terms-of-use${localeCode}.html`
  );

const getLegalNoticeData = (route: string) => ({
  key: "legalNotice",
  route,
  trackCode: 20,
  message: messages.legalNotice,
});

export const getLegalNoticeLink = (localeCode: string) =>
  getLegalNoticeData(
    `https://www.mydhli.com/global-en/home/footer/legal-notice${localeCode}.html`
  );

export const getCookieSettingsLink = (): LinkParams => ({
  key: "cookieSettings",
  route: "",
  onClick: (e: React.MouseEvent<HTMLHeadingElement>) => {
    e.preventDefault();
    const links = document.getElementsByClassName(
      COOKIE_SETTINGS_LINK_CLASS
    ) as HTMLCollectionOf<HTMLLinkElement>;
    if (links.length) links[0].click();
  },
  trackCode: 1100,
  message: messages.cookieSettings,
});

export const getContactUsLink = (route: string) => ({
  key: "contactUs",
  route,
  trackCode: 1101,
  message: messages.contactUs,
});

const getFeedbackLink = (route: string): LinkParams => ({
  key: "feedback",
  route,
  message: messages.feedback,
});

export const useFooterLinks = (): Array<LinkParams> => {
  const intl = useIntl();
  const countryCodeFromProfile = useSelector(
    (state: RootState) => state.app?.userCountry
  );
  const isRegisteredUser = useSelector(
    (state: RootState) => !!state.userSettings?.visibilityScope?.id
  );

  const { data } = useQuery(GET_AEM_BY_LANGUAGE_AND_COUNTRY, {
    variables: {
      params: {
        languageCode: intl.locale.replace("-", "_"),
        countryCode: countryCodeFromProfile,
      },
    },
  });
  const feedbackLink = useFeedbackLink();

  return [
    getPrivacyNoticeData(data?.getAemByLanguageAndCountry?.url_privacy_notice),
    getTermsOfUseData(data?.getAemByLanguageAndCountry?.url_terms_of_use),
    getLegalNoticeData(data?.getAemByLanguageAndCountry?.url_legal_notice),
    getCookieSettingsLink(),
    getContactUsLink(
      isRegisteredUser
        ? data?.getAemByLanguageAndCountry?.url_contact_us
        : data?.getAemByLanguageAndCountry?.url_contact_us_unregistered
    ),
    getFeedbackLink(feedbackLink),
  ];
};
