import { ReactComponent as SearchIcon } from "assets/icons/myDHLi/search.svg";
import useOutsideClick from "hooks/useOutsideClick";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Input } from "react-lib";
import SimpleBar from "simplebar-react";
import FlyOutWrapper from "./parts/FlyOutWrapper";
import {
  FlyOutItemWithHighlighter,
  flyOutListSearchWrapperStyles,
  SearchIconWrapper,
} from "./styles";

const FlyOutListSearch = ({ items, visible, ...props }) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, props.onOutsideClick, props.disableOutsideClick);
  const [searchText, setSearchText] = useState("");
  const filteredItems = useMemo(
    () =>
      searchText
        ? items.filter((i) =>
            i.text.toLowerCase().includes(searchText.toLowerCase())
          )
        : items,
    [items, searchText]
  );
  const [isInputFocused, setIsInputFocused] = useState(false);
  useEffect(() => {
    if (!visible && searchText) {
      setSearchText("");
    }
  }, [visible, searchText]);
  return (
    <div ref={wrapperRef}>
      <FlyOutWrapper
        visible={visible}
        containerWidth={props.containerWidth}
        containerTop={props.containerTop}
        containerRight={props.containerRight}
        arrowRight={props.arrowRight}
        footer={props.footer}
        padding={props.wrapperPadding}
        maxHeight="none"
        $styles={props.wrapperStyles}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Input
          inputProps={{
            placeholder: "Search",
            value: searchText,
            onChange: (e) => setSearchText(e.target.value),
            onFocus: () => setIsInputFocused(true),
            onBlur: () => setIsInputFocused(false),
          }}
          isBlock
        >
          <SearchIconWrapper isInputFocused={isInputFocused}>
            <SearchIcon />
          </SearchIconWrapper>
        </Input>
        <SimpleBar style={{ maxHeight: 250 }}>
          {filteredItems.map((item, i) => (
            <FlyOutItemWithHighlighter
              key={item.key}
              onClick={props.setFlyOutVisible}
              item={
                props.onSelect
                  ? {
                      ...item,
                      onClick: () => {
                        props.onSelect(i);
                      },
                    }
                  : item
              }
              padding={props.flyoutItemPadding}
              highlighterStyles={props.flyOutItemHighlighterStyles}
            />
          ))}
          {!filteredItems.length && (
            <FlyOutItemWithHighlighter
              key="no_results"
              item={{ text: "No Results" }}
              padding={props.flyoutItemPadding}
              onClick={props.setFlyOutVisible}
            />
          )}
        </SimpleBar>
      </FlyOutWrapper>
    </div>
  );
};

FlyOutListSearch.defaultProps = {
  items: [
    {
      text: "My Account",
      key: "myAccount",
      route: "/",
      onClick: () => {},
    },
    {
      text: "Logout",
      key: "logout",
      route: "/",
      onClick: () => {},
    },
  ],
  visible: false,
  onOutsideClick: () => {},
  setFlyOutVisible: () => {},
};
FlyOutListSearch.defaultProps = {
  wrapperStyles: flyOutListSearchWrapperStyles,
  flyOutItemHighlighterStyles: { right: "0" },
  disableOutsideClick: false,
};
FlyOutListSearch.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      key: PropTypes.string,
      route: PropTypes.string,
      icon: PropTypes.element,
    })
  ),
  visible: PropTypes.bool,
  setFlyOutVisible: PropTypes.func,
  onOutsideClick: PropTypes.func,
  containerWidth: PropTypes.number,
  containerTop: PropTypes.number,
  containerRight: PropTypes.number,
  arrowRight: PropTypes.number,
  wrapperStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  flyOutItemHighlighterStyles: PropTypes.instanceOf(Object),
  disableOutsideClick: PropTypes.bool,
};

export default FlyOutListSearch;
