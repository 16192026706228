// eslint-disable
import { applyMiddleware, compose, createStore } from "redux";
import createDebounce from "redux-debounced";
import persistState from "redux-localstorage";
import createSagaMiddleware from "redux-saga";
import persistStateToSession from "redux-sessionstorage";
import * as C from "redux/constants";
import startSession from "utils/openSession";
import reducers from "./reducers";
import sagas from "./sagas";

const configureStore = (initialState) => {
  startSession();
  const isEnvDev = process.env.NODE_ENV === "development";
  const sagaMiddleware = createSagaMiddleware();
  const debounceMiddleware = createDebounce();
  const middlewares = [sagaMiddleware, debounceMiddleware];

  const enhancer =
    isEnvDev &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const store = createStore(
    reducers,
    initialState,
    enhancer(
      applyMiddleware(...middlewares),
      persistState(["userSettings"], {
        key: C.userSettingsPersistentStateKey,
      }),
      persistState(["searchHistory"], {
        key: "MyDHLiSearchHistoryV1",
      }),
      persistStateToSession(["quoteAndBook"], {
        key: "quoteAndBookV1",
      }),
      persistStateToSession(["quoteData"], {
        key: "quoteData",
      })
    )
  );

  sagaMiddleware.run(sagas);

  if (isEnvDev && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./reducers", () => {
      const nextRootReducer = require("./reducers");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export const store = configureStore();
