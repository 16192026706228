import { Country } from "constants/masterDataManagement";
import * as MDM from "constants/masterDataManagement";
import { useQuery } from "@apollo/client";
import { transformCountriesList } from "helpers/transformCountriesList";
import { GET_COUNTRIES } from "queries/AAA/masterDataManagement";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { Select } from "react-lib";
import { useDispatch } from "react-redux";
import { setBookingsListFilter } from "redux/userSettings/actions";
import customStyles from "../customStyles";
import CustomOption from "../ListBucketSearch/Partials/CustomOption";
import * as C from "./CountryDropdown.constants";
import { messages } from "./CountryDropdown.messages";
import * as S from "./CountryDropdown.styles";

const Countries: FC<C.CountryDropdownProps> = ({ filter, onOutsideClick }) => {
  const dispatch = useDispatch();
  const applyFilter = (val: string) => {
    dispatch(setBookingsListFilter(filter, val));
    onOutsideClick();
  };
  const intl = useIntl();

  const { data: countriesData } =
    useQuery<{ getCountries: Country[] }>(GET_COUNTRIES);

  const countryList = useMemo(
    () => transformCountriesList(countriesData?.getCountries || []),
    [countriesData]
  );

  return (
    <S.FilterItemWrapper>
      <Select
        inputId="countrySearch"
        name="countrySearch"
        options={countryList}
        onChange={(item: MDM.Country) => {
          if (item) {
            applyFilter(item.value);
          }
        }}
        placeholder={intl.formatMessage(messages.suggestion)}
        components={{
          Option: CustomOption,
        }}
        menuIsOpen
        isClearable
        openMenuOnFocus
        autoComplete="off"
        styles={customStyles}
      />
    </S.FilterItemWrapper>
  );
};

export default Countries;
