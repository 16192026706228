import { defineMessages } from "react-intl";

export default defineMessages({
  contactSectionTitle: {
    id: "bookingStandalone.addressBook.contactSectionTitle",
    defaultMessage: "Contact Details",
  },

  nameLabel: {
    id: "bookingStandalone.addressBook.nameLabel",
    defaultMessage: "Name",
  },

  emailLabel: {
    id: "bookingStandalone.addressBook.emailLabel",
    defaultMessage: "Email",
  },

  phoneLabel: {
    id: "bookingStandalone.addressBook.phoneLabel",
    defaultMessage: "Phone Number",
  },

  contactTypeLabel: {
    id: "bookingStandalone.addressBook.contactTypeLabel",
    defaultMessage: "Contact type (optional)",
  },

  addContact: {
    id: "bookingStandalone.addressBook.addContact",
    defaultMessage: "Add Contact",
  },
});
