import gql from "graphql-tag";

const UserDetailsFragment = gql`
  fragment UserDetails on User {
    firstName
    lastName
    companyName: company
    roleInCompany: companyRole
    department
    phoneNumber: phone
    email
    currency
    latestTCAccepted
    getEmailNotifications: toNotify
    languageCode
    address {
      addressLine1
      addressLine2
      city
      country {
        code
        name
      }
      postalCode: postcode
      state
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query getUserDetailsQuery {
    user {
      ...UserDetails
      nameInitials @client
    }
  }
  ${UserDetailsFragment}
`;

export const UPDATE_USER_DETAILS = gql`
  mutation updateUserDetails($user: UserInput!) {
    updateUser(user: $user) {
      ...UserDetails
    }
  }
  ${UserDetailsFragment}
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $oldPassword: String!) {
    resetPassword(password: $password, oldPassword: $oldPassword)
  }
`;
