import types from "./action-types";

export const addError = (errorObj) => ({
  type: types.ADD_ERROR,
  payload: errorObj,
});

export const addAxiosError = (res, operationName) => {
  const traceId = res?.config?.headers?.x_request_id ?? "";
  let variables;
  try {
    variables = JSON.parse(res?.config?.data);
  } catch {
    variables = res?.config?.data ?? null;
  }
  return addError({
    error: {
      code: res?.status,
      message: res?.statusText || "Unknown error",
      path: [],
      extensions: {
        code: res?.status,
        exception: { stacktrace: [] },
        response: res?.data,
      },
      distribute_trace_id: traceId,
      dhl_error: traceId.substr(0, 8),
    },
    variables,
    operationName,
  });
};

export const removeError = (index) => ({
  type: types.REMOVE_ERROR,
  payload: index,
});

export const removeAllErrors = () => ({
  type: types.REMOVE_ALL_ERROR,
});

export const hideAllErrors = () => ({
  type: types.HIDE_ALL_ERROR,
});
