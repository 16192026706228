import styled from "styled-components";

const ProfileImageWrapper = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
`;

export default ProfileImageWrapper;
