import _get from "lodash/get";
const get = (obj, path, defValue) => {
  const val = _get(obj, path, defValue);
  if (val === null) return defValue;
  return val;
};

export const getArray = (obj, path, defValue) => {
  const val = _get(obj, path, defValue);
  if (Array.isArray(val)) return val;
  if (Array.isArray(defValue)) return defValue;
  return [];
};

// just a wrapper function to satisfy fortify
export const getIt = (item) => item;

export default get;
