import { Product } from "constants/bookWorkflow";
import * as MDM from "constants/masterDataManagement";
import React, { useCallback } from "react";
import { IntlShape, useIntl } from "react-intl";
import { zIndices } from "react-lib";
import {
  ActiveFilter,
  ListElement,
} from "react-lib/@types/organisms/FiltersList/FiltersList.constants";
import SvgEdit from "react-lib/es/icons/SvgEdit";
import IconUserInterfaceFilter from "react-lib/es/icons/SvgIconUserInterfaceFilter";
import SvgMoreVertical from "react-lib/es/icons/SvgMoreVertical";
import SvgTrashBinDelete from "react-lib/es/icons/SvgTrashBinDelete";
import { ListElementTypeEnum } from "react-lib/es/organisms/FiltersList/FiltersList.constants";
import {
  AddressFlag,
  HeaderContentRendererProps,
  ImprovedTableColumn,
  MenuOption,
  RowContentRendererProps,
  RowMenuOptions,
  SmallColumnsNames,
  UserAddressBook,
  UserAddressBookAddressFlag,
  ListElementSchema,
  UserAddressBookAddressRowData,
  AddressBookFilterCol,
  ColumnNameEnum,
  CountriesDataObjForFilters,
} from "./AddressBook.constants";
import messages from "./AddressBook.messages";
import * as S from "./AddressBook.styles";

export const useTranslateAddressFlag = () => {
  const intl = useIntl();

  const translateAddressFlag = useCallback(
    (flagsObj: UserAddressBookAddressFlag): string => {
      const getAddressFlagTranslation = (addressFlag: AddressFlag): string => {
        switch (addressFlag) {
          case AddressFlag.isBilling:
            return intl.formatMessage(messages.isBilling);
          case AddressFlag.isConsignee:
            return intl.formatMessage(messages.isConsignee);
          case AddressFlag.isDelivery:
            return intl.formatMessage(messages.isDelivery);
          case AddressFlag.isPickup:
            return intl.formatMessage(messages.isPickup);
          case AddressFlag.isShipper:
            return intl.formatMessage(messages.isShipper);
          case AddressFlag.isNotify:
            return intl.formatMessage(messages.isNotify);
          default:
            return "";
        }
      };

      const flagsToDisplay = Object.values(AddressFlag)
        .filter((flag) => flagsObj[flag])
        .map((flag) => getAddressFlagTranslation(flag));

      return flagsToDisplay?.join(", ");
    },
    [intl]
  );

  return translateAddressFlag;
};

export const customHeaderRowRenderer = ({
  columns,
}: HeaderContentRendererProps) => (
  <tr>
    {columns.map((column: ImprovedTableColumn) => (
      <S.Th key={column.name}>
        {column.headerRenderer
          ? column.headerRenderer(column)
          : column.displayName}
      </S.Th>
    ))}
  </tr>
);

export const selectableRowRenderer = (
  args: RowContentRendererProps,
  onSelectCallback: (selectedRow: UserAddressBook) => void
) => {
  return (
    <tr
      key={args.rowIndex}
      id={`${args.row.id}`}
      onMouseEnter={() => args.setHoveredRowId(args.row.id)}
      onMouseLeave={() => args.setHoveredRowId(-1)}
      onClick={() => {
        onSelectCallback(args.row);
        args.setClickedRowId(args.row.id);
      }}
    >
      {rowContentRenderer(args)}
    </tr>
  );
};

export const OptionsName = "options";

export const MenuIcons: Record<string, React.ReactElement> = {
  edit: <SvgEdit />,
  delete: <SvgTrashBinDelete />,
};

export const getAddressBookColumns = (
  intl: IntlShape,
  activeFilters: ActiveFilter[],
  setSelectedFilterId: (id: string) => void
): ImprovedTableColumn[] => [
  {
    name: "companyName",
    headerRenderer: (col) =>
      getDefaultHeaderRenderer(
        col,
        activeFilters.some(
          (filter) => filter.id === ColumnNameEnum.COMPANY_NAME
        ),
        setSelectedFilterId,
        ColumnNameEnum.COMPANY_NAME
      ),
    displayName: intl.formatMessage(messages.company),
    emptyFallback: <div> empty </div>,
  },
  {
    name: "concatAddress",
    headerRenderer: (col) =>
      getDefaultHeaderRenderer(
        col,
        activeFilters.some((filter) => filter.id === ColumnNameEnum.ADDRESS),
        setSelectedFilterId,
        ColumnNameEnum.ADDRESS
      ),
    displayName: intl.formatMessage(messages.address),
    emptyFallback: <div> empty </div>,
  },
  {
    name: "country",
    headerRenderer: (col) =>
      getDefaultHeaderRenderer(
        col,
        activeFilters.some(
          (filter) => filter.id === ColumnNameEnum.COUNTRY_CODE
        ),
        setSelectedFilterId,
        ColumnNameEnum.COUNTRY_CODE
      ),
    displayName: intl.formatMessage(messages.country),
    emptyFallback: <div> empty </div>,
  },
  {
    name: "type",
    headerRenderer: (col) =>
      getDefaultHeaderRenderer(
        col,
        activeFilters.some((filter) => filter.id === ColumnNameEnum.TYPE),
        setSelectedFilterId,
        ColumnNameEnum.TYPE
      ),
    displayName: intl.formatMessage(messages.type),
    emptyFallback: <div> empty </div>,
  },
  {
    name: "reference",
    headerRenderer: (col) =>
      getDefaultHeaderRenderer(
        col,
        activeFilters.some((filter) => filter.id === ColumnNameEnum.REFERENCE),
        setSelectedFilterId,
        ColumnNameEnum.REFERENCE
      ),
    displayName: intl.formatMessage(messages.referenceId),
    emptyFallback: <div> empty </div>,
  },
];
export const getAddressBookOptionsColumn = (): ImprovedTableColumn => ({
  name: OptionsName,
  headerRenderer: () => <S.StyledMenuWrapper />,
  displayName: "",
  emptyFallback: "",
});

const rowContentRenderer = ({
  columns,
  row,
  hoveredRowId,
  clickedRowId,
  intl,
  selectMenuOptionForListItem,
}: RowContentRendererProps) => {
  const rowData: UserAddressBookAddressRowData = {
    type: row.type || "",
    concatAddress: row.concatAddress || "",
    country: row.country || "",
    addressLine1: row.address.addressLine1,
    addressLine2: row.address.addressLine2,
    city: row.address.city,
    companyName: row.address.companyName,
    countryCode: row.address.countryCode,
    postalCode: row.address.postalCode,
    reference: row.address.reference,
    stateProvince: row.address.stateProvince,
  };
  return columns.map((column) => {
    if (column.name in SmallColumnsNames) {
      return (
        <S.SmallTd key={column.name} isClicked={clickedRowId === row.id}>
          {hoveredRowId === row.id
            ? renderRows(
                column,
                rowData,
                intl,
                selectMenuOptionForListItem,
                hoveredRowId
              )
            : renderRows(column, rowData, intl, selectMenuOptionForListItem)}
        </S.SmallTd>
      );
    } else {
      return (
        <S.Td key={column.name} isClicked={clickedRowId === row.id}>
          {hoveredRowId === row.id
            ? renderRows(
                column,
                rowData,
                intl,
                selectMenuOptionForListItem,
                hoveredRowId
              )
            : renderRows(column, rowData, intl, selectMenuOptionForListItem)}
        </S.Td>
      );
    }
  });
};

const renderRows = (
  column: ImprovedTableColumn,
  row: UserAddressBookAddressRowData,
  intl: IntlShape,
  selectMenuOptionForListItem: (id: number, menuOption: RowMenuOptions) => void,
  selectedElementId?: number
) => {
  if (column.name === OptionsName && selectedElementId) {
    return renderOptions(intl, selectedElementId, selectMenuOptionForListItem);
  } else if (column.renderer) {
    return column.renderer({
      ...row,
    });
  } else {
    return row[column.name as keyof UserAddressBookAddressRowData];
  }
};

const renderOptions = (
  intl: IntlShape,
  id: number,
  selectMenuOptionForListItem: (id: number, menuOption: RowMenuOptions) => void
) => (
  <S.StyledMenu
    zIndex={zIndices.popover}
    options={getRowMenuOptions(intl)}
    getIcon={(option: MenuOption) => MenuIcons[option.value]}
    onChange={(menuOption: RowMenuOptions) =>
      selectMenuOptionForListItem(id, menuOption)
    }
  >
    <S.OptionsWrapper>
      <SvgMoreVertical />
    </S.OptionsWrapper>
  </S.StyledMenu>
);

export const getRowMenuOptions = (intl: IntlShape): MenuOption[] => [
  {
    value: RowMenuOptions.EDIT,
    label: intl.formatMessage(messages.edit),
  },
  {
    value: RowMenuOptions.DELETE,
    label: intl.formatMessage(messages.delete),
  },
];

export const getDefaultHeaderRenderer = (
  column: ImprovedTableColumn,
  hasActiveFilter: boolean,
  setSelectedFilterId: (id: string) => void,
  id: string
) => (
  <S.StyledHeader key={column.displayName}>
    {column.displayName}
    {hasActiveFilter && (
      <IconUserInterfaceFilter onClick={() => setSelectedFilterId(id)} />
    )}
  </S.StyledHeader>
);

export const normalizeActiveFilters = (
  activeFilters: ActiveFilter[],
  countriesData?: MDM.Country[]
) =>
  activeFilters.map((activeFilter) => {
    if (activeFilter.id === ColumnNameEnum.COUNTRY_CODE) {
      return {
        columnName: activeFilter.id,
        values: Array.isArray(activeFilter.value)
          ? activeFilter.value.reduce<string[]>((acc, countryName) => {
              const activeEl = countriesData?.find(
                (countryDataEl) => countryDataEl.label === countryName
              );
              if (activeEl) {
                acc.push(activeEl.code);
              }
              return acc;
            }, [])
          : [activeFilter.value],
        exact: false,
      };
    }

    return {
      columnName: activeFilter.id,
      values: Array.isArray(activeFilter.value)
        ? activeFilter.value
        : [activeFilter.value],
      exact: false,
    };
  });

export const concatAddress = (entry: UserAddressBook): string => {
  const nullToEmpty = (field: string | undefined): string => field ?? "";
  const address = entry?.address;
  if (address) {
    const beforeComma = [
      nullToEmpty(address.addressLine1),
      nullToEmpty(address.addressLine2),
    ]
      .filter((addressPart) => addressPart)
      .join(" ");
    const afterComma = [
      nullToEmpty(address.postalCode),
      nullToEmpty(address.city),
    ]
      .filter((addressPart) => addressPart)
      .join(" ");
    return [beforeComma, afterComma]
      .filter((addressPart) => addressPart)
      .join(", ");
  }
  return "";
};

const buildCountriesDataForProvidedCodes = (
  codes?: string[],
  countriesData?: MDM.Country[]
) =>
  codes?.reduce<CountriesDataObjForFilters[]>((acc, curr) => {
    const country = countriesData?.find((country) => country.code === curr);

    if (country) {
      acc.push({
        code: curr,
        name: country?.label,
      });
    }
    return acc.sort((prevCountry, nextCountry) =>
      prevCountry.name.localeCompare(nextCountry.name)
    );
  }, []);

export const buildFilters = (
  filtersConfig: ListElementSchema[],
  activeFilters: ActiveFilter[],
  countriesData?: MDM.Country[],
  filterValues?: AddressBookFilterCol[]
) =>
  filtersConfig.reduce<ListElement[]>((results, filterSchemaEl) => {
    const filterValue = filterValues?.find(
      (val) => val.column === filterSchemaEl.columnName
    );
    if (
      filterValue?.values.length ||
      activeFilters.find(
        (activeFilter) => activeFilter.id === filterValue?.column
      )
    ) {
      const { columnName, ...rest } = filterSchemaEl;

      return [
        ...results,
        {
          ...rest,
          ...(filterSchemaEl.type === ListElementTypeEnum.MULTI_SELECT && {
            tagsList:
              columnName === ColumnNameEnum.COUNTRY_CODE
                ? buildCountriesDataForProvidedCodes(
                    filterValue?.values,
                    countriesData
                  )?.map((el) => el.name) || []
                : filterValue?.values,
          }),
        },
      ];
    }
    return results;
  }, []);

export const generateError = (
  prefix: string,
  intl: IntlShape,
  selectedAddress: UserAddressBook | null,
  countriesPossibleToSelect: string[],
  countriesProductData?: MDM.CountryProduct[],
  product?: Product
) => {
  const isCountryCanBeSelected = countriesPossibleToSelect.some(
    (code) => code === selectedAddress?.address.countryCode
  );
  const countryProduct = countriesProductData?.find(
    (countryProduct) =>
      countryProduct.countryCode === selectedAddress?.address.countryCode &&
      countryProduct.productCode === product
  );

  const isCountryProperForOrigin = !!countryProduct?.isOrigin;
  const isCountryProperForDestination = !!countryProduct?.isDestination;
  const isCountryProperForBilling = !!countryProduct?.isBilling;

  switch (prefix) {
    case "shipper":
    case "pickup": {
      if (!isCountryProperForOrigin) {
        return intl.formatMessage(messages.wrongCountryForOrigin);
      }
      if (!isCountryCanBeSelected) {
        return intl.formatMessage(messages.originCantBeTheSameAsDestination);
      }
      break;
    }
    case "delivery":
    case "consignee": {
      if (!isCountryProperForDestination) {
        return intl.formatMessage(messages.wrongCountryForDestination);
      }
      if (!isCountryCanBeSelected) {
        return intl.formatMessage(messages.destinationCantBeTheSameAsOrigin);
      }
      break;
    }
    case "party": {
      if (!isCountryProperForBilling) {
        return intl.formatMessage(messages.wrongCountryForBilling);
      }
      break;
    }

    default:
      return undefined;
  }
};
