const COLORS = {
  RED_SCARLET: "#D40511",
  YELLOW_SUNFLOWER: "#FFCC00",
};

export const theme = {
  colors: {
    error: {
      400: COLORS.RED_SCARLET,
    },
    font: {
      default: "#000000",
      inverted: "#FFFFFF",
    },
    gray: {
      100: "#F2F2F2",
      200: "#EBEBEB",
      300: "#E5E5E5",
      400: "#CCCCCC",
      500: "#B2B2B2",
      600: "#8C8C8C",
      700: "#666666",
      800: "#333333",
    },
    primary: {
      300: "#F9131F",
      400: COLORS.RED_SCARLET,
    },
    secondary: {
      400: COLORS.YELLOW_SUNFLOWER,
    },
    warning: {
      200: "#FBF2D7",
      400: COLORS.YELLOW_SUNFLOWER,
    },
  },
  animation: {
    slow: "500ms",
    regular: "250ms",
    fast: "100ms",
  },
  spacing: {
    radius: "4px", // 4px
    units: {
      large: "1.125rem",
      regular: "0.75rem",
      small: "0.375rem",
    },
  },
  fonts: {
    family: "Delivery, Verdana, sans-serif",
    sizes: {
      tiny: "0.75rem", // 12px
      small: "0.875rem", // 14px
      regular: "1rem", // 16px
      big: "1.25rem", // 20px
      xbig: "1.75rem", // 28px
      xxbig: "1.875rem", // 30px
      huge: "2.375rem", // 38px
    },
  },
};

const getProp = (path) => (props) =>
  path.split(".").reduce(
    (acc, cur) => {
      if (acc[cur] != null) {
        return acc[cur];
      }

      // eslint-disable-next-line no-console
      console.warn("Could not find path in theme", path);

      return {};
    },
    Object.keys(props.theme).length > 0 ? props.theme : theme
  );

export const getPrimaryColor = (path) => getProp(`colors.primary.${path}`);

export const getSecondaryColor = (path) => getProp(`colors.secondary.${path}`);

export const getGrayColor = (path) => getProp(`colors.gray.${path}`);

export const getWarningColor = (path) => getProp(`colors.warning.${path}`);

export const getErrorColor = (path) => getProp(`colors.error.${path}`);

export const getFontColor = (path) => getProp(`colors.font.${path}`);

export const getFontFamily = () => getProp(`fonts.family`);

export const getFontSize = (path) => getProp(`fonts.sizes.${path}`);

export const getUnit = (path) => getProp(`spacing.units.${path}`);

export const getRadius = () => getProp("spacing.radius");

export const getAnimationSpeed = (path) => getProp(`animation.${path}`);
