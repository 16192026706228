import { ERROR_CODES, LIST_TYPE } from "constants/common";
import { HeaderAndFooterHeightTotal } from "constants/variables";
import { ReactComponent as BadgeIcon } from "assets/icons/myDHLi/badge-icon.svg";
import { ReactComponent as NotFound } from "assets/icons/myDHLi/no-results.svg";
import { ReactComponent as ServerErrorIcon } from "assets/icons/myDHLi/server-error.svg";
import IconButton from "components/atoms/Buttons/IconButton";
import Col from "components/atoms/Col";
import Row from "components/atoms/Row";
import SVG from "components/atoms/SVGWrapper";
import startsWith from "lodash/startsWith";
import React from "react";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { getRem, SCREEN_SIZES } from "utils/css";
import { getTextByViewAndError } from "./helper";

const SVGWrapper = styled(SVG)`
  align-items: center;
  padding: 0 !important;
`;

const ButtonWrap = styled.div`
  @media (max-width: 768px) {
    padding-left: ${getRem(20)};
  }
`;

const Wrapper = styled(Row)`
  height: calc(100vh - ${getRem(HeaderAndFooterHeightTotal)});
  align-items: center;
  ${(p) =>
    !p.inMapView
      ? css`
          justify-content: center;
        `
      : null};
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: flex;
  flex-direction: column;
  ${(p) =>
    p.inMapView
      ? css`
          padding: ${getRem(30)};
        `
      : null}
`;

const RowWrapper = styled.div`
  max-width: ${getRem(572)};
  display: flex;
  flex-direction: ${(p) => (p.inMapView ? "column" : "row")};
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: ${(p) => getRem(p.inMapView || p.isMobile ? 21 : 39)};
  font-weight: bold;
  color: ${(props) => props.theme.colors.dhlRed};
  ${(p) =>
    p.isMobile
      ? css`
          padding: 0 ${getRem(20)};
        `
      : null}
`;

const ContentText = styled.div`
  font-size: ${(p) => getRem(p.inMapView || p.isMobile ? 21 : 39)};
  font-weight: lighter;
  ${(p) =>
    p.isMobile
      ? css`
          padding: 0 ${getRem(20)};
        `
      : null}
`;

const ContactUsText = styled.div`
  padding: ${getRem(20)} 0;
  ${(p) =>
    p.inMapView || p.isMobile
      ? css`
          font-size: ${getRem(14)};
          padding-bottom: ${getRem(40)};
        `
      : null}
  ${(p) =>
    p.isMobile
      ? css`
          padding: 0 ${getRem(20)} ${getRem(40)} ${getRem(20)};
        `
      : null}
`;

const TextCol = styled(Col)`
  flex: 5;
  ${(p) =>
    p.inMapView || p.isMobile
      ? css`
          padding: ${getRem(24)} ${getRem(20)} 0 ${getRem(20)};
        `
      : null};
`;

const IconCol = styled(Col)`
  flex: 1;
  align-self: flex-start;
  ${(p) =>
    p.inMapView
      ? css`
          padding-left: 0 !important;
        `
      : null}
`;

const ErrorModal = ({ view, inMapView, errors, errorCode }) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.until.md });
  const intl = useIntl();

  const isServerError =
    !!errors.filter((error) => {
      return (
        error?.error?.code === ERROR_CODES.SERVER_ERROR ||
        startsWith(error?.error?.message, "5")
      );
    }).length || [500, 501, 503, 504].includes(errorCode);
  const isNotAllowed =
    !!errors.filter((error) => error?.error?.code === ERROR_CODES.FORBIDDEN)
      .length || [403].includes(errorCode);
  const isNotFound =
    !!errors.filter((error) => error?.error?.code === ERROR_CODES.NOT_FOUND)
      .length || [400].includes(errorCode);

  if (isServerError && !errorCode) errorCode = 500;

  const {
    text,
    contactUsText,
    buttonText,
    title,
    handleClick,
    backButtonTrackCode,
  } = getTextByViewAndError(view, errors, history, intl, errorCode);
  const iconSize = {
    width: isMobile ? 100 : 251,
    height: isMobile ? 80 : 201,
  };

  return (
    <Wrapper inMapView={inMapView}>
      {isNotFound && (
        <RowWrapper style={{ marginLeft: getRem(-120) }}>
          <SVGWrapper
            width={getRem(iconSize.width)}
            height={getRem(iconSize.height)}
          >
            <NotFound />
          </SVGWrapper>
        </RowWrapper>
      )}
      <RowWrapper inMapView={inMapView}>
        {isNotAllowed || isServerError ? (
          <IconCol inMapView={inMapView}>
            <SVGWrapper
              width={getRem(inMapView ? 48 : 72)}
              height={getRem(inMapView ? 48 : 72)}
              fill="#d40511"
              hoverFill="#eb131e"
            >
              {isServerError ? (
                <ServerErrorIcon />
              ) : (
                <BadgeIcon data-track={69} />
              )}
            </SVGWrapper>
          </IconCol>
        ) : null}
        <TextCol inMapView={inMapView}>
          <Row>
            <Title isMobile={isMobile} inMapView={inMapView}>
              {title}
            </Title>
          </Row>
          <Row>
            <ContentText isMobile={isMobile} inMapView={inMapView}>
              {text}
            </ContentText>
          </Row>
          {contactUsText && (
            <Row>
              <ContactUsText isMobile={isMobile} inMapView={inMapView}>
                {contactUsText}
              </ContactUsText>
            </Row>
          )}
          {buttonText && (
            <Row>
              <ButtonWrap>
                <IconButton
                  data-track={backButtonTrackCode}
                  margin
                  color="#ffffff"
                  hoverColor="#ffffff"
                  bgColor="#d40511"
                  padding={`${getRem(16)} ${getRem(32.5)}`}
                  hideBorder
                  onClick={handleClick}
                >
                  {buttonText}
                </IconButton>
              </ButtonWrap>
            </Row>
          )}
        </TextCol>
      </RowWrapper>
    </Wrapper>
  );
};

ErrorModal.defaultProps = {
  view: LIST_TYPE.SHIPMENTS,
  inMapView: false,
  errors: [],
};

export default ErrorModal;
