import gql from "graphql-tag";

export const GET_LEGAL_ENTITY = gql`
  query GetLegalEntityQuery(
    $ratesSource: LegalEntityPayloadRatesSource
    $countryCode: String
  ) {
    getLegalEntity(ratesSource: $ratesSource, countryCode: $countryCode) {
      name
    }
  }
`;

export const LegalEntityRatesSources = {
  DGF: "DGF",
  FREIGHT: "FREIGHT",
};
