import PropTypes from "prop-types";
import { colors } from "react-lib";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getRem } from "../../../utils/css";

const LinkButton = styled(Link)`
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "all")};
  font-size: ${(props) => props.size};
  padding: ${(props) => props.padding};
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
  display: inline-block;
  background-color: ${(props) => props.bgColor};
  color: ${(props) =>
    props.isDisabled ? colors.gray20 : props.textCcolor || props.color};
  font-weight: bold;
  margin-right: ${getRem(20)};
  line-height: 1.2;
  border: none;
  &:last-child {
    margin-right: 0;
  }
  span,
  svg {
    display: inline-block;
  }
  span {
    font-weight: bold;
  }
  svg {
    fill: ${(props) => props.color};
    width: calc(${(props) => props.size} * 1.5);
    height: calc(${(props) => props.size} * 1.5);
    margin-left: ${getRem(7.5)};
  }
  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
  &:focus,
  &:active {
    text-decoration: none;
  }
  &:visited {
    color: ${(props) =>
      props.isDisabled ? colors.gray20 : props.textCcolor || props.color};
  }
  &:hover {
    text-decoration: underline;
    color: ${(props) => (props.isDisabled ? colors.gray20 : props.hoverColor)};
    box-shadow: unset;
    svg {
      fill: ${(props) => props.hoverColor};
    }
  }
`;

LinkButton.defaultProps = {
  color: "#d40511",
  hoverColor: "#eb131e",
  bgColor: "#FFFFFF",
  padding: `${getRem(10)} ${getRem(30)}`,
  size: getRem(16),
  type: "button",
};

LinkButton.propTypes = {
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  bgColor: PropTypes.string,
  padding: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
};

export default LinkButton;
