import { dateTableColumnSize } from "constants/columns";

const shipmentColumns = {
  Follow: {
    sortable: false,
    customSize: 2.6,
    removable: false,
    isDropdown: true,
  },
  Mode: {
    sortable: true,
    customSize: 2.2,
    removable: false,
    isDropdown: true,
  },
  Departure: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: false,
    isDropdown: true,
  },
  Origin: {
    sortable: true,
    customSize: 8,
    removable: false,
    isDropdown: true,
  },
  Arrival: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: false,
    isDropdown: true,
  },
  Destination: {
    sortable: true,
    customSize: 8,
    removable: false,
    isDropdown: true,
  },
  HouseBill: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: true,
  },
  Phase: {
    sortable: true,
    customSize: 5,
    removable: true,
    isDropdown: true,
  },
  Status: {
    sortable: true,
    customSize: 4,
    removable: true,
    isDropdown: true,
  },
  LastEvent: {
    sortable: true,
    customSize: 14,
    removable: true,
    isDropdown: true,
  },
  Carrier: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: true,
  },
  Shipper: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: true,
  },
  Consignee: {
    sortable: true,
    customSize: 6,
    removable: true,
    isDropdown: true,
  },
  Customer: {
    sortable: true,
    customSize: 6,
    removable: true,
    isDropdown: true,
  },
  Reference: {
    sortable: false,
    customSize: 8,
    removable: true,
    isDropdown: false,
  },
  CustomsEntryNumber: {
    sortable: false,
    customSize: 8,
    removable: true,
    isDropdown: false,
  },
  OriginCountry: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: true,
  },
  OriginPort: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: true,
  },
  DestinationCountry: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: true,
  },
  DestinationPort: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: true,
  },
  EstimatedPickup: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: true,
    isDropdown: true,
  },
  ActualPickup: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: true,
    isDropdown: true,
  },
  EstimatedDeparturePort: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: true,
    isDropdown: true,
  },
  ActualDeparturePort: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: true,
    isDropdown: true,
  },
  EstimatedArrivalPort: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: true,
    isDropdown: true,
  },
  ActualArrivalPort: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: true,
    isDropdown: true,
  },
  EstimatedCustomerDelivery: {
    sortable: true,
    customSize: dateTableColumnSize,
    removable: true,
    isDropdown: true,
  },
  DocumentHandover: {
    sortable: true,
    customSize: 5,
    removable: true,
    isDropdown: true,
  },
  JourneyDuration: {
    sortable: true,
    customSize: 6,
    removable: true,
    isDropdown: false,
  },
  DelayInShipmentArrival: {
    sortable: false,
    customSize: 6,
    removable: true,
    isDropdown: false,
  },
  MasterBill: {
    sortable: true,
    customSize: 6,
    removable: true,
    isDropdown: true,
  },
  FlightNumber: {
    sortable: true,
    customSize: 6,
    removable: true,
    isDropdown: true,
  },
  VesselName: {
    sortable: true,
    customSize: 6,
    removable: true,
    isDropdown: true,
  },
  ContainerNumber: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: true,
  },
  ContainerSize: {
    sortable: true,
    customSize: 6,
    removable: true,
    isDropdown: false,
  },
  GoodsDescription: {
    sortable: true,
    customSize: 6,
    removable: true,
    isDropdown: false,
  },
  Volume: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: false,
  },
  TotalWeight: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: false,
  },
  ChargeableWeight: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: false,
  },
  Pieces: {
    sortable: true,
    customSize: 6,
    removable: true,
    isDropdown: false,
  },
  IncotermsDescription: {
    sortable: true,
    customSize: 6,
    removable: true,
    isDropdown: true,
  },
  IncotermsCode: {
    sortable: true,
    customSize: 4,
    removable: true,
    isDropdown: true,
  },
  // ShipmentDelay: {
  //   sortable: true,
  //   customSize: 7,
  //   removable: true,
  // },
  // DelayExpectedTime: {
  //   sortable: true,
  //   customSize: 8,
  //   removable: true,
  // },
  // DelayOriginal: {
  //   sortable: true,
  //   customSize: 8,
  //   removable: true,
  // },
  // DelayOriginalTime: {
  //   sortable: true,
  //   customSize: 8,
  //   removable: true,
  // },
  LoadingMeters: {
    sortable: true,
    customSize: 5,
    removable: true,
  },
  FeatureNames: {
    sortable: false,
    customSize: 6,
    removable: true,
    isDropdown: true,
  },
  ProductName: {
    sortable: true,
    customSize: 10,
    removable: true,
    isDropdown: true,
  },
  CO2WtWFootprint: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: false,
  },
  CO2WtWEfficiency: {
    sortable: true,
    customSize: 8,
    removable: true,
    isDropdown: false,
  },
  Irregularities: {
    sortable: false,
    customSize: 6,
    removable: true,
    isDropdown: true,
  },
};

export default shipmentColumns;
