import getConfigClient from "constants/getConfigClient";
import { messages } from "components/organisms/ErrorPopup/messages";
import {
  errorPopupItems,
  errorPopupItemsCritical,
  errorPopupItemsQnB,
  errorPopupItemsReporting,
} from "data/errorPopupItems";
import includes from "lodash/includes";
import React from "react";
import { Copy, Input } from "react-lib";
import { isLocal, isProd } from "utils/env";
import get from "utils/get";
import { getArray } from "./get";

export const makeErrorObject = (graphQLErrors, operation, statusCodeInExt) => {
  const errorMessage = get(graphQLErrors, "[0].message");
  let errorCode = statusCodeInExt || Number.parseInt(errorMessage.slice(0, 3));
  if (isNaN(errorCode)) {
    errorCode = null;
  }
  return {
    error: {
      code: errorCode,
      message: errorMessage,
      path: get(graphQLErrors, "[0].path"),
      extensions: get(graphQLErrors, "[0].extensions"),
      distribute_trace_id: get(graphQLErrors, "[0].distribute_trace_id"),
      dhl_error: get(graphQLErrors, "[0].dhl_error"),
    },
    variables: get(operation, "variables"),
    operationName: get(operation, "operationName"),
  };
};

function corporatePartnerAlreadyPresent(userSettings) {
  return get(userSettings, "visibilityScope.uuid");
}

function widgetPreferenceAlreadyPresent(userSettings) {
  return getArray(userSettings, "widgetList").length;
}

function removeCorporatePartnerFromList(popupCandidate) {
  return popupCandidate.filter(
    (item) => item.operationName !== "GetCorporatePartnersQuery"
  );
}

function removeUserPreferenceForCorporatePartnerFromList(popupCandidate) {
  return popupCandidate.filter(
    (item) =>
      !(
        item.operationName === "GetPreferenceItemQuery" &&
        get(item, "variables.userObjectsFilter[0].key") === "userSettings"
      )
  );
}

function removeUserPreferenceForWidgetsFromList(popupCandidate) {
  return popupCandidate.filter(
    (item) =>
      !(
        item.operationName === "GetPreferenceItemQuery" &&
        get(item, "variables.userObjectsFilter[0].key") === "widgetListOrdered"
      )
  );
}

function removeQuotesFromList(popupCandidate) {
  return popupCandidate.filter(
    (item) => item.operationName !== "GetQuotesListQuery"
  );
}

function removeDocumentFromList(popupCandidate) {
  return popupCandidate.filter(
    (item) => item.operationName !== "GetShipmentDocuments"
  );
}

const shouldShowErrorPopupSpecialChecks = (error) => {
  const specialCodes = [];
  switch (error.operationName) {
    case "CreateConsignment":
      specialCodes.push("410");
      break;
    case "GetRecalculatedQuoteOption":
      specialCodes.push("404");
      break;
    case "CalculateConsignment":
      specialCodes.push("404");
      break;
    default:
      return true;
  }
  const errorCode = error?.error?.message?.split(":")[0];
  return !specialCodes.includes(errorCode);
};

export const shouldShowErrorPopup = (
  errors,
  inMapView = false,
  userSettings = {},
  pathname
) => {
  let popupCandidate = errors.filter((error) => {
    return (
      (error?.error?.code !== 403 &&
        includes(
          [
            ...errorPopupItems,
            ...errorPopupItemsQnB,
            ...errorPopupItemsReporting,
          ],
          error.operationName
        ) &&
        shouldShowErrorPopupSpecialChecks(error)) ||
      error.blockedCustomer
    );
  });
  if (corporatePartnerAlreadyPresent(userSettings)) {
    popupCandidate = removeCorporatePartnerFromList(popupCandidate);
    popupCandidate =
      removeUserPreferenceForCorporatePartnerFromList(popupCandidate);
  }
  if (widgetPreferenceAlreadyPresent(userSettings)) {
    popupCandidate = removeUserPreferenceForWidgetsFromList(popupCandidate);
  }
  if (pathname !== "/quotes") {
    popupCandidate = removeQuotesFromList(popupCandidate);
  }
  if (pathname.split("/").pop() !== "documents") {
    popupCandidate = removeDocumentFromList(popupCandidate);
  }
  return popupCandidate.filter((item) => item.hidden !== true).length > 0;
};

export const hasCriticalError = (errors) => {
  const popupCandidate = errors.filter((error) => {
    return includes(errorPopupItemsCritical, error.operationName);
  });
  return popupCandidate.length > 0;
};

export const getErrorMessage = (errorsArg, isCritical, intl) => {
  const errors = errorsArg.filter((e) => shouldShowErrorPopupSpecialChecks(e));
  const blockedCustomerErr = errors.find((e) => e.blockedCustomer);
  if (blockedCustomerErr) {
    return (
      <p>
        {intl.formatMessage(messages.blockedCustomer, {
          contactUsLink: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={blockedCustomerErr.contactLink}
            >
              {intl.formatMessage(messages.contactUsLinkText)}
            </a>
          ),
        })}
      </p>
    );
  }
  const errorCode = get(
    errors.filter((e) => !e.hidden),
    "[0].error.dhl_error"
  );
  const traceId = get(
    errors.filter((e) => !e.hidden),
    "[0].error.distribute_trace_id"
  );
  const theMessage = get(
    errors.filter((e) => !e.hidden),
    "[0].error.message"
  );
  const query = get(
    errors.filter((e) => !e.hidden),
    "[0].operationName"
  );
  if (
    noOffersFound(errors) &&
    errors.some(
      (e) =>
        e?.error?.message.includes("404") || e?.error?.message.includes("500")
    )
  ) {
    return (
      <>
        <div>
          <p>{intl.formatMessage(messages.noOffersFoundError)}</p>
          <a
            style={{ color: "white" }}
            href={getConfigClient("CONTACT_URL")}
            target="_black"
          >
            {intl.formatMessage(messages.noOffersFoundErrorLink)}
          </a>
        </div>
      </>
    );
  }
  if (
    noOffersFound(errors) &&
    errors.some((e) => e?.error?.message.includes("429"))
  ) {
    return (
      <>
        <div>
          <p>{intl.formatMessage(messages.errorMaxAllowedQuotesReached)}</p>
          <a
            style={{ color: "white" }}
            href={getConfigClient("CONTACT_URL")}
            target="_black"
          >
            {intl.formatMessage(messages.noOffersFoundErrorLink)}
          </a>
        </div>
      </>
    );
  }
  if (hasQuotePackageTypeError(errors)) {
    return (
      <>
        <div>
          {intl.formatMessage(messages.errorQuotePackageTypes)}
          {additionalCriticalMessage(errors, intl)}
        </div>
      </>
    );
  }
  return (
    <>
      <div>
        {intl.formatMessage(messages.errorOccurred)}
        {isCritical
          ? additionalCriticalMessage(errors, intl)
          : additionalMessage(errors, intl)}{" "}
        {intl.formatMessage(messages.reportProblem) + " "}
        {errorCode && (
          <>
            {intl.formatMessage(messages.mentionCode) + " "}
            <code>{errorCode}</code>
          </>
        )}
        {!isProd() ? (
          <>
            <Copy bold colorVariant="red" as="div">
              Non-Prod Env Message
            </Copy>
            <Copy colorVariant="red" as="div">
              Error Message: {theMessage}
            </Copy>
            <Copy colorVariant="red" as="div">
              QGL query: {query}
            </Copy>
            {isLocal() ? (
              <div>
                Open <em>errors.log.json</em> and search for trace_id:{" "}
                <Input isBlock size="MEDIUM" inputProps={{ value: traceId }} />
              </div>
            ) : (
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://czcholstc001144.prg-dc.dhl.com:8000/en-GB/app/dhlProd_app_dci/search?earliest=-15m&latest=now&q=search%20distribute_trace_id%3D%22${traceId}%22&display.page.search.mode=smart&dispatch.sample_ratio=1`}
                >
                  Go to Splunk Log
                </a>
              </div>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

const additionalCriticalMessage = (errors, intl) => {
  if (hasQuotePackageTypeError(errors)) {
    return (
      <>
        {". "}
        {intl.formatMessage(messages.tryLater, {
          contactUsLink: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mydhli.com/global-en/home/contact-us/registered-mydhli-customers.html"
            >
              {intl.formatMessage(messages.contactUsLinkText)}
            </a>
          ),
        })}
      </>
    );
  }

  const localMessages = [];
  if (hasMenuItemError(errors)) {
    localMessages.push(intl.formatMessage(messages.navPane));
  }
  if (hasShipmentListError(errors)) {
    localMessages.push(intl.formatMessage(messages.loadShipment));
  }
  if (hasCorporatePartnerError(errors)) {
    localMessages.push(intl.formatMessage(messages.loadData));
  }
  if (hasWidgetLoadError(errors)) {
    localMessages.push(intl.formatMessage(messages.loadWidget));
  }

  if (localMessages.length) {
    const commaSeparated = localMessages.slice(0, -2).join(", ");
    const andSeparated = localMessages
      .slice(-2)
      .join(" " + intl.formatMessage(messages.errorAnd) + " ");
    return (
      intl.formatMessage(messages.joinWeCouldNot) +
      " " +
      (commaSeparated ? commaSeparated + ", " + andSeparated : andSeparated) +
      intl.formatMessage(messages.errorStop)
    );
  }
  return ".";
};
export const additionalMessage = (errors, intl) => {
  const localMessages = [];
  if (hasFavoriteError(errors)) {
    localMessages.push(intl.formatMessage(messages.markFavorite));
  }
  if (hasUnFavoriteError(errors)) {
    localMessages.push(intl.formatMessage(messages.removeFavorite));
  }
  if (hasMarkAsViewedError(errors)) {
    localMessages.push(intl.formatMessage(messages.markLastViewed));
  }
  if (hasColumnSuggestionError(errors)) {
    localMessages.push(intl.formatMessage(messages.columnSuggestion));
  }
  if (hasAnalyticsItemListError(errors)) {
    localMessages.push(intl.formatMessage(messages.loadAnalytics));
  }
  if (hasCurrentLocationError(errors)) {
    localMessages.push(intl.formatMessage(messages.loadMap));
  }
  if (hasExcelExportError(errors)) {
    localMessages.push(intl.formatMessage(messages.exportList));
  }
  if (hasSearchSuggestionError(errors)) {
    localMessages.push(intl.formatMessage(messages.searchSuggestion));
  }
  if (hasDocumentListError(errors)) {
    localMessages.push(intl.formatMessage(messages.loadDocuments));
  }
  if (hasUserDetailsQueryError(errors)) {
    localMessages.push(intl.formatMessage(messages.loadProfile));
  }
  if (hasQuoteListError(errors)) {
    localMessages.push(intl.formatMessage(messages.loadQuotes));
  }
  if (hasCurrencyListQuery(errors)) {
    localMessages.push(intl.formatMessage(messages.loadCurrencies));
  }
  if (hasSetPreferenceItemQuery(errors)) {
    localMessages.push(intl.formatMessage(messages.savePreference));
  }
  if (hasGetReportingSharedViews(errors)) {
    localMessages.push(intl.formatMessage(messages.reportingSharedViews));
  }

  if (localMessages.length) {
    const commaSeparated = localMessages.slice(0, -2).join(", ");
    const andSeparated = localMessages
      .slice(-2)
      .join(" " + intl.formatMessage(messages.errorAnd) + " ");
    return (
      intl.formatMessage(messages.joinWeCouldNot) +
      " " +
      (commaSeparated ? commaSeparated + ", " + andSeparated : andSeparated) +
      intl.formatMessage(messages.errorStop)
    );
  }
  return intl.formatMessage(messages.errorStop);
};

const noOffersFound = (errors) => {
  return !!errors.filter((error) => error.operationName === "GetQuoteOptions")
    .length;
};

const hasFavoriteError = (errors) => {
  return !!errors.filter((error) => error.operationName === "favoriteItem")
    .length;
};
const hasUnFavoriteError = (errors) => {
  return !!errors.filter((error) => error.operationName === "unFavoriteItem")
    .length;
};
const hasMarkAsViewedError = (errors) => {
  return !!errors.filter((error) => error.operationName === "MarkAsViewed")
    .length;
};
const hasMenuItemError = (errors) => {
  return !!errors.filter((error) => error.operationName === "getMenuItems")
    .length;
};
const hasShipmentListError = (errors) => {
  return !!errors.filter((error) => error.operationName === "ShipmentListQuery")
    .length;
};
const hasCorporatePartnerError = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "GetCorporatePartnersQuery"
  ).length;
};
const hasColumnSuggestionError = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "ColumnSuggestionQuery"
  ).length;
};
const hasAnalyticsItemListError = (errors) => {
  return !!errors.filter((error) => error.operationName === "getAnalyticsItems")
    .length;
};
const hasCurrentLocationError = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "CurrentLocationsQuery"
  ).length;
};

const hasExcelExportError = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "ExcelExportLinkQuery"
  ).length;
};

const hasSearchSuggestionError = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "SearchSuggestionQuery"
  ).length;
};
const hasDocumentListError = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "GetShipmentDocuments"
  ).length;
};

const hasUserDetailsQueryError = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "getUserDetailsQuery"
  ).length;
};

const hasQuoteListError = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "GetQuotesListQuery"
  ).length;
};
const hasWidgetLoadError = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "getAvailableWidgets"
  ).length;
};
const hasQuotePackageTypeError = (errors) => {
  return !!errors.filter(
    (error) =>
      error.operationName === "QuotePackageTypesQuery" ||
      error.operationName === "QuotePackageTypeInfoQuery"
  ).length;
};
const hasCurrencyListQuery = (errors) => {
  return !!errors.filter((error) => error.operationName === "CurrencyListQuery")
    .length;
};
const hasSetPreferenceItemQuery = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "SetPreferenceItemQuery"
  ).length;
};
const hasGetReportingSharedViews = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "GetReportingSharedViews"
  ).length;
};
export const hasMapError = (errors) => {
  return !!errors.filter(
    (error) => error.operationName === "CurrentLocationsQuery"
  ).length;
};
export const hasAnalyticsError = (errors) => {
  return !!errors.filter((error) => error.operationName === "getAnalyticsItems")
    .length;
};
