import types from "./action-types";

const initialState = {
  navCollapsed: true,
  navHovered: false,
  screenChanged: 0,
  isCheckingDemoProfile: true, // can stay here
  hasNoRealCPs: false,
  hasNoDemoCPs: false,
  notification: {
    text: "",
    key: undefined,
  },
  userCountry: undefined,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_STATE: {
      return { ...state, [action.payload.key]: action.payload.value };
    }
    case types.INC_SCREEN_CHANGE: {
      return { ...state, screenChanged: state.screenChanged + 1 };
    }
    case types.ADD_NOTIFICATION: {
      return { ...state, notification: action.payload };
    }
    default:
      return { ...state };
  }
};

export default appReducer;
