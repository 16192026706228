import { PurchaseOrderShipmentTransitStatus } from "constants/purchaseOrders";
import {
  SortableField,
  SortedByMilestone,
  ShipmentTransitStatus,
  ShipmentMode,
  MilestonesDateRangeFilters,
} from "constants/shipments";
import { LocationByQuery } from "constants/subscription";

import { SortDirection } from "react-lib";

export enum PurchaseOrdersActiveTab {
  ORDERS = "orders",
  ORDER_LINES = "orderLines",
}

export interface PurchaseOrdersFilters extends MilestonesDateRangeFilters {
  favorite?: boolean;
  delayStatus?: string;
  order?: SortedByMilestone[];
  transitStatus?: Array<
    ShipmentTransitStatus | PurchaseOrderShipmentTransitStatus
  >;
  transportMode?: ShipmentMode[];
  origin?: LocationByQuery;
  destination?: LocationByQuery;
  limit?: number;
  offset?: number;
  query?: string;
}

export interface PurchaseOrdersState {
  [PurchaseOrdersActiveTab.ORDERS]: PurchaseOrdersFilters;
  [PurchaseOrdersActiveTab.ORDER_LINES]: PurchaseOrdersFilters;
  activeTab: PurchaseOrdersActiveTab;
  scrollPosition: number;
}

export const initialState: PurchaseOrdersState = {
  [PurchaseOrdersActiveTab.ORDERS]: {
    order: [
      { field: SortableField.PICKUP_COMBINED, order: SortDirection.DESCENDING },
    ],
    limit: 20,
    offset: 0,
  },
  [PurchaseOrdersActiveTab.ORDER_LINES]: {
    order: [
      { field: SortableField.PICKUP_COMBINED, order: SortDirection.DESCENDING },
    ],
    limit: 20,
    offset: 0,
  },
  activeTab: PurchaseOrdersActiveTab.ORDERS,
  scrollPosition: 0,
};

export enum PurchaseOrdersActionType {
  SET_PURCHASE_ORDERS_ACTIVE_TAB = "PURCHASE_ORDERS/SET_PURCHASE_ORDERS_ACTIVE_TAB",
  SET_MILESTONES_DATE_RANGE_FILTERS = "PURCHASE_ORDERS/SET_MILESTONES_DATE_RANGE_FILTERS",
  SET_PURCHASE_ORDERS_QUERY = "PURCHASE_ORDERS/SET_PURCHASE_ORDERS_QUERY",
  SET_PURCHASE_ORDERS_LIST_SORT = "PURCHASE_ORDERS/SET_PURCHASE_ORDERS_LIST_SORT",
  SET_PURCHASE_ORDERS_LIST_FILTERS = "PURCHASE_ORDERS/SET_PURCHASE_ORDERS_LIST_FILTERS",
  SET_FAVORITE_FILTER = "PURCHASE_ORDERS/SET_FAVORITE_FILTER",
  SET_SCROLL_POSITION = "PURCHASE_ORDERS/SET_SCROLL_POSITION",
}

export type PurchaseOrdersAction =
  | {
      type: PurchaseOrdersActionType.SET_PURCHASE_ORDERS_ACTIVE_TAB;
      activeTab: PurchaseOrdersActiveTab;
    }
  | {
      type: PurchaseOrdersActionType.SET_MILESTONES_DATE_RANGE_FILTERS;
      milestonesDateRangeFilters: MilestonesDateRangeFilters | undefined;
    }
  | {
      type: PurchaseOrdersActionType.SET_PURCHASE_ORDERS_QUERY;
      query?: string;
    }
  | {
      type: PurchaseOrdersActionType.SET_PURCHASE_ORDERS_LIST_SORT;
      field: SortableField;
      order: SortDirection;
    }
  | {
      type: PurchaseOrdersActionType.SET_PURCHASE_ORDERS_LIST_FILTERS;
      filters: PurchaseOrdersFilters;
    }
  | {
      type: PurchaseOrdersActionType.SET_FAVORITE_FILTER;
      isFavorite: boolean;
    }
  | {
      type: PurchaseOrdersActionType.SET_SCROLL_POSITION;
      scrollPosition: number;
    };
