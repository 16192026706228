import { AddressInputType } from "constants/bookWorkflow";
import React, { Dispatch, SetStateAction } from "react";
import { IntlShape } from "react-intl";
import {
  ActiveFilter,
  ListElement,
} from "react-lib/@types/organisms/FiltersList/FiltersList.constants";
import { ListElementTypeEnum } from "react-lib/es/organisms/FiltersList/FiltersList.constants";
import messages from "./AddressBook.messages";

export interface AddressBookModalProps {
  onAddressConfirm: (confirmedRow: UserAddressBook) => void;
  closeAddressBookModal: () => void;
  openAddAddressModal: () => void;
  openEditAddressModal: (address: UserAddressBook) => void;
  openDeleteAddressModal: (address: UserAddressBook) => void;
  countriesPossibleToSelect: string[];
  setSnackbarError: Dispatch<React.SetStateAction<string | undefined>>;
  fieldPrefix: string;
}

export interface AddressBookTableProps {
  setSelectedAddress: (selectedAddress: UserAddressBook | null) => void;
  openAddAddressModal: () => void;
  openEditAddressModal: (address: UserAddressBook) => void;
  openDeleteAddressModal: (address: UserAddressBook) => void;
  activeFilters: ActiveFilter[];
  setSelectedFilterId: (id: string) => void;
  hideFilters: () => void;
  showFilters: () => void;
}

export interface UserAddressBookResponse {
  getUserAddressBook: {
    addresses: UserAddressBook[];
    page: UserAddressBookPageInfo;
  };
}

export interface AddressBookFilterCol {
  column: string;
  values: string[];
}

export interface AddressBookFiltersResponse {
  getUserAddressBookFilters: AddressBookFilterCol[];
}

export interface UserAddressBookPageInfo {
  count: number;
  page: number;
  size: number;
  totalCount: number;
}

export interface UserAddressBook {
  address: UserAddressBookAddress;
  addressFlags: UserAddressBookAddressFlag;
  contact: UserAddressBookContact;
  id: number;
  concatAddress?: string;
  type?: string;
  country?: string;
}

export interface UserAddressBookAddress {
  accountNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  companyName?: string;
  countryCode?: string;
  postalCode?: string;
  reference?: string;
  stateProvince?: string;
  preferredAddressInputType?: AddressInputType;
}

export interface UserAddressBookAddressFlag {
  isBilling: boolean;
  isConsignee: boolean;
  isDelivery: boolean;
  isPickup: boolean;
  isShipper: boolean;
  isNotify: boolean;
  __typename?: string;
}

export interface UserAddressBookContact {
  contactName: string;
  email: string;
  phone: string;
  reference: string;
}

export type UserAddressBookAddressRowData = Omit<
  UserAddressBookAddress,
  "accountNumber"
> & {
  concatAddress: string;
  type: string;
  country: string;
};

export interface ImprovedTableColumn {
  name: string;
  renderer?: (rowData: UserAddressBookAddressRowData) => JSX.Element;
  headerRenderer: (column: ImprovedTableColumn) => JSX.Element;
  displayName: string;
  emptyFallback: React.ReactNode;
}

export interface RowContentRendererProps {
  columns: ImprovedTableColumn[];
  row: UserAddressBook;
  rowIndex: number;
  hoveredRowId: number;
  setHoveredRowId: Dispatch<SetStateAction<number>>;
  clickedRowId: number;
  setClickedRowId: Dispatch<SetStateAction<number>>;
  intl: IntlShape;
  selectMenuOptionForListItem: (id: number, menuOption: RowMenuOptions) => void;
}

export interface HeaderContentRendererProps {
  columns: ImprovedTableColumn[];
}

export interface MenuOption {
  value: string;
  label: string;
  icon?: React.ReactElement;
}

export interface Config {
  VALUE: string;
  KEY: string;
  __typename: "Config";
}

export const AddressBookDefaultSorting = "addressbook.default.sorting";

export enum AddressFlag {
  isBilling = "isBilling",
  isConsignee = "isConsignee",
  isDelivery = "isDelivery",
  isPickup = "isPickup",
  isShipper = "isShipper",
  isNotify = "isNotify",
}

export enum SmallColumnsNames {
  reference = "reference",
  type = "type",
  stateProvince = "stateProvince",
}

export enum RowMenuOptions {
  EDIT = "edit",
  DELETE = "delete",
}

export const UserAddressBookQueryPageSize = 10;
export const ModalContainerWidth = "80rem";

export const defaultOrderingParameter = "companyName";

export const defaultOrderingDirection = "ASC";

export enum ColumnNameEnum {
  COMPANY_NAME = "companyName",
  ADDRESS = "address",
  COUNTRY_CODE = "countryCode",
  TYPE = "type",
  REFERENCE = "reference",
}

export type ListElementSchema = ListElement & {
  columnName: ColumnNameEnum;
};

export interface CountriesDataObjForFilters {
  name: string;
  code: string;
}

export const generateFiltersConfig = (
  intl: IntlShape,
  isMobile: boolean
): ListElementSchema[] => [
  {
    columnName: ColumnNameEnum.COMPANY_NAME,
    title: intl.formatMessage(messages.company),
    placeholder: intl.formatMessage(messages.enterCompanyName),
    id: "companyName",
    type: ListElementTypeEnum.FREE_TEXT,
    noValueMessage: intl.formatMessage(messages.noTagsFound),
    applyText: intl.formatMessage(messages.apply),
  },
  {
    columnName: ColumnNameEnum.ADDRESS,
    title: intl.formatMessage(messages.address),
    placeholder: intl.formatMessage(messages.enterAddress),
    id: "address",
    type: ListElementTypeEnum.FREE_TEXT,
    noValueMessage: intl.formatMessage(messages.noTagsFound),
    applyText: intl.formatMessage(messages.apply),
  },
  ...(!isMobile
    ? [
        {
          columnName: ColumnNameEnum.COUNTRY_CODE,
          title: intl.formatMessage(messages.country),
          id: "countryCode",
          type: ListElementTypeEnum.MULTI_SELECT,
          noValueMessage: intl.formatMessage(messages.noTagsFound),
          tagsList: [],
          intlText: {
            applyText: intl.formatMessage(messages.apply),
            clearText: intl.formatMessage(messages.clear),
            noResultText: intl.formatMessage(messages.noResult),
            noFilterSelectedText: intl.formatMessage(messages.noFilterSelected),
            placeholderText: intl.formatMessage(messages.searchFor),
          },
        },
        {
          columnName: ColumnNameEnum.TYPE,
          title: intl.formatMessage(messages.type),
          id: "type",
          type: ListElementTypeEnum.MULTI_SELECT,
          noValueMessage: intl.formatMessage(messages.noTagsFound),
          tagsList: [],
          intlText: {
            applyText: intl.formatMessage(messages.apply),
            clearText: intl.formatMessage(messages.clear),
            noResultText: intl.formatMessage(messages.noResult),
            noFilterSelectedText: intl.formatMessage(messages.noFilterSelected),
            placeholderText: intl.formatMessage(messages.searchFor),
          },
        },
        {
          columnName: ColumnNameEnum.REFERENCE,
          title: intl.formatMessage(messages.referenceId),
          placeholder: intl.formatMessage(messages.enterReference),
          id: "reference",
          type: ListElementTypeEnum.FREE_TEXT,
          noValueMessage: intl.formatMessage(messages.noTagsFound),
          applyText: intl.formatMessage(messages.apply),
        },
      ]
    : []),
];
