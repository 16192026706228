import getConfigClient from "constants/getConfigClient";
import axios from "axios";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import { useEffect, useState, useRef } from "react";
import {
  convertReportingFilterFromFrontendToPreview,
  convertReportingFilterWithGroupsFromFrontendToPreview,
} from "utils/reporting";
import useCorporatePartnerUUID from "./useCorporatePartnerUUID";

const endpoint = getConfigClient("NODE_ENDPOINT");
export const SHOW_MAX_ROWS = 50;

const getQuery = (thisTemplateName, joinedCols) => `
  query getData(
    $filter: ${thisTemplateName}_filter
    $sorts: [${thisTemplateName}_sort!]
    $limit: Int
    $offset: Int
    $params: [Param!]
  ) {
    get_${thisTemplateName}_list(
    filter: $filter
    sorts: $sorts
    limit: $limit
    offset: $offset
    params: $params
  ) {
      page {
        limit
        offset
        count
        totalCount
        __typename
      }
      data {
        ${joinedCols}
        __typename
      }
      __typename
    }
  }
`;

const getQueryWithFilterGroups = (thisTemplateName, joinedCols) => `
  query getData(
    $filter: [${thisTemplateName}_filter]
    $sorts: [${thisTemplateName}_sort!]
    $limit: Int
    $offset: Int
    $params: [Param!]
  ) {
    get_${thisTemplateName}_list_filter_groups(
    filter: $filter
    sorts: $sorts
    limit: $limit
    offset: $offset
    params: $params
  ) {
      page {
        limit
        offset
        count
        totalCount
        __typename
      }
      data {
        ${joinedCols}
        __typename
      }
      __typename
    }
  }
`;

const useReportingPreview = ({
  columnLookup,
  templateName,
  columns,
  sorts,
  filters,
  pageSize = SHOW_MAX_ROWS,
  initialPageSize = pageSize,
  onBeforeFetch,
  onAfterFetch,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [rows, setRows] = useState(null);
  const [count, setCount] = useState(null);
  const corporatePartnerUUID = useCorporatePartnerUUID();
  const usedDependencyData = useRef(null);
  const [loadMore, setLoadMore] = useState(() => noop);

  useEffect(() => {
    if (!templateName || !columns || !columnLookup) return;
    if (!columns.length) {
      setRows([]);
      return;
    }

    const joinedCols = columns.map((col) => col.name).join("\n");

    const isFilterDisjunctionEnabled = getConfigClient(
      "FEATURE_REPORTING_FILTER_DISJUNCTION"
    );

    const responseDataProperty = isFilterDisjunctionEnabled
      ? `get_${templateName}_list_filter_groups`
      : `get_${templateName}_list`;

    const query = isFilterDisjunctionEnabled
      ? getQueryWithFilterGroups(templateName, joinedCols)
      : getQuery(templateName, joinedCols);

    const nextFilters = isFilterDisjunctionEnabled
      ? convertReportingFilterWithGroupsFromFrontendToPreview(filters)
      : convertReportingFilterFromFrontendToPreview(filters);

    const dependencyData = {
      nextFilters,
      templateName,
      columns,
      sorts,
    };

    if (isEqual(usedDependencyData.current, dependencyData)) {
      return;
    }
    usedDependencyData.current = dependencyData;

    onBeforeFetch?.();
    const url = `${endpoint}reporting/get-data/${templateName}/graphql`;

    const getData = (offset, limit) => ({
      operationName: "getData",
      query,
      variables: {
        sorts,
        filter: nextFilters,
        params: [{ key: "corporatePartnerUUID", value: corporatePartnerUUID }],
        offset,
        limit,
      },
    });

    setIsLoading(true);
    axios
      .post(url, getData(0, initialPageSize))
      .then((response) => {
        if (response.data && response.data.errors) {
          throw new Error(JSON.stringify(response.data.errors));
        }
        const list = response?.data?.data?.[responseDataProperty];
        setRows(list?.data || []);
        setCount(list.page.count);
        let offset = initialPageSize;
        let isBlocked = false;
        setLoadMore(() => () => {
          if (isBlocked) return;
          isBlocked = true;
          setIsLoadingMore(true);
          axios
            .post(url, getData(offset, pageSize))
            .then((response) => {
              const responseData = response?.data?.data?.[responseDataProperty];
              setRows((oldList) => [...oldList, ...responseData.data]);
              offset += pageSize;
              setCount(responseData.page.count);
            })
            .finally(() => {
              setIsLoadingMore(false);
              isBlocked = false;
            });
        });
      })
      .finally(() => {
        setIsLoading(false);
        onAfterFetch?.();
      })
      .catch((error) => {
        setLoadMore(() => noop);
        setCount(0);
        // TODO proper error handling
        // eslint-disable-next-line no-console
        console.log("Failed to load template", error);
      });
  }, [
    columns,
    columnLookup,
    templateName,
    filters,
    onBeforeFetch,
    initialPageSize,
    corporatePartnerUUID,
    pageSize,
    onAfterFetch,
    sorts,
  ]);

  return {
    rows,
    isLoading: !rows || isLoading,
    hasMore: count === pageSize || count === null,
    isLoadingMore,
    loadMore,
  };
};

export default useReportingPreview;
