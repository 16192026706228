import capitalize from "lodash/capitalize";
import isString from "lodash/isString";
import * as C from "./constants";

const splitPathName = (pathName: string): string[] => {
  if (pathName === C.RootPath.DASHBOARD) {
    return [C.RootPath.DASHBOARD];
  }

  return pathName.split("/").filter((pathPart) => pathPart);
};

const parseToPageNameFormat = (
  rootPath: C.RootPath,
  nestedPath: string
): string => {
  const parsedNestedPath = nestedPath
    .split("-")
    .map((path) => capitalize(path))
    .join(" ");

  return `${C.RootPathToPageNameMapper[rootPath]} - ${parsedNestedPath}`;
};

const removeSearchParamsWithSensitiveData = (search?: string): string => {
  if (isString(search)) {
    const params = new URLSearchParams(search);
    C.sensitiveDataSearchParams.forEach((searchParam) => {
      params.delete(searchParam);
    });

    return params.toString() ? `?${params.toString()}` : "";
  }

  return "";
};

export const getPageNameForBsa = (pathName: string) => {
  return pathName.split("/")?.find((el) => C.BsaRoutes.includes(el)) || "";
};

export const getPageNameFromPathName = (pathName: string): string => {
  const pathsArray = splitPathName(pathName);
  const [rootPath, nestedPath, thirdLevelPath] = pathsArray;

  if (rootPath === C.RootPath.BSA) {
    const pageName = getPageNameForBsa(pathName);
    return pageName.length
      ? `${pageName?.charAt(0)?.toUpperCase()}${pageName?.slice(1)}`
      : pageName;
  } else if (
    Object.values(C.RootPath).includes(rootPath as C.RootPath) &&
    !thirdLevelPath
  ) {
    if (!nestedPath) {
      return C.RootPathToPageNameMapper[rootPath as C.RootPath];
    } else if (
      C.NestedPathsForRootPaths[rootPath as C.NestedPathsType]?.includes(
        nestedPath
      )
    ) {
      return parseToPageNameFormat(rootPath as C.RootPath, nestedPath);
    }
  }

  return `${C.pageNamePrefix} ${
    location.pathname
  }${removeSearchParamsWithSensitiveData(location.search)}`;
};

const getSection = (pathName: string): string => {
  return pathName.includes(C.RootPath.BSA) ? "Book Standalone" : "Portal";
};

const getCategory = (pathName: string): string => {
  return pathName.includes(C.RootPath.BSA) ? "Sales" : "Customer Portal";
};

const getFunnel = (pathName: string) => {
  const pageName = getPageNameForBsa(pathName);
  const progressNumber = [...C.BsaRoutes.values()].indexOf(pageName) + 1;
  return pathName.includes(C.RootPath.BSA)
    ? {
        funnel: {
          type: "Sales",
          name: "Book Standalone",
          step: getPageNameFromPathName(pathName),
          progress: `${progressNumber} of 6`,
        },
      }
    : {};
};

export const getUserType = (
  isLoggedIn: boolean,
  email?: string
): C.UserType => {
  if (!isLoggedIn || !email) {
    return C.UserType.ANONYMOUS;
  }
  const [, emailDomain] = email.split("@");

  return C.internalEmailDomains.includes(emailDomain)
    ? C.UserType.INTERNAL
    : C.UserType.EXTERNAL;
};

export const dispatchEvent = (eventName: string): void => {
  const event = new CustomEvent(eventName, { bubbles: true });
  window.dispatchEvent(event);
};

export const prepareDefaultDataLayerObject = (
  isLoggedIn: boolean,
  loggedInUserEmail: string,
  language: string,
  location: C.LocationObject
) => ({
  user: {
    status: isLoggedIn ? C.UserStatus.LOGGED_IN : C.UserStatus.LOGGED_OUT,
    type: getUserType(isLoggedIn, loggedInUserEmail),
  },
  page: {
    title: document.title,
    country: "Global",
    language,
    finding_method:
      location.state?.analyticsFindingMethodMenu || C.FindingMethod.NONE,
    publisher: "DSC",
    name: getPageNameFromPathName(location.pathname),
    section: getSection(location.pathname),
    category: getCategory(location.pathname),
    ...getFunnel(location.pathname),
  },
});
