import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "ExportShipmentsInteractions";

export const ExportShipmentsInteractions = {
  CLOSE: { id: `${prefix}_Close`, name: "Close" },
  SELECT_ALL: { id: `${prefix}_Select All`, name: "Select All" },
  FIELD: { id: `${prefix}_Field`, name: "Field" },
  CANCEL: { id: `${prefix}_Cancel`, name: "Cancel" },
  EXPORT: { id: `${prefix}_Export`, name: "Export" },
};

enum ExportShipmentsPosition {
  HEADER = "Header",
  FIELD_LIST = "Field List",
  FOOTER = "Footer",
}

const exportShipmentsContext = "Export Shipments";

export const exportShipmentsEventNameToExportShipmentsEventContentMapper: C.EventNameToContentMapperType<
  typeof ExportShipmentsInteractions
> = {
  [ExportShipmentsInteractions.CLOSE.id]: {
    name: ExportShipmentsInteractions.CLOSE.name,
    ...eventContentButtonInterObj,
    position: ExportShipmentsPosition.HEADER,
    context: exportShipmentsContext,
  },
  [ExportShipmentsInteractions.SELECT_ALL.id]: {
    name: ExportShipmentsInteractions.SELECT_ALL.name,
    ...eventContentButtonInterObj,
    position: ExportShipmentsPosition.FIELD_LIST,
    context: exportShipmentsContext,
  },
  [ExportShipmentsInteractions.FIELD.id]: {
    name: ExportShipmentsInteractions.FIELD.name,
    ...eventContentButtonInterObj,
    position: ExportShipmentsPosition.FIELD_LIST,
    context: exportShipmentsContext,
  },
  [ExportShipmentsInteractions.CANCEL.id]: {
    name: ExportShipmentsInteractions.CANCEL.name,
    ...eventContentButtonInterObj,
    position: ExportShipmentsPosition.FOOTER,
    context: exportShipmentsContext,
  },
  [ExportShipmentsInteractions.EXPORT.id]: {
    name: ExportShipmentsInteractions.EXPORT.name,
    ...eventContentButtonInterObj,
    position: ExportShipmentsPosition.FOOTER,
    context: exportShipmentsContext,
  },
};
