import actionTypes from "./action-types";

const initialState = {
  searchMapText: "",
  showMapSearchResult: false,
  expandMapSearchResult: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MAP_SEARCH:
      return {
        ...state,
        searchMapText: action.payload.text,
      };
    case actionTypes.SHOW_HIDE_MAP_SEARCH_RESULT:
      return {
        ...state,
        showMapSearchResult: action.payload.state,
      };
    case actionTypes.EXPAND_COLLAPSE_MAP_SEARCH_RESULT:
      return {
        ...state,
        expandMapSearchResult: action.payload.state,
      };
    default:
      return state;
  }
};

export default reducer;
