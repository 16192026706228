import { useEffect, useState } from "react";

const useDomElementObserver = (config: {
  observeChildList?: boolean;
  observeAttributes?: boolean;
  attributeName: string;
  childId?: string;
}) => {
  const [attributeValue, setAttributeValue] = useState<string | null>(null);
  const [childAdded, setChildAdded] = useState<boolean>(false);
  const [observedChild, setObservedChild] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const body = document.body;
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          config.observeAttributes &&
          mutation.type === "attributes" &&
          mutation.attributeName === config.attributeName
        ) {
          setAttributeValue(body.getAttribute(config.attributeName));
        }

        if (config.observeChildList && mutation.type === "childList") {
          mutation.addedNodes.forEach((node: Node) => {
            if (
              node.nodeType === 1 &&
              (!config.childId ||
                (node as HTMLElement).getAttribute(config.attributeName) ===
                  config.childId)
            ) {
              setChildAdded(true);
              setObservedChild(node as HTMLElement);
            }
          });
        }
      }
    });

    const observerConfig = {
      attributes: config.observeAttributes || true,
      childList: config.observeChildList || false,
      attributeFilter: config.observeAttributes
        ? [config.attributeName]
        : undefined,
    };

    observer.observe(body, observerConfig);

    return () => {
      observer.disconnect();
    };
  }, [
    config.attributeName,
    config.childId,
    config.observeAttributes,
    config.observeChildList,
  ]);

  return {
    attributeValue,
    childAdded,
    observedChild,
  };
};

export default useDomElementObserver;
