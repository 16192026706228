export interface QuoteAndBookNonSyncState {
  saveConsignmentAPICount: number;
  recalculateQuoteAPICount: number;
  calculateQuoteAPICount: number;
  saveAndGetConsignmentAPICount: number;
  recalculatingQuote: boolean;
  dataChangedCount: number;
  recalculationErrorAlready: {
    404: boolean;
    410: boolean;
  };
  contractId: string | undefined;
  userCountryViaGeoLocation: object | undefined;
  isR2R: boolean | undefined;
  CPResolved: boolean | undefined;
  consignment: {
    offerInfo?: {
      ratesType?: string;
      serviceType?: string;
    };
    consignmentId?: string;
    consignmentUuid?: string;
    charges?: {
      total?: string;
      mainCarriage?: string;
    };
    currency?: string;
  };
  previousRatesType: undefined | string;
  isGetOffersLoading: boolean;
}

export const enum ActionsType {
  INCREMENT_SAVE_CONSIGNMENT_API_COUNT = `QUOTE_AND_BOOK_NON_SYNC/INCREMENT_SAVE_CONSIGNMENT_API_COUNT`,
  INCREMENT_RECALCULATE_QUOTE_API_COUNT = `QUOTE_AND_BOOK_NON_SYNC/INCREMENT_RECALCULATE_QUOTE_API_COUNT`,
  INCREMENT_CALCULATE_QUOTE_API_COUNT = `QUOTE_AND_BOOK_NON_SYNC/INCREMENT_CALCULATE_QUOTE_API_COUNT`,
  INCREMENT_GET_CONSIGNMENT_API_COUNT = `QUOTE_AND_BOOK_NON_SYNC/INCREMENT_GET_CONSIGNMENT_API_COUNT`,
  INCREMENT_DATA_CHANGED_COUNT = `QUOTE_AND_BOOK_NON_SYNC/INCREMENT_DATA_CHANGED_COUNT`,
  SET_RECALCULATING_QUOTE = `QUOTE_AND_BOOK_NON_SYNC/SET_RECALCULATING_QUOTE`,
  SET_RECALCULATION_ERROR = `QUOTE_AND_BOOK_NON_SYNC/SET_RECALCULATION_ERROR`,
  RESET_RECALCULATION_ERROR = `QUOTE_AND_BOOK_NON_SYNC/RESET_RECALCULATION_ERROR`,
  SET_CONTRACT_ID = `QUOTE_AND_BOOK_NON_SYNC/SET_CONTRACT_ID`,
  SET_USER_COUNTRY_VIA_GEO_LOCATION = `QUOTE_AND_BOOK_NON_SYNC/SET_USER_COUNTRY_VIA_GEO_LOCATION`,
  SET_IS_R2R = `QUOTE_AND_BOOK_NON_SYNC/SET_IS_R2R`,
  SET_CP_RESOLVED = `QUOTE_AND_BOOK_NON_SYNC/SET_CP_RESOLVED`,
  SAVE_CONSIGNMENT_FORM = `QUOTE_AND_BOOK_NON_SYNC/SAVE_CONSIGNMENT_FORM`,
  RESET_CONSIGNMENT_DATA = `QUOTE_AND_BOOK_NON_SYNC/RESET_CONSIGNMENT_DATA`,
  SET_GET_OFFERS_LOADING = `QUOTE_AND_BOOK_NON_SYNC/SET_GET_OFFERS_LOADING`,
}

export type QuoteAndBookNonSyncActions =
  | {
      type: ActionsType.INCREMENT_SAVE_CONSIGNMENT_API_COUNT;
    }
  | {
      type: ActionsType.INCREMENT_RECALCULATE_QUOTE_API_COUNT;
      payload: string;
    }
  | {
      type: ActionsType.INCREMENT_CALCULATE_QUOTE_API_COUNT;
    }
  | {
      type: ActionsType.INCREMENT_GET_CONSIGNMENT_API_COUNT;
    }
  | {
      type: ActionsType.INCREMENT_DATA_CHANGED_COUNT;
    }
  | {
      type: ActionsType.SET_RECALCULATING_QUOTE;
      payload: boolean;
    }
  | {
      type: ActionsType.SET_RECALCULATION_ERROR;
      payload: {
        value?: boolean;
        code: string;
      };
    }
  | {
      type: ActionsType.SET_CONTRACT_ID;
      payload: {
        contractId?: string;
        fromPath?: string;
        locale?: string;
      };
    }
  | {
      type: ActionsType.SET_USER_COUNTRY_VIA_GEO_LOCATION;
      payload: object;
    }
  | {
      type: ActionsType.SET_IS_R2R;
      payload: boolean;
    }
  | {
      type: ActionsType.SET_CP_RESOLVED;
      payload: boolean;
    }
  | {
      type: ActionsType.SAVE_CONSIGNMENT_FORM;
      payload: object;
      meta: {
        debounce: {
          time: number;
        };
      };
    }
  | {
      type: ActionsType.RESET_CONSIGNMENT_DATA;
    }
  | {
      type: ActionsType.RESET_RECALCULATION_ERROR;
    }
  | {
      type: ActionsType.SET_GET_OFFERS_LOADING;
      payload: boolean;
    };
