/* eslint-disable react-hooks/exhaustive-deps */ // TODO remove this and handle this independently in each hook
import { ReactComponent as DownIcon } from "assets/icons/myDHLi/chevron-down.svg";
import { ReactComponent as UpIcon } from "assets/icons/myDHLi/chevron-up.svg";
import Gap from "components/atoms/Gap";
import SmallText from "components/atoms/SmallText";
import SVGWrapper from "components/atoms/SVGWrapper";
import FlyOutListSearch from "components/molecules/FlyOut/FlyOutListSearch";
import useOutsideClick from "hooks/useOutsideClick";
import PropTypes from "prop-types";
import React, { useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { saveVisibilityScope } from "redux/userSettings/actions";
import styled from "styled-components";
import { getRem } from "utils/css";
import get from "utils/get";
import { startsWithAnyOf } from "utils/string";
import { scopeSelectorWrapperStyles } from "./styles";

const Wrapper = styled.div`
  flex: 0 0 auto;
  padding-right: ${getRem(20)};
  position: relative;
`;

const ScopeSelector = (props) => {
  const { corporatePartners } = props;
  const isDemoMode = props.visibilityScope?.demo;
  const [flyOutVisible, setFlyOutVisible] = useState(false);
  const groups = useMemo(
    () => corporatePartners.filter((x) => x.demo === isDemoMode),
    [corporatePartners, isDemoMode]
  );

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setFlyOutVisible(false));
  const location = useLocation();
  const shouldHideCPSwitcher = startsWithAnyOf(location.pathname, [
    "/offers",
    "/booking/",
    "/terms-and-conditions",
    "/continue-with-quote",
  ]);
  return props.belowLg || props.isCheckingDemoProfile ? (
    <span />
  ) : (
    <Wrapper ref={wrapperRef}>
      {groups.length > 1 && (
        <span
          onClick={() => setFlyOutVisible(!flyOutVisible)}
          style={{ cursor: "pointer" }}
        >
          <SmallText>{get(props, "visibilityScope.name")} </SmallText>
          <Gap />
          {!shouldHideCPSwitcher && (
            <SVGWrapper height={getRem(20)} width={getRem(20)} showPointer>
              {flyOutVisible ? (
                <UpIcon key="up-icon" />
              ) : (
                <DownIcon key="down-icon" />
              )}
            </SVGWrapper>
          )}
        </span>
      )}
      {!shouldHideCPSwitcher && groups.length > 1 ? (
        <FlyOutListSearch
          items={groups.map((item, i) => ({
            key: item.key,
            text: item.name,
            onClick: () => {
              props.saveVisibilityScope(item, item.demo);
            },
          }))}
          setFlyOutVisible={setFlyOutVisible}
          visible={flyOutVisible}
          containerWidth={32.0}
          arrowRight={4.0}
          containerRight={3}
          containerTop={7}
          wrapperPadding=""
          flyoutItemPadding={`${getRem(17)} ${getRem(24)} ${getRem(
            17
          )} ${getRem(24)}`}
          wrapperStyles={scopeSelectorWrapperStyles}
          disableOutsideClick
        />
      ) : null}
    </Wrapper>
  );
};
ScopeSelector.defaultProps = {
  visibilityScope: {},
  corporatePartners: [],
};
ScopeSelector.propTypes = {
  visibilityScope: PropTypes.instanceOf(Object),
  corporatePartners: PropTypes.instanceOf(Array),
};

export default connect(
  (state) => ({
    visibilityScope: get(state, "userSettings.visibilityScope"),
    corporatePartners: get(state, "userSettings.corporatePartners"),
    isCheckingDemoProfile: state.app.isCheckingDemoProfile,
  }),
  { saveVisibilityScope }
)(ScopeSelector);
