import { useMutation } from "@apollo/client";
import useCorporatePartnerUUID from "hooks/useCorporatePartnerUUID";
import { editAddressInUsersAddressBookMutation } from "queries/MakeABooking/userAddressBookQueries";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { parseFormValuesToRequestSchema } from "../AddAddressModal/AddAddressModal.helpers";
import AddressBookForm from "../components/AddressBookModalContent/AddressBookForm/AddressBookForm";
import {
  AddressBookFormSchema,
  initialFormValues,
} from "../components/AddressBookModalContent/AddressBookForm/AddressBookFormFields/AddressBookFormFields.constants";
import * as C from "./EditAddressModal.constants";
import * as H from "./EditAddressModal.helpers";
import messages from "./EditAddressModal.messages";

const EditAddressModal = ({
  goToAddressBook,
  showSuccessToastMessage,
  countryList,
  initialValues,
  showDeleteAddressModal,
}: C.EditAddressModalProps) => {
  const intl = useIntl();

  const [initialValuesInFormSchema, setInitialInitialValuesInFormSchema] =
    useState<AddressBookFormSchema>(initialFormValues);
  const corporatePartner = useCorporatePartnerUUID() || "";

  useEffect(() => {
    const parsedFormValue = H.parseListValueToFormSchema(
      initialValues,
      countryList
    );
    return setInitialInitialValuesInFormSchema(parsedFormValue);
  }, [initialValues, countryList]);

  const [editAddressToUsersAddressBook, { loading: isEditing }] = useMutation(
    editAddressInUsersAddressBookMutation
  );

  const onEditAddress = (formValues: AddressBookFormSchema): void => {
    const parsedValues = parseFormValuesToRequestSchema({
      ...formValues,
      id: initialValues.id,
    });
    editAddressToUsersAddressBook({
      variables: {
        newAddress: {
          ...parsedValues.addressBook,
          id: parsedValues.id,
          corporatePartnerUUID: corporatePartner,
        },
      },
    }).then(() => {
      showSuccessToastMessage();
      goToAddressBook();
    });
  };

  return (
    <AddressBookForm
      formHeader={intl.formatMessage(messages.editAddress)}
      onFormSubmit={onEditAddress}
      onDelete={showDeleteAddressModal}
      onCancel={goToAddressBook}
      onBackArrowClick={goToAddressBook}
      formInitialValues={initialValuesInFormSchema}
      isDeletable
      isDisabled={isEditing}
    />
  );
};

export default EditAddressModal;
