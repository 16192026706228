import qs from "query-string";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setCountry } from "redux/quoteAndBook/actions";
import { useQuoteCountryOptions } from "./useQuoteCountryOptions";

const useQueryStringResolver = () => {
  const [countryProcessed, setCountryProcessed] = useState(false);
  const [analyticsProcessed, setAnalyticsProcessed] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const queryString = location?.search;
  const countries = useQuoteCountryOptions();

  useEffect(() => {
    if (queryString && (!countryProcessed || !analyticsProcessed)) {
      const queryObject = qs.parse(queryString);
      if (queryObject.country || queryObject.analytics) {
        if (!queryObject.country) {
          !countryProcessed && setCountryProcessed(true);
        }
        if (countries.length > 0 && queryObject.country) {
          const matchedCountry = countries.find(
            (country) =>
              country.value.toLowerCase() ===
                queryObject.country.toLowerCase() && country.isBilling
          );
          if (matchedCountry) {
            dispatch(
              setCountry(
                { label: matchedCountry.label, value: matchedCountry.value },
                "QUERY_STRING"
              )
            );
          }
          !countryProcessed && setCountryProcessed(true);
        }
        if (queryObject.analytics && !analyticsProcessed) {
          !analyticsProcessed && setAnalyticsProcessed(true);
        }
      }
    }
  }, [queryString, countries, countryProcessed, analyticsProcessed, dispatch]);
};

export default useQueryStringResolver;
