import { AnimatePresence } from "framer-motion";
import React from "react";
import { Backdrop, ModalContainer } from "react-lib";
import * as C from "./AddressModal.constants";

const AddressModal = ({
  isModalOpen,
  className,
  children,
}: C.AddressModalProps) => (
  <AnimatePresence>
    {isModalOpen && (
      <Backdrop className={className}>
        <ModalContainer width={C.addressBookModalWidth}>
          {children}
        </ModalContainer>
      </Backdrop>
    )}
  </AnimatePresence>
);

export default AddressModal;
