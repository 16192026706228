import { motion } from "framer-motion";
import styled from "styled-components";
import { getRem, getRemMedia } from "utils/css";

const Dialog = styled(motion.div)`
  position: relative;
  min-height: 100vh;
  outline: 0;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  width: 100%;
  cursor: default;
  background: white;
  margin-bottom: 0;
  margin-top: ${(p) => (p.marginTop ? p.marginTop : 0)};
  @media (min-width: ${getRemMedia(880.2)}) {
    left: 50%;
    margin-left: ${getRem(-880 / 2)};
    width: ${getRem(880)};
    margin-bottom: ${getRem(40)};
    min-height: calc(100vh - ${getRem(40)});
  }
  box-shadow: 0 ${getRem(16)} ${getRem(64)} 0 rgba(0, 0, 0, 0.05),
    0 ${getRem(4)} ${getRem(16)} 0 rgba(0, 0, 0, 0.05),
    0 ${getRem(1)} ${getRem(4)} 0 rgba(0, 0, 0, 0.05);
`;

export default Dialog;
