import {
  eventContentButtonInterObj,
  eventContentPageOverlayInterObj,
} from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "BookingFinalizeInteractions";

export const BookingFinalizeInteractions = {
  COMPLETE_BOOKING: {
    id: `${prefix}_Complete Booking`,
    name: "Complete Booking",
  },
  THANK_YOU: {
    id: `${prefix}_Thank You`,
    name: "Thank You",
  },
  PRINT_LABEL: { id: `${prefix}_Print Label`, name: "Print Label" },
  PRINT_MANIFEST: { id: `${prefix}_Print Manifest`, name: "Print Manifest" },
  BACK_TO_PORTAL: { id: `${prefix}_Back To Portal`, name: "Back To Portal" },
  START_NEW_BOOKING: {
    id: `${prefix}_Start New Booking`,
    name: "Start New Booking",
  },
};

enum BookingFinalize {
  FINALIZE = "Finalize",
  THANK_YOU_MODAL = "Thank You Modal",
}

export const bookingFinalizeEventNameToBookingFinalizeEventContentMapper: C.EventNameToContentMapperType<
  typeof BookingFinalizeInteractions
> = {
  [BookingFinalizeInteractions.COMPLETE_BOOKING.id]: {
    name: BookingFinalizeInteractions.COMPLETE_BOOKING.name,
    ...eventContentButtonInterObj,
    position: BookingFinalize.FINALIZE,
    context: BookingFinalize.FINALIZE,
  },
  [BookingFinalizeInteractions.THANK_YOU.id]: {
    name: BookingFinalizeInteractions.THANK_YOU.name,
    ...eventContentPageOverlayInterObj,
    position: BookingFinalize.THANK_YOU_MODAL,
    context: BookingFinalize.THANK_YOU_MODAL,
  },
  [BookingFinalizeInteractions.PRINT_LABEL.id]: {
    name: BookingFinalizeInteractions.PRINT_LABEL.name,
    ...eventContentButtonInterObj,
    position: BookingFinalize.THANK_YOU_MODAL,
    context: BookingFinalize.THANK_YOU_MODAL,
  },
  [BookingFinalizeInteractions.PRINT_MANIFEST.id]: {
    name: BookingFinalizeInteractions.PRINT_MANIFEST.name,
    ...eventContentButtonInterObj,
    position: BookingFinalize.THANK_YOU_MODAL,
    context: BookingFinalize.THANK_YOU_MODAL,
  },
  [BookingFinalizeInteractions.BACK_TO_PORTAL.id]: {
    name: BookingFinalizeInteractions.BACK_TO_PORTAL.name,
    ...eventContentButtonInterObj,
    position: BookingFinalize.THANK_YOU_MODAL,
    context: BookingFinalize.THANK_YOU_MODAL,
  },
  [BookingFinalizeInteractions.START_NEW_BOOKING.id]: {
    name: BookingFinalizeInteractions.START_NEW_BOOKING.name,
    ...eventContentButtonInterObj,
    position: BookingFinalize.THANK_YOU_MODAL,
    context: BookingFinalize.THANK_YOU_MODAL,
  },
};
