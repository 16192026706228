import { eventContentButtonInterObj } from "../../AdobeAnalytics.constants";
import * as C from "../ContentInteractionEvents.constants";

const prefix = "AccountInteractions";

export const AccountInteractions = {
  LOG_OUT: { id: `${prefix}_Logout`, name: "Logout" },
  PROFILE_PICTURE: { id: `${prefix}_Profile Picture`, name: "Profile Picture" },
  ENABLE_NOTIFICATIONS: {
    id: `${prefix}_Enable Notifications`,
    name: "Enable Notifications",
  },
  DISABLE_NOTIFICATIONS: {
    id: `${prefix}_Disable Notifications`,
    name: "Disable Notifications",
  },
  DEFAULT_CURRENCY: {
    id: `${prefix}_Default Currency`,
    name: "Default Currency",
  },
  CORPORATE_PARTNER: {
    id: `${prefix}_Corporate Partner`,
    name: "Corporate Partner",
  },
};

enum AccountPosition {
  PAGE_HEADER = "Page Header",
  PROFILE_INFORMATION = "Profile Information",
}

const accountContext = "Profile";

export const accountEventNameToAccountEventContentMapper: C.EventNameToContentMapperType<
  typeof AccountInteractions
> = {
  [AccountInteractions.LOG_OUT.id]: {
    name: AccountInteractions.LOG_OUT.name,
    ...eventContentButtonInterObj,
    position: AccountPosition.PAGE_HEADER,
    context: accountContext,
  },
  [AccountInteractions.PROFILE_PICTURE.id]: {
    name: AccountInteractions.PROFILE_PICTURE.name,
    ...eventContentButtonInterObj,
    position: AccountPosition.PROFILE_INFORMATION,
    context: accountContext,
  },
  [AccountInteractions.ENABLE_NOTIFICATIONS.id]: {
    name: AccountInteractions.ENABLE_NOTIFICATIONS.name,
    ...eventContentButtonInterObj,
    position: AccountPosition.PROFILE_INFORMATION,
    context: accountContext,
  },
  [AccountInteractions.DISABLE_NOTIFICATIONS.id]: {
    name: AccountInteractions.DISABLE_NOTIFICATIONS.name,
    ...eventContentButtonInterObj,
    position: AccountPosition.PROFILE_INFORMATION,
    context: accountContext,
  },
  [AccountInteractions.DEFAULT_CURRENCY.id]: {
    name: AccountInteractions.DEFAULT_CURRENCY.name,
    ...eventContentButtonInterObj,
    position: AccountPosition.PROFILE_INFORMATION,
    context: accountContext,
  },
  [AccountInteractions.CORPORATE_PARTNER.id]: {
    name: AccountInteractions.CORPORATE_PARTNER.name,
    ...eventContentButtonInterObj,
    position: AccountPosition.PROFILE_INFORMATION,
    context: accountContext,
  },
};
