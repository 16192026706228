export const showHideWithScale = {
  hidden: {
    opacity: 0,
    scale: 0.9,
    transition: { duration: 0.25, ease: [0.4, 0.0, 0.2, 1] },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.3, ease: [0.4, 0.0, 0.2, 1] },
  },
};
export const showHideWithFade = {
  hidden: {
    opacity: 0,
    transition: { duration: 0.25, ease: [0.4, 0.0, 0.2, 1] },
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.3, ease: [0.4, 0.0, 0.2, 1] },
  },
};
